<div class="map-hover-preview" [ngClass]="{
  'awaiting-for-conditions': snowCannon.computedStatus === 'awaiting-for-conditions',
  'breakdown': snowCannon.computedStatus === 'breakdown',
  'connection-lost': snowCannon.computedStatus === 'connection-lost',
  'in-local-mode': snowCannon.computedStatus === 'in-local-mode',
  'in-service-mode': snowCannon.computedStatus === 'in-service-mode',
  'preparing': snowCannon.computedStatus === 'preparing-to-work',
  'ready-to-work': snowCannon.computedStatus === 'ready-to-work',
  'snoozing': snowCannon.computedStatus === 'snoozing',
  'standing-by': snowCannon.computedStatus === 'standing-by',
  'starting': snowCannon.computedStatus === 'starting',
  'stopping': snowCannon.computedStatus === 'stopping',
  'working': snowCannon.computedStatus === 'working',
  'map-hover-preview--bottom-right': !direction || direction === 'bottom-right',
  'map-hover-preview--top-right': direction === 'top-right',
  'map-hover-preview--top-left': direction === 'top-left',
  'map-hover-preview--bottom-left': direction === 'bottom-left'
}" *ngIf="snowCannon">
  <div class="map-hover-preview__header d-flex align-items-center" [ngClass]="{
    'mode-auto': snowCannon.operationMode === 1,
    'mode-manual': snowCannon.operationMode === 0
  }">
    <div class="px-3 map-hover-preview__cannon-no d-flex align-self-stretch align-items-center justify-content-center">
      <p class="fs-30">{{ snowCannon.symbol }}</p>
    </div>
    <div class="map-hover-preview__image">
      <ss-device-image [device]="snowCannon"></ss-device-image>
      <div class="steering-is-local-overlay" *ngIf="snowCannon.connectionStatus && snowCannon.isInLocalMode">
        <svg>
          <use xlink:href='#icon-lock'/>
        </svg>
      </div>
    </div>
    <div class="map-hover-preview__model-n-mount">
      <p>{{ snowCannon.cannonModel }}</p>
      <span>
        <ss-translated-device-mount-type [device]="snowCannon"></ss-translated-device-mount-type>
      </span>
    </div>
  </div>
  <div class="px-2" *ngIf="snowCannon.connectionStatus">
    <div class="row py-2">
      <div class="col-4">
        <p class="color-white white-space-nowrap text-right pr-2">
          <strong class="color-green fs-20">{{ snowCannon|convertedValue:'currentSnowProductionVolume' }}</strong>
          <span class="fs-14 color-light-grey">{{ snowCannon|convertedUnit:'currentSnowProductionVolume' }}</span>
        </p>
      </div>
      <div class="col-8">
        <div #miniBarChartContainer1>
          <ss-d3-linked-mini-bar-chart [viewBoxWidth]="miniBarChartContainer1.offsetWidth" tag="compare-snow-cannons" [device]="snowCannon"></ss-d3-linked-mini-bar-chart>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-4 d-flex align-items-center">
        <div class="map-hover-preview__parameter">
          <svg>
            <use xlink:href='#icon-termo-wet'/>
          </svg>
          {{ snowCannon.wetBulbTemperature|number:'1.1-1' }}&#176;C
        </div>
      </div>
      <div class="col-8 d-flex align-items-center pl-2">
        <div class="map-hover-preview__parameter">
          <svg>
            <use xlink:href='#icon-snow'/>
          </svg>
          <span class="map-hover-preview__snow-quality">{{ snowCannon.snowQuality }}</span>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-4 d-flex align-items-center">
        <div class="map-hover-preview__parameter">
          <svg>
            <use xlink:href='#icon-tap'/>
          </svg>
          {{ snowCannon.currentWaterPressure > 0 ? (snowCannon.waterTemperature|number:'1.1-1') : '--' }}&#176;C
        </div>
      </div>
      <div class="col-8 d-flex align-items-center pl-2">
        <span class="map-hover-preview__parameter">
          {{ (snowCannon.currentWaterPressure || 0)|number:'1.1-1' }}bar
        </span>
        <span class="map-hover-preview__parameter pl-2">
            {{ snowCannon|convertedValue:'currentWaterFlow' }}{{ snowCannon|convertedUnit:'currentWaterFlow' }}
        </span>
      </div>
    </div>
  </div>
</div>
