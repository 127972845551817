import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { ResortStatisticsService } from 'src/app/resort-statistics.service';
import { SettingsService } from 'src/app/settings.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TimedChartConfig, TimedChartConfigSeries, TimedChartData } from '../../../../../../../common';

@Component({
  selector: 'ss-d3-pressure-and-flow-chart',
  templateUrl: './pressure-and-flow-chart.component.html',
  styleUrls: ['./pressure-and-flow-chart.component.sass']
})
export class PressureAndFlowChartComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public device: { id: string };
  @Input()
  public suplementaryDevice: { id: string };
  @Output()
  public durationChanged: EventEmitter<number> = new EventEmitter();

  public data: TimedChartData;
  public config: TimedChartConfig;
  public duration = 12;

  private statsUpdatedSubscribtion: Subscription;
  private dataChange: EventEmitter<any> = new EventEmitter();

  public constructor(
    private el: ElementRef,
    private settings: SettingsService,
    private resortStatisticsService: ResortStatisticsService
  ) {
    this.duration = this.settings.get('defaultChartPeriod');

    this.dataChange.pipe(debounceTime(10)).subscribe(() => {
      this.config = {
        ...new TimedChartConfig(),
        xAxisType: 'bottom',
        series: [
          {
            ... new TimedChartConfigSeries(),
            seriesType: 'bar-w-rounded-top',
            seriesName: 'water flow', // no need to translate - not shown anywhere
            valueFieldName: 'waterFlow',
            primaryColor: '#1BD374',
            secondaryColor: '#FD994C',
            fixedMin: 0,
            grid: true,
            unit: 'waterFlow',
            storedUnitOverride: 'm3/h'
          },
          {
            ... new TimedChartConfigSeries(),
            seriesType: 'line-w-rounded-end',
            seriesName: 'water pressure', // no need to translate - not shown anywhere
            valueFieldName: 'waterPressure',
            primaryColor: '#00A1E4',
            fixedMin: 0,
            unit: 'waterPressure'
          },
        ]
      };
      this.data = this.fetchChartDataById();
    });
  }

  public setDuration(val: number) {
    this.duration = val;
    this.durationChanged.emit(val);
    this.updateData();
  }

  protected fetchChartDataById(): TimedChartData {
    const duration = this.duration || this.settings.get('defaultChartPeriod');

    const rawData = this.resortStatisticsService.getStats(this.device.id, duration);
    const preparedData = {
      labels: rawData.aggregatedLabels,
      waterFlow: rawData.waterFlow,
      waterPressure: rawData.waterPressure
    };

    return new TimedChartData(preparedData);
  }

  private updateData() {
    this.dataChange.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.device
      || changes.duration
    ) {
      this.updateData();
    }
  }

  ngOnInit() {
    this.statsUpdatedSubscribtion = this.resortStatisticsService.statsUpdated.subscribe(() => {
      this.updateData();
    });

    this.updateData();
  }

  ngOnDestroy() {
    if (this.statsUpdatedSubscribtion) {
      this.statsUpdatedSubscribtion.unsubscribe();
    }
  }

}
