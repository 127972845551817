import { Component, OnInit } from '@angular/core';
import { ModalRouteService } from '../../modal-route.service';

@Component({
  selector: 'ss-rename-modal',
  templateUrl: './rename-modal.component.html',
  styleUrls: ['./rename-modal.component.sass']
})
export class RenameModalComponent implements OnInit {

  constructor(
    private mr: ModalRouteService
  ) { }

  public isReadOnly = false;
  public renameString: string;
  public displayTitleText: string;
  public displayInputLabel: string;

  ngOnInit(): void {
    const lrd = this.mr.lastRequestData || {};
    this.isReadOnly = !!lrd.isReadOnly;
    this.renameString = lrd.renameString ? `${lrd.renameString}` : undefined;
    this.displayTitleText = lrd.displayTitleText ? `${lrd.displayTitleText}` : undefined;
    this.displayInputLabel = lrd.displayInputLabel ? `${lrd.displayInputLabel}` : '';
  }

  public close() {
    this.mr.closeModal();
  }

  public save() {
    this.mr.closeModal(`${this.renameString}`);
  }
}
