import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UsersClientService } from 'src/app/http-clients';
import { Observable, of } from 'rxjs';
import { dayjs } from '../../../../../common';

@Injectable({
  providedIn: 'root'
})
export class UserAuditTrailResolverService implements Resolve<any> {

  constructor(private ucs: UsersClientService) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    const hnow = Math.ceil((dayjs().valueOf() / 1000 / 3600)) * 3600;
    const id: number = parseInt(route.paramMap.get('userId'), 10);

    if (!id || isNaN(id)) {
      return of(null);
    }

    return this.ucs.getUserAuditTrail(id, hnow - 7 * 24 * 3600, hnow);
  }
}
