<div class="form-row" [ngClass]="{
  'is-required': markIsRequired
}">
  <div class="form-label" *ngIf="!hideLabel">
    <label [attr.for]="identifier">
      <span #labelContent><ng-content select="form-label"></ng-content></span>
      <ng-container *ngIf="!labelContent.innerHTML.trim()">{{ label }}</ng-container>
    </label>
  </div>
  <div class="form-control" [ngClass]="{
    'extra-wide': isExtraWide,
    invalid: (invalid | async)
  }">
    <form-errors-display [extraErrorComponents]="extraErrorComponents" [messages]="failures | async">
      <ng-content select="form-error"></ng-content>
    </form-errors-display>
    <input class="input"
      [ngClass]="{ 'input--extra-wide': isExtraWide }"
      [disabled]="disabled"
      [readonly]="isReadOnly"
      [ssdAutoFocus]="isAutoFocus"
      [autocomplete]="type === 'password' ? 'new-password' : 'off'"
      [type]="type"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      [name]="name"
      [id]="identifier" />
</div>
</div>
