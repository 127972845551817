<ng-container *ngIf="forecast">


  <ss-page-header>

    <ss-page-header-title i18n="Prognoza|Tytuł 'Prognoza' na stronie prognoz i schematów@@weatherAndSchedulesListForecast">Prognoza</ss-page-header-title>

    <div class="d-flex align-items-center justify-content-between flex-wrap flex-grow-1">
      <div class="ml-3 my-2 d-flex align-items-center">

        <div class="btn-group">
          <button [disabled]="loadingInProgress" (click)="setLowBound('-7d')" class="btn btn-dark"
            [ngClass]="{ 'btn-pressed': lowBound === '-7d' }">
            -7<ng-container i18n="d|Jednostki czasu - skróty@@globalSymbolTimeUnitDayAbbr">d</ng-container>
          </button>
          <button [disabled]="loadingInProgress" (click)="setLowBound('-3d')" class="btn btn-dark"
            [ngClass]="{ 'btn-pressed': lowBound === '-3d' }">
            -3<ng-container i18n="d|Jednostki czasu - skróty@@globalSymbolTimeUnitDayAbbr">d</ng-container>
          </button>
          <button [disabled]="loadingInProgress" (click)="setLowBound('-1d')"
            class="btn btn-dark red-separator"
            [ngClass]="{ 'btn-pressed': lowBound === '-1d' }">
            -1<ng-container i18n="d|Jednostki czasu - skróty@@globalSymbolTimeUnitDayAbbr">d</ng-container>
          </button>

          <button [disabled]="loadingInProgress" (click)="setHighBound('4h')" class="btn btn-dark"
            [ngClass]="{ 'btn-pressed': highBound === '4h' }">
            4<ng-container i18n="h|Jednostki czasu - skróty@@globalSymbolTimeUnitHourAbbr">h</ng-container>
          </button>
          <button [disabled]="loadingInProgress" (click)="setHighBound('8h')" class="btn btn-dark"
            [ngClass]="{ 'btn-pressed': highBound === '8h' }">
            8<ng-container i18n="h|Jednostki czasu - skróty@@globalSymbolTimeUnitHourAbbr">h</ng-container>
          </button>
          <button [disabled]="loadingInProgress" (click)="setHighBound('12h')" class="btn btn-dark"
            [ngClass]="{ 'btn-pressed': highBound === '12h' }">
            12<ng-container i18n="h|Jednostki czasu - skróty@@globalSymbolTimeUnitHourAbbr">h</ng-container>
          </button>
          <button [disabled]="loadingInProgress" (click)="setHighBound('1d')" class="btn btn-dark"
            [ngClass]="{ 'btn-pressed': highBound === '1d' }">
            1<ng-container i18n="d|Jednostki czasu - skróty@@globalSymbolTimeUnitDayAbbr">d</ng-container>
          </button>
          <button [disabled]="loadingInProgress" (click)="setHighBound('3d')" class="btn btn-dark"
            [ngClass]="{ 'btn-pressed': highBound === '3d' }">
            3<ng-container i18n="d|Jednostki czasu - skróty@@globalSymbolTimeUnitDayAbbr">d</ng-container>
          </button>
          <button [disabled]="loadingInProgress" (click)="setHighBound('7d')" class="btn btn-dark"
            [ngClass]="{ 'btn-pressed': highBound === '7d' }">
            7<ng-container i18n="d|Jednostki czasu - skróty@@globalSymbolTimeUnitDayAbbr">d</ng-container>
          </button>
        </div>
      </div>
      <div class="forecast-legend d-flex align-items-center my-2 pl-2">
        <ng-select class="custom-select custom-select--simple fs-12 text-uppercase" [items]="forecast.providers"
          [clearable]="false" [searchable]="false" [(ngModel)]="selectedProvider">
          <ng-template ng-option-tmp ng-label-tmp let-item="item">
            <svg class="forecast-legend__symbol forecast-legend__symbol--svg">
              <use xlink:href='#icon-curved-line' [ngStyle]="{ 'stroke': getForecastColor(forecast.providers, item) }" />
            </svg>
            <span class="color-light-grey">{{ item.name }}</span>
          </ng-template>
        </ng-select>
        <div class="forecast-legend__item d-flex align-items-center fs-12 ml-2">
          <span class="forecast-legend__symbol forecast-legend__symbol--colored"></span>
          <span class="forecast-legend__text"
            i18n="Prognoza naśnieżania|Opisy wykresu prognoz na stronie prognoz i schematów@@weatherAndSchedulesListChartDescSnowingForecast">Prognoza
            naśnieżania</span>
        </div>
        <div class="forecast-legend__item d-flex align-items-center fs-12 ml-2">
          <span class="forecast-legend__symbol forecast-legend__symbol--colored"></span>
          <span class="forecast-legend__text"
            i18n="Naśnieżanie|Opisy wykresu prognoz na stronie prognoz i schematów@@weatherAndSchedulesListChartDescTimeOfSnowing">Naśnieżanie</span>
        </div>
        <div class="forecast-legend__item d-flex align-items-center fs-12 ml-2">
          <span class="forecast-legend__symbol forecast-legend__symbol--colored"></span>
          <span class="forecast-legend__text"
            i18n="Niekorzystne warunki|Opisy wykresu prognoz na stronie prognoz i schematów@@weatherAndSchedulesListChartDescUnfavourableConditions">Niekorzystne
            warunki</span>
        </div>
        <div class="forecast-legend__item d-flex align-items-center fs-12 ml-2">
          <span class="forecast-legend__symbol forecast-legend__symbol--colored"></span>
          <span class="forecast-legend__text"
            i18n="Niewykorzystane warunki|Opisy wykresu prognoz na stronie prognoz i schematów@@weatherAndSchedulesListChartDescMissedConditions">Niewykorzystane
            warunki</span>
        </div>
        <div class="forecast-legend__item d-flex align-items-center fs-12 ml-2">
          <span class="forecast-legend__symbol forecast-legend__symbol--colored"></span>
          <span class="forecast-legend__text"
            i18n="Śnieg|Opisy wykresu prognoz na stronie prognoz i schematów@@weatherAndSchedulesListChartDescSnow">Śnieg</span>
        </div>
        <div class="forecast-legend__item d-flex align-items-center fs-12 ml-2">
          <span class="forecast-legend__symbol forecast-legend__symbol--colored"></span>
          <span class="forecast-legend__text"
            i18n="Deszcz|Opisy wykresu prognoz na stronie prognoz i schematów@@weatherAndSchedulesListChartDescRain">Deszcz</span>
        </div>
      </div>



      <!-- <ss-page-button-group>
        <a [routerLink]="['../edit/', 'new']" class="btn btn-default ml-4" *ssdHideIfNotGranted="'ROLE_ADMIN'">
          <svg>
            <use xlink:href='#icon-plus' />
          </svg>
          <ng-container
            i18n="Dodaj schemat|Dodaj schemat na stronie prognoz i schematów@@weatherAndSchedulesListAddNew">Dodaj
            schemat</ng-container>
        </a>
      </ss-page-button-group> -->



    </div>
  </ss-page-header>

  <div #container>
    <div class="weather-stations mt-2 mb-4">
      <ng-container *ngFor="let station of meteoStations; let i = index">
        <div *ngIf="i === 0" class="weather-station weather-station--first d-flex align-items-center">
          <i [ngStyle]="{ 'background-color': getMeteoStationColor(meteoStations, station) }"></i>
          <ss-checkbox [isChecked]="isMeteoStationChecked(station.id)" (hasChanged)="toggleMeteoStation(station.id)"
            size="large"></ss-checkbox>
          <div class="col-3">
            <ss-translated-device-type-symbol [device]="station"></ss-translated-device-type-symbol>
          </div>
          <div class="weather-station__meteo-bar col-auto ml-4">
            <ss-meteo-conditions-display [sourceDevice]="station"></ss-meteo-conditions-display>
          </div>
          <ng-container *ngIf="station.isPurelySynthetic">
            <div class="col"></div>
            <div class="col-auto">
              <button class="btn btn-default"
                (click)="openMeteoSimulatorConfig(station.id)"
                i18n="Konfiguruj symulację|Edycja stacji meteo, która jest symulatorem@@weatherAndSchedulesConfigSimulation">
                Konfiguruj symulację
              </button>
            </div>
          </ng-container>
          <button *ngIf="meteoStations.length > 1" class="weather-stations__btn d-flex align-items-center"
            (click)="isStationsDropdownExpanded = !isStationsDropdownExpanded">
            <ng-container
              i18n="Wszystkie stacje meteo|Wszystkie stacje meteo na stronie prognoz i schematów@@weatherAndSchedulesListAllMeteoStations">
              Wszystkie stacje meteo</ng-container>
            <svg class="weather-stations__angle"
              [ngClass]="{'weather-stations__angle--rotated': isStationsDropdownExpanded}">
              <use xlink:href='#icon-chevron-down' />
            </svg>
          </button>
        </div>
      </ng-container>

      <div *ngIf="meteoStations.length > 1" class="weather-stations__dropdown"
        [ngStyle]="{'height': isStationsDropdownExpanded ? 70 * (meteoStations.length - 1) + 'px' : '0px' }">
        <ng-container *ngFor="let station of meteoStations; let i = index">
          <div *ngIf="i !== 0" class="weather-station d-flex align-items-center">
            <i [ngStyle]="{ 'background-color': getMeteoStationColor(meteoStations, station) }"></i>
            <ss-checkbox [isChecked]="isMeteoStationChecked(station.id)" (hasChanged)="toggleMeteoStation(station.id)"
              size="large"></ss-checkbox>
            <div class="col-3">
              <ss-translated-device-type-symbol [device]="station"></ss-translated-device-type-symbol>
            </div>
            <div class="weather-station__meteo-bar col-auto ml-4">
              <ss-meteo-conditions-display [sourceDevice]="station"></ss-meteo-conditions-display>
            </div>
            <ng-container *ngIf="station.isPurelySynthetic">
              <div class="col"></div>
              <div class="col-auto">
                <button class="btn btn-default"
                  (click)="openMeteoSimulatorConfig(station.id)"
                  i18n="Konfiguruj symulację|Edycja stacji meteo, która jest symulatorem@@weatherAndSchedulesConfigSimulation">
                  Konfiguruj symulację
                </button>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>


    <ss-d3-chart-drawer
      [viewBoxHeight]="200"
      [viewBoxWidth]="container.offsetWidth"
      [config]="config"
      [data]="data"></ss-d3-chart-drawer>

  </div>


</ng-container>
