<div class="form-row align-items-center" [ngClass]="{ 'is-required': markIsRequired }">
  <div class="form-label" *ngIf="!hideLabel">
    <label [attr.for]="identifier">
      <span #labelContent><ng-content select="form-label"></ng-content></span>
      <ng-container *ngIf="!labelContent.innerHTML.trim()">{{ label }}</ng-container>
    </label>
  </div>
  <div class="form-control" [ngClass]="{invalid: (invalid | async)}">
    <input
      [disabled]="disabled"
      class="input"
      type="range"
      [min]="min"
      [max]="max"
      [step]="step"
      [(ngModel)]="value"
      [name]="name"
      [id]="identifier" />
    <div class="range-min">{{ min|number:formatScale }}</div>
    <div class="range-value">{{ value|number:formatScale }}</div>
    <div class="range-max">{{ max|number:formatScale }}</div>
  </div>
</div>
