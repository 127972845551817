<div class="snowing-angle">
    <div class="snowing-angle__overflow-box">
        <div class="snowing-angle__circle">
            <div class="snowing-angle__angle" [ngStyle]="{'transform': 'rotate(-' + snowingAngle + 'deg)'}"></div>
        </div>
    </div>
    <div class="snowing-angle__desc-l1">
        <div class="snowing-angle__desc-l2" [ngStyle]="{'transform': 'rotate(-' + snowingAngle + 'deg)'}">
            <span class="snowing-angle__label" [ngStyle]="{'transform': 'translateX(100%) rotate(' + snowingAngle + 'deg)'}">{{ snowingAngle }}&#176;</span>
        </div>
    </div>
</div>
