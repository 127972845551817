<div [ngClass]="{ 'disabled': lock }">
  <form #form="ngForm" (submit)="save()">
  <ss-page [modal]="true">
    <ss-page-header>
      <ss-page-header-title i18n="Ustawienia armatki|Główny tytuł@@snowcannonConfigModalTitle">Ustawienia armatki {{ device.symbol }}</ss-page-header-title>
    </ss-page-header>
    <ss-page-panel panelTitle="Ogólne"
      i18n-panelTitle="Ogólne|Tytuł panelu@@deviceConfigModalPanelTitleGeneral"
      *ngIf="device.connectionStatus
      && (
          device.CAP_CHANGE_MAXIMUM_WATER_PRESSURE
          || device.CAP_CHANGE_MAXIMUM_WATER_FLOW
      )">
      <div class="row" *ngIf="device.CAP_CHANGE_MAXIMUM_WATER_PRESSURE">
        <div class="col-10">
          <form-range [min]="8" [max]="40" [step]="1" name="maximumTargetWaterPressure" label="Ciśnienie maksymalne"
            i18n-label="Ciśnienie maksymalne|Ustawienia armatki - Ciśnienie maksymalne@@snowCannonSettingsMaximumTargetWaterPressure"
            [(ngModel)]="maximumWaterPressure">
          </form-range>
        </div>
        <div class="col">
          <p class="p-3">
            {{ device.maximumWaterPressure|number }}bar
          </p>
        </div>
      </div>
      <div class="row" *ngIf="device.CAP_CHANGE_MAXIMUM_WATER_FLOW">
        <div class="col-10">
          <form-range [min]="1" [max]="device.CAP_MAXIMUM_WATER_FLOW_VALUE || 10" [step]="0.1" name="maximumTargetWaterFlow" label="Przepływ maksymalny"
            i18n-label="Przepływ maksymalny|Ustawienia armatki - Przepływ maksymalny@@snowCannonSettingsMaximumTargetWaterFlow"
            [(ngModel)]="maximumWaterFlow">
          </form-range>
        </div>
        <div class="col">
          <p class="p-3">
            {{ device.maximumWaterFlow|number:'1.1-1' }}l/s
          </p>
        </div>
      </div>
    </ss-page-panel>

    <ss-page-panel panelTitle="Notyfikacje" i18n-panelTitle="Notyfikacje|Tytuł panelu@@deviceConfigModalPanelTitleNotifications">
      <form-checkbox
        [hideLabel]="true"
        [(ngModel)]="notificationsEnabled"
        name="form-cfg-notificationsEnabled">
        <form-label i18n="Wysyłaj notyfikacje o przejściu armatki w wybrane statusy|Checkbox po którego zaznaczeniu można zarządzać notyfikacjami@@snowcannonConfigModalNotificationsCheckbox">Wysyłaj notyfikacje o przejściu armatki w wybrane statusy</form-label>
      </form-checkbox>

      <div class="detailed-settings" [ngClass]="{ 'visible': notificationsEnabled }">
        <p i18n="Wybierz statusy dla których będą wysyłane powiadomienia|Akaput z info@@snowcannonConfigModalNotificationsInfo">Wybierz statusy dla których będą wysyłane powiadomienia</p>
        <div class="row align-items-center">
          <table class="status-notifications-table">
            <tr>
              <td [ngClass]="{ 'active': isNotificationSelected('working') }" (click)="toggleNotification('working')">
                <ss-snow-cannon-status size="small" state="working"></ss-snow-cannon-status>
              </td>
              <td [ngClass]="{ 'active': isNotificationSelected('awaiting-for-conditions') }" (click)="toggleNotification('awaiting-for-conditions')">
                <ss-snow-cannon-status size="small" state="awaiting-for-conditions"></ss-snow-cannon-status>
              </td>
              <td [ngClass]="{ 'active': isNotificationSelected('ready-to-work') }" (click)="toggleNotification('ready-to-work')">
                <ss-snow-cannon-status size="small" state="ready-to-work"></ss-snow-cannon-status>
              </td>
              <td [ngClass]="{ 'active': isNotificationSelected('standing-by') }" (click)="toggleNotification('standing-by')">
                <ss-snow-cannon-status size="small" state="standing-by"></ss-snow-cannon-status>
              </td>
            </tr>
            <tr>
              <td [ngClass]="{ 'active': isNotificationSelected('starting') }" (click)="toggleNotification('starting')">
                <ss-snow-cannon-status size="small" state="starting"></ss-snow-cannon-status>
              </td>
              <td [ngClass]="{ 'active': isNotificationSelected('snoozing') }" (click)="toggleNotification('snoozing')">
                <ss-snow-cannon-status size="small" state="snoozing"></ss-snow-cannon-status>
              </td>
              <td [ngClass]="{ 'active': isNotificationSelected('stopping') }" (click)="toggleNotification('stopping')">
                <ss-snow-cannon-status size="small" state="stopping"></ss-snow-cannon-status>
              </td>
              <td [ngClass]="{ 'active': isNotificationSelected('preparing-to-work') }" (click)="toggleNotification('preparing-to-work')">
                <ss-snow-cannon-status size="small" state="preparing-to-work"></ss-snow-cannon-status>
              </td>
            </tr>
          </table>





            <!-- 'starting' -->
            <!-- 'working' -->
            <!-- 'stopping' -->
            <!-- 'awaiting-for-conditions' -->
            <!-- 'ready-to-work' -->
            <!-- 'snoozing' -->
            <!-- 'standing-by' -->
            <!-- 'preparing-to-work' -->
        </div>
      </div>
    </ss-page-panel>

    <ss-page-panel *ngIf="device.CAP_CHANGE_HYDRANT"
      panelTitle="Podłączenie do hydrantu"
      i18n-panelTitle="Podłączenie do hydrantu|Tytuł panelu@@snowcannonConfigModalPanelTitleHydrantConnect">
      <div *ngIf="
        connectionPointEnabled
        && device.connectionStatus
        && device.conenctionPointRef
        && device.hydrantNumber !== device.conenctionPointRef.symbolAsNumber"
        style="padding:15px; background: #EE2142; color: #fff">
        <p i18n="Numer hydrantu skonfigurowany w systemie jest inny niż na armatce. Co chcesz z tym zrobic?|Komunikat Inny numer na armatce niż w systemie@@snowCannonTabSettingsHydrantNoNotMatching">Numer hydrantu skonfigurowany w systemie jest inny niż na armatce. Co chcesz z tym zrobic?</p>
        <div class="d-flex">
          <button
            (click)="setHydrantNumber(device.conenctionPointRef.symbol)"
            class="btn btn-warning flex-grow-1 mt-3 mr-1">
              <ng-container i18n="Ustaw na armacie hydrant numer|Ustaw na armacie hydrant numer@@snowCannonPanelNotMatchingHydrantNoOption1">Ustaw na armacie hydrant numer</ng-container>
              &nbsp;<u>{{ device.conenctionPointRef.symbol }}</u>&nbsp;
            </button>
          <button
            (click)="setHydrantNumber(device.hydrantNumber)"
            class="btn btn-warning flex-grow-1 mt-3 ml-1">
            <ng-container i18n="Skonfiguruj w systemie hydrant numer|Skonfiguruj w systemie hydrant numer@@snowCannonPanelNotMatchingHydrantNoOption2">Skonfiguruj w systemie hydrant numer</ng-container>
            &nbsp;<u>{{ device.hydrantNumber }}</u>&nbsp;
            </button>
        </div>
      </div>
      <form-checkbox
        [disabled]="true"
        [hideLabel]="true"
        [(ngModel)]="connectionPointEnabled"
        name="form-cfg-connectionPointEnabled">
        <form-label i18n="Podłącz armatkę do hydrantu|Checkbox po którego zaznaczeniu można podłączyć armatkę@@snowcannonConfigModalHydrantCheckbox">Podłącz armatkę do hydrantu</form-label>
      </form-checkbox>
      <div class="detailed-settings" [ngClass]="{ 'visible': connectionPointEnabled }">
        <p i18n="Wybierz hydrant, do którego podłączona jest armatka|Akaput z info@@snowcannonConfigModalHydrantInfo">Wybierz hydrant, do którego podłączona jest armatka</p>
        <div class="row align-items-center">
          <div class="col-10">
            <form-select name="selectedConnectionPoint" label="Wybrany hydrant"
                i18n-label="Wybrany hydrant|Wybrany hydrant@@snowCannonSettingsSelectedHydrant"
                [(ngModel)]="connectionPoint">
                <form-select-option *ngFor="let c of connectionPoints"
                  [value]="c"
                  [device]="c"></form-select-option>
            </form-select>
          </div>
        </div>
      </div>
    </ss-page-panel>

    <ss-page-panel *ngIf="device.CAP_SET_REMOTE_CONDITIONS"
      panelTitle="Współdzielenie parametrów pogodowych"
      i18n-panelTitle="Współdzielenie parametrów pogodowych|Tytuł panelu@@snowcannonConfigModalPanelTitleRemoteMeteoConditions">
      <form-checkbox [hideLabel]="true" [(ngModel)]="remoteMeteoStationEnabled" name="form-cfg-remoteMeteoStationEnabled">
        <form-label i18n="Włącz współdzielenie parametrów pogodowych z innej armatki lub stacji meteo|Checkboź po włączeniu którego można wybrać stację meteo@@snowcannonConfigModalRemoteMeteoConditionsCheckbox">Włącz współdzielenie parametrów pogodowych z innej armatki lub stacji meteo</form-label>
      </form-checkbox>
      <div class="detailed-settings" [ngClass]="{ 'visible': remoteMeteoStationEnabled }">
        <p class="pb-2" i18n="Armatka będzie korzystać z danych pogodowych wybranego urządzenia zamiast z wbudowanej stacji meteo|Akapit z ingo@@snowcannonConfigModalRemoteMeteoConditionsInfo">Armatka będzie korzystać z danych pogodowych wybranego urządzenia zamiast z wbudowanej stacji meteo</p>
        <ss-meteo-conditions-source-selector
          [sourceId]="device.remoteMeteoStation"
          [limitToResortArea]="device.connectionPoint ? device.conenctionPointRef.resortArea : null"
          [exceludeIds]="[ device.id ]"
          (sourceIdChanged)="onMeteoSourceSelection($event)"></ss-meteo-conditions-source-selector>
      </div>
    </ss-page-panel>

    <ss-page-footer>
      <div></div>
      <ss-page-button-group>
        <button type="submit"
          class="btn btn-primary"
          i18n="Zapisz konfigurację|Przycisk zapisz@@snowcannonConfigModalSaveBtn">Zapisz konfigurację</button>
        <button type="button"
          class="btn btn-default"
          (click)="close()" i18n="Anuluj|Anuluj@@massOperationsModalCancelButon">Anuluj</button>
      </ss-page-button-group>
    </ss-page-footer>
  </ss-page>
  </form>
</div>
