import { Component, OnInit } from '@angular/core';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ModalRouteService } from 'src/app/application/modal-route.service';
import { ResortService } from 'src/app/resort.service';
import { SnowQualityValue } from 'src/app/shared/components/snow-quality/snow-quality.component';
import { of } from 'rxjs';
import { ProgressOfObservablesService } from '../progress-of-observables.service';
import { NIL } from 'uuid';
import { MeteoStation } from '../../../shared/models';
import { isNullOrUndefined, isNumber } from '../../../../../../common';

@Component({
  selector: 'ss-mass-operations-modal',
  templateUrl: './mass-operations-modal.component.html',
  styleUrls: ['./mass-operations-modal.component.sass']
})
export class MassOperationsModalComponent implements OnInit {

  public lock = false;
  public ids: string[] = [];
  public autoIds: string[] = [];
  public manualIds: string[] = [];
  public startedManualIds: string[] = [];
  public stoppedManualIds: string[] = [];
  public canSetStartTemperature: string[] = [];
  public canSetStartWaterFlow: string[] = [];
  public needToFixHydrantNoIds: string[] = [];
  public canChangeOscillationAngleIds: string[] = [];
  public canStartOscillationIds: string[] = [];
  public canStopOscillationIds: string[] = [];
  public hasRemoteConditionsOnIds: string[] = [];

  constructor(
    private rs: ResortService,
    private cs: ConfigureClientService,
    private ar: ActivatedRoute,
    private mr: ModalRouteService,
    private ps: ProgressOfObservablesService
  ) { }

  meteoSimulator: MeteoStation;

  startTemperature = Infinity;
  startWaterFlow = Infinity;

  ngOnInit() {
    this.ar.paramMap.subscribe(params => {
      params.get('joinedCannonIds').split(',').forEach(id => {
        this.meteoSimulator = this.rs.getMeteoStations().find(s => s.isPurelySynthetic);
        const cn = this.rs.getSnowCannon(id);
        if (cn && cn.connectionStatus) {
          this.ids.push(id);
          if (cn.CAP_CHANGE_START_TEMPERATURE) {
            if (!isNullOrUndefined(cn.startTemperature)) {
              this.startTemperature = Math.min(this.startTemperature, cn.startTemperature);
            }
            this.canSetStartTemperature.push(id);
          }
          if (cn.CAP_CHANGE_START_WATER_FLOW) {
            if (!isNullOrUndefined(cn.startWaterFlow)) {
              this.startWaterFlow = Math.min(this.startWaterFlow, cn.startWaterFlow);
            }
            this.canSetStartWaterFlow.push(id);
          }
          if (cn.operationMode) {
            this.autoIds.push(id);
          } else {
            this.manualIds.push(id);
            if (cn.commandStatusCannonStart) {
              this.startedManualIds.push(id);
            } else {
              this.stoppedManualIds.push(id);
            }
          }
          if (cn.connectionPointSymbol !== cn.hydrantNumber) {
            this.needToFixHydrantNoIds.push(id);
          }

          if (cn.CAP_START_OSCILLATION && cn.CAP_STOP_OSCILLATION) {
            if (cn.isOscillationOn) {
              this.canStopOscillationIds.push(id);
            } else {
              this.canStartOscillationIds.push(id);
            }
          }

          if (cn.remoteMeteoStationRef) {
            this.hasRemoteConditionsOnIds.push(id);
          }

          if (cn.CAP_CHANGE_OSCILLATION_ANGLE) {
            this.canChangeOscillationAngleIds.push(id);
          }
        }

        this.startTemperature = this.startTemperature < -15 ? -15 : this.startTemperature;
        this.startWaterFlow = this.startWaterFlow < 0.7 ?  0.7 : this.startWaterFlow;
      });

      if (this.ids.length <= 0) {
        this.mr.closeModal();
      }
    });
  }

  close() {
    this.mr.closeModal();
  }

  resetRemoteMeteoConditions() {
    this.configureMultipleCannons(this.hasRemoteConditionsOnIds, {
      remoteMeteoStation: NIL
    });
  }

  setMeteoSimulatorAsRemoteMeteoConditions() {
    if(this.meteoSimulator) {
      this.configureMultipleCannons(this.ids, {
        remoteMeteoStation: this.meteoSimulator.id
      });
    }
  }

  setOscillationAngle(angle: number) {
    this.configureMultipleCannons(this.canChangeOscillationAngleIds, {
      setOscillationSetAngle: angle
    });
  }

  startOscillation() {
    this.configureMultipleCannons(this.canChangeOscillationAngleIds, {
      commandOscillationOn: true
    });
  }

  stopOscillation() {
    this.configureMultipleCannons(this.canChangeOscillationAngleIds, {
      commandOscillationOff: true
    });
  }

  applyHydrantNumberFromSystem() {
    this.lock = true;
    this.ps.runProgressAction(this.needToFixHydrantNoIds.map(id => {
      const cp = this.rs.getSnowCannon(id).conenctionPointRef;
      if (!cp) {
        return of(true);
      }
      return this.cs.configureSnowCannonById(id, {
        connectionPoint: cp.id
      });
    }));
  }

  applyHydrantNumberFromSnowCannon() {
    this.lock = true;
    this.ps.runProgressAction(this.needToFixHydrantNoIds.map(id => {
      const cp = this.rs.getConnectionPoints().find(c => c.symbolAsNumber === this.rs.getSnowCannon(id).hydrantNumber);
      if (!cp) {
        return of(true);
      }
      return this.cs.configureSnowCannonById(id, {
        connectionPoint: cp.id
      });
    }));
  }

  setStartTemperature() {
    if (isNumber(this.startTemperature) && this.startTemperature >= -15 && this.startTemperature <= 5) {
      this.configureMultipleCannons(this.canSetStartTemperature, {
        setStartTemperature: 0.1 * Math.round(10 * this.startTemperature)
      });
    }
  }

  setStartWaterFlow() {
    if (isNumber(this.startWaterFlow) && this.startWaterFlow >= 0.7 && this.startWaterFlow <= 11.1) {
      this.configureMultipleCannons(this.canSetStartWaterFlow, {
        setStartWaterFlow: 0.1 * Math.round(10 * this.startWaterFlow)
      });
    }
  }

  resetAlarms() {
    this.configureMultipleCannons(this.ids, {
      commandResetAlerts: true
    });
  }

  setSnowQuality(event: SnowQualityValue) {
    this.configureMultipleCannons(this.ids, {
      snowQualityType: 0,
      setSnowQuality: event.current,
    });
  }
  setOperationModeAuto() {
    this.configureMultipleCannons(this.manualIds, {
      operationMode: 1
    });
  }
  setOperationModeManual() {
    this.configureMultipleCannons(this.autoIds, {
      operationMode: 0
    });
  }

  enableHeaters() {
    this.configureMultipleCannons(this.ids, {
      commandRingHeatingOn: true
    });
  }
  disableHeaters() {
    this.configureMultipleCannons(this.ids, {
      commandRingHeatingOff: true
    });
  }

  enableLamp() {
    this.configureMultipleCannons(this.ids, {
      commandLightTurnOn: true
    });
  }
  setLampToAuto() {
    this.configureMultipleCannons(this.ids, {
      commandLightAutomaticOn: true
    });
  }
  disableLamp() {
    this.configureMultipleCannons(this.ids, {
      commandLightTurnOff: true,
      commandLightAutomaticOff: true
    });
  }

  startCannons() {
    this.lock = true;

    this.ps.runProgressAction(this.stoppedManualIds.map(id => this.cs.startDevice(id)));
  }
  stopCannons() {
    this.lock = true;
    this.ps.runProgressAction(this.startedManualIds.map(id => this.cs.stopDevice(id)));
  }

  private configureMultipleCannons(ids: string[], data: any) {
    this.lock = true;
    this.ps.runProgressAction(ids.map(id => this.cs.configureSnowCannonById(id, data)));
  }
}
