import { Injectable, EventEmitter } from '@angular/core';
import { UserNote } from 'src/app/shared/models';
import { UserNotesClientService } from 'src/app/http-clients/user-notes.client.service';
import { Observable, of } from 'rxjs';
import { SocketioClientService } from 'src/app/http-clients';

@Injectable({
  providedIn: 'root'
})
export class UserNotesService {

  constructor(
    private sockets: SocketioClientService,
    private cli: UserNotesClientService
  ) {
    this.sockets.notesNotification.subscribe(() => {
      this.update();
    });
  }

  private notes: UserNote[] = [];

  public updated: EventEmitter<any> = new EventEmitter();
  public initialize(data: UserNote[]) {
    this.notes = data;
  }

  public update() {
    this.cli.getAll().subscribe(data => {
      this.notes = data;
      this.updated.emit(true);
    });
  }

  public findById(id: number): UserNote {
    return this.notes.find(n => n.id === id);
  }

  public findByContextString(ctx: string): UserNote[] {
    return this.notes.filter((e) => e.context && e.context === ctx);
  }

  create(note: UserNote): Observable<any> {
    return this.cli.create(note);
  }

  edit(noteId: number, note: UserNote): Observable<any> {
    return this.cli.edit(noteId, note);
  }

  remove(noteId: number): Observable<any> {
    return this.cli.remove(noteId);
  }

}
