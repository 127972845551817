<div class="component" [ngClass]="{
    'highlighted': filter.isSelectedByDeviceIds(selectedPipelineIds),
    'breakdown': filter.isBreakdown
}">
    <div class="info-row">
        <svg class="icon">
            <use xlink:href='#icon-filter' />
        </svg>
        <p>{{ filter.symbol }}</p>
        <p>{{ filter.typeDescription }}&nbsp;</p>
    </div>
    <div class="status-row">
        <ss-pump-room-detail-status [status]="filter.computedStatus"></ss-pump-room-detail-status>
    </div>
</div>
