import { Component, OnInit } from '@angular/core';
import { ResortService } from 'src/app/resort.service';
import { PumpRoom, WaterPipeline } from 'src/app/shared/models';

@Component({
  selector: 'ss-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass']
})
export class EditorMenuComponent implements OnInit {

  constructor(public rs: ResortService) { }

  prs: PumpRoom[] = [];

  ngOnInit() {
    this.prs = this.rs.getPumpRooms();
  }

}
