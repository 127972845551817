import { isNullOrUndefined, TimedChartConfigSeries } from '../..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';

export const getYTickFormat = (value: any, state: D3DrawerChartState, series: TimedChartConfigSeries): string => {
  let unit = state.config.unitSet.getCalculatedUnit(series.unit);

  if(series.unit && unit) {
    value = state.config.unitSet.getCalculatedValue(series.unit, value, series.storedUnitOverride);
  } else {
    unit = '';
  }

  if(!isNullOrUndefined(value) && !isNaN(value)) {
    value = Intl.NumberFormat(state.config.locale || 'pl').format(0.1 * Math.round(10 * value));
  } else {
    value = '--';
  }

  return `${value} ${unit}`;
};
