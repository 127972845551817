import { DAYJS, TimedChartConfig } from '../..';
import { D3DrawerData } from './d3-drawer-data.model';

export class D3DrawerChartState {
  config: TimedChartConfig;
  hostEl: HTMLElement;
  viewBoxWidth: number;
  viewBoxHeight: number;
  selectedDate: DAYJS;
  generatedCanvas: any;
  generatedValuesStorage: Map<string, any> = new Map();
  defaultData: D3DrawerData;
  seriesSpecificData: D3DrawerData[];
}
