import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ss-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.sass']
})
export class PageComponent implements OnInit {

  constructor() { }

  @Input()
  public modal = false;

  @Input()
  public short = false;

  @Output()
  scrollY: EventEmitter<number> = new EventEmitter();

  @Output()
  scrollX: EventEmitter<number> = new EventEmitter();

  ngOnInit() {
  }

  onScrollY(ev) {
    const v = parseInt(ev['target']['scrollTop'], 10);
    if (v >= 0) {
      this.scrollY.emit(v);
    }
  }

  onScrollX(ev) {
    const v = parseInt(ev['target']['scrollLeft'], 10);
    if (v >= 0) {
      this.scrollX.emit(v);
    }
  }
}
