<div class="row flex-nowrap justify-content-between align-items-center h-100" *ngIf="snowCannon">

  <div class="header">
    <ss-translated-device-type-symbol [device]="snowCannon"></ss-translated-device-type-symbol>
    <small *ngIf="snowCannon.remoteMeteoStationRef">
      (<ng-container i18n="korzysta z parametrów meteo z|fragment tekstu informujacego o tym z jakiego zrodla danych meteo korzysta armatka@@meteoBarHeaderSnowCannonUsesRemoteMeteoStation">korzysta z parametrów meteo z</ng-container>
      &nbsp;<ss-translated-device-type-symbol [device]="snowCannon.remoteMeteoStationRef"></ss-translated-device-type-symbol>)
    </small>
    <ss-meteo-conditions-display [sourceDevice]="snowCannon"></ss-meteo-conditions-display>
  </div>

  <div class="d-flex">
    <div class="d-flex flex-column align-items-end">
        <div class="item">
            <svg>
                <use xlink:href="#icon-tap"/>
            </svg>
        </div>
        <div class="item color-light-grey text-uppercase">
            <ng-container i18n="Hydrant|Etykieta hydrantu@@hydrantLabel">Hydrant</ng-container>:&nbsp;
            <span class="fs-14">
                {{ snowCannon.conenctionPointRef.symbol || '--' }}
            </span>
        </div>
        <div class="item">
            <ng-container i18n="Rurociąg|Etykieta rurociągu@@pipelineLabel">Rurociąg</ng-container>:
            <a [routerLink]="['/application/pump-rooms/detail', snowCannon.conenctionPointRef.waterPipelineRef.pumpRoomRef.id]"
                class="fs-14 color-light-blue pl-1 clickable">
                {{ snowCannon.conenctionPointRef.waterPipelineRef.symbol || '--' }}
            </a>
        </div>
        <div class="item">
            <ng-container i18n="Pompownia|Etykieta pompowni@@pumpRoomLabel">Pompownia</ng-container>:
            <a [routerLink]="['/application/pump-rooms/detail', snowCannon.conenctionPointRef.waterPipelineRef.pumpRoomRef.id]"
                class="fs-14 color-light-blue pl-1 clickable">
                {{ snowCannon.conenctionPointRef.waterPipelineRef.pumpRoomRef.symbol || '--' }}
            </a>
        </div>
    </div>
    <div class="pl-5 text-right">
        <p class="fs-16 color-white pb-5 pt-2 white-space-nowrap">
            <span class="pr-3">
              <ng-container *ngIf="snowCannon.CAP_SHOW_WATER_TEMPERATURE">
                {{ snowCannon.currentWaterPressure > 0 ? (snowCannon.waterTemperature|number:'0.1-1') : '--' }}&#176;C
              </ng-container>
            </span>
            <span class="pr-3">{{ (snowCannon.currentWaterPressure || 0)|number:'1.1-1' }} bar</span>
            <span>
              {{ snowCannon|convertedValue:'currentWaterFlow' }}
              {{ snowCannon|convertedUnit:'currentWaterFlow' }}
            </span>
        </p>
        <p class="fs-14 color-light-grey pb-1 white-space-nowrap">
            <span class="pr-3">{{ (snowCannon.conenctionPointRef.waterPipelineRef.currentWaterTemperature || 0)|number:'1.1-1' }}&#176;C</span>
            <span class="pr-3">{{ (snowCannon.conenctionPointRef.waterPipelineRef.currentWaterPressure || 0)|number:'1.1-1' }} bar</span>
            <span>{{ snowCannon.conenctionPointRef.waterPipelineRef|convertedValue:'currentWaterFlow' }} {{ snowCannon.conenctionPointRef.waterPipelineRef|convertedUnit:'currentWaterFlow' }}</span>
        </p>
        <p class="fs-14 color-light-grey white-space-nowrap">
          {{ snowCannon.conenctionPointRef.waterPipelineRef.pumpRoomRef|convertedValue:'computedWaterFlow' }} {{ snowCannon.conenctionPointRef.waterPipelineRef.pumpRoomRef|convertedUnit:'computedWaterFlow' }}
        </p>
    </div>
  </div>


</div>
