import { Component, Input, Output, EventEmitter, Inject, LOCALE_ID, OnChanges, SimpleChanges } from '@angular/core';
import {IAngularMyDpOptions, IMyDateModel, CalAnimation, DefaultView} from 'angular-mydatepicker';
import { dayjs, DAYJS } from '../../../../../../common';

@Component({
  selector: 'ss-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.sass']
})
export class RangeDatepickerComponent implements OnChanges {

  constructor(@Inject(LOCALE_ID) public locale) {}

  @Input()
  public selectedStartDate: string | Date | DAYJS;

  @Input()
  public selectedEndDate: string | Date | DAYJS;

  @Input()
  public min: string | Date | DAYJS;

  @Input()
  public max: string | Date | DAYJS;

  @Input()
  public disabled: boolean;

  @Output()
  public startDateChanged: EventEmitter<DAYJS> = new EventEmitter();

  @Output()
  public endDateChanged: EventEmitter<DAYJS> = new EventEmitter();

  public model: IMyDateModel;

  public options: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: 'yyyy-mm-dd',
    firstDayOfWeek: 'mo',
    sunHighlight: true,
    markCurrentDay: true,
    minYear: 2000,
    maxYear: 2100,
    showSelectorArrow: true,
    monthSelector: true,
    yearSelector: true,
    satHighlight: false,
    disableHeaderButtons: true,
    showWeekNumbers: false,
    disableUntil: {year: 0, month: 0, day: 0},
    disableSince: {year: 0, month: 0, day: 0},
    selectorHeight: '266px',
    selectorWidth: '266px',
    closeSelectorOnDateSelect: true,
    closeSelectorOnDocumentClick: true,
    showMonthNumber: true,
    appendSelectorToBody: false,
    focusInputOnDateSelect: true,
    dateRangeDatesDelimiter: ' - ',
    defaultView: DefaultView.Date,
    showFooterToday: false,
    calendarAnimation: {in: CalAnimation.Fade, out: CalAnimation.Fade },
    stylesData: {
      selector: 'dp1',
      styles: `
      .dp1 * {
        font-family: 'Montserrat', sans-serif;
      }
      .dp1 .myDpIcon {
        font-family: angular-mydatepicker;
      }
      .dp1 .myDpIconLeftArrow,
      .dp1 .myDpIconRightArrow,
      .dp1 .myDpHeaderBtn {
        color: #fff;
      }
      .dp1 .myDpHeaderBtn:focus,
      .dp1 .myDpMonthLabel:focus,
      .dp1 .myDpYearLabel:focus {
        color: #66afe9;
      }
      .dp1 .myDpDaycell:focus,
      .dp1 .myDpMonthcell:focus,
      .dp1 .myDpYearcell:focus {
        box-shadow: inset 0 0 0 1px #66afe9;
      }
      .dp1 .myDpSelector:focus {
        box-shadow: 0 0 0 0 transparent;
      }
      .dp1 .myDpSelectorArrow:after {
        border-color: rgba(108, 117, 125, 0);
        border-bottom-color: #343642;
      }
      .dp1 .myDpSelectorArrow:focus:before {
        border-bottom-color: #ADD8E6;
      }
      .dp1 .myDpCurrMonth,
      .dp1 .myDpMonthcell,
      .dp1 .myDpYearcell {
        color: #fff;
      }
      .dp1 .myDpDaycellWeekNbr {
        color: #fff;
        background-color: #343642;
      }
      .dp1 .myDpPrevMonth,
      .dp1 .myDpNextMonth {
        color: #bbb;
      }
      .dp1 .myDpWeekDayTitle {
        background-color: #343642;
        color: #fff;
      }
      .dp1 .myDpHeaderBtnEnabled:hover,
      .dp1 .myDpMonthLabel:hover,
      .dp1 .myDpYearLabel:hover,
      .dp1 .myDpFooterBtn:hover {
        color: #ddd;
      }
      .dp1 .myDpMarkCurrDay,
      .dp1 .myDpMarkCurrMonth,
      .dp1 .myDpMarkCurrYear {
        border-bottom: 0;
      }
      .dp1 .myDpTableSingleDay:hover,
      .dp1 .myDpTableSingleMonth:hover,
      .dp1 .myDpTableSingleYear:hover {
        background-color: #ddd;
        color: #000;
      }
      .dp1 .myDpDaycell,
      .dp1 .myDpMonthcell,
      .dp1 .myDpYearcell {
        background-color: #343642;
      }
      .dp1 .myDpRangeColor {
        background-color: #1F1F21;
        color: #00A1E4;
      }
      .dp1 .myDpSelectedDay,
      .dp1 .myDpSelectedMonth,
      .dp1 .myDpSelectedYear {
        background-color: #1F1F21;
        color: #00A1E4;
        box-shadow: inset 0 0 0 1px #00A1E4;
      }
      .dp1 .myDpSelector,
      .dp1 .myDpMonthYearSelBar,
      .dp1 .myDpFooterBar {
        background-color: #343642;
      }
      .dp1 .myDpDisabled {
        color: #A4A7AA;
        background: repeating-linear-gradient(-45deg, #343642 0, #1F1F21 1px, #343642 2px, #343642 13px);
      }
      .dp1 .myDpHighlight {
        color: 	#f00;
      }
      `
    }
  };

  public onDateChanged(event: IMyDateModel) {
    this.startDateChanged.emit(dayjs(event.dateRange.beginJsDate));
    this.endDateChanged.emit(dayjs(event.dateRange.endJsDate));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedStartDate
      || changes.selectedEndDate
    ) {
      let selectedStartDate: DAYJS = changes.selectedStartDate.currentValue;
      if (!dayjs.isDayjs(selectedStartDate)) {
        selectedStartDate = dayjs(selectedStartDate, 'YYYY-MM-DD');
      }
      let selectedEndDate: DAYJS = changes.selectedEndDate.currentValue;
      if (!dayjs.isDayjs(selectedEndDate)) {
        selectedEndDate = dayjs(selectedEndDate, 'YYYY-MM-DD');
      }

      this.model = {
        isRange: true,
        dateRange: {
          beginJsDate: selectedStartDate.toDate(),
          endJsDate: selectedEndDate.toDate()
        }
      };
    }

    if (changes.min) {
      let min: DAYJS = changes.min.currentValue;
      if (!dayjs.isDayjs(min)) {
        min = dayjs(min, 'YYYY-MM-DD');
      }
      this.options.minYear = min.year();
      min.subtract(1, 'day');
      this.options.disableUntil = {
        year: min.year(),
        month: min.month() + 1,
        day: min.date()
      };
    }
    if (changes.max) {
      let max: DAYJS = changes.max.currentValue;
      if (!dayjs.isDayjs(max)) {
        max = dayjs(max, 'YYYY-MM-DD');
      }
      max = max.add(1, 'miute').startOf('day');
      this.options.maxYear = max.year();
      max.add(1, 'day');
      this.options.disableSince = {
        year: max.year(),
        month: max.month() + 1,
        day: max.date()
      };
    }

  }
}
