<perfect-scrollbar>
  <div class="pt-2 pb-6 px-2">
    <div class="tabs mb-2">
      <ss-tabs>
        <ss-tab tabTitle="Podsumowanie"
          i18n-tabTitle="Podsumowanie|Nazwa zakładki z zestawieniem informacji nt. stoku@@areaTabsSummary">
          <div class="d-flex flex-wrap">
            <div class="tabs__content tabs__content--double">
              <div class="d-flex">

                <div class="main-mode-dropdown" [ngClass]="{'main-mode-dropdown--manual': !area.operationMode}">
                  <button [disabled]="area.isLocked" class="main-mode-dropdown__btn"
                    [ngClass]="{'main-mode-dropdown__btn--active': area['isModeDropdownExpanded']}"
                    (click)="toggleProperty(area, 'isModeDropdownExpanded')">
                    <span class="main-mode-dropdown__subheader"  i18n="Stokiem steruje|Etykieta wyboru trybu pracy stoku@@areaListOperationMode">Stokiem steruje</span>
                    <span *ngIf="area.operationMode" class="main-mode-dropdown__header" i18n="Snowmatic|Tryb pracy Snowmatic@@operationModeSnowmatic">Snowmatic</span>
                    <span *ngIf="!area.operationMode" class="main-mode-dropdown__header" i18n="Operator|Tryb pracy Operator@@operationModeOperator">Operator</span>
                    <svg class="main-mode-dropdown__angle"
                      [ngClass]="{'main-mode-dropdown__angle--rotated': area['isModeDropdownExpanded']}">
                      <use xlink:href='#icon-chevron-down' />
                    </svg>
                  </button>
                  <div class="main-mode-dropdown__menu"
                    [ngClass]="{'main-mode-dropdown__menu--expanded': area['isModeDropdownExpanded'], 'main-mode-dropdown__menu--auto': !area.operationMode}">
                    <button class="main-mode-dropdown__btn" (click)="setAreaOperationMode(area, !area.operationMode); toggleProperty(area, 'isModeDropdownExpanded')">
                      <span *ngIf="!area.operationMode" class="main-mode-dropdown__header" i18n="Snowmatic|Tryb pracy Snowmatic@@operationModeSnowmatic">Snowmatic</span>
                      <span *ngIf="area.operationMode" class="main-mode-dropdown__header" i18n="Operator|Tryb pracy Operator@@operationModeOperator">Operator</span>
                    </button>
                  </div>
                </div>

                <div class="mode-details d-flex flex-column justify-content-center"
                  [ngClass]="{'mode-details--manual': !area.operationMode}">
                  <ng-container *ngIf="area.operationMode; else manualSettings">
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="fs-14" i18n="Schemat pracy|Etykieta schematu pacy@@schemaLabel">Schemat pracy</p>
                      <button class="mode-details__edit-btn"
                        [routerLink]="['/application/weather-and-schedules/edit', currentSchedule.id]"
                        i18n="Edytuj|Edycja ustawień w trybie automatycznym@@areaButtonEdit">Edytuj</button>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between pt-3" *ngIf="currentSchedule">
                      <p class="fs-30">
                        {{ currentSchedule.schema.name }}
                      </p>
                      <p class="mode-details__setting d-flex align-items-center">
                        <svg>
                          <use xlink:href="#icon-termo-wet" />
                        </svg>
                        {{ currentSchedule.schema.startTemperature }}&#176;C
                      </p>
                      <p class="mode-details__setting d-flex align-items-center">
                        <svg>
                          <use xlink:href="#icon-time" />
                        </svg>
                        {{ closestScheduleHours }}
                      </p>
                    </div>
                  </ng-container>
                  <ng-template #manualSettings>
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <ng-container *ngIf="hasSnowCannonWithStartTemperature">
                          <p class="mode-details__header" i18n="Temperatura startu|Etykieta pola ustawiającego temperaturę startu@@areaStartTemperature">Temperatura startu</p>
                          <div class="mode-details__setting"  [ngClass]="{ 'mode-details__setting__small': hasSnowCannonWithStartFlow}">
                            <ng-container *ngIf="minSnowCannonStartTemperature === maxSnowCannonStartTemperature">
                              {{ minSnowCannonStartTemperature|number:'1.1-1' }}
                              <svg>
                                <use xlink:href='#icon-termo-wet' />
                              </svg>&#176;C
                            </ng-container>
                            <ng-container *ngIf="minSnowCannonStartTemperature < maxSnowCannonStartTemperature">
                              <small i18n="od|Etykieta poczatku przedzialu wartosci startowej@@areaStartParamFrom">od</small>&nbsp;
                              {{ minSnowCannonStartTemperature|number:'1.1-1' }}&nbsp;
                              <small i18n="do|Etykieta konca przedzialu wartosci startowej@@areaStartParamTo">do</small>&nbsp;
                              {{ maxSnowCannonStartTemperature|number:'1.1-1' }}
                              <svg>
                                <use xlink:href='#icon-termo-wet' />
                              </svg>&#176;C
                            </ng-container>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="hasSnowCannonWithStartFlow">
                          <p class="mode-details__header" i18n="Przeplyw startu|Etykieta pola ustawiającego przepływ startu@@areaStartFlow">Przeplyw startu</p>
                          <div class="mode-details__setting" [ngClass]="{ 'mode-details__setting__small': hasSnowCannonWithStartTemperature}">
                            <ng-container *ngIf="minSnowCannonStartFlow === maxSnowCannonStartFlow">
                              {{ minSnowCannonStartFlow|number:'1.1-1' }}
                              <svg>
                                <use xlink:href='#icon-tap' />
                              </svg>l/s
                            </ng-container>
                            <ng-container *ngIf="minSnowCannonStartFlow < maxSnowCannonStartFlow">
                              <small i18n="od|Etykieta poczatku przedzialu wartosci startowej@@areaStartParamFrom">od</small>&nbsp;
                              {{ minSnowCannonStartFlow|number:'1.1-1' }}
                              <small i18n="do|Etykieta konca przedzialu wartosci startowej@@areaStartParamTo">do</small>&nbsp;
                              {{ maxSnowCannonStartFlow|number:'1.1-1' }}
                              <svg>
                                <use xlink:href='#icon-tap' />
                              </svg>l/s
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                      <div>
                        <ss-control-button size="small"
                          [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
                          [disabled]="area.isLocked"
                          [action]="area.isAnyCannonStarted() ? 'stop' : 'start'"
                          (click)="toggleAreaStartStop(area, !area.isAnyCannonStarted())">
                          <ng-container *ngIf="area.isAnyCannonStarted()" i18n="Stop|Etykieta przycisku zatrzymującego@@controlButtonStop">Stop</ng-container>
                          <ng-container *ngIf="!area.isAnyCannonStarted()" i18n="Start|Etykieta przycisku uruchamiającego@@controlButtonStart">Start</ng-container>
                        </ss-control-button>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
              <div class="px-4 pb-2">

                <ss-area-cannons-status-display [area]="area"></ss-area-cannons-status-display>

              </div>
            </div>
            <div class="tabs__content tabs__content--double">
              <div class="border-bottom px-4">
                <ss-meteo-conditions-named-display
                  class="clickable"
                  (click)="openMeteoSelectorModal()"
                  [sourceId]="area.remoteMeteoStation"></ss-meteo-conditions-named-display>
              </div>
              <div class="row px-4 py-3">
                <div class="col-auto pr-4" style="min-width: 200px;">

                  <p class="window-overview__header"
                    i18n="Produkcja śniegu|Nazwa pola przedstawiającego wielkość produkcji śniegu@@snowProduction">Produkcja śniegu</p>
                  <p class="window-overview__text white-space-nowrap">
                    <span class="window-overview__text--xl color-green">{{ area|convertedValue:'snowProductionVolume' }}</span>
                    <span class="fs-20 color-mid-grey">{{ area|convertedUnit:'snowProductionVolume' }}</span>
                  </p>
                  <p class="window-overview__header pt-1" i18n="Zużycie wody|Nazwa pola przedstawiającego wielkość zużycie wody@@currentWaterFlowFieldTitle">Zużycie wody</p>
                  <p class="window-overview__text white-space-nowrap">

                    <span class="window-overview__text--xl color-mid-grey">
                      {{ area|convertedValue:'computedWaterFlow' }}
                    </span>
                    <span class="fs-20 color-mid-grey">{{ area|convertedUnit:'computedWaterFlow' }}</span>
                  </p>

                </div>
                <div class="col">
                  <ss-d3-snow-production-and-twb-chart [device]="area"
                    [suplementaryDevice]="area.remoteMeteoStationRef"
                    (durationChanged)="setDuration($event)"></ss-d3-snow-production-and-twb-chart>
                </div>
              </div>
              <div class="px-4 py-3">
                <ss-snow-quality [showIcons]="true" [isDisabled]="area.isLocked"
                  [disableSnowQualitySelection]="area.operationMode === 0"
                  [noOfDevicesBySnowQuality]="area.countBySnowQuality()" [currentSnowQuality]="area.snowQualityConstant"
                  [snowQualityConstant]="area.snowQualityConstant" [snowQualityMin]="area.snowQualityLow"
                  [snowQualityMax]="area.snowQualityHigh" [snowQualityType]="area.snowQualityType"
                  (snowQualityChange)="setAreaSnowQuality(area, $event)" size="large">
                </ss-snow-quality>
              </div>
            </div>
          </div>
        </ss-tab>

        <ss-area-stats-and-reports-tab
          [area]="area"
          [pumpRooms]="pumpRooms"
          [powerSources]="powerSources"
          [waterReservoirs]="waterReservoirs"
          tabTitle="Statystyki i raporty"
          i18n-tabTitle="Statystyki i raporty|Tytuł zakładki ze statystykami dot. danej armatki@@areaTabsTitleStats"></ss-area-stats-and-reports-tab>
        <ss-user-notes-tab
          [context]="area.id"
          tabTitle="Notatki"
          i18n-tabTitle="Notatki|Tytuł zakładki z notatkami dot. danej armatki@@areaTabsTitleNotes"></ss-user-notes-tab>
        <ss-documentation-tab
          [context]="area.id"
          [extraWide]="true"
          tabTitle="Dokumentacja"
          i18n-tabTitle="Dokumentacja|Tytuł zakładki z dokuemntacją dot. danej armatki@@areaTabsTitleDocumentation"></ss-documentation-tab>
        <ss-area-settings-tab
          *ssdHideIfNotGranted="'ROLE_OPERATOR'"
          [area]="area"
          tabTitle="Ustawienia"
          i18n-tabTitle="Ustawienia|Tytuł zakładki ze ustawieniami@@areaTabsTitleSettings"></ss-area-settings-tab>

        <ss-tab tabTitle="Debug" *ssdHideIfNotGranted="'ROLE_SUPERADMIN'">
          <div class="p-3 color-light-grey">
            <ss-debug-table [data]="area"></ss-debug-table>
          </div>
        </ss-tab>
        <ss-notifications-tab
          [context]="devIds"
          [extraWide]="true"
          tabTitle="Powiadomienia"
          i18n-tabTitle="Powiadomienia|Nazwa zakładki z listą powiadomień@@areaTabsAlerts"></ss-notifications-tab>

      </ss-tabs>
    </div>

    <table class="table mb-2">
      <thead>
        <tr class="table__row table__row--unhoverable">
          <td class="table__cell table__cell--rounded-top-left text-center px-4">
            <ss-checkbox [isChecked]="isEveryOneSelected" (hasChanged)="selectFromArea($event)"></ss-checkbox>
          </td>
          <td class="table__cell table__cell--rounded-top-right" colspan="15">
            <span class="d-inline-flex mr-2" *ngIf="selectedCannonsCount > 0" i18n="Wybrane armatki|Informacja o ilości zaznaczonych urządzeń w tabeli@@areaListSelectedCannons">
              Wybrano { selectedCannonsCount, plural,
                =0 { {{selectedCannonsCount}} armatek }
                =1 { {{selectedCannonsCount}} armatkę }
                =2 { {{selectedCannonsCount}} armatki }
                =3 { {{selectedCannonsCount}} armatki }
                =4 { {{selectedCannonsCount}} armatki }
                other { {{selectedCannonsCount}} armatek }
                }
            </span>

            <button class="rounded-btn d-inline-flex align-items-center"
              [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
              (click)="openMassOperations()"
              [disabled]="selectedCannonsCount <= 0">
              <ng-container i18n="Zmień ustawienia wielu armatek|Zmień ustawienia wielu armatek@@areaListMassOperations">Zmień ustawienia wielu armatek</ng-container>
              <svg class="rounded-btn__angle">
                <use xlink:href="#icon-chevron-right" />
              </svg>
            </button>
          </td>
        </tr>

      </thead>
      <thead>
        <tr class="table__row table__row--main" [ngClass]="{'table__row--collapsed': isTableCollapsed}">
          <td class="table__cell table__cell--rounded-top-left text-center px-4">
            <ss-checkbox [isChecked]="isEveryOneSelected" (hasChanged)="selectFromGroup($event)"></ss-checkbox>
          </td>
          <td class="table__cell pl-4" colspan="5">
            <span class="fs-30" i18n="Armatki w użyciu|Nagłówek tabeli@@areaListCannonsInUse">armatki w użyciu</span>
          </td>
          <td class="table__cell fs-20 pl-3 pr-2 white-space-nowrap color-green">
            {{ area|convertedValue:'snowProductionVolume' }}
            {{ area|convertedUnit:'snowProductionVolume' }}
          </td>
          <td class="table__cell">
            <div style="width: 140px">
              <ss-d3-linked-mini-bar-chart [viewBoxWidth]="140" [duration]="duration" [device]="area"></ss-d3-linked-mini-bar-chart>
            </div>
          </td>
          <td class="table__cell px-3 fs-20">
            <ng-container *ngIf="meteoStation">
              {{ meteoStation.wetBulbTemperature|number:'1.1-1' }}&#176;C
            </ng-container>
          </td>
          <td class="table__cell">
            <ng-container *ngIf="meteoStation">
              <ss-wind-speed-and-direction [windSpeed]="meteoStation.windSpeed"
                [windDirection]="meteoStation.windDirection"></ss-wind-speed-and-direction>
            </ng-container>
            <p class="color-mid-grey px-3 fs-14">
              {{ area|convertedValue:'computedWaterFlow' }}
              {{ area|convertedUnit:'computedWaterFlow' }}
            </p>
          </td>
          <td class="table__cell pr-2">
            <ss-snow-quality [isDisabled]="area.isLocked"
              [disableSnowQualitySelection]="area.operationMode === 0"
              [noOfDevicesBySnowQuality]="area.countBySnowQuality()" [snowQualityType]="area.snowQualityType"
              [currentSnowQuality]="area.snowQualityConstant" [snowQualityConstant]="area.snowQualityConstant"
              [snowQualityMin]="area.snowQualityLow" [snowQualityMax]="area.snowQualityHigh"
              (snowQualityChange)="setAreaSnowQuality(area, $event)"></ss-snow-quality>
          </td>
          <td class="table__cell"
            [ngClass]="{'table__cell--green': area.operationMode, 'table__cell--orange': !area.operationMode}">
            <div class="mode-dropdown mode-dropdown--large">
              <button [disabled]="area.isLocked" class="mode-dropdown__btn"
                [ngClass]="{'mode-dropdown__btn--active': area['isModeDropdownExpanded2']}"
                (click)="toggleProperty(area, 'isModeDropdownExpanded2')">
                <span *ngIf="area.operationMode" class="mode-dropdown__header" i18n="Snowmatic|Tryb pracy Snowmatic@@operationModeSnowmatic">Snowmatic</span>
                <span *ngIf="!area.operationMode" class="mode-dropdown__header" i18n="Operator|Tryb pracy Operator@@operationModeOperator">Operator</span>
                <svg class="mode-dropdown__angle"
                  [ngClass]="{'mode-dropdown__angle--rotated': area['isModeDropdownExpanded2']}">
                  <use xlink:href='#icon-chevron-down' />
                </svg>
              </button>
              <div class="mode-dropdown__menu"
                [ngClass]="{'mode-dropdown__menu--expanded': area['isModeDropdownExpanded2'], 'mode-dropdown__menu--auto': !area.operationMode}">
                <button class="mode-dropdown__btn" (click)="setAreaOperationMode(area, !area.operationMode); toggleProperty(area, 'isModeDropdownExpanded2')">
                  <span *ngIf="!area.operationMode"
                    class="mode-dropdown__header" i18n="Snowmatic|Tryb pracy Snowmatic@@operationModeSnowmatic">Snowmatic</span>
                  <span *ngIf="area.operationMode"
                    class="mode-dropdown__header" i18n="Operator|Tryb pracy Operator@@operationModeOperator">Operator</span>
                </button>
              </div>
            </div>
          </td>
          <td class="table__cell"
            [ngClass]="{'table__cell--orange': !area.operationMode}">
            <div class="d-flex align-items-center justify-content-between"  *ngIf="area.operationMode === 1">
              <ng-container *ngIf="currentSchedule">
                <span class="px-2 white-space-nowrap">
                  {{ currentSchedule.schema.name }}
                </span>
                <span class="px-2">{{ currentSchedule.schema.startTemperature }}&#176;C</span>
              </ng-container>
              <button class="color-light-blue fs-14"
                [routerLink]="['/application/weather-and-schedules/edit', currentSchedule.id]"
                i18n="Edytuj|Edycja ustawień w trybie automatycznym@@areaButtonEdit">Edytuj</button>
            </div>
            <div *ngIf="area.operationMode === 0" class="d-flex align-items-center justify-content-center">
              <ss-control-button [disabled]="area.isLocked"
                [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
                [action]="area.isAnyCannonStarted() ? 'stop' : 'start'"
                (click)="toggleAreaStartStop(area, !area.isAnyCannonStarted())" size="extra-small" class="px-4">
                {{ area.isAnyCannonStarted() ? 'Stop' : 'Start' }}
              </ss-control-button>
            </div>
          </td>

          <td colspan="2" class="table__cell table__cell--rounded-top-right">
            <button class="table__collapse-btn" (click)="isTableCollapsed = !isTableCollapsed">
              <svg class="table__collapse-angle" [ngClass]="{'table__collapse-angle--rotated': isTableCollapsed}">
                <use xlink:href='#icon-chevron-down' />
              </svg>
            </button>
          </td>
        </tr>
        <tr *ngIf="isTableCollapsed" class="table__space"></tr>
      </thead>
      <tbody class="table__body" [ngClass]="{'table__body--collapsed': isTableCollapsed}">
        <tr class="table__col-headings">
          <th>
            <div class="table__wrapper"></div>
          </th>
          <th (click)="setOrderBy('symbol')" class="table__cell table__cell--small table__cell--sortable text-center"
            [ngClass]="{'is-sorting': orderBy === 'symbol', 'is-asc': !orderByDirection}"
            i18n="nr|Nagłówek kolumny@@areaListTableHeaderNr">
            nr
          </th>
          <th (click)="setOrderBy('computedStatus', true)"
            class="table__cell table__cell--small table__cell--sortable text-center"
            [ngClass]="{'is-sorting': orderBy === 'computedStatus', 'is-asc': !orderByDirection}"
            i18n="stan|Nagłówek kolumny@@areaListTableHeaderState">
            stan
          </th>
          <th (click)="setOrderBy('cannonModel', true)" colspan="2"
            class="table__cell table__cell--small table__cell--sortable"
            [ngClass]="{'is-sorting': orderBy === 'cannonModel', 'is-asc': !orderByDirection}"
            i18n="typ i model|Nagłówek kolumny@@areaListTableHeaderType">
            typ i model
          </th>
          <th class="table__cell table__cell--small table__cell--sortable"
              (click)="setOrderBy('connectionPointSymbol')"
              [ngClass]="{'is-sorting': orderBy === 'connectionPointSymbol', 'is-asc': !orderByDirection}"
              i18n="hydrant|Nagłówek kolumny@@areaListTableHeaderHydrant">Hydrant
          </th>
          <th (click)="setOrderBy('snowProductionVolume')"
            class="table__cell table__cell--small white-space-nowrap table__cell--sortable px-3"
            [ngClass]="{'is-sorting': orderBy === 'snowProductionVolume', 'is-asc': !orderByDirection}" colspan="2">
            <ng-container i18n="produkcja śniegu|Nagłówek kolumny@@areaListTableHeaderSnowProduction">produkcja śniegu
            </ng-container> m<sup>3</sup>/h
          </th>
          <th (click)="setOrderBy('wetBulbTemperature')"
            class="table__cell table__cell--small px-3 table__cell--sortable text-right"
            [ngClass]="{'is-sorting': orderBy === 'wetBulbTemperature', 'is-asc': !orderByDirection}"
            i18n="twb|Nagłówek kolumny@@areaListTableHeaderTWB">
            twb
          </th>
          <th (click)="setOrderBy('currentWaterFlow')"
            class="table__cell table__cell--small px-3 table__cell--sortable"
            [ngClass]="{'is-sorting': orderBy === 'currentWaterFlow', 'is-asc': !orderByDirection}"
            i18n="woda|Nagłówek kolumny@@areaListTableHeaderWater">
            woda
          </th>
          <th (click)="setOrderBy('snowQuality')" width="255px"
            class="table__cell table__cell--small table__cell--sortable text-right"
            [ngClass]="{'is-sorting': orderBy === 'snowQuality', 'is-asc': !orderByDirection}"
            i18n="jakość śniegu|Nagłówek kolumny@@areaListTableHeaderSnowQuality">
            jakość śniegu
          </th>
          <th (click)="setOrderBy('operationMode')"
            class="table__cell table__cell--small table__cell--sortable text-center"
            [ngClass]="{'is-sorting': orderBy === 'operationMode', 'is-asc': !orderByDirection}"
            i18n="armatką steruje|Nagłówek kolumny@@areaListTableHeaderOperationMode">
            armatką steruje
          </th>
          <th (click)="setOrderBy('startTemperature')"
            class="table__cell table__cell--small table__cell--sortable text-center"
            [ngClass]="{'is-sorting': orderBy === 'startTemperature', 'is-asc': !orderByDirection}"
            i18n="zakres|Nagłówek kolumny@@areaListTableHeaderRange">
            zakres
          </th>
          <th>
            <div class="table__wrapper"></div>
          </th>
          <th>
            <div class="table__wrapper"></div>
          </th>
        </tr>

        <ng-container
          *ngFor="let device of orderedSnowCannons; trackBy: trackByFn; let first = first; let last = last"
          [ngTemplateOutlet]="device.connectionStatus ? (device.operationMode ? tableRowConnectedAuto : tableRowConnectedManual) : tableRowDisconnected"
          [ngTemplateOutletContext]="{device: device, first: first, last: last}">
        </ng-container>
      </tbody>
    </table>
  </div>
</perfect-scrollbar>


<ng-template #tableRowConnectedAuto let-device="device" let-first="first" let-last="last">
  <tr class="table__row"
    [ngClass]="{'table__row--first': first, 'table__row--last': last, 'table__row--alert': device.isComputedStatusBreakdown() }"
    (mouseenter)="addShadows($event)" (mouseleave)="removeShadows($event)">
    <td class="table__cell table__cell--bordered-right text-center px-4">
      <div class="table__wrapper">
        <ss-checkbox [isChecked]="getSelectionVal(device.id)"
          (hasChanged)="selectFromSnowCannons($event, device.id)"
          [color]="device.isComputedStatusBreakdown() ? 'danger' : ''"></ss-checkbox>
      </div>
    </td>
    <td class="table__cell text-center fs-22 px-3">
      <div [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="table__wrapper clickable">{{ device.symbol }}</div>
    </td>
    <td class="table__cell text-center px-3">
      <div [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="table__wrapper clickable">
        <ss-snow-cannon-status size="small" [state]="device.computedStatus"></ss-snow-cannon-status>
      </div>
    </td>
    <td class="table__cell table__cell--bordered-both table__cell--dark text-center table__image">
      <div [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="table__wrapper clickable table__steering-is-local">
        <!-- <img class="table__image " src="{{ device|snowCannonImage }}"> -->
        <ss-device-image [device]="device"></ss-device-image>
        <div class="steering-is-local-overlay" *ngIf="device.isInLocalMode">
          <svg>
            <use xlink:href='#icon-lock' />
          </svg>
        </div>
      </div>
    </td>
    <td class="table__cell table__cell--water px-3">
      <div [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="table__wrapper clickable">
          {{ device.cannonModel }}<br />
          <span *ngIf="device.serialNumber">{{ device.serialNumber }}</span>
        </div>
    </td>
    <td class="table__cell color-mid-grey px-3">
      <div [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="table__wrapper text-center clickable">
        {{ device.connectionPointSymbol }}
        <s class="color-red" *ngIf="device.connectionPointSymbol !== device.hydrantNumber">({{ device.hydrantNumber }})</s>
      </div>
    </td>
    <td class="table__cell table__cell--snow-production-volume pl-3 pr-2 white-space-nowrap">
      <div class="table__wrapper" *ngIf="device.CAP_SHOW_SNOW_PRODUCTION">
        {{ device|convertedValue:'currentSnowProductionVolume' }}
        {{ device|convertedUnit:'currentSnowProductionVolume' }}
      </div>
    </td>
    <td class="table__cell pr-3">
      <div class="table__wrapper">
        <div style="width: 140px" *ngIf="device.CAP_SHOW_SNOW_PRODUCTION">
          <ss-d3-linked-mini-bar-chart [viewBoxWidth]="140" tag="compare-snow-cannons" [duration]="duration" [device]="device"></ss-d3-linked-mini-bar-chart>
        </div>
      </div>
    </td>
    <td class="table__cell px-3">
      <div class="table__wrapper text-right">
        <span class="color-green">{{ device.wetBulbTemperature|number:'1.1-1' }}&#176;C</span><br />
        <span class="color-mid-grey fs-14" *ngIf="!device.remoteMeteoStationRef">{{ device.airHumidity|number:'1.0-0' }}%</span>
        <span class="color-mid-grey fs-10 color-red" *ngIf="device.remoteMeteoStationRef">
          (<ng-container i18n="TWB z|Napis w liście przy zdalnych parametrach meteo@@areaListTableDeviceRemoteMeteoFromMeteoStation">TWB z</ng-container>
          &nbsp;<ss-translated-device-type-symbol [device]="device.remoteMeteoStationRef"></ss-translated-device-type-symbol>)
        </span>
      </div>
    </td>
    <td class="table__cell table__cell--water px-3">
      <div class="table__wrapper">
        {{ device.currentWaterPressure|number:'1.1-1' }}&nbsp;bar<br />
        <span>
          {{ device|convertedValue:'currentWaterFlow' }}
          {{ device|convertedUnit:'currentWaterFlow' }}
        </span>
      </div>
    </td>
    <td class="table__cell pl-4 pr-2">
      <div class="table__wrapper">
        <ng-container *ngIf="device.CAP_CHANGE_SNOWQUALITY">
          <ss-snow-quality (snowQualityChange)="setCannonSnowQuality(device, $event)"
            [disableSnowQualitySelection]="false"
            [isDisabled]="device.isLocked" [snowQualityType]="device.snowQualityType"
            [currentSnowQuality]="device.snowQuality" [snowQualityConstant]="device.snowQualityConstant"
            [snowQualityMax]="device.snowQualityHigh" [snowQualityMin]="device.snowQualityLow" size="small">
          </ss-snow-quality>
        </ng-container>
      </div>
    </td>
    <td class="table__cell table__cell--green">
      <div class="table__wrapper">
        <div class="mode-dropdown">
          <button [disabled]="device.isLocked" class="mode-dropdown__btn"
            [ngClass]="{'mode-dropdown__btn--active': device.isModeDropdownExpanded}"
            (click)="toggleProperty(device, 'isModeDropdownExpanded')">
            <span class="mode-dropdown__header" i18n="Snowmatic|Tryb pracy Snowmatic@@operationModeSnowmatic">Snowmatic</span>
            <svg class="mode-dropdown__angle"
              [ngClass]="{'mode-dropdown__angle--rotated': device.isModeDropdownExpanded}">
              <use xlink:href='#icon-chevron-down' />
            </svg>
          </button>
          <div class="mode-dropdown__menu"
            [ngClass]="{'mode-dropdown__menu--expanded': device.isModeDropdownExpanded}">
            <button class="mode-dropdown__btn" (click)="setSnowCannonOperationMode(device, !device.operationMode); toggleProperty(device, 'isModeDropdownExpanded')">
              <span
                class="mode-dropdown__header" i18n="Operator|Tryb pracy Operator@@operationModeOperator">Operator</span>
            </button>
          </div>
        </div>
      </div>
    </td>
    <td class="table__cell table__cell--dark-green">
      <div class="table__wrapper">
        <div class="d-flex align-items-center justify-content-between">
          <span class="pl-4 pr-2 white-space-nowrap">
            {{ closestScheduleHours }}
          </span>
          <span class="px-2 white-space-nowrap">
            {{ device.startTemperature|number:'1.1-1' }}&#176;C
          </span>
        </div>
      </div>
    </td>
    <ng-container [ngTemplateOutlet]="tableCellDeviceComponentsAndGotoLink"
      [ngTemplateOutletContext]="{ device: device }"></ng-container>
  </tr>
</ng-template>


<ng-template #tableRowConnectedManual let-device="device"  let-first="first" let-last="last">
  <tr class="table__row"
    [ngClass]="{'table__row--first': first, 'table__row--last': last, 'table__row--alert': device.isComputedStatusBreakdown() }"
    (mouseenter)="addShadows($event)" (mouseleave)="removeShadows($event)">
    <td class="table__cell table__cell--bordered-right text-center px-4">
      <div class="table__wrapper">
        <ss-checkbox [isChecked]="getSelectionVal(device.id)"
          (hasChanged)="selectFromSnowCannons($event, device.id)"
          [color]="device.isComputedStatusBreakdown() ? 'danger' : ''"></ss-checkbox>
      </div>
    </td>
    <td class="table__cell text-center fs-22 px-3">
      <div [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="table__wrapper clickable">{{ device.symbol }}</div>
    </td>
    <td class="table__cell text-center px-3">
      <div [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="table__wrapper clickable">
        <ss-snow-cannon-status size="small" [state]="device.computedStatus"></ss-snow-cannon-status>
      </div>
    </td>
    <td class="table__cell table__cell--bordered-both table__cell--dark text-center table__image">
      <div [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="table__wrapper clickable table__steering-is-local">
        <!-- <img class="table__image " src="{{ device|snowCannonImage }}"> -->
        <ss-device-image [device]="device"></ss-device-image>
        <div class="steering-is-local-overlay" *ngIf="device.isInLocalMode">
          <svg>
            <use xlink:href='#icon-lock' />
          </svg>
        </div>
      </div>
    </td>
    <td class="table__cell table__cell--water px-3">
      <div [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="table__wrapper clickable">
          {{ device.cannonModel }}<br />
          <span  *ngIf="device.serialNumber">{{ device.serialNumber }}</span>
        </div>
    </td>
    <td class="table__cell color-mid-grey px-3">
      <div [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="table__wrapper text-center clickable">
        {{ device.connectionPointSymbol }}
        <s class="color-red" *ngIf="device.connectionPointSymbol !== device.hydrantNumber">({{ device.hydrantNumber }})</s>
      </div>
    </td>
    <td class="table__cell table__cell--snow-production-volume pl-3 pr-2 white-space-nowrap">
      <div class="table__wrapper" *ngIf="device.CAP_SHOW_SNOW_PRODUCTION">
        {{ device|convertedValue:'currentSnowProductionVolume' }}
        {{ device|convertedUnit:'currentSnowProductionVolume' }}
      </div>
    </td>
    <td class="table__cell pr-3">
      <div class="table__wrapper" *ngIf="device.CAP_SHOW_SNOW_PRODUCTION">
        <div style="width: 140px">
          <ss-d3-linked-mini-bar-chart [viewBoxWidth]="140" tag="compare-snow-cannons" [duration]="duration" [device]="device"></ss-d3-linked-mini-bar-chart>
        </div>
      </div>
    </td>
    <td class="table__cell px-3 ">
      <div class="table__wrapper text-right">
        <span class="color-green">{{ device.wetBulbTemperature|number:'1.1-1' }}&#176;C</span><br />
        <span class="color-mid-grey fs-14" *ngIf="!device.remoteMeteoStationRef">{{ device.airHumidity|number:'1.0-0' }}%</span>
        <span class="color-mid-grey fs-10 color-red" *ngIf="device.remoteMeteoStationRef">
          (<ng-container i18n="TWB z|Napis w liście przy zdalnych parametrach meteo@@areaListTableDeviceRemoteMeteoFromMeteoStation">TWB z</ng-container>
          &nbsp;<ss-translated-device-type-symbol [device]="device.remoteMeteoStationRef"></ss-translated-device-type-symbol>)
        </span>
      </div>
    </td>
    <td class="table__cell table__cell--water px-3">
      <div class="table__wrapper">
        {{ device.currentWaterPressure|number:'1.1-1' }}&nbsp;bar<br />
        <span>
          {{ device|convertedValue:'currentWaterFlow' }}
          {{ device|convertedUnit:'currentWaterFlow' }}
        </span>
      </div>
    </td>
    <td class="table__cell pl-4 pr-2">
      <div class="table__wrapper">
        <ng-container *ngIf="device.CAP_CHANGE_SNOWQUALITY">
          <ss-snow-quality (snowQualityChange)="setCannonSnowQuality(device, $event)"
            [disableSnowQualitySelection]="true"
            [isDisabled]="device.isLocked" [snowQualityType]="device.snowQualityType"
            [currentSnowQuality]="device.snowQuality" [snowQualityConstant]="device.snowQualityConstant"
            [snowQualityMax]="device.snowQualityHigh" [snowQualityMin]="device.snowQualityLow" size="small">
          </ss-snow-quality>
        </ng-container>
      </div>
    </td>
    <td class="table__cell table__cell--orange">
      <div class="table__wrapper">
        <div class="mode-dropdown mode-dropdown--manual">
          <button [disabled]="device.isLocked" class="mode-dropdown__btn"
            [ngClass]="{'mode-dropdown__btn--active': device.isModeDropdownExpanded}"
            (click)="toggleProperty(device, 'isModeDropdownExpanded')">
            <span class="mode-dropdown__header" i18n="Operator|Tryb pracy Operator@@operationModeOperator">Operator</span>
            <svg class="mode-dropdown__angle"
              [ngClass]="{'mode-dropdown__angle--rotated': device.isModeDropdownExpanded}">
              <use xlink:href='#icon-chevron-down' />
            </svg>
          </button>
          <div class="mode-dropdown__menu mode-dropdown__menu--auto"
            [ngClass]="{'mode-dropdown__menu--expanded': device.isModeDropdownExpanded}">
            <button class="mode-dropdown__btn" (click)="setSnowCannonOperationMode(device, !device.operationMode); toggleProperty(device, 'isModeDropdownExpanded')">
              <span class="mode-dropdown__header" i18n="Snowmatic|Tryb pracy Snowmatic@@operationModeSnowmatic">Snowmatic</span>
            </button>
          </div>
        </div>
      </div>
    </td>
    <td class="table__cell table__cell--orange">
      <div class="table__wrapper">
        <div class="d-flex justify-content-center align-items-center">

          <ng-container *ngIf="device.CAP_CHANGE_START_TEMPERATURE">
            <ss-number-input size="small" [min]="-15" [max]="2" [step]="0.1"
              [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
              [disabled]="device.isLocked"
              [inputValue]="device.startTemperature"
              (changed)="setSnowCannonStartTemperature(device, $event)">
            </ss-number-input>
          </ng-container>

          <ng-container *ngIf="device.CAP_RUN_START_STOP_SNOWCANNON">
            <ss-control-button size="extra-small"
              [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
              [disabled]="device.isLocked || device.isInLocalMode"
              [action]="device.commandStatusCannonStart ? 'stop' : 'start'"
              (click)="toggleSnowCannonStartStop(device)"
              class="px-4">
              {{ device.commandStatusCannonStart ? 'Stop' : 'Start' }}
            </ss-control-button>
          </ng-container>

        </div>
      </div>
    </td>
    <ng-container [ngTemplateOutlet]="tableCellDeviceComponentsAndGotoLink"
      [ngTemplateOutletContext]="{ device: device }"></ng-container>
  </tr>
</ng-template>


<ng-template #tableRowDisconnected let-device="device" let-first="first" let-last="last">
  <tr class="table__row"
    [ngClass]="{'table__row--first': first, 'table__row--last': last }"
    (mouseenter)="addShadows($event)" (mouseleave)="removeShadows($event)">
    <td class="table__cell table__cell--bordered-right text-center px-4"></td>
    <td class="table__cell text-center fs-22 px-3">
      <div [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="table__wrapper clickable">{{ device.symbol }}</div>
    </td>
    <td class="table__cell text-center px-3">
      <div [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="table__wrapper clickable">
        <ss-snow-cannon-status size="small" [state]="device.computedStatus"></ss-snow-cannon-status>
      </div>
    </td>
    <td class="table__cell table__cell--bordered-both table__cell--dark text-center table__image">
      <div [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="table__wrapper clickable table__steering-is-local">
        <!-- <img class="table__image " src="{{ device|snowCannonImage }}"> -->
        <ss-device-image [device]="device"></ss-device-image>
      </div>
    </td>
    <td class="table__cell table__cell--water px-3">
      <div [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="table__wrapper clickable">
          {{ device.cannonModel }}<br />
          <span *ngIf="device.serialNumber">{{ device.serialNumber }}</span>
        </div>
    </td>
    <td class="table__cell color-mid-grey px-3">
      <div [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="table__wrapper text-center clickable">{{ device.connectionPointSymbol }}</div>
    </td>
    <td class="table__cell">
      <div class="table__wrapper"></div>
    </td>
    <td class="table__cell">
      <div class="table__wrapper"></div>
    </td>
    <td class="table__cell">
      <div class="table__wrapper"></div>
    </td>
    <td class="table__cell">
      <div class="table__wrapper"></div>
    </td>
    <td class="table__cell">
      <div class="table__wrapper"></div>
    </td>
    <td class="table__cell">
      <div class="table__wrapper"></div>
    </td>
    <td class="table__cell">
      <div class="table__wrapper"></div>
    </td>
    <td class="table__cell">
      <div class="table__wrapper"></div>
    </td>
    <td class="table__cell table__cell--light fs-14">
        <a title="Szczegóły"
          i18n-title="Szczegóły|Przycisk prowadzący do danego urządzenia@@areaListTableDeviceDetails"
          [queryParams]="{ fromList: true }"
          [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
          class="table__details-link">
          <svg class="table__details-icon">
            <use xlink:href='#icon-chevron-right' />
          </svg>
        </a>
    </td>
  </tr>
</ng-template>

<ng-template #tableCellDeviceComponentsAndGotoLink let-device="device">

  <td class="table__cell table__cell--light fs-14">
    <div class="table__wrapper table__details-link d-flex align-items-center justify-content-between">
      <ss-snow-cannon-component-button-heater
        [snowCannon]="device"
        [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
        class="mr-1" size="extra-small"></ss-snow-cannon-component-button-heater>
      <ss-snow-cannon-component-button-lamp
        [snowCannon]="device"
        [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
        class="mr-1" size="extra-small"></ss-snow-cannon-component-button-lamp>
      <ss-snow-cannon-component-button-oscillator
        [snowCannon]="device"
        [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
        class="mr-1" size="extra-small"></ss-snow-cannon-component-button-oscillator>
    </div>
  </td>
  <td class="table__cell table__cell--light fs-14">
    <div class="table__wrapper table__details-link d-flex align-items-center justify-content-between">
      <a title="Szczegóły"
        i18n-title="Szczegóły|Przycisk prowadzący do danego urządzenia@@areaListTableDeviceDetails"
        [queryParams]="{ fromList: true }"
        [routerLink]="['/application/resort/area', area.id, 'map', device.id]"
        class="d-flex align-items-center justify-content-end">
        <svg class="table__details-icon">
          <use xlink:href='#icon-chevron-right' />
        </svg>
      </a>
    </div>
  </td>
</ng-template>