import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
    handleError(error: Error) {
        console.error('Error', error);

        if (environment.production) {
            const data = btoa(error.message + '\n' + error.stack);
            const displayData = data.match(/.{1,80}/g).join('\n');

            window.document.getElementById('error-dump').textContent = displayData;
            window.document.getElementById('error-send')['href'] =
                'mailto:ocwypyziuleh@gmail.com?subject=SnowmaticV4 Encoded error&body=' + data;
            window.document.getElementById('error-reporting').style.display = 'block';
        }
    }
}
