import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SettingsService } from '../../../../settings.service';
import { DAYJS, dayjs, ReportDefinitionDuration, ReportDefinitionPrecision, ReportResult } from '../../../../../../../common';

interface PredefinedRagneInterface {
  label?: string;
  from: DAYJS;
  to: DAYJS;
  months?: PredefinedRagneInterface[];
}

@Component({
  selector: 'ss-reporting-single-report-page-toolbar',
  templateUrl: './page-toolbar.component.html',
  styleUrls: ['./page-toolbar.component.sass']
})
export class ReportingSingleReportPageToolbarComponent {

  @Output()
  public updateResult: EventEmitter<any> = new EventEmitter();

  @Output()
  public updateView: EventEmitter<any> = new EventEmitter();

  @Input()
  public isLoading = false;

  @Input()
  public get result(): ReportResult {
    return this._result;
  };
  public set result(v: ReportResult) {
    this.overallDuration = Math.round(v.to.diff(v.from, 'd', true));
    this.duration = v.duration;
    this.precision = v.precision;
    this.from = v.from;
    this.to = v.to;
    this._result = v;
  }
  private _result: ReportResult;

  public isShowDateRagneToolbarVisible = false;

  public duration: ReportDefinitionDuration;
  public precision: ReportDefinitionPrecision;
  public from: DAYJS;
  public to: DAYJS;
  public overallDuration = 7;

  public selectedDate: DAYJS;

  public minStartDate: DAYJS;
  public maxEndDate: DAYJS;

  public selectedRange: PredefinedRagneInterface;
  public predefinedReportRanges: {
    seasons: PredefinedRagneInterface[];
    years: PredefinedRagneInterface[];
    lastUsed: PredefinedRagneInterface[];
    lastWeek: PredefinedRagneInterface;
    lastMonth: PredefinedRagneInterface;
    currentSeason: PredefinedRagneInterface;
    currentMonth: PredefinedRagneInterface;
    previousMonth: PredefinedRagneInterface;
  };

  public constructor(
    private settings: SettingsService
  ) {
    this.minStartDate = dayjs(1000 * this.settings.get<number>('timestampOfOldestStatusEntry')).startOf('day');
    this.maxEndDate = dayjs().endOf('day');
    this.predefinedReportRanges = {
      seasons: [],
      years: [],
      lastWeek: {
        from: dayjs().subtract(6, 'days').startOf('day'),
        to: dayjs().endOf('day')
      },
      lastMonth: {
        label: '',
        from: dayjs().subtract(1, 'month').startOf('day'),
        to: dayjs().endOf('day')
      },
      currentSeason: null,
      currentMonth: {
        from: dayjs().startOf('month'),
        to: dayjs().endOf('month')
      },
      previousMonth: {
        from: dayjs().subtract(1, 'month').startOf('month'),
        to: dayjs().subtract(1, 'month').endOf('month')
      },
      lastUsed: this.getLastUsedCustomDates()
    };

    const start = this.minStartDate.startOf('month');
    let to = dayjs();
    let from = dayjs();
    if (from.month() < 8) {
      from = from.subtract(1, 'year');
    }

    while (
      start.isSameOrBefore(from)
      || (
        start.isSameOrAfter(from)
        && start.isSameOrBefore(to)
      )
    ) {
      from = from.day(15).month(8).startOf('month');
      to = from.add(1, 'year').day(15).month(3).endOf('month');

      const season = {
        from,
        to,
        label: `${from.tz().format('YYYY')}/${to.tz().format('YYYY')}`,
        months: this.calcMonthsForRange(from, to, 'MMM YYYY')
      };
      this.predefinedReportRanges.seasons.push(season);

      if (dayjs().isBetween(season.from, season.to)) {
        this.predefinedReportRanges.currentSeason = season;
      }

      from = from.subtract(1, 'year');
      to = to.subtract(1, 'year');
    }

    from = dayjs();
    while (start.isSameOrBefore(from)) {
      from = from.startOf('year');
      to = from.endOf('year');
      this.predefinedReportRanges.years.push({
        from,
        to,
        label: `${from.tz().format('YYYY')}`,
        months: this.calcMonthsForRange(from, to)
      });
      from = from.subtract(1, 'year');
    }
  }

  private getLastUsedCustomDates(): PredefinedRagneInterface[] {
    const datePairs = this.settings.get<string[]>('recentReportManualDateRanges');
    return datePairs.map(el => {
      const split = el.split(' - ');
      return {
        label: el,
        from: dayjs(split[0], 'YYYY-MM-DD').startOf('day'),
        to: dayjs(split[1], 'YYYY-MM-DD').endOf('day')
      };
    });
  }

  private calcMonthsForRange(from: DAYJS, to: DAYJS, format: string = 'MMM'): PredefinedRagneInterface[] {
    const ret = [];
    let start = from.startOf('month');
    while (start.isBefore(to)) {
      ret.push({
        label: start.tz().format(format),
        from: start.startOf('month'),
        to: start.endOf('month')
      });
      start = start.add(1, 'month');
    }
    return ret;
  }

  public fromDateChanged(from: DAYJS) {
    this.from = from;
  }

  public toDateChanged(to: DAYJS) {
    this.to = to;
  }

  public setCustomDate(range: PredefinedRagneInterface) {
    this.settings.append('recentReportManualDateRanges',
      range.from.tz().format('YYYY-MM-DD') + '-' + range.to.tz().format('YYYY-MM-DD'),
    3);
    this.predefinedReportRanges.lastUsed = this.getLastUsedCustomDates();
    this.changeDate(range);
  }

  public changeDate(range: PredefinedRagneInterface) {
    this.selectedDate = undefined;
    this.isShowDateRagneToolbarVisible = !!range.months;
    this.selectedRange = range;

    let from = range.from;
    let to = range.to;
    from = this.minStartDate.isAfter(from) ? this.minStartDate : from;
    to = this.maxEndDate.isBefore(to) ? this.maxEndDate : to;
    from = from.startOf('day');
    to = to.endOf('day');

    this.updateResult.emit({ from, to });
  }

  public changePrecision(prec: ReportDefinitionPrecision) {
    this.precision = prec;
    this.updateResult.emit({ precision: prec });
  }

  public changeDuration(dura: ReportDefinitionDuration) {
    this.duration = dura;
    this.updateView.emit({ duration: dura });
  }

  public changeLiveReload(isLive: boolean) {
    this.updateView.emit({ isLive });
  }

}
