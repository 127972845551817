import { Component } from './component.model';
import { SimplifiedSchema } from './simplified-schema.model';
import { PumpRoom } from '../pump-room.model';
import { FullSchema } from './full-schema.model';

export class PumpRoomConfiguration {
    components: Component[];

    fullSchemaEntries: any;
    simplifiedSchemaEntries: any;

    fullSchema: FullSchema;
    simplifiedSchema: SimplifiedSchema;

    componentBySymbol: Map<string, Component> = new Map();

    public static create(pumpRoom: PumpRoom, data: any[]): PumpRoomConfiguration {
        let resp = new PumpRoomConfiguration();
        resp = Object.assign(resp, data);

        if (resp.components) {
            for (const compDatsKey of Object.keys(resp.components)) {
                const comp = Component.create(pumpRoom, resp.components[compDatsKey]);

                resp.componentBySymbol.set(comp.symbol, comp);
            }

            resp.simplifiedSchema = SimplifiedSchema.create(resp.simplifiedSchemaEntries || [], resp.componentBySymbol);
            resp.fullSchema = FullSchema.create(resp.fullSchemaEntries || [], resp.componentBySymbol);
        }

        return resp;
    }

    getComponentBySymbol(symbol: string): Component {
        return this.componentBySymbol.get(symbol);
    }
}
