import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { drawGridHorizontal } from './draw-grid-horizontal.function';
import { drawGridVertical } from './draw-grid-vertical.function';

export const drawGrid = (state: D3DrawerChartState): D3DrawerChartState => {
  if(state.config.grid) {
    state = drawGridVertical(state);
  }

  state.config.series.forEach((series, seriesIndex) => {
    if(!series.grid) {
      return;
    }

    const data = state.seriesSpecificData[seriesIndex]
      ? state.seriesSpecificData[seriesIndex]
      : state.defaultData;

    switch(series.seriesType) {
      case 'bar-w-rounded-top':
      case 'line-w-rounded-end':
      case 'bar':
      case 'line':
      case 'line-stepped':
      case 'point':
        state = drawGridHorizontal(state, data, series, seriesIndex);
        break;
    }
  });
  return state;
};
