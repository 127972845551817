import { Component, Input, OnInit } from '@angular/core';
import { translateDeviceType } from '../../../../../../common';

@Component({
  selector: 'ss-translated-device-type',
  template: '{{ text }}',
  styleUrls: []
})
export class TranslatedDeviceTypeComponent{

  public text = '';

  private _systemType: string;
  @Input()
  public get systemType(): string {
    return this._systemType;
  }
  public set systemType(v: string) {
    this._systemType = v;
    this.text = translateDeviceType(v as any);
  }

}
