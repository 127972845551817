import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { ResortService } from 'src/app/resort.service';
import { Device, isArray } from '../../../../../../common';
import { Alert, ElectricalLine, PumpRoom, SnowCannon, WaterPipeline, WorkingArea } from '../../models';

@Component({
  selector: 'ss-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.sass']
})
export class NotificationListComponent implements OnInit, OnDestroy {

  @Output()
  public clickedId: EventEmitter<string[]> = new EventEmitter();

  @Output()
  public alertCount: EventEmitter<number> = new EventEmitter();

  @Output()
  public alertLevel: EventEmitter<string> = new EventEmitter();

  @Input()
  public allowDynamicLoading = false;
  @Input()
  public compact = false;

  private _context: string|string[] = [];
  @Input()
  public get context(): string|string[] {
    return this._context;
  }
  public set context(v: string|string[]) {
    this._context = v;
    this.refreshAlertList();
  }

  private _levels: string|string[] = [];
  @Input()
  public get levels(): string|string[] {
    return this._levels;
  }
  public set levels(v: string|string[]) {
    this._levels = v;
    this.refreshAlertList();
  }

  private _onlyActive = false;
  @Input()
  public get onlyActive(): boolean {
    return this._onlyActive;
  }
  public set onlyActive(v: boolean) {
    this._onlyActive = v;
    this.refreshAlertList();
  }

  private onAlertSubscription: Subscription;

  public isInProgress = '';
  public shouldHideFilterLinks = false;
  public isShowingOnlyOneSnowCannon = false;

  constructor(
    private cs: ConfigureClientService,
    private rs: ResortService
  ) { }

  private alerts: Alert[] = [];
  public visibleAlerts: Alert[] = [];

  ngOnInit(): void {
    this.shouldHideFilterLinks = this.clickedId.observers.length <= 0;

    this.refreshAlertList();
    this.onAlertSubscription = this.rs.onAlerts.subscribe(() => {
      this.refreshAlertList();
    });
  }

  ngOnDestroy(): void {
    if (this.onAlertSubscription) {
      this.onAlertSubscription.unsubscribe();
    }
  }

  private configureSnowCannon(snowCannon: SnowCannon, data: any) {
    this.isInProgress = snowCannon.id;
    this.cs.configureSnowCannon(snowCannon, data).subscribe(() => {
      this.isInProgress = '';
    }, () => {
      this.isInProgress = '';
    });
  }
  private configurePumpRoom(pr: PumpRoom, data: any) {
    this.isInProgress = pr.id;
    this.cs.configurePumpRoom(pr, data).subscribe(() => {
      this.isInProgress = '';
    }, () => {
      this.isInProgress = '';
    });
  }

  public resetSnowCannonAlerts(snowCannon: any) {
    this.configureSnowCannon(snowCannon, {
      commandResetAlerts: true
    });
  }
  public resetPumpRoomAlerts(pumpRoom: any) {
    this.configurePumpRoom(pumpRoom, {
      commandResetAlerts: true
    });
  }

  private refreshAlertList() {
    const ctx: string[] = isArray(this.context) ? (this.context as string[]) : [this.context as string];
    const lvl: string[] = isArray(this.levels) ? (this.levels as string[]) : [this.levels as string];

    this.isShowingOnlyOneSnowCannon = ctx.length === 1 && this.rs.getById(ctx[0]).systemType === 'SnowCannon';

    this.alerts = this.rs.filterAlerts(ctx as any, lvl as any, this.onlyActive);

    this.visibleAlerts = this.alerts.slice(0, this.allowDynamicLoading ? 50 : 1000);

    setTimeout(() => {
      this.alertCount.emit(this.alerts.length);
      if (this.alerts.length > 0) {
        const respLvl = Math.min(...this.alerts.map(a => a.alertLevel));
        this.alertLevel.emit(this.alerts.find(a => a.alertLevel === respLvl).level);
      }
    });
  }

  public logScrollY(ev) {
    console.log(ev);
  }

  public trackByFn(index: number, alert: Alert): number {
    return alert.id;
  }

  public addShadows(event) {
    const prevEl = ((event.srcElement).previousElementSibling);
    const nextEl = ((event.srcElement).nextElementSibling);

    if (prevEl && Array.from(prevEl.classList).includes('table__row')) {
      prevEl.classList.add('table__hovered-prev');
    }
    if (nextEl && Array.from(nextEl.classList).includes('table__row')) {
      nextEl.classList.add('table__hovered-next');
    }
  }

  public removeShadows(event) {
    const prevEl = ((event.srcElement).previousElementSibling);
    const nextEl = ((event.srcElement).nextElementSibling);

    if (prevEl && Array.from(prevEl.classList).includes('table__row')) {
      prevEl.classList.remove('table__hovered-prev');
    }
    if (nextEl && Array.from(nextEl.classList).includes('table__row')) {
      nextEl.classList.remove('table__hovered-next');
    }
  }

  public getLinkToDevice(device: Device): any {
    let resp = [];
    switch (device.systemType) {
      case 'ResortArea':
        resp = ['resort', 'area', device.id, 'map'];
        break;
      case 'WorkingArea':
        if ((device as WorkingArea).resortAreaRef) {
          resp = ['resort', 'area', (device as WorkingArea).resortAreaRef.id, 'map'];
        }
        break;
      case 'PumpRoom':
        resp = ['pump-rooms', 'detail', device.id];
        break;
      case 'WaterPipeline':
        if ((device as WaterPipeline).pumpRoomRef) {
          resp = ['pump-rooms', 'detail', (device as WaterPipeline).pumpRoomRef.id];
        }
        break;
      case 'PowerSource':
        resp = ['power-sources', 'detail', device.id];
        break;
      case 'ElectricalLine':
        if ((device as ElectricalLine).powerSourceRef) {
          resp = ['power-sources', 'detail', (device as ElectricalLine).powerSourceRef.id];
        }
        break;
      case 'SnowCannon':
        if ((device as SnowCannon).conenctionPointRef) {
          resp = ['resort', 'area', (device as SnowCannon).conenctionPointRef.resortAreaRef.id, 'map', device.id];
        }
        break;
    }
    return ['/', 'application', { outlets: { primary: resp, modal: null }}];
  }
}
