import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertedUnit',
  pure: false
})
export class ConvertedUnitPipe implements PipeTransform {

  transform(device: any, fieldName: string): string {
    if (device && device[fieldName + 'Unit']) {
      return '' + device[fieldName + 'Unit'];
    }
    return '?';
  }

}
