import { ConvertUnit, DeviceSystemType } from '../../../../../common';
import { AbstractDevice } from './abstract-device.model';
import { ResortConfiguration } from './resort-configuration.model';

export class Resort extends AbstractDevice {
    systemType: DeviceSystemType = 'Resort';

    symbol: string;
    mapBoundaries: any[];

    mapBearing: number;
    mapCenter: [number, number];
    mapZoom: number;
    mapTilesDirectory: string;
    mapPerspectiveMapImage: string;
    rasterizedResortProject: string;

    @ConvertUnit('production', 'm3/h')
    snowProductionVolume: number;
    @ConvertUnit('waterFlow', 'l/s')
    computedWaterFlow: number;
    computedPowerDraw: number;
    computedAirFlow: number;

    extraParameters: any[];

    isLocked = false;

    configuration: ResortConfiguration;


    public update(data: any) {
      data.configuration = ResortConfiguration.create(data['configuration']);

      return Object.assign(this, data);
    }
}
