import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AreaData } from '../area-data-resolver.service';
import {
  ResortArea,
  Schedule,
  SnowCannon,
  PumpRoom,
  CompressorRoom,
  PowerSource,
  ConnectionPoint,
  MeteoStation,
  Resort,
  MeteoDevice,
  WaterReservoir
} from 'src/app/shared/models';
import { ResortService } from 'src/app/resort.service';
import { Observable, of } from 'rxjs';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { SnowQualityValue } from 'src/app/shared/components/snow-quality/snow-quality.component';
import { DevicesClientService } from 'src/app/http-clients';
import { HeaderService } from 'src/app/header.service';
import { SettingsService } from 'src/app/settings.service';
import { AreaHeaderExtensionComponent } from '../area-header-extension/area-header-extension.component';
import { ConfirmModalService } from '../../../confirm-modal.service';
import { ModalRouteService } from 'src/app/application/modal-route.service';
import { ProgressOfObservablesService } from 'src/app/application/modals/progress-of-observables.service';
import { isNullOrUndefined } from '../../../../../../../common';

@Component({
  selector: 'ss-area-list',
  templateUrl: './area-list.component.html',
  styleUrls: ['./area-list.component.sass']
})
export class AreaListComponent implements OnInit {

  constructor(
    private confirmModalService: ConfirmModalService,
    private settingsService: SettingsService,
    private titleService: HeaderService,
    private rs: ResortService,
    private cs: ConfigureClientService,
    private route: ActivatedRoute,
    private mr: ModalRouteService,
    private ps: ProgressOfObservablesService
  ) { }

  openMeteoSelectorModal() {
    this.mr.showModalRoute('meteo-conditions-source-selection-modal/' + this.area.id + '/');
    const sub = this.mr.results.subscribe(id => {
      if(id) {
        this.setAreaRemoteMeteoStation(this.area, id);
      }
      sub.unsubscribe();
    });
  }

  public minSnowCannonStartTemperature: number;
  public maxSnowCannonStartTemperature: number;
  public hasSnowCannonWithStartTemperature: boolean;
  public hasSnowCannonWithStartFlow: boolean;
  public minSnowCannonStartFlow: number;
  public maxSnowCannonStartFlow: number;

  windowCollapse = false;
  isAlertsDropdownExpanded = false;
  isTableCollapsed = false;
  operationModeDropdownExpanded = false;
  operationModeDropdownExpanded2 = false;

  area: ResortArea;
  schedules: Schedule[];
  currentSchedule: Schedule;
  meteoStation: MeteoStation;
  private snowCannons: SnowCannon[];
  orderedSnowCannons: SnowCannon[];
  connectionPoints: ConnectionPoint[];
  pumpRooms: PumpRoom[];
  compressorRooms: CompressorRoom[];
  powerSources: PowerSource[];
  meteoStations: MeteoStation[];
  waterReservoirs: WaterReservoir[];

  public devIds: string[] = [];

  public duration: number = null;

  closestScheduleHours: string;

  orderBy = 'symbol';
  orderByDirection = true;
  orderByRequiresStringComparison = false;
  orderedSnowCannonsRefreshTimeout;

  isEveryOneSelected = false;
  selectedCannonsCount = 0;
  selectedCannons: Map<string, boolean> = new Map();
  public resort: Resort;
  public setDuration(val: number) {
    this.duration = val;
  }

  ngOnInit() {
    this.route.data
      .subscribe((data: { areaData: AreaData }) => {
        Object.assign(this, data.areaData);

        this.settingsService.append('recentAreaIds', this.area.id, 10);

        this.titleService.setUpdateHeader('menuSnowCannons', AreaHeaderExtensionComponent, {
          resortName: this.resort.symbol,
          area: this.area,
          isMap: false
        });

        this.snowCannons.forEach(v => {
          this.selectedCannons.set(v.id, false);
        });

        this.devIds = [
          this.area.id,
          ... this.snowCannons.map(e => e.id),
          ... this.pumpRooms.map(e => e.id),
          ... this.powerSources.map(e => e.id),
          ... this.compressorRooms.map(e => e.id),
          ... this.meteoStations.map(e => e.id)
        ];

        this.updateOrderedSnowCannons();
      });

    this.closestScheduleHours = this.currentSchedule.getClosest();
  }

  setOrderBy(field: string, requiresStringComparison: boolean = false) {
    if (this.orderBy === field) {
      this.orderByDirection = !this.orderByDirection;
    } else {
      this.orderByDirection = true;
    }
    this.orderBy = field;
    this.orderByRequiresStringComparison = requiresStringComparison;

    this.updateOrderedSnowCannons();
  }

  private updateOrderedSnowCannons() {
    clearTimeout(this.orderedSnowCannonsRefreshTimeout);

    const snowCannons = [ ... this.snowCannons ];
    if (this.orderByRequiresStringComparison) {
      snowCannons.sort((a, b) => {
        const ret = b.connectionStatus - a.connectionStatus;

        if (ret === 0 || this.orderBy === 'symbol') {
          return (this.orderByDirection ? 1 : -1) * (a[this.orderBy].localeCompare(b[this.orderBy]));
        }

        return ret;
      });
    } else {
      snowCannons.sort((a, b) => {
        let ret = b.connectionStatus - a.connectionStatus;

        if (ret === 0 || this.orderBy === 'symbol') {
          if (this.orderBy === 'connectionPointSymbol') {
            ret = (b.connectionPointSymbol === b.hydrantNumber ? 0 : 1) - (a.connectionPointSymbol === a.hydrantNumber ? 0 : 1);
          }
          if (ret === 0) {
            return (this.orderByDirection ? 1 : -1) * (a[this.orderBy] - b[this.orderBy]);
          }
          return ret;
        }

        return ret;
      });
    }

    this.orderedSnowCannonsRefreshTimeout = setTimeout(() => {
      this.updateOrderedSnowCannons();
    }, 1000);

    const cannonsWStartTemp = this.area.getSnowCannons().filter(sc =>
      sc.connectionStatus
      && sc.CAP_CHANGE_START_TEMPERATURE
      && !isNullOrUndefined(sc.startTemperature)
    );
    this.hasSnowCannonWithStartTemperature = cannonsWStartTemp.length > 0;
    this.minSnowCannonStartTemperature = 0.1 * Math.round(10 * Math.min(...cannonsWStartTemp.map(sc => sc.startTemperature)));
    this.maxSnowCannonStartTemperature =  0.1 * Math.round(10 * Math.max(...cannonsWStartTemp.map(sc => sc.startTemperature)));

    const cannonsWStartFlow = this.area.getSnowCannons().filter(sc =>
      sc.connectionStatus
      && sc.CAP_CHANGE_START_WATER_FLOW
      && !isNullOrUndefined(sc.startWaterFlow)
    );

    this.hasSnowCannonWithStartFlow = cannonsWStartFlow.length > 0;
    this.minSnowCannonStartFlow = 0.1 * Math.round(10 * Math.min(...cannonsWStartFlow.map(sc => sc.startWaterFlow)));
    this.maxSnowCannonStartFlow = 0.1 * Math.round(10 * Math.max(...cannonsWStartFlow.map(sc => sc.startWaterFlow)));

    this.orderedSnowCannons = snowCannons;
  }

  toggleAreaStartStop(area: ResortArea, value: boolean) {
    const cannons = this.area.getSnowCannons().filter(sc => sc.connectionStatus);
    let actions: Observable<any>[] = [];
    if (value) {
      actions = cannons.map(sc => this.cs.startDevice(sc.id));
    } else {
      actions = cannons.map(sc => this.cs.stopDevice(sc.id));
    }
    this.ps.runProgressAction(actions);
  }

  setAreaSnowQuality(area: ResortArea, event: SnowQualityValue) {
    let cannons = this.area.getSnowCannons().filter(sc => sc.connectionStatus);
    let om = {};
    if (area.operationMode) {
      om = {
        snowQualityType: event.type,
        snowQualityConstant: event.current,
        snowQualityLow: event.low,
        snowQualityHigh: event.high
      };
      if (event.type > 0) {
        cannons = cannons.filter(c => c.operationMode);
      }
    } else {
      om = {
        snowQualityType: 0,
        snowQualityConstant: event.current
      };
    }
    const actions: Observable<any>[] = cannons.map(sc => this.cs.configureSnowCannonById(sc.id, om));
    actions.push(this.cs.configureArea(area, om));
    this.ps.runProgressAction(actions);
  }

  setAreaOperationMode(area: ResortArea, mode: boolean) {
    let question: Observable<boolean> = of(true);
    if (area.operationMode === 1) {
      let autoStartedSnowCannons = 0;

      area.snowCannonRefs.forEach(sc => {
        if (sc.operationMode === 1 && sc.computedStatus === 'working') {
          autoStartedSnowCannons++;
        }
      });

      if (autoStartedSnowCannons > 0) {
        question = this.confirmModalService.openAlertChangeWillShutDownSnowCannons(autoStartedSnowCannons);
      }
    }

    question.subscribe(answer => {
      if (answer) {
        this.configureArea(area, {
          scheduleSchema: area.scheduleSchema,
          operationMode: mode
        });
      }
    });
  }

  setAreaRemoteMeteoStation(area: ResortArea, msId: string) {
    this.configureArea(area, {
      remoteMeteoStation: msId
    });
  }

  setSnowCannonStartTemperature(snowCannon: SnowCannon, event) {
    this.configureSnowCannon(snowCannon, {
      setStartTemperature: 0.01 * Math.round(100 * event)
    });
  }

  setSnowCannonOperationMode(snowCannon: SnowCannon, mode: boolean) {
    let question: Observable<boolean> = of(true);
    const om = {
      operationMode: mode
    };

    if (
      mode
      && (
        snowCannon.snowQualityType > 0
        || snowCannon.snowQualityConstant !== snowCannon.snowQuality
      )
    ) {
      question = this.confirmModalService.openAlertChangeWillSetDifferentSnowQuality(
        snowCannon.snowQualityType,
        snowCannon.snowQuality,
        snowCannon.snowQualityConstant,
        snowCannon.snowQualityLow,
        snowCannon.snowQualityHigh,
      );
    }

    question.subscribe(answer => {
      if (!answer) {
        om['snowQualityType'] = 0;
        om['snowQualityConstant'] = snowCannon.snowQuality;
      }
      this.configureSnowCannon(snowCannon, om);
    });
  }

  setCannonSnowQuality(cannon: SnowCannon, event: SnowQualityValue) {
    let om = {};
    if (cannon.operationMode) {
      om = {
        snowQualityType: event.type,
        snowQualityConstant: event.current,
        snowQualityLow: event.low,
        snowQualityHigh: event.high
      };
    } else {
      om = {
        setSnowQuality: event.current
      };
    }
    this.configureSnowCannon(cannon, om);
  }

  toggleSnowCannonStartStop(snowCannon: SnowCannon) {
    let action = this.cs.startDevice(snowCannon.id);
    if (snowCannon.commandStatusCannonStart) {
      action = this.cs.stopDevice(snowCannon.id);
    }
    action.subscribe(() => { }, () => { });
  }

  private configureSnowCannon(snowCannon: SnowCannon, data: any) {
    this.cs.configureSnowCannon(snowCannon, data).subscribe(() => { }, () => { });
  }

  private configureArea(area: ResortArea, data: any) {
    this.cs.configureArea(area, data).subscribe(() => { }, () => { });
  }

  trackByFn(index: number, snowCannon: SnowCannon): string {
    return snowCannon.id;
  }

  toggleProperty(object, property) {
    object[property] = !object[property];
  }

  addShadows(event) {
    const prevEl = ((event.srcElement).previousElementSibling);
    const nextEl = ((event.srcElement).nextElementSibling);

    if (prevEl && Array.from(prevEl.classList).includes('table__row')) {
      prevEl.classList.add('table__row--hovered-prev');
    }
    if (nextEl && Array.from(nextEl.classList).includes('table__row')) {
      nextEl.classList.add('table__row--hovered-next');
    }
  }

  removeShadows(event) {
    const prevEl = ((event.srcElement).previousElementSibling);
    const nextEl = ((event.srcElement).nextElementSibling);

    if (prevEl && Array.from(prevEl.classList).includes('table__row')) {
      prevEl.classList.remove('table__row--hovered-prev');
    }
    if (nextEl && Array.from(nextEl.classList).includes('table__row')) {
      nextEl.classList.remove('table__row--hovered-next');
    }
  }
  selectFromSnowCannons(val: boolean, id: string) {
    this.selectedCannons.set(id, val);
    this.checkIsEveryOneSelected();
  }
  selectFromArea(val: boolean) {
    this.selectedCannons.forEach((v, k) => {
      this.selectedCannons.set(k, val);
    });
    this.checkIsEveryOneSelected();
  }
  selectFromGroup(val: boolean) {
    this.selectFromArea(val);
  }

  getSelectionVal(id) {
    return this.selectedCannons.get(id);
  }

  private checkIsEveryOneSelected() {
    let every = true;
    let selectedCannonsCount = 0;
    this.selectedCannons.forEach((v, k) => {
      selectedCannonsCount += v ? 1 : 0;
      every = every && v;
    });
    this.isEveryOneSelected = every;
    this.selectedCannonsCount = selectedCannonsCount;
  }

  openMassOperations() {
    const ids: string[] = [];
    this.selectedCannons.forEach((v, k) => {
      if (v && this.rs.getSnowCannon(k) && this.rs.getSnowCannon(k).connectionStatus) {
        ids.push(k);
      }
    });

    this.mr.showModalRoute('mass-operations-modal/' + ids.join(','));
  }

}
