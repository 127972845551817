import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'ss-tab',
  templateUrl: './tab.component.html',
  styleUrls: []
})
export class TabComponent {

  @Input() tabId: string = 'tmp-tab-id-' + Math.random();
  @Input() tabTitle: string;

  @Input() position: string;
  @Input() noLevelInfos: string;
  @Input() notifications: string;
  @Input() warnings: string;
  @Input() alerts: string;

  @Input()
  hidden = false;

  @HostBinding('class.is-active')
  active = false;

  @HostBinding('class.ss-tab')
  public readonly ssTabConstantHtmlClassDecorator: boolean = true;
}
