import { Component, Input } from '@angular/core';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { SnowCannon } from '../../models';

@Component({
  selector: 'ss-abstract-snow-cannon-component-button',
  template: '',
  styleUrls: []
})
export abstract class AbstractSnowCannonComponentButtonComponent {
  @Input()
  public size = 'small';

  @Input()
  public snowCannon: SnowCannon;

  constructor(private cs: ConfigureClientService) {}

  protected configureSnowCannon(data: any) {
    this.cs.configureSnowCannon(this.snowCannon, data).subscribe(() => {}, () => {});
  }
}
