import { Component, Input } from '@angular/core';

@Component({
  selector: 'ss-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.sass']
})
export class UserRolesComponent {
  constructor() { }

  private _roles: string[];
  @Input()
  get roles(): string[] {
    return this._roles;
  }
  set roles(val: string[]) {
    this._roles = val || [];

    if (this._roles.indexOf('ROLE_SUPERADMIN') >= 0) {
      this.role = 'ROLE_SUPERADMIN';
    } else if (this._roles.indexOf('ROLE_ADMIN') >= 0) {
      this.role = 'ROLE_ADMIN';
    } else if (this._roles.indexOf('ROLE_OPERATOR') >= 0) {
      this.role = 'ROLE_OPERATOR';
    } else if (this._roles.indexOf('ROLE_OBSERVER') >= 0) {
      this.role = 'ROLE_OBSERVER';
    } else {
      this.role = 'ROLE_NONE';
    }
  }

  role = 'ROLE_NONE';

}
