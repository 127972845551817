<button [disabled]="disabled" class="control-button" [ngClass]="{
  'control-button--start': action === 'start',
  'control-button--stop': action === 'stop',
  'control-button--medium': size === 'medium',
  'control-button--small': size === 'small',
  'control-button--extra-small': size === 'extra-small'
}">
  <ng-container *ngIf="size!=='medium'">
    <svg *ngIf="action==='start'">
      <use xlink:href='#icon-start'/>
    </svg>
    <svg *ngIf="action==='stop'">
      <use xlink:href='#icon-stop'/>
    </svg>
  </ng-container>
  <ng-content *ngIf="size!=='extra-small'"></ng-content>
  <ng-container *ngIf="size==='medium'">
    <svg *ngIf="action==='start'">
      <use xlink:href='#icon-start'/>
    </svg>
    <svg *ngIf="action==='stop'">
      <use xlink:href='#icon-stop'/>
    </svg>
  </ng-container>
</button>
