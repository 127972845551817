import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'ss-number-input',
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.sass']
})
export class NumberInputComponent implements OnInit {

    constructor() {
        this.changed = new EventEmitter();
        this.localChanged = new EventEmitter();
        this.localChanged.pipe(debounceTime(500)).subscribe((ev) => {
            this.changed.emit(ev);
        });
    }

    @Input() size: string;
    @Input() step = 1;
    @Input() min = 1;
    @Input() max = 100;
    @Input() extraContent = '';
    @Input() inputValue = 1;
    @Input() disabled = false;

    @Output() changed: EventEmitter<number>;

    localChanged: EventEmitter<number>;

    sizeClassName = '';

    emitValue(value: number) {
        this.localChanged.emit(value);
    }

    inputChanged($event) {
        let val: any = '' + $event.target.value;
        val = val.replace(',', '.');
        val = parseFloat(val);
        if (
            Math.round(val * 100) / 100 >= +this.min
            && Math.round(val * 100) / 100 <= +this.max
        ) {
            this.inputValue = val;

            this.emitValue(val);
        } else {
            $event.target.value = this.inputValue;
        }
    }

    increase() {
        if (Math.round(this.inputValue * 100) / 100 <= +this.max) {
            this.inputValue = +this.inputValue + +this.step;

            this.emitValue(this.inputValue);
        }
    }

    decrease() {
        if (Math.round(this.inputValue * 100) / 100 >= +this.min) {
            this.inputValue = +this.inputValue - +this.step;

            this.emitValue(this.inputValue);
        }
    }

    ngOnInit() { }

}
