import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/header.service';

@Component({
  selector: 'ss-weather-and-schedules',
  templateUrl: './weather-and-schedules.component.html',
  styleUrls: ['./weather-and-schedules.component.sass']
})
export class WeatherAndSchedulesComponent implements OnInit {

  constructor(
    private titleService: HeaderService
  ) { }

  ngOnInit() {
    this.titleService.setUpdateHeader('menuWeatherAndSchedules');
  }


}

