import { Component, OnInit, Input } from '@angular/core';
import { Filter } from 'src/app/shared/models/pump-room';

@Component({
  selector: 'ss-filter-tile',
  templateUrl: './filter-tile.component.html',
  styleUrls: []
})
export class FilterTileComponent implements OnInit {

  constructor() { }

  @Input()
  public filter: Filter;

  @Input()
  public selectedPipelineIds: string[] = [];


  ngOnInit() {
  }

}
