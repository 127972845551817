<ss-page>
    <ss-page-header>
        <ss-page-header-title>Connection points</ss-page-header-title>
        <ss-page-button-group>
            <button
              (click)="save()"
              [disabled]="editedConnectionPoint" class="btn btn-primary">Save <kbd>Ctrl+Shift+S</kbd></button>
            <a class="btn btn-default" [routerLink]="['/application', 'editor']">Go back</a>
        </ss-page-button-group>
    </ss-page-header>

    <ng-container *ngIf="editedConnectionPoint">
        <ss-page-panel>
            <div class="row">
                <div class="col">
                    <button [disabled]="!editedSymbol"
                    (click)="saveEdit()"
                    class="btn btn-primary w-100">Save <kbd>Ctrl+s</kbd></button>
                </div>
                <div class="col-auto pl-1">
                    <button (click)="cancelEdit()" class="btn btn-default">Cancel <kbd>Esc</kbd></button>
                </div>
            </div>
        </ss-page-panel>
        <ss-page-panel
            panelTitle="Edit symbol">
            <form-text [hideLabel]="true" [(ngModel)]="editedSymbol" placeholder="type in connection point no..."></form-text>
        </ss-page-panel>
        <ss-page-panel
            panelTitle="Set resort area">
            <form-select [hideLabel]="true" [(ngModel)]="selectedArea" [disabled]="areas.length <= 1">
                <form-select-option *ngFor="let x of areas" [value]="x" [label]="x.symbol"></form-select-option>
            </form-select>
        </ss-page-panel>

        <ss-page-panel
            panelTitle="Set water pipeline">
            <form-select [hideLabel]="true" [(ngModel)]="selectedWaterPipeline" [disabled]="waterPipelines.length <= 1">
                <form-select-option *ngFor="let x of waterPipelines" [value]="x" [label]="x.symbol"></form-select-option>
            </form-select>
        </ss-page-panel>
        <ss-page-panel
            panelTitle="Set compressed air pipeline">
            <form-select [hideLabel]="true" [(ngModel)]="selectedAirPileline" [disabled]="airPilelines.length <= 1">
                <form-select-option *ngFor="let x of airPilelines" [value]="x" [label]="x.symbol"></form-select-option>
            </form-select>
        </ss-page-panel>
        <ss-page-panel
            panelTitle="Set power line">
            <form-select [hideLabel]="true" [(ngModel)]="selectedPowerLine" [disabled]="powerLines.length <= 1">
                <form-select-option *ngFor="let x of powerLines" [value]="x" [label]="x.symbol"></form-select-option>
            </form-select>
        </ss-page-panel>
        <ss-page-panel
            panelTitle="Set communication line">
            <form-select [hideLabel]="true" [(ngModel)]="selectedCommunicationLine" [disabled]="communicationLines.length <= 1">
                <form-select-option *ngFor="let x of communicationLines" [value]="x" [label]="x.symbol"></form-select-option>
            </form-select>
        </ss-page-panel>

        <ss-page-panel
            *ngIf="editedPosition"
            panelTitle="Edit position">
            <pre *ngIf="editedPosition">
                {{ editedPosition| json }}
            </pre>
        </ss-page-panel>
    </ng-container>

    <ng-container *ngIf="!editedConnectionPoint">
        <ss-page-panel>
            <button (click)="add()" class="btn w-100 btn-primary">Add new conenction <kbd>Ctrl+a</kbd></button>
        </ss-page-panel>
        <ss-page-panel
            [panelTitle]="'List (' + connectionPoints.length + ')'">
            <div class="row align-items-center pb-1" *ngFor="let cp of connectionPoints">
                <div class="col">Foundation pit: {{ cp.c.symbol }}</div>
                <div class="col-auto">
                    <div class="btn btn-default" (click)="edit(cp)">Edit</div>
                </div>
            </div>
        </ss-page-panel>
    </ng-container>
</ss-page>
