import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ss-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.sass']
})
export class TimeInputComponent implements OnInit {

  constructor() {
    this.changed = new EventEmitter();
    this.localChanged = new EventEmitter();
    this.localChanged.pipe(debounceTime(500)).subscribe((ev) => {
      this.changed.emit(ev);
    });
  }
  @Input()
  get inputValue(): number {
    return this._inputValue;
  }
  set inputValue(val: number) {
    if (val < 0) {
      val = 0;
    }
    if (val > 24 * 60) {
      val = 24 * 60;
    }
    this.hour = Math.floor(val / 60);
    this.minute = val - this.hour * 60;

    this._inputValue = val;
  }

  minutesHasFocus = false;
  hour = 0;
  minute = 0;

  @Input() size: string;
  private _inputValue = 0;

  @Input() disabled = false;

  @Output() changed: EventEmitter<number>;

  localChanged: EventEmitter<number>;

  sizeClassName = '';

  private calcTime() {
    return this.hour * 60 + this.minute;
  }

  emitValue() {
    this.localChanged.emit(this.inputValue);
  }

  inputHoursChanged($event) {
    let v = parseInt($event.target.value, 10);
    if (v < 0) {
      v = 0;
    }
    if (v > 24) {
      v = 24;
    }
    this.hour = v;

    this._inputValue = this.calcTime();

    this.emitValue();
  }
  inputMinutesChanged($event) {
    let v = parseInt($event.target.value, 10);
    if (v < 0) {
      v = 0;
    }
    if (v > 59) {
      v = 59;
    }
    this.minute = v;

    this._inputValue = this.calcTime();

    this.emitValue();
  }

  increase() {
    this.inputValue += this.minutesHasFocus ? 1 : 60;

    this.emitValue();
  }

  decrease() {
    this.inputValue -= this.minutesHasFocus ? 1 : 60;

    this.emitValue();
  }

  ngOnInit() { }

}
