import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from 'src/app/header.service';
import { ResortService } from 'src/app/resort.service';
import { PumpRoom, WaterPipeline, WaterReservoir } from 'src/app/shared/models';
import { ActivatedRoute } from '@angular/router';
import { SimplifiedSchema } from 'src/app/shared/models/pump-room';
import { PumpRoomHeaderExtensionComponent } from '../pump-room-header-extension/pump-room-header-extension.component';

@Component({
  selector: 'ss-pump-room-detail',
  templateUrl: './pump-room-detail.component.html',
  styleUrls: ['./pump-room-detail.component.sass']
})
export class PumpRoomDetailComponent implements OnInit {

  constructor(
    private titleService: HeaderService,
    private resortService: ResortService,
    private route: ActivatedRoute
  ) { }

  public pumpRoom: PumpRoom;
  public devIds: string[] = [];
  public pipelines: WaterPipeline[] = [];
  public waterReservoirs: WaterReservoir[] = [];
  public simplifiedSchema: SimplifiedSchema;

  public selectedPipelineIds: string[] = [];

  public pumps2AccordionExpanded = false;
  public pumps1AccordionExpanded = false;
  public lifitngPumpAccordionExpanded = false;
  public filtersAccordionExpanded = false;
  public valvesAccordionExpanded = false;
  public coolingTowersAccordionExpanded = false;
  public coolingTowerPumpAccordionExpanded = false;
  public compressorAccordionExpanded = false;
  public aerationLevelAccordionExpanded = false;

  public maximumWaterFlowUnit = '';
  public get maximumWaterFlow(): number {
    if (this.pumpRoom) {
      let resp = 0;
      this.pumpRoom.waterPipelineRefs.forEach(wp => {
        resp += wp['maximumWaterFlowConverted'];
        this.maximumWaterFlowUnit = wp['maximumWaterFlowUnit'];
      });
      return resp;
    }
    return 0;
  }

  ngOnInit() {
    this.titleService.setUpdateHeader('menuPumpRooms');

    this.route.paramMap.subscribe(data => {
      this.pumpRoom = this.resortService.getPumpRoom(data.get('id'));
      this.simplifiedSchema = this.pumpRoom.configuration.simplifiedSchema;

      this.titleService.setUpdateHeader('menuPumpRooms', PumpRoomHeaderExtensionComponent, {
        pumpRoom: this.pumpRoom
      });

      this.pipelines = this.pumpRoom.getWaterPipelines().sort((a, b) => `${a.symbol}`.localeCompare(`${b.symbol}`));

      this.pipelines.forEach(wp => {
        if (wp.waterReservoirRef && !this.waterReservoirs.includes(wp.waterReservoirRef)) {
          this.waterReservoirs.push(wp.waterReservoirRef);
        }
      });

      this.devIds = [ this.pumpRoom.id, ...this.pipelines.map(p => p.id) ];
    });
  }

  public checkComponentArrayByDeviceId(arr: any[], ids: string[]) {
    return arr.map(el => el.isSelectedByDeviceIds(ids)).reduce((p, c) => p || c, false);
  }

  public highlightComponentsChange(pipeline, ev) {
    if (ev) {
      this.selectedPipelineIds.push(pipeline.id);
    } else {
      const idx = this.selectedPipelineIds.indexOf(pipeline.id);
      if (idx >= 0) {
        this.selectedPipelineIds.splice(pipeline.id);
      }
    }

    if (this.simplifiedSchema) {
      this.filtersAccordionExpanded = this.checkComponentArrayByDeviceId(this.simplifiedSchema.filters, this.selectedPipelineIds);
      this.valvesAccordionExpanded = this.checkComponentArrayByDeviceId(this.simplifiedSchema.valves, this.selectedPipelineIds);
      this.lifitngPumpAccordionExpanded = this.checkComponentArrayByDeviceId(this.simplifiedSchema.liftingPumps, this.selectedPipelineIds);
      this.pumps1AccordionExpanded = this.checkComponentArrayByDeviceId(this.simplifiedSchema.firstStagePumps, this.selectedPipelineIds);
      this.pumps2AccordionExpanded = this.checkComponentArrayByDeviceId(this.simplifiedSchema.secondStagePumps, this.selectedPipelineIds);
      this.coolingTowersAccordionExpanded = this.checkComponentArrayByDeviceId(
        this.simplifiedSchema.coolingTowers,
        this.selectedPipelineIds
      );
      this.coolingTowerPumpAccordionExpanded = this.checkComponentArrayByDeviceId(
        this.simplifiedSchema.coolingTowerPumps,
        this.selectedPipelineIds
      );
      this.compressorAccordionExpanded = this.checkComponentArrayByDeviceId(
        this.simplifiedSchema.compressors,
        this.selectedPipelineIds
      );
      this.aerationLevelAccordionExpanded = this.checkComponentArrayByDeviceId(
        this.simplifiedSchema.aerationLevels,
        this.selectedPipelineIds
      );
    }
  }

}
