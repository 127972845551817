import { Component, Input, OnInit } from '@angular/core';
import { ConfigureClientService } from '../../../../http-clients/configure.client.service';
import { ElectricalLine } from '../../../../shared/models';
import { ModalRouteService } from '../../../modal-route.service';

@Component({
  selector: 'ss-electrical-line-tile',
  templateUrl: './electrical-line-tile.component.html',
  styleUrls: ['./electrical-line-tile.component.sass']
})
export class ElectricalLineTileComponent implements OnInit {

  constructor(
    private mr: ModalRouteService
  ) { }

  @Input()
  public electricalLine: ElectricalLine;
  public areasListExpanded = false;

  oppenConfigModal() {
    this.mr.showModalRoute('configure-electrical-line-modal/' + this.electricalLine.id);
  }

  ngOnInit(): void {
  }

}
