import { Component, Input } from '@angular/core';
import { WaterReservoir } from '../../models';

@Component({
  selector: 'ss-water-tank',
  templateUrl: './water-tank.component.html',
  styleUrls: ['./water-tank.component.sass']
})
export class WaterTankComponent {

  @Input() waterReservoir: WaterReservoir;
  @Input() size: 'small'|'extra-small'|'medium'|'large' = 'medium';
  @Input() type: 'stream'|'tank' = 'tank';

  constructor() { }
}
