import { Component } from '@angular/core';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { AbstractSnowCannonComponentButtonComponent } from '../abstract-snow-cannon-component-button.component';

@Component({
  selector: 'ss-snow-cannon-component-button-oscillator',
  templateUrl: './component.html',
  styleUrls: []
})
export class SnowCannonComponentButtonOscillatorComponent extends AbstractSnowCannonComponentButtonComponent {

  constructor(cs: ConfigureClientService) {
    super(cs);
  }

  public toggleOscilaltion() {
    if (this.snowCannon.isOscillationOn) {
      this.configureSnowCannon({
        commandOscillationOff: true
      });
    } else {
      this.configureSnowCannon({
        commandOscillationOn: true
      });
    }
  }

}
