import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { isArray } from '../../../common';
import { SettingsClientService } from './http-clients';

export type SettingKey = 'animationSpeed'
  |'defaultChartPeriod'
  |'machineRoomFullSchemaDarkMode'
  |'mapSettingHipsoLayerOpacity'
  |'mapSettingLayersMenuIsVisible'
  |'recentAreaIds'
  |'recentReportManualDateRanges'
  |'recentSnowCannonIds'
  |'unitSet'

  |'serverTimezone'
  |'timestampOfOldestStatusEntry'
  ;

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public constructor(private client: SettingsClientService) { }

  public preloadedPreviewImage = '';

  private serverTimezone = 'UTC';
  private timestampOfOldestStatusEntry: number = 0.001 * (new Date()).getTime();

  private animationSpeed = 500;
  private defaultChartPeriod = 12;
  private machineRoomFullSchemaDarkMode = true;
  private mapSettingHipsoLayerOpacity = 0;
  private mapSettingLayersMenuIsVisible = false;
  private recentAreaIds: string[] = [];
  private recentReportManualDateRanges: string[] = [];
  private recentSnowCannonIds: string[] = [];
  private unitSet: any = {};

  public initialize(settings, preloadedPreviewImage) {
    this.preloadedPreviewImage = preloadedPreviewImage;
    if (settings && settings['settings']) {
      for (const s of Object.keys(settings['settings'])) {
        this[s] = settings['settings'][s];
      }
    }
  }

  public has(key: SettingKey): boolean {
    return Object.keys(this).includes(key);
  }

  public get<T extends string[] | boolean | number | string | any>(key: SettingKey): T {
    this.throwIfNotHas(key);

    return this[key] as T;
  }

  public set(key: SettingKey, data: any, immediate: boolean = true): Observable<any> {
    this.throwIfNotHas(key);

    (this[key] as any) = data;

    const resp = this.client.save(key, data);
    if (!immediate) {
      return resp;
    }
    resp.subscribe(() => {}, () => {});
    return of();
  }

  public append(key: SettingKey, data: any, limit: number = 3) {
    this.throwIfNotHas(key);

    let val = this.get<string[]>(key);
    if (!isArray(val)) {
      val = [];
    }
    if (val.indexOf(data) >= 0) {
      val.splice(val.indexOf(data), 1);
    }
    val.unshift(data);
    while (val.length > limit) {
      val.pop();
    }

    this.set(key, val);
  }

  private throwIfNotHas(key: SettingKey) {
    if (!this.has(key) && !environment.production) {
      throw Error('unknown setting ' + key);
    }
  }
}
