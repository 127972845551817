import { isArray } from '../../../../../../common';
import { Component } from './component.model';

export class Drawable {

    get x(): number {
        return this.getCoord('from', 0);
    }

    get y(): number {
        return this.getCoord('from', 1);
    }
    type: string;
    from: [number, number];

    isDragged = false;
    isHover = false;
    isSelected = false;

    public static create(data: any, componentsBySymbol: Map<string, Component>): Drawable {
        let  resp: Drawable;
        switch (data['type']) {
            case 'line':
                resp = new DrawableLine();
                break;
            case 'text':
                resp = new DrawableText();
                break;
            case 'connector':
                resp = new DrawableConnector();
                break;
            case 'component':
                resp = new DrawableComponent(componentsBySymbol.get(data['symbol']));
                break;

            default:
                throw new Error('invlid pumproom component');
        }

        return Object.assign(resp, data);
    }

    protected getCoord(cKey: any, aKey: number): number {
        return isArray(this[cKey]) && this[cKey][aKey] ? this[cKey][aKey] : 0;
    }
}

export class DrawableLine extends Drawable {
    type = 'line';
    to: [number, number];

    get x2(): number {
        return this.getCoord('to', 0);
    }

    get y2(): number {
        return this.getCoord('to', 1);
    }
}
export class DrawableText extends Drawable {
    label: string;
    type = 'text';
}
export class DrawableConnector extends Drawable {
    type = 'connector';
    public to: [number, number];
    public fromComponent: string;
    public toComponent: string;
    public fromComponentRef: Drawable;
    public toComponentRef: Drawable;

    get x(): number {
        return this.fromComponentRef ? this.fromComponentRef.x : super.x;
    }

    get y(): number {
        return this.fromComponentRef ? this.fromComponentRef.y : super.y;
    }

    get x2(): number {
        return this.toComponentRef ? this.toComponentRef.x : this.getCoord('to', 0);
    }

    get y2(): number {
        return this.toComponentRef ? this.toComponentRef.y : this.getCoord('to', 1);
    }
}
export class DrawableComponent<T extends Component> extends Drawable {
    type = 'component';
    rotation: number;
    symbol: string;

    get rot(): string {
        return 'rotate(' + this.rotation + 'deg)';
    }

    get r(): boolean {
        return !this.rotation || this.rotation === 0;
    }
    get d(): boolean {
        return this.rotation === 90;
    }
    get l(): boolean {
        return this.rotation === 180;
    }
    get u(): boolean {
        return this.rotation === 270;
    }

    public component: T;

    public constructor(component: T) {
        super();
        if (component) {
          this.component = component;
          this.symbol = component.symbol;
        }
    }
}
