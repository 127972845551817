<perfect-scrollbar>
  <div class="power-sources px-2 pb-2" [ngStyle]="{
    'background-image': perspectiveMapImage ? 'url(\'' + perspectiveMapImage + '\')' : 'url(assets/images/placeholder_bkg.png)',
    'background-size':'cover',
    'background-position': '50% 0',
    'background-repeat': 'no-repeat'
  }">
    <div class="power-sources__container">
      <div class="power-sources__row power-sources__row--bordered px-2 py-4">
        <p class="fs-26 color-white font-bold px-2 mb-4" i18n="Zasilanie|Nagłówek na stronie Zasilanie@@powerSourcesHeader">Zasilanie</p>
        <div class="d-flex flex-wrap">
          <div *ngFor="let p of powerSources"
            [routerLink]="['/application/power-sources/detail', p.id]"
            [ngClass]="{ 'power-source__33wide': grid3, 'power-source__25wide': !grid3 }"
            class="power-source clickable mx-2 mb-4 px-2 py-3">
            <p class="fs-22 color-white mb-2">{{ p.symbol }}</p>
            <div class="row mb-4">
              <div class="col-auto">



                  <ng-container *ngIf="p.electricalLinesRefs.size === 1">
                    <ss-pump-room-detail-status [status]="p.getElectricalLines()[0].computedStatus"></ss-pump-room-detail-status>
                  </ng-container>
                  <ng-container *ngIf="p.electricalLinesRefs.size > 1">
                    <ss-pump-room-detail-multi-status [hideDescriptions]="true" [components]="p.getElectricalLines()">
                      <ss-pump-room-detail-status status="standing-by" class="first-compacted last-compacted"></ss-pump-room-detail-status>
                      <ss-pump-room-detail-status status="breakdown" [standalone]="true"></ss-pump-room-detail-status>
                      <ss-pump-room-detail-status status="connection-lost" [standalone]="true"></ss-pump-room-detail-status>
                    </ss-pump-room-detail-multi-status>
                  </ng-container>



              </div>
              <div class="col"></div>
              <div class="col-auto pl-4" *ngIf="p.canDisplayCurrentPowerDraw()">
                <p class="fs-14 color-light-grey" i18n="Moc|Etykieta pola Moc@@powerSourcesComputedPowerDrawLabel">Moc</p>
                <p class="text-right">
                  <span class="fs-20 color-white">{{ p|convertedValue:'computedPowerDraw' }}</span>
                  <span class="fs-14 color-light-grey"> {{ p|convertedUnit:'computedPowerDraw' }}</span>
                </p>
              </div>
            </div>

            <ss-d3-power-draw-chart [device]="p"></ss-d3-power-draw-chart>

            <div class="cleafrix"></div>

            <ng-container *ngIf="getAlertCount(p.id) > 0">
              <ss-badge class="power-source__alert"
                size="small" color="info"
                *ngIf="getAlertLevel(p.id) === 'notification'" >{{ getAlertCount(p.id) }}</ss-badge>
              <ss-badge class="power-source__alert"
                size="small" color="warning"
                *ngIf="getAlertLevel(p.id) === 'warning'" >{{ getAlertCount(p.id) }}</ss-badge>
              <ss-badge class="power-source__alert"
                size="small" color="danger"
                *ngIf="getAlertLevel(p.id) === 'alert'" >{{ getAlertCount(p.id) }}</ss-badge>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</perfect-scrollbar>
