import { Component, OnInit } from '@angular/core';
import { ResortService } from 'src/app/resort.service';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { ActivatedRoute } from '@angular/router';
import { ModalRouteService } from 'src/app/application/modal-route.service';
import { SnowCannon, ConnectionPoint, MeteoDevice } from 'src/app/shared/models';
import { NIL } from 'uuid';

@Component({
  selector: 'ss-snow-cannon-configuration-modal',
  templateUrl: './snow-cannon-configuration-modal.component.html',
  styleUrls: ['./snow-cannon-configuration-modal.component.sass']
})
export class SnowCannonConfigurationModalComponent implements OnInit {

  public lock = false;
  public device: SnowCannon;
  public maximumWaterPressure: number;
  public maximumWaterFlow: number;
  public connectionPointEnabled: boolean;
  public connectionPoint: ConnectionPoint;
  public connectionPoints: ConnectionPoint[];

  public remoteMeteoStationEnabled: boolean;
  public remoteMeteoStation: string;

  public notifications: string[] = [];
  public notificationsEnabled = false;

  constructor(
    private rs: ResortService,
    private cs: ConfigureClientService,
    private ar: ActivatedRoute,
    private mr: ModalRouteService
  ) { }

  public toggleNotification(lvl: string) {
    const lvlIdx = this.notifications.indexOf(lvl);
    if(lvlIdx >= 0) {
      this.notifications.splice(lvlIdx, 1);
    } else {
      this.notifications.push(lvl);
    }
  }

  public isNotificationSelected(lvl: string): boolean {
    return this.notifications.includes(lvl);
  }

  public onMeteoSourceSelection(id: string) {
    this.remoteMeteoStation = id;
  }

  close() {
    this.mr.closeModal();
  }

  setHydrantNumber(hydrantSymbol: any) {
    const hydrantNumber = parseInt(`${hydrantSymbol}`, 10);
    if (hydrantNumber && !isNaN(hydrantNumber)) {
      this.connectionPoint = this.connectionPoints.find(p => parseInt(`${p.symbol}`, 10) === hydrantNumber);
    }
  }


  save() {
    this.lock = true;
    this.cs.configureSnowCannon(this.device, {
      connectionPoint: this.connectionPointEnabled && this.connectionPoint ? this.connectionPoint.id : NIL,
      remoteMeteoStation: this.remoteMeteoStationEnabled && this.remoteMeteoStation ? this.remoteMeteoStation  : NIL,
      setMaximumWaterPressure: this.maximumWaterPressure,
      setMaximumWaterFlow: this.maximumWaterFlow,
      notifyAboutReachingComputedStatusses: this.notificationsEnabled && this.notifications.length>0 ? this.notifications : [],
    }).subscribe(() => {
      this.lock = false;
      this.close();
    }, (err) => {
      console.error(err);
      this.lock = false;
    });
  }

  ngOnInit(): void {
    this.ar.paramMap.subscribe(params => {
      const deviceId = params.get('snowCannonId');
      const device: SnowCannon = this.rs.getById(deviceId);

      if (device) {
        this.device = device;

        this.maximumWaterPressure = device.maximumWaterPressure;
        this.maximumWaterFlow = device.maximumWaterFlow;
        this.connectionPointEnabled = true; // !!device.conenctionPointRef;
        this.connectionPoint = device.conenctionPointRef;
        this.notifications = device.notifyAboutReachingComputedStatusses || [];
        this.notificationsEnabled = !!device.notifyAboutReachingComputedStatusses && this.notifications.length > 0;

        const connectionPoints = this.device.conenctionPointRef?.resortAreaRef?.getConnectionPoints() || [];
        this.connectionPoints = connectionPoints.sort((a, b) => a.symbolAsNumber - b.symbolAsNumber);

        this.remoteMeteoStation = this.device.remoteMeteoStation;
        this.remoteMeteoStationEnabled = !!this.remoteMeteoStation;

      } else {
        this.close();
      }
    });
  }

}
