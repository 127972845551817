<form #form="ngForm" autocomplete="off">

<ss-page [modal]="true">
    <ss-page-header>
        <ss-page-header-title><ng-container i18n="Konfiguracja rurociągu|Tytuł modala Konfiguracja rurociągu@@modalConfigureWaterPipelineTitle">Konfiguracja rurociągu</ng-container> {{ waterPipeline.symbol }}</ss-page-header-title>
    </ss-page-header>
    <ss-page-panel>

        <form-range
            [disabled]="waterPipeline.isLocked"
            [max]="waterPipeline.maximumWaterPressure > 0 ? waterPipeline.maximumWaterPressure : 40"
            [min]="waterPipeline.minimumWaterPressure > 0 ? waterPipeline.minimumWaterPressure : 10"
            [step]="1"
            name="waterPipelineTargetWaterPressure"
            label="Ciśnienie zadane [bar]"
            i18n-label="Ciśnienie zadane [bar]|Tytuł modala Ciśnienie zadane [bar]@@modalConfigureWaterMaxWaterPressure"
            [(ngModel)]="targetWaterPressure">
        </form-range>

    </ss-page-panel>
    <ss-page-footer>
        <div></div>
        <ss-page-button-group>
            <button type="submit" [disabled]="waterPipeline.isLocked || form.invalid" class="btn btn-primary" (click)="save()"
              i18n="Zapisz zmiany|Zapisz zmiany@@modalConfigureWaterSaveButton">Zapisz zmiany</button>
            <button type="button" class="btn btn-default" (click)="close()"
              i18n="Anuluj|Anuluj@@modalConfigureWaterCancelButton">Anuluj</button>
        </ss-page-button-group>
    </ss-page-footer>
</ss-page>

</form>
