import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { TabComponent } from 'src/app/shared/components/tabs/tab/tab.component';
import { SnowCannon, ResortArea } from 'src/app/shared/models';
import { ModalRouteService } from 'src/app/application/modal-route.service';

@Component({
  selector: 'ss-snow-cannon-settings-tab',
  templateUrl: './snow-cannon-settings-tab.component.html',
  styleUrls: ['./snow-cannon-settings-tab.component.sass'],
  providers: [
    {provide: TabComponent, useExisting: forwardRef(() => SnowCannonSettingsTabComponent), multi: true}
  ]
})
export class SnowCannonSettingsTabComponent extends TabComponent {

  @Input()
  public snowCannon: SnowCannon;

  @Input()
  public area: ResortArea;

  constructor(
    private hes: ModalRouteService,
  ) {
    super();
  }

  public goToCannonConfig() {
    this.hes.showModalRoute('snow-cannon-config-modal/' + this.snowCannon.id);
  }

  public goToHydrantConfig() {
    this.hes.showModalRoute('connection-point-config-modal/' + this.snowCannon.connectionPoint);
  }

}
