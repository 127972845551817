import { Component, OnInit } from '@angular/core';
import { AreaData } from '../../area-data-resolver.service';
import { ActivatedRoute } from '@angular/router';
import {
  ResortArea,
  Schedule,
  MeteoStation,
  SnowCannon,
  ConnectionPoint,
  PumpRoom,
  CompressorRoom,
  PowerSource,
  Resort,
  WaterReservoir,
  SnowThickness,
  MeteoDevice
} from 'src/app/shared/models';
import { Subscription, Observable, of, ObjectUnsubscribedError } from 'rxjs';
import { SettingsService } from 'src/app/settings.service';
import { HeaderService } from 'src/app/header.service';
import { ResortService } from 'src/app/resort.service';
import { DevicesClientService, SnowThicknessClientService } from 'src/app/http-clients';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { SnowQualityValue } from 'src/app/shared/components/snow-quality/snow-quality.component';
import { AreaHeaderExtensionComponent } from '../../area-header-extension/area-header-extension.component';
import { MapService } from '../map.service';
import { ConfirmModalService } from 'src/app/application/confirm-modal.service';
import { ModalRouteService } from 'src/app/application/modal-route.service';
import { ProgressOfObservablesService } from 'src/app/application/modals/progress-of-observables.service';
import { dayjs, isArray, isNullOrUndefined, isObject } from '../../../../../../../../common';

@Component({
  selector: 'ss-area-panel',
  templateUrl: './area-panel.component.html',
  styleUrls: ['./area-panel.component.sass']
})
export class AreaPanelComponent implements OnInit {

  constructor(
    private confirmModalService: ConfirmModalService,
    private mapService: MapService,
    private route: ActivatedRoute,
    private cs: ConfigureClientService,
    private st: SnowThicknessClientService,
    private settingsService: SettingsService,
    private titleService: HeaderService,
    private ps: ProgressOfObservablesService,
    private mr: ModalRouteService) { }

  openMeteoSelectorModal() {
    this.mr.showModalRoute('meteo-conditions-source-selection-modal/' + this.area.id + '/');
    const sub = this.mr.results.subscribe(id => {
      if(id) {
        this.setAreaRemoteMeteoStation(this.area, id);
      }
      sub.unsubscribe();
    });
  }

  get minSnowCannonStartTemperature(): number {
    const cannons = this.area.getSnowCannons().filter(sc =>
      sc.connectionStatus
      && sc.CAP_CHANGE_START_TEMPERATURE
      && !isNullOrUndefined(sc.startTemperature)
    );
    return 0.1 * Math.round(10 * Math.min(...cannons.map(sc => sc.startTemperature)));
  }
  get maxSnowCannonStartTemperature(): number {
    const cannons = this.area.getSnowCannons().filter(sc =>
      sc.connectionStatus
      && sc.CAP_CHANGE_START_TEMPERATURE
      && !isNullOrUndefined(sc.startTemperature)
    );
    return 0.1 * Math.round(10 * Math.max(...cannons.map(sc => sc.startTemperature)));
  }

  get hasSnowCannonWithStartTemperature(): boolean {
    return this.area.getSnowCannons().filter(sc =>
      sc.connectionStatus
      && sc.CAP_CHANGE_START_TEMPERATURE
      && !isNullOrUndefined(sc.startTemperature)
    ).length > 0;
  }
  get hasSnowCannonWithStartFlow(): boolean {
    return this.area.getSnowCannons().filter(sc =>
      sc.connectionStatus
      && sc.CAP_CHANGE_START_WATER_FLOW
      && !isNullOrUndefined(sc.startWaterFlow)
    ).length > 0;
  }

  get minSnowCannonStartFlow(): number {
    const cannons = this.area.getSnowCannons().filter(sc =>
      sc.connectionStatus
      && sc.CAP_CHANGE_START_WATER_FLOW
      && !isNullOrUndefined(sc.startWaterFlow)
    );
    return 0.1 * Math.round(10 * Math.min(...cannons.map(sc => sc.startWaterFlow)));
  }
  get maxSnowCannonStartFlow(): number {
    const cannons = this.area.getSnowCannons().filter(sc =>
      sc.connectionStatus
      && sc.CAP_CHANGE_START_WATER_FLOW
      && !isNullOrUndefined(sc.startWaterFlow)
    );
    return 0.1 * Math.round(10 * Math.max(...cannons.map(sc => sc.startWaterFlow)));
  }

  windowCollapse = false;

  area: ResortArea;
  schedules: Schedule[];
  currentSchedule: Schedule;
  meteoStation: MeteoStation;
  statistics: any = {};
  snowCannons: SnowCannon[];
  connectionPoints: ConnectionPoint[];
  pumpRooms: PumpRoom[];
  compressorRooms: CompressorRoom[];
  powerSources: PowerSource[];
  meteoStations: MeteoStation[];
  waterReservoirs: WaterReservoir[];

  public devIds: string[] = [];

  closestScheduleHours: string;

  public resort: Resort;

  public sTProviderId: string;
  public sTYear: number;
  public sTMonth: number;
  public sTDay: number;
  public sTMap: any = null;
  public lockSt = false;

  canNotShowSnowThickness = false;

  public objectKeys(obj: any): any[] {
    if (obj && isObject(obj)) {
      return Object.keys(obj);
    }
    return [];
  }

  toggleAreaStartStop(area: ResortArea, value: boolean) {
    const cannons = this.area.getSnowCannons().filter(sc => sc.connectionStatus);
    let actions: Observable<any>[] = [];
    if (value) {
      actions = cannons.map(sc => this.cs.startDevice(sc.id));
    } else {
      actions = cannons.map(sc => this.cs.stopDevice(sc.id));
    }
    this.ps.runProgressAction(actions);
  }

  setAreaSnowQuality(area: ResortArea, event: SnowQualityValue) {
    let cannons = this.area.getSnowCannons().filter(sc => sc.connectionStatus);
    let om = {};
    if (area.operationMode) {
      om = {
        snowQualityType: event.type,
        snowQualityConstant: event.current,
        snowQualityLow: event.low,
        snowQualityHigh: event.high
      };
      if (event.type > 0) {
        cannons = cannons.filter(c => c.operationMode);
      }
    } else {
      om = {
        snowQualityType: 0,
        snowQualityConstant: event.current
      };
    }

    const actions: Observable<any>[] = cannons.map(sc => this.cs.configureSnowCannonById(sc.id, om));
    actions.push(this.cs.configureArea(area, om));
    this.ps.runProgressAction(actions);
  }

  setAreaOperationMode(area: ResortArea, mode: boolean) {
    let question: Observable<boolean> = of(true);
    if (area.operationMode === 1) {
      let autoStartedSnowCannons = 0;

      area.snowCannonRefs.forEach(sc => {
        if (sc.operationMode === 1 && sc.computedStatus === 'working') {
          autoStartedSnowCannons++;
        }
      });

      if (autoStartedSnowCannons > 0) {
        question = this.confirmModalService.openAlertChangeWillShutDownSnowCannons(autoStartedSnowCannons);
      }
    }

    question.subscribe(answer => {
      if (answer) {
        this.configureArea(area, {
          scheduleSchema: area.scheduleSchema,
          operationMode: mode
        });
      }
    });
  }

  setAreaRemoteMeteoStation(area: ResortArea, msId: string) {
    this.configureArea(area, {
      remoteMeteoStation: msId
    });
  }

  private configureArea(area: ResortArea, data: any) {
    this.cs.configureArea(area, data).subscribe(() => {}, () => {});
  }

  toggleWindowCollapse() {
    this.windowCollapse = !this.windowCollapse;
    if (this.windowCollapse) {
      this.mapService.mapToAreaWithoutMenus();
    } else {
      this.mapService.mapToArea(this.area);
    }
  }

  setsTProviderId(x: string) {
    this.updateST(x);
  }
  setsTYear(x: number) {
    this.updateST(this.sTProviderId, x);
  }
  setsTMonth(x: number) {
    this.updateST(this.sTProviderId, this.sTYear, x);
  }
  setsTDay(x: number) {
    this.updateST(this.sTProviderId, this.sTYear, this.sTMonth, x);
  }
  updateST(p: string = null, y: number = null, m: number = null, d: number = null) {
    if (this.lockSt) {
      return;
    }

    if (!isObject(this.sTMap) || Object.keys(this.sTMap).length === 0) {
      this.canNotShowSnowThickness = true;
      return;
    }

    this.lockSt = true;
    if (!isObject(this.sTMap[p])) {
      p = Object.keys(this.sTMap)[0];
    }

    if (!isObject(this.sTMap[p][y])) {
      y = parseInt(Object.keys(this.sTMap[p]).pop(), 10);
    }

    if (!isObject(this.sTMap[p][y][m])) {
      m = parseInt(Object.keys(this.sTMap[p][y]).pop(), 10);
    }

    if (!this.sTMap[p][y][m][d]) {
      d = parseInt(Object.keys(this.sTMap[p][y][m]).pop(), 10);
    }

    if (this.sTMap[p][y][m][d]) {
      this.sTProviderId = p;
      this.sTYear = y;
      this.sTMonth = m;
      this.sTDay = d;
    } else {
      this.sTYear = null;
      this.sTMonth = null;
      this.sTDay = null;
    }

    this.st.getOne(this.sTProviderId, dayjs(this.sTYear + '-' + this.sTMonth + '-' + this.sTDay)).subscribe(dt => {
      this.mapService.updateSnowThicknesLayer(dt);
      this.lockSt = false;
    }, (e) => {
      console.error(e);
      this.lockSt = false;
    });
  }

  toggleSnowThicknessLayer() {
    this.mapService.setShowLayers('snowThickness');
  }

  ngOnInit() {
    this.route.data
      .subscribe((data: { areaData: AreaData }) => {
        Object.assign(this, data.areaData);

        this.settingsService.append('recentAreaIds', this.area.id, 10);

        this.titleService.setUpdateHeader('menuSnowCannons', AreaHeaderExtensionComponent, {
          resortName: this.resort.symbol,
          area: this.area,
          isMap: true
        });

        this.devIds = [
          this.area.id,
          ... this.snowCannons.map(e => e.id),
          ... this.pumpRooms.map(e => e.id),
          ... this.powerSources.map(e => e.id),
          ... this.compressorRooms.map(e => e.id),
          ... this.meteoStations.map(e => e.id),
        ];

        this.closestScheduleHours = this.currentSchedule.getClosest();

        if (data.areaData.snowThickness && data.areaData.snowThickness.latest) {
          const l = data.areaData.snowThickness.latest;
          this.sTMap = {};
          Object.keys(l).forEach(k => {
            if (!this.sTMap[k]) {
              this.sTMap[k] = {};
            }

            if (isArray(l[k])) {
              l[k].forEach((d: string) => {
                const dx =  d.split('-');

                const year = parseInt(dx[0], 10);
                const month = parseInt(dx[1], 10);
                const day = parseInt(dx[2], 10);

                if (!this.sTMap[k][year]) {
                  this.sTMap[k][year] = {};
                }
                if (!this.sTMap[k][year][month]) {
                  this.sTMap[k][year][month] = {};
                }

                this.sTMap[k][year][month][day] = day;

              });
            }
          });
          this.updateST();
        }

        this.mapService.mapToArea(this.area);
        this.mapService.setShowLayers('resortArea', 'snowCannons', 'meteoStations');
      });
  }

  toggleProperty(object, property) {
    object[property] = !object[property];
  }

  trackByFn(i, e) {
    return e['id'];
  }

}
