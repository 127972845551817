import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ss-pump-rooms',
  templateUrl: './pump-rooms.component.html',
  styleUrls: ['./pump-rooms.component.sass']
})
export class PumpRoomsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
