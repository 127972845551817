import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/shared/models';

@Component({
  selector: 'ss-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.sass']
})
export class UserAvatarComponent implements OnInit {

  constructor() { }

  private _user: User;
  @Input()
  get user(): User {
    return this._user;
  }
  set user(u: User) {
    this._user = u;

    if (!this._user.roles) {
      this.role = 'ROLE_NONE';
    } else if (this._user.roles.indexOf('ROLE_SUPERADMIN') >= 0) {
      this.role = 'ROLE_SUPERADMIN';
    } else if (this._user.roles.indexOf('ROLE_ADMIN') >= 0) {
      this.role = 'ROLE_ADMIN';
    } else if (this._user.roles.indexOf('ROLE_OPERATOR') >= 0) {
      this.role = 'ROLE_OPERATOR';
    } else if (this._user.roles.indexOf('ROLE_OBSERVER') >= 0) {
      this.role = 'ROLE_OBSERVER';
    } else {
      this.role = 'ROLE_NONE';
    }
  }

  role: string;

  @Input()
  showDescription = false;

  @Input()
  size: string;

  ngOnInit() {
  }

}
