import { TimedChartConfigSeries } from '../..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { D3DrawerData } from '../../models/charts/d3-drawer-data.model';
import { getSeriesColor } from './get-series-color.function';

export const drawSeriesPoint = (
  state: D3DrawerChartState,
  data: D3DrawerData,
  series: TimedChartConfigSeries,
  seriesIndex: number,
  seriesIndexInType: number
): D3DrawerChartState => {
  const xAxisScale = state.generatedValuesStorage.get('xAxisScale');
  const xAxisBandScale = state.generatedValuesStorage.get('xAxisBandScale');
  const pointYAxisScale = state.generatedValuesStorage.get(`series${seriesIndex}YAxisScale`);
  const t = state.generatedCanvas.transition();
  const animationTime = series.animationTime || state.config.animationTime || 0;

  const color = series.primaryColor || getSeriesColor(series, seriesIndexInType);
  state.generatedValuesStorage.set(`series${seriesIndex}BaseColor`, color);

  let initialDuration = false;
  let seriesG = state.generatedCanvas.select(`.series${seriesIndex}`);
  if (seriesG.empty()) {
    seriesG = state.generatedCanvas.append('g')
      .attr('opacity', 0)
      .attr('class', `bars-g series${seriesIndex}`);
    initialDuration = true;
  }
  seriesG.transition().duration(initialDuration ? 0 : animationTime)
    .attr('opacity', series.opacity);

  const points = seriesG
    .selectAll('.point')
    .data(data, (d: { labels }) => d.labels);

  points.exit()
    .remove();
  const barG = points.enter()
    .append('g')
    .attr('class', 'point')
    .attr('transform', (d, i) => 'translate(' + xAxisScale(series.labelFieldName ? +d[series.labelFieldName] : d.labels) + ',0)');
  barG
    .append('circle')
    .attr('fill', color)
    .attr('stroke-width', 0)
    .attr('r', 3)
    .attr('cx', 0)
    .attr('cy', d => (pointYAxisScale(d[series.valueFieldName]) || 0) );

  points.merge(points)
    .attr('transform', (d, i) => 'translate(' + xAxisScale(series.labelFieldName ? +d[series.labelFieldName] : d.labels) + ',0)');
  points.merge(points)
    .select('circle')
    .attr('r', 3)
    .attr('cy', d => (pointYAxisScale(d[series.valueFieldName]) || 0) )
    .attr('fill', color);

  return state;
};
