import { Injectable } from '@angular/core';
import { UsersClientService } from 'src/app/http-clients';
import { UserStats } from 'src/app/shared/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { dayjs } from '../../../../../common';

@Injectable({
  providedIn: 'root'
})
export class UserStatsResolverService implements Resolve<UserStats> {

  constructor(private ucs: UsersClientService) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserStats> | Observable<never> {

    const hnow = Math.ceil((dayjs().valueOf() / 1000));

    if (!route.paramMap.has('userId')) {
      return this.ucs.getStats('0', hnow - 7 * 24 * 3600, hnow);
    }

    const id: number = parseInt(route.paramMap.get('userId'), 10);

    if (!id || isNaN(id)) {
      return of(null);
    }

    return this.ucs.getStats(id, hnow - 7 * 24 * 3600, hnow);
  }
}
