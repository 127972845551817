import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HeaderExtensionComponent } from '../../../header-extension-host.directive';
import { ModalRouteService } from '../../../../application/modal-route.service';

@Component({
  selector: 'ss-areas-header-extension',
  templateUrl: './areas-header-extension.component.html',
  styleUrls: ['./areas-header-extension.component.sass']
})
export class AreasHeaderExtensionComponent implements HeaderExtensionComponent, OnInit, OnDestroy {
  constructor(
    private hes: ModalRouteService
  ) {}

  data: any;

  isRecentRoutesExpanded = false;
  isModalShownSubscription: Subscription;

  updateData(data: any) {
    this.data = data;
  }
  ngOnInit() {
    this.isModalShownSubscription = this.hes.isModalShown.subscribe(data => {
      this.isRecentRoutesExpanded = data === 'resort-areas-recent';
    });
  }
  ngOnDestroy(): void {
    this.isModalShownSubscription.unsubscribe();
  }

  toggleRecentRoutes() {
    this.hes.showModalRoute('resort-areas-recent');
  }

}
