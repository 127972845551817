<div class="row justify-content-between align-items-center h-100" *ngIf="sourceDevice; else empty">
  <div class="col">
    <ss-translated-device-type-symbol [device]="sourceDevice"></ss-translated-device-type-symbol>
  </div>
  <div class="col-auto">
    <ss-meteo-conditions-display [sourceDevice]="sourceDevice"></ss-meteo-conditions-display>
  </div>
</div>
<ng-template #empty>
  <p class="empty" i18n="Nie wybrano żadnego źródła danych meteo|Np nad panelem stoku na mapie@@meteoConditionsNamedDisplayEmptySource">Nie wybrano żadnego źródła danych meteo</p>
</ng-template>