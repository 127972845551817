import { D3 } from '..';
import { dayjs } from '../..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { D3DrawerData } from '../../models/charts/d3-drawer-data.model';

export const drawXAxisScaleBottom = (state: D3DrawerChartState, data: D3DrawerData): D3DrawerChartState => {
  const min = D3.min(data, d => +d[state.config.labelFieldName]);
  const max = D3.max(data, d => +d[state.config.labelFieldName]);

  let range: [number, number] = [ 5, state.viewBoxWidth - 5 ];
  if(['single','single-with-line' ].includes(state.config.yAxisType)) {
    range = [ 85, state.viewBoxWidth - 20 ];
  } else if(['double','double-with-line' ].includes(state.config.yAxisType)) {
    range = [ 85, state.viewBoxWidth - 85 ];
  }

  let xAxisScale = state.generatedValuesStorage.get('xAxisScale');
  if (!xAxisScale) {
    xAxisScale = D3.scaleUtc();
    state.generatedValuesStorage.set('xAxisScale', xAxisScale);
  }

  xAxisScale
    .range(range)
    .domain([ min, max ]);

  let xAxisBandScale = state.generatedValuesStorage.get('xAxisBandScale');
  if (!xAxisBandScale) {
    xAxisBandScale = D3.scaleBand()
      .padding(0);
    state.generatedValuesStorage.set('xAxisBandScale', xAxisBandScale);
  }
  xAxisBandScale
    .range(range)
    .domain(data.map( d => `${d[state.config.labelFieldName]}`));

  let xAxis = state.generatedValuesStorage.get('xAxis');
  if(!xAxis) {
    xAxis = D3.axisBottom(xAxisScale)
      .ticks(D3.utcHour.every(1))
      .tickSize(0)
      .tickPadding(5)
      .tickFormat((val, ind) => dayjs(+val).tz().format('HH'));
    state.generatedValuesStorage.set('xAxis', xAxis);
  }
  xAxis.ticks(D3.utcHour.every(1));

  let xAxisGroup = state.generatedCanvas.select('.x-axis-bottom');
  if(xAxisGroup.empty()) {
    xAxisGroup = state.generatedCanvas.append('g')
      .attr('class', 'x-axis-bottom')
      .attr('transform', 'translate(0, ' + (state.viewBoxHeight - 15) + ')')
      .attr('stroke-width', 0)
      .style('color', '#B9BBC8')
      .style('font-size', '12')
      .style('font-family', 'Montserrat')
      .call(xAxis);
  }
  xAxisGroup.call(xAxis);

  // xAxisGroup.selectAll('.tick text')
  //   .attr('text-anchor', 'middle')
  // xAxisGroup.select('.tick:first-of-type text')
  //   .attr('text-anchor', 'start')
  // xAxisGroup.select('.tick:last-of-type text')
  //   .attr('text-anchor', 'end')

  return state;
};
