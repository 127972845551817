import { TimedChartConfigSeries } from '../..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { D3DrawerData } from '../../models/charts/d3-drawer-data.model';
import { getSeriesColor } from './get-series-color.function';

export const drawSeriesBar = (
  state: D3DrawerChartState,
  data: D3DrawerData,
  series: TimedChartConfigSeries,
  seriesIndex: number,
  seriesIndexInType: number
): D3DrawerChartState => {
  const xAxisScale = state.generatedValuesStorage.get('xAxisScale');
  const xAxisBandScale = state.generatedValuesStorage.get('xAxisBandScale');
  const barsYAxisScale = state.generatedValuesStorage.get(`series${seriesIndex}YAxisScale`);
  const t = state.generatedCanvas.transition();
  const animationTime = series.animationTime || state.config.animationTime || 0;

  const color = series.primaryColor || getSeriesColor(series, seriesIndexInType);
  state.generatedValuesStorage.set(`series${seriesIndex}BaseColor`, color);

  let maxWidthOfBar = xAxisBandScale.bandwidth();
  const barSeriesCount = state.config.series.filter(s => s.seriesType === 'bar').length;

  let basSeriesOffset = 0;
  if(barSeriesCount > 1) {
    maxWidthOfBar = maxWidthOfBar / barSeriesCount;
    basSeriesOffset = maxWidthOfBar * seriesIndexInType;
  }
  let widthOfBar = maxWidthOfBar > 10 ? 10: maxWidthOfBar;
  widthOfBar = widthOfBar > 2 ? widthOfBar - 1 : widthOfBar;

  let initialDuration = false;
  let seriesG = state.generatedCanvas.select(`.series${seriesIndex}`);
  if (seriesG.empty()) {
    seriesG = state.generatedCanvas.append('g')
      .attr('opacity', 0)
      .attr('class', `bars-g series${seriesIndex}`);
    initialDuration = true;
  }
  seriesG.transition().duration(initialDuration ? 0 : animationTime)
    .attr('opacity', series.opacity);

  let yOffset = 15;
  if(state.config.xAxisType === 'invisible') {
    yOffset = 5;
  }

  const bars = seriesG
    .selectAll('.bar')
    .data(data, (d: { labels }) => d.labels);

  bars.exit()
    .remove();
  const barG = bars.enter()
    .append('g')
    .attr('class', 'bar')
    .style('opacity', (d, i) => i === data.length - 1 ? 0 : 1)
    .attr('transform', (d, i) => 'translate(' + xAxisScale(series.labelFieldName ? +d[series.labelFieldName] : d.labels) + ',0)');
  barG
    .append('rect')
    .attr('fill', color)
    .attr('stroke-width', 0)
    .attr('width', widthOfBar)
    .attr('x', basSeriesOffset)
    .attr('height', d => (state.viewBoxHeight - yOffset - barsYAxisScale(d[series.valueFieldName])) || 0 )
    .attr('y', d => (barsYAxisScale(d[series.valueFieldName]) || 0) );

  bars.merge(bars)
    .style('opacity', (d, i) => i === data.length - 1 ? 0 : 1)
    .attr('transform', (d, i) => 'translate(' + xAxisScale(series.labelFieldName ? +d[series.labelFieldName] : d.labels) + ',0)');
  bars.merge(bars)
    .select('rect')
    .attr('width', widthOfBar)
    .attr('height', d => (state.viewBoxHeight - yOffset - barsYAxisScale(d[series.valueFieldName])) || 0 )
    .attr('y', d => (barsYAxisScale(d[series.valueFieldName]) || 0) )
    .attr('fill', color);

  return state;
};
