import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ss-page-accordion-block',
  templateUrl: './page-accordion-block.component.html',
  styleUrls: ['./page-accordion-block.component.sass']
})
export class PageAccordionBlockComponent implements OnInit {

  constructor() { }

  @Output()
  public expandedChanged: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public expand = false;

  public showOverflow = false;
  private showOverflowTimeout;

  ngOnInit() {
  }

  public toggleExpanded() {
    this.expand = !this.expand;

    clearTimeout(this.showOverflowTimeout);
    if (this.expand) {
      this.showOverflowTimeout = setTimeout(() => {
        this.showOverflow = true;
      }, 500);
    } else {
      this.showOverflow = false;
    }

    this.expandedChanged.emit(this.expand);
  }
}
