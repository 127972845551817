import { PumpRoom } from './pump-room.model';
import { ResortArea } from './resort-area.model';
import { AbstractDevice } from './abstract-device.model';
import { WaterReservoir } from './water-reservoir.model';
import { ConvertUnit, DeviceSystemType } from '../../../../../common';

export class WaterPipeline extends AbstractDevice {
    systemType: DeviceSystemType = 'WaterPipeline';

    points: any;
    position: any;
    symbol: string;
    configuration: {
      canBeStarted: boolean;
      hasFlowMeter: boolean;
      hasPressureMeter: boolean;
      hasWaterThermometer: boolean;
      hasMeteredWaterConsumption: boolean;
      hasMeteredWaterConsumption1h: boolean;
      canChangeTargetWatterPressure: boolean;
    };

    waterSource: string;
    waterReservoir: string;

    pumpRoomRef: PumpRoom;
    waterReservoirRef?: WaterReservoir;
    resortAreaRefs: Map<string, ResortArea> = new Map();

    startCommand: boolean;
    alertBreakdown: boolean;
    alertEmergencyButtonPressed: boolean;
    automaticModeEnabled: boolean;
    infoFullAutoReady: boolean;
    infoPipelineFilled: boolean;
    infoReady: boolean;
    infoRemoteModeEnabled: boolean;
    infoWorking: boolean;
    infoWorkLockUnlocked: boolean;
    manualModeEnabled: boolean;


    @ConvertUnit('waterFlow', 'm3/h')
    currentWaterFlow: number;
    currentWaterPressure: number;
    currentWaterTemperature: number;
    limitWaterFlow: number;
    @ConvertUnit('waterFlow', 'm3/h')
    maximumWaterFlow: number;
    maximumWaterPressure: number;
    @ConvertUnit('waterFlow', 'm3/h')
    minimumWaterFlow: number;
    minimumWaterPressure: number;
    targetWaterPressure: number;

    meteredWaterConsumption: number;

    isLocked = false;

    getResortAreas(): ResortArea[] {
        return Array.from(this.resortAreaRefs.values());
    }

    countResortAreas(): number {
        return Array.from(this.resortAreaRefs.values()).length;
    }
}
