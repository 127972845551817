import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';

export const drawLegend = (state: D3DrawerChartState): D3DrawerChartState => {
  let lgg = state.generatedCanvas.select('g.chart-legend-group');

  if(!state.config.legend) {
    return state;
  }

  if (lgg.empty()) {
    lgg = state.generatedCanvas.append('g')
      .attr('class', 'chart-legend-group');
  }

  let seriesCtx = 0;
  let currentSeriesGroupOffset = 0;
  state.config.series.forEach((series, seriesIndex) => {
    if(series.isVisibleOnLegend) {
      seriesCtx++;


      let seriesGroup = lgg.select('g.legend-series.series-group-' + seriesCtx);
      if (seriesGroup.empty()) {
        seriesGroup = lgg.append('g')
          .attr('class', 'legend-series series-group-' + seriesCtx);
      }

      let seriesColor = seriesGroup.select('rect');
      if(seriesColor.empty()) {
        seriesColor = seriesGroup.append('rect')
          .attr('rx', 2);

        if(series.seriesType === 'line') {
          seriesColor
            .attr('y', 5)
            .attr('x', 4)
            .style('width', 12)
            .style('height', 3);
        } else {
          seriesColor
            .attr('y', 0)
            .attr('x', 6)
            .style('width', 8)
            .style('height', 12);
        }
      }
      seriesColor.style('fill', state.generatedValuesStorage.get(`series${seriesIndex}BaseColor`));

      let seriesLabel = seriesGroup.select('text.tool-tip-series-label');
      if(seriesLabel.empty()) {
        seriesLabel = seriesGroup.append('text')
          .attr('class', 'tool-tip-series-label')
          .attr('x', 18)
          .attr('y', 1);
      }
      seriesLabel.text(`${series.seriesName}`);

      seriesGroup.attr('transform', 'translate(' + currentSeriesGroupOffset + ' 0)');

      currentSeriesGroupOffset = currentSeriesGroupOffset + (seriesLabel.node() as any).getBBox().width + 25;
    }
  });

  lgg.attr('transform', 'translate(' + (state.viewBoxWidth - currentSeriesGroupOffset - 80) + ' 0)');

  return state;
};
