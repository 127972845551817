<div class="compass">
    <div class="compass__decorations">

        <span class="n" i18n-title="Północ|Kierunki świata - pełne nazwy@@globalSymbolWorldDirNorthFull"
          title="Północ"
          i18n="N|Kierunki świata - skróty@@globalSymbolWorldDirNorthAbbr">N</span>
        <span class="s" i18n-title="Południe|Kierunki świata - pełne nazwy@@globalSymbolWorldDirSouthFull"
          title="Południe"
          i18n="S|Kierunki świata - skróty@@globalSymbolWorldDirSouthAbbr">S</span>
        <span class="w" i18n-title="Zachód|Kierunki świata - pełne nazwy@@globalSymbolWorldDirWestFull"
          title="Zachód"
          i18n="W|Kierunki świata - skróty@@globalSymbolWorldDirWestAbbr">W</span>
        <span class="e" i18n-title="Wschód|Kierunki świata - pełne nazwy@@globalSymbolWorldDirEastFull"
          title="Wschód"
          i18n="E|Kierunki świata - skróty@@globalSymbolWorldDirEastAbbr">E</span>
        <div class="c">
            <div class="h"></div>
            <div class="v"></div>
            <div class="d" [ngStyle]="{ 'transform': 'rotate(' + shadowDirection + 'deg)' }"></div>
        </div>
    </div>
    <div class="compass__container">
        <ng-content></ng-content>
    </div>
</div>
