import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { CoolingTower, DrawableComponent } from 'src/app/shared/models/pump-room';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[ss-full-schema-cooling-tower]',
  templateUrl: './cooling-tower.component.html',
  styleUrls: ['./cooling-tower.component.sass']
})
export class CoolingTowerComponent implements OnInit {

  @Input()
  public allowEdit = false;

  constructor() { }

  @Input()
  public drawable: DrawableComponent<CoolingTower>;

  ngOnInit() {
  }

  @HostBinding('class.ready')
  get ready(): boolean {
    return this.drawable && this.drawable.component['isReady'];
  }

  @HostBinding('class.working')
  get working(): boolean {
    return this.drawable && this.drawable.component['isWorking'];
  }

  @HostBinding('class.breakdown')
  get breakdown(): boolean {
    return this.hasSensorData && ['connection-lost', 'breakdown'].includes(this.drawable.component['computedStatus']);
  }
  @HostBinding('class.sensored')
  get hasSensorData(): boolean {
    return this.drawable && this.drawable.component['hasSensorData'];
  }
}
