import { DeviceSystemType } from '..';
import { UnitType } from '../..';

export interface SubjectFieldInterface {
  fieldName: string;
  type: 'aggregated' | 'status';
  chartSeriesType: 'line' | 'bar';
  storedUnitOverride?: string;
  unitType: UnitType;
}

export const subjectFieldMapping: {
  [subjectType: string]: {
    systemType: DeviceSystemType;
    fields: {
      [fieldName: string]: SubjectFieldInterface;
    };
  };
} = {
  ConnectionPoint: {
    systemType: 'ConnectionPoint',
    fields: {
      snowProductionVolume: {
        fieldName: 'snowProductionVolume',
        type: 'aggregated',
        chartSeriesType: 'bar',
        unitType: 'production'
      },
      waterFlow: {
        fieldName: 'waterFlow',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'waterFlow'
      },
      powerConsumption: {
        fieldName: 'powerConsumption',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'powerUsage'
      },
      wetBulbTemperature: {
        fieldName: 'wetBulbTemperature',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'temperature'
      },
    }
  },
  ElectricalLine: {
    systemType: 'ElectricalLine',
    fields: {
      powerDraw: {
        fieldName: 'powerDraw',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'powerDraw'
      },
      averagePowerDraw: {
        fieldName: 'averagePowerDraw',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'powerDraw'
      },
      reactivePowerDraw: {
        fieldName: 'reactivePowerDraw',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'powerDraw'
      },
      voltagePhaseL1ToN: {
        fieldName: 'voltagePhaseL1ToN',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'voltage'
      },
      voltagePhaseL2ToN: {
        fieldName: 'voltagePhaseL2ToN',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'voltage'
      },
      voltagePhaseL3ToN: {
        fieldName: 'voltagePhaseL3ToN',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'voltage'
      },
      voltagePhaseL1ToL2: {
        fieldName: 'voltagePhaseL1ToL2',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'voltage'
      },
      voltagePhaseL2ToL3: {
        fieldName: 'voltagePhaseL2ToL3',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'voltage'
      },
      voltagePhaseL3ToL1: {
        fieldName: 'voltagePhaseL3ToL1',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'voltage'
      },
      averageVoltagePhaseToNeutral: {
        fieldName: 'averageVoltagePhaseToNeutral',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'voltage'
      },
      averageVoltagePhaseToPhase: {
        fieldName: 'averageVoltagePhaseToPhase',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'voltage'
      },
      currentPhaseL1: {
        fieldName: 'currentPhaseL1',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'current'
      },
      currentPhaseL2: {
        fieldName: 'currentPhaseL2',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'current'
      },
      currentPhaseL3: {
        fieldName: 'currentPhaseL3',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'current'
      },
      averageCurrentOfAllPhases: {
        fieldName: 'averageCurrentOfAllPhases',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'current'
      },
      meteredPowerConsumption: {
        fieldName: 'meteredPowerConsumption',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'powerUsage'
      },
    }
  },
  MeteoStation: {
    systemType: 'MeteoStation',
    fields: {
      windSpeed: {
        fieldName: 'windSpeed',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'windSpeed'
      },
      wetBulbTemperature: {
        fieldName: 'wetBulbTemperature',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'temperature'
      },
      airTemperature: {
        fieldName: 'airTemperature',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'temperature'
      },
      atmosphericAirPressure: {
        fieldName: 'atmosphericAirPressure',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'atmosphericAirPressure'
      },
      airHumidity: {
        fieldName: 'airHumidity',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'humidity'
      },
    }
  },
  PowerSource: {
    systemType: 'PowerSource',
    fields: {
      powerDraw: {
        fieldName: 'powerDraw',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'powerDraw'
      },
    }
  },
  PumpRoom: {
    systemType: 'PumpRoom',
    fields: {
      waterFlow: {
        fieldName: 'waterFlow',
        type: 'aggregated',
        storedUnitOverride: 'm3/h',
        chartSeriesType: 'line',
        unitType: 'waterFlow'
      },
      waterPressure: {
        fieldName: 'waterPressure',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'waterPressure'
      },
    }
  },
  SnowCannon: {
    systemType: 'SnowCannon',
    fields: {
      waterFlow: {
        fieldName: 'waterFlow',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'waterFlow'
      },
      powerConsumption: {
        fieldName: 'powerConsumption',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'powerDraw'
      },
      waterTemperature: {
        fieldName: 'waterTemperature',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'temperature'
      },
      waterPressure: {
        fieldName: 'waterPressure',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'waterPressure'
      },
      snowProductionVolume: {
        fieldName: 'snowProductionVolume',
        type: 'aggregated',
        chartSeriesType: 'bar',
        unitType: 'production'
      },
      airTemperature: {
        fieldName: 'airTemperature',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'temperature'
      },
      airHumidity: {
        fieldName: 'airHumidity',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'humidity'
      },
      wetBulbTemperature: {
        fieldName: 'wetBulbTemperature',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'temperature'
      },
    }
  },
  WaterPipeline: {
    systemType: 'WaterPipeline',
    fields: {
      waterFlow: {
        fieldName: 'waterFlow',
        type: 'aggregated',
        storedUnitOverride: 'm3/h',
        chartSeriesType: 'line',
        unitType: 'waterFlow'
      },
      waterPressure: {
        fieldName: 'waterPressure',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'waterPressure'
      },
      waterTemperature: {
        fieldName: 'waterTemperature',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'temperature'
      },
      meteredWaterConsumption: {
        fieldName: 'meteredWaterConsumption',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'waterUsage'
      },
    }
  },
  WaterReservoir: {
    systemType: 'WaterReservoir',
    fields: {
      waterVolume: {
        fieldName: 'waterVolume',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'waterUsage'
      },
      waterLevel: {
        fieldName: 'waterLevel',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'length'
      },
      waterTemperature: {
        fieldName: 'waterTemperature',
        type: 'aggregated',
        chartSeriesType: 'line',
        unitType: 'temperature'
      },
    }
  },
  // WorkingArea: {
  // systemType: 'WorkingArea',
  //   fields: {
  //     wetBulbTemperature: {
  //       fieldName: 'wetBulbTemperature',
  //       type: 'aggregated',
  //       chartSeriesType: 'line',
  //       unitType: 'temperature'
  //     },
  //     snowProductionVolume: {
  //       fieldName: 'snowProductionVolume',
  //       type: 'aggregated',
  //       chartSeriesType: 'bar',
  //       unitType: 'production'
  //     },
  //     waterFlow: {
  //       fieldName: 'waterFlow',
  //       type: 'aggregated',
  //       chartSeriesType: 'line',
  //       unitType: 'waterFlow'
  //     },
  //     powerConsumption: {
  //       fieldName: 'powerConsumption',
  //       type: 'aggregated',
  //       chartSeriesType: 'line',
  //       unitType: 'powerUsage'
  //     },
  //   }
  // },
  // Resort: {
  // systemType: 'Resort',
  //   fields: {
  //     snowProductionVolume: {
  //       fieldName: 'snowProductionVolume',
  //       type: 'aggregated',
  //       chartSeriesType: 'bar',
  //       unitType: 'production'
  //     },
  //     waterFlow: {
  //       fieldName: 'waterFlow',
  //       type: 'aggregated',
  //       chartSeriesType: 'line',
  //       unitType: 'waterFlow'
  //     },
  //     powerConsumption: {
  //       fieldName: 'powerConsumption',
  //       type: 'aggregated',
  //       chartSeriesType: 'line',
  //       unitType: 'powerUsage'
  //     },
  //   }
  // },
  // ResortArea: {
  // systemType: 'ResortArea',
  //   fields: {
  //     wetBulbTemperature: {
  //       fieldName: 'wetBulbTemperature',
  //       type: 'aggregated',
  //       chartSeriesType: 'line',
  //       unitType: 'temperature'
  //     },
  //     snowProductionVolume: {
  //       fieldName: 'snowProductionVolume',
  //       type: 'aggregated',
  //       chartSeriesType: 'bar',
  //       unitType: 'production'
  //     },
  //     waterFlow: {
  //       fieldName: 'waterFlow',
  //       type: 'aggregated',
  //       chartSeriesType: 'line',
  //       unitType: 'waterFlow'
  //     },
  //     powerConsumption: {
  //       fieldName: 'powerConsumption',
  //       type: 'aggregated',
  //       chartSeriesType: 'line',
  //       unitType: 'powerUsage'
  //     },
  //   }
  // },
};
