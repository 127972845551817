<ss-snow-cannon-position-editor
  [currentMapBearing]="currentMapBearing"
  [areaCenterX]="snowingSettingsAreaCenterX"
  [areaCenterY]="snowingSettingsAreaCenterY"
  [snowCannon]="snowCannon"
  (executeCommand)="positionAndOscillationCommand($event)">
</ss-snow-cannon-position-editor>

<div class="wind-on-map-wrapper" *ngIf="meteoStation" [ngClass]="{ 'wind-on-map-wrapper--is-shown': showWindOnMap }"
  [ngStyle]="{
    'background-image': 'url(data:image/svg+xml;encoding=utf-8,%3Csvg%20width%3D%22200%22%20height%3D%22200%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3Cfilter%20id%3D%22shadow%22%3E%3CfeDropShadow%20dx%3D%221%22%20dy%3D%221%22%20stdDeviation%3D%222%22%2F%3E%3C%2Ffilter%3E%3C%2Fdefs%3E%3Cg%20transform%3D%22rotate%28'
    + (meteoStation ? 180 + meteoStation.windDirection : 0)
      + '%20100%20100%29%22%20style%3D%22fill%3A%23ffffff%3Bfilter%3Aurl%28%23shadow%29%3B%22%3E%3Cpath%20d%3D%22m73.68255%2C94.5l0%2C-6.5c0%2C-0.27614%20-0.22386%2C-0.5%20-0.5%2C-0.5c-0.27614%2C0%20-0.5%2C0.22386%20-0.5%2C0.5l0%2C6.5l-2%2C0l2.5%2C4l2.5%2C-4l-2%2C0z%22%2F%3E%3Cpath%20d%3D%22m127.31744%2C94.92735l0%2C-6.5c0%2C-0.27614%20-0.22386%2C-0.5%20-0.5%2C-0.5c-0.27614%2C0%20-0.5%2C0.22386%20-0.5%2C0.5l0%2C6.5l-2%2C0l2.5%2C4l2.5%2C-4l-2%2C0z%22%2F%3E%3Cpath%20d%3D%22m100.31744%2C108.5l0%2C-6.5c0%2C-0.27614%20-0.22386%2C-0.5%20-0.5%2C-0.5c-0.27614%2C0%20-0.5%2C0.22386%20-0.5%2C0.5l0%2C6.5l-2%2C0l2.5%2C4l2.5%2C-4l-2%2C0z%22%2F%3E%3Cpath%20d%3D%22m87.31744%2C101.70699l0%2C-6.5c0%2C-0.27614%20-0.22386%2C-0.5%20-0.5%2C-0.5c-0.27614%2C0%20-0.5%2C0.22386%20-0.5%2C0.5l0%2C6.5l-2%2C0l2.5%2C4l2.5%2C-4l-2%2C0z%22%2F%3E%3Cpath%20d%3D%22m113.31744%2C101.5l0%2C-6.5c0%2C-0.27614%20-0.22386%2C-0.5%20-0.5%2C-0.5c-0.27614%2C0%20-0.5%2C0.22386%20-0.5%2C0.5l0%2C6.5l-2%2C0l2.5%2C4l2.5%2C-4l-2%2C0z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E)',
    'background-position': snowingSettingsAreaCenterX + 'px ' + snowingSettingsAreaCenterY + 'px',
    'background-size': calcWindSpeedScale(meteoStation.windSpeed) + 'px ' + calcWindSpeedScale(meteoStation.windSpeed) +'px'
  }"></div>

<div class="window-overview-wrapper" *ngIf="snowCannon">
  <perfect-scrollbar>
    <div class="window-overview mb-2">
      <div class="window-overview__container">
        <div class="device-info">
          <div class="cannon-select">
            <button
              (click)="goToPrevCannon()"
              class="cannon-select__btn cannon-select__btn--prev">
              <svg>
                <use xlink:href="#icon-chevron-down" />
              </svg>
            </button>
            <div class="cannon-select__box number-dropdown" (click)="cannonsListExpanded = !cannonsListExpanded">
              {{ snowCannon.symbol }}
              <button class="cannon-select__chevron"
                [ngClass]="{'cannon-select__chevron--rotated': cannonsListExpanded}">
                <svg>
                  <use xlink:href="#icon-chevron-down" />
                </svg>
              </button>
            </div>
            <button
              (click)="goToNextCannon()"
              class="cannon-select__btn cannon-select__btn--next">
              <svg>
                <use xlink:href="#icon-chevron-down" />
              </svg>
            </button>
          </div>
          <!-- [ngStyle]="{'background-image': 'url(' + (snowCannon|snowCannonImage) + ')'}" -->
          <div class="device-info__image ml-3">
            <ss-device-image [device]="snowCannon"></ss-device-image>
            <div class="steering-is-local-overlay" *ngIf="snowCannon.connectionStatus && snowCannon.isInLocalMode">
              <svg>
                <use xlink:href='#icon-lock' />
              </svg>
            </div>
          </div>
          <div class="device-info__wrapper pl-3">
            <div>
              <h1 class="device-info__header">{{ snowCannon.cannonModel }} <small class="color-mid-grey fs-12">{{ snowCannon.serialNumber }}</small></h1>
              <p class="device-info__subheader">
                <ng-container *ngIf="snowCannon.mountType === 'chassis' || snowCannon.mountType === 'wheels'"
                  i18n="Na podwoziu|Typ mocowania armatki@@snowCannonMountTypeChassis">na podwoziu</ng-container>
                <ng-container *ngIf="snowCannon.mountType !== 'chassis' && snowCannon.mountType !== 'wheels'">{{ snowCannon.mountType }}</ng-container>
              </p>
            </div>
            <button class="window-overview__close"
              [routerLink]="isFromList ? ['/application/resort/area', area.id, 'list'] : ['/application/resort/area', area.id, 'map']">
              <svg>
                <use xlink:href='#icon-close' />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="cannonsListExpanded" class="cannons-list">
        <p class="fs-26 color-white pl-2 pb-1"
          i18n="Ostatnio używane|Nagłówek listy ostatnio używanych urządzeń@@snowCannonListHeaderLastUsed">Ostatnio
          używane</p>
        <div class="cannons-list__recent" *ngIf="recentCannons && recentCannons.length > 0">
          <ng-container *ngFor="let sc of recentCannons">
            <a [routerLink]="['/application/resort/area', area.id, 'map', sc.id]"
              class="cannons-list__label" [ngClass]="{'cannons-list__label--checked': sc.id === snowCannon.id}">
              {{ sc.symbol }}
            </a>
          </ng-container>
        </div>
        <ng-container *ngFor="let sc of snowCannons">
          <a [routerLink]="['/application/resort/area', area.id, 'map', sc.id]" class="cannons-list__label"
            [ngClass]="{'cannons-list__label--checked': sc.id === snowCannon.id}">
            {{ sc.symbol }}
          </a>
        </ng-container>
      </div>
      <ng-container *ngIf="!cannonsListExpanded">
        <div class="window-overview__container">
          <ss-snow-cannon-panel-meteo-bar [snowCannon]="snowCannon"></ss-snow-cannon-panel-meteo-bar>
        </div>

        <div class="window-overview__mode">
          <div class="mode-dropdown" [ngClass]="{'mode-dropdown--manual': !snowCannon.operationMode}">
            <button [disabled]="snowCannon.isLocked" class="mode-dropdown__btn"
              (click)="isModeDropdownExpanded = true">
              <span class="mode-dropdown__subheader" i18n="Armatką steruje|Tryb pracy armatki@@snowCannonPanelOperationMode">Armatką steruje</span>
              <span *ngIf="snowCannon.operationMode" class="mode-dropdown__header" i18n="Snowmatic|Tryb pracy Snowmatic@@operationModeSnowmatic">Snowmatic</span>
              <span *ngIf="!snowCannon.operationMode" class="mode-dropdown__header" i18n="Operator|Tryb pracy Operator@@operationModeOperator">Operator</span>
              <svg class="mode-dropdown__angle"
                [ngClass]="{'mode-dropdown__angle--rotated': isModeDropdownExpanded}">
                <use xlink:href='#icon-chevron-down' />
              </svg>
            </button>
            <div class="mode-dropdown__menu"
              [ngClass]="{'mode-dropdown__menu--expanded': isModeDropdownExpanded, 'mode-dropdown__menu--auto': !snowCannon.operationMode}">
              <button [disabled]="snowCannon.isLocked" class="mode-dropdown__btn"
                (click)="setSnowCannonOperationMode(snowCannon, !snowCannon.operationMode); isModeDropdownExpanded=false">
                <span *ngIf="!snowCannon.operationMode" class="mode-dropdown__header" i18n="Snowmatic|Tryb pracy Snowmatic@@operationModeSnowmatic">Snowmatic</span>
                <span *ngIf="snowCannon.operationMode" class="mode-dropdown__header" i18n="Operator|Tryb pracy Operator@@operationModeOperator">Operator</span>
              </button>
            </div>
          </div>
          <div class="mode-details" [ngClass]="{'mode-details--manual': !snowCannon.operationMode}">
            <ng-container *ngIf="snowCannon.operationMode; else manualSettings">
              <p class="mode-details__header" i18n="Schemat pracy|Etykieta schematu pacy@@schemaLabel">Schemat pracy</p>
              <div class="mode-details__container">
                <p class="mode-details__setting mode-details__setting--small">
                  7dni | {{ closestScheduleHours }}
                  <button [routerLink]="['/application/weather-and-schedules/edit', currentSchedule.id]"
                    class="mode-details__edit-btn"
                    i18n="Edytuj|Edycja ustawień w trybie automatycznym@@areaButtonEdit">Edytuj</button>
                </p>
                <span class="mode-details__setting">
                  <svg>
                    <use xlink:href='#icon-termo-wet' />
                  </svg>
                  {{ snowCannon.startTemperature|number:'1.1-1' }}&#176;C
                </span>
                <span class="mode-details__setting">
                  <svg>
                    <use xlink:href='#icon-time' />
                  </svg>
                  {{ closestScheduleHours }}
                </span>
              </div>
            </ng-container>
            <ng-template #manualSettings>
              <div class="mode-details__container" *ngIf="snowCannon.connectionStatus">
                <div class="mode-details__col">
                  <ng-container *ngIf="snowCannon.CAP_CHANGE_START_TEMPERATURE">
                    <p class="mode-details__header"
                      i18n="Temperatura startu|Etykieta pola ustawiającego temperaturę startu@@snowCannonStartTemperature">
                      Temperatura startu</p>
                    <div class="mode-details__setting">
                      <ss-number-input
                        [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
                        [disabled]="snowCannon.isLocked" [min]="-15" [max]="2" [step]="0.1"
                        (changed)="setSnowCannonStartTemperature(snowCannon, $event)"
                        [inputValue]="snowCannon.startTemperature"></ss-number-input>
                      <svg>
                        <use xlink:href='#icon-termo-wet' />
                      </svg>
                      &#176;C
                    </div>
                  </ng-container>
                  <ng-container *ngIf="snowCannon.CAP_CHANGE_START_WATER_FLOW">
                    <p class="mode-details__header"
                      i18n="Przepływ startu|Etykieta pola ustawiającego temperaturę startu@@snowCannonStartWaterFlow">
                      Przeplyw startu</p>
                    <div class="mode-details__setting">
                      <ss-number-input [disabled]="snowCannon.isLocked" [min]="0.7" [max]="11.1" [step]="0.1"
                        (changed)="setSnowCannonStartWaterFlow(snowCannon, $event)"
                        [inputValue]="snowCannon.startWaterFlow"></ss-number-input>
                      <svg>
                        <use xlink:href='#icon-tap' />
                      </svg>
                      l/s
                    </div>
                  </ng-container>
                </div>
                <div class="mode-details__col">
                  <ng-container *ngIf="snowCannon.CAP_RUN_START_STOP_SNOWCANNON">
                    <ss-control-button size="small"
                      [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
                      [disabled]="snowCannon.isLocked || snowCannon.isInLocalMode"
                      [action]="snowCannon.commandStatusCannonStart ? 'stop' : 'start'"
                      (click)="toggleSnowCannonStartStop(snowCannon)">
                      <ng-container *ngIf="snowCannon.commandStatusCannonStart" i18n="Stop|Etykieta przycisku zatrzymującego@@controlButtonStop">Stop</ng-container>
                      <ng-container *ngIf="!snowCannon.commandStatusCannonStart" i18n="Start|Etykieta przycisku uruchamiającego@@controlButtonStart">Start</ng-container>

                    </ss-control-button>
                  </ng-container>
                </div>
              </div>
            </ng-template>
          </div>
        </div>

        <div class="window-overview__container window-overview__container--double">
          <div class="pt-3 pb-2 d-flex flex-grow-1 flex-row align-items-center justify-content-around">
            <ss-snow-cannon-status size="detailed" [state]="snowCannon.computedStatus"></ss-snow-cannon-status>

            <div class="flex-grow-1" *ngIf="!snowCannon.connectionStatus; else hasConnection">
              <p class="px-4 pt-0 pb-1 color-white">
                <ng-container
                  i18n="Ostatnie połączenie|Komunikat o braku aktualnego i czasie ostatniego połączenia z urządzeniem@@snowCannonNoConnection">
                  Ostatnie połączenie</ng-container>
                {{ snowCannon.lastConnected|date: 'yyyy-MM-dd HH:mm:ss' }}
              </p>
            </div>
            <ng-template #hasConnection>
              <div class="flex-grow-1"></div>
              <ss-snow-cannon-component-button-heater
                [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
                [snowCannon]="snowCannon"></ss-snow-cannon-component-button-heater>
              <ss-snow-cannon-component-button-lamp
                [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
                [snowCannon]="snowCannon"></ss-snow-cannon-component-button-lamp>
              <ss-snow-cannon-component-button-hydraulic-pump [snowCannon]="snowCannon"></ss-snow-cannon-component-button-hydraulic-pump>
              <ss-snow-cannon-component-button-air-compressor [snowCannon]="snowCannon"></ss-snow-cannon-component-button-air-compressor>
              <ss-snow-cannon-component-button-main-fan [snowCannon]="snowCannon"></ss-snow-cannon-component-button-main-fan>
              <ss-snow-cannon-component-button-nozzle-row [snowCannon]="snowCannon" [rowNumber]="1"></ss-snow-cannon-component-button-nozzle-row>
              <ss-snow-cannon-component-button-nozzle-row [snowCannon]="snowCannon" [rowNumber]="2"></ss-snow-cannon-component-button-nozzle-row>
              <ss-snow-cannon-component-button-nozzle-row [snowCannon]="snowCannon" [rowNumber]="3"></ss-snow-cannon-component-button-nozzle-row>
              <ss-snow-cannon-component-button-nozzle-row [snowCannon]="snowCannon" [rowNumber]="4"></ss-snow-cannon-component-button-nozzle-row>
              <ss-snow-cannon-component-button-hydrant-drive [snowCannon]="snowCannon"></ss-snow-cannon-component-button-hydrant-drive>
            </ng-template>
          </div>
        </div>
        <div class="window-overview__container window-overview__container--double" *ngIf="snowCannon.connectionStatus">
          <div class="window-overview__col pt-1" *ngIf="snowCannon.CAP_SHOW_SNOW_PRODUCTION">
            <p class="window-overview__header text-uppercase"
              i18n="Produkcja śniegu|Nazwa pola przedstawiającego wielkość produkcji śniegu@@snowProduction">Produkcja śniegu</p>
            <p class="window-overview__text white-space-nowrap">
              <span class="window-overview__text--xl color-green">
                {{ snowCannon|convertedValue:'currentSnowProductionVolume' }}
              </span>
              <span class="window-overview__text window-overview__text--l color-mid-grey">
                {{ snowCannon|convertedUnit:'currentSnowProductionVolume' }}
              </span>
            </p>
          </div>
          <div class="window-overview__col pt-1" *ngIf="!snowCannon.CAP_SHOW_SNOW_PRODUCTION">
            <p class="window-overview__header text-uppercase"
              i18n="Zużycie wody|Nazwa pola przedstawiającego wielkość zużycia wody@@waterConsumption">Zużycie wody</p>
            <p class="window-overview__text white-space-nowrap">
              <span class="window-overview__text--xl color-green">
                {{ snowCannon|convertedValue:'currentWaterFlow' }}
              </span>
              <span class="window-overview__text window-overview__text--l color-mid-grey">
                {{ snowCannon|convertedUnit:'currentWaterFlow' }}
              </span>
            </p>
          </div>

          <div class="window-overview__col pt-2 pb-2">
            <ss-d3-snow-production-and-twb-chart [device]="snowCannon"></ss-d3-snow-production-and-twb-chart>
          </div>
        </div>


        <div class="window-overview__container window-overview__container--double" *ngIf="snowCannon.connectionStatus">
          <div class="pb-3 pt-1 w-100">
            <ng-container *ngIf="snowCannon.CAP_CHANGE_SNOWQUALITY">
              <ss-snow-quality (snowQualityChange)="setCannonSnowQuality(snowCannon, $event)"
                [snowQualityType]="snowCannon.snowQualityType" [currentSnowQuality]="snowCannon.snowQuality"
                [snowQualityConstant]="snowCannon.snowQualityConstant" [snowQualityMax]="snowCannon.snowQualityHigh"
                [snowQualityMin]="snowCannon.snowQualityLow" [disableSnowQualitySelection]="snowCannon.operationMode === 0"
                [isDisabled]="snowCannon.isLocked" [showIcons]="true" size="large"></ss-snow-quality>
            </ng-container>
          </div>
        </div>

      </ng-container>

    </div>

    <div *ngIf="!cannonsListExpanded" class="window-overview window-overview--tabs">
      <ss-tabs>
        <ss-tab *ngIf="
          snowCannon.connectionStatus
          && (
            snowCannon.CAP_SHOW_OSCILLATION_INFO
            || snowCannon.CAP_SHOW_CURRENT_VERTICAL_POSITION
            || snowCannon.CAP_SHOW_CURRENT_HORIZONTAL_POSITION
          )"
          tabTitle="Pozycjonowanie i wiatr"
          i18n-tabTitle="Pozycjonowanie i wiatr|Tytuł zakładki pozycjonowania i wiatru danej armatki@@snowCannonTabTitlePositioningAndWind">
          <div class="tabs-content">
            <div class="row">
              <div *ngIf="snowCannon.CAP_SHOW_OSCILLATION_INFO" class="col tabs-content__box d-flex flex-wrap align-items-center">
                <p class="window-overview__header text-uppercase mb-4 px-3 pt-1"
                  i18n="Oscylacja|Nazwa pola przedstawiającego wartość oscylacji@@snowCannonOscillation">Oscylacja</p>
                <ss-control-button [disabled]="
                    !snowCannon.canChangeOscillation
                    || snowCannon.isLocked
                    || (!snowCannon.isOscillationOn && !snowCannon.CAP_START_OSCILLATION)
                  "
                  [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
                  (click)="setSnowCannonOscillationEnabled(snowCannon, snowCannon.isOscillationOn ? false : true);"
                  class="pl-3 pb-3" [action]="snowCannon.isOscillationOn ? 'stop' : 'start'">
                  <ng-container *ngIf="snowCannon.isOscillationOn"
                    i18n="Zatrzymaj|Etykieta przycisku zatrzymującego oscylację@@snowCannonOscillationStop">Zatrzymaj
                  </ng-container>
                  <ng-container *ngIf="!snowCannon.isOscillationOn"
                    i18n="Uruchom|Etykieta przycisku uruchamiającego oscylację@@snowCannonOscillationStart">Uruchom
                  </ng-container>
                </ss-control-button>
                <div class="indicator pb-3" *ngIf="snowCannon.CAP_SHOW_OSCILLATION_INFO || snowCannon.isOscillationOn">
                  <svg class="indicator__icon ml-2">
                    <use xlink:href='#icon-range' />
                  </svg>
                  <span class="indicator__value">{{ snowCannon.oscillationSetAngle|number:'1.0-0' }}&#176;</span>
                </div>
              </div>
            </div>
          </div>
          <div class="tabs-content" *ngIf="snowCannon.CAP_SHOW_CURRENT_VERTICAL_POSITION && snowCannon.CAP_SHOW_CURRENT_HORIZONTAL_POSITION">
            <div class="row">
              <div
                class="col tabs-content__box d-flex flex-column justify-content-between align-items-center px-3 py-1">
                <p class="window-overview__header text-uppercase"
                  i18n="Pozycja armatki|Etykieta pola z informacjami o pozycji armatki@@snowCannonPosition">Pozycja armatki</p>
                <ss-snow-cannon-position-indicator [snowCannon]="snowCannon"></ss-snow-cannon-position-indicator>
              </div>

              <div
                class="col tabs-content__box d-flex flex-column justify-content-between align-items-center px-3 py-1">
                <p class="window-overview__header text-uppercase"
                  i18n="Kąt wyrzutu śniegu|Etykieta pola z informacjami na temat kąta wyrzutu śniegu@@snowCannonSnowingAngle">Kąt wyrzutu śniegu</p>
                <ss-snowing-angle class="mb-4" [snowingAngle]="snowCannon.tubeVerticalCurrentAngle"></ss-snowing-angle>
              </div>

              <div
                class="col tabs-content__box d-flex flex-column justify-content-between align-items-center px-3 py-1">
                <p class="window-overview__header text-uppercase"
                  i18n="Pozycja wysięgnika|Etykieta pola z informacjami na temat pozycji wysięgnika@@snowCannonBaseRotation">Pozycja wysięgnika</p>
                <ss-snow-cannon-base-position-indicator [snowCannon]="snowCannon"></ss-snow-cannon-base-position-indicator>
              </div>
            </div>
          </div>
        </ss-tab>

        <ss-snow-cannon-stats-and-reports-tab
          [snowCannon]="snowCannon"
          [pumpRooms]="pumpRooms"
          tabTitle="Statystyki i raporty"
          i18n-tabTitle="Statystyki i raporty|Tytuł zakładki ze statystykami dot. danej armatki@@snowCannonTabTitleStats"></ss-snow-cannon-stats-and-reports-tab>
        <ss-user-notes-tab
          [context]="snowCannon.id"
          tabTitle="Notatki"
          i18n-tabTitle="Notatki|Tytuł zakładki z notatkami dot. danej armatki@@snowCannonTabTitleNotes"></ss-user-notes-tab>
        <ss-documentation-tab
          [context]="snowCannon.id"
          tabTitle="Dokumentacja"
          i18n-tabTitle="Dokumentacja|Tytuł zakładki z dokuemntacją dot. danej armatki@@snowCannonTabTitleDocumentation"></ss-documentation-tab>
        <ss-snow-cannon-settings-tab
          *ssdHideIfNotGranted="'ROLE_OPERATOR'"
          [snowCannon]="snowCannon"
          [area]="area"
          tabTitle="Ustawienia"
          i18n-tabTitle="Ustawienia|Tytuł zakładki z ustawieniami dot. danej armatki@@snowCannonTabTitleSettings"></ss-snow-cannon-settings-tab>
        <ss-tab tabTitle="Debug" *ssdHideIfNotGranted="'ROLE_SUPERADMIN'">
          <div class="p-3 color-light-grey">
            <ss-debug-table [data]="snowCannon"></ss-debug-table>
          </div>
        </ss-tab>
        <ss-notifications-tab
          [context]="devIds"
          tabTitle="Powiadomienia"
          i18n-tabTitle="Powiadomienia|Tytuł zakładki z powiadomieniami dot. danej armatki@@snowCannonTabTitleAlerts"></ss-notifications-tab>
      </ss-tabs>
    </div>
  </perfect-scrollbar>
</div>
