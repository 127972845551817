import { D3 } from '..';
import { dayjs } from '../..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { getYTickFormat } from './get-y-tick-format.function';

export const drawFloatingToolTip = (state: D3DrawerChartState): D3DrawerChartState => {
    if(!state.hostEl || !state.generatedValuesStorage) {
      return state;
    }

    let ttg = state.generatedCanvas.select('g.chart-tool-tip-group');
    if (ttg.empty()) {
      ttg = state.generatedCanvas.append('g')
        .attr('class', 'chart-tool-tip-group');
      ttg.append('rect')
        .attr('class', 'tool-tip-background')
        .attr('rx', 3)
        .attr('x', 0)
        .attr('y', 0);
      ttg.append('text')
        .attr('class', 'tool-tip-time')
        .attr('x', 5)
        .attr('y', 5);
    }

    if(dayjs.isDayjs(state.selectedDate) && state.generatedValuesStorage.has('xAxisScale')) {
      let labelPosition = state.generatedValuesStorage.get('xAxisScale')(state.selectedDate.valueOf());
      let formattedTime = state.selectedDate.tz().format('YYYY-MM-DD HH:mm');

      let seriesCtx = 0;
      let maxTextLen = 80;
      let maxValueLen = 20;
      state.config.series.forEach((series, seriesIndex) => {
        if(series.isVisibleOnLegend) {
          seriesCtx++;

          const bisectDate = D3.bisector((d) => d[series.labelFieldName]).right;
          const dataElementIndex = bisectDate(state.seriesSpecificData[seriesIndex], state.selectedDate.valueOf());

          let value;
          if (dataElementIndex > 0 && state.seriesSpecificData[seriesIndex][dataElementIndex-1]) {
            const tX = dayjs(state.seriesSpecificData[seriesIndex][dataElementIndex-1][series.labelFieldName]);
            formattedTime = tX.tz().format('YYYY-MM-DD HH:mm');
            value = state.seriesSpecificData[seriesIndex][dataElementIndex-1][series.valueFieldName];

            value = getYTickFormat(value, state, series);
          }

          let seriesGroup = ttg.select('g.tool-tip-series.series-group-' + seriesCtx);
          if (seriesGroup.empty()) {
            seriesGroup = ttg.append('g')
              .attr('class', 'tool-tip-series series-group-' + seriesCtx);
          }
          seriesGroup.attr('transform', 'translate(0 ' + (seriesCtx * 18 + 3) + ')');


          let seriesColor = seriesGroup.select('rect');
          if(seriesColor.empty()) {
            seriesColor = seriesGroup.append('rect')
              .attr('rx', 2);

            if(series.seriesType === 'line') {
              seriesColor
                .attr('y', 5)
                .attr('x', 4)
                .style('width', 12)
                .style('height', 3);
            } else {
              seriesColor
                .attr('y', 0)
                .attr('x', 6)
                .style('width', 8)
                .style('height', 12);
            }
          }
          seriesColor.style('fill', state.generatedValuesStorage.get(`series${seriesIndex}BaseColor`));

          let seriesLabel = seriesGroup.select('text.tool-tip-series-label');
          if(seriesLabel.empty()) {
            seriesLabel = seriesGroup.append('text')
              .attr('class', 'tool-tip-series-label')
              .attr('x', 18)
              .attr('y', 1);
          }
          seriesLabel.text(`${series.seriesName}`);
          maxTextLen = Math.max(maxTextLen, (seriesLabel.node() as any).getBBox().width);

          let seriesValue = seriesGroup.select('text.tool-tip-series-value');
          if(seriesValue.empty()) {
            seriesValue = seriesGroup.append('text')
              .attr('class', 'tool-tip-series-value')
              .attr('x', 0)
              .attr('y', 1);
          }

          seriesValue.text(!!value ? `${value}` : '--');
          maxValueLen = Math.max(maxValueLen, (seriesValue.node() as any).getBBox().width);
        }
      });
      ttg.selectAll('g.tool-tip-series text.tool-tip-series-value')
        .attr('x', maxTextLen + 20);

      const tooltipHeight = 21 + seriesCtx * 18;
      const requestedWidth = maxValueLen + maxTextLen + 37;
      ttg.select('rect.tool-tip-background')
        .style('width', requestedWidth)
        .style('height', tooltipHeight);
      ttg.select('text.tool-tip-time').text(formattedTime);

      labelPosition = labelPosition < state.viewBoxWidth - requestedWidth - 10 ? labelPosition + 10: labelPosition - requestedWidth - 10;
      ttg
        .attr('transform', 'translate(' + labelPosition + ' ' + (0.5 * (state.viewBoxHeight - tooltipHeight)) + ')')
        .style('opacity', 1);
    } else {
      ttg.style('opacity', 0);
    }

    return state;
  };
