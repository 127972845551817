import { ConvertUnit, isArray, isNumber, isObject } from '../../..';
import { ReportPartView } from '../part-view';
import { ReportResultView } from '../result-view';
import { subjectFieldMapping } from '../series-subject-field-to-type-mapping';

export class ReportAggrMaxPartView extends ReportPartView {
  public calculate(resultView: ReportResultView) {
    this.calculateAutoLabel(resultView);
    if(this.part.series.length === 1) {
      const type = this.part.series[0].subjectType;
      const field = this.part.series[0].subjectField;
      const id = this.part.series[0].subjectId;

      if(subjectFieldMapping[type] && subjectFieldMapping[type].fields[field]) {
        const dataByIds = this.getDataByIds(resultView);

        if(!isObject(dataByIds[id]) || !isArray(dataByIds[id][field])) {
          return;
        }

        const values = (dataByIds[id][field] as number[] || []).filter(v => isNumber(v));
        if(values.length > 0) {
          const max = Math.max(...values);
          const scalarData = { value: max };
          ConvertUnit(
            subjectFieldMapping[type].fields[field].unitType,
            subjectFieldMapping[type].fields[field].storedUnitOverride
          )(scalarData, 'value');
          scalarData.value = max;
          this.scalarData = scalarData as any;
        }
      }
    }
  }
}
