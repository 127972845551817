import { isArray, isObject } from '../..';

export class ReportData {

  public keys: string[] = [];
  private storage: {[deviceUuid: string]: {
    aggregated: {labels: number[]; values: {
      [fieldName: string]: number[];
    };};
    // status: {labels: number[]; values: {
    //   [fieldName: string]: number[];
    // };};
  };} = {};

  public getData(id: string, ...fields: string[]): any {
    if(!this.storage[id]) {
      return;
    }
    const resp = { labels: [...this.storage[id].aggregated.labels] };
    if(isArray(fields)) {
      fields.forEach(f => {
        if(isArray(this.storage[id].aggregated.values[f])) {
          resp[f] = [ ... this.storage[id].aggregated.values[f]];
        }
      });
    }
    return resp;
  }

  public static create(data: any): ReportData {
    if(isObject(data)) {
      if(data instanceof ReportData) {
        return data;
      }

      const resp = new ReportData();
      resp.keys = Object.keys(data);

      resp.keys.forEach(key => {
        resp.storage[key] = {
          aggregated: { labels: data[key][0][0], values: data[key][0][1] },
          // status: { labels: data[key][1][0], values: data[key][1][1] },
        };
      });

      return resp;
    }
    return null;
  }
}
