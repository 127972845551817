<ss-page>
    <ss-page-header>
        <ss-page-header-title>Areas</ss-page-header-title>
        <ss-page-button-group>
            <button
              (click)="save()"
              [disabled]="editedArea" class="btn btn-primary">Save <kbd>Ctrl+Shift+s</kbd></button>
            <a class="btn btn-default" [routerLink]="['/application', 'editor']">Go back</a>
        </ss-page-button-group>
    </ss-page-header>
    <ss-page-panel panelTitle="Project image opacity">
        <form-range [min]="0" [max]="1" [step]="0.05" [(ngModel)]="imageOpacity" (change)="imageOpacityChanged()" [hideLabel]="true"></form-range>
    </ss-page-panel>

    <ng-container *ngIf="editedArea">
        <ss-page-panel>
            <div class="row">
                <div class="col">
                    <button [disabled]="
                        !editedName
                        || addingPoly
                        || addingHole
                        || editingAll
                    "(click)="saveEdit()"
                    class="btn btn-primary w-100">Save <kbd>Ctrl+s</kbd></button>
                </div>
                <div class="col-auto pl-1">
                    <button (click)="cancelEdit()" class="btn btn-default">Cancel <kbd>Esc</kbd></button>
                </div>
            </div>
        </ss-page-panel>
        <ss-page-panel panelTitle="Edit symbol">
            <form-text [hideLabel]="true" [(ngModel)]="editedName" placeholder="type in area name..."></form-text>
        </ss-page-panel>
        <ss-page-panel panelTitle="Edit visual bounds">

            <div class="row">
                <div class="col">
                    <button class="btn btn-default w-100"
                        [disabled]="addingHole || editingAll"
                        *ngIf="!addingPoly"
                        (click)="addPoly()">add poly</button>
                    <button class="btn btn-primary w-100"
                        [disabled]="addingHole || editingAll"
                        *ngIf="addingPoly"
                        (click)="apply()">apply poly</button>
                </div>
                <div class="col pl-1">
                    <button class="btn btn-default w-100"
                        [disabled]="addingPoly || editingAll"
                        *ngIf="!addingHole"
                        (click)="addHole()">add hole</button>
                    <button class="btn btn-primary w-100"
                        [disabled]="addingPoly || editingAll"
                        *ngIf="addingHole"
                        (click)="apply()">apply hole</button>
                </div>
                <div class="col pl-1">
                    <button class="btn btn-default w-100"
                        [disabled]="addingPoly || addingHole"
                        *ngIf="!editingAll"
                        (click)="editAll()">edit</button>
                    <button class="btn btn-primary w-100"
                        [disabled]="addingPoly || addingHole"
                        *ngIf="editingAll"
                        (click)="apply()">apply edit</button>
                </div>
            </div>

            <pre *ngIf="editedMapBoundaries">
                {{ editedMapBoundaries| json }}
            </pre>
        </ss-page-panel>
    </ng-container>

    <ng-container *ngIf="!editedArea">
        <ss-page-panel>
            <button (click)="add()" class="btn w-100 btn-primary">Add new area</button>
        </ss-page-panel>
        <ss-page-panel
            [panelTitle]="'List (' + areas.length + ')'">
            <div class="row align-items-center pb-1" *ngFor="let a of areas">
                <div class="col">Area: {{ a.a.symbol }}</div>
                <div class="col-auto">
                    <div class="btn btn-default" (click)="edit(a)">Edit</div>
                </div>
            </div>
        </ss-page-panel>
    </ng-container>

</ss-page>
