<ss-page *ngIf="resortArea">
  <ss-page-header>
      <ss-page-header-title>Konfiguracja grup i hydrantów na stoku {{ resortArea.symbol }}</ss-page-header-title>
      <ss-page-button-group>
          <button type="button" [routerLink]="'../map'" class="btn btn-default">Anuluj</button>
          <button type="button" (click)="save()" class="btn btn-primary">Zapisz konfiguracje</button>
      </ss-page-button-group>
  </ss-page-header>

  <ss-page-toolbar>
    <button class="btn btn-primary" (click)="addWorkingArea()">
      <svg>
        <use xlink:href="#icon-plus" />
      </svg>
      Dodaj grupę
    </button>

    <div class="col"></div>

    <p>Szukaj hydrantu</p>
    <div class="input-holder">
      <input type="text" class="input"
        placeholder="Początek symbolu hydrantu..."
        (blur)="performDebouncedHydrantSearch()"
        (keyup)="performDebouncedHydrantSearch()"
        [(ngModel)]="hydrantSearchString" />
      <svg>
        <use xlink:href='#icon-search'/>
      </svg>
    </div>

    <p>Pokaż</p>
    <div class="btn-group">
      <button class="btn btn-dark"
        [ngClass]="{'btn-pressed': isViewSelected('groups') }"
        (click)="selectView('groups')">Listę grup</button>
      <button class="btn btn-dark"
        [ngClass]="{'btn-pressed': isViewSelected('hydrants') }"
        (click)="selectView('hydrants')">Listę hydrantów nieprzypisanych do grup</button>
      <button class="btn btn-dark"
        [ngClass]="{'btn-pressed': isViewSelected('cannons') }"
        (click)="selectView('cannons')">Listę nieprzypisanych armat</button>
      <button class="btn btn-dark"
        [ngClass]="{'btn-pressed': isViewSelected('map') }"
        (click)="selectView('map')">Mapę</button>
    </div>
  </ss-page-toolbar>

  <div class="row multiselelct-row">
    <div class="multiselelct-col" [ngClass]="{ 'visible': isViewSelected('groups') }">
      <div class="drop-container-holder">
        <p *ngIf="assignedHydrants.length === 0" class="drop-container-info py-4">Nie zdefiniowano żadnych grup na stoku</p>
        <div class="drop-container"
          [dragula]="DG_GROUPS"
          [(dragulaModel)]="assignedHydrants">
          <div *ngFor="let g of assignedHydrants">
            <ss-page-panel [panelTitle]="g.workingArea.symbol">
              <div class="row working-area-info align-items-center">
                <div class="col-auto handler working-area-handle">
                  <svg>
                    <use xlink:href="#icon-move" />
                  </svg>
                </div>
                <div class="col-auto symbol">

                </div>
                <div class="col-auto">
                  {{ g.workingArea.priority || 0 }}
                </div>
                <div class="col"></div>
                <div class="col-auto edits">
                  <button class="btn btn-defaut"
                    [disabled]="!g.workingArea.id"
                    (click)="goToWorkingAreaConfig(g.workingArea.id)">
                    <svg>
                      <use xlink:href="#icon-gear" />
                    </svg>
                  </button>
                </div>
                <div class="col-auto edits">
                  <button class="btn btn-danger" (click)="removeWorkingArea(g.workingArea)">
                    <svg>
                      <use xlink:href="#icon-trash" />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="drop-container-holder">
                <p *ngIf="g.hydrants.length === 0" class="drop-container-info">Do grupy nie dodano żadnych hydrantów</p>
                <p *ngIf="hasNoVisible(g.hydrants)" class="drop-container-info">Filtrowanie ukryło wszystkie hydranty</p>
                <div class="drop-container"
                  [ngClass]="{'show-drop-area': g.hydrants.length === 0 || hasNoVisible(g.hydrants) }"
                  [dragula]="DG_HYDRANTS"
                  [(dragulaModel)]="g.hydrants">
                  <div *ngFor="let hy of g.hydrants">
                    <div class="row movable-hydrant" *ngIf="hy.isVisible">
                      <div class="col-6 pr-2">
                        <ss-area-configuration-info-hydrant [connectionPoint]="hy.hydrant"></ss-area-configuration-info-hydrant>
                      </div>
                      <div class="col-6 drop-container-holder">
                        <p *ngIf="hy.cannons.length === 0" class="drop-container-info">Brak przypisanych armatek</p>
                        <div class="drop-container" [dragula]="DG_CANNONS" [(dragulaModel)]="hy.cannons">
                          <ss-area-configuration-info-cannon
                            *ngFor="let cn of hy.cannons"
                            [snowCannon]="cn"></ss-area-configuration-info-cannon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ss-page-panel>
          </div>
        </div>
      </div>
    </div>
    <div class="multiselelct-col" [ngClass]="{ 'visible': isViewSelected('hydrants') }">

      <ss-page-panel panelTitle="Hydranty nie przypisane do grup">
        <div class="drop-container-holder">
          <p *ngIf="notAssignedHydrants.length === 0" class="drop-container-info">Wszystkie hydranty są przypisane do grup</p>
          <p *ngIf="hasNoVisible(notAssignedHydrants)" class="drop-container-info">Filtrowanie ukryło wszystkie hydranty</p>
          <div class="drop-container"
            [ngClass]="{'show-drop-area': notAssignedHydrants.length === 0 || hasNoVisible(notAssignedHydrants) }"
            [dragula]="DG_HYDRANTS"
            [(dragulaModel)]="notAssignedHydrants">
            <div *ngFor="let hy of notAssignedHydrants">
              <div class="row movable-hydrant" *ngIf="hy.isVisible">
                <div class="col-6 pr-2">
                  <ss-area-configuration-info-hydrant [connectionPoint]="hy.hydrant"></ss-area-configuration-info-hydrant>
                </div>
                <div class="col-6 drop-container-holder">
                  <p *ngIf="hy.cannons.length === 0" class="drop-container-info">Brak przypisanych armatek</p>
                  <div class="drop-container" [dragula]="DG_CANNONS" [(dragulaModel)]="hy.cannons">
                    <ss-area-configuration-info-cannon
                      *ngFor="let cn of hy.cannons"
                      [snowCannon]="cn"></ss-area-configuration-info-cannon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ss-page-panel>
    </div>
    <div class="multiselelct-col" [ngClass]="{ 'visible': isViewSelected('cannons') }">

      <ss-page-panel panelTitle="Nieprzypisane armatki">
        <div class="drop-container-holder">
          <p *ngIf="notAssignedSnowCannons.length === 0" class="drop-container-info">Wszystkie armatki są przypisane do hydrantów</p>
          <div class="drop-container" [dragula]="DG_CANNONS" [(dragulaModel)]="notAssignedSnowCannons">
            <ss-area-configuration-info-cannon
              *ngFor="let cn of notAssignedSnowCannons"
              [snowCannon]="cn"></ss-area-configuration-info-cannon>
          </div>
        </div>
      </ss-page-panel>
    </div>
    <div class="multiselelct-col" [ngClass]="{ 'visible': isViewSelected('map') }">
      <ss-page-panel panelTitle="Mapa grup i hydrantów">Mapa</ss-page-panel>
    </div>
  </div>

  <ss-page-footer>
    <button type="button" class="btn btn-default">Konfiguruj ustawienia stoku</button>
    <div></div>
    <ss-page-button-group>
        <button type="button" [routerLink]="'../map'" class="btn btn-default" >Anuluj</button>
        <button type="button" (click)="save()" class="btn btn-primary">Zapisz konfiguracje</button>
    </ss-page-button-group>
  </ss-page-footer>
</ss-page>
