import { Component, Input, OnInit } from '@angular/core';
import { AerationLevel } from '../../../../../shared/models/pump-room';

@Component({
  selector: 'ss-aeration-level-tile',
  templateUrl: './aeration-level-tile.component.html',
  styleUrls: []
})
export class AerationLevelTileComponent implements OnInit {

  constructor() { }

  @Input()
  public aerationLevel: AerationLevel;

  @Input()
  public selectedPipelineIds: string[] = [];

  ngOnInit() {
  }
}
