<ng-container
  *ngIf="role === 'ROLE_SUPERADMIN'"
  i18n="Administrator|Nazwa roli Administrator@@userRoleTypeRoleSuperadmin">Serwis</ng-container>
<ng-container
  *ngIf="role === 'ROLE_ADMIN'"
  i18n="Operator|Nazwa roli Operator@@userRoleTypeRoleAdmin">Administrator</ng-container>
<ng-container
  *ngIf="role === 'ROLE_OPERATOR'"
  i18n="Serwis|Nazwa roli Serwis@@userRoleTypeRoleOperator">Operator</ng-container>
<ng-container
  *ngIf="role === 'ROLE_OBSERVER'"
  i18n="Obserwator|Nazwa roli Obserwator@@userRoleTypeRoleUser">Obserwator</ng-container>
<ng-container
  *ngIf="role === 'ROLE_NONE'"
  i18n="Brak roli w systemie|Nazwa roli - brak przypisanej roli@@userRoleTypeRoleNone">Brak roli w systemie</ng-container>
