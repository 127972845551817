import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'ss-pump-room-detail-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.sass']
})
export class PumproomStatusComponent {

  public count = -1;

  @Input()
  public hideDescription = false;
  @Input()
  public status: string;
  @Input()
  public optional = false;

  @Input()
  @HostBinding('class.standalone')
  public standalone = false;
  @HostBinding('class.compacted')
  get compacted(): boolean {
    return !this.standalone;
  }
  @HostBinding('class.hide')
  get hide(): boolean {
    return (this.optional || this.standalone) && this.count <= 0;
  }
  @HostBinding('class.red')
  get red(): boolean {
    return [
      'breakdown',
      'connection-lost',
      'dry-run',
      'overcurrent',
      'overheated',
      'working-in-manual-mode'
    ].includes(this.status);
  }
  @HostBinding('class.green')
  get green(): boolean {
    return [
      'enabled',
      'filling',
      'fsl',
      'working'
    ].includes(this.status);
  }
  @HostBinding('class.yellow')
  get yellow(): boolean {
    return [
      'ready-to-work'
    ].includes(this.status);
  }
  @HostBinding('class.blue')
  get blue(): boolean {
    return [
      'snoozing',
      'valve-is-open'
    ].includes(this.status);
  }
  @HostBinding('class.gray')
  get gray(): boolean {
    return [
      'disabled',
      'standing-by',
      'valve-is-closed'
    ].includes(this.status);
  }

}
