import { Component, OnInit, Input } from '@angular/core';
import { DrawableComponent, VisualComponent } from 'src/app/shared/models/pump-room';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[ss-full-schema-visual-component]',
  templateUrl: './visual-component.component.html',
  styleUrls: ['./visual-component.component.sass']
})
export class VisualComponentComponent implements OnInit {

  constructor() { }

  @Input()
  public allowEdit = false;

  @Input()
  public drawable: DrawableComponent<VisualComponent>;

  ngOnInit() {
  }

}
