import { TimedChartConfigSeries } from '../..';

export const getSeriesColor = (series: TimedChartConfigSeries, seriesIndex: number): string => {
  let colors = ['#00A1E4', '#60ff9e', '#faf3b2', '#4a90e2', '#067a45', '#9566ab'];
  if(
    series.seriesType === 'bar'
    || series.seriesType === 'bar-w-rounded-top'
  ) {
    colors = ['#e94f75', '#8a7bff', '#4774ff', '#1bd374', '#ff33ff', '#50ccff'];
  }
  return colors[seriesIndex % colors.length];
};
