<perfect-scrollbar>
  <div class="px-2 pb-5">



    <div class="power-source__header mt-2">
      <ss-tabs>
        <ss-tab tabTitle="Podsumowanie"
          i18n-tabTitle="Podsumowanie|Tytuł zakładki z podsumowaniem@@powerSourceDetailTabTitleSummary">

          <div class="d-flex justify-content-between px-4 pt-5 pb-2">
            <h3>
              <ng-container i18n="Zasilanie|Etykieta zasilanie@@powerSourceLabel">Zasilanie</ng-container>:
              {{ powerSource.symbol }}
            </h3>
            <div class="d-flex ">
              <div class="mx-4" *ngIf="powerSource.canDisplayCurrentPowerDraw()">
                <p class="color-light-grey fs-12 text-uppercase"
                  i18n="Moc|Etykieta wskaźnika mocy chwilowej@@powerSourcePowerDraw">Moc</p>
                <p class="mt-2 white-space-nowrap">
                  <span class="fs-42 color-green">{{ powerSource|convertedValue:'computedPowerDraw' }}</span>
                  <span class="fs-20 color-mid-grey pl-1"> {{ powerSource|convertedUnit:'computedPowerDraw' }}</span>
                </p>
                <p class="color-light-grey fs-12 pt-4 text-uppercase"
                  i18n="Moc 15min|Etykieta wskaźnika mocy chwilowej@@powerSourcePowerDraw15min">Moc 15min</p>
                <p class="mt-2 white-space-nowrap">
                  <span class="fs-42 color-green">{{ lastQuarterPowerDraw | number: '1.1-1' }}</span>
                  <span class="fs-20 color-mid-grey pl-1"> {{ powerSource|convertedUnit:'computedPowerDraw' }}</span>
                </p>
              </div>
              <div class="ml-4">
                <div style="width: 730px">
                  <ss-d3-power-draw-chart [device]="powerSource"></ss-d3-power-draw-chart>
                </div>
              </div>
            </div>
          </div>
        </ss-tab>
        <ss-tab [hidden]="true" tabTitle="Statystyki i raporty"
          i18n-tabTitle="Statystyki i raporty|Tytuł zakładki ze statystykami dot. danej armatki@@powerSourceTabTitleStats">
          <div class="p-3">
            <p class="window-overview__header text-uppercase"
              i18n="Statystyki|Nagłówek pola ze statystykami dot. danej armatki@@powerSourceTabHeaderStats">
              Statystyki
            </p>
          </div>
        </ss-tab>
        <ss-tab tabTitle="Ustawienia"
          *ssdHideIfNotGranted="'ROLE_OPERATOR'"
          [hidden]="!powerSource.canDisplayCurrentPowerDraw()"
          i18n-tabTitle="Ustawienia|Tytuł zakładki z ustawieniam@@powerSourceDetailTabTitleSettings">
          <div class="p-3">
            <div class="row">
              <div class="col">

                <dl>
                  <dt i18n="Limit poboru mocy|input Limit poboru mocy@@tabConfigurePowerSourceLimitPowerDrawInfo">Limit poboru mocy</dt>


                  <dd *ngIf="powerSource.limitPowerDraw > 0 ; else snowCannonSettingsTabRemoteMeteoConditionsAlt">
                    <ng-container i18n="Limit poboru mocy|@@tabConfigurePowerSourceLimitPowerDrawIsSetWithValue">Limit poboru mocy</ng-container>&nbsp;
                    <span>{{ powerSource.limitPowerDraw|number:'1.0-0' }}kW</span>
                  </dd>
                  <ng-template #snowCannonSettingsTabRemoteMeteoConditionsAlt>
                    <dd i18n="Brak zdefiniowanego limitu poboru mocy|Brak zdefiniowanego limitu poboru mocy@@tabConfigurePowerSourceLimitPowerDrawNotSet">Brak zdefiniowanego limitu poboru mocy</dd>
                  </ng-template>
                </dl>
              </div>

              <div class="col-auto">
                <button type="button" class="btn btn-default"
                  i18n="Zmień konfigurację|Przycisk ustawień@@tabConfigurePowerSourceLimitPowerDrawOpenModalButton"
                  (click)="goToPoweSourceConfig()">Zmień konfigurację</button>
              </div>
            </div>
          </div>
        </ss-tab>
        <ss-user-notes-tab
          [context]="powerSource.id"
          tabTitle="Notatki"
          i18n-tabTitle="Notatki|Tytuł zakładki z notatkami dot. danej armatki@@powerSourceDetailTabTitleNotes"></ss-user-notes-tab>
        <ss-documentation-tab
          [context]="powerSource.id"
          [extraWide]="true"
          tabTitle="Dokumentacja"
          i18n-tabTitle="Dokumentacja|Tytuł zakładki z dokuemntacją dot. danej armatki@@powerSourceDetailTabTitleDocumentation"></ss-documentation-tab>
        <ss-tab tabTitle="Debug" *ssdHideIfNotGranted="'ROLE_SUPERADMIN'">
          <div class="p-3 color-light-grey">
            <h1>{{ powerSource.systemType }} {{ powerSource.symbol }}</h1>
            <ss-debug-table [data]="powerSource"></ss-debug-table>

            <ng-container *ngFor="let wp of electricalLines">
              <h1>{{ wp.systemType }} {{ wp.symbol }}</h1>
              <ss-debug-table [data]="wp"></ss-debug-table>
            </ng-container>
          </div>
        </ss-tab>
        <ss-notifications-tab
          [context]="devIds"
          [extraWide]="true"
          tabTitle="Powiadomienia"
          i18n-tabTitle="Powiadomienia|Tytuł zakładki z listą awarii@@powerSourceDetailTabTitleNotifications"></ss-notifications-tab>
      </ss-tabs>
    </div>

    <ss-page-accordion-panel>
      <ss-page-accordion-block *ngIf="electricalLines && electricalLines.length" [expand]="true">

        <ss-page-accordion-block-header>
          <div class="row">
            <div class="col-4">
              <h3><ng-container i18n="Punkty pomiarowe|Punkty pomiarowe - tytuł panelu@@powerSourceDetailElectricalLinePanelTitle">Punkty pomiarowe</ng-container> ({{ electricalLines.length }})</h3>
            </div>
            <div class="col-8">
              <ss-pump-room-detail-multi-status [components]="electricalLines">
                <ss-pump-room-detail-status status="standing-by" class="first-compacted last-compacted"></ss-pump-room-detail-status>
                <ss-pump-room-detail-status status="breakdown" [standalone]="true"></ss-pump-room-detail-status>
                <ss-pump-room-detail-status status="connection-lost" [standalone]="true"></ss-pump-room-detail-status>
              </ss-pump-room-detail-multi-status>
            </div>
          </div>
        </ss-page-accordion-block-header>
        <div class="row component-row">
          <div class="col-4" *ngFor="let p of electricalLines">
            <ss-electrical-line-tile
              [electricalLine]="p"></ss-electrical-line-tile>
          </div>
        </div>


      </ss-page-accordion-block>
    </ss-page-accordion-panel>

  </div>
</perfect-scrollbar>