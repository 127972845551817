import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DocumentationFile } from 'src/app/shared/models';
import { DocumentationFilesService } from './documentation-files.service';

@Component({
  selector: 'ss-documentation-files',
  templateUrl: './documentation-files.component.html',
  styleUrls: ['./documentation-files.component.sass']
})
export class DocumentationFilesComponent implements OnInit {

  @Input()
  public set context(context: string) {
    this.files = this.dfs.findByContextString(context);
    this.canBeHidden.emit(!this.files || this.files.length <= 0);
  }

  @Input()
  files: DocumentationFile[] = [];

  @Input()
  public extraWide: boolean;

  constructor(private dfs: DocumentationFilesService) { }

  @Output()
  canBeHidden: EventEmitter<boolean> = new EventEmitter();

  ngOnInit() {
  }

}
