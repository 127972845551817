import { isArray, isBoolean } from '../../../../../../common';
import { PumpRoom } from '../pump-room.model';

export abstract class Component {
  symbol: string;
  type: string;
  subType: string;
  typeDescription: string;
  servesDevice: string[];

  public pumpRoom: PumpRoom;

  public static create(pumpRoom: PumpRoom, data: any): Component {
    let resp: Component;
    switch (data['type']) {
      case 'pump':
        resp = new Pump();
        break;
      case 'valve':
        resp = new Valve();
        break;
      case 'filter':
        resp = new Filter();
        break;
      case 'coolingTower':
        resp = new CoolingTower();
        break;
      case 'sensor':
        resp = new Sensor();
        break;
      case 'visualComponent':
        resp = new VisualComponent();
        break;
      case 'additionalModeOfOperation':
        resp = new ModeOfOperation();
        break;
      case 'aerationLevel':
        resp = new AerationLevel();
        break;
      case 'compressor':
        resp = new Compressor();
        break;
      case 'levelIndicator':
        resp = new LevelIndicator();
        break;
      default:
        throw new Error('invlid pumproom component');
    }

    resp.pumpRoom = pumpRoom;
    return Object.assign(resp, data);
  }

  public isSelectedByDeviceId(id: string) {
    return !isArray(this.servesDevice) || this.servesDevice.includes(id);
  }

  public isSelectedByDeviceIds(ids: string[]) {
    return !isArray(this.servesDevice)
      || ids.reduce((p, id) => p || this.servesDevice.includes(id), false);
  }

  protected getVal(fieldName: string): string {
    return this.pumpRoom.extraParameters && this.pumpRoom.extraParameters[fieldName] || null;
  }

  public abstract get computedStatus(): string;
  public abstract get hasSensorData(): boolean;
}

export class Sensor extends Component {
  module: string;
  number: string;
  valueVar: string;
  valueUnit: string;
  isBreakdownVar: string;

  get computedStatus() {
    if (!this.pumpRoom.connectionStatus) {
      return 'connection-lost';
    }
    if (this.isBreakdown) {
      return 'breakdown';
    } else if (this.valueVar) {
      return 'working';
    }
    return 'standing-by';
  }

  get value(): number {
    const v = this.getVal(this.valueVar);
    if(isBoolean(v)) {
      return !!v ? 1 : 0;
    }
    return parseFloat(v) || 0;
  }
  get isBreakdown(): boolean {
    return !!this.getVal(this.isBreakdownVar);
  }

  get hasSensorData(): boolean {
    return !!this.valueVar;
  }
}

export class VisualComponent extends Component {
  get computedStatus() {
    return 'standing-by';
  }
  get hasSensorData(): boolean {
    return false;
  }
}
export class Pump extends Component {

  isAutoVar: string;
  isBreakdownVar: string;
  isFslVar: string;
  isReadyVar: string;
  isManualVar: string;
  isWorkingVar: string;
  isDryRunVar: string;
  isOverheatedVar: string;
  isOvercurrentVar: string;
  currentDrawVar: string;
  vfdFrequencyVar: string;
  isHeaterOnVar: string;

  currentDrawSensorModule: string;
  currentDrawSensorNumber: string;

  get computedStatus(): string {
    if (!this.pumpRoom.connectionStatus) {
      return 'connection-lost';
    }
    if (this.isBreakdown) {
      return 'breakdown';
    }

    if (this.isDryRun) {
      return 'dry-run';
    }
    if (this.isOverheated) {
      return 'overheated';
    }
    if (this.isOvercurrent) {
      return 'overcurrent';
    }
    if (this.isWorking && this.isFsl) {
      return 'fsl';
    }

    if (this.isWorking) {
      return 'working';
    }
    if (this.isReady) {
      return 'ready-to-work';
    }

    return 'standing-by';
  }
  get isAuto(): boolean {
    return !!this.getVal(this.isAutoVar);
  }
  get isReady(): boolean {
    return !!this.getVal(this.isReadyVar);
  }
  get isManual(): boolean {
    return !!this.getVal(this.isManualVar);
  }
  get isWorking(): boolean {
    return !!this.getVal(this.isWorkingVar);
  }
  get isFsl(): boolean {
    return !!this.getVal(this.isFslVar);
  }
  get isBreakdown(): boolean {
    return !!this.getVal(this.isBreakdownVar);
  }
  get isDryRun(): boolean {
    return !!this.getVal(this.isDryRunVar);
  }
  get isOverheated(): boolean {
    return !!this.getVal(this.isOverheatedVar);
  }
  get isOvercurrent(): boolean {
    return !!this.getVal(this.isOvercurrentVar);
  }
  get isHeaterOn(): boolean {
    return !!this.getVal(this.isHeaterOnVar);
  }

  get currentDraw(): number {
    return parseFloat(this.getVal(this.currentDrawVar)) || 0;
  }
  get vfdFrequency(): number {
    return parseFloat(this.getVal(this.vfdFrequencyVar)) || 0;
  }


  get hasSensorData(): boolean {
    return !!this.isWorkingVar;
  }
}

export class Valve extends Component {
  isWorkingVar: string;
  isBreakdownVar: string;
  isOpenVar: string;
  isClosedVar: string;
  isManualVar: string;
  isAutoVar: string;
  openedPercentVar: string;
  openCommandVar: string;
  closeCommandVar: string;
  isContinous: boolean;
  get isWorking(): boolean {
    return !!this.getVal(this.isWorkingVar);
  }
  get isBreakdown(): boolean {
    return !!this.getVal(this.isBreakdownVar);
  }
  get isOpen(): boolean {
    return !!this.getVal(this.isOpenVar);
  }
  get isClosed(): boolean {
    return !!this.getVal(this.isClosedVar);
  }
  get isManual(): boolean {
    return !!this.getVal(this.isManualVar);
  }
  get isAuto(): boolean {
    return !!this.getVal(this.isAutoVar);
  }
  get openedPercent(): number {
    return parseFloat(this.getVal(this.openedPercentVar)) || 0;
  }
  get computedStatus(): string {
    if (!this.pumpRoom.connectionStatus) {
      return 'connection-lost';
    }
    if (this.isBreakdown) {
      return 'breakdown';
    }
    if (this.isWorking) {
      return 'working';
    }

    return 'standing-by';
  }
  get hasSensorData(): boolean {
    return !!this.isWorkingVar;
  }
}

export class ModeOfOperation extends Component {
  isWorkingVar: string;
  startCommandVar: string;
  stopCommandVar: string;
  get isWorking(): boolean {
    return !!this.getVal(this.isWorkingVar);
  }
  get computedStatus(): string {
    if (!this.pumpRoom.connectionStatus) {
      return 'connection-lost';
    }
    if (this.isWorking) {
      return 'enabled';
    }

    return 'disabled';
  }
  get hasSensorData(): boolean {
    return false;
  }
}

export class Filter extends Component {
  isBreakdownVar: string;
  isWorkingVar: string;
  get isBreakdown(): boolean {
    return !!this.getVal(this.isBreakdownVar);
  }
  get isWorking(): boolean {
    return !!this.getVal(this.isWorkingVar);
  }
  get computedStatus(): string {
    if (!this.pumpRoom.connectionStatus) {
      return 'connection-lost';
    }

    if (this.isBreakdown) {
      return 'breakdown';
    }
    if (this.isWorking) {
      return 'working';
    }

    return 'standing-by';
  }
  get hasSensorData(): boolean {
    return !!this.isWorkingVar;
  }
}
export class CoolingTower extends Component {
  isBreakdownVar: string;
  isReadyVar: string;
  isWorkingVar: string;
  currentWaterTemperatureVar: string;
  currentAirTemperatureVar: string;
  isManualVar: string;
  isAutoVar: string;
  isHeaterOnVar: string;

  get isReady(): boolean {
    return !!this.getVal(this.isReadyVar);
  }
  get isWorking(): boolean {
    return !!this.getVal(this.isWorkingVar);
  }
  get isBreakdown(): boolean {
    return !!this.getVal(this.isBreakdownVar);
  }
  get isManual(): boolean {
    return !!this.getVal(this.isManualVar);
  }
  get isAuto(): boolean {
    return !!this.getVal(this.isAutoVar);
  }
  get isHeaterOn(): boolean {
    return !!this.getVal(this.isHeaterOnVar);
  }
  get computedStatus(): string {
    if (!this.pumpRoom.connectionStatus) {
      return 'connection-lost';
    }

    if (this.isBreakdown) {
      return 'breakdown';
    }
    if (this.isWorking) {
      return 'working';
    }
    if (this.isReady) {
      return 'ready-to-work';
    }

    return 'standing-by';
  }

  get currentWaterTemperature(): number {
    return parseFloat(this.getVal(this.currentWaterTemperatureVar)) || 0;
  }

  get currentAirTemperature(): number {
    return parseFloat(this.getVal(this.currentAirTemperatureVar)) || 0;
  }
  get hasSensorData(): boolean {
    return !!this.isBreakdownVar
    || !!this.currentWaterTemperatureVar
    || !!this.currentAirTemperatureVar;
  }

}

export class AerationLevel extends Component {
  isWorkingVar: string;
  isBreakdownVar: string;
  isManualVar: string;
  isAutoVar: string;

  get isWorking(): boolean {
    return !!this.getVal(this.isWorkingVar);
  }
  get isBreakdown(): boolean {
    return !!this.getVal(this.isBreakdownVar);
  }
  get isManual(): boolean {
    return !!this.getVal(this.isManualVar);
  }
  get isAuto(): boolean {
    return !!this.getVal(this.isAutoVar);
  }
  get computedStatus(): string {
    if (!this.pumpRoom.connectionStatus) {
      return 'connection-lost';
    }

    if (this.isBreakdown) {
      return 'breakdown';
    }
    if (this.isWorking) {
      return 'working';
    }

    return 'standing-by';
  }

  get hasSensorData(): boolean {
    return !!this.isBreakdownVar
    || !!this.isWorkingVar;
  }
}

export class LevelIndicator extends Component {
  isHighLevelExceededVar: string;
  isHighLevelReachedVar: string;
  isMediumLevelReachedVar: string;
  isLowLevelReachedVar: string;

  isBreakdownVars: string[];

  public get computedStatus(): string {
    if (!this.pumpRoom.connectionStatus) {
      return 'connection-lost';
    }

    if (this.isBreakdown) {
      return 'breakdown';
    }

    return 'standing-by';
  }

  get isBreakdown(): boolean {
    if(isArray(this.isBreakdownVars)) {
      return this.isBreakdownVars.reduce((prev, bv) => prev || !!this.getVal(bv), false);
    }
    return false;
  }

  public get hasSensorData(): boolean {
    return !!this.isHighLevelReachedVar
    || !!this.isHighLevelExceededVar
    || !!this.isMediumLevelReachedVar
    || !!this.isLowLevelReachedVar
    || !!this.isBreakdownVars;
  }

  get isHighLevelExceeded(): boolean {
    return !!this.getVal(this.isHighLevelExceededVar);
  }
  get isHighLevelReached(): boolean {
    return !!this.getVal(this.isHighLevelReachedVar);
  }
  get isMediumLevelReached(): boolean {
    return !!this.getVal(this.isMediumLevelReachedVar);
  }
  get isLowLevelReached(): boolean {
    return !!this.getVal(this.isLowLevelReachedVar);
  }

}

export class Compressor extends Component {
  isReadyVar: string;
  isWorkingVar: string;
  isBreakdownVar: string;
  isManualVar: string;
  isAutoVar: string;

  get isReady(): boolean {
    return !!this.getVal(this.isReadyVar);
  }
  get isWorking(): boolean {
    return !!this.getVal(this.isWorkingVar);
  }
  get isManual(): boolean {
    return !!this.getVal(this.isManualVar);
  }
  get isAuto(): boolean {
    return !!this.getVal(this.isAutoVar);
  }
  get isBreakdown(): boolean {
    return !!this.getVal(this.isBreakdownVar);
  }
  get computedStatus(): string {
    if (!this.pumpRoom.connectionStatus) {
      return 'connection-lost';
    }

    if (this.isBreakdown) {
      return 'breakdown';
    }
    if (this.isWorking) {
      return 'working';
    }
    if (this.isReady) {
      return 'ready-to-work';
    }

    return 'standing-by';
  }

  get hasSensorData(): boolean {
    return !!this.isBreakdownVar
    || !!this.isReadyVar
    || !!this.isWorkingVar;
  }
}
