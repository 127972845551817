<div class="info-row">
  <div class="row">
    <div class="col">
      <h3 i18n="Konfiguracja armatki|Tytuł@@snowCannonSettingsTabSnowCannonTitle">Konfiguracja armatki</h3>
      <dl>
        <dt i18n="Hydrant|Nagłówek@@snowCannonSettingsTabSnowCannonHydrantTitle">Hydrant</dt>
        <dd *ngIf="snowCannon.conenctionPointRef; else snowCannonSettingsTabSnowCannonHydrantNone">
          <ng-container i18n="Armatka jest podłączona do|Info@@snowCannonSettingsTabSnowCannonHydrantDesc">Armatka jest podłączona do</ng-container>&nbsp;
          <span><ss-translated-device-type-symbol [device]="snowCannon.conenctionPointRef"></ss-translated-device-type-symbol></span>
        </dd>
        <ng-template #snowCannonSettingsTabSnowCannonHydrantNone>
          <dd i18n="Armatka nie jest podłączona do żadnego hydrantu|Brak@@snowCannonSettingsTabSnowCannonHydrantNone">Armatka nie jest podłączona do żadnego hydrantu</dd>
        </ng-template>
      </dl>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-default"
        i18n="Zmień konfigurację armatki|Przycisk ustawień armatki@@snowCannonSettingsTabBtnCfgSnowCannon"
        (click)="goToCannonConfig()">Zmień konfigurację armatki</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <dl>
        <dt i18n="Wysyłanine notyfikacje o przejściu armatki w wybrane statusy|Nagłówek@@snowCannonSettingsTabSnowCannonNotificationsTitle">Wysyłanine notyfikacje o przejściu armatki w wybrane statusy</dt>
        <dd *ngIf="snowCannon.notifyAboutReachingComputedStatusses && snowCannon.notifyAboutReachingComputedStatusses.length > 0 ; else snowCannonSettingsTabNotificationsDisabled">
          <ng-container i18n="Armatka wysyła notyfikacje dla następujących statusów:|Info o statusach dla ktorych będą wysyłane notyfikacje@@snowCannonSettingsTabSnowCannonNotificationsEnabled">Armatka wysyła notyfikacje dla następujących statusów:</ng-container>
          <ng-container *ngFor="let n of snowCannon.notifyAboutReachingComputedStatusses">
            &nbsp;<ss-snow-cannon-status size="small" [state]="n"></ss-snow-cannon-status>
          </ng-container>
        </dd>
        <ng-template #snowCannonSettingsTabNotificationsDisabled>
          <dd i18n="Armatka nie wysyła notyfikacji o zmianie statusu|Brak notyfikacji@@snowCannonSettingsTabNotificationsNone">Armatka nie wysyła notyfikacji o zmianie statusu</dd>
        </ng-template>

        <ng-container *ngIf="snowCannon.CAP_SET_REMOTE_CONDITIONS">
          <dt i18n="Współdzielenie paremetrów pogodowych|Nagłówek@@snowCannonSettingsTabSnowCannonRemoteMeteoConditionsTitle">Współdzielenie paremetrów pogodowych</dt>
          <dd *ngIf="snowCannon.remoteMeteoStationRef; else snowCannonSettingsTabRemoteMeteoConditionsAlt">
            <ng-container i18n="Armatka otrzymuje dane pogodowe z|Info@@snowCannonSettingsTabSnowCannonRemoteMeteoConditionsDesc">Armatka otrzymuje dane pogodowe z</ng-container>&nbsp;
            <span><ss-translated-device-type-symbol [device]="snowCannon.remoteMeteoStationRef"></ss-translated-device-type-symbol></span>
          </dd>
          <ng-template #snowCannonSettingsTabRemoteMeteoConditionsAlt>
            <dd i18n="Armatka nie otrzymuje danych pogodowych z zewnętrznego źródła|Brak@@snowCannonSettingsTabSnowCannonRemoteMeteoConditionsNone">Armatka nie otrzymuje danych pogodowych z zewnętrznego źródła</dd>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="snowCannon.connectionStatus && snowCannon.CAP_CHANGE_MAXIMUM_WATER_PRESSURE">
          <dt i18n="Ciśnienie wody|Nagłówek@@snowCannonSettingsTabSnowCannonMaximumWaterPressureTitle">Ciśnienie wody</dt>
          <dd>
            <ng-container i18n="Na armatce skonfigurowano ciśnienie maksymalne|Info@@snowCannonSettingsTabSnowCannonMaximumWaterPressureDesc">Na armatce skonfigurowano ciśnienie maksymalne</ng-container>&nbsp;
            <span>{{ snowCannon.maximumWaterPressure|number }}bar</span>
          </dd>
        </ng-container>
        <ng-container *ngIf="snowCannon.connectionStatus && snowCannon.CAP_CHANGE_MAXIMUM_WATER_FLOW">
          <dt i18n="Maksymalny przepływ wody|Nagłówek@@snowCannonSettingsTabSnowCannonMaximumWaterFlowTitle">Maksymalny przepływ wody</dt>
          <dd>
            <ng-container i18n="Na armatce skonfigurowano przepływ maksymalny|Info@@snowCannonSettingsTabSnowCannonMaximumWaterFlowDesc">Na armatce skonfigurowano przepływ maksymalny</ng-container>&nbsp;
            <span>{{ snowCannon.maximumWaterFlow|number:'1.1-1' }}l/s</span>
          </dd>
        </ng-container>
      </dl>
    </div>
  </div>
</div>
<div class="info-row" *ngIf="snowCannon.conenctionPointRef">
  <div class="row">
    <div class="col">
      <h3 i18n="Konfiguracja hydrantu|Tytuł@@snowCannonSettingsTabHydrantConfigTitle">Konfiguracja hydrantu {{ snowCannon.conenctionPointRef.symbol }}</h3>
      <dl>
        <dt i18n="Przypisanie do grupy|Nagłówek@@snowCannonSettingsTabHydrantConfigAssignmentTitle">Przypisanie do grupy</dt>
        <dd *ngIf="snowCannon.conenctionPointRef.workingAreaRef; else snowCannonSettingsTabHydrantConfigAssignmentNone">
          <ng-container i18n="Hydrant jest przypisany do|Info@@snowCannonSettingsTabHydrantConfigAssignmentDesc">Hydrant jest przypisany do</ng-container>&nbsp;
          <span><ss-translated-device-type-symbol [device]="snowCannon.conenctionPointRef.workingAreaRef"></ss-translated-device-type-symbol></span>
        </dd>
        <ng-template #snowCannonSettingsTabHydrantConfigAssignmentNone>
          <dd i18n="Hydrant nie jest przypisany do żadnej grupy|Brak@@snowCannonSettingsTabHydrantConfigAssignmentNone">Hydrant nie jest przypisany do żadnej grupy</dd>
        </ng-template>
        <dt i18n="Kąt ustawienia podstawy względem północy|Nagłówek@@snowCannonSettingsTabHydrantConfigAzimuthTitle">Kąt ustawienia podstawy względem północy</dt>
        <dd>
          <ng-container i18n="Podstawa jest ustawiona pod kątem|Info@@snowCannonSettingsTabHydrantConfigAzimuthDesc">Podstawa jest ustawiona pod kątem</ng-container>&nbsp;
          <span>{{ (snowCannon.conenctionPointRef.baseOrientationOffsetFromNorth || 0) | number }}&deg;</span>
        </dd>
      </dl>
    </div>
    <div class="col-auto">
      <button type="button" class="btn w-100 btn-default"
        i18n="Zmień konfigurację hydrantu|Przycisk ustawień hydrantu@@snowCannonSettingsTabBtnCfgConnectionPoint"
        (click)="goToHydrantConfig()">Zmień konfigurację hydrantu</button>
      <button type="button" class="btn w-100 mt-1 btn-default"
        *ssdHideIfNotGranted="'ROLE_SUPERADMIN'"
        i18n="Konfiguruj grupy hydrantów|Przejście do konfiguratora grup@@snowCannonSettingsTabBtnCfgWorkingGroups"
        [routerLink]="['/application/resort/area', area.id, 'configure']">Konfiguruj grupy hydrantów</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <!--
      <dl>
        {{ snowCannon.conenctionPointRef.priority }}
        <dt>Kontrola wiatru</dt>
        <dd *ngIf="snowCannon.conenctionPointRef.resortAreaRef.limitWindSpeed > 0">Armatki zostaną wyłączona przy wietrze o prędkości powyżej <span>{{ snowCannon.conenctionPointRef.resortAreaRef.limitWindSpeed|number:'1.0-0' }}m/s</span></dd>
        <dd *ngIf="snowCannon.conenctionPointRef.resortAreaRef.limitWindSpeed <= 0">Wyłączona</dd>
        <dt>Kontrola produkcji śniegu</dt>
        <dd *ngIf="snowCannon.conenctionPointRef.limitSnowProductionVolume > 0">Armatki zostaną wyłączona po wyprodukowaniu {{ snowCannon.conenctionPointRef.limitSnowProductionVolume|number:'1.0-0' }}m3 śniegu w tym sezonie</dd>
        <dd *ngIf="snowCannon.conenctionPointRef.limitSnowProductionVolume <= 0">Wyłączona</dd>
        <dt>Kontrola zużycia wody</dt>
        <dd *ngIf="snowCannon.conenctionPointRef.limitWaterConsumption > 0">Armatki zostaną wyłączona po zużyciu {{ snowCannon.conenctionPointRef.limitWaterConsumption|number:'1.0-0' }}m3 wody w tym sezonie</dd>
        <dd *ngIf="snowCannon.conenctionPointRef.limitWaterConsumption <= 0">Wyłączona</dd>
        <dt>Kontrola zużycia prądu</dt>
        <dd *ngIf="snowCannon.conenctionPointRef.limitPowerConsumption > 0">Armatki zostaną wyłączona po zużyciu {{ snowCannon.conenctionPointRef.limitPowerConsumption|number:'1.0-0' }}m3 prądu w tym sezonie</dd>
        <dd *ngIf="snowCannon.conenctionPointRef.limitPowerConsumption <= 0">Wyłączona</dd>
      </dl>
      -->
    </div>
  </div>
</div>
<!-- TODO -->
