<div class="checkbox" [ngClass]="{
    'checkbox--danger': color === 'danger',
    'checkbox--success': color === 'success',
    'checkbox--warning': color === 'warning',
    'checkbox--info': color === 'info',
    'checkbox--small': size === 'small',
    'checkbox--large': size === 'large'
}">
    <label class="checkbox__label">
        <input
        class="checkbox__input"
        type="checkbox"
        [(ngModel)]="isChecked"
        (change)="changed()"
        >
        <span class="checkbox__check">
            <svg [ngStyle]="{ 'display': isChecked ? 'block' : 'none' }">
              <use xlink:href='#icon-check'/>
            </svg>
        </span>
        <span #checkboxLabel [ngClass]="{'ml-2': hasLabel}">
            <ng-content></ng-content>
        </span>
    </label>
</div>
