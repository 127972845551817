<ng-container *ngIf="snowCannon.CAP_SHOW_OSCILLATION_INFO">
  <ng-container *ngIf="snowCannon.CAP_START_OSCILLATION; else canNotStartOscillation">
    <ss-snow-cannon-component-button deviceType="oscillator"
      [isActive]="snowCannon.isOscillationOn"
      (click)="toggleOscilaltion()"
      mode="manual"
      [size]="size"></ss-snow-cannon-component-button>
  </ng-container>
  <ng-template #canNotStartOscillation>
    <ss-snow-cannon-component-button deviceType="oscillator"
      [isActive]="snowCannon.isOscillationOn"
      [size]="size"></ss-snow-cannon-component-button>
  </ng-template>
</ng-container>