import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { ConfirmModalService } from '../confirm-modal.service';
import { SocketioClientService, UsersClientService } from 'src/app/http-clients';
import { User, UserNotificationsSchedule, UserStats } from 'src/app/shared/models';
import { ResortConfigurationService } from '../../resort-configuration.service';
import { dayjs } from '../../../../../common';

@Component({
  selector: 'ss-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.sass']
})
export class UserProfileComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private rcs: ResortConfigurationService,
    private usersClientService: UsersClientService,
    private confirmModalService: ConfirmModalService,
    private wsc: SocketioClientService
  ) { }

  atFrom: string;
  atTo: string;
  atData: any[];

  chartData: UserStats;

  user: User;
  public notificationSchedule: UserNotificationsSchedule;

  public allowNotificationSchedule = false;

  name = '';
  surname = '';
  phone = '';
  avatarUrl = '';

  oldPassword = '';
  newPassword = '';
  newPasswordRepeat = '';

  accountInfoDataDisable = false;

  passwordDataDisable = false;

  phoneNoTestInProgress = false;

  testPhoneNo() {
    this.phoneNoTestInProgress = true;
    this.wsc.sendTestSms(this.phone).subscribe(() => {
      this.phoneNoTestInProgress = false;
    }, () => {
      this.phoneNoTestInProgress = false;
    });
  }

  public notificationScheduleChanged(notificationSchedule: UserNotificationsSchedule) {
    this.notificationSchedule = notificationSchedule;
  }

  ngOnInit() {
    const hnow = Math.ceil((dayjs().valueOf() / 1000 / 3600)) * 3600;

    this.allowNotificationSchedule = this.rcs.checkCapability('hasRemoteNotifications')
      && this.authService.isAuthorized('ROLE_OPERATOR');

    this.user = this.authService.getUser();
    this.resetAccountInfoData();
    this.usersClientService.getStats(this.user.id, hnow - 7 * 24 * 3600, hnow).subscribe(data => {
      this.chartData = data;
    });
    this.usersClientService.getProfileAuditTrail(hnow - 7 * 24 * 3600, hnow).subscribe(dta => {
      this.atData = dta.data;
      this.atFrom = dta.from;
      this.atTo = dta.to;
    });
  }

  saveNotificationScheduleSettings() {
    if (this.notificationSchedule) {
      this.accountInfoDataDisable = true;
      this.usersClientService.updateNotificationsSchedule(this.user.id, this.notificationSchedule)
        .subscribe(() => {
          this.accountInfoDataDisable = false;
          this.user = this.authService.getUser();
          this.user.notificationsSchedule = this.notificationSchedule;
          this.notificationSchedule = null;
        }, () => {
          this.accountInfoDataDisable = false;
        });
    }
  }
  accountInfoDataSubmit() {
    this.accountInfoDataDisable = true;

    this.usersClientService.changeProfileData(this.name, this.surname, this.phone).subscribe(() => {
      this.user = this.authService.getUser();
      this.user.name = this.name;
      this.user.surname = this.surname;
      this.user.phone = this.phone;

      this.resetAccountInfoData();
    }, () => {
      this.resetAccountInfoData();
    });
  }

  passwordDataSubmit() {
    this.passwordDataDisable = true;
    this.usersClientService.changePassword(this.oldPassword, this.newPassword).subscribe(() => {

      this.authService.logout();

    }, () => {
      this.passwordDataDisable = false;
      this.oldPassword = '';
    });

  }

  resetAccountInfoData() {
    this.name = this.user.name;
    this.surname = this.user.surname;
    this.phone = this.user.phone;
    this.notificationSchedule = null;
    this.accountInfoDataDisable = false;
  }

  logout() {
    this.confirmModalService.openAlertDoUWannaLogout().subscribe((resp) => {
      if (resp) {
        this.authService.logout();
      }
    });
  }
}
