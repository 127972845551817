import { Component, Input, OnInit } from '@angular/core';
import { ModalRouteService } from 'src/app/application/modal-route.service';
import { ResortService } from 'src/app/resort.service';
import { Alert, ResortArea, SnowCannon } from 'src/app/shared/models';

@Component({
  selector: 'ss-area-cannons-status-display',
  templateUrl: './area-cannons-status-display.component.html',
  styleUrls: ['./area-cannons-status-display.component.sass']
})
export class AreaCannonsStatusDisplayComponent implements OnInit {

  @Input()
  public area: ResortArea;

  public snowCannons: SnowCannon[];
  public isAlertsDropdownExpanded = false;

  constructor(
    private rs: ResortService,
    private mr: ModalRouteService
  ) { }

  ngOnInit(): void {
    this.snowCannons = this.area.getSnowCannons();
  }

  get cannonAlerts(): Alert[] {
    const ids = this.snowCannons.filter(v => ['breakdown', 'connection-lost'].includes(v.computedStatus)).map(v => v.id);

    return this.rs.filterAlerts(ids, [], true);
  }

  openMassOperationsForAll() {
    const ids = this.area.getSnowCannons().filter(sc => sc.connectionStatus).map(sc => sc.id);

    this.mr.showModalRoute('mass-operations-modal/' + ids.join(','));
  }

}
