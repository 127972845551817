import { Injectable } from '@angular/core';

import { EndpointMapService } from '../endpoint-map.service';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Forecast } from '../shared/models';
import { map, tap } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ForecastClientService {

  constructor(private e: EndpointMapService, private http: HttpClient) {}

  public getAll(from: number = null, to: number = null): Observable<Forecast> {
    const options = { ... httpOptions, params: new HttpParams() };
    if (from) {
      options.params = options.params.set('from', '' + from);
    }
    if (to) {
      options.params = options.params.set('to', '' + to);
    }
    return this.http.get(this.e.forecast, options).pipe(map(a => Forecast.create(a)));
  }

}
