import { Component, OnInit, Input, HostBinding, HostListener, Output, EventEmitter } from '@angular/core';
import { DrawableComponent, Drawable } from 'src/app/shared/models/pump-room';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[ss-full-schema-drawable]',
  templateUrl: './drawable.component.html',
  styleUrls: ['./drawable.component.sass']
})
export class DrawableComponentComponent implements OnInit {

  constructor() { }

  @HostBinding('attr.transform')
  public get transform(): string {
    return 'translate(' + this.x + ' ' + this.y + ')';
  }

  get x(): number {
    return this.drawable.x - 50.5;
  }

  get y(): number {
    return this.drawable.y - 50.5;
  }

  @Input()
  public drawable: Drawable;

  public get castedDrawableComponent(): DrawableComponent<any> {
    return this.drawable as DrawableComponent<any>;
  }

  @Input()
  public allowEdit = false;

  @Output()
  public selected: EventEmitter<Drawable> = new EventEmitter();

  startDragOffsetX = 0;
  startDragOffsetY = 0;
  startDragPositionX = 0;
  startDragPositionY = 0;

  public onMouseEnter(ev: MouseEvent) {
    if (!this.allowEdit) {
 return;
}
    this.drawable.isHover = true;
  }

  public onMouseLeave(ev: MouseEvent) {
    if (!this.allowEdit) {
 return;
}
    this.drawable.isHover = false;
  }

  public onClick(ev: MouseEvent) {
    if (!this.allowEdit) {
 return;
}
    this.selected.emit(this.drawable);
  }

  onMouseDown(ev: MouseEvent) {
    if (!this.allowEdit) {
 return;
}

    ev.stopPropagation();
    ev.preventDefault();

    if (this.drawable.isSelected) {
      this.drawable.isDragged = true;

      this.startDragPositionX = this.drawable.x;
      this.startDragPositionY = this.drawable.y;
      this.startDragOffsetX = ev.screenX;
      this.startDragOffsetY = ev.screenY;
    }

  }

  onMouseMove(ev: MouseEvent) {
    if (this.drawable.isDragged) {
      const newx = 10 * Math.round(0.1 * (this.startDragPositionX + ev.screenX - this.startDragOffsetX));
      const newy = 10 * Math.round(0.1 * (this.startDragPositionY + ev.screenY - this.startDragOffsetY));

      this.drawable.from = [newx, newy];
    }
  }

  onMouseUp(ev: MouseEvent) {
    this.drawable.isDragged = false;
  }

  ngOnInit() {
  }

}
