import { Component, Input } from '@angular/core';
import { ResortService } from 'src/app/resort.service';
import { MeteoDevice } from 'src/app/shared/models';

@Component({
  selector: 'ss-meteo-conditions-named-display',
  templateUrl: './named-display.component.html',
  styleUrls: ['./named-display.component.sass']
})
export class MeteoConditionsNamedDisplayComponent {

  private _sourceId: string;
  @Input()
  public get sourceId(): string {
    return this._sourceId;
  }
  public set sourceId(v: string) {
    this._sourceId = v;
    this.sourceDevice = this.rs.getSnowCannon(v) || this.rs.getMeteoStation(v);
  }

  @Input()
  public sourceDevice: MeteoDevice;

  constructor(private rs: ResortService) { }
}
