import { Injectable, EventEmitter } from '@angular/core';
import { ChartData } from './shared/models';
import { DevicesClientService } from './http-clients';
import { dayjs, isArray, isObject } from '../../../common';

const UPADTE_INTERVAL_DURATION = 30000;

@Injectable({
  providedIn: 'root'
})
export class ResortStatisticsService {

  private stats: Map<string, ChartData> = new Map();
  private limits: Map<string, ChartData> = new Map();

  public statsUpdated: EventEmitter<any> = new EventEmitter();

  constructor(private devices: DevicesClientService) { }

  public initialize(statsData: any, seasonLimitsData: any) {
    if (isObject(statsData)) {
      for (const el of Object.values(statsData)) {
        if (isObject(el)) {
          for (const id of Object.keys(el)) {
            this.stats.set(id, el[id]);
          }
        }
      }
    }

    if (isObject(seasonLimitsData)) {
      for (const el of Object.values(seasonLimitsData)) {
        if (isObject(el)) {
          for (const id of Object.keys(el)) {
            this.limits.set(id, el[id]);
          }
        }
      }
    }

    let lastUpdate = dayjs().valueOf();
    setInterval(() => {
      const hoursToLoad = Math.ceil((dayjs().valueOf() - lastUpdate) / 3600 / 1000) + 1;

      this.devices.getStats(Array.from(this.stats.keys()), hoursToLoad > 11 ? 11 : hoursToLoad).subscribe((dta) => {
        lastUpdate = dayjs().valueOf();
        this.update(dta);
      }, () => {});
      this.devices.getSeasonLimits(Array.from(this.limits.keys()), 10).subscribe((dta) => {
        this.updateLimits(dta);
      }, () => {});
    }, UPADTE_INTERVAL_DURATION);
  }

  public updateLimits(data: any) {
    if (isObject(data)) {
      for (const el of Object.values(data)) {
        if (isObject(el)) {
          for (const id of Object.keys(el)) {
            this.limits.set(id, { ... el[id] });
          }
        }
      }
      this.statsUpdated.emit();
    }
  }

  public update(data: any) {
    if (isObject(data)) {
      for (const el of Object.values(data)) {
        if (isObject(el)) {
          for (const id of Object.keys(el)) {
            const next: ChartData = { ... el[id] };
            const last: ChartData = this.stats.get(id);
            if (
              last && next
              && last.aggregatedLabels.length > 0
              && next.aggregatedLabels
              && next.aggregatedLabels.length > 0
              && last.aggregatedLabels.length > next.aggregatedLabels.length
            ) {
              let patchOffset = last.aggregatedLabels.length - 1;
              while (last.aggregatedLabels[patchOffset] !== next.aggregatedLabels[0] && patchOffset > 0) {
                patchOffset--;
              }

              if (patchOffset === 0) {
                this.stats.set(id, next);
              } else {
                for (let i = 0; i < next.aggregatedLabels.length ; i++) {
                  for (const rs of Object.keys(last)) {
                    if (isArray(next[rs]) && isArray(last[rs])) {
                      last[rs][patchOffset + i] = next[rs][i];
                    }
                  }
                }
              }
            } else {
              this.stats.set(id, next);
            }
          }
        }
      }
      this.statsUpdated.emit();
    }
  }

  public getLimits(id: string): ChartData {
    return this.limits.get(id);
  }

  public getStats(id: string, period: number = 12): ChartData {
    const recordedStats = this.stats.get(id);

    if (!recordedStats) {
      return null;
    }

    const resp = new ChartData();
    for (const rs of Object.keys(recordedStats)) {
      if (recordedStats[rs] && isArray(recordedStats[rs])) {
        resp[rs] = recordedStats[rs].slice(-period - 1) || [];
      }
    }
    return resp;
  }
}
