import { TimedChartConfigSeriesType } from '../..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { drawSeriesBarWRoundedTop } from './draw-series-bar-w-rounded-top.function';
import { drawSeriesBar } from './draw-series-bar.function';
import { drawSeriesLineStepped } from './draw-series-line-stepped.function';
import { drawSeriesLineWRoundedEnd } from './draw-series-line-w-rounded-end.function';
import { drawSeriesLine } from './draw-series-line.function';
import { drawSeriesNowDelimiter } from './draw-series-now-delimiter.function';
import { drawSeriesPoint } from './draw-series-point.function';

export const drawSeries = (state: D3DrawerChartState): D3DrawerChartState => {
  const seriesIndexer: Map<TimedChartConfigSeriesType, number> = new Map();

  state.config.series.forEach((series, seriesIndex) => {
    const data = state.seriesSpecificData[seriesIndex]
      ? state.seriesSpecificData[seriesIndex]
      : state.defaultData;

    let seriesIndexInType = 0;

    if(seriesIndexer.has(series.seriesType)) {
      seriesIndexInType = seriesIndexer.get(series.seriesType);
    }
    seriesIndexer.set(series.seriesType, seriesIndexInType+1);

    switch(series.seriesType) {
      case 'now-delimiter':
        state = drawSeriesNowDelimiter(state, data, series, seriesIndex, seriesIndexInType);
        break;
      case 'bar-w-rounded-top':
        state = drawSeriesBarWRoundedTop(state, data, series, seriesIndex, seriesIndexInType);
        break;
      case 'line-w-rounded-end':
        state = drawSeriesLineWRoundedEnd(state, data, series, seriesIndex, seriesIndexInType);
        return;
      case 'bar':
        state = drawSeriesBar(state, data, series, seriesIndex, seriesIndexInType);
        return;
      case 'line':
        state = drawSeriesLine(state, data, series, seriesIndex, seriesIndexInType);
        return;
      case 'line-stepped':
        state = drawSeriesLineStepped(state, data, series, seriesIndex, seriesIndexInType);
        return;
      case 'point':
        state = drawSeriesPoint(state, data, series, seriesIndex, seriesIndexInType);
        return;
    }
  });

  return state;
};
