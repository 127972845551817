<div class="horizontal"
    *ngIf="snowCannon">
    <div class="circle"></div>
    <div [ngClass]="{
            'in-progress': !snowCannon.canChangePositionOrOscillation
        }"
        [ngStyle]="{
            transform: 'rotate(' + (baseAngle) + 'deg)'
        }"
        class="base-direction-wrapper">
        <div class="base-direction-shadow"></div>
        <div [ngStyle]="{
                transform: 'rotate(' + (directionAngle) + 'deg)'
            }"
            class="direction-wrapper">

            <div class="oscillation-shadow-wrapper">
                <!-- <div class="oscillation-shadow left">
                    <div [ngStyle]="{
                        transform: 'rotate(-' + (oscillationAngle || 0) + 'deg)'
                    }"></div>
                </div> -->
                <div class="oscillation-shadow right">
                    <div [ngStyle]="{
                        transform: 'rotate(' + (oscillationAngle || 0) + 'deg)'
                    }"></div>
                </div>
            </div>

            <div [ngStyle]="{
                    transform: 'rotate(' + (oscillationAngle || 0) + 'deg)'
                }" class="oscillation button">
                <button [disabled]="snowCannon.isLocked"
                    (mousedown)="putOscillationInProgress()"
                    (touchstart)="putOscillationInProgress()"
                    (window:mouseup)="releaseMoveProgress()"
                    (window:touchend)="releaseMoveProgress()"
                    (window:mousemove)="moveOscillationIndicator($event)"
                    (window:touchmove)="moveOscillationIndicator($event)">
                    <svg>
                        <use xlink:href='#icon-move'/>
                    </svg>
                </button>
                <div [ngStyle]="{
                        transform: 'rotate(' + ( - oscillationAngle - baseAngle - directionAngle) + 'deg)'
                    }"
                    [ngClass]="{
                        show: moveOscillationInProgress
                    }" class="label">{{ oscillationAngle|number }}&deg;</div>
            </div>
            <!-- <div [ngStyle]="{
                    transform: 'rotate(-' + (oscillationAngle || 0) + 'deg)'
                }" class="oscillation button">
                <button [disabled]="snowCannon.isLocked"
                    (mousedown)="putOscillationInProgress()"
                    (window:mouseup)="releaseMoveProgress()"
                    (window:mousemove)="moveOscillationIndicator($event)">
                    <svg>
                        <use xlink:href='#icon-move'/>
                    </svg>
                </button>
            </div> -->
            <div class="direction button">
                <button [disabled]="snowCannon.isLocked"
                    (mousedown)="putPositionInProgress()"
                    (touchstart)="putPositionInProgress()"
                    (window:mouseup)="releaseMoveProgress()"
                    (window:touchend)="releaseMoveProgress()"
                    (window:mousemove)="movePositionIndicator($event)"
                    (window:touchmove)="movePositionIndicator($event)"
                    *ngIf="snowCannon.CAP_CHANGE_HORIZONTAL_POSITION_ANGLE"
                    class="move">
                    <svg>
                        <use xlink:href='#icon-move-linked'/>
                    </svg>
                </button>
                <button [disabled]="snowCannon.isLocked"
                    (click)="cancelMovement()"
                    class="stop"
                    [ngStyle]="{
                        transform: 'rotate(-' + (directionAngle || 0) + 'deg)'
                    }">
                    <svg>
                        <use xlink:href='#icon-stop'/>
                    </svg>
                </button>
                <div [ngStyle]="{
                        transform: 'rotate(' + ( - baseAngle - directionAngle) + 'deg)'
                    }"
                    [ngClass]="{
                        show: movePositionInProgress
                    }"
                    *ngIf="snowCannon.CAP_CHANGE_HORIZONTAL_POSITION_ANGLE"
                    class="label">{{ directionAngle|number }}&deg;</div>
            </div>
            <div class="position target"></div>
        </div>
        <div [ngStyle]="{
                transform: 'rotate(' + (snowCannon.tubeHorizontalStartAngle || 0) + 'deg)'
            }"
            *ngIf="snowCannon.CAP_SHOW_CURRENT_HORIZONTAL_POSITION"
            class="position start">
            <div></div>
        </div>
        <div [ngStyle]="{
                transform: 'rotate(' + (snowCannon.tubeHorizontalCurrentAngle || 0) + 'deg)'
            }"
            *ngIf="snowCannon.CAP_SHOW_CURRENT_HORIZONTAL_POSITION || snowCannon.CAP_SHOW_CURRENT_OSCLLATION_POSITION"
            class="position current">
            <div></div>
        </div>
    </div>
</div>
