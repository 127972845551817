import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/header.service';
import { dayjs } from '../../../../../common';
import { UsersClientService } from '../../http-clients';

@Component({
  selector: 'ss-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.sass']
})
export class AuditTrailComponent implements OnInit, OnDestroy {

  constructor(
    private ucs: UsersClientService,
    private titleService: HeaderService
  ) { }

  private refreshInterv;
  atFrom: string;
  atTo: string;
  atData: any[];

  private refresh() {
    const hnow = Math.ceil((dayjs().valueOf() / 1000 / 3600)) * 3600;
    this.ucs.getUserAuditTrail(null, hnow - 7 * 24 * 3600, hnow).subscribe(atResponse => {

      this.atData = atResponse.data;
      this.atFrom = atResponse.from;
      this.atTo = atResponse.to;
    });
  }

  public ident(row: any): number {
    return row.id;
  }

  ngOnInit() {
    this.titleService.setUpdateHeader('menuAuditTrail');

    this.refreshInterv = setInterval(() => {
      this.refresh();
    }, 1000);
    this.refresh();
  }

  ngOnDestroy() {
    clearInterval(this.refreshInterv);
  }

}
