import { DeviceSystemType } from '..';
import { trn } from './translation-wrapper.function';

export const translateDeviceType = (systemType: DeviceSystemType): string => {
  switch(systemType) {
    case 'Resort':
      return trn($localize`:Nazwa typu urządzenia 'Cały ośrodek'|@@deviceTypeResort:Cały ośrodek`);
    case 'AirPipeline':
      return trn($localize`:Nazwa typu urządzenia 'Rurociąg sprężonego powietrza'|@@deviceTypeAirPipeline:Rurociąg sprężonego powietrza`);
    case 'CommunicationLine':
      return trn($localize`:Nazwa typu urządzenia 'Linia komunikacyjna'|@@deviceTypeCommunicationLine:Linia komunikacyjna`);
    case 'CompressorRoom':
      return trn($localize`:Nazwa typu urządzenia 'Kompresorownia'|@@deviceTypeCompressorRoom:Kompresorownia`);
    case 'ConnectionPoint':
      return trn($localize`:Nazwa typu urządzenia 'Hydrant'|@@deviceTypeConnectionPoint:Hydrant`);
    case 'ElectricalLine':
      return trn($localize`:Nazwa typu urządzenia 'Linia zasilania'|@@deviceTypeElectricalLine:Linia zasilania`);
    case 'MeteoStation':
      return trn($localize`:Nazwa typu urządzenia 'Stacja meteo'|@@deviceTypeMeteoStation:Stacja meteo`);
    case 'PowerSource':
      return trn($localize`:Nazwa typu urządzenia 'Żródło zasilania'|@@deviceTypePowerSource:Żródło zasilania`);
    case 'PumpRoom':
      return trn($localize`:Nazwa typu urządzenia 'Pompownia'|@@deviceTypePumpRoom:Pompownia`);
    case 'ResortArea':
      return trn($localize`:Nazwa typu urządzenia 'Stok'|@@deviceTypeResortArea:Stok`);
    case 'SnowCannon':
      return trn($localize`:Nazwa typu urządzenia 'Armatka'|@@deviceTypeSnowCannon:Armatka`);
    case 'WaterPipeline':
      return trn($localize`:Nazwa typu urządzenia 'Rurociąg'|@@deviceTypeWaterPipeline:Rurociąg`);
    case 'WaterReservoir':
      return trn($localize`:Nazwa typu urządzenia 'Zbiornik'|@@deviceTypeWaterReservoir:Zbiornik`);
    case 'WorkingArea':
      return trn($localize`:Nazwa typu urządzenia 'Grupa'|@@deviceTypeWorkingArea:Grupa`);
  }
};
