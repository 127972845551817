import { Component, OnInit } from '@angular/core';
import { ResortService } from 'src/app/resort.service';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { ActivatedRoute } from '@angular/router';
import { ModalRouteService } from 'src/app/application/modal-route.service';
import { WaterPipeline } from 'src/app/shared/models';

@Component({
  selector: 'ss-configure-water-pipeline-modal',
  templateUrl: './configure-water-pipeline-modal.component.html',
  styleUrls: ['./configure-water-pipeline-modal.component.sass']
})
export class ConfigureWaterPipelineModalComponent implements OnInit {

  constructor(
    private rs: ResortService,
    private cs: ConfigureClientService,
    private ar: ActivatedRoute,
    private mr: ModalRouteService
  ) { }

  public waterPipeline: WaterPipeline;
  public targetWaterPressure: number;

  ngOnInit() {
    this.ar.paramMap.subscribe(params => {
      const id = params.get('waterPipelineId');

      this.waterPipeline = this.rs.getWaterPipeline(id);

      if (!this.waterPipeline) {
        this.mr.closeModal();
        return;
      }

      this.targetWaterPressure = this.waterPipeline.targetWaterPressure;
    });
  }

  save() {
    this.cs.configureWaterPipeline(this.waterPipeline, null, this.targetWaterPressure).subscribe(() => {
      this.close();
    });
  }

  close() {
    this.mr.closeModal();
  }

}
