import { Component, OnInit, ChangeDetectionStrategy, NgZone, OnDestroy } from '@angular/core';
import { HeaderExtensionComponent } from '../../../header-extension-host.directive';
import { Subscription } from 'rxjs';
import { ModalRouteService } from '../../../modal-route.service';
import { ResortService } from 'src/app/resort.service';

@Component({
  selector: 'ss-area-header-extension',
  templateUrl: './area-header-extension.component.html',
  styleUrls: ['./area-header-extension.component.sass']
})
export class AreaHeaderExtensionComponent implements HeaderExtensionComponent, OnInit, OnDestroy {
  constructor(
    private resort: ResortService,
    private hes: ModalRouteService
  ) {}

  data: any;

  isRecentRoutesExpanded = false;
  isModalShownSubscription: Subscription;

  allowExpand = true;

  updateData(data: any) {
    this.data = data;
  }
  ngOnInit() {
    this.allowExpand = this.resort.getResortAreas().length > 1;

    this.isModalShownSubscription = this.hes.isModalShown.subscribe(data => {
      this.isRecentRoutesExpanded = data === 'resort-areas-recent';
    });
  }
  ngOnDestroy(): void {
    this.isModalShownSubscription.unsubscribe();
  }

  toggleRecentRoutes() {
    if (this.allowExpand) {
      this.hes.showModalRoute('resort-areas-recent');
    }
  }
}
