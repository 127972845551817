import { User } from './user.model';

export class PreAuthLoginData {
  allowAzureOauthLogin = false;
  resortName = '';
  resortBounds: any[];
  users: User[] = [];

  public static create(data): PreAuthLoginData {
    const resp = new PreAuthLoginData();
    if (data['users']) {
      for (const o of Object.values(data['users'])) {
        resp.users.push(Object.assign(new User(), o));
      }
    }

    resp.resortName = data['resortName'];
    resp.resortBounds = data['resortBounds'];
    resp.allowAzureOauthLogin = !!data['allowAzureOauthLogin'];

    return resp;
  }
}
