import { Component, OnInit, Input, QueryList, OnDestroy, ViewChildren, DoCheck, OnChanges, HostBinding } from '@angular/core';
import { ValidationResult } from '../validate';
import { FormErrorComponent } from '../form-error.component';
import { Subject } from 'rxjs';
import { sampleTime } from 'rxjs/operators';

@Component({
  selector: 'form-errors-display',
  templateUrl: './form-errors-display.component.html',
  styleUrls: []
})
export class FormErrorsDisplayComponent implements OnDestroy, OnInit {

  @HostBinding('class.form-errors')
  public errors = true;

  private _defaultErrorComponents: QueryList<FormErrorComponent> = new QueryList<FormErrorComponent>();
  @ViewChildren(FormErrorComponent)
  public get defaultErrorComponents(): QueryList<FormErrorComponent> {
    return this._defaultErrorComponents;
  }
  public set defaultErrorComponents(val: QueryList<FormErrorComponent>) {
    this._defaultErrorComponents = val ? val : new QueryList<FormErrorComponent>();

    this.scheduleVisibilityCalculation();
  }

  private _extraErrorComponents: QueryList<FormErrorComponent> = new QueryList<FormErrorComponent>();
  @Input()
  public get extraErrorComponents(): QueryList<FormErrorComponent> {
    return this._extraErrorComponents;
  }
  public set extraErrorComponents(val: QueryList<FormErrorComponent>) {
    this._extraErrorComponents = val ? val : new QueryList<FormErrorComponent>();

    this.scheduleVisibilityCalculation();
  }

  messagesMinIsPresent = false;
  messagesMinCondition = 0;
  messagesMaxIsPresent = false;
  messagesMaxCondition = 0;
  messagesRequiredIsPresent = false;
  messagesRequiredTrueIsPresent = false;
  messagesEmailIsPresent = false;
  messagesMinlengthIsPresent = false;
  messagesMinlengthCondition = 0;
  messagesMaxlengthIsPresent = false;
  messagesMaxlengthCondition = 0;
  messagesPatternIsPresent = false;
  messagesPatternCondition = '';

  private _messages: ValidationResult = {};
  @Input()
  public get messages(): ValidationResult {
    return this._messages;
  }
  public set messages(val: ValidationResult) {
    this._messages = val || {};

    this.messagesMinIsPresent = !!this._messages['min'];
    this.messagesMinCondition = this.messagesMinIsPresent ?
      this._messages['min']['min'] : 0;
    this.messagesMaxIsPresent = !!this._messages['max'];
    this.messagesMaxCondition = this.messagesMaxIsPresent ?
      this._messages['max']['max'] : 0;
    this.messagesRequiredIsPresent = !!this._messages['required'];
    this.messagesRequiredTrueIsPresent = !!this._messages['requiredTrue'];
    this.messagesEmailIsPresent = !!this._messages['email'];
    this.messagesMinlengthIsPresent = !!this._messages['minlength'];
    this.messagesMinlengthCondition = this.messagesMinlengthIsPresent ?
      this._messages['minlength']['requiredLength'] : 0;
    this.messagesMaxlengthIsPresent = !!this._messages['maxlength'];
    this.messagesMaxlengthCondition = this.messagesMaxlengthIsPresent ?
      this._messages['maxlength']['requiredLength'] : 0;
    this.messagesPatternIsPresent = !!this._messages['pattern'];
    this.messagesPatternCondition = this.messagesPatternIsPresent ?
      this._messages['pattern']['requiredPattern'] : '';

    this.scheduleVisibilityCalculation();
  }

  scheduleVisibilityCalculationSampler: Subject<any> = new Subject();

  ngOnInit() {
    this.scheduleVisibilityCalculationSampler
      .pipe(sampleTime(100))
      .subscribe(() => {
        const allComponents: FormErrorComponent[] = [...this.defaultErrorComponents.toArray(), ...this.extraErrorComponents.toArray()];
        allComponents.forEach(ec => {
          ec.data = null;
          ec.isLastVisibleError = false;
        });
        Object.keys(this._messages).forEach(msgKey => {
          const ecs = allComponents.filter(ec => ec.validator === msgKey);
          if (ecs.length > 0) {
            ecs[ecs.length - 1].data = this._messages[msgKey];
          }
        });
        let lastEC: FormErrorComponent = null;
        allComponents.forEach(el => {
          if (el.isDisplayed) {
            lastEC = el;
          }
        });
        if (lastEC) {
          lastEC.isLastVisibleError = true;
        }
      });
  }

  ngOnDestroy() {
    this.scheduleVisibilityCalculationSampler.unsubscribe();
  }

  private scheduleVisibilityCalculation() {
    this.scheduleVisibilityCalculationSampler.next();
  }
}
