<div class="map-hover-preview" [ngClass]="{
  'breakdown': meteoStation.computedStatus === 'breakdown',
  'connection-lost': meteoStation.computedStatus === 'connection-lost',
  'map-hover-preview--bottom-right': !direction || direction === 'bottom-right',
  'map-hover-preview--top-right': direction === 'top-right',
  'map-hover-preview--top-left': direction === 'top-left',
  'map-hover-preview--bottom-left': direction === 'bottom-left'
}" *ngIf="meteoStation">
  <div class="map-hover-preview__header d-flex align-items-center">
    <div class="px-3 py-2">
      <p class="fs-24">{{ meteoStation.symbol }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ss-meteo-conditions-display type="grid" [sourceDevice]="meteoStation"></ss-meteo-conditions-display>
    </div>
  </div>
</div>
