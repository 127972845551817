import { Component, OnInit, HostListener } from '@angular/core';
import { PumpRoom } from 'src/app/shared/models';
import { ResortService } from 'src/app/resort.service';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from 'src/app/header.service';

@Component({
  selector: 'ss-pump-room-full-schema-editor',
  templateUrl: './full-schema.component.html',
  styleUrls: ['./full-schema.component.sass']
})
export class PumpRoomFullSchemaEditorComponent implements OnInit {

  constructor(
    private titleService: HeaderService,
    private resortService: ResortService,
    private route: ActivatedRoute
  ) { }

  pumpRoom: PumpRoom;

  shouldToolbarDetach = false;

  save() {
    console.log(JSON.stringify(this.pumpRoom.configuration.fullSchema.buildEntries()));
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.code === 'KeyS' && event.ctrlKey && event.shiftKey) {
      this.save();
    }
  }

  ngOnInit() {
    this.titleService.setUpdateHeader('menuEditor');

    this.route.paramMap.subscribe(data => {
      this.pumpRoom = this.resortService.getPumpRoom(data.get('pumpRoomId'));
    });
  }

  onScrollY(offset: number) {
    this.shouldToolbarDetach = offset > 80;
  }

}
