import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { UsersClientService } from 'src/app/http-clients';
import { User } from 'src/app/shared/models';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SingleUserResolverService implements Resolve<User> {

  constructor(private ucs: UsersClientService) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> | Observable<never> {
    const id: number = parseInt(route.paramMap.get('userId'), 10);

    if (!id || isNaN(id)) {
      return of(new User());
    }

    return this.ucs.get(id);
  }
}
