<div class="row" #container>
  <div class="col">
    <ng-select class="custom-select custom-select--simple custom-select--small fs-14 text-uppercase"
      [(ngModel)]="duration" [clearable]="false" [searchable]="false" (change)="setDuration($event)">
      <ng-template ng-label-tmp let-item="item">
        <span class="color-light-grey text-uppercase">
          <ng-container i18n="Ostatnie|Wykresy - etykieta ostatnie (wybrany zakres godzin)@@chartLatestHoursLabel">Ostatnie</ng-container>:
        </span>
        <span class="color-dark-grey pl-2">{{item}}h</span>
      </ng-template>
      <ng-option [value]="6" i18n="Ostatnie 6h|Wykresy - etykieta ostatnie 6h@@chartLatestHours6hLabel">Ostatnie 6h</ng-option>
      <ng-option [value]="12" i18n="Ostatnie 12h|Wykresy - etykieta ostatnie 12h@@chartLatestHours12hLabel">Ostatnie 12h</ng-option>
      <ng-option [value]="24" i18n="Ostatnie 24h|Wykresy - etykieta ostatnie 24h@@chartLatestHours24hLabel">Ostatnie 24h</ng-option>
    </ng-select>
  </div>
  <div class="col-auto" *ngIf="hasTemp">
    <div class="twb">
      <span></span>
      <ng-container i18n="TWB|Wykresy - etykieta TWB@@chartWetBulbTemperatureLabel">TWB</ng-container>
    </div>
  </div>
</div>

<ss-d3-chart-drawer
  [viewBoxHeight]="120"
  [viewBoxWidth]="container.offsetWidth"
  [config]="config"
  [data]="data"></ss-d3-chart-drawer>

