import { EventEmitter, Inject, Injectable, LOCALE_ID } from '@angular/core';
import { from, Observable } from 'rxjs';
import { bufferTime, filter } from 'rxjs/operators';
import { dayjs, isArray, ReportDefinition, ReportExport, ReportExportFormat } from '../../../../../common';
import { AuthService } from '../../auth.service';
import { ReportingClientService, SocketioClientService } from '../../http-clients';

@Injectable({
  providedIn: 'root'
})
export class ReportingReportExportService {

  constructor(
    @Inject(LOCALE_ID) private locale,
    private auth: AuthService,
    private rcs: ReportingClientService,
    private socketIoClient: SocketioClientService
  ) {
    this.socketIoClient.fileExportsNotification
    .pipe(
      bufferTime(500),
      filter(b => b.length > 0)
    )
    .subscribe(() => {
      this.fetchLatestForUser();
    });

    this.fetchLatestForUser();
  }

  public recentExportChanged: EventEmitter<ReportExport[]> = new EventEmitter();
  public recentExports: ReportExport[] = [];

  private fetchLatestForUser() {
    this.rcs.getRreportingExportsLatest().subscribe(resp => {
      this.recentExports = resp;
      this.recentExportChanged.emit(resp);
    });
  }

  public scheduleExport(report: ReportDefinition, format: ReportExportFormat): Observable<any> {
    const exportRequest = ReportExport.create({ ...report, format, reportId: report.id, locale: this.locale });

    return this.rcs.scheduleExport(exportRequest);
  }

  public download(e: ReportExport): Observable<any> {
    const obs = this.rcs.download(e, this.auth.getApiKey());
    obs.subscribe({
      next: (fileResponse: any) => {
        const fromTo = e.from.format('YYYY-MM-DD') + ' - ' + e.to.format('YYYY-MM-DD');
        const now = dayjs().format('YYYYMMDDHHmmss');
        const filename = `${e.reportName || e.reportId} ${fromTo} (${now})`;

        const guessExtension = (mimetype: string): string => {
          // subset from npm package 'mime-db'
          const mimeDict = {
            'application/epub+zip': {source:'iana',compressible:false,extensions:['epub']},
            'application/geo+json': {source:'iana',compressible:true,extensions:['geojson']},
            'application/gzip': {source:'iana',compressible:false,extensions:['gz']},
            'application/java-archive': {source:'apache',compressible:false,extensions:['jar','war','ear']},
            'application/json': {source:'iana',charset:'UTF-8',compressible:true,extensions:['json','map']},
            'application/msword': {source:'iana',compressible:false,extensions:['doc','dot']},
            'application/pdf': {source:'iana',compressible:false,extensions:['pdf']},
            'application/vnd.americandynamics.acc': {source:'iana',extensions:['acc']},
            'application/vnd.google-earth.kml+xml': {source:'iana',compressible:true,extensions:['kml']},
            'application/vnd.google-earth.kmz': {source:'iana',compressible:false,extensions:['kmz']},
            'application/vnd.ms-excel': {source:'iana',compressible:false,extensions:['xls','xlm','xla','xlc','xlt','xlw']},
            'application/vnd.ms-htmlhelp': {source:'iana',extensions:['chm']},
            'application/vnd.ms-powerpoint': {source:'iana',compressible:false,extensions:['ppt','pps','pot']},
            'application/vnd.oasis.opendocument.chart': {source:'iana',extensions:['odc']},
            'application/vnd.oasis.opendocument.text': {source:'iana',compressible:false,extensions:['odt']},
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': {source:'iana',compressible:false,extensions:[
              'pptx'
            ]},
            'application/vnd.openxmlformats-officedocument.presentationml.slideshow': {source:'iana',extensions:['ppsx']},
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {source:'iana',compressible:false,extensions:['xlsx']},
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {source:'iana',compressible:false,extensions:[
              'docx'
            ]},
            'application/vnd.rar': {source:'iana',extensions:['rar']},
            'application/x-7z-compressed': {source:'apache',compressible:false,extensions:['7z']},
            'application/x-apple-diskimage': {source:'apache',extensions:['dmg']},
            'application/x-bzip': {source:'apache',compressible:false,extensions:['bz']},
            'application/x-bzip2': {source:'apache',compressible:false,extensions:['bz2','boz']},
            'application/x-debian-package': {source:'apache',extensions:['deb','udeb']},
            'application/x-freearc': {source:'apache',extensions:['arc']},
            'application/x-msdownload': {source:'apache',extensions:['exe','dll','com','bat','msi']},
            'application/x-rar-compressed': {source:'apache',compressible:false,extensions:['rar']},
            'application/x-tar': {source:'apache',compressible:true,extensions:['tar']},
            'application/xml': {source:'iana',compressible:true,extensions:['xml','xsl','xsd','rng']},
            'application/zip': {source:'iana',compressible:false,extensions:['zip']},
            'audio/mp3': {compressible:false,extensions:['mp3']},
            'audio/mpeg': {source:'iana',compressible:false,extensions:['mpga','mp2','mp2a','mp3','m2a','m3a']},
            'audio/ogg': {source:'iana',compressible:false,extensions:['oga','ogg','spx','opus']},
            'audio/x-flac': {source:'apache',extensions:['flac']},
            'image/gif': {source:'iana',compressible:false,extensions:['gif']},
            'image/jpeg': {source:'iana',compressible:false,extensions:['jpeg','jpg','jpe']},
            'image/png': {source:'iana',compressible:false,extensions:['png']},
            'image/vnd.adobe.photoshop': {source:'iana',compressible:true,extensions:['psd']},
            'image/vnd.dwg': {source:'iana',extensions:['dwg']},
            'image/vnd.microsoft.icon': {source:'iana',compressible:true,extensions:['ico']},
            'image/x-icon': {source:'apache',compressible:true,extensions:['ico']},
            'text/csv': {source:'iana',compressible:true,extensions:['csv']},
            'text/html': {source:'iana',compressible:true,extensions:['html','htm','shtml']},
            'text/markdown': {source:'iana',compressible:true,extensions:['markdown','md']},
            'text/plain': {source:'iana',compressible:true,extensions:['txt','text','conf','def','list','log','in','ini']},
            'text/x-c': {source:'apache',extensions:['c','cc','cxx','cpp','h','hh','dic']},
            'text/xml': {source:'iana',compressible:true,extensions:['xml']},
            'video/mp4': {source:'iana',compressible:false,extensions:['mp4','mp4v','mpg4']},
            'video/mpeg': {source:'iana',compressible:false,extensions:['mpeg','mpg','mpe','m1v','m2v']},
            'video/quicktime': {source:'iana',compressible:false,extensions:['qt','mov']},
            'video/x-msvideo': {source:'apache',extensions:['avi']}
          };
          if(mimeDict[mimetype] && isArray(mimeDict[mimetype].extensions) && mimeDict[mimetype].extensions.length > 0) {
            return mimeDict[mimetype].extensions[0];
          }
          return 'bin';
        };

        const dataType = fileResponse.type;
        let format = guessExtension(dataType);
        if(['pdf', 'xlsx'].includes(e.format)) {
          format = e.format;
        }
        const fileName = `${filename}.${format}`;
        const binaryData = [];
        const downloadLink = document.createElement('a');
        binaryData.push(fileResponse);
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    });
    return obs;
  }
}
