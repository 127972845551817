import { trn } from './translation-wrapper.function';

export const translateDeviceMountType = (mountType: string): string => {
  switch(mountType) {
    case 'chassis':
      return trn($localize`:Nazwa typu punktu przyłączowego 'Podwozie łapowe'|@@deviceMountTypeChassis:Podwozie łapowe`);
    case 'wheels':
      return trn($localize`:Nazwa typu punktu przyłączowego 'Podwozie kołowe'|@@deviceMountTypeWheels:Podwozie kołowe`);
    case 'e400':
      return trn($localize`:Nazwa typu punktu przyłączowego 'Wysięgnik E400'|@@deviceMountTypeE400:Wysięgnik E400`);
    case 'h800':
      return trn($localize`:Nazwa typu punktu przyłączowego 'Wysięgnik H800'|@@deviceMountTypeH800:Wysięgnik H800`);
    case 'st170':
      return trn($localize`:Nazwa typu punktu przyłączowego 'Słup ST170'|@@deviceMountTypeSt170:Słup ST170`);
    case 'st250':
      return trn($localize`:Nazwa typu punktu przyłączowego 'Słup ST250'|@@deviceMountTypeSt250:Słup ST250`);
    case 'hydrobox':
      return trn($localize`:Nazwa typu punktu przyłączowego 'Hydrobox'|@@deviceMountTypeHydrobox:Hydrobox`);
    case 'pit':
    default:
      return trn($localize`:Nazwa typu punktu przyłączowego 'Studnia fundamentowa'|@@deviceMountTypePit:Studnia fundamentowa`);
  }
};
