<div class="row">
    <div class="col-3">
        <div class="menu">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="col-9">
        <div class="map"
            (leafletMapReady)="onMapReady($event)"
            [leafletOptions]="options"
            leaflet>
        </div>
    </div>
</div>
