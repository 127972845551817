import { dayjs } from '../..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { getXTickFormatAndFunction } from './get-x-tick-format-and-filter.function';

export const drawGridVertical = (
  state: D3DrawerChartState
): D3DrawerChartState => {
  const xAxisScale = state.generatedValuesStorage.get('xAxisScale');
  if (!xAxisScale) {
    return state;
  }

  let range: [number, number] = [state.viewBoxHeight - 5, state.config.legend ? 25 : 5];
  if(state.config.xAxisType !== 'invisible') {
    range = [state.viewBoxHeight - 15, state.config.legend ? 35 : 15];
  }

  let gridG = state.generatedCanvas.select(`.grid-vertical`);
  if (gridG.empty()) {
    gridG = state.generatedCanvas.append('g')
      .attr('class', `grid-vertical`);
  }

  const minmax = xAxisScale.domain();

  const tickdesc = getXTickFormatAndFunction(state, dayjs(minmax[0]).valueOf(), dayjs(minmax[1]).valueOf());

  const ticks: Date[] = xAxisScale.ticks(tickdesc.ticksFilterFn);

  if(['single','single-with-line' ].includes(state.config.yAxisType)) {
    ticks.shift();
  }
  if(['double','double-with-line' ].includes(state.config.yAxisType)) {
    ticks.shift();
    ticks.pop();
  }

  const colorLines = gridG.selectAll('line.color-grid-lines')
    .data(ticks);
  colorLines.exit().remove();
  colorLines.enter()
    .append('line')
    .attr('class', 'color-grid-lines')
    .attr('x1', d => xAxisScale(d))
    .attr('x2', d => xAxisScale(d))
    .attr('y1', d => range[0])
    .attr('y2', d => range[1])
    .attr('stroke', '#ffffff')
    .attr('stroke-width', 0.5)
    .attr('opacity', d => dayjs(d).tz().hour() === 0 && dayjs(d).tz().minute() === 0 ? 1 : 0.2);
  colorLines.merge(colorLines)
    .attr('x1', d => xAxisScale(d))
    .attr('x2', d => xAxisScale(d))
    .attr('opacity', d => dayjs(d).tz().hour() === 0 && dayjs(d).tz().minute() === 0 ? 1 : 0.2);

  return state;
};
