import { DeviceDetectorService } from 'ngx-device-detector';
import { Component, OnInit, NgZone, EventEmitter, OnDestroy } from '@angular/core';
import { SettingsService } from 'src/app/settings.service';
import { HeaderService } from 'src/app/header.service';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { AreaData } from '../area-data-resolver.service';
import {
  tileLayer,
  latLng,
  Map as LeafletMap,
  FeatureGroup,
  featureGroup,
  marker,
  divIcon,
  Marker,
  latLngBounds,
  LeafletMouseEvent,
  FitBoundsOptions,
  polygon,
  polyline,
  TileLayer,
  DivIcon,
  LayerGroup,
  LatLngBounds,
  MapOptions,
  point,
  rectangle
} from 'leaflet';
import { ResortArea, SnowCannon, Resort, ConnectionPoint, SnowThickness, MeteoStation } from 'src/app/shared/models';
import { ResortService } from 'src/app/resort.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MapService } from './map.service';
import { EndpointMapService } from 'src/app/endpoint-map.service';
import { AuthService } from 'src/app/auth.service';
import { isNumber } from '../../../../../../../common';

declare let L;

@Component({
  selector: 'ss-area-map',
  templateUrl: './area-map.component.html',
  styleUrls: ['./area-map.component.sass']
})
export class AreaMapComponent implements OnInit, OnDestroy {
  constructor(
    private mapService: MapService,
    private route: ActivatedRoute,
    private zone: NgZone,
    private router: Router,
    private settingsService: SettingsService,
    private titleService: HeaderService,
    private rs: ResortService,
    private dds: DeviceDetectorService
  ) {
    this.routesSub = router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
      this.isCannonOpenned = event['url'].match(/map\/[a-f0-9]+/);
    });

    this.layersSub = this.mapService.showLayers.subscribe((data) => {
      this.hideLayers();
      (data || []).forEach(lName => {
        this.toggleLayers(lName);
      });
    });

    this.snowThicknesLayerUpdated = this.mapService.snowThicknesLayerUpdated.subscribe((thk: SnowThickness) => {
      this.snowThicknessLayer.clearLayers();
      if (thk && thk.geojson) {
        this.snowDepthDate = thk.measured;
        this.snowThicknessLayer.addLayer(L.geoJSON(JSON.parse(thk.geojson), {
          onEachFeature: (feature, layer) => {
            const color = this.getColorBySnowDepth(feature.properties.depth || 0);
            layer.setStyle({
              color,
              fillOpacity: 0.5,
              weight: 0
            });
            layer.on('mouseover', () => {
              this.focusOnSnowDepthColor = color;
            });
            layer.on('mouseout', () => {
              this.focusOnSnowDepthColor = null;
            });
          }
        }));
      }
    });

    this.mapServiceSub = this.mapService.panMapTo.subscribe(boundsTypeOrDeviceId => {
      if(boundsTypeOrDeviceId) {
        this.boundsTypeOrDeviceId = boundsTypeOrDeviceId;
      }

      this.recenterMap();
    });

    this.layersWorker = new Worker(new URL('./area-map-layers.worker', import.meta.url), { type: 'module' });
    this.layersWorker.onmessage = async ({ data }) => {
      if (data.currentTWBMarkerLayerGeojson && this.currentTWBMarkerLayer) {
        try {
          this.currentTWBMarkerLayer.clearLayers();
          this.currentTWBMarkerLayer.addLayer(L.geoJson(data.currentTWBMarkerLayerGeojson, {
            onEachFeature: (feature, layer) => {
              const color = this.getColorByTemp(feature.properties.twb);
              layer.setStyle({
                color,
                fillOpacity: 0.5,
                weight: 0
              });
              layer.on('mouseover', () => {
                this.focusOnTwbColor = color;
              });
              layer.on('mouseout', () => {
                this.focusOnTwbColor = null;
              });
            }
          }));
        } catch (e) {
          console.error(e);
        } finally {
          await new Promise(r => setTimeout(r, 10000));
          this.updateCurrentTWBMarkerLayer();
        }
      }
    };
  }

  public get hipsoTileLayerOpacity(): number {
    return 100 * this._hipsoTileLayerOpacity;
  }
  public set hipsoTileLayerOpacity(val: number) {
    if (0.01 * val !== this._hipsoTileLayerOpacity) {
      this._hipsoTileLayerOpacity = 0.01 * val;

      this.hipsoTileLayer.setOpacity(this._hipsoTileLayerOpacity);
      this.settingsService.set('mapSettingHipsoLayerOpacity', this._hipsoTileLayerOpacity);
    }
  }

  public focusOnTwbColor: string;
  public focusOnSnowDepthColor: string;
  public snowDepthDate: string;

  private layersWorker: Worker;

  layersSub: Subscription;
  mapServiceSub: Subscription;
  routesSub: Subscription;
  snowThicknesLayerUpdated: Subscription;

  mapScrollEmmiter: EventEmitter<any> = new EventEmitter();

  area: ResortArea;
  snowCannons: SnowCannon[];
  meteoStations: MeteoStation[];

  groupsVisible = false;
  layersHidden = true;
  optionsHidden = true;
  isCannonOpenned = false;

  boundsType: string;

  canZoomIn = true;
  canZoomOut = true;

  mapHoverSelectedSnowCannon: SnowCannon;
  mapHoverSelectedConnectionPoint: ConnectionPoint;
  mapHoverSelectedMeteoStation: MeteoStation;
  mapHoverPositionX = 0;
  mapHoverPositionY = 0;
  mapHoverPositionDirection: string;

  map: LeafletMap;
  layers: any;
  layersControl: any;
  mapOptions: MapOptions;
  currentMapBearing = 0;
  boundsTypeOrDeviceId = 'area';
  mapZoomSub: Subscription = null;

  resortAreaLayer: FeatureGroup;
  connectionPointMarkerLayer: FeatureGroup;

  cannonMarkers: Marker[];
  cannonMarkerLayer: FeatureGroup;
  meteoStationMarkers: Marker[];
  meteoStationLayer: FeatureGroup;


  snowThicknessColorMap = [
    { color: '#986800', depth: 321 },
    { color: '#FDD001', depth: 320 },
    { color: '#0203FE', depth: 160 },
    { color: '#00FF00', depth: 80 },
    { color: '#FD0003', depth: 40 },
    { color: '#B10000', depth: 20 },
  ];
  currentTWBColorMap =  [
    { color: '#ff0000', temp:  5.0 },
    { color: '#ff3030', temp:  4.0 },
    { color: '#ff5555', temp:  3.0 },
    { color: '#ff8e8e', temp:  2.0 },
    { color: '#ffbbb7', temp:  1.0 },
    { color: '#ffffff', temp: -0.0 },
    { color: '#ffe3c5', temp: -1.0 },
    { color: '#ffe3c5', temp: -1.5 },
    { color: '#ffb566', temp: -2.0 },
    { color: '#ff9312', temp: -2.5 },
    { color: '#ffab00', temp: -3.0 },
    { color: '#ffd500', temp: -3.5 },
    { color: '#f2fc00', temp: -4.0 },
    { color: '#9cff00', temp: -4.5 },
    { color: '#45ff00', temp: -5.0 },
    { color: '#19da00', temp: -5.5 },
    { color: '#2eb900', temp: -6.0 },
    { color: '#2fa91a', temp: -7.0 },
    { color: '#1ca757', temp: -8.0 },
    { color: '#09a78e', temp: -9.0 },
    { color: '#00bbbb', temp: -10.0 },
    { color: '#00d9d9', temp: -11.0 },
    { color: '#00f7f9', temp: -12.0 },
    { color: '#00deff', temp: -13.0 },
    { color: '#00b8ff', temp: -14.0 },
    { color: '#0095ff', temp: -15.0 },
    { color: '#0075ff', temp: -16.0 },
    { color: '#0057ff', temp: -17.0 },
    { color: '#123dff', temp: -18.0 },
    { color: '#4a27ff', temp: -19.0 },
    { color: '#8d10ff', temp: -20.0 }
  ];
  currentTWBMarkerLayer: LayerGroup;

  pumpRoomMarkerLayer: FeatureGroup;

  waterPipelineMarkers: Marker[];
  waterPipelinePathLayer: FeatureGroup;

  snowThicknessLayer: FeatureGroup = featureGroup([]);

  cannonInterval: any;

  public resort: Resort;

  private hipsoTileLayer: any;
  private _hipsoTileLayerOpacity = 0;

  resetMapHoverSelectedTimeout: any;

  t: number = Number.POSITIVE_INFINITY;
  private updateWorkerData(connectionPointTWBs: number[][]) {
    if (this.layersWorker) {
      this.layersWorker.postMessage({connectionPointTWBs});
    }
  }

  private updateCurrentTWBMarkerLayer() {
    const dta = [];
    this.snowCannons.forEach(sn => {
      if (
        sn.connectionStatus
        && sn.conenctionPointRef
        && isNumber(sn.wetBulbTemperature)
      ) {
        dta.push([sn.conenctionPointRef.position[1], sn.conenctionPointRef.position[0], sn.wetBulbTemperature, false]);
      }
    });
    this.meteoStations.forEach(ms => {
      if (
        ms.connectionStatus
        && !ms.isPurelySynthetic
        && isNumber(ms.wetBulbTemperature)
      ) {
        dta.push([ms.position[1], ms.position[0], ms.wetBulbTemperature, true]);
      }
    });
    this.updateWorkerData(dta);
  }

  private getColorBySnowDepth(depth?: number): string {
    if (!isNumber(depth)) {
      return 'transparent';
    }
    let last = this.snowThicknessColorMap[this.snowThicknessColorMap.length - 1].color;
    for (let i = 0; i < this.snowThicknessColorMap.length - 1; i++) {
      if (this.snowThicknessColorMap[i].depth >= depth) {
        last = this.snowThicknessColorMap[i].color;

      }
    }
    return last;
  }
  private getColorByTemp(temp?: number): string {
    if (!isNumber(temp)) {
      return 'transparent';
    }
    let last = this.currentTWBColorMap[0].color;
    for (let i = 0; i < this.currentTWBColorMap.length - 1; i++) {
      if (this.currentTWBColorMap[i].temp >= temp) {
        last = this.currentTWBColorMap[i].color;
      }
    }
    return last;
  }

  private recenterMap() {
    if(!this.map) {
      return;
    }

    if (this.mapZoomSub) {
      this.mapZoomSub.unsubscribe();
      this.mapZoomSub = null;
    }

    let bounds: LatLngBounds = null;

    (this.map as any).setBearing(this.currentMapBearing);
    this.mapService.mapBearing.emit(this.currentMapBearing);
    this.cannonMarkers.forEach(cm => cm['dataIsHighlighted'] = false);

    if (this.boundsTypeOrDeviceId === 'area-wo-menus') {
      this.map.dragging.enable();
      this.boundsType = 'area-wo-menus';
      bounds = this.calcLayeredBounds();
    } else if (this.boundsTypeOrDeviceId === 'area') {
      this.map.dragging.enable();
      this.boundsType = 'area';
      bounds = this.calcLayeredBounds();
    } else {

      if(this.dds.isDesktop()) {
        this.map.dragging.enable();
      } else {
        this.map.dragging.disable();
      }


      this.boundsType = 'snow-cannon';
      const cm = this.cannonMarkers.find(cmx => cmx['dataId'] === this.boundsTypeOrDeviceId);
      if (cm) {
        cm['dataIsHighlighted'] = true;
        bounds = latLngBounds([cm.getLatLng()]);

        this.mapZoomSub = this.mapScrollEmmiter.subscribe((prop) => {
          this.mapService.monitoredMarkerPosition.emit(prop ? this.map.latLngToContainerPoint(cm.getLatLng()) : null);
        });
      }
    }

    // refactor cancel animations
    // this.map.flyToBounds(this.calcLayeredBounds(), this.calcBoundsOptionsForFitOperation());
    // if(this.boundsType === 'snow-cannon') {
    //   setTimeout(() => {
    //     this.map.flyToBounds(bounds, this.calcBoundsOptionsForFitOperation());
    //   }, 1000);
    // }
    this.map.fitBounds(bounds, this.calcBoundsOptionsForFitOperation());

    this.updateMarkers(true);
  }

  private calcLayeredBounds(): LatLngBounds {
    const markers = [ ... this.cannonMarkers.map(l => l.getLatLng()) ];
    if (this.rs.getResortAreas().length <= 1) {
      markers.push(... this.meteoStationMarkers.map(l => l.getLatLng()));
    }
    const lpts = markers.map(p => this.map.latLngToContainerPoint(p));

    const minX = Math.min(...lpts.map(p => p.x));
    const maxX = Math.max(...lpts.map(p => p.x));
    const minY = Math.min(...lpts.map(p => p.y));
    const maxY = Math.max(...lpts.map(p => p.y));

    const sw = this.map.containerPointToLatLng(point({x : maxX, y: minY}));
    const ne = this.map.containerPointToLatLng(point({x : minX, y: maxY}));
    return latLngBounds(sw, ne);
  }

  private calcBoundsOptionsForFitOperation(): FitBoundsOptions {
    switch (this.boundsType) {
      case 'area':
        return {
          paddingBottomRight: [240, 40],
          paddingTopLeft: [770, 40]
        };
      case 'snow-cannon':
        return {
          paddingBottomRight: [960, 40],
          paddingTopLeft: [40, 40]
        };
    }
    return {
      paddingBottomRight: [40, 40],
      paddingTopLeft: [40, 40]
    };
  }

  private getGeoportalTileLayer(): TileLayer {
    return this.mapService.getGeoportalTileLayer(1);
  }

  private getSatelliteTileLayer(): TileLayer {
    return this.mapService.getSatelliteTileLayer();
  }

  private getHipsoTileLayer(): TileLayer {
    this.hipsoTileLayer = this.mapService.getHipsoTileLayer(this._hipsoTileLayerOpacity);
    return this.hipsoTileLayer;
  }

  displayMapHoverSelectedMeteoStation(x: number, y: number, ms: MeteoStation) {
    if (this.boundsType === 'snow-cannon') {
      return;
    }
    this.mapHoverPositionX = x - 8;
    this.mapHoverPositionY = y - 8;

    let pos = 200 < this.mapHoverPositionY ? 'bottom' : 'top';
    pos += this.calcBoundsOptionsForFitOperation().paddingTopLeft[0] + 230 < this.mapHoverPositionX ? '-right' : '-left';
    this.mapHoverPositionDirection = pos;

    this.mapHoverSelectedMeteoStation = ms;
  }

  displaymapHoverSelectedSnowCannon(x: number, y: number, sc: SnowCannon) {
    if (this.boundsType === 'snow-cannon') {
      return;
    }
    this.mapHoverPositionX = x - 8;
    this.mapHoverPositionY = y - 8;

    let pos = 200 < this.mapHoverPositionY ? 'bottom' : 'top';
    pos += this.calcBoundsOptionsForFitOperation().paddingTopLeft[0] + 230 < this.mapHoverPositionX ? '-right' : '-left';
    this.mapHoverPositionDirection = pos;

    this.mapHoverSelectedSnowCannon = sc;
  }
  displayMapHoverSelectedConnectionPoint(x: number, y: number, cp: ConnectionPoint) {
    this.mapHoverPositionX = x - 8;
    this.mapHoverPositionY = y - 8;

    let pos = 200 < this.mapHoverPositionY ? 'bottom' : 'top';
    pos += this.calcBoundsOptionsForFitOperation().paddingTopLeft[0] + 230 < this.mapHoverPositionX ? '-right' : '-left';
    this.mapHoverPositionDirection = pos;

    this.mapHoverSelectedConnectionPoint = cp;
  }
  removeResetMapHoverSelectedTimeout() {
    clearTimeout(this.resetMapHoverSelectedTimeout);
  }
  resetMapHoverSelectedImmediately() {
    this.removeResetMapHoverSelectedTimeout();
    this.mapHoverPositionX = 0;
    this.mapHoverPositionY = 0;
    this.mapHoverPositionDirection = null;
    this.mapHoverSelectedSnowCannon = null;
    this.mapHoverSelectedMeteoStation = null;
    this.mapHoverSelectedConnectionPoint = null;
  }
  resetMapHoverSelected() {
    this.removeResetMapHoverSelectedTimeout();
    this.resetMapHoverSelectedTimeout = setTimeout(() => {
      this.resetMapHoverSelectedImmediately();
    }, 500);
  }

  onMapReady(map: LeafletMap) {
    this.map = map;
    this.map.on('zoomstart movestart', () => {
      this.resetMapHoverSelectedImmediately();
      this.mapScrollEmmiter.emit(false);
    });
    this.map.on('zoomend move zoom moveend', () => {// mat be sensible to remove zoom and move
      this.mapScrollEmmiter.emit(true);
    });
    this.map.on('zoomend', () => {
      this.updateMarkers(true);
      this.updateCanZooms();
    });

    this.updateMapBearing(this.calcMapBearing());
  }

  private calcMapBearing(): number {
    return this.area.mapBearing || this.resort.mapBearing || 0;
  }

  private updateMapBearing(newBearing?: number) {
    if(newBearing !== undefined) {
      this.currentMapBearing = newBearing;
    }

    this.recenterMap();
  }

  updateCanZooms() {
    this.canZoomIn = this.map.getMaxZoom() <= this.map.getZoom();
    this.canZoomOut = this.map.getMinZoom() >= this.map.getZoom();
  }

  zoomIn() {
    this.map.zoomIn();
  }

  zoomOut() {
    this.map.zoomOut();
  }

  setNorth() {
    this.updateMapBearing(this.currentMapBearing < 1
      ? this.calcMapBearing()
      : 0.1);
  }

  public toggleLayersMenu() {
    this.layersHidden = !this.layersHidden;
    this.settingsService.set('mapSettingLayersMenuIsVisible', !this.layersHidden);
  }

  toogleOptionsMenu() {
    this.optionsHidden = !this.optionsHidden;
  }

  ngOnDestroy() {
    this.routesSub.unsubscribe();
    this.mapServiceSub.unsubscribe();
    this.snowThicknesLayerUpdated.unsubscribe();
    this.layersSub.unsubscribe();
    clearInterval(this.cannonInterval);
  }

  hideLayers() {
    this.layersControl.forEach((el) => {
        el.isVisible = false;
    });

    this.layers = [];
  }
  toggleLayers(layerId: string) {
    const layers = [];

    this.layersControl.forEach((el) => {
      if (el.id === layerId) {
        el.isVisible = !el.isVisible;
      }
      if (el.isVisible) {
        layers.push(el.data);
      }
    });

    this.layers = layers;
  }

  createPumpRoomIcon(lbl: string): DivIcon {
    return divIcon({
      className: 'cannon-state cannon-state--pumproom ',
      html: `<div class="cannon-state__circle absolute-on-the-map">
        <div class="cannon-state__icon">
          <span class="cannon-state__label">` + lbl + '</span></div></div>'
    });
  }
  createWaterPipelineIcon(cls: string, lbl: string): DivIcon {
    return divIcon({
      className: 'cannon-state ' + cls + ' cannon-state--pumproom ',
      html: `<div class="cannon-state__circle absolute-on-the-map">
        <div class="cannon-state__icon">
          <span class="cannon-state__label">` + lbl + '</span></div></div>'
    });
  }
  createConnectionPointIcon(lbl: string): DivIcon {
    return divIcon({
      className: 'cannon-state cannon-state--squared ',
      html: `<div class="cannon-state__circle absolute-on-the-map">
        <div class="cannon-state__icon">
          <span class="cannon-state__label">` + lbl + '</span></div></div>'
    });
  }

  createMeteoStationIcon(ms: MeteoStation): DivIcon {
    return divIcon({
      className: 'cannon-state cannon-state--meteostation ' + ms.computedStatus,
      html: `<div class="cannon-state__circle absolute-on-the-map">
        <div class="cannon-state__icon">
          <svg>
            <use xlink:href="#icon-termo-wet" />
          </svg>
          <span class="cannon-state__label"><strong>` +
          ( ms.computedStatus !== 'working' ? '--' : (0.1 * Math.round(10 * ms.wetBulbTemperature) ).toFixed(1)) +
          '</strong><small>&deg;C</small></span></div></div>'
    });
  }

  createSnowCannonIcon(cls: string, lbl: string, isHighlighted: boolean = false): DivIcon {
    let size = 'cannon-state--small';

    if (this.map) {
      const zoom: number = this.map.getZoom();
      if (zoom >= 19) {
        size = 'cannon-state--big';
      } else if (zoom >= 16) {
        size = 'cannon-state--mid';
      }
    }

    return divIcon({
      className: 'cannon-state ' + size + ' ' + cls + (isHighlighted ? ' cannon-state--highlighted' : ''),
      html: `<div class="cannon-state__circle absolute-on-the-map">
        <svg class="cannon-state__locked">
          <use xlink:href="#icon-lock" />
        </svg>
        <div class="cannon-state__icon">
          <span class="cannon-state__label">` + lbl + '</span></div></div>'
    });
  }

  updateMarkers(isZoomChanged: boolean = false) {
    if (this.cannonMarkerLayer) {
      this.cannonMarkerLayer.eachLayer(l => {
        if (l['dataId']) {
          const sc: SnowCannon = this.rs.getSnowCannon(l['dataId']);
          const scMarker = (l as Marker);

          const cpPos = latLng(sc.conenctionPointRef.position);
          if (sc.gpsStatus > 0) {
            const cnPos = latLng(sc.latitude, sc.longitude);

            const dist = cnPos ? cpPos.distanceTo(cnPos) : 0;
            if (dist > 10 && dist < 20000000) {
              // zmien pozycje armatki ze studni na armatki jesli jest > 10m
              scMarker.setLatLng(cnPos);
            } else {
              // ustaw na studnie
              scMarker.setLatLng(cpPos);
            }
          } else {
            if (cpPos.distanceTo(scMarker.getLatLng()) > 10) {
              // nie ma pozycji gps i armatka jest > 10m od studni przywroc studnie
              scMarker.setLatLng(cpPos);
            }
          }

          if (isZoomChanged || sc.computedStatus !== l['dataStatus']) {
            l['dataStatus'] = sc.computedStatus;
            scMarker.setIcon(this.createSnowCannonIcon(
              sc.computedStatus,
              sc.symbol,
              l['dataIsHighlighted']
            ));
            scMarker.setZIndexOffset(sc.computedStatus !== 'connection-lost' ? 200 : 0);
          }
        }
      });
    }

    if (this.meteoStationLayer) {
      this.meteoStationLayer.eachLayer(l => {
        if (l['dataId']) {
          const ms: MeteoStation = this.rs.getMeteoStation(l['dataId']);
          const msMarker = (l as Marker);
          const status = ms.computeMapStatus();

          if (isZoomChanged || status !== l['dataStatus']) {
            l['dataStatus'] = status;
            msMarker.setIcon(this.createMeteoStationIcon(ms));
            msMarker.setZIndexOffset(ms.computedStatus !== 'connection-lost' ? 100 : 0);
          }
        }
      });
    }
  }

  private splitOverlappingMarkers(arr: Marker[], xNudge: number, yNudge: number) {
    const groupper: Map<string, Marker[]> = new Map();
    arr.forEach(m => {
      const hash = m.getLatLng().toString();
      if (groupper.has(hash)) {
        groupper.get(hash).push(m);
      } else {
        groupper.set(hash, [m]);
      }
    });
    groupper.forEach(v => {
      if (v.length > 1) {
        v.forEach((m, i) => {
          const ll = m.getLatLng();
          m.setLatLng(latLng(ll.lat + (i - 0.5) * xNudge, ll.lng + (i - 0.5) * yNudge));
        });
      }
    });
  }

  ngOnInit() {
    this.layersHidden = !this.settingsService.get('mapSettingLayersMenuIsVisible');

    this._hipsoTileLayerOpacity = this.settingsService.get('mapSettingHipsoLayerOpacity');

    this.route.data
      .subscribe((data: { areaData: AreaData }) => {
        this.resort = data.areaData.resort;
        this.area = data.areaData.area;
        this.snowCannons = data.areaData.snowCannons;
        this.meteoStations = data.areaData.meteoStations;

        this.mapOptions = {
          layers: [
            this.getSatelliteTileLayer(),
            // this.getGeoportalTileLayer(),
            this.getHipsoTileLayer()
          ],
          attributionControl: false,
          fadeAnimation: true,
          zoomControl: false,
          zoomSnap: 0.1,
          zoom: this.resort.mapZoom,
          center: latLng(this.resort.mapCenter),
          // maxBounds: this.resort.mapBoundaries,
          // maxBoundsViscosity: .5
        };
        (this.mapOptions as any).rotate = true;


        this.resortAreaLayer = featureGroup([polygon(this.area.mapBoundaries, {
          color: '#ffffff',
          fillOpacity: 0.75
        })]);
        this.resortAreaLayer.setZIndex(0);

        this.cannonMarkers = [];
        this.snowCannons.forEach((sc) => {
          const cnmarker = marker(latLng(sc.conenctionPointRef.position), {
            riseOnHover: true,
            icon: this.createSnowCannonIcon(
              sc.computedStatus,
              sc.symbol
            ),
          });
          cnmarker.setZIndexOffset(sc.computedStatus !== 'connection-lost' ? 200 : 0);
          cnmarker['dataId'] = sc.id;
          cnmarker['dataStatus'] = sc.computedStatus;

          cnmarker.on('mouseover', (ev: LeafletMouseEvent) => {
            this.displaymapHoverSelectedSnowCannon(ev.containerPoint.x, ev.containerPoint.y, sc);

            ev.target._icon.classList.add('cannon-state--highlighted');
          });
          cnmarker.on('mouseout', (ev: LeafletMouseEvent) => {
            this.resetMapHoverSelectedImmediately();

            ev.target._icon.classList.remove('cannon-state--highlighted');
          });
          cnmarker.on('click', () => {
            this.zone.run(() => {
              this.router.navigate(['/application/resort/area', this.area.id, 'map', sc.id]);
            });
          });
          this.cannonMarkers.push(cnmarker);
        });
        this.splitOverlappingMarkers(this.cannonMarkers, 0, 0.0001);

        this.meteoStationMarkers = [];
        this.meteoStations.forEach((ms) => {
          if (ms.isPurelySynthetic) {
            return;
          }
          const msMark = marker(latLng(ms.position), {
            riseOnHover: true,
            icon: this.createMeteoStationIcon(ms)
          });
          msMark['dataId'] = ms.id;
          msMark['dataStatus'] = ms.computeMapStatus();
          msMark.setZIndexOffset(ms.computedStatus !== 'connection-lost' ? 100 : 0);
          msMark.on('mouseover', (ev: LeafletMouseEvent) => {
            this.displayMapHoverSelectedMeteoStation(ev.containerPoint.x, ev.containerPoint.y, ms);

            ev.target._icon.classList.add('cannon-state--highlighted');
          });
          msMark.on('mouseout', (ev: LeafletMouseEvent) => {
            this.resetMapHoverSelected();

            ev.target._icon.classList.remove('cannon-state--highlighted');
          });

          this.meteoStationMarkers.push(msMark);
        });

        const connectionPointMarkers = [];
        data.areaData.area.conenctionPointRefs.forEach((el) => {
          const cpMark = marker(latLng(el.position), {
            riseOnHover: true,
            icon: this.createConnectionPointIcon(el.symbol)
          });

          cpMark.on('mouseover', (ev: LeafletMouseEvent) => {
            this.displayMapHoverSelectedConnectionPoint(ev.containerPoint.x, ev.containerPoint.y, el);

            ev.target._icon.classList.add('cannon-state--highlighted');
          });
          cpMark.on('mouseout', (ev: LeafletMouseEvent) => {
            this.resetMapHoverSelected();

            ev.target._icon.classList.remove('cannon-state--highlighted');
          });
          connectionPointMarkers.push(cpMark);
        });
        this.splitOverlappingMarkers(connectionPointMarkers, 0.0001, 0);

        const pumpRoomMarkers = [];
        const waterPipelinePaths = [];
        this.waterPipelineMarkers = [];
        data.areaData.pumpRooms.forEach(pr => {
          const prMarker = marker(latLng(pr.position), {
            riseOnHover: true,
            icon: this.createPumpRoomIcon(pr.symbol)
          });

          prMarker.on('click', () => {
            this.zone.run(() => {
              this.router.navigate(['/application/pump-rooms/detail/', pr.id]);
            });
          });
          pumpRoomMarkers.push(prMarker);

          pr.waterPipelineRefs.forEach(pi => {
            waterPipelinePaths.push(polyline(pi.points, {
              color: '#333'
            }));

            if (pi.position) {
              const piMarker = marker(latLng(pi.position), {
                riseOnHover: true,
                icon: this.createWaterPipelineIcon(pi.computedStatus, pi.symbol)
              });

              piMarker.on('click', () => {
                this.zone.run(() => {
                  this.router.navigate(['/application/pump-rooms/detail/', pr.id]);
                });
              });
              this.waterPipelineMarkers.push(piMarker);
            }
          });
        });

        this.connectionPointMarkerLayer = featureGroup(connectionPointMarkers);
        this.connectionPointMarkerLayer.setZIndex(3);

        this.cannonMarkerLayer = featureGroup(this.cannonMarkers);
        this.cannonMarkerLayer.setZIndex(5);

        this.meteoStationLayer = featureGroup(this.meteoStationMarkers);
        this.meteoStationLayer.setZIndex(4);

        this.currentTWBMarkerLayer = featureGroup([]);
        this.currentTWBMarkerLayer.setZIndex(4);

        this.updateCurrentTWBMarkerLayer();

        this.pumpRoomMarkerLayer = featureGroup(pumpRoomMarkers);
        this.pumpRoomMarkerLayer.setZIndex(5);
        this.waterPipelinePathLayer = featureGroup([...waterPipelinePaths, ... this.waterPipelineMarkers]);
        this.waterPipelinePathLayer.setZIndex(2);

        this.layersControl = [
          {
            id: 'resortArea',
            label: $localize`:@@layerControlResortAreaLayerLabel:Obszar stoku`,
            data: this.resortAreaLayer,
            isVisible: false
          },
          {
            id: 'snowCannons',
            label: $localize`:@@layerControlSnowCannonsLayerLabel:Armatki i lance`,
            data: this.cannonMarkerLayer,
            isVisible: false
          },
          {
            id: 'meteoStations',
            label: $localize`:@@layerControlMeteoStations:Stacje meteo`,
            data: this.meteoStationLayer,
            isVisible: true
          },
          {
            id: 'pumpRooms',
            label: $localize`:@@layerControlPumpRoomsLayerLabel:Pompownie`,
            data: this.pumpRoomMarkerLayer,
            isVisible: false
          },
          {
            id: 'waterPipelines',
            label: $localize`:@@layerControlWaterPipelineLayerLabel:Rurociągi`,
            data: this.waterPipelinePathLayer,
            isVisible: false
          },
          {
            id: 'connectionPoints',
            label: $localize`:@@layerControlConnectionPointsLayerLabel:Studnie fundamentowe`,
            data: this.connectionPointMarkerLayer,
            isVisible: false
          },
          {
            id: 'currentTWB',
            label: $localize`:@@layerControlCurrentTWBLayerLabel:Aktualne TWB`,
            data: this.currentTWBMarkerLayer,
            isVisible: false
          }
        ];

        if (data.areaData.snowThickness && data.areaData.snowThickness.latest) {
          this.snowThicknessLayer.setZIndex(2);
          this.layersControl.push({
            id: 'snowThickness',
            label: $localize`:@@layerControlSnowThicknessLayerLabel:Grubość pokrywy śnieżnej`,
            data: this.snowThicknessLayer,
            isVisible: false
          });
        }

        this.updateMapBearing(this.calcMapBearing());
      });

    this.cannonInterval = setInterval(() => {
      this.updateMarkers();
    }, 500);

  }

}
