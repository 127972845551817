import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DeviceSystemType, isArray } from '../../../../../common';
import { ReportingClientService } from '../../http-clients/reporting.client.service';
import { ResortService } from '../../resort.service';
import { AbstractDevice } from '../../shared/models/abstract-device.model';
import {
  ReportDefinition,
  ReportExport,
  ReportExportFormat,
  ReportResult
} from '../../../../../common';
import {
  blankDefinition,
  // snowCannonAndMeteoStationDefinition,
  snowCannonDefinition
} from '../../../../../common/models/reporting/definitions';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(
    private rs: ResortService,
    private rcs: ReportingClientService
  ) { }

  public getStaticDefinitions(): ReportDefinition[] {
    const staticDefinitions = [
      blankDefinition,
      snowCannonDefinition,
      // snowCannonAndMeteoStationDefinition
    ].filter(rd => {
      let hasAllRequiredDeviceTypesToGetDataFrom = true;
      rd.parts.forEach(p => {
        p.series.forEach(s => {
          hasAllRequiredDeviceTypesToGetDataFrom = hasAllRequiredDeviceTypesToGetDataFrom
            && this.getDevidesBySubjectType(s.subjectType).length !== 0;
        });
      });
      return hasAllRequiredDeviceTypesToGetDataFrom;
    });


    return staticDefinitions;
  }

  public getDevidesBySubjectType(subjectType: DeviceSystemType): AbstractDevice[] {
    switch (subjectType) {
      case 'Resort':
        return [this.rs.getResort()];
      case 'ResortArea':
        return this.rs.getResortAreas();
      case 'WaterReservoir':
        return this.rs.getWaterReservoirs();
      case 'WaterPipeline':
        return this.rs.getWaterPipelines();
      case 'PumpRoom':
        return this.rs.getPumpRooms();
      case 'ElectricalLine':
        return this.rs.getElectricalLines();
      case 'PowerSource':
        return this.rs.getPowerSources();
      case 'SnowCannon':
        return this.rs.getSnowCannons();
      case 'ConnectionPoint':
        return this.rs.getConnectionPoints();
      case 'MeteoStation':
        return this.rs.getMeteoStations();
    }
    return [];
  }

  public updateCustomDefinitionsOrder(customDefinitions: ReportDefinition[]): Observable<ReportDefinition[]> {
    const req = customDefinitions.map((cd, i) => ({ id: cd.id, priority: 10 * i }));
    return this.rcs.updateCustomDefinitionsOrder(req).pipe(
      catchError(() => of(null)),
      switchMap(() => this.getDefinitions() )
    );
  }

  public getDefinition(id: string): Observable<ReportDefinition> {
    const stat = this.getStaticDefinitions();
    const idx = stat.findIndex(sd => sd.id === id);
    let resp: Observable<ReportDefinition> = this.rcs.getReportDefinition(id);;
    if(idx >= 0) {
      resp = of(stat[idx]);
    }

    return resp.pipe(map(def => this.decorateWithDevicesFromParts<ReportDefinition>(def)));
  }

  public getDefinitions(): Observable<ReportDefinition[]> {
    return this.rcs.getReportDefinitions().pipe(map(v => {
      if(!Array.isArray(v)) {
        v = [];
      }
      v.push(...this.getStaticDefinitions().filter(sd => !sd.isHidden));
      v.sort((a, b) => (a.priority || 0) - (b.priority || 0));
      return v.map(def => this.decorateWithDevicesFromParts<ReportDefinition>(def));
    }));
  }

  public fetch(request: ReportResult): Observable<ReportResult> {
    return this.rcs.fetch(request).pipe(map(def => this.decorateWithDevicesFromParts<ReportResult>(def)));
  }

  private decorateWithDevicesFromParts<T extends ReportDefinition>(def: T): T {
    def.decorateWithDevicesFromParts(this.rs.getByIds()); // refact
    return def;
  }

}
