import { Component, Input } from '@angular/core';
import { Device, translateDeviceMountType } from '../../../../../../common';
import { ConnectionPoint, SnowCannon } from '../../models';

@Component({
  selector: 'ss-translated-device-mount-type',
  template: '{{ mountType }}',
  styleUrls: []
})
export class TranslatedDeviceMountTypeComponent {

  public mountType: string;

  private _device: Device;
  @Input()
  public get device(): Device {
    return this._device;
  }
  public set device(v: Device) {
    this._device = v;
    if(v instanceof SnowCannon || v instanceof ConnectionPoint) {
      this.mountType = translateDeviceMountType(v ? `${v.mountType}`.toLocaleLowerCase() : undefined);
    }
  }


}
