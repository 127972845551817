import { D3 } from '..';
import { D3DrawerRoot } from '../d3-drawer-root.type';

export const selectRoot = (
  hostElement: HTMLElement,
  width?: number,
  height?: number
): D3DrawerRoot => {
  const host = D3.select(hostElement);
  let root = host.select('svg.chart-drawer') as D3DrawerRoot;

  if(root.empty()) {
    root = host.append('svg')
      .attr('class', 'chart-drawer')
      .attr('width', '100%');
  }

  if(width !== undefined && height !== undefined) {
    root.attr('viewBox', '0 0 ' + width + ' ' + height);
  }

  return root;
};
