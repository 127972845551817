import { Injectable } from '@angular/core';
import { ResortService } from 'src/app/resort.service';
import { DevicesClientService, ScheduleClientService, ForecastClientService } from 'src/app/http-clients';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Subject, Observable, forkJoin } from 'rxjs';
import { ResortArea, MeteoStation, Schedule, Forecast } from 'src/app/shared/models';

export class ScheduleListData {
  areas: ResortArea[];
  meteoStations: MeteoStation[];
  schedules: Schedule[];
  forecast: Forecast;
  devIds: string[];
}

@Injectable({
  providedIn: 'root'
})
export class ListDataResolverService implements Resolve<ScheduleListData> {

  constructor(
    private rs: ResortService,
    private fc: ForecastClientService,
    private dd: DevicesClientService,
    private sc: ScheduleClientService
  ) {}

  getData(): Observable<ScheduleListData> | Observable<never> {
    const sub: Subject<ScheduleListData> = new Subject();

    const areas = this.rs.getResortAreas();
    const meteoStations = this.rs.getMeteoStations();

    if (areas) {
      const ids: string[] = [].concat(
        areas.map(a => a.id),
        meteoStations.map(ms => ms.id)
      );

      forkJoin([
        this.sc.getAll(),
        this.fc.getAll()
      ])
      .subscribe(data => {
        sub.next({
          areas,
          meteoStations,
          schedules: data[0],
          forecast: data[1],
          devIds: ids
        });
        sub.complete();
      }, (el) => {
        sub.error('can not fetch schedules and forecast ' + el);
      });
    } else {
      sub.error('can not fetch area and forecast');
    }

    return sub.asObservable();
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ScheduleListData> | Observable<never> {
    return this.getData();
  }
}
