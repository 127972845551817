import {
  dayjs,
  DAYJS,
  isArray,
  isNullOrUndefined,
  isObject,
  TimedChartConfig,
  TimedChartConfigSeries,
  TimedChartData,
  translateDeviceTypeAndSymbol,
  translateReportFieldName
} from '../../..';
import { ReportPartView } from '../part-view';
import { ReportResultView } from '../result-view';
import { subjectFieldMapping } from '../series-subject-field-to-type-mapping';

export class ReportChartPartView extends ReportPartView {
  public calculate(resultView: ReportResultView, from?: DAYJS, to?: DAYJS) {
    this.calculateAutoLabel(resultView);
    from = !!from ? from : resultView.displayFrom;
    to = !!to ? to : resultView.displayTo;

    const startT = from.startOf('h').unix();
    const endT = to.endOf('h').add(2, 's').startOf('h').unix();

    const rawData = this.getDataByIds(resultView);
    const dataParams = { labels: [] };
    const config: TimedChartConfig = {
      ... new TimedChartConfig(),
      xAxisType: 'up-and-bottom',
      yAxisType: 'double-with-line',
      legend: true,
      grid: true,
      series: [],
    };

    const allLabels = [];
    const attachDeviceNameToTheLabel = [...new Set([... this.part.series.map(s => s.subjectId)])].length !== 1;

    const hasAnySeriesTemperature = this.part.series.reduce((prev, seriesDefinition) =>
      prev || subjectFieldMapping[seriesDefinition.subjectType].fields[seriesDefinition.subjectField].unitType === 'temperature'
    , false);
    this.part.series.forEach((seriesDefinition, seriesIndex) => {
      const id = seriesDefinition.subjectId;
      const symbol = !!resultView.devicesInParts[id] ? translateDeviceTypeAndSymbol(resultView.devicesInParts[id]) : '?';
      const type = seriesDefinition.subjectType;
      const field = seriesDefinition.subjectField;

      if(!isObject(rawData[id]) || !isArray(rawData[id]['labels']) || !isArray(rawData[id][field])) {
        return;
      }

      const seriesFieldDescription = subjectFieldMapping[type].fields[field];
      const dataLabelName = `labels-${id}`;
      const dataFieldName = `${field}-${id}`;
      const dataLabelValues = [ ... rawData[id]['labels'] ];
      const dataFieldValues = [ ... rawData[id][field] ];

      const chartSeries: TimedChartConfigSeries = {
        ... new TimedChartConfigSeries(),
        seriesType: seriesFieldDescription.chartSeriesType === 'bar' ? 'bar' : 'line',
        unit: seriesFieldDescription.unitType,
        storedUnitOverride: seriesFieldDescription.storedUnitOverride,
        labelFieldName: dataLabelName,
        valueFieldName: dataFieldName,
        grid: seriesFieldDescription.unitType === 'temperature'
          || (!hasAnySeriesTemperature && seriesIndex === 0),
        fixedMin: seriesFieldDescription.unitType === 'production' ? 0 : undefined,
        seriesName: translateReportFieldName(field) + (attachDeviceNameToTheLabel ? ' (' + symbol + ')': '')
      };

      config.series.push(chartSeries);

      const matchedLabels = [];
      const matchedData = [];

      dataLabelValues.forEach((t, i) => {
        const dt0 = dayjs(1000 * t);
        const v0 = dataFieldValues[i];
        if(i + 1 < dataLabelValues.length) {
          const dt1 = dayjs(1000 * dataLabelValues[i + 1]);
          const v1 = dataFieldValues[i + 1];

          if(from.isBetween(dt0, dt1, null, '[]')) {
            matchedLabels.push(startT);
            matchedData.push( isNullOrUndefined(v0) || isNaN(v0) || isNullOrUndefined(v1) || isNaN(v1)
             ? undefined
             : 0.5 * (v0 + v1)
            );
          }
        }

        if(dt0.isBetween(from, to)) {
          matchedLabels.push(t);
          matchedData.push(v0);
        }

        if(i + 1 < dataLabelValues.length) {
          const dt1 = dayjs(1000 * dataLabelValues[i + 1]);
          const v1 = dataFieldValues[i + 1];

          if(to.isBetween(dt0, dt1, null, '[]')) {
            matchedLabels.push(endT);
            matchedData.push( isNullOrUndefined(v0) || isNaN(v0) || isNullOrUndefined(v1) || isNaN(v1)
             ? undefined
             : 0.5 * (v0 + v1)
            );
          }
        }
      });

      dataParams[dataLabelName] = matchedLabels;
      dataParams[dataFieldName] = matchedData;

      allLabels.push(... matchedLabels, startT, endT);
    });
    dataParams.labels = [ ...new Set(allLabels)].sort();

    this.chartConfig = config;
    this.chartData = new TimedChartData(dataParams);
  }
}
