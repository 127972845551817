import { Injectable, EventEmitter } from '@angular/core';
import {
  Map as LeafletMap,
  FeatureGroup,
  LatLngBoundsExpression,
  ImageOverlay,
  latLng,
  MapOptions,
  TileLayer,
  imageOverlay,
  tileLayer,
  control
} from 'leaflet';
import { EndpointMapService } from 'src/app/endpoint-map.service';
import { AuthService } from 'src/app/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EditorMapControllerService {

  constructor(
    private authService: AuthService,
    private endpointMapService: EndpointMapService
  ) { }

  satTileLayer: TileLayer;
  hipsoTileLayer: TileLayer;

  private _map: LeafletMap;
  public get map(): LeafletMap {
    if (!this._map) {
      throw new Error('map is not ready');
    }
    return this._map;
  }
  public set map(map: LeafletMap) {
    this._map = map;
  }

  public workingLayer: FeatureGroup;

  public updateWorkingLayer(wl: FeatureGroup) {
    this.resetWorkingLayer();
    this.map.addLayer(wl);
    this.workingLayer = wl;
  }

  public resetWorkingLayer() {
    if (this.workingLayer) {
      this.map.removeLayer(this.workingLayer);
    }
    this.workingLayer = null;
  }

  public createProjectImage(bounds: LatLngBoundsExpression): ImageOverlay {
    return imageOverlay(this.endpointMapService.tilesProject + '?bearer=' + this.authService.getApiKey(), bounds, { opacity: 0.8 });
  }

  private getSatelliteTileLayer(): TileLayer {
    const layerOptions = {
      minZoom: 14,
      maxZoom: 18,
      edgeBufferTiles: 2,
      maxNativeZoom: 17,
      attribution: 'Custom cached map layers from Google Maps or ESRI'
    };
    return tileLayer(this.endpointMapService.satelliteTiles + '?bearer=' + this.authService.getApiKey(), layerOptions);
  }

  private getHipsoTileLayer(): TileLayer {
    const layerOptions = {
      minZoom: 14,
      maxZoom: 18,
      edgeBufferTiles: 2,
      maxNativeZoom: 18,
      attribution: 'Custom cached map layers from thunderforest.com'
    };
    return tileLayer(this.endpointMapService.hipsoTiles + '?bearer=' + this.authService.getApiKey(), layerOptions);
  }

  public getMapOptions(zoom: number, mapCenter: [number, number]): MapOptions {
    this.satTileLayer = this.getSatelliteTileLayer();
    this.hipsoTileLayer = this.getHipsoTileLayer();

    return {
      layers: [
        this.satTileLayer,
        this.hipsoTileLayer
      ],
      attributionControl: false,
      fadeAnimation: true,
      zoomControl: true,
      zoom,
      center: latLng(mapCenter)
    };
  }

  public updateControls() {
    control.layers({
      hipsometric: this.hipsoTileLayer,
      satellite: this.satTileLayer
    }).addTo(this.map);
  }
}
