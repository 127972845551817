import {
  Component,
  ContentChildren,
  QueryList,
  Input,
  forwardRef,
  OnInit,
  AfterViewInit
} from '@angular/core';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'ss-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.sass']
})

export class TabsComponent implements AfterViewInit {

  @Input()
  public selectedTab = '';

  private _tabs: QueryList<TabComponent>;
  @ContentChildren(TabComponent, {
    descendants: true
  })
  set tabs(val: QueryList<TabComponent>) {
    this._tabs = val;
    this.selectNamedTabOrFirstTab(this.selectedTab);
  }
  get tabs(): QueryList<TabComponent> {
    return this._tabs;
  }

  private overflowTimeout;
  public showOverflow = false;

  public selectNamedTabOrFirstTab(tab: string) {
    if (!this._tabs) {
      return;
    }

    this.showOverflow = false;
    setTimeout(() => {
      const visibleTabs = this._tabs.filter(t => !t.hidden);
      if (visibleTabs.length > 0 && visibleTabs.findIndex(t => t.tabId === tab) < 0) {
        tab = visibleTabs[0].tabId;
      }
      this._tabs.forEach(t => {
        t.active = t.tabId === tab;
      });
      this.selectedTab = tab;

      clearTimeout(this.overflowTimeout);
      this.overflowTimeout = setTimeout(() => {
        this.showOverflow = true;
      }, 400);
    });
  }

  ngAfterViewInit() {
    this.selectNamedTabOrFirstTab(this.selectedTab);
  }
}

