import { Injectable } from '@angular/core';
import { EndpointMapService } from '../endpoint-map.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentationFile, DocumentationFiles } from '../shared/models';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class DocumentationFilesClientService {

  constructor(private e: EndpointMapService, private http: HttpClient) {}

  public getAll(): Observable<DocumentationFile[]> {
    return this.http.get<DocumentationFiles>(this.e.documentationFiles, httpOptions)
        .pipe(map((data) => data.files ? data.files.map(f =>
            Object.assign(new DocumentationFile(), f)
        ) : []));
  }
}
