import { User } from './user.model';

export class UserNotes {
    public notes: UserNote[] = [];
}

export class UserNote {
    id: number;
    user: User;
    content: string;
    context?: string;
    replaces?: UserNote;
    created: string;
    deleted?: string;
    replaced?: string;
    isPrivate: boolean;

    isHistoryShown = false;

    static create(data: any) {
        const resp = Object.assign(new UserNote(), data);
        resp.id = parseInt(resp.id, 10) || 0;
        resp.user = Object.assign(new User(), resp.user);

        if (resp.replaces) {
            resp.replaces = UserNote.create(resp.replaces);
        }

        return resp;
    }

    get replacementArray(): UserNote[] {
        const ret = [];
        let replacement = this.replaces;
        while (replacement) {
            ret.push(replacement);
            replacement = replacement.replaces;
        }
        return ret;
    }
}
