import { Component, Input, OnInit } from '@angular/core';
import { translateReportFieldName } from '../../../../../../common';

@Component({
  selector: 'ss-translated-report-field-name',
  template: '{{ name }}',
  styleUrls: []
})
export class TranslatedReportFieldNameComponent {

  public name: string;

  private _fieldName: string;
  @Input()
  public get fieldName(): string {
    return this._fieldName;
  }
  public set fieldName(v: string) {
    this._fieldName = v;
    this.name = translateReportFieldName(v);
  }

}
