import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from 'src/app/header.service';
import { MeteoStation, Schedule, ScheduleDay, ResortArea, Forecast } from 'src/app/shared/models';
import { ResortService } from 'src/app/resort.service';
import { DevicesClientService, ScheduleClientService } from 'src/app/http-clients';
import { TimeRangePopupRequest } from '../time-range-popup/time-range-popup.component';
import { ScheduleListData } from '../list-data-resolver.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ss-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class WeatherAndSchedulesListComponent implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private schedulesService: ScheduleClientService
  ) {
  }

  currentPopupRequest: TimeRangePopupRequest;

  isDropdownExpanded = false;
  isStationsDropdownExpanded = false;

  meteoStations: MeteoStation[] = [];
  schedules: Schedule[] = [];
  areas: ResortArea[] = [];
  forecast: Forecast;

  selectedSchedule: Schedule;

  selectSchedule(s: Schedule) {
    this.selectedSchedule = this.selectedSchedule === s ? null : s;
  }

  saveSchedule(schedule: Schedule) {
    this.schedulesService.save(schedule).subscribe(() => {});
  }

  toggleDropdown() {
    this.isDropdownExpanded = !this.isDropdownExpanded;
  }

  ngOnInit() {
    this.route.data
    .subscribe((data: { areaData: ScheduleListData }) => {
      this.meteoStations = data.areaData.meteoStations;
      this.schedules = data.areaData.schedules;
      this.areas = data.areaData.areas;
      this.forecast = data.areaData.forecast;
    });
  }

  ngOnDestroy() { }
}
