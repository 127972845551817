import { Component, OnInit, Input, OnDestroy, HostListener } from '@angular/core';
import { PumpRoom } from 'src/app/shared/models';
import {
  Component as MachineRoomComponent,
  FullSchema,
  Drawable,
  DrawableConnector,
  DrawableComponent,
  DrawableLine,
  DrawableText
} from 'src/app/shared/models/pump-room';
import { SettingsService } from 'src/app/settings.service';
import { isArray } from '../../../../../../../common';

@Component({
  selector: 'ss-full-schema',
  templateUrl: './full-schema.component.html',
  styleUrls: ['./full-schema.component.sass']
})
export class FullSchemaComponent implements OnInit, OnDestroy {

  constructor(private settingsService: SettingsService) {
    this.isDark = this.settingsService.get('machineRoomFullSchemaDarkMode');
  }

  get availableSymbols(): MachineRoomComponent[] {
    const usedSymbols = this.fullSchema.components.map(f => f.symbol);
    const symbols = this.pumpRoom.configuration.components.filter(a => !usedSymbols.includes(a.symbol));

    symbols.sort((a, b) => {
      const aNo = parseInt(a.symbol.replace(/[^0-9]/ig, ''), 10) || 0;
      const bNo = parseInt(b.symbol.replace(/[^0-9]/ig, ''), 10) || 0;

      if (aNo > bNo) {
        return 1;
      } else if (bNo > aNo) {
        return -1;
      }
      return a.type.localeCompare(b.type);
    });
    return symbols;
  }

  isDark = false;

  @Input()
  public pumpRoom: PumpRoom;

  @Input()
  public allowEdit = false;

  public fullSchema: FullSchema;

  private _selectedComponent: Drawable;;
  public get selectedComponent(): Drawable {
    return this._selectedComponent;
  }
  public set selectedComponent(v: Drawable) {
    this._selectedComponent = v;

    this.selectedDrawableComponent = null;
    this.selectedDrawableConnector = null;
    this.selectedDrawableLine = null;
    this.selectedDrawableText = null;

    if(v instanceof DrawableComponent) {
      this.selectedDrawableComponent = v;
    } else if(v instanceof DrawableLine) {
      this.selectedDrawableLine = v;
    } else if(v instanceof DrawableConnector) {
      this.selectedDrawableConnector = v;
    } else if(v instanceof DrawableText) {
      this.selectedDrawableText = v;
    }
  }

  public selectedDrawableComponent: DrawableComponent<any>;
  public selectedDrawableLine: DrawableLine;
  public selectedDrawableConnector: DrawableConnector;
  public selectedDrawableText: DrawableText;

  public w = 1860;
  public h = 120;

  componentToAdd: Drawable;
  adding = false;

  public selectX = 0;
  public selectY = 0;
  public selectW = 0;
  public selectH = 0;
  public selecting = false;
  public selectFromX: number;
  public selectFromY: number;
  public selectToX: number;
  public selectToY: number;
  public selected = false;
  public movingSelected = false;
  public selectMoveX: number;
  public selectMoveY: number;
  public selectStartMoveX: number;
  public selectStartMoveY: number;
  public selectedDrawables: {copy: {from: any[]; to?: any[]}; real: Drawable}[] = null;
  switchContrast() {
    this.isDark = !this.isDark;
    this.settingsService.set('machineRoomFullSchemaDarkMode', this.isDark);
  }
  addLine() {
    if (!this.allowEdit) {
 return;
}

    this.adding = true;
    this.componentToAdd = new DrawableLine();
  }
  addText() {
    if (!this.allowEdit) {
 return;
}

    this.adding = true;
    this.selecting = false;
    this.selected = null;
    this.selectedDrawables = null;
    this.componentToAdd = new DrawableText();
    this.componentToAdd['label'] = 'new text';
  }
  addConnection() {
    if (!this.allowEdit) {
 return;
}

    this.adding = true;
    this.selecting = false;
    this.selected = null;
    this.selectedDrawables = null;
    this.componentToAdd = new DrawableConnector();
  }

  addComponent() {
    if (!this.allowEdit) {
 return;
}

    this.adding = true;
    this.selecting = false;
    this.selected = null;
    this.selectedDrawables = null;
    let sym = null;
    if (this.availableSymbols.length > 0) {
      sym = this.availableSymbols[0];
    }
    this.componentToAdd = new DrawableComponent(sym);
  }

  resetAdding() {
    this.adding = false;
    if (this.componentToAdd) {
      const accedIdx = this.fullSchema.all.indexOf(this.componentToAdd);
      if (accedIdx >= 0) {
        this.fullSchema.all.splice(accedIdx, 1);
      }
      this.componentToAdd = null;
    }
  }

  onClick(ev: MouseEvent) {
    if (!this.allowEdit) {
 return;
}

    if (this.adding && this.componentToAdd) {
      const crds: [number, number] = [
        10 * Math.round(0.1 * ev.offsetX),
        10 * Math.round(0.1 * ev.offsetY)
      ];
      switch (this.componentToAdd.type) {
        case 'component':
          this.componentToAdd.from = crds;
          this.addPreparedComponent();
          this.componentToAdd = null;
          this.resetAdding();
          break;
        case 'text':
          this.componentToAdd.from = crds;
          this.addPreparedComponent();
          this.componentToAdd = null;
          this.resetAdding();
          break;
        case 'line':
          if (this.componentToAdd.from) {
            this.componentToAdd['to'] = crds;
            this.componentToAdd = null;
            this.resetAdding();
            this.clearSelection();
          } else {
            this.componentToAdd.from = crds;
            this.addPreparedComponent();
          }
          break;
        case 'connector':
          let elSymbol = null;
          let elRef = null;
          if (ev.target['dataset'] && ev.target['dataset']['symbol']) {
            elSymbol = ev.target['dataset']['symbol'];
            elRef = this.fullSchema.components.find(e => e.symbol === elSymbol);
          }

          if (this.componentToAdd.from || this.componentToAdd['fromComponent']) {
            if (elSymbol && elRef) {
              this.componentToAdd['toComponent'] = elSymbol;
              this.componentToAdd['toComponentRef'] = elRef;
            } else {
              this.componentToAdd['to'] = crds;
            }
            this.componentToAdd = null;
            this.resetAdding();
            this.clearSelection();
          } else {
            if (elSymbol && elRef) {
              this.componentToAdd['fromComponent'] = elSymbol;
              this.componentToAdd['fromComponentRef'] = elRef;
            } else {
              this.componentToAdd.from = crds;
            }
            this.addPreparedComponent();
          }
          break;
        default:
          this.resetAdding();
      }
    }
  }

  selectionOnMouseDown(ev: MouseEvent) {
    if (
      !this.allowEdit
      || !this.selected
    ) {
 return;
}

    ev.preventDefault();
    ev.stopPropagation();

    this.movingSelected = true;

    this.selectMoveX = ev.offsetX;
    this.selectMoveY = ev.offsetY;
    this.selectStartMoveX = this.selectX;
    this.selectStartMoveY = this.selectY;
  }
  selectionOnMouseMove(ev: MouseEvent) {
    if (
      !this.allowEdit
      || !this.selected
    ) {
 return;
}

    ev.preventDefault();
    ev.stopPropagation();

    if (this.movingSelected) {
      const deltaX = ev.offsetX - this.selectMoveX;
      const deltaY = ev.offsetY - this.selectMoveY;

      const roundDeltaX = 10 * Math.round(0.1 * deltaX);
      const roundDeltaY = 10 * Math.round(0.1 * deltaY);

      this.selectX = this.selectStartMoveX + deltaX;
      this.selectY = this.selectStartMoveY + deltaY;

      this.selectedDrawables.forEach(tpl => {
        if (tpl.real.from && tpl.copy.from) {
          tpl.real.from = [
            tpl.copy.from[0] + roundDeltaX,
            tpl.copy.from[1] + roundDeltaY
          ];
        }
        if (tpl.real['to'] && tpl.copy['to']) {
          tpl.real['to'] = [
            tpl.copy['to'][0] + roundDeltaX,
            tpl.copy['to'][1] + roundDeltaY
          ];
        }
      });
    }
  }
  selectionOnMouseUp(ev): MouseEvent {
    if (
      !this.allowEdit
      || !this.selected
    ) {
 return;
}

    ev.preventDefault();
    ev.stopPropagation();

    this.movingSelected = false;
  }

  onMouseDown(ev: MouseEvent) {
    if (
      !this.allowEdit
      || this.adding
    ) {
 return;
}

    if (!this.selecting) {
      this.onSelected(null);
      this.selectFromX = ev.offsetX;
      this.selectFromY = ev.offsetY;

      this.selecting = true;
    }

    this.selected = false;
    this.selectedDrawables = null;
  }

  onMouseUp(ev: MouseEvent) {
    if (
      !this.allowEdit
      || this.adding
    ) {
 return;
}

    if (this.selecting) {
      this.selectToX = ev.offsetX;
      this.selectToY = ev.offsetY;

      this.selecting = false;
      this.calcSelection();

      this.selected = true;
    }
  }

  onMouseMove(ev: MouseEvent) {
    if (!this.allowEdit) {
 return;
}

    if (this.adding && this.componentToAdd) {
      const crds: [number, number] = [
        10 * Math.round(0.1 * ev.offsetX),
        10 * Math.round(0.1 * ev.offsetY)
      ];
      switch (this.componentToAdd.type) {
        case 'line':
          if (this.componentToAdd.from) {
            this.componentToAdd['to'] = crds;
          }
          break;
        case 'connector':
          if (this.componentToAdd.from || this.componentToAdd['fromComponent']) {
            this.componentToAdd['to'] = crds;
          }
          break;
      }
    } else if (this.selecting) {
      this.selectToX = ev.offsetX;
      this.selectToY = ev.offsetY;

      this.calcSelection();
    }
  }

  calcSelection() {

    this.selectX = this.selectFromX < this.selectToX ? this.selectFromX : this.selectToX;
    this.selectY = this.selectFromY < this.selectToY ? this.selectFromY : this.selectToY;
    this.selectW = Math.abs(this.selectFromX - this.selectToX);
    this.selectH = Math.abs(this.selectFromY - this.selectToY);

    let fromX = this.selectFromX;
    let toX = this.selectToX;
    let fromY = this.selectFromY;
    let toY = this.selectToY;
    if (this.selectFromX > this.selectToX) {
      toX = this.selectFromX;
      fromX = this.selectToX;
    }
    if (this.selectFromY > this.selectToY) {
      toY = this.selectFromY;
      fromY = this.selectToY;
    }
    const isIn = (n: [number, number]): boolean => {
      if (!isArray(n) || n.length < 2) {
        return false;
      }
      return n[0] >= fromX && n[0] <= toX && n[1] >= fromY && n[1] <= toY;
    };

    const sels: {copy: {from: any[]; to?: any[]}; real: Drawable}[] = [];
    this.fullSchema.all.forEach(c => {
      let issel = false;
      if (c.type === 'line') {
        issel = isIn(c.from) && isIn(c['to']);
      } else if (c.type === 'connector') {
        issel =
          (
            c.from && isIn(c.from)
            || c['fromComponentRef'] && isIn (c['fromComponentRef']['from'])
          )
          && (
            c['to'] && isIn(c['to'])
            || c['toComponentRef'] && isIn (c['toComponentRef']['from'])
          );
      } else {
        issel = isIn(c.from);
      }
      c.isSelected = issel;

      if (issel) {
        sels.push({
          copy: {
            from: c.from ? [...c.from] : null,
            to: c['to'] ? [ ... c['to']] : null
          },
          real: c
        });
      }
    });

    this.selectedDrawables = sels;
  }

  addPreparedComponent() {
    if (!this.allowEdit) {
 return;
}

    if (this.componentToAdd.type === 'component') {
      this.fullSchema.all.push(this.componentToAdd);
    } else {
      this.fullSchema.all.unshift(this.componentToAdd);
    }
    this.selectedComponent = this.componentToAdd;
    this.selectedComponent.isSelected = true;
  }

  ngOnInit() {
    if (this.pumpRoom && this.pumpRoom.configuration && this.pumpRoom.configuration.fullSchema) {
      this.fullSchema = this.pumpRoom.configuration.fullSchema;

      this.fullSchema.all.forEach(e => {

        this.w = Math.max(this.w, e.x + 60);
        this.h = Math.max(this.h, e.y + 60);
      });
    }
  }

  ngOnDestroy() {
    if (this.selectedComponent) {
      this.selectedComponent.isSelected = false;
    }
    this.clearSelection();
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this.clearSelection();
      this.resetAdding();
    } else if (event.code === 'Digit1' && event.ctrlKey) {
      if (!this.adding) {
        this.addLine();
      }
    } else if (event.code === 'Digit2' && event.ctrlKey) {
      if (!this.adding) {
        this.addText();
      }
    } else if (event.code === 'Digit3' && event.ctrlKey) {
      if (!this.adding) {
        this.addConnection();
      }
    } else if (event.code === 'Digit4' && event.ctrlKey) {
      if (!this.adding) {
        this.addComponent();
      }
    }
  }

  selectedComponentChangeComponent(ev) {
    this.selectedComponent['component'] = ev;
    this.selectedComponent['symbol'] = ev.symbol;
  }

  onRemove(el: Drawable) {
    if (
      !this.allowEdit
      || this.adding
    ) {
 return;
}

    const conIdx = this.fullSchema.all.indexOf(el);
    if (conIdx >= 0) {
      this.fullSchema.all.splice(conIdx, 1);
    }

    this.clearSelection();
  }

  onSelected(el?: Drawable) {
    if (this.adding) {
 return;
}
    if (el && el !== this.selectedComponent) {
      this.clearSelection();
      const elIdx = this.fullSchema.all.indexOf(el);
      if (elIdx >= 0) {
        setTimeout(() => {
          this.selectedComponent = this.fullSchema.all[elIdx];
          this.fullSchema.all.splice(elIdx, 1);
          this.selectedComponent.isSelected = true;
          this.fullSchema.all.push(this.selectedComponent);
        }, 100);
      }
    } else if (!el) {
      this.clearSelection();
    }
  }

  private clearSelection() {
    if (this.selectedComponent) {
      this.selectedComponent.isSelected = false;
      this.selectedComponent = null;
    }
    this.fullSchema.all.forEach(e => {
      e.isSelected = false;
      e.isDragged = false;
    });
    this.fullSchema.all.sort((a, b) => {
      if (a.type === 'component' && b.type !== 'component') {
        return 1;
      }

      if (a.type !== 'component' && b.type === 'component') {
        return -1;
      }

      return 0;
    });
  }

}
