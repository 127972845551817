import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, Subject, forkJoin } from 'rxjs';
import {
  ResortArea,
  Schedule,
  SnowCannon,
  PumpRoom,
  CompressorRoom,
  PowerSource,
  ConnectionPoint,
  MeteoStation,
  SnowThickness,
  Resort,
  WaterReservoir,
  SnowThicknessTimeRange,
  MeteoDevice
} from 'src/app/shared/models';
import { ResortService } from 'src/app/resort.service';
import { ScheduleClientService, DevicesClientService, SnowThicknessClientService } from 'src/app/http-clients';

export class AreaData {
  resort: Resort;
  area: ResortArea;
  schedules: Schedule[];
  currentSchedule?: Schedule;
  meteoStation?: MeteoDevice;
  snowCannons?: SnowCannon[];
  connectionPoints?: ConnectionPoint[];
  pumpRooms?: PumpRoom[];
  compressorRooms?: CompressorRoom[];
  powerSources?: PowerSource[];
  meteoStations?: MeteoStation[];
  waterReservoirs?: WaterReservoir[];
  snowThickness?: SnowThicknessTimeRange;
}

@Injectable({
  providedIn: 'root'
})
export class AreaDataResolverService implements Resolve<AreaData> {
  constructor(
    private rs: ResortService,
    private dd: DevicesClientService,
    private ss: ScheduleClientService,
    private st: SnowThicknessClientService,
    private router: Router
  ) {}

  getData(id): AreaData | Observable<AreaData> | Observable<never> {
    const sub: Subject<AreaData> = new Subject();

    const obss: Observable<any>[] = [
      this.ss.getAll(),
      this.st.get()
    ];

    if (!this.rs.isReady()) {
      obss.push(this.rs.onReady);
    }

    forkJoin(obss)
    .subscribe(data => {
      const area = this.rs.getResortArea(id);
      const resort = this.rs.getResort();

      if (area) {
        const meteoStations: MeteoStation[] = this.rs.getMeteoStations();

        const connectionPoints: ConnectionPoint[] = area.getConnectionPoints();
        const snowCannons: SnowCannon[] = area.getSnowCannons().sort((a, b) => a.symbolAsNumber - b.symbolAsNumber);

        const compressorRooms: CompressorRoom[] = area.getCompressorRooms().filter(p => !p.isPurelySynthetic);
        const powerSources: PowerSource[] = area.getPowerSources()
          .filter(p => !p.isPurelySynthetic && p.canDisplayCurrentPowerDraw());

        const pumpRooms: PumpRoom[] = area.getPumpRooms();
        const waterReservoirs: WaterReservoir[] = this.rs.getWaterReservoirs();

        const meteoStation: MeteoDevice = area.remoteMeteoStationRef;

        const ids: string[] = snowCannons.map(sc => sc.id);
        ids.push(area.id);

        if (meteoStation) {
          ids.push(meteoStation.id);
        }
        let currentSchedule: Schedule = null;
        if (area.scheduleSchema && data[0]) {
          currentSchedule = data[0].find(e => '' + e.schema.id === '' + area.scheduleSchema);
        }

        sub.next({
          resort,
          area,
          schedules: data[0],
          connectionPoints,
          currentSchedule,
          meteoStation,
          snowCannons,
          pumpRooms,
          compressorRooms,
          powerSources,
          meteoStations,
          waterReservoirs,
          snowThickness: data[1]
        });
        sub.complete();
      } else {
        sub.error('can not fetch area');
      }
    }, () => {
      sub.error('can not fetch areaData');
    });

    return sub.asObservable();
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): AreaData | Observable<AreaData> | Observable<never> {
    return this.getData(route.paramMap.get('id'));
  }
}
