import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Device } from '../../../../../../common';
import { ConnectionPoint, SnowCannon } from '../../models';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'ss-device-image',
  template: `<img *ngIf="link" [attr.src]="'assets/images/device-images/' + link + '.jpg'">`,
  styleUrls: []
})
export class DeviceImageComponent {

  public link = 'otherscannon';

  private _device: Device;
  @Input()
  public get device(): Device {
    return this._device;
  }
  public set device(v: Device) {
    this._device = v;

    if(v instanceof SnowCannon) {
      let mt = `${v.mountType}`.toLowerCase();
      if(!['chassis','wheels','e400','h800','st170'].includes(mt)) {
        mt = 'chassis';
      }
      switch (`${v.cannonModel}`.toLocaleLowerCase()) {
        case 'bachler':
          this.link = 'otherslance';
          return;
        case 'areco':
        case 'fa380':
        case 'titan2.0':
        case 'titans':
        case 'titan':
        case 'fa540':
        case 'fa540whisper':
        case 's60':
        case 't40':
        case 't60':
        case 'tf10':
        case 'sufag':
          this.link = 'otherscannon';
          return;
        case 'virga':
          this.link = 'snvirga';
          return;
        case '700':
        case '700a':
        case 'sn700':
        case 'sn700a':
          this.link = mt + '-sn700a';
          return;
        case '700ase':
        case 'sn700ase':
          this.link = mt + '-sn700ase';
          return;
        case '700h':
        case '700hybrid':
        case 'sn700h':
        case 'sn700hybrid':
          this.link = mt + '-sn700h';
          return;
        case '900':
        case '900a':
        case 'sn900':
        case 'sn900a':
        default:
          this.link = mt + '-sn900a';
          return;
      }
    } else if(v instanceof ConnectionPoint) {
      let mt = `${v.mountType}`.toLowerCase();
      if(!['e400', 'h800', 'st170', 'st250', 'pit', 'hydrobox'].includes(mt)) {
        mt = 'pit';
      }
      this.link = mt;
      return;
    }
    this.link = '';
  }
}
