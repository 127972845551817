import { ReportDefinition } from '../definition';

export const blankDefinition: ReportDefinition = ReportDefinition.create({
  id: 'blank-definition',
  reportName: `Nowy szablon raportu`,
  duration: '3d',
  precision: '1h',
  isCustom: false,
  isLive: false,
  isHidden: true,
  priority: -10
});
