<div id="main" class="main" [ngClass]="{'is-menu-expanded': isMenuExpanded}">
  <div class="menu">
    <perfect-scrollbar>
    <div class="menu__container">
      <div class="menu__logo">
        <svg>
          <use xlink:href="#supersnow-logo" />
        </svg>
      </div>
      <ul class="menu__list">

        <li class="menu__item menu__item--decorated" routerLinkActive="is-active"
          *ssdHideIfNotGranted="'ROLE_OBSERVER';requiresCapability:'hasSnowmakingCapabilities'">
          <a routerLink="/application/resort/areas/map" i18n="Armatki i lance|Wpis w menu - armatki i lance@@menuSnowCannons">Armatki i lance</a>
        </li>

        <li class="menu__item menu__item--decorated" routerLinkActive="is-active"
          *ssdHideIfNotGranted="'ROLE_OBSERVER';requiresCapability:'hasPumprooms'">
          <a routerLink="/application/pump-rooms" i18n="Pompownie|Wpis w menu - pompownie@@menuPumpRooms">Pompownie</a>
        </li>

        <li class="menu__item menu__item--decorated" routerLinkActive="is-active"
          *ssdHideIfNotGranted="'ROLE_OBSERVER';requiresCapability:'hasPowerSources'">
          <a routerLink="/application/power-sources" i18n="Zasilanie|Wpis w menu - zasilanie@@menuPowerSources">Zasilanie</a>
        </li>

        <li class="menu__item menu__item--decorated" routerLinkActive="is-active">
          <a routerLink="/application/weather-and-schedules" i18n="Pogoda i schematy|Wpis w menu - pogoda i schematy@@menuWeatherAndSchedules">Pogoda i schematy</a>
        </li>

      </ul>
      <ul class="menu__list">

        <li class="menu__item" routerLinkActive="is-active"
          *ssdHideIfNotGranted="'ROLE_OBSERVER';requiresCapability:'hasReports'">
          <a routerLink="/application/reporting" i18n="Raporty|Wpis w menu - raporty@@menuReports">Raporty</a>
        </li>

        <li class="menu__item" routerLinkActive="is-active"
          *ssdHideIfNotGranted="'ROLE_ADMIN';requiresCapability:'hasUserManagement'">
          <a routerLink="/application/users" i18n="Użytkownicy|Wpis w menu - użytkownicy@@menuUsers">Użytkownicy</a>
        </li>

        <li class="menu__item" routerLinkActive="is-active"
          *ssdHideIfNotGranted="'ROLE_SUPERADMIN'"><!-- ;requiresCapability:'hasAuditTrail' -->
          <a routerLink="/application/audit-trail" i18n="Ślad serwisowy|Wpis w menu - ślad serwisowy@@menuAuditTrail">Ślad serwisowy</a>
        </li>

        <li class="menu__item" routerLinkActive="is-active"
          *ssdHideIfNotGranted="'ROLE_SUPERADMIN'">
          <a routerLink="/application/editor" i18n="Edytor|Wpis w menu - edytor@@menuEditor">Edytor</a>
        </li>

        <li class="menu__item" routerLinkActive="is-active"
          *ssdHideIfNotGranted="'ROLE_SUPERADMIN'">
          <a routerLink="/application/settings" i18n="Ustawienia|Wpis w menu - ustawienia@@menuSettings">Ustawienia</a>
        </li>
      </ul>
<!--
        <li class="menu__item menu__item--decorated" routerLinkActive="is-active">
          <a routerLink="/application/connection-points" i18n="Studnie fundamentowe|Wpis w menu - studnie fundamentowe@@menuConnectionPoints">Studnie fundamentowe</a>
        </li>
-->
<!--
  <li class="menu__item" routerLinkActive="is-active">
    <a routerLink="/application/notifications" i18n="Notyfikacje|Wpis w menu - notyfikcaje@@menuNotifications">Notyfikacje</a>
  </li>
-->
      <div class="locale-and-version">
        <p>
          <a class="btn pr-0 mr-0 btn-link" (click)="openInNewWindow($event)">
            <ng-container i18n="Otwórz w nowym oknie|Link na dole menu@@menuOpenInNewWindowLink">Otwórz w nowym oknie</ng-container>
            <svg class="ml-1">
              <use xlink:href="#icon-new-window" />
            </svg>
          </a>
        </p>
        <ul>
          <li class="desc" i18n="Wybrany język|Wpis w menu - Wybrany język@@menuSelectedLocale">Wybrany język</li>
          <li [ngClass]="{'selected': locale === 'pl' }">
            <a href="/pl">pl</a>
          </li>
          <li [ngClass]="{'selected': locale === 'en' }">
            <a href="/en">en</a>
          </li>
          <li [ngClass]="{'selected': locale === 'de' }">
            <a href="/de">de</a>
          </li>
<!--
          <li [ngClass]="{'selected': locale == 'cs' }">
            <a href="/cs">cs</a>
          </li>
          <li [ngClass]="{'selected': locale == 'sk' }">
            <a href="/sk">sk</a>
          </li>
-->
        </ul>
        <p>{{ version }}</p>
      </div>
  </div>
  </perfect-scrollbar>
  </div>


  <div id="main-container" class="main__container">
    <div class="menu__closer" *ngIf="isMenuExpanded" (click)="closeMenu()"></div>

    <nav class="navbar">
      <div class="navbar__group">
        <button class="navbar__btn navbar__btn--menu" (click)="toggleMenu()">
          <svg>
            <use xlink:href="#icon-hamburger" />
          </svg>
        </button>
        <h1 class="navbar__header">
          <a routerLink="/application/resort/areas/map">
            {{ title }}
          </a>
        </h1>
      </div>


      <ng-template ssdHeaderExtension></ng-template>

      <div class="navbar__group stretch" *ngIf="!componentInfo || !componentInfo.component"></div>

      <div class="navbar__group pl-4">
        <span class="navbar__divider"></span>
        <div class="notifications-dropdown">
          <button
            class="notifications-dropdown__btn"
            [ngClass]="{
              'notifications-dropdown__btn__no-immediate-notifications': alertsCount === 0,
              'notifications-dropdown__btn__highest-level-alert': alertsLevel === 'alert',
              'notifications-dropdown__btn__highest-level-warning': alertsLevel === 'warning',
              'notifications-dropdown__btn__highest-level-notification': alertsLevel === 'notification'
            }"
            (click)="toggleRecentNotifications()">
            <svg>
              <use xlink:href="#icon-alert" />
            </svg>
            {{ alertsCount|number:'1.0-0' }}
            <svg [ngClass]="{'notifications-dropdown__btn--rotated': isRecentNotificationsExpanded }">
              <use xlink:href="#icon-chevron-down" />
            </svg>
          </button>
        </div>
        <div class="notifications-dropdown" *ngIf="reportExportsCount > 0">
          <button
            class="notifications-dropdown__btn"
            [ngClass]="{
              'notifications-dropdown__btn__no-immediate-notifications': reportExportsInProgressCount === 0,
              'notifications-dropdown__btn__highest-level-notification': reportExportsInProgressCount > 0
            }"
            (click)="toggleRecentReportExports()">
            <svg>
              <use xlink:href="#icon-file-csv" />
            </svg>
            {{ reportExportsInProgressCount|number:'1.0-0' }}
            <svg [ngClass]="{'notifications-dropdown__btn--rotated': isRecentReportExportsExpanded }">
              <use xlink:href="#icon-chevron-down" />
            </svg>
          </button>
        </div>
        <div class="user-dropdown">
          <button class="user-dropdown__btn" (click)="toggleUserProfile()">
            <ss-user-avatar [user]="user"></ss-user-avatar>
            <span>{{ user.name }} {{ user.surname }}</span>
            <svg [ngClass]="{'user-dropdown__btn--rotated': isUserProfileExpanded }">
              <use xlink:href="#icon-chevron-down" />
            </svg>
          </button>
        </div>
        <span class="navbar__divider"></span>
        <div class="d-flex flex-column align-items-center">
          <span class="navbar__hour text-center white-space-nowrap">
            {{ todayDateHour }}<span style="display: inline-block;width: 6px">{{ todayDateDot }}</span>{{ todayDateMinute }}
          </span>
          <span class="color-mid-grey fs-14 text-center white-space-nowrap">{{ todayDate }}</span>
        </div>
        <span class="navbar__divider"></span>
        <svg class="logo mr-6">
          <use xlink:href="#snowmatic-logo" />
        </svg>
      </div>
    </nav>

    <div #content id="content" class="main__content">
      <router-outlet></router-outlet>
    </div>

    <div class="modal-content" [ngClass]="{'activated': modalOutlet.isActivated }" (click)="hideModalContent()">
      <div>
        <div (click)="$event.stopPropagation()">
          <perfect-scrollbar>
            <router-outlet #modalOutlet="outlet" name="modal"></router-outlet>
          </perfect-scrollbar>
        </div>
      </div>
    </div>

    <div class="modal-content confirm-modal" [ngClass]="{'activated': isConfirmModalShown }">
      <div>
        <div (click)="$event.stopPropagation()">
          <perfect-scrollbar>

            <ss-page [modal]="true">
              <div class="row">
                <div class="col-auto">
                  <div class="modal-icon">
                    <svg>
                      <use xlink:href="#icon-exclamation" />
                    </svg>
                  </div>
                </div>
                <div class="col">
                  <ss-page-header>
                    <ss-page-header-title i18n="Uwaga!|Modal - Tytuł nad komunikatem@@confirmModalTitle">Uwaga!</ss-page-header-title>
                  </ss-page-header>
                  <ss-page-panel>
                  {{ confirmModalContent }}
                  </ss-page-panel>
                  <ss-page-footer>
                    <div></div>
                    <ss-page-button-group>
                      <button (click)="configrmModalOnAccept()" class="btn btn-default" i18n="Tak|Modal - przycisk potwierdzenia@@confirmModalAccept">Tak</button>
                      <button (click)="configrmModalOnDecline()" class="btn btn-primary" i18n="Nie|Modal - przycisk zaprzeczenia@@confirmModalDecline">Nie</button>
                    </ss-page-button-group>
                  </ss-page-footer>
                </div>
              </div>
            </ss-page>

          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</div>
