import { TimedChartData } from '../..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { drawAxis } from './draw-axis.function';
import { drawFloatingToolTip } from './draw-floating-tool-tip.function';
import { drawGrid } from './draw-grid.function';
import { drawLegend } from './draw-legend.function';
import { drawSeries } from './draw-series.function';
import { selectCanvas } from './select-canvas.function';
import { selectRoot } from './select-root.function';

export const updateChart = (
  state: D3DrawerChartState,
  dataStorage: TimedChartData,
): D3DrawerChartState => {
  if(
    !state.hostEl
    || !state.config
  ) {
    return state;
  }

  const seriesSpecificData = state.config.series.map(s =>
    s.labelFieldName
      ? dataStorage.getD3DrawabeSeries(s.labelFieldName)
      : undefined
  );
  const defaultData = dataStorage.getD3DrawabeSeries(state.config.labelFieldName);

  const root = selectRoot(state.hostEl, state.viewBoxWidth, state.viewBoxHeight);
  state = {
    ...state,
    seriesSpecificData,
    defaultData,
    generatedCanvas: selectCanvas(root)
  };

  state = drawAxis(state);
  state = drawGrid(state);
  state = drawSeries(state);
  state = drawLegend(state);
  state = drawFloatingToolTip(state);

  return state;
};
