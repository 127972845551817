import { Component, OnInit, Input } from '@angular/core';
import { Valve } from 'src/app/shared/models/pump-room';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';

@Component({
  selector: 'ss-valve-tile',
  templateUrl: './valve-tile.component.html',
  styleUrls: []
})
export class ValveTileComponent implements OnInit {

  constructor(private cfgService: ConfigureClientService) { }

  @Input()
  public valve: Valve;

  @Input()
  public selectedPipelineIds: string[] = [];

  public runOpenTimer;
  public runCloseTimer;

  private runOpen() {
    this.cfgService.configurePumpRoom(this.valve.pumpRoom, {
      openValveWithSymbol: this.valve.symbol
    }).subscribe();
  }
  public stopOpening() {
    clearInterval(this.runOpenTimer);
    this.runOpenTimer = undefined;
  }
  public open() {
    this.stopOpening();
    this.runOpen();
    if (this.valve.isContinous) {
      this.runOpenTimer = setInterval(() => {
        this.runOpen();
      }, 1500);
    }
  }
  private runClose() {
    this.cfgService.configurePumpRoom(this.valve.pumpRoom, {
      closeValveWithSymbol: this.valve.symbol
    }).subscribe();
  }
  public stopClosing() {
    clearInterval(this.runCloseTimer);
    this.runCloseTimer = undefined;
  }
  public close() {
    this.stopClosing();
    this.runClose();
    if (this.valve.isContinous) {
      this.runCloseTimer = setInterval(() => {
        this.runClose();
      }, 1500);
    }
  }

  ngOnInit() {
  }

}
