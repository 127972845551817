<svg:use  width="101" height="101" [attr.xlink:href]="icon" [ngStyle]="{
    transform: 'rotate(' + ( drawable.l || drawable.r ? 0 : 90) + 'deg)'
}" ></svg:use>
<svg:text *ngIf="drawable.r || drawable.l" x="50.5" y="70" dominant-baseline="middle" text-anchor="middle">{{ drawable.component.symbol }}</svg:text>
<svg:text *ngIf="drawable.d || drawable.u" x="40" y="50.5" dominant-baseline="middle" text-anchor="end">{{ drawable.component.symbol }}</svg:text>

<ng-container *ngIf="drawable.component.subType==='acutated'">
    <svg:text *ngIf="drawable.r || drawable.l" x="50.5" y="32" dominant-baseline="middle" text-anchor="middle">S</svg:text>
    <svg:text *ngIf="drawable.d || drawable.u" x="71" y="51.5" dominant-baseline="middle" text-anchor="middle">S</svg:text>
</ng-container>

<ng-container *ngIf="drawable.component.isOpenVar || drawable.component.isClosedVar">
    <svg:line *ngIf="drawable.component.isClosed" x1="45" y1="45" x2="56" y2="56" class="closed"></svg:line>
    <svg:line *ngIf="drawable.component.isClosed" x1="56" y1="45" x2="45" y2="56" class="closed"></svg:line>

    <svg:line *ngIf="drawable.component.isOpen && (drawable.u || drawable.d)" x1="50.5" y1="30" x2="50.5" y2="71" class="open"></svg:line>
    <svg:line *ngIf="drawable.component.isOpen && (drawable.l || drawable.r)" x1="30" y1="50.5" x2="71" y2="50.5" class="open"></svg:line>
</ng-container>

<ng-container *ngIf="drawable.component.openedPercentVar">
  <svg:text x="49" y="85" *ngIf="(drawable.r || drawable.l)"
    dominant-baseline="middle" text-anchor="middle">{{ (drawable.component.openedPercent || 0)|number:'1.0-0' }} %</svg:text>
  <svg:text x="80" y="49" *ngIf="(drawable.d || drawable.u)"
    dominant-baseline="middle" text-anchor="start">{{ (drawable.component.openedPercent || 0)|number:'1.0-0' }} %</svg:text>
</ng-container>
