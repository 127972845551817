import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { WaterPipeline } from 'src/app/shared/models';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { ModalRouteService } from 'src/app/application/modal-route.service';
import { ResortStatisticsService } from '../../../../../resort-statistics.service';
import { Subscription } from 'rxjs';
import { isArray } from '../../../../../../../../common';

@Component({
  selector: 'ss-water-pipeline-tile',
  templateUrl: './water-pipeline-tile.component.html',
  styleUrls: ['./water-pipeline-tile.component.sass']
})
export class WaterPipelineTileComponent implements OnInit, OnDestroy {

  constructor(
    private statService: ResortStatisticsService,
    private cfgService: ConfigureClientService,
    private mr: ModalRouteService
  ) { }

  private statSubscription: Subscription;

  private _waterPipeline: WaterPipeline;
  @Input()
  public get waterPipeline(): WaterPipeline {
    return this._waterPipeline;
  }
  public set waterPipeline(v: WaterPipeline) {
    this._waterPipeline = v;

    if(this.statSubscription) {
      this.statSubscription.unsubscribe();
    }

    if(this._waterPipeline && this._waterPipeline.id) {
      this.upsateLastHourMeteredFlow();
      this.statSubscription = this.statService.statsUpdated.subscribe(s => {
        this.upsateLastHourMeteredFlow();
      });
    }
  }

  private upsateLastHourMeteredFlow() {
    const id = this.waterPipeline.id;
    const stats = this.statService.getStats(id, 3);

    if(isArray(stats.meteredWaterConsumption) && stats.meteredWaterConsumption.length > 0) {
      this.lastHourMeteredFlow = stats.meteredWaterConsumption[ stats.meteredWaterConsumption.length - 1] || 0;
    }
  }
  public lastHourMeteredFlow = 0;

  public operationModeDropdownExpanded = false;
  public areasListExpanded = false;
  public highlightComponents = false;

  @Output()
  public selected: EventEmitter<boolean> = new EventEmitter();

  public highlightComponentsChange(ev: boolean) {
    this.selected.emit(ev);
  }

  setManualMode() {
    this.operationModeDropdownExpanded = false;
    this.changeOperationMode(false);
  }
  setAutoMode() {
    this.operationModeDropdownExpanded = false;
    this.changeOperationMode(true);
  }

  private changeOperationMode(operationMode: boolean) {
    this.cfgService.configureWaterPipeline(this.waterPipeline, operationMode, null).subscribe();
  }

  start() {
    this.cfgService.startDevice(this.waterPipeline.id).subscribe();
  }
  stop() {
    this.cfgService.stopDevice(this.waterPipeline.id).subscribe();
  }

  oppenConfigModal() {
    this.mr.showModalRoute('configure-water-pipeline-modal/' + this.waterPipeline.id);
  }

  ngOnInit() {}

  ngOnDestroy() {
    if(this.statSubscription) {
      this.statSubscription.unsubscribe();
    }
  }
}
