<ss-page>
    <ss-page-header>
        <ss-page-header-title i18n="Użytkownicy|Wpis w menu - użytkownicy@@menuUsers">Użytkownicy</ss-page-header-title>
        <ss-page-button-group>
            <button class="btn btn-default" [routerLink]="['../edit', 'new']">
                <svg>
                    <use xlink:href='#icon-plus' />
                </svg>
                <ng-container i18n="Dodaj użytkownika|Przycisk nad listą użytkowników@@usersListAddUserButton">Dodaj użytkownika</ng-container>
            </button>
        </ss-page-button-group>
    </ss-page-header>
    <ss-page-panel>
        <ss-d3-user-activity-chart [chartData]="chartData"></ss-d3-user-activity-chart>
    </ss-page-panel>

<!--
    <ss-page-toolbar>
        <p>Szukaj:</p>
        <input type="text" class="input" placeholder="login, imię, nazwisko..." />
        <p>
            <ss-checkbox>
                <ss-user-roles [roles]="['ROLE_OBSERVER']"></ss-user-roles>
            </ss-checkbox>
        </p>
        <p>
            <ss-checkbox>
                <ss-user-roles [roles]="['ROLE_OPERATOR']"></ss-user-roles>
            </ss-checkbox>
        </p>
        <p>
            <ss-checkbox>
                <ss-user-roles [roles]="['ROLE_ADMIN']"></ss-user-roles>
            </ss-checkbox>
        </p>
        <p>
            <ss-checkbox>
                <ss-user-roles [roles]="['ROLE_SUPERADMIN']"></ss-user-roles>
            </ss-checkbox>
        </p>
        <div class="filler"></div>
        <p>
            <ss-checkbox>Aktywni</ss-checkbox>
        </p>
        <p>
            <ss-checkbox>Nieaktywni</ss-checkbox>
        </p>
    </ss-page-toolbar>
-->
    <ss-page-panel>
        <div class="user-holder">
            <div *ngFor="let user of users">
                <div class="user row" [routerLink]="['../edit', user.id]" [ngClass]="{
                    'is-logged-in': user.isLoggedIn
                }">
                    <div class="col">
                        <p><strong>{{ user.name }} {{ user.surname }}</strong></p>
                        <p><small>{{ user.email }}</small></p>
                        <p><small>{{ user.phone }}</small></p>
                        <p class="is-active" *ngIf="user.isEnabled">
                            <ng-container i18n="Aktywny|Opis użytkownika w stanie Aktywny@@usersListUserInActive">Aktywny</ng-container>
                            <br />
                            <ng-container *ngIf="user.isLoggedIn; else displayLastLoginInstead">
                                <span i18n="Zalogowany|Opis użytkownika w stanie Zalogowany@@usersListUserLoggedIn">Zalogowany</span>
                            </ng-container>
                            <ng-template #displayLastLoginInstead>
                              {{ user.lastActivity?.activeTo|date:'yyyy-MM-dd HH:mm:ss' }}
                            </ng-template>
                        </p>
                        <p class="is-active" *ngIf="!user.isEnabled"><s i18n="Nieaktywny|Opis użytkownika w stanie Nieaktywny@@usersListUserInInactive">Nieaktywny</s></p>
                    </div>
                    <div class="col-auto">
                        <ss-user-avatar size="big" [showDescription]="true" [user]="user"></ss-user-avatar>
                    </div>
                </div>
            </div>
        </div>
    </ss-page-panel>
</ss-page>
