import { Component, EventEmitter, Input, Output } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { dayjs, isArray } from '../../../../../../common';
import { User, UserNotificationsSchedule } from '../../models';

@Component({
  selector: 'ss-user-notifications-schedule',
  templateUrl: './user-notifications-schedule.component.html',
  styleUrls: ['./user-notifications-schedule.component.sass']
})
export class UserNotificationsScheduleComponent {

  @Input()
  public disabled = false;
  private _user: User;
  @Input()
  public get user(): User {
    return this._user;
  }
  public set user(u: User) {
    this._user = u;
    const notifSched = this._user.notificationsSchedule;
    this._isSmsNotificationsEnabled = notifSched.isSmsNotificationsEnabled;
    this._isAppNotificationsEnabled = notifSched.isAppNotificationsEnabled;
    this._isScheduleAlwaysValid = !isArray(notifSched.weeklySchedule)
      || notifSched.weeklySchedule.reduce((p, c) => p && c.length <= 0, true);

    if (isArray(notifSched.pumpRoomMinmumNotificationLevel)) {
      this.notificationIndex[0][0] = notifSched.pumpRoomMinmumNotificationLevel.includes('alert');
      this.notificationIndex[0][1] = notifSched.pumpRoomMinmumNotificationLevel.includes('warning');
      this.notificationIndex[0][2] = notifSched.pumpRoomMinmumNotificationLevel.includes('notification');
      this.notificationIndex[0][3] = notifSched.pumpRoomMinmumNotificationLevel.includes('connection-lost');
    }
    if (isArray(notifSched.snowCannonMinmumNotificationLevel)) {
      this.notificationIndex[1][0] = notifSched.snowCannonMinmumNotificationLevel.includes('alert');
      this.notificationIndex[1][1] = notifSched.snowCannonMinmumNotificationLevel.includes('warning');
      this.notificationIndex[1][2] = notifSched.snowCannonMinmumNotificationLevel.includes('notification');
      this.notificationIndex[1][3] = notifSched.snowCannonMinmumNotificationLevel.includes('connection-lost');
    }

    if (isArray(notifSched.weeklySchedule)) {
      notifSched.weeklySchedule.forEach((d, di) => {
        d.forEach(h => {
          if (di >= 0 && di <= 6 && h >= 0 && h <= 23) {
            this.hourlyIndex[di][h] = true;
          }
        });
      });
    }
  }

  @Output()
  public changed: EventEmitter<UserNotificationsSchedule> = new EventEmitter();

  private _changed: EventEmitter<UserNotificationsSchedule> = new EventEmitter();

  public dayLabels: string[];
  public hourLabels: string[];

  public hourlyIndex: boolean[][];
  public notificationIndex: boolean[][];

  private _isSmsNotificationsEnabled = false;
  public get isSmsNotificationsEnabled(): boolean {
    return this._isSmsNotificationsEnabled;
  }
  public set isSmsNotificationsEnabled(v: boolean) {
    this._isSmsNotificationsEnabled = v;
    this.triggerChangedEvent();
  }
  private _isAppNotificationsEnabled = false;
  public get isAppNotificationsEnabled(): boolean {
    return this._isAppNotificationsEnabled;
  }
  public set isAppNotificationsEnabled(v: boolean) {
    this._isAppNotificationsEnabled = v;
    this.triggerChangedEvent();
  }
  private _isScheduleAlwaysValid = true;
  public get isScheduleAlwaysValid(): boolean {
    return this._isScheduleAlwaysValid;
  }
  public set isScheduleAlwaysValid(v: boolean) {
    this._isScheduleAlwaysValid = v;
    this.triggerChangedEvent();
  }

  private triggerChangedEvent() {
    if (this.disabled) {
      return;
    }
    this._changed.emit();
  }

  public toggleLevel(typeIdx: number, levelIdx: number) {
    if (this.disabled) {
      return;
    }
    if (typeIdx !== undefined && levelIdx !== undefined) {
      this.notificationIndex[typeIdx][levelIdx] = !this.notificationIndex[typeIdx][levelIdx];
    } else if (typeIdx !== undefined && levelIdx === undefined) {
      const target = this.notificationIndex[typeIdx].reduce((p, c) => p + (c ? 1 : 0), 0) < 2;
      this.notificationIndex[typeIdx].fill(target);
    }

    this.triggerChangedEvent();
  }

  public toggleHour(dayIdx: number, hourIdx: number) {
    if (this.disabled) {
      return;
    }
    if (dayIdx !== undefined && hourIdx !== undefined) {
      this.hourlyIndex[dayIdx][hourIdx] = !this.hourlyIndex[dayIdx][hourIdx];
    } else if (dayIdx !== undefined && hourIdx === undefined) {
      const target = this.hourlyIndex[dayIdx].reduce((p, c) => p + (c ? 1 : 0), 0) < 12;
      this.hourlyIndex[dayIdx].fill(target);
    } else if (dayIdx === undefined && hourIdx !== undefined) {
      const target = this.hourlyIndex.map(v => v[hourIdx]).reduce((p, c) => p + (c ? 1 : 0), 0) < 4;
      this.hourlyIndex.forEach(v => v[hourIdx] = target);
    }

    this.triggerChangedEvent();
  }

  constructor() {
    const sof = dayjs().startOf('isoWeek').add(-1, 'd');
    this.dayLabels = [ ... new Array(7) ].map((v, i) => sof.add(i + 1, 'd').tz().format('dddd'));
    this.hourLabels = [ ... new Array(24) ].map((v, i) => `${i}`.padStart(2, '0'));
    this.hourlyIndex = [ ... new Array(7) ].map(w => [ ... new Array(24) ].map(v => false));
    this.notificationIndex = [
      [true, false, false, false],
      [true, false, false, false]
    ];

    this._changed.pipe(
      debounceTime(500)
    ).subscribe(() => {
      const snowCannonMinmumNotificationLevel = [];
      if (this.notificationIndex[0][0]) {
        snowCannonMinmumNotificationLevel.push('alert');
      }
      if (this.notificationIndex[0][1]) {
        snowCannonMinmumNotificationLevel.push('warning');
      }
      if (this.notificationIndex[0][2]) {
        snowCannonMinmumNotificationLevel.push('notification');
      }
      if (this.notificationIndex[0][3]) {
        snowCannonMinmumNotificationLevel.push('connection-lost');
      }
      if (snowCannonMinmumNotificationLevel.length === 0) {
        snowCannonMinmumNotificationLevel.push('alert');
      }

      const pumpRoomMinmumNotificationLevel = [];
      if (this.notificationIndex[1][0]) {
        pumpRoomMinmumNotificationLevel.push('alert');
      }
      if (this.notificationIndex[1][1]) {
        pumpRoomMinmumNotificationLevel.push('warning');
      }
      if (this.notificationIndex[1][2]) {
        pumpRoomMinmumNotificationLevel.push('notification');
      }
      if (this.notificationIndex[1][3]) {
        pumpRoomMinmumNotificationLevel.push('connection-lost');
      }
      if (pumpRoomMinmumNotificationLevel.length === 0) {
        pumpRoomMinmumNotificationLevel.push('alert');
      }

      const resp = UserNotificationsSchedule.create({
        isSmsNotificationsEnabled: this._isSmsNotificationsEnabled,
        isAppNotificationsEnabled: this._isAppNotificationsEnabled,
        isScheduleAlwaysValid: this._isScheduleAlwaysValid,
        pumpRoomMinmumNotificationLevel,
        snowCannonMinmumNotificationLevel
      });

      if (!this._isScheduleAlwaysValid) {
        const weeklySchedule = [... new Array(7)].map(v => []);
        this.hourlyIndex.forEach((d, di) => {
          d.forEach((h, hi) => {
            if (h) {
              weeklySchedule[di].push(hi);
            }
          });
        });
        resp.weeklySchedule = weeklySchedule;
      }

      this.changed.emit(resp);
    });
   }
}
