import { UnitSet, UnitType } from '..';

let unitSet;
export const updateConvertUnitDecoratorUnitSet = (us: UnitSet) => {
  unitSet = us;
};

export const ConvertUnit = (unitType: UnitType, baseUnit: string) => (target: any, propertyKey: string) => {
    const uKey = propertyKey + 'Unit';
    const cKey = propertyKey + 'Converted';
    const lKey = '_' + propertyKey;

    const getter = function(this) {
      return this[lKey];
    };
    const setter = function(this, newVal: number) {
      if (!unitSet || !unitSet[unitType]) {
        throw new Error('This object can not be decorated with ConvertUnit on '
        + propertyKey + ' because of empty unitSet ' + JSON.stringify(target));
      }

      this[lKey] = newVal || 0;
      this[uKey] = unitSet.getCalculatedUnit(unitType);

      this[cKey] = unitSet.getCalculatedValue(unitType, this[lKey], baseUnit as any);
    };

    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter
    });
  };
