export type LocalizeFnReturnFormat = string | {
  id: string;
  fallbackString: string;
  placeholderValues: string[];
};

declare const global: any;

/**
 * This function is used for localization of strings in backend services.
 * In Angular it only passes through text translated by $localize mechanizm on typescript compine
 * In backend services it converts LocalizeFnReturnFormat returned by custom $localize (ts-monorepo/common/serverside/localize.functions.ts)
 * and passes this object to custom, defined globally, xliff translator (ts-monorepo/common/serverside/translator.functions.ts)
 *
 * This function should not be used outside ts-monorepo/common/translation-functions
 *
 * @param localizedTranslationTextToCheckOnRuntime LocalizeFnReturnFormat
 * @returns string
 */
export const trn = (localizedTranslationTextToCheckOnRuntime: LocalizeFnReturnFormat): string => {
  if(global['translator']) {
    return global['translator'](localizedTranslationTextToCheckOnRuntime);
  }
  return localizedTranslationTextToCheckOnRuntime as string;
};
