<svg:text *ngIf="drawable.r || drawable.l" x="50.5" y="70" dominant-baseline="middle" text-anchor="middle">{{ drawable.component.symbol }}</svg:text>
<svg:text *ngIf="drawable.d || drawable.u" x="40" y="50.5" dominant-baseline="middle" text-anchor="end">{{ drawable.component.symbol }}</svg:text>

<svg:rect x="35" y="0" width="31" height="101" rx="4" class="border"></svg:rect>
<svg:rect *ngIf="drawable.component.isHighLevelExceeded"
  x="37" y="2" width="27" height="97" rx="3" style="fill:#f00"></svg:rect>
<svg:rect *ngIf="!drawable.component.isHighLevelExceeded && drawable.component.isHighLevelReached"
  x="37" y="2" width="27" height="97" rx="3" style="fill:rgb(33, 190, 54)"></svg:rect>
<svg:rect *ngIf="!drawable.component.isHighLevelReached && drawable.component.isMediumLevelReached"
  x="37" y="37" width="27" height="62" rx="3" style="fill:rgb(217, 255, 0)"></svg:rect>
<svg:rect *ngIf="!drawable.component.isMediumLevelReached  && drawable.component.isLowLevelReached"
  x="37" y="79" width="27" height="20" rx="3" style="fill:rgb(255, 153, 0)"></svg:rect>
<svg:rect *ngIf="!drawable.component.isLowLevelReached"
  x="37" y="89" width="27" height="10" rx="3" style="fill:#f00"></svg:rect>
