import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'form-error',
  template: '<ng-content></ng-content>',
  styles: []
})
export class FormErrorComponent {
  @HostBinding('class.error-is-visible')
  get isDisplayed(): boolean {
    return !!this.data;
  }

  @HostBinding('class.last-visible-error')
  isLastVisibleError = false;

  @Input() public data: any;
  @Input() public validator: string;
}
