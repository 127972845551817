import { Injectable, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {

  constructor() { }

  modalRequest: EventEmitter<string> = new EventEmitter();
  modalResponse: EventEmitter<boolean> = new EventEmitter();

  public open(message: string): Observable<boolean> {
    this.modalRequest.emit(message);

    return this.modalResponse.asObservable();
  }

  public openAlertDoUWannaLogout(): Observable<boolean> {
    return this.open($localize`:Czy na pewno chcesz się wylogować
      |Treść powiadomienia po wcisnieciu guzika
      @@userProfileLogoutConfirmationMessage:
      Czy na pewno chcesz się wylogować?
    `);
  }

  public openAlertChangeWillSetDifferentSnowQuality(
    snowQualityType: number,
    currentSnowQuality: number,
    snowQualityConstant: number,
    snowQualityLow: number,
    snowQualityHigh: number
  ): Observable<boolean> {
    return this.open($localize`:Zmiana trybu pracy na SNOWMATIC spowoduję zmianę ustawień trybu jakości śniegu
      |Treść powiadomienia, które pojawia się użytkownikowy gdy włączenie przez niego
      trybu automatycznego spowoduje zmianę jakości śniegu armatki
      @@confirmModalAlertChangeWillSetDifferentSnowQuality:
      Zmiana trybu pracy na SNOWMATIC spowoduję zmianę ustawień trybu jakości śniegu.
      Aktualnie obowiązuje jakośc śniegu ${currentSnowQuality}.
      Właczenie trybu automatycznego spowoduje przełączenie armatki na tryb
      {
        ${snowQualityType}, select,
        1 {najlepszej jakości śniegu z zakresem ${snowQualityLow} - ${snowQualityHigh}}
        2 {największej ilości śniegu z zakresem ${snowQualityLow} - ${snowQualityHigh}}
        other {stałej jakokości śniegu ${snowQualityConstant}}
      }.
      Czy chcesz przywrócić poprzednie ustawienia trybu jakości śniegu?
    `);
  }

  public openAlertChangeWillShutDownSnowCannons(count: number): Observable<boolean> {
    return this.open($localize`:Ta zmiana spowoduje wyłączenie armatek
      |Treść powiadomienia, które pojawia się użytkownikowy gdy dokonywana przez niego zmiana spowoduje wyłączenie armatek
      @@confirmModalAlertChangeWillShutDownSnowCannons:
      Ta zmiana spowoduje wyłączenie
      {
        ${count}, plural,
        =1 {jednej armatki}
        =2 {${count} armatek}
        =3 {${count} armatek}
        =4 {${count} armatek}
        =5 {${count} armatek}
        other {${count} armatek}
      }.
      Czy chcesz kontynuować?
    `);
  }

  public openConfirmRemovalOfAccount(): Observable<boolean> {
    return this.open($localize`:Czy na pewno chcesz usunąć to konto?
      |Treść powiadomienia z potwierdzeniem usunięcia konta
      @@confirmModalUserProfileRemoveAccount:
      Czy na pewno chcesz usunąć to konto?
    `);
  }

  public openConfirmRemovalOfReportSchema(): Observable<boolean> {
    return this.open(`Do you want to remove this report schema?`);
  }
}
