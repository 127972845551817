import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { isArray } from '../../../../../../common';
import { HeaderService } from '../../../header.service';
import { ResortStatisticsService } from '../../../resort-statistics.service';
import { ResortService } from '../../../resort.service';
import { ElectricalLine, PowerSource } from '../../../shared/models';
import { ModalRouteService } from '../../modal-route.service';
import { PowerSourceHeaderExtensionComponent } from '../header-extension/header-extension.component';

@Component({
  selector: 'ss-power-sources-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.sass']
})
export class PowerSourcesDetailComponent implements OnInit, OnDestroy {

  constructor(
    private titleService: HeaderService,
    private resortService: ResortService,
    private resortStatisticsService: ResortStatisticsService,
    private mr: ModalRouteService,
    private route: ActivatedRoute
  ) { }

  public powerSource: PowerSource;
  public devIds: string[] = [];
  public electricalLines: ElectricalLine[] = [];
  public lastQuarterPowerDraw = 0;
  private statsUpdatedSubscribtion: Subscription;

  ngOnInit(): void {
    this.titleService.setUpdateHeader('menuPowerSources');

    this.route.paramMap.subscribe(data => {
      this.powerSource = this.resortService.getPowerSource(data.get('id'));

      this.titleService.setUpdateHeader('menuPowerSources', PowerSourceHeaderExtensionComponent, {
        powerSource: this.powerSource
      });

      this.electricalLines = this.powerSource.getElectricalLines().sort((a, b) => `${a.symbol}`.localeCompare(`${b.symbol}`));

      this.devIds = [ this.powerSource.id, ...this.electricalLines.map(p => p.id) ];
    });

    this.statsUpdatedSubscribtion = this.resortStatisticsService.statsUpdated.subscribe(() => {
      this.updateData();
    });

    this.updateData();
  }

  private updateData() {
    const id = this.powerSource.id;
    const stats = this.resortStatisticsService.getStats(id, 3);

    if(isArray(stats.powerDraw) && stats.powerDraw.length > 0) {
      this.lastQuarterPowerDraw = stats.powerDraw[ stats.powerDraw.length - 1] || 0;
    }
  }

  ngOnDestroy() {
    if (this.statsUpdatedSubscribtion) {
      this.statsUpdatedSubscribtion.unsubscribe();
    }
  }


  public goToPoweSourceConfig() {
    this.mr.showModalRoute('configure-power-source-modal/' + this.powerSource.id);
  }

}
