import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndpointMapService } from '../endpoint-map.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AlertsClientService {

  constructor(private e: EndpointMapService, private http: HttpClient) {}

  public get(fromId: number = 0, toId: number = 0, isPreload: boolean = false): Observable<any> {
    const options = { ... httpOptions };
    options['params'] = { fromId, toId };
    if (isPreload) {
      options['params'].isPreload = true;
    }
    return this.http.get(this.e.alerts, options);
  }

  public getActive(): Observable<any> {
    return this.get(0, 0, true);
  }

}
