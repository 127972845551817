<form #form="ngForm" (submit)="saveSettings()">
<ss-page>
  <ss-page-header>
    <ss-page-header-title>Ustawienia</ss-page-header-title>
    <ss-page-button-group>
      <button class="btn btn-default" type="button" (click)="loadSettings()" i18n="Anuluj|Anuluj@@massOperationsModalCancelButon">Anuluj</button>
      <button class="btn btn-primary" type="submit" [disabled]="isInProgress || form.invalid">Zapisz ustawienia</button>
    </ss-page-button-group>
  </ss-page-header>

  <div class="row">
    <div class="col-8 pr-1">
      <ss-page-panel panelTitle="Powiadomienia" *ngIf="allowNotificationSchedule">
        <ss-user-notifications-schedule [user]="user"
          [disabled]="isInProgress"
          (changed)="notificationScheduleChanged($event)"></ss-user-notifications-schedule>
      </ss-page-panel>
    </div>
    <div class="col-4 pl-1">
      <ss-page-panel panelTitle="Jednostki">

          <form-select name="unitSetWindSpeed" *ssdHideIfNotGranted="'ROLE_SUPERADMIN'"
            [disabled]="isInProgress"
            [(ngModel)]="unitSet.windSpeed"
            label="Prędkość wiatru">
            <form-select-option *ngFor="let x of windSpeedUnits"
              [value]="x" [label]="x"></form-select-option>
          </form-select>

          <form-select name="unitSetWaterFlow"
            [disabled]="isInProgress"
            [(ngModel)]="unitSet.waterFlow"
            label="Przepływ wody">
            <form-select-option *ngFor="let x of waterFlowUnits"
              [value]="x" [label]="x"></form-select-option>
          </form-select>

          <form-select name="unitSetProduction"
            [disabled]="isInProgress"
            [(ngModel)]="unitSet.production"
            label="Produkcja śniegu">
            <form-select-option *ngFor="let x of productionUnits"
              [value]="x" [label]="x"></form-select-option>
          </form-select>

          <form-select name="unitSetAirUsage" *ssdHideIfNotGranted="'ROLE_SUPERADMIN'"
            [disabled]="isInProgress"
            [(ngModel)]="unitSet.airUsage"
            label="Zużycie sprężonego powietrza">
            <form-select-option *ngFor="let x of airUsageUnits"
              [value]="x" [label]="x"></form-select-option>
          </form-select>

          <form-select name="unitSetWaterUsage" *ssdHideIfNotGranted="'ROLE_SUPERADMIN'"
            [disabled]="isInProgress"
            [(ngModel)]="unitSet.waterUsage"
            label="Zużycie wody">
            <form-select-option *ngFor="let x of waterUsageUnits"
              [value]="x" [label]="x"></form-select-option>
          </form-select>

      </ss-page-panel>
    </div>
  </div>

  <div class="row" *ssdHideIfNotGranted="'ROLE_SUPERADMIN'">
    <div class="col-4 pr-1">
      <ss-page-panel panelTitle="Ustawienia języka">
      </ss-page-panel>
    </div>
    <div class="col-4 px-1">
      <ss-page-panel panelTitle="Raporty">
      </ss-page-panel>
    </div>
    <div class="col-4 pl-1">
      <ss-page-panel panelTitle="Ustawienia widoków">
        <form-select name="animationSpeed"
          [disabled]="isInProgress"
          [(ngModel)]="animationSpeed"
          label="Szybkość animacji">
          <form-select-option [value]="'0'" label="Brak"></form-select-option>
          <form-select-option [value]="'200'" label="Szybkie"></form-select-option>
          <form-select-option [value]="'500'" label="Wolne"></form-select-option>
        </form-select>
        <form-select name="defaultChartPeriod"
          [disabled]="isInProgress"
          [(ngModel)]="defaultChartPeriod"
          label="Domyślna długość przediału czasu dla danych wyświetlanych na wykresach">
          <form-select-option [value]="'6'" label="6 godzin"></form-select-option>
          <form-select-option [value]="'12'" label="12 godzin"></form-select-option>
          <form-select-option [value]="'24'" label="24 godziny"></form-select-option>
        </form-select>
        <form-select name="machineRoomFullSchemaDarkMode"
          [disabled]="isInProgress"
          [(ngModel)]="machineRoomFullSchemaDarkMode"
          label="Jakie tło mają mieć pełne schematy maszyniwni">
          <form-select-option [value]="'1'" label="Ciemne tło"></form-select-option>
          <form-select-option [value]="'0'" label="Białe tło"></form-select-option>
        </form-select>
      </ss-page-panel>
    </div>
  </div>

  <ss-page-footer>
    <div></div>
    <ss-page-button-group>
      <button class="btn btn-default" type="button" (click)="loadSettings()" i18n="Anuluj|Anuluj@@massOperationsModalCancelButon">Anuluj</button>
      <button class="btn btn-primary" type="submit" [disabled]="isInProgress || form.invalid">Zapisz ustawienia</button>
    </ss-page-button-group>
  </ss-page-footer>

</ss-page>
</form>
<!-- TODO -->