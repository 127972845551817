import { Component, OnInit } from '@angular/core';
import { User, UserStats } from 'src/app/shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { UsersClientService } from 'src/app/http-clients';
import { ConfirmModalService } from '../../confirm-modal.service';
import { Observable } from 'rxjs';
import { ModalRouteService } from '../../modal-route.service';

@Component({
  selector: 'ss-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.sass']
})
export class UserEditComponent implements OnInit {

  user: User;
  chartData: UserStats;

  inProgress = false;
  isOwnAccount = false;

  atFrom: string;
  atTo: string;
  atData: any[];

  public email: string;
  public name: string;
  public surname: string;
  public phone: string;
  public role: string;
  public isEnabled = true;
  public isVisibleOnPreloginList = true;
  public isAllowedToLogInRemotely = true;

  resetPassword() {
    this.mr.showModalRoute('reset-user-password-modal/' + this.user.id);
  }

  delete() {
    this.confirmModalService
      .openConfirmRemovalOfAccount()
      .subscribe((resp) => {
      if (resp) {
        this.inProgress = true;
        this.ucs.delete(this.user.id).subscribe(() => {
          this.router.navigate(['application', 'users', 'list']);
          this.inProgress = false;
        }, () => {
          this.inProgress = false;
        });
      }
    });
  }

  save() {
    this.inProgress = true;
    if (!this.user.id) {
      this.ucs.create(
        this.email,
        this.name,
        this.surname,
        this.phone,
        this.role,
        this.isEnabled,
        this.isVisibleOnPreloginList,
        this.isAllowedToLogInRemotely
      ).subscribe((user) => {
        this.mr.showDialog('rename-modal', resp => {
          setTimeout(() => {
            this.router.navigate(['application', 'users', 'edit', user.id]);
            this.inProgress = false;
          }, 1000);
        }, {
          isReadOnly: true,
          displayTitleText: $localize`:Hasło nowego użytkownika
          |Tytuł modala
          @@userProfileAccountModalDisplayTitleText:
          Hasło nowego użytkownika`,
          displayInputLabel: $localize`:Hasło należy skopiować i przekazać wraz z loginem użytkownikowi.
          |Tytuł modala
          @@userProfileAccountModalDisplayInputLabel:
          Hasło należy skopiować i przekazać wraz z loginem użytkownikowi.`,
          renameString: user.temporaryPassword
        });
      }, () => {
        this.inProgress = false;
      });
    } else {
      this.ucs.update(
        this.user.id,
        this.name,
        this.surname,
        this.phone,
        this.role,
        this.isEnabled,
        this.isVisibleOnPreloginList,
        this.isAllowedToLogInRemotely
      ).subscribe(() => {
        this.router.navigate(['application', 'users', 'list']);
        this.inProgress = false;
      }, () => {
        this.inProgress = false;
      });
    }
  }

  constructor(
    private ucs: UsersClientService,
    private as: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmModalService: ConfirmModalService,
    private mr: ModalRouteService
  ) { }

  ngOnInit() {
    this.route.data.subscribe((data: { user: User; stats: UserStats; auditTrail: any }) => {
      this.user = data.user;

      if (this.user.id) {
        this.email = this.user.email;
        this.name = this.user.name;
        this.surname = this.user.surname;
        this.phone = this.user.phone;
        this.role = this.user.roles && this.user.roles.length > 0 ? this.user.roles[0] : User.ROLE_OBSERVER;
        this.isEnabled = this.user.isEnabled;
        this.isVisibleOnPreloginList = this.user.isVisibleOnPreloginList;
        this.isAllowedToLogInRemotely = this.user.isAllowedToLogInRemotely;

        this.isOwnAccount = this.as.getUser().id === this.user.id;
      }

      this.chartData = data.stats;
      if (data.auditTrail) {
        this.atData = data.auditTrail.data;
        this.atFrom = data.auditTrail.from;
        this.atTo = data.auditTrail.to;
      }
    });
  }

}
