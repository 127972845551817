<ss-page-panel *ngIf="partView">
  <div class="row align-items-center">
    <ng-container *ngIf="isEditMode">
      <div class="col-auto px-4">
        <button class="btn btn-dark" (click)="renamePart()" i18n="Przycisk częsci raportu Zmień nazwę|@@reportingPartDisplayButtonRename">Zmień nazwę</button>
      </div>
    </ng-container>
    <div class="col">
      <ng-container *ngIf="partView.part.partName; else generateDefaultTitle">
        <h4>{{ partView.part.partName }}</h4>
      </ng-container>
      <ng-template #generateDefaultTitle>
        <h4>{{ partView.autoLabel }}</h4>
      </ng-template>
    </div>
    <ng-container *ngIf="isEditMode">
      <div class="col-auto">
        <button class="btn btn-primary mr-4" (click)="editPart()" i18n="Przycisk częsci raportu Zmień dane|@@reportingPartDisplayButtonChaneData">Zmień dane</button>
      </div>
      <div class="col-auto">
        <button class="btn btn-danger" (click)="removePart()" i18n="Przycisk częsci raportu Usuń|@@reportingPartDisplayButtonRemove">Usuń</button>
      </div>
      <div class="col-auto handler part-handle">
        <small i18n="Raporty użytkowników uchwyt-sortowania|@@reportinSortHandlerDesctiption">przeciągnij by zmienić kolejność</small>
        <svg>
          <use xlink:href="#icon-move" />
        </svg>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="partView && isChart ; else displayAsScalarValue">
    <div class="row">
      <div class="col">
        <ss-d3-chart-drawer
          [viewBoxHeight]="250"
          [selectedDate]="selectedDate"
          [viewBoxWidth]="secondaryOffsetWidth"
          [config]="partView.chartConfig"
          [data]="partView.chartData"></ss-d3-chart-drawer>
        <!-- <pre style="font-size: 10px">{{ partView|json }}</pre> -->
      </div>
    </div>
  </ng-container>
  <ng-template #displayAsScalarValue>
    <div class="row scalar-value">
      <div class="col">
        <p class="fs-22" *ngIf="partView.scalarData ; else noScalarDataToDisplay">
          {{ partView.scalarData|convertedValue:'value' }}
          {{ partView.scalarData|convertedUnit:'value' }}
        </p>
        <ng-template #noScalarDataToDisplay>
          <p class="fs-22">--</p>
        </ng-template>
      </div>
      <!-- <div class="col-auto" *ngIf="partView.scalarDataSecondaryString">
        <p class="fs-12">{{ partView.scalarDataSecondaryString }}</p>
      </div> -->
    </div>
  </ng-template>

</ss-page-panel>
