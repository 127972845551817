import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { TabComponent } from '../tab.component';

@Component({
  selector: 'ss-notifications-tab',
  templateUrl: './notifications-tab.component.html',
  styleUrls: [],
  providers: [
    {provide: TabComponent, useExisting: forwardRef(() => NotificationsTabComponent), multi: true}
  ]
})
export class NotificationsTabComponent extends TabComponent {

  @Input()
  public context: string[];

  @Input()
  public extraWide: boolean;

  private count = '';
  private level = '';

  public updateCount(ev) {
    this.count = ev;
    this.calc();
  }

  public updateLevel(ev) {
    this.level = ev;
    this.calc();
  }

  public calc() {
    this.hidden = !this.count;
    switch (this.level) {
      case 'alert':
        this.alerts = this.count;
        this.warnings = '';
        this.notifications = '';
        this.noLevelInfos = '';
        break;
      case 'warning':
        this.alerts = '';
        this.warnings = this.count;
        this.notifications = '';
        this.noLevelInfos = '';
        break;
      case 'notification':
        this.alerts = '';
        this.warnings = '';
        this.notifications = this.count;
        this.noLevelInfos = '';
        break;
      default:
        this.alerts = '';
        this.warnings = '';
        this.notifications = '';
        this.noLevelInfos = this.count;
    }
  }

  constructor() {
    super();
    this.position = 'right';
    this.hidden = false;
  }

}
