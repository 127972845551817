import { ConnectionPoint } from './connection-point.model';
import { AbstractDevice } from './abstract-device.model';
import { MeteoDevice } from './meteo-device.interface';
import { ConvertUnit, DeviceSystemType, isArray, isString } from '../../../../../common';

export class SnowCannon extends AbstractDevice implements MeteoDevice {

  get isInLocalMode(): boolean {
    return isArray(this.hardwareWarnings) && this.hardwareWarnings.includes('snow-cannon-in-manual-mode');
  }

  get symbol(): string {
    if (!this.cannonNumber) {
      return this.fallbackSymbol;
    }
    return `${this.cannonNumber}`;
  }
  set symbol(val: string) {
    this.fallbackSymbol = val;
  }

  get symbolAsNumber(): number {
    return parseInt(this.symbol, 10);
  }

  get connectionPointSymbol(): number {
    if (this.conenctionPointRef) {
      return this.conenctionPointRef.symbolAsNumber;
    }
    return 0;
  }

  get canChangePositionOrOscillation(): boolean {
    return !(this.isOscillationOn || this.isVerticalPositioningOn || this.isPositioningHorizontalOn);
  }
  get canChangePosition(): boolean {
    return !(this.isOscillationOn);
  }
  get canChangeOscillation(): boolean {
    return !(this.isVerticalPositioningOn || this.isPositioningHorizontalOn);
  }
  systemType: DeviceSystemType = 'SnowCannon';

  fallbackSymbol: string;

  connectionPoint: string;
  remoteMeteoStation: string;

  cannonOperationStatus: number;

  serialNumber: string;
  plcProgramVersion: number;
  communicationTableVersion: number;
  chassisType: number;
  cannonModel: string;
  hydrantNumber: number;
  cannonNumber: number;

  private _mountType;
  get mountType(): string {
    switch (this.chassisType) {
      case 0:
        return 'chassis';
      case 1:
        return 'wheels';
      case 2:
        return 'st170';
      case 3:
        return 'e400';
      case 4:
        return 'h800';
    }

    //identify chassis by connectionPoint
    return this._mountType; // this as a last resort fallback
  }
  set mountType(v: string) {
    v = v.toLowerCase();
    this._mountType = [
      'chassis',
      'wheels',
      'st170',
      'e400',
      'h800'
    ].includes(v) ? v : 'chassis';
  }

  cannonOperationTime: number;

  isHeatersOn: boolean;
  isRingWarmedUp: boolean;
  isCompressorOn: boolean;
  isFanOn: boolean;
  isHydraulicPumpOn: boolean;
  isWaterNozzleRow1On: boolean;
  isWaterNozzleRow2On: boolean;
  isWaterNozzleRow3On: boolean;
  isWaterNozzleRow4On: boolean;
  isHydrantValveOn: boolean;
  isLampOn: boolean;
  isHydrantConnected: boolean;
  isEmergencyButtonPressed: boolean;
  isPanelActive: boolean;
  isTubeMovingLeft: boolean;
  isTubeMovingRight: boolean;
  isTubeMovingUp: boolean;
  isTubeMovingDown: boolean;
  isOscillationOn: boolean;
  isVerticalPositioningOn: boolean;
  isPositioningHorizontalOn: boolean;

  commandStatusCannonStart: boolean;
  commandStatusCannonStop: boolean;
  commandStatusRingHeating: boolean;
  commandStatusPreparingForWork: boolean;
  commandStatusRemoteCondition: boolean;
  commandStatusLightAutomatic: boolean;
  commandStatusOscillation: boolean;
  commandStatusHorizontalPositioning: boolean;
  commandStatusVerticalPositioning: boolean;

  failurePowerSupplyFault: boolean;
  failureMainFanFault: boolean;
  failureCompressorFault: boolean;
  failureHydraulicsFault: boolean;
  failureRingHeatersFault: boolean;
  failureWaterValvesFault: boolean;
  failureAirTemperatureSensorFault: boolean;
  failureWaterPressureTooLow: boolean;
  failureAirPressureTooLow: boolean;

  warningOscillationFault: boolean;
  warningHorizontalPositioningFault: boolean;
  warningVerticalPositioningFault: boolean;
  warningOilPressureTooLow: boolean;
  warningWaterPressureLow: boolean;
  warningWaterPressureTooHigh: boolean;
  warningWaterPressureSensorFault: boolean;
  warningHydrantDriveMissing: boolean;
  warningRingHeatersContactorFault: boolean;
  warningAirHumiditiSensorFault: boolean;
  warningWaterTemperatureSensorFault: boolean;
  warningEncoderTubeHorizontalFault: boolean;
  warningEncoderTubeVerticalFault: boolean;
  warningEncoderArmFault: boolean;

  waitingForRingWarmUp: boolean;
  delayFanTurnOn: boolean;
  delayFanTurnOff: boolean;
  waitingForWaterPressureHigh: boolean;
  delayCompressorTurnOn: boolean;
  delayCompressorTurnOff: boolean;
  waitingForWaterPressureLow: boolean;
  returningToTubeHorizontalStartAngle: boolean;


  equipmentOilCompressor: boolean;
  equipmentOilFreeCompressor: boolean;
  equipmentCentralCompressor: boolean;
  equipmentEncoders: boolean;
  equipmentInductionSensors: boolean;
  equipmentTubeLinearActuatorElectric: boolean;
  equipmentTubeLinearActuatorManual: boolean;
  equipmentTubeLinearActuatorHydraulic: boolean;

  extendedStatusParameter: number;

  oscillationTargetAngle: number;
  tubeHorizontalCurrentAngle: number;
  tubeHorizontalStartAngle: number;
  tubeVerticalCurrentAngle: number;
  tubeVerticalStartAngle: number;

  oscillationSetAngle: number;
  tubeVerticalSetAngle: number;
  tubeHorizontalSetAngle: number;

  @ConvertUnit('waterFlow', 'l/s')
  currentWaterFlow: number;
  currentAirFlow: number;
  @ConvertUnit('production', 'm3/h')
  currentSnowProductionVolume: number;
  currentWaterPressure: number;
  currrentPowerConsumption: number;

  airHumidity: number;
  airTemperature: number;
  wetBulbTemperature: number;
  canHaveWindDirection = false;

  waterTemperature: number;
  startTemperature: number;
  oilPressure: number;
  maximumWaterFlow: number;
  startWaterFlow: number;
  maximumAirFlow: number;
  maximumWaterPressure: number;
  maximumPowerConsumption: number;

  snowQuality: number;
  snowQualityType: number;
  snowQualityConstant: number;
  snowQualityLow: number;
  snowQualityHigh: number;

  isGpsEnabled: boolean;
  latitude: number;
  longitude: number;
  altitude: number;
  satellites: number;
  gpsStatus: number;
  gpsTime: string;

  isLocked = false;
  remoteMeteoStationRef?: MeteoDevice;
  conenctionPointRef?: ConnectionPoint;

  public CAP_MAIN_FAN = false;
  public CAP_HYDRAULIC_PUMP = false;
  public CAP_BUILTIN_COMPRESSOR = false;
  public CAP_NOZZLE_ROW_1 = false;
  public CAP_NOZZLE_ROW_2 = false;
  public CAP_NOZZLE_ROW_3 = false;
  public CAP_NOZZLE_ROW_4 = false;
  public CAP_RUN_START_STOP_SNOWCANNON = false;
  public CAP_SHOW_WATER_TEMPERATURE = false;
  public CAP_SHOW_SNOW_PRODUCTION = false;
  public CAP_CHANGE_START_TEMPERATURE = false;
  public CAP_CHANGE_START_WATER_FLOW = false;
  public CAP_SHOW_HEATERS_STATE = false;
  public CAP_CHANGE_HEATERS_STATE = false;
  public CAP_SHOW_LAMP_STATE = false;
  public CAP_CHANGE_LAMP_STATE = false;
  public CAP_CHANGE_SNOWQUALITY = false;
  public CAP_SHOW_OSCILLATION_INFO = false;
  public CAP_START_OSCILLATION = false;
  public CAP_STOP_OSCILLATION = false;
  public CAP_CHANGE_OSCILLATION_ANGLE = false;
  public CAP_SHOW_CURRENT_OSCLLATION_POSITION = false;
  public CAP_CHANGE_HORIZONTAL_POSITION_ANGLE = false;
  public CAP_SHOW_CURRENT_HORIZONTAL_POSITION = false;
  public CAP_CHANGE_VERTICAL_POSITION_ANGLE = false;
  public CAP_SHOW_CURRENT_VERTICAL_POSITION = false;
  public CAP_CHANGE_POSITION_MANUALLY = false;
  public CAP_CHANGE_HYDRANT = false;
  public CAP_CHANGE_MAXIMUM_WATER_PRESSURE = false;
  public CAP_CHANGE_MAXIMUM_WATER_FLOW = false;
  public CAP_SET_REMOTE_CONDITIONS = false;
  public CAP_RESET_ALERTS = false;
  public CAP_CAN_SHOW_OVERALL_WORK_TIME = false;

  public CAP_MAXIMUM_WATER_FLOW_VALUE = 10;

  isComputedStatusBreakdown(): boolean {
    return this.computedStatus === 'breakdown' || this.computedStatus === 'connection-lost';
  }

  public calculateCapabilities() {
    const model = isString(this.cannonModel) ? this.cannonModel.toLocaleLowerCase() : null;

    const isSufag = ['sufag'].includes(model);
    const isTechnoAlpin = ['tf10', 'tr8', 'tr10'].includes(model);
    const isLenko = ['fa380', 'fa540', 'fa540whisper', 'titan2.0', 'titans'].includes(model);
    const isBachler = ['bachler'].includes(model);

    this.CAP_NOZZLE_ROW_1 = true;
    this.CAP_NOZZLE_ROW_2 = true;
    this.CAP_NOZZLE_ROW_3 = true;
    this.CAP_NOZZLE_ROW_4 = !isBachler;

    this.CAP_SHOW_WATER_TEMPERATURE = !isBachler;

    this.CAP_RUN_START_STOP_SNOWCANNON = true;
    this.CAP_CHANGE_SNOWQUALITY = true;

    this.CAP_SHOW_HEATERS_STATE = !isBachler;
    this.CAP_CHANGE_HEATERS_STATE = !isBachler;
    this.CAP_RESET_ALERTS = !isBachler && !isTechnoAlpin;

    this.CAP_SHOW_SNOW_PRODUCTION = !isLenko && !isSufag;
    this.CAP_SHOW_LAMP_STATE = !isLenko;
    this.CAP_CHANGE_START_TEMPERATURE = !isLenko;
    this.CAP_CHANGE_MAXIMUM_WATER_PRESSURE = !isLenko;
    this.CAP_CHANGE_START_WATER_FLOW = isLenko;

    this.CAP_CHANGE_OSCILLATION_ANGLE = !isTechnoAlpin && !isBachler;
    this.CAP_SHOW_OSCILLATION_INFO = !isBachler;

    this.CAP_MAIN_FAN = !isBachler;
    this.CAP_HYDRAULIC_PUMP = this.equipmentTubeLinearActuatorHydraulic;
    this.CAP_BUILTIN_COMPRESSOR = !this.equipmentCentralCompressor;

    this.CAP_CHANGE_LAMP_STATE = !isLenko && !isBachler;

    this.CAP_START_OSCILLATION = !isLenko && !isBachler && !isTechnoAlpin;
    this.CAP_STOP_OSCILLATION = !isLenko && !isBachler && !isTechnoAlpin;

    if (!isLenko && !isBachler && !isTechnoAlpin && !isSufag) {
      this.CAP_CHANGE_HORIZONTAL_POSITION_ANGLE = this.equipmentEncoders;
      this.CAP_SHOW_CURRENT_HORIZONTAL_POSITION = this.equipmentEncoders;
      this.CAP_CHANGE_VERTICAL_POSITION_ANGLE = this.equipmentEncoders;
      this.CAP_SHOW_CURRENT_VERTICAL_POSITION = this.equipmentEncoders;
      this.CAP_CHANGE_POSITION_MANUALLY = true;
    }

    if (`${model}`.includes('700')) {
      this.CAP_MAXIMUM_WATER_FLOW_VALUE = 8.3;
    }

    this.CAP_CAN_SHOW_OVERALL_WORK_TIME = !isBachler && !isLenko && !isSufag;
    this.CAP_SET_REMOTE_CONDITIONS = !isTechnoAlpin && !isSufag;
    this.CAP_CHANGE_HYDRANT = !isTechnoAlpin;
    this.CAP_CHANGE_MAXIMUM_WATER_FLOW = true;
  }
}
