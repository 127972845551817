<div class="container" *ngIf="fullSchema">
  <div class="editor" *ngIf="allowEdit">
    <div>
      <div *ngIf="selectedDrawableLine || selectedDrawableConnector || selectedDrawableText || selectedDrawableComponent; else noSelectedComponent">
          <ng-container *ngIf="selectedDrawableLine">
              <h3>Line</h3>
              <div class="row">
                  <div class="col-auto pr-1">
                      <p>From</p>
                      <p>{{ selectedDrawableLine.from | json }}</p>
                  </div>
                  <div class="col-auto pr-1">
                      <p>To</p>
                      <p>{{ selectedDrawableLine.to | json }}</p>
                  </div>
                  <div class="col"></div>
                  <div class="col-auto pt-5">
                      <button class="btn btn-danger" (click)="onRemove(selectedDrawableLine)">Remove</button>
                  </div>
              </div>
          </ng-container>
          <ng-container *ngIf="selectedDrawableConnector">
              <h3>Connector</h3>
              <div class="row">
                  <div class="col-auto pr-1">
                      <p *ngIf="!selectedDrawableConnector.fromComponent">From<br />{{ selectedDrawableConnector.from | json }}</p>
                      <p *ngIf="selectedDrawableConnector.fromComponent">From component<br />{{ selectedDrawableConnector.fromComponent }}</p>
                  </div>
                  <div class="col-auto pr-1">
                      <p *ngIf="!selectedDrawableConnector.toComponent">To<br />{{ selectedDrawableConnector.to | json }}</p>
                      <p *ngIf="selectedDrawableConnector.toComponent">To component<br />{{ selectedDrawableConnector.toComponent }}</p>
                  </div>
                  <div class="col"></div>
                  <div class="col-auto pt-5">
                      <button class="btn btn-danger" (click)="onRemove(selectedDrawableConnector)">Remove</button>
                  </div>
              </div>
          </ng-container>
          <ng-container *ngIf="selectedDrawableText">
              <h3>Text</h3>
              <div class="row">
                  <div class="col-auto pr-1">
                      <p>Label</p>
                      <form-text [(ngModel)]="selectedDrawableText.label" [hideLabel]="true"></form-text>
                  </div>
                  <div class="col-auto pr-1">
                      <p>Position</p>
                      <p>{{ selectedDrawableText.from | json }}</p>
                  </div>
                  <div class="col"></div>
                  <div class="col-auto pt-5">
                      <button class="btn btn-danger" (click)="onRemove(selectedDrawableText)">Remove</button>
                  </div>
              </div>
          </ng-container>
          <ng-container *ngIf="selectedDrawableComponent">
              <h3>{{ selectedDrawableComponent.symbol ? selectedDrawableComponent.symbol : 'New Component' }}</h3>
              <div class="row">
                  <div class="col-auto pr-1">
                      <p>Component symbol</p>
                      <form-select [ngModel]="selectedDrawableComponent.component" (ngModelChange)="selectedComponentChangeComponent($event)" [hideLabel]="true">
                          <form-select-option *ngIf="selectedDrawableComponent.component" [value]="selectedDrawableComponent.component" [label]="selectedDrawableComponent.component.type + ' ' +selectedDrawableComponent.symbol"></form-select-option>
                          <form-select-option *ngFor="let a of availableSymbols" [value]="a" [label]="a.type + ' ' + a.symbol"></form-select-option>
                      </form-select>
                  </div>
                  <div class="col-auto pr-1">
                      <p>Rotation</p>
                      <div class="btn-group mt-2">
                          <button class="btn"
                              (click)="selectedDrawableComponent.rotation = 0"
                              [ngClass]="{
                                  'btn-primary': !selectedDrawableComponent.rotation,
                                  'btn-default': !!selectedDrawableComponent.rotation
                          }">0</button>
                          <button class="btn"
                              (click)="selectedDrawableComponent.rotation = 90"
                              [ngClass]="{
                                  'btn-primary': selectedDrawableComponent.rotation === 90,
                                  'btn-default': selectedDrawableComponent.rotation !== 90
                          }">90</button>
                          <button class="btn"
                              (click)="selectedDrawableComponent.rotation = 180"
                              [ngClass]="{
                                  'btn-primary': selectedDrawableComponent.rotation === 180,
                                  'btn-default': selectedDrawableComponent.rotation !== 180
                          }">180</button>
                          <button class="btn"
                              (click)="selectedDrawableComponent.rotation = 270"
                              [ngClass]="{
                                  'btn-primary': selectedDrawableComponent.rotation === 270,
                                  'btn-default': selectedDrawableComponent.rotation !== 270
                          }">270</button>
                      </div>
                  </div>
                  <div class="col"></div>
                  <div class="col-auto pt-5">
                      <button class="btn btn-danger" (click)="onRemove(selectedDrawableComponent)">Remove</button>
                  </div>
              </div>
          </ng-container>
      </div>
      <ng-template #noSelectedComponent>
        <div>
          <h3>Edit schema area</h3>
          <div class="row align-items-center">
            <div class="col-auto pr-1">Add element</div>
            <div class="col-auto pr-1">
              <button class="btn btn-default"
              [disabled]="adding"
              (click)="addLine()">Line <kbd>Ctrl+1</kbd></button></div>
            <div class="col-auto pr-1">
              <button class="btn btn-default"
              [disabled]="adding"
              (click)="addText()">Text <kbd>Ctrl+2</kbd></button></div>
            <div class="col-auto pr-1">
              <button class="btn btn-default"
              [disabled]="adding"
              (click)="addConnection()">Connection <kbd>Ctrl+3</kbd></button></div>
            <div class="col-auto pr-5">
              <button class="btn btn-default"
              [disabled]="adding"
              (click)="addComponent()">Component <kbd>Ctrl+4</kbd></button></div>
            <div class="col-auto pr-1">
              <p>Width</p>
              <form-range [(ngModel)]="w" [min]="1860" [step]="10" [max]="4000" [hideLabel]="true"></form-range>
            </div>
            <div class="col-auto pr-1">
              <p>Height</p>
              <form-range [(ngModel)]="h" [min]="120" [step]="10" [max]="4000" [hideLabel]="true"></form-range>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    </div>

    <div class="grouper">
      <button class="btn btn-link contrast-change-btn" (click)="switchContrast()">
        <svg>
          <use xlink:href="#icon-contrast" />
        </svg>
        &nbsp;
        <ng-container *ngIf="isDark" i18n="Zmień na białe tło|Etykieta przycisku zmiany koloru schematu maszynowni@@machineRoomFullSchemaContrastToWhiteButton">Zmień na białe tło</ng-container>
        <ng-container *ngIf="!isDark" i18n="Zmień na ciemne tło|Etykieta przycisku zmiany koloru schematu maszynowni@@machineRoomFullSchemaContrastToDarkButton">Zmień na ciemne tło</ng-container>
      </button>

      <perfect-scrollbar>

        <svg:svg xmlns="http://www.w3.org/2000/svg" version="1.1"
          class="drawing-board"
          [attr.viewBox]="'0 0 ' + w + ' ' + h"
          [style.width]="w + 'px'"
          [style.height]="h + 'px'"
          (click)="onClick($event)"
          (mousedown)="onMouseDown($event)"
          (mousemove)="onMouseMove($event)"
          (mouseup)="onMouseUp($event)"
          [ngClass]="{
              dark: isDark,
              adding: adding
          }">
          <svg:rect x="1" y="1" [attr.width]="w - 2" [attr.height]="h - 2" class="border" (click)="onSelected()"></svg:rect>

          <ng-container *ngFor="let a of pumpRoom.configuration.fullSchema.all">
            <svg:line ss-full-schema-line [allowEdit]="allowEdit" (remove)="onRemove($event)" (selected)="onSelected($event)"
              *ngIf="a.type === 'line'"
              [drawable]="a"></svg:line>
            <svg:text ss-full-schema-text [allowEdit]="allowEdit" (remove)="onRemove($event)" (selected)="onSelected($event)"
              *ngIf="a.type === 'text'"
              [drawable]="a"></svg:text>
            <svg:line ss-full-schema-connector [allowEdit]="allowEdit" (remove)="onRemove($event)" (selected)="onSelected($event)"
              *ngIf="a.type === 'connector'"
              [drawable]="a"></svg:line>
            <svg:g ss-full-schema-drawable [allowEdit]="allowEdit" (selected)="onSelected($event)"
              *ngIf="a.type === 'component'"
              [drawable]="a"></svg:g>
          </ng-container>

          <svg:rect
            [attr.x]="selectX"
            [attr.y]="selectY"
            [attr.width]="selectW"
            [attr.height]="selectH"
            (mousedown)="selectionOnMouseDown($event)"
            (mousemove)="selectionOnMouseMove($event)"
            (mouseup)="selectionOnMouseUp($event)"
            class="selection"></svg:rect>

          <svg:defs>
            <symbol id="icon-exclamation" width="101" height="101" viewBox="0 0 512.001 512.001">
              <path d="M503.839,395.379l-195.7-338.962C297.257,37.569,277.766,26.315,256,26.315c-21.765,0-41.257,11.254-52.139,30.102 L8.162,395.378c-10.883,18.85-10.883,41.356,0,60.205c10.883,18.849,30.373,30.102,52.139,30.102h391.398 c21.765,0,41.256-11.254,52.14-30.101C514.722,436.734,514.722,414.228,503.839,395.379z M477.861,440.586 c-5.461,9.458-15.241,15.104-26.162,15.104H60.301c-10.922,0-20.702-5.646-26.162-15.104c-5.46-9.458-5.46-20.75,0-30.208 L229.84,71.416c5.46-9.458,15.24-15.104,26.161-15.104c10.92,0,20.701,5.646,26.161,15.104l195.7,338.962 C483.321,419.836,483.321,431.128,477.861,440.586z"/>
              <rect x="241.001" y="176.01" width="29.996" height="149.982"/>
              <path d="M256,355.99c-11.027,0-19.998,8.971-19.998,19.998s8.971,19.998,19.998,19.998c11.026,0,19.998-8.971,19.998-19.998 S267.027,355.99,256,355.99z"/>
            </symbol>

              <symbol viewBox="-15 0 84 84" width="101" height="101" id="acutated-valve">
                  <rect x="11" y="15" width="31" height="64" fill="currentColor" class="fill"></rect>
                  <path d="m 14.09375,33.996094 a 0.48004802,0.48004802 0 0 0 -0.490234,0.480468 v 14.326172 a 0.48004802,0.48004802 0 0 0 0.107422,0.384766 0.48004802,0.48004802 0 0 0 0.0332,0.03516 0.48004802,0.48004802 0 0 0 0.03516,0.0332 0.48004802,0.48004802 0 0 0 0.03906,0.02734 0.48004802,0.48004802 0 0 0 0.01758,0.01172 0.48004802,0.48004802 0 0 0 0.0332,0.01758 0.48004802,0.48004802 0 0 0 0.01758,0.0078 0.48004802,0.48004802 0 0 0 0.01563,0.0078 0.48004802,0.48004802 0 0 0 0.01758,0.0059 0.48004802,0.48004802 0 0 0 0.04687,0.01367 0.48004802,0.48004802 0 0 0 0.414062,-0.08984 l 12.421875,-7.166015 a 0.48004802,0.48004802 0 0 0 0,-0.830078 L 14.322266,34.060547 a 0.48004802,0.48004802 0 0 0 -0.228516,-0.06445 z m 0.46875,1.310547 11.041016,6.36914 -11.041016,6.371094 z"/>
                  <path d="m 39.029297,33.996094 a 0.48004802,0.48004802 0 0 0 -0.226563,0.06445 l -12.480468,7.201172 a 0.48004802,0.48004802 0 0 0 0,0.830078 l 12.417968,7.164062 0.0059,0.0039 a 0.48004802,0.48004802 0 0 0 0.380859,0.09375 0.48004802,0.48004802 0 0 0 0.04687,-0.0098 0.48004802,0.48004802 0 0 0 0.04492,-0.01563 0.48004802,0.48004802 0 0 0 0.01758,-0.0059 0.48004802,0.48004802 0 0 0 0.0098,-0.0039 0.48004802,0.48004802 0 0 0 0.0332,-0.01758 0.48004802,0.48004802 0 0 0 0.02539,-0.01563 0.48004802,0.48004802 0 0 0 0.01562,-0.0098 0.48004802,0.48004802 0 0 0 0.02344,-0.01758 0.48004802,0.48004802 0 0 0 0.01367,-0.01172 0.48004802,0.48004802 0 0 0 0.03516,-0.0332 0.48004802,0.48004802 0 0 0 0.13086,-0.400391 V 34.476562 a 0.48004802,0.48004802 0 0 0 -0.494141,-0.480468 z m -0.466797,1.3125 V 48.044922 L 27.521484,41.675781 Z"/>
                  <path d="m 26.556641,30.470703 a 0.48004802,0.48004802 0 0 0 -0.47461,0.486328 v 10.71875 a 0.480469,0.480469 0 1 0 0.960938,0 v -10.71875 a 0.48004802,0.48004802 0 0 0 -0.486328,-0.486328 z"/>
                  <path d="m 41.916016,33.990234 a 0.48004802,0.48004802 0 0 0 -0.07227,0.0059 H 39.042969 A 0.48004802,0.48004802 0 0 0 38.5625,34.476562 v 14.400391 a 0.48004802,0.48004802 0 0 0 0.480469,0.480469 h 2.880859 a 0.48004802,0.48004802 0 0 0 0.478516,-0.480469 v -14.3125 a 0.48004802,0.48004802 0 0 0 -0.107422,-0.398437 0.48004802,0.48004802 0 0 0 -0.002,-0.0039 0.48004802,0.48004802 0 0 0 -0.03125,-0.03125 0.48004802,0.48004802 0 0 0 -0.02148,-0.02148 0.48004802,0.48004802 0 0 0 -0.01367,-0.01172 0.48004802,0.48004802 0 0 0 -0.0039,-0.002 0.48004802,0.48004802 0 0 0 -0.03516,-0.02539 0.48004802,0.48004802 0 0 0 -0.0039,-0.002 0.48004802,0.48004802 0 0 0 -0.267578,-0.07813 z m -2.392578,0.966797 h 1.919921 v 13.439453 h -1.919921 z"/>
                  <path d="m 14.076172,33.990234 a 0.48004802,0.48004802 0 0 0 -0.07227,0.0059 h -2.800781 a 0.48004802,0.48004802 0 0 0 -0.480469,0.480468 v 14.400391 a 0.48004802,0.48004802 0 0 0 0.480469,0.480469 h 2.878906 A 0.48004802,0.48004802 0 0 0 14.5625,48.876953 v -14.3125 a 0.48004802,0.48004802 0 0 0 -0.107422,-0.398437 0.48004802,0.48004802 0 0 0 -0.002,-0.0039 0.48004802,0.48004802 0 0 0 -0.03125,-0.03125 0.48004802,0.48004802 0 0 0 -0.02148,-0.02148 0.48004802,0.48004802 0 0 0 -0.01367,-0.01172 0.48004802,0.48004802 0 0 0 -0.0039,-0.002 0.48004802,0.48004802 0 0 0 -0.03516,-0.02539 0.48004802,0.48004802 0 0 0 -0.0039,-0.002 0.48004802,0.48004802 0 0 0 -0.267578,-0.07813 z m -2.392578,0.966797 h 1.919922 v 13.439453 h -1.919922 z"/>
                  <g transform="matrix(0,-0.16,-0.16,0,26.642973,24.956626)">
                      <path d="m 0,-40.5 c -22.332,0 -40.5,18.168 -40.5,40.5 0,22.332 18.168,40.5 40.5,40.5 22.108611,0 40.075145,-17.822905 40.433594,-39.84765625 A 3.0003,3.0003 0 0 0 40.5,0 3.0003,3.0003 0 0 0 40.433594,-0.66601562 C 40.067815,-22.684382 22.103977,-40.5 0,-40.5 Z m 0,6 c 19.08936,0 34.5,15.41064 34.5,34.5 0,19.08936 -15.41064,34.5 -34.5,34.5 -19.08936,0 -34.5,-15.41064 -34.5,-34.5 0,-19.08936 15.41064,-34.5 34.5,-34.5 z"/>
                  </g>
              </symbol>
              <symbol viewBox="-15 0 84 84" width="101" height="101" id="auto-bleed-valve">
                  <rect x="15" y="30" width="20" height="22" fill="currentColor" class="fill"></rect>
                  <g transform="matrix(0,0.16,0.16,0,-171.75358,-107.52)">
                      <path d="m 937,1328 -3,27 v -27 z m -3,27 v -27 h -3 z" />
                  </g>
                  <path d="m 40.732422,40.960938 a 0.48004802,0.48004802 0 0 0 -0.486328,0.478515 v 0.960938 a 0.4804685,0.4804685 0 1 0 0.960937,0 v -0.423829 l 3.78711,0.419922 a 0.48004802,0.48004802 0 1 0 0.105468,-0.953125 l -4.320312,-0.480468 a 0.48004802,0.48004802 0 0 0 -0.04687,-0.002 z" />
                  <path d="m 25.994141,37.117188 a 0.48004802,0.48004802 0 0 0 -0.205079,0.910156 l 8.478516,4.320312 a 0.48004802,0.48004802 0 0 0 0.21875,0.05273 h 6.240234 a 0.480469,0.480469 0 1 0 0,-0.960938 h -6.125 l -8.376953,-4.267578 a 0.48004802,0.48004802 0 0 0 -0.230468,-0.05469 z" />
                  <path d="m 34.484375,41.4375 a 0.48004802,0.48004802 0 0 0 -0.216797,0.05469 L 25.789062,45.8125 a 0.48004802,0.48004802 0 1 0 0.435547,0.855469 l 8.480469,-4.320313 A 0.48004802,0.48004802 0 0 0 34.484375,41.4375 Z" />
                  <path d="m 25.207031,37.119141 a 0.4804685,0.4804685 0 1 0 0,0.960937 h 1.759766 a 0.4804685,0.4804685 0 1 0 0,-0.960937 z" />
                  <path d="m 25.207031,45.759766 a 0.4804685,0.4804685 0 1 0 0,0.960937 h 1.759766 a 0.4804685,0.4804685 0 1 0 0,-0.960937 z" />
                  <path d="m 26,37.113281 a 0.48004802,0.48004802 0 0 0 -0.472656,0.486328 v 8.640625 a 0.48004802,0.48004802 0 1 0 0.958984,0 V 37.599609 A 0.48004802,0.48004802 0 0 0 26,37.113281 Z" />
                  <path d="m 15.925781,37.119141 a 0.48004802,0.48004802 0 0 0 -0.478515,0.480468 v 8.640625 a 0.48004802,0.48004802 0 0 0 0.478515,0.480469 h 9.28125 9.279297 a 0.48004802,0.48004802 0 0 0 0.480469,-0.480469 v -8.640625 a 0.48004802,0.48004802 0 0 0 -0.480469,-0.480468 h -9.279297 z m 0.480469,0.960937 h 8.800781 8.798828 v 7.679688 h -8.765625 a 0.48004802,0.48004802 0 0 0 -0.0332,0 H 16.40625 Z" />
                  <path d="m 8.0859375,41.439453 a 0.480469,0.480469 0 1 0 0,0.960938 h 7.8398435 a 0.480469,0.480469 0 1 0 0,-0.960938 z" />
              </symbol>
              <symbol viewBox="-15 0 84 84" width="101" height="101" id="auto-filter">
                  <rect x="4" y="25" width="45" height="50" fill="currentColor" class="fill"></rect>
                  <path d="M 8.6933594,31.117188 A 0.48004802,0.48004802 0 0 0 8.25,31.673828 v 20.566406 a 0.48004802,0.48004802 0 0 0 0.4804688,0.480469 h 35.8398432 4 a 0.48004802,0.48004802 0 0 0 0.480469,-0.480469 V 31.599609 a 0.48004802,0.48004802 0 0 0 -0.480469,-0.480468 h -4 -35.804687 a 0.48004802,0.48004802 0 0 0 -0.072266,-0.002 z m 1.8320316,0.96289 h 33.564453 v 19.330078 z m 34.52539,0 h 3.039063 V 51.759766 H 45.050781 Z M 9.2089844,32.429688 42.773438,51.759766 H 9.2089844 Z" />
                  <path d="m 4.5703125,31.119141 a 0.48004802,0.48004802 0 0 0 -0.4804687,0.480468 v 20.564453 a 0.48004802,0.48004802 0 0 0 0.109375,0.386719 0.48004802,0.48004802 0 0 0 0.027344,0.03125 0.48004802,0.48004802 0 0 0 0.00586,0.0059 0.48004802,0.48004802 0 0 0 0.00586,0.0059 0.48004802,0.48004802 0 0 0 0.027344,0.02539 0.48004802,0.48004802 0 0 0 0.00391,0.002 0.48004802,0.48004802 0 0 0 0.375,0.09961 H 8.7304688 A 0.48004802,0.48004802 0 0 0 9.2089844,52.240234 V 31.599609 A 0.48004802,0.48004802 0 0 0 8.7304688,31.119141 Z m 0.4804687,0.960937 H 8.25 V 51.759766 H 5.0507812 Z" />
                  <path d="m 40.5625,27.113281 a 0.48004802,0.48004802 0 0 0 -0.472656,0.486328 v 4 a 0.4804685,0.4804685 0 1 0 0.960937,0 v -4 A 0.48004802,0.48004802 0 0 0 40.5625,27.113281 Z" />
                  <g transform="rotate(-90)">
                      <path d="m -49.038807,12.538573 h 3.420069 v 0.677636 h -2.614878 v 1.753881 h 2.359768 v 0.677636 h -2.359768 v 2.842085 h -0.805191 z" />
                  </g>
              </symbol>
              <symbol viewBox="-15 0 84 84" width="101" height="101" id="check-valve">
                  <rect x="11" y="15" width="31" height="64" fill="currentColor" class="fill"></rect>
                  <path d="m 39.042969,34.232422 a 0.48004802,0.48004802 0 0 0 -0.472657,0.488281 v 14.398438 a 0.48004802,0.48004802 0 1 0 0.958985,0 V 34.720703 a 0.48004802,0.48004802 0 0 0 -0.486328,-0.488281 z" />
                  <path d="m 26.56983,41.92002 12.48,7.2 v -14.4 z" />
                  <path d="m 39.072266,34.236328 a 0.48004802,0.48004802 0 0 0 -0.261719,0.06836 l -12.480469,7.199218 a 0.48004802,0.48004802 0 0 0 0,0.832032 l 12.480469,7.199218 a 0.48004802,0.48004802 0 1 0 0.478515,-0.830078 L 27.53125,41.919922 39.289062,35.136719 a 0.48004802,0.48004802 0 0 0 -0.216796,-0.900391 z" />
                  <path d="m 14.082031,34.232422 a 0.48004802,0.48004802 0 0 0 -0.07617,0.0098 0.48004802,0.48004802 0 0 0 -0.0918,0.02539 0.48004802,0.48004802 0 0 0 -0.04492,0.01953 0.48004802,0.48004802 0 0 0 -0.03906,0.02148 0.48004802,0.48004802 0 0 0 -0.002,0.002 0.48004802,0.48004802 0 0 0 -0.02344,0.01758 0.48004802,0.48004802 0 0 0 -0.195313,0.457031 v 14.333985 a 0.48004802,0.48004802 0 0 0 0.720703,0.416015 l 12.480469,-7.199218 a 0.48004802,0.48004802 0 0 0 0,-0.832032 L 14.392578,34.341797 a 0.48004802,0.48004802 0 0 0 -0.0059,-0.0039 0.48004802,0.48004802 0 0 0 -0.304688,-0.105469 z m 0.488281,1.320312 11.039063,6.367188 -11.039063,6.36914 z" />
                  <path d="m 11.208984,34.240234 a 0.48004802,0.48004802 0 0 0 -0.478515,0.480469 V 49.03125 a 0.48004802,0.48004802 0 0 0 0.107422,0.400391 0.48004802,0.48004802 0 0 0 0.002,0.0039 0.48004802,0.48004802 0 0 0 0.03125,0.03125 0.48004802,0.48004802 0 0 0 0.0059,0.0059 0.48004802,0.48004802 0 0 0 0.0293,0.02539 0.48004802,0.48004802 0 0 0 0.0039,0.002 0.48004802,0.48004802 0 0 0 0.378906,0.09961 h 2.800782 a 0.48004802,0.48004802 0 0 0 0.480468,-0.480468 V 34.720703 a 0.48004802,0.48004802 0 0 0 -0.480468,-0.480469 z m 0.480469,0.958985 h 1.919922 v 13.441406 h -1.919922 z" />
                  <path d="m 39.050781,34.240234 a 0.48004802,0.48004802 0 0 0 -0.480469,0.480469 V 49.03125 a 0.48004802,0.48004802 0 0 0 0.107422,0.400391 0.48004802,0.48004802 0 0 0 0.002,0.0039 0.48004802,0.48004802 0 0 0 0.03125,0.03125 0.48004802,0.48004802 0 0 0 0.0059,0.0059 0.48004802,0.48004802 0 0 0 0.0293,0.02539 0.48004802,0.48004802 0 0 0 0.0039,0.002 0.48004802,0.48004802 0 0 0 0.378906,0.09961 h 2.800782 a 0.48004802,0.48004802 0 0 0 0.480468,-0.480468 V 34.720703 a 0.48004802,0.48004802 0 0 0 -0.480468,-0.480469 z m 0.478516,0.958985 h 1.919922 v 13.441406 h -1.919922 z" />
              </symbol>
              <symbol viewBox="-15 0 84 84" width="101" height="101" id="compressor">
                  <rect x="0" y="9" width="53" height="53" fill="currentColor" class="fill"></rect>
                  <path d="m 26.726562,30.160156 a 0.48004799,0.48004799 0 0 0 -0.107421,0.01172 c -7.233758,0.0594 -13.091797,5.939855 -13.091797,13.1875 0,7.284473 5.914745,13.201172 13.199218,13.201172 7.284474,0 13.199219,-5.916699 13.199219,-13.201172 0,-7.249037 -5.860027,-13.132286 -13.095703,-13.189453 a 0.48004799,0.48004799 0 0 0 -0.103516,-0.0098 z m 0,0.958985 c 6.765651,0 12.240235,5.474583 12.240235,12.240234 0,6.765651 -5.474584,12.240234 -12.240235,12.240234 -6.76565,0 -12.240234,-5.474583 -12.240234,-12.240234 0,-6.765651 5.474584,-12.240234 12.240234,-12.240234 z" />
                  <path d="m 10.966797,39.919922 a 0.48004802,0.48004802 0 0 0 -0.480469,0.480469 v 5.84375 a 0.48004802,0.48004802 0 0 0 0.109375,0.386718 0.48004802,0.48004802 0 0 0 0.02734,0.03125 0.48004802,0.48004802 0 0 0 0.0059,0.0059 0.48004802,0.48004802 0 0 0 0.0059,0.0059 0.48004802,0.48004802 0 0 0 0.02734,0.02539 0.48004802,0.48004802 0 0 0 0.0039,0.002 0.48004802,0.48004802 0 0 0 0.367187,0.09961 h 2.8125 a 0.48004802,0.48004802 0 0 0 0.480469,-0.480469 v -5.919921 a 0.48004802,0.48004802 0 0 0 -0.480469,-0.480469 z m 0.480469,0.960937 h 1.919922 v 4.958985 h -1.919922 z" />
                  <path d="m 23.605469,27.279297 a 0.48004802,0.48004802 0 0 0 -0.478516,0.480469 v 2.951172 a 0.48004802,0.48004802 0 0 0 0.107422,0.40039 0.48004802,0.48004802 0 0 0 0.002,0.0039 0.48004802,0.48004802 0 0 0 0.03125,0.03125 0.48004802,0.48004802 0 0 0 0.0059,0.0059 0.48004802,0.48004802 0 0 0 0.0293,0.02539 0.48004802,0.48004802 0 0 0 0.0039,0.002 0.48004802,0.48004802 0 0 0 0.378906,0.09961 h 5.841797 a 0.48004802,0.48004802 0 0 0 0.478515,-0.478516 v -3.041015 a 0.48004802,0.48004802 0 0 0 -0.478515,-0.480469 z m 0.480469,0.960937 h 4.960937 v 2.080078 h -4.960937 z" />
                  <path d="m 39.287109,39.919922 a 0.48004802,0.48004802 0 0 0 -0.480468,0.480469 v 5.919921 a 0.48004802,0.48004802 0 0 0 0.480468,0.480469 h 2.806641 a 0.48004802,0.48004802 0 0 0 0.345703,-0.08008 0.48004802,0.48004802 0 0 0 0.0039,-0.002 0.48004802,0.48004802 0 0 0 0.0332,-0.02734 0.48004802,0.48004802 0 0 0 0.0039,-0.002 0.48004802,0.48004802 0 0 0 0.01367,-0.01172 0.48004802,0.48004802 0 0 0 0.02148,-0.02148 0.48004802,0.48004802 0 0 0 0.0293,-0.0332 0.48004802,0.48004802 0 0 0 0.002,-0.0039 0.48004802,0.48004802 0 0 0 0.09961,-0.376953 v -5.841797 a 0.48004802,0.48004802 0 0 0 -0.480468,-0.480469 z m 0.478516,0.960937 h 1.919922 v 4.958985 h -1.919922 z" />
                  <path d="m 26.726562,36.240234 a 0.48004799,0.48004799 0 0 0 -0.199218,0.04102 c -5.419768,0.112821 -9.800782,4.512498 -9.800782,9.958984 0,5.517162 4.482839,10 10,10 5.517162,0 10.000001,-4.482838 10,-10 0,-5.446486 -4.381013,-9.846163 -9.800781,-9.958984 a 0.48004799,0.48004799 0 0 0 -0.199219,-0.04102 z m 0,0.958985 c 4.998339,0 9.039063,4.042677 9.039063,9.041015 0,4.998339 -4.040724,9.039063 -9.039063,9.039063 -4.998338,0 -9.041015,-4.040724 -9.041015,-9.039063 0,-4.998338 4.042677,-9.041015 9.041015,-9.041015 z" />
                  <path d="m 26.726562,43.599609 a 0.48004799,0.48004799 0 0 0 -0.349609,0.146485 c -1.263705,0.194593 -2.291016,1.178063 -2.291015,2.49414 -10e-7,1.452346 1.188279,2.640625 2.640624,2.640625 1.452346,0 2.640625,-1.188279 2.640626,-2.640625 0,-1.316346 -1.026935,-2.301831 -2.291016,-2.496093 a 0.48004799,0.48004799 0 0 0 -0.34961,-0.144532 z m 0,0.960938 c 0.933523,0 1.679688,0.746165 1.679688,1.679687 0,0.933523 -0.746165,1.679688 -1.679688,1.679688 -0.933522,0 -1.679687,-0.746165 -1.679687,-1.679688 0,-0.933522 0.746165,-1.679687 1.679687,-1.679687 z" />
              </symbol>
              <symbol viewBox="-15 0 84 84" width="101" height="101" id="emergency-dump">
                  <rect x="0" y="25" width="45" height="35" fill="currentColor" class="fill"></rect>
                  <g transform="translate(-626.09317,-222.67264)">
                      <path d="m 641.38281,263.87305 a 0.64006399,0.64006399 0 1 0 -0.006,1.27929 l 33.43946,0.16016 a 0.64006399,0.64006399 0 1 0 0.006,-1.2793 z" />
                      <path d="m 651.92969,266.26367 a 0.64006399,0.64006399 0 0 0 -0.63086,0.64844 v 4.48047 a 0.640625,0.640625 0 1 0 1.28125,0 v -4.48047 a 0.64006399,0.64006399 0 0 0 -0.65039,-0.64844 z" />
                      <path d="m 651.92969,257.14453 a 0.64006399,0.64006399 0 0 0 -0.63086,0.64844 v 4.64062 a 0.640625,0.640625 0 1 0 1.28125,0 v -4.64062 a 0.64006399,0.64006399 0 0 0 -0.65039,-0.64844 z" />
                      <path d="m 641.87891,262.66992 -0.5586,0.0664 -10.8789,1.27929 0.0586,0.9961 h 0.16016 10.71875 0.5 z m -1,1.125 v 0.2168 h -1.84571 z" />
                      <path d="m 630.5,264.01172 v 1 h 10.87891 v -1 z" />
                      <path d="m 630.66016,264.01172 -0.0664,0.99609 11.28516,1.51563 v -2.51172 z m 7.46875,1 h 2.75 v 0.36914 z" />
                      <path d="m 641.36133,262.59375 a 0.64006399,0.64006399 0 0 0 -0.0566,0.004 l -10.84766,1.27539 -0.0332,0.004 a 0.64006399,0.64006399 0 0 0 -0.0625,0.01 0.64006399,0.64006399 0 0 0 -0.0625,0.0156 0.64006399,0.64006399 0 0 0 -0.17188,0.084 0.64006399,0.64006399 0 0 0 -0.0508,0.0391 0.64006399,0.64006399 0 0 0 -0.0391,0.0352 0.64006399,0.64006399 0 0 0 -0.0156,0.0156 0.64006399,0.64006399 0 0 0 -0.0137,0.0156 0.64006399,0.64006399 0 0 0 -0.0195,0.0254 0.64006399,0.64006399 0 0 0 -0.0371,0.0527 0.64006399,0.64006399 0 0 0 -0.0312,0.0547 0.64006399,0.64006399 0 0 0 -0.0254,0.0527 0.64006399,0.64006399 0 0 0 -0.008,0.0254 0.64006399,0.64006399 0 0 0 -0.006,0.0195 0.64006399,0.64006399 0 0 0 -0.01,0.0312 0.64006399,0.64006399 0 0 0 -0.01,0.041 0.64006399,0.64006399 0 0 0 -0.006,0.0391 0.64006399,0.64006399 0 0 0 0,0.008 0.64006399,0.64006399 0 0 0 -0.004,0.0371 0.64006399,0.64006399 0 0 0 0,0.0527 0.64006399,0.64006399 0 0 0 0,0.004 0.64006399,0.64006399 0 0 0 0.002,0.0293 0.64006399,0.64006399 0 0 0 0.008,0.0645 0.64006399,0.64006399 0 0 0 0.0156,0.0625 0.64006399,0.64006399 0 0 0 0.0195,0.0566 0.64006399,0.64006399 0 0 0 0.002,0.006 0.64006399,0.64006399 0 0 0 0.0215,0.0449 0.64006399,0.64006399 0 0 0 0.008,0.0156 0.64006399,0.64006399 0 0 0 0.0293,0.0488 0.64006399,0.64006399 0 0 0 0.0371,0.0527 0.64006399,0.64006399 0 0 0 0.043,0.0469 0.64006399,0.64006399 0 0 0 0.0469,0.043 0.64006399,0.64006399 0 0 0 0.0527,0.0391 0.64006399,0.64006399 0 0 0 0.17187,0.0801 0.64006399,0.64006399 0 0 0 0.0625,0.0156 0.64006399,0.64006399 0 0 0 0.084,0.0117 0.64006399,0.64006399 0 0 0 0.0117,0 l 10.82812,1.43555 a 0.64006399,0.64006399 0 0 0 0.72461,-0.63477 v -2.7207 a 0.64006399,0.64006399 0 0 0 -0.6582,-0.63867 z m -0.6211,1.35937 v 1.26954 l -5.08007,-0.67188 z" />
                  </g>
              </symbol>
              <symbol viewBox="-15 0 84 84" width="101" height="101" id="em-flow-sensor">
                  <rect x="13" y="15" width="27" height="44" fill="currentColor" class="fill"></rect>
                  <path d="m 36.962891,32.392578 a 0.48004802,0.48004802 0 0 0 -0.296875,0.109375 0.48004802,0.48004802 0 0 0 -0.0078,0.0059 0.48004802,0.48004802 0 0 0 -0.0039,0.0039 0.48004802,0.48004802 0 0 0 -0.03125,0.0293 0.48004802,0.48004802 0 0 0 -0.002,0.0039 0.48004802,0.48004802 0 0 0 -0.0293,0.03125 0.48004802,0.48004802 0 0 0 -0.002,0.0039 0.48004802,0.48004802 0 0 0 -0.09961,0.378906 v 17.201172 a 0.48004802,0.48004802 0 0 0 0.480469,0.480469 h 3.359375 a 0.48004802,0.48004802 0 0 0 0.480469,-0.480469 V 32.880859 a 0.48004802,0.48004802 0 0 0 -0.480469,-0.480468 h -3.283203 a 0.48004802,0.48004802 0 0 0 -0.08398,-0.0078 z m 0.486328,0.966797 h 2.40039 v 16.320313 h -2.40039 z" />
                  <path d="m 35.205078,32.394531 a 0.48004802,0.48004802 0 0 0 -0.06445,0.0059 H 18.089844 A 0.48004802,0.48004802 0 0 0 17.75,32.541016 l -1.759766,1.759765 a 0.48004802,0.48004802 0 0 0 -0.140625,0.339844 v 13.759766 a 0.48004802,0.48004802 0 0 0 0.140625,0.339843 L 17.75,50.5 a 0.48004802,0.48004802 0 0 0 0.339844,0.140625 h 17.11914 A 0.48004802,0.48004802 0 0 0 35.548828,50.5 l 1.759766,-1.759766 a 0.48004802,0.48004802 0 0 0 0.140625,-0.339843 V 34.640625 a 0.48004802,0.48004802 0 0 0 -0.140625,-0.339844 l -1.699219,-1.697265 a 0.48004802,0.48004802 0 0 0 -0.0078,-0.0098 0.48004802,0.48004802 0 0 0 -0.162109,-0.136719 0.48004802,0.48004802 0 0 0 -0.0078,-0.0059 0.48004802,0.48004802 0 0 0 -0.0039,-0.002 0.48004802,0.48004802 0 0 0 -0.04102,-0.01758 0.48004802,0.48004802 0 0 0 -0.0039,-0.002 0.48004802,0.48004802 0 0 0 -0.0039,-0.002 0.48004802,0.48004802 0 0 0 -0.173828,-0.0332 z m -16.916016,0.964844 h 16.722657 l 1.478515,1.480469 v 13.361328 l -1.478515,1.478516 H 18.289062 L 16.810547,48.201172 V 34.839844 Z" />
                  <path d="m 12.802734,32.392578 a 0.48004802,0.48004802 0 0 0 -0.296875,0.109375 0.48004802,0.48004802 0 0 0 -0.0078,0.0059 0.48004802,0.48004802 0 0 0 -0.0039,0.0039 0.48004802,0.48004802 0 0 0 -0.03125,0.0293 0.48004802,0.48004802 0 0 0 -0.002,0.0039 0.48004802,0.48004802 0 0 0 -0.0293,0.03125 0.48004802,0.48004802 0 0 0 -0.002,0.0039 0.48004802,0.48004802 0 0 0 -0.09961,0.378906 v 17.201172 a 0.48004802,0.48004802 0 0 0 0.480469,0.480469 h 3.519531 a 0.48004802,0.48004802 0 0 0 0.480469,-0.480469 V 32.880859 a 0.48004802,0.48004802 0 0 0 -0.480469,-0.480468 h -3.443359 a 0.48004802,0.48004802 0 0 0 -0.08399,-0.0078 z m 0.486328,0.966797 h 2.560547 v 16.320313 h -2.560547 z" />
                  <path d="m 22.410156,30.640625 a 0.48004802,0.48004802 0 0 0 -0.480468,0.478516 v 5.28125 a 0.48004802,0.48004802 0 0 0 0.480468,0.480468 h 8.40625 a 0.48004802,0.48004802 0 0 0 0.345703,-0.08008 0.48004802,0.48004802 0 0 0 0.0039,-0.002 0.48004802,0.48004802 0 0 0 0.03516,-0.02734 0.48004802,0.48004802 0 0 0 0.0039,-0.002 0.48004802,0.48004802 0 0 0 0.03125,-0.0293 0.48004802,0.48004802 0 0 0 0.002,-0.0039 0.48004802,0.48004802 0 0 0 0.0293,-0.0332 0.48004802,0.48004802 0 0 0 0.002,-0.0039 0.48004802,0.48004802 0 0 0 0.09961,-0.378907 v -5.201171 a 0.48004802,0.48004802 0 0 0 -0.478516,-0.478516 z m 0.480469,0.958984 h 7.519531 v 4.320313 h -7.519531 z" />
                  <path d="m 26.650391,5.1992188 a 0.48004799,0.48004799 0 0 0 -0.09375,0.00977 0.48004799,0.48004799 0 0 0 -0.002,0 c -7.239363,0.052878 -13.105469,5.9396936 -13.105469,13.1914066 0,7.284473 5.916698,13.199218 13.201172,13.199218 7.284473,0 13.199218,-5.914745 13.199218,-13.199218 0,-7.249702 -5.861064,-13.1353051 -13.097656,-13.1914066 a 0.48004799,0.48004799 0 0 0 -0.101562,-0.00977 z m 0,0.9609374 c 6.76565,0 12.240234,5.4745838 12.240234,12.2402348 0,6.76565 -5.474584,12.240234 -12.240234,12.240234 -6.765651,0 -12.240235,-5.474584 -12.240235,-12.240234 0,-6.765651 5.474584,-12.2402347 12.240235,-12.2402348 z" />
              </symbol>
              <symbol viewBox="-15 0 84 84" width="101" height="101" id="filter">
                  <rect x="11" y="15" width="31" height="64" fill="currentColor" class="fill"></rect>
                  <path d="m 14.076172,34.263672 a 0.48004802,0.48004802 0 0 0 -0.466797,0.404297 0.48004802,0.48004802 0 0 0 -0.0059,0.04883 0.48004802,0.48004802 0 0 0 0,0.0332 0.48004802,0.48004802 0 0 0 0,0.01367 v 14.386719 a 0.48004802,0.48004802 0 0 0 0.478515,0.478515 h 24.900391 a 0.48004802,0.48004802 0 0 0 0.541016,-0.542968 V 34.75 A 0.48004802,0.48004802 0 0 0 39.042969,34.269531 H 14.160156 a 0.48004802,0.48004802 0 0 0 -0.08398,-0.0059 z m 1.800781,0.966797 H 38.5625 v 13.08789 z M 14.5625,35.580078 37.248047,48.669922 H 14.5625 Z" />
                  <path d="m 33.275391,48.662109 a 0.48004802,0.48004802 0 0 0 -0.472657,0.488282 v 2.878906 a 0.480469,0.480469 0 1 0 0.960938,0 v -2.878906 a 0.48004802,0.48004802 0 0 0 -0.488281,-0.488282 z" />
                  <path d="m 14.076172,34.263672 a 0.48004802,0.48004802 0 0 0 -0.07227,0.0059 H 11.203125 A 0.48004802,0.48004802 0 0 0 10.722656,34.75 v 14.400391 a 0.48004802,0.48004802 0 0 0 0.480469,0.478515 h 2.878906 A 0.48004802,0.48004802 0 0 0 14.5625,49.150391 v -14.3125 a 0.48004802,0.48004802 0 0 0 -0.107422,-0.398438 0.48004802,0.48004802 0 0 0 -0.002,-0.0039 0.48004802,0.48004802 0 0 0 -0.03125,-0.0332 0.48004802,0.48004802 0 0 0 -0.002,-0.002 0.48004802,0.48004802 0 0 0 -0.0332,-0.0293 0.48004802,0.48004802 0 0 0 -0.0039,-0.002 0.48004802,0.48004802 0 0 0 -0.30664,-0.105469 z m -2.392578,0.966797 h 1.919922 v 13.439453 h -1.919922 z" />
                  <path d="m 41.916016,34.263672 a 0.48004802,0.48004802 0 0 0 -0.07227,0.0059 H 39.042969 A 0.48004802,0.48004802 0 0 0 38.5625,34.75 v 14.400391 a 0.48004802,0.48004802 0 0 0 0.480469,0.478515 h 2.880859 a 0.48004802,0.48004802 0 0 0 0.478516,-0.478515 v -14.3125 a 0.48004802,0.48004802 0 0 0 -0.107422,-0.398438 0.48004802,0.48004802 0 0 0 -0.002,-0.0039 0.48004802,0.48004802 0 0 0 -0.03125,-0.0332 0.48004802,0.48004802 0 0 0 -0.002,-0.002 0.48004802,0.48004802 0 0 0 -0.0332,-0.0293 0.48004802,0.48004802 0 0 0 -0.0039,-0.002 0.48004802,0.48004802 0 0 0 -0.30664,-0.105469 z m -2.392578,0.966797 h 1.919921 v 13.439453 h -1.919921 z" />
              </symbol>
              <symbol viewBox="-15 0 84 84" width="101" height="101" id="manual-valve">
                  <rect x="11" y="15" width="31" height="64" fill="currentColor" class="fill"></rect>
                  <path d="m 39.035156,34.371094 a 0.48004802,0.48004802 0 0 0 -0.224609,0.06445 l -12.480469,7.199219 a 0.48004802,0.48004802 0 0 0 0,0.832031 l 12.416016,7.162109 a 0.48004802,0.48004802 0 0 0 0.480468,0.07422 0.48004802,0.48004802 0 0 0 0.04297,-0.01953 0.48004802,0.48004802 0 0 0 0.0293,-0.01563 0.48004802,0.48004802 0 0 0 0.06641,-0.04883 0.48004802,0.48004802 0 0 0 0.164063,-0.441407 V 34.849609 a 0.48004802,0.48004802 0 0 0 -0.494141,-0.478515 z m -0.464844,1.308594 V 48.419922 L 27.53125,42.050781 Z" />
                  <path d="m 14.052734,34.371094 a 0.48004802,0.48004802 0 0 0 -0.443359,0.478515 v 14.326172 a 0.48004802,0.48004802 0 0 0 0.08008,0.347657 0.48004802,0.48004802 0 0 0 0.06055,0.07422 0.48004802,0.48004802 0 0 0 0.0293,0.02539 0.48004802,0.48004802 0 0 0 0.0059,0.0059 0.48004802,0.48004802 0 0 0 0.01563,0.01172 0.48004802,0.48004802 0 0 0 0.0332,0.02344 0.48004802,0.48004802 0 0 0 0.01758,0.0098 0.48004802,0.48004802 0 0 0 0.0293,0.01367 0.48004802,0.48004802 0 0 0 0.0059,0.0039 0.48004802,0.48004802 0 0 0 0.02344,0.0098 0.48004802,0.48004802 0 0 0 0.01758,0.0059 0.48004802,0.48004802 0 0 0 0.457032,-0.07227 l 12.425781,-7.167969 a 0.48004802,0.48004802 0 0 0 0,-0.832031 L 14.330078,34.435547 a 0.48004802,0.48004802 0 0 0 -0.277344,-0.06445 z m 0.517578,1.310547 11.039063,6.36914 -11.039063,6.367188 z" />
                  <path d="m 26.5625,27.164062 a 0.48004802,0.48004802 0 0 0 -0.472656,0.486329 v 14.40039 a 0.48004802,0.48004802 0 1 0 0.958984,0 V 27.650391 A 0.48004802,0.48004802 0 0 0 26.5625,27.164062 Z" />
                  <path d="m 19.369141,27.169922 a 0.4804685,0.4804685 0 1 0 0,0.960937 h 14.40039 a 0.4804685,0.4804685 0 1 0 0,-0.960937 z" />
                  <path d="m 11.203125,34.363281 a 0.48004802,0.48004802 0 0 0 -0.304687,0.115235 0.48004802,0.48004802 0 0 0 -0.0039,0.002 0.48004802,0.48004802 0 0 0 -0.03125,0.03125 0.48004802,0.48004802 0 0 0 -0.0059,0.0059 0.48004802,0.48004802 0 0 0 -0.02539,0.0293 0.48004802,0.48004802 0 0 0 -0.002,0.0039 0.48004802,0.48004802 0 0 0 -0.09961,0.378907 V 49.25 a 0.48004802,0.48004802 0 0 0 0.478515,0.480469 h 2.88086 A 0.48004802,0.48004802 0 0 0 14.570312,49.25 V 34.849609 a 0.48004802,0.48004802 0 0 0 -0.480468,-0.478515 h -2.791016 a 0.48004802,0.48004802 0 0 0 -0.0957,-0.0078 z m 0.486328,0.966797 h 1.919922 v 13.439453 h -1.919922 z" />
                  <path d="m 39.042969,34.363281 a 0.48004802,0.48004802 0 0 0 -0.304688,0.115235 0.48004802,0.48004802 0 0 0 -0.0039,0.002 0.48004802,0.48004802 0 0 0 -0.03125,0.03125 0.48004802,0.48004802 0 0 0 -0.0059,0.0059 0.48004802,0.48004802 0 0 0 -0.02539,0.0293 0.48004802,0.48004802 0 0 0 -0.002,0.0039 0.48004802,0.48004802 0 0 0 -0.09961,0.378907 V 49.25 a 0.48004802,0.48004802 0 0 0 0.478516,0.480469 h 2.88086 A 0.48004802,0.48004802 0 0 0 42.410156,49.25 V 34.849609 a 0.48004802,0.48004802 0 0 0 -0.480468,-0.478515 h -2.791016 a 0.48004802,0.48004802 0 0 0 -0.0957,-0.0078 z m 0.486328,0.966797 h 1.919922 v 13.439453 h -1.919922 z" />
              </symbol>
              <symbol viewBox="-15 0 84 84" width="101" height="101" id="pneumatic-valve">
                  <rect x="11" y="15" width="31" height="64" fill="currentColor" class="fill"></rect>
                  <path d="m 39.035156,34.380859 a 0.48004802,0.48004802 0 0 0 -0.224609,0.0625 l -12.480469,7.201172 a 0.48004802,0.48004802 0 0 0 0,0.832031 l 12.416016,7.16211 a 0.48004802,0.48004802 0 0 0 0.470703,0.07617 0.48004802,0.48004802 0 0 0 0.0098,-0.002 0.48004802,0.48004802 0 0 0 0.01758,-0.0078 0.48004802,0.48004802 0 0 0 0.02539,-0.01172 0.48004802,0.48004802 0 0 0 0.01758,-0.0098 0.48004802,0.48004802 0 0 0 0.07813,-0.05469 0.48004802,0.48004802 0 0 0 0.02148,-0.01953 0.48004802,0.48004802 0 0 0 0.01172,-0.01367 0.48004802,0.48004802 0 0 0 0.130859,-0.408203 V 34.859375 a 0.48004802,0.48004802 0 0 0 -0.494141,-0.478516 z m -0.464844,1.308594 V 48.429688 L 27.53125,42.060547 Z" />
                  <path d="m 14.052734,34.380859 a 0.48004802,0.48004802 0 0 0 -0.443359,0.478516 v 14.326172 a 0.48004802,0.48004802 0 0 0 0.140625,0.419922 0.48004802,0.48004802 0 0 0 0.03125,0.0293 0.48004802,0.48004802 0 0 0 0.01172,0.0078 0.48004802,0.48004802 0 0 0 0.0078,0.0078 0.48004802,0.48004802 0 0 0 0.02344,0.01563 0.48004802,0.48004802 0 0 0 0.0332,0.01953 0.48004802,0.48004802 0 0 0 0.02344,0.01172 0.48004802,0.48004802 0 0 0 0.0059,0.0039 0.48004802,0.48004802 0 0 0 0.02344,0.0098 0.48004802,0.48004802 0 0 0 0.01758,0.0059 0.48004802,0.48004802 0 0 0 0.04492,0.01367 0.48004802,0.48004802 0 0 0 0.41211,-0.08594 l 12.425781,-7.167969 a 0.48004802,0.48004802 0 0 0 0,-0.832031 L 14.330078,34.443359 a 0.48004802,0.48004802 0 0 0 -0.277344,-0.0625 z m 0.517578,1.310547 11.039063,6.369141 -11.039063,6.367187 z" />
                  <path d="m 26.5625,27.173828 a 0.48004802,0.48004802 0 0 0 -0.472656,0.486328 v 14.400391 a 0.4804685,0.4804685 0 1 0 0.960937,0 V 27.660156 A 0.48004802,0.48004802 0 0 0 26.5625,27.173828 Z" />
                  <path d="m 19.369141,27.179688 a 0.4804685,0.4804685 0 1 0 0,0.960937 h 14.40039 a 0.4804685,0.4804685 0 1 0 0,-0.960937 z" />
                  <path d="m 11.203125,34.373047 a 0.48004802,0.48004802 0 0 0 -0.265625,0.08594 0.48004802,0.48004802 0 0 0 -0.0039,0.002 0.48004802,0.48004802 0 0 0 -0.03516,0.02734 0.48004802,0.48004802 0 0 0 -0.0039,0.002 0.48004802,0.48004802 0 0 0 -0.03125,0.03125 0.48004802,0.48004802 0 0 0 -0.0059,0.0059 0.48004802,0.48004802 0 0 0 -0.02539,0.0293 0.48004802,0.48004802 0 0 0 -0.002,0.0039 0.48004802,0.48004802 0 0 0 -0.09961,0.376953 v 14.322266 a 0.48004802,0.48004802 0 0 0 0.478515,0.480468 h 2.88086 a 0.48004802,0.48004802 0 0 0 0.480468,-0.480468 V 34.859375 a 0.48004802,0.48004802 0 0 0 -0.480468,-0.480469 h -2.806641 a 0.48004802,0.48004802 0 0 0 -0.08008,-0.0059 z m 0.486328,0.966797 h 1.919922 v 13.439453 h -1.919922 z" />
                  <path d="m 39.042969,34.373047 a 0.48004802,0.48004802 0 0 0 -0.265625,0.08594 0.48004802,0.48004802 0 0 0 -0.0039,0.002 0.48004802,0.48004802 0 0 0 -0.03516,0.02734 0.48004802,0.48004802 0 0 0 -0.0039,0.002 0.48004802,0.48004802 0 0 0 -0.03125,0.03125 0.48004802,0.48004802 0 0 0 -0.0059,0.0059 0.48004802,0.48004802 0 0 0 -0.02539,0.0293 0.48004802,0.48004802 0 0 0 -0.002,0.0039 0.48004802,0.48004802 0 0 0 -0.09961,0.376953 v 14.322266 a 0.48004802,0.48004802 0 0 0 0.480469,0.480468 h 2.878907 a 0.48004802,0.48004802 0 0 0 0.480468,-0.480468 V 34.859375 a 0.48004802,0.48004802 0 0 0 -0.480468,-0.480469 h -2.806641 a 0.48004802,0.48004802 0 0 0 -0.08008,-0.0059 z m 0.486328,0.966797 h 1.919922 v 13.439453 h -1.919922 z" />
                  <path d="m 26.650391,21.740234 c -3.552557,1.71e-4 -6.674829,2.370922 -7.628907,5.792969 a 0.48004799,0.48004799 0 1 0 0.923828,0.257813 c 0.839559,-3.011294 3.578941,-5.091647 6.705079,-5.091797 3.126137,-1.5e-4 5.863279,2.078633 6.703125,5.089843 a 0.48050429,0.48050429 0 1 0 0.925781,-0.257812 c -0.954405,-3.421954 -4.07635,-5.791186 -7.628906,-5.791016 z" />
              </symbol>
              <symbol viewBox="-15 0 84 84" width="101" height="101" id="pump">
                  <rect x="9" y="0" width="43" height="84" fill="currentColor" class="fill"></rect>
                  <path d="M 31.005859,19.519531 C 18.772913,19.497267 8.8289046,29.40768 8.8066406,41.640625 8.7843767,53.873571 18.692836,63.81758 30.925781,63.839844 c 12.19817,0.0222 22.111056,-9.832646 22.189453,-22.017578 a 0.48004879,0.48004879 0 0 0 0.0098,-0.101563 0.48004879,0.48004879 0 0 0 -0.01172,-0.111328 C 53.073797,29.42888 43.200653,19.541726 31.005859,19.519531 Z m -0.002,0.960938 c 11.714123,0.02132 21.183429,9.524157 21.16211,21.238281 C 52.144696,53.432873 42.641858,62.902179 30.927734,62.880859 19.213612,62.85954 9.7443053,53.354749 9.765625,41.640625 9.7869447,29.926502 19.289782,20.459149 31.003906,20.480469 Z" />
                  <path d="m 20.291016,22.400391 a 0.48004802,0.48004802 0 0 0 -0.445313,0.478515 l -0.160156,37.439453 a 0.48004802,0.48004802 0 0 0 0.71875,0.417969 L 52.833984,42.207031 a 0.48004802,0.48004802 0 0 0 0.28125,-0.324219 0.48004802,0.48004802 0 0 0 0.0098,-0.04687 0.48004802,0.48004802 0 0 0 0.0039,-0.03711 0.48004802,0.48004802 0 0 0 0.002,-0.0098 0.48004802,0.48004802 0 0 0 0,-0.01953 0.48004802,0.48004802 0 0 0 0,-0.0293 0.48004802,0.48004802 0 0 0 -0.0039,-0.03711 0.48004802,0.48004802 0 0 0 0,-0.0098 0.48004802,0.48004802 0 0 0 -0.0039,-0.01953 0.48004802,0.48004802 0 0 0 -0.294922,-0.363281 L 20.568359,22.464844 a 0.48004802,0.48004802 0 0 0 -0.277343,-0.06445 z M 20.802734,23.714844 51.6875,41.755859 20.650391,59.490234 Z" />
                  <path d="M 13.205078,0 C 5.9206045,0 0.00585938,5.9147451 0.00585938,13.199219 c 0,7.249701 5.86106402,13.135304 13.09765662,13.191406 a 0.48004799,0.48004799 0 0 0 0.101562,0.0098 0.48004799,0.48004799 0 0 0 0.107422,-0.01172 C 20.546298,26.329323 26.40625,20.446893 26.40625,13.199219 26.40625,5.9147451 20.489551,0 13.205078,0 Z m 0,0.9609375 c 6.765651,0 12.240234,5.4726301 12.240234,12.2382815 0,6.76565 -5.474583,12.240234 -12.240234,12.240234 -6.765651,0 -12.23828112,-5.474583 -12.23828112,-12.240234 0,-6.7656514 5.47263002,-12.2382815 12.23828112,-12.2382815 z" />
                  <path d="m 13.205078,57.439453 c -7.2844735,0 -13.19921862,5.916699 -13.19921862,13.201172 0,7.250361 5.86210802,13.134409 13.09960962,13.189453 a 0.48004799,0.48004799 0 0 0 0.09961,0.0098 0.48004799,0.48004799 0 0 0 0.107422,-0.01172 c 7.233795,-0.05935 13.09375,-5.939828 13.09375,-13.1875 0,-7.284473 -5.916699,-13.201172 -13.201172,-13.201172 z m 0,0.960938 c 6.765651,0 12.240234,5.474583 12.240234,12.240234 0,6.765651 -5.474583,12.240234 -12.240234,12.240234 -6.765651,0 -12.23828112,-5.474583 -12.23828112,-12.240234 0,-6.765651 5.47263012,-12.240234 12.23828112,-12.240234 z" />
              </symbol>
              <symbol viewBox="-15 0 84 84" width="101" height="101" id="pump-no-sensors">
                  <rect x="9" y="0" width="43" height="84" fill="currentColor" class="fill"></rect>
                  <path d="M 31.005859,19.519531 C 18.772913,19.497267 8.8289046,29.40768 8.8066406,41.640625 8.7843767,53.873571 18.692836,63.81758 30.925781,63.839844 c 12.19817,0.0222 22.111056,-9.832646 22.189453,-22.017578 a 0.48004879,0.48004879 0 0 0 0.0098,-0.101563 0.48004879,0.48004879 0 0 0 -0.01172,-0.111328 C 53.073797,29.42888 43.200653,19.541726 31.005859,19.519531 Z m -0.002,0.960938 c 11.714123,0.02132 21.183429,9.524157 21.16211,21.238281 C 52.144696,53.432873 42.641858,62.902179 30.927734,62.880859 19.213612,62.85954 9.7443053,53.354749 9.765625,41.640625 9.7869447,29.926502 19.289782,20.459149 31.003906,20.480469 Z" />
                  <path d="m 20.291016,22.400391 a 0.48004802,0.48004802 0 0 0 -0.445313,0.478515 l -0.160156,37.439453 a 0.48004802,0.48004802 0 0 0 0.71875,0.417969 L 52.833984,42.207031 a 0.48004802,0.48004802 0 0 0 0.28125,-0.324219 0.48004802,0.48004802 0 0 0 0.0098,-0.04687 0.48004802,0.48004802 0 0 0 0.0039,-0.03711 0.48004802,0.48004802 0 0 0 0.002,-0.0098 0.48004802,0.48004802 0 0 0 0,-0.01953 0.48004802,0.48004802 0 0 0 0,-0.0293 0.48004802,0.48004802 0 0 0 -0.0039,-0.03711 0.48004802,0.48004802 0 0 0 0,-0.0098 0.48004802,0.48004802 0 0 0 -0.0039,-0.01953 0.48004802,0.48004802 0 0 0 -0.294922,-0.363281 L 20.568359,22.464844 a 0.48004802,0.48004802 0 0 0 -0.277343,-0.06445 z M 20.802734,23.714844 51.6875,41.755859 20.650391,59.490234 Z" />
              </symbol>
              <symbol viewBox="-15 0 84 84" width="101" height="101" id="sensor">
                  <rect x="13" y="28" width="27" height="27" fill="currentColor" class="fill"></rect>
                  <path d="m 26.570312,28.720703 a 0.48004799,0.48004799 0 0 0 -0.107421,0.01172 c -7.233758,0.0594 -13.09375,5.939855 -13.09375,13.1875 0,7.284473 5.916698,13.199219 13.201171,13.199219 7.284474,0 13.199219,-5.914746 13.199219,-13.199219 0,-7.250361 -5.862108,-13.134409 -13.099609,-13.189453 a 0.48004799,0.48004799 0 0 0 -0.09961,-0.0098 z m 0,0.958985 c 6.765651,0 12.240235,5.474583 12.240235,12.240234 0,6.765651 -5.474584,12.240234 -12.240235,12.240234 -6.76565,0 -12.240234,-5.474583 -12.240234,-12.240234 0,-6.765651 5.474584,-12.240234 12.240234,-12.240234 z" />
              </symbol>
              <symbol viewBox="-15 0 84 84" width="101" height="101" id="cooling-tower">
                  <path d="M 0.5,19.5 V 20 82.5 h 52 v -63 z m 1,1 h 50 v 61 h -50 z"/>
                  <path d="M 10.5,9.5 V 10 20.5 h 32 v -11 z m 1,1 h 30 v 9 h -30 z"/>
              </symbol>
              <symbol viewBox="0 0 104 74" width="200" height="150" id="reservoir">
                  <path d="M 6.296875,24.287109 H 12.34375 V 23.53125 H 6.296875 Z"/>
                  <path d="m 18.390625,24.287109 h 6.048828 V 23.53125 h -6.048828 z"/>
                  <path d="m 30.486328,24.287109 h 6.046875 V 23.53125 h -6.046875 z"/>
                  <path d="m 42.580078,24.287109 h 6.046875 V 23.53125 h -6.046875 z"/>
                  <path d="m 54.675781,24.287109 h 6.046875 V 23.53125 h -6.046875 z"/>
                  <path d="m 66.769531,24.287109 h 6.046875 V 23.53125 h -6.046875 z"/>
                  <path d="m 78.863281,24.287109 h 6.046875 V 23.53125 h -6.046875 z"/>
                  <path d="m 90.958984,24.287109 h 6.046875 V 23.53125 h -6.046875 z"/>
                  <path d="M 7.7167969,31.15625 H 13.763672 V 30.400391 H 7.7167969 Z"/>
                  <path d="m 19.8125,31.15625 h 6.046875 V 30.400391 H 19.8125 Z"/>
                  <path d="m 31.90625,31.15625 h 6.046875 V 30.400391 H 31.90625 Z"/>
                  <path d="m 44,31.15625 h 6.048828 V 30.400391 H 44 Z"/>
                  <path d="m 56.095703,31.15625 h 6.046875 v -0.755859 h -6.046875 z"/>
                  <path d="m 68.189453,31.15625 h 6.046875 v -0.755859 h -6.046875 z"/>
                  <path d="m 80.285156,31.15625 h 6.046875 v -0.755859 h -6.046875 z"/>
                  <path d="m 92.378906,31.15625 h 4.072266 v -0.755859 h -4.072266 z"/>
                  <path d="m 10.697266,44.896484 h 6.046875 v -0.755859 h -6.046875 z"/>
                  <path d="m 22.791016,44.896484 h 6.046875 v -0.755859 h -6.046875 z"/>
                  <path d="m 34.884766,44.896484 h 6.048828 v -0.755859 h -6.048828 z"/>
                  <path d="m 46.980469,44.896484 h 6.046875 v -0.755859 h -6.046875 z"/>
                  <path d="m 59.074219,44.896484 h 6.046875 v -0.755859 h -6.046875 z"/>
                  <path d="m 71.167969,44.896484 h 6.048828 v -0.755859 h -6.048828 z"/>
                  <path d="m 83.263672,44.896484 h 6.046875 v -0.755859 h -6.046875 z"/>
                  <path d="m 12.568359,51.765625 h 6.046875 v -0.755859 h -6.046875 z"/>
                  <path d="m 24.662109,51.765625 h 6.046875 v -0.755859 h -6.046875 z"/>
                  <path d="m 36.755859,51.765625 h 6.048829 v -0.755859 h -6.048829 z"/>
                  <path d="m 48.851562,51.765625 h 6.046876 v -0.755859 h -6.046876 z"/>
                  <path d="m 60.945312,51.765625 h 6.046876 v -0.755859 h -6.046876 z"/>
                  <path d="m 73.041016,51.765625 h 6.046875 v -0.755859 h -6.046875 z"/>
                  <path d="m 85.134766,51.765625 h 6.046875 v -0.755859 h -6.046875 z"/>
                  <path d="m 9.3339844,38.025391 h 6.0468746 v -0.75586 H 9.3339844 Z"/>
                  <path d="m 21.427734,38.025391 h 6.048828 v -0.75586 h -6.048828 z"/>
                  <path d="m 33.523438,38.025391 h 6.046874 v -0.75586 h -6.046874 z"/>
                  <path d="m 45.617188,38.025391 h 6.046874 v -0.75586 h -6.046874 z"/>
                  <path d="m 57.712891,38.025391 h 6.046875 v -0.75586 h -6.046875 z"/>
                  <path d="m 69.806641,38.025391 h 6.046875 v -0.75586 h -6.046875 z"/>
                  <path d="m 81.900391,38.025391 h 6.048828 v -0.75586 h -6.048828 z"/>
                  <path d="m 93.996094,38.025391 h 0.943359 v -0.75586 h -0.943359 z"/>
                  <path d="m 29.871094,12.140625 c -3.341703,-0.189673 -6.246201,1.694613 -9.083985,3.556641 -2.837783,1.862027 -5.615949,3.702168 -8.65625,3.695312 -1.19783,-0.0027 -3.0507732,-0.741783 -4.5546871,-1.501953 -1.503914,-0.76017 -2.6777344,-1.519531 -2.6777344,-1.519531 l -0.78125,-0.50586 0.2070313,0.90625 12.6289062,55.351563 H 87.316406 L 100.4082,12.863281 99.605469,13.441406 c 0,0 -2.922414,2.102398 -6.679688,4.167969 -3.757273,2.065571 -8.369482,4.065663 -11.566406,3.925781 -2.952584,-0.12919 -5.433531,-2.231914 -7.976563,-4.419922 -2.543031,-2.188007 -5.147944,-4.46208 -8.433593,-4.61914 -3.659883,-0.17495 -6.797754,2.064467 -9.867188,4.228515 -3.069433,2.164048 -6.070444,4.252416 -9.398437,4.095703 -2.839091,-0.13369 -5.229315,-2.136434 -7.681641,-4.228515 -2.452326,-2.092081 -4.964861,-4.271472 -8.130859,-4.451172 z m -0.04297,0.753906 c 2.83852,0.161113 5.230584,2.180773 7.683594,4.273438 2.45301,2.092665 4.968284,4.259004 8.136719,4.408203 3.659943,0.172344 6.799761,-2.068412 9.86914,-4.232422 3.06938,-2.16401 6.068665,-4.250873 9.396484,-4.091797 2.95239,0.14113 5.433249,2.249249 7.976563,4.4375 2.543314,2.188251 5.148939,4.455804 8.435547,4.599609 3.531964,0.154542 8.160828,-1.927384 11.96289,-4.017578 3.361528,-1.848008 5.394225,-3.318446 5.97461,-3.728515 L 86.708984,71.367188 H 17.556641 L 5.2539062,17.4375 c 0.4353637,0.269702 0.8668739,0.564074 1.9804688,1.126953 1.5435966,0.780228 3.408744,1.580607 4.894531,1.583985 3.341982,0.0075 6.238904,-1.959234 9.072266,-3.81836 2.833362,-1.859126 5.596614,-3.607547 8.626953,-3.435547 z"/>
                  <path d="M 102.07422,1.8652344 86.611328,71.244141 H 17.652344 L 1.7324219,1.9472656 0.7578125,2.171875 16.857422,72.244141 h 70.55664 L 103.04883,2.0839844 Z"/>
              </symbol>

          </svg:defs>
        </svg:svg>
    </perfect-scrollbar>
  </div>
</div>
