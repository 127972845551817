import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ss-svg-symbols',
  templateUrl: './svg-symbols.component.html',
  styleUrls: []
})
export class SvgSymbolsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
