<div class="cannon-state cannon-state--with-text"
[ngClass]='{
  "cannon-state--detailed": size === "detailed",
  "cannon-state--bigest": size === "bigest",
  "cannon-state--big": size === "big",
  "cannon-state--mid": size === "mid",
  "cannon-state--small": size === "small",
  "cannon-state--smallest": size === "smallest",

  "working": state === "working",
  "awaiting-for-conditions": state === "awaiting-for-conditions",
  "ready-to-work": state === "ready-to-work",
  "standing-by": state === "standing-by",
  "preparing-to-work": state === "preparing-to-work",
  "snoozing": state === "snoozing",
  "stopping": state === "stopping",
  "starting": state === "starting",
  "working-in-manual-mode": state === "working-in-manual-mode",
  "breakdown": state === "breakdown" || state === "connection-lost"
}'
  >
  <div class="cannon-state__circle" *ngIf="size === 'detailed'">
    <div class="cannon-state__icon">
      <svg>
        <use xlink:href='#icon-cannon-blower'/>
      </svg>
    </div>
  </div>

  <span class="cannon-state__label text-left">
    <ng-container [ngSwitch]="state">
      <ng-container *ngSwitchCase="'connection-lost'"
        i18n="Stan urządzenia 'Brak połączenia'|@@deviceStatusConnectionLost">Brak połączenia</ng-container>
      <ng-container *ngSwitchCase="'breakdown'"
        i18n="Stan urządzenia 'Awaria'|@@deviceStatusBreakdown">Awaria</ng-container>
      <ng-container *ngSwitchCase="'working-in-manual-mode'"
        i18n="Stan urządzenia 'Tryb manualny'|@@deviceStatusManualMode">Tryb manualny</ng-container>
      <ng-container *ngSwitchCase="'starting'"
        i18n="Stan urządzenia 'Uruchamianie'|@@deviceStatusStarting">Uruchamianie</ng-container>
      <ng-container *ngSwitchCase="'working'"
        i18n="Stan urządzenia 'Pracuje'|@@deviceStatusWorking">Pracuje</ng-container>
      <ng-container *ngSwitchCase="'stopping'"
        i18n="Stan urządzenia 'Zatrzymywanie'|@@deviceStatusStopping">Zatrzymywanie</ng-container>
      <ng-container *ngSwitchCase="'awaiting-for-conditions'"
        i18n="Stan urządzenia 'Czeka na warunki'|@@deviceStatusAwaiting-for-conditions">Czeka na warunki</ng-container>
      <ng-container *ngSwitchCase="'ready-to-work'"
        i18n="Stan urządzenia 'Gotowość'|@@deviceStatusReadyToWork">Gotowość</ng-container>
      <ng-container *ngSwitchCase="'snoozing'"
        i18n="Stan urządzenia 'Usypianie'|@@deviceStatusSnoozing">Usypianie</ng-container>
      <ng-container *ngSwitchCase="'standing-by'"
        i18n="Stan urządzenia 'Czuwanie'|@@deviceStatusStandingBy">Czuwanie</ng-container>
      <ng-container *ngSwitchCase="'preparing-to-work'"
        i18n="Stan urządzenia 'Przygotowanie'|@@deviceStatusPreparing">Przygotowanie</ng-container>
      </ng-container>
  </span>
</div>
