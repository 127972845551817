import { D3 } from '..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { D3DrawerData } from '../../models/charts/d3-drawer-data.model';

export const drawXAxisScaleInvisible = (state: D3DrawerChartState, data: D3DrawerData): D3DrawerChartState => {
  const min = D3.min(data, d => +d[state.config.labelFieldName]);
  const max = D3.max(data, d => +d[state.config.labelFieldName]);

  let range: [number, number] = [ 5, state.viewBoxWidth - 5 ];
  if(['single','single-with-line' ].includes(state.config.yAxisType)) {
    range = [ 85, state.viewBoxWidth - 20 ];
  } else if(['double','double-with-line' ].includes(state.config.yAxisType)) {
    range = [ 85, state.viewBoxWidth - 85 ];
  }

  let xAxisScale = state.generatedValuesStorage.get('xAxisScale');
  if (!xAxisScale) {
    xAxisScale = D3.scaleUtc();
    state.generatedValuesStorage.set('xAxisScale', xAxisScale);
  }

  xAxisScale
    .range(range)
    .domain([ min, max ]);

  let xAxisBandScale = state.generatedValuesStorage.get('xAxisBandScale');
  if (!xAxisBandScale) {
    xAxisBandScale = D3.scaleBand()
      .padding(0);
    state.generatedValuesStorage.set('xAxisBandScale', xAxisBandScale);
  }

  xAxisBandScale
    .range(range)
    .domain(data.map( d => `${d[state.config.labelFieldName]}`));

  return state;
};
