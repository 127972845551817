<ng-container *ngIf="waterReservoir">
<div *ngIf="
  size === 'small'
  || size === 'extra-small'
  ; else largeReservoir
" class="water-tube" [ngClass]="{'water-tube--xs': size === 'extra-small'}">


    <div *ngIf="size === 'extra-small'" class="water-tube__info ml-0">
        <span class="water-tube__name fs-14">{{ waterReservoir.symbol }}</span>
    </div>
    <div class="water-tube__indicator"
        [ngClass]="{
            'water-tube__indicator--disconnected': !waterReservoir.connectionStatus,
            'water-tube__indicator--xs': size === 'extra-small',
            'water-tube__indicator--level-min': waterReservoir.levelPercent < 20,
            'water-tube__indicator--level-1': waterReservoir.levelPercent < 35 && waterReservoir.levelPercent >= 20,
            'water-tube__indicator--level-2': waterReservoir.levelPercent < 50 && waterReservoir.levelPercent >= 35,
            'water-tube__indicator--level-3': waterReservoir.levelPercent < 65 && waterReservoir.levelPercent >= 50,
            'water-tube__indicator--level-4': waterReservoir.levelPercent < 80 && waterReservoir.levelPercent >= 65,
            'water-tube__indicator--level-max': waterReservoir.levelPercent > 80
        }"
    ></div>

    <div *ngIf="size === 'small'" class="water-tube__info">
        <span class="water-tube__name mb-1">{{ waterReservoir.symbol }}</span>
        <div class="d-flex align-items-center">
            <p *ngIf="
              !waterReservoir.configuration
              || waterReservoir.configuration.levelDisplay !== 'volume' && waterReservoir.configuration.levelDisplay !== 'both'
              ; else waterReservoirCurrentVolume
            ">
              <span class="water-tube__level">{{ waterReservoir.connectionStatus ? (waterReservoir.currentWaterLevel|number:'1.2-2') : '--' }}</span>
              <span class="water-tube__unit">m</span>
            </p>

            <ng-template #waterReservoirCurrentVolume>
              <p>
                <span class="water-tube__level">{{ waterReservoir.connectionStatus ? (waterReservoir.currentWaterVolume|number:'1.0-0') : '--' }}</span>
                <span class="water-tube__unit">m<sup>3</sup></span>

                <ng-container *ngIf="waterReservoir.configuration.levelDisplay === 'both' ">
                  <br />
                  <span class="water-tube__level">{{ waterReservoir.connectionStatus ? (waterReservoir.currentWaterLevel|number:'1.2-2') : '--' }}</span>
                  <span class="water-tube__unit">m</span>
                </ng-container>

              </p>
            </ng-template>

            <p *ngIf="!waterReservoir.configuration || waterReservoir.configuration.hasWaterThermometer" class="fs-20 ml-2">
              {{ waterReservoir.connectionStatus ? (waterReservoir.currentWaterTemperature|number:'1.1-1') : '--' }}<span class="color-mid-grey">&deg;C</span>
            </p>
        </div>
    </div>



</div>
<ng-template #largeReservoir>
    <div *ngIf="type === 'stream'; else waterTank" class="water-tank water-tank--stream"
        [ngClass]="{'water-tank--large': size === 'large'}">
        <div class="water-tank__fulfillment"
          [ngClass]="{
            'water-tube__fulfillment--disconnected': !waterReservoir.connectionStatus,
            'water-tank__fulfillment--level-min': waterReservoir.levelPercent < 20,
            'water-tank__fulfillment--level-1': waterReservoir.levelPercent < 30 && waterReservoir.levelPercent >= 20,
            'water-tank__fulfillment--level-2': waterReservoir.levelPercent < 40 && waterReservoir.levelPercent >= 30,
            'water-tank__fulfillment--level-3': waterReservoir.levelPercent < 50 && waterReservoir.levelPercent >= 40,
            'water-tank__fulfillment--level-4': waterReservoir.levelPercent < 60 && waterReservoir.levelPercent >= 50,
            'water-tank__fulfillment--level-5': waterReservoir.levelPercent < 70 && waterReservoir.levelPercent >= 80,
            'water-tank__fulfillment--level-max': waterReservoir.levelPercent > 80
          }">
        </div>
        <span class="water-tank__name">{{ waterReservoir.symbol }}</span>
        <span class="water-tank__level">
          <ng-container *ngIf="
            !waterReservoir.configuration
            || waterReservoir.configuration.levelDisplay !== 'volume' && waterReservoir.configuration.levelDisplay !== 'both'
            ; else waterReservoirCurrentVolume2
          ">
            {{ waterReservoir.connectionStatus ? (waterReservoir.currentWaterLevel|number:'1.2-2') : '--' }}m
          </ng-container>
          <ng-template #waterReservoirCurrentVolume2>
            {{ waterReservoir.connectionStatus ? (waterReservoir.currentWaterVolume|number:'1.0-0') : '--' }}m<sup>3</sup>
            <ng-container *ngIf="waterReservoir.configuration.levelDisplay === 'both' ">
              <br />
              {{ waterReservoir.connectionStatus ? (waterReservoir.currentWaterLevel|number:'1.2-2') : '--' }}m
            </ng-container>
          </ng-template>
          <span *ngIf="
            size === 'large'
            && (!waterReservoir.configuration || waterReservoir.configuration.hasWaterThermometer)
          " class="water-tank__temp">
            <br />
            {{ waterReservoir.connectionStatus ? (waterReservoir.currentWaterTemperature|number:'1.1-1') : '--' }}&deg;C
          </span>
        </span>

    </div>
    <ng-template #waterTank>
        <div class="water-tank"
        [ngClass]="{'water-tank--large': size === 'large'}">
        <div class="water-tank__fulfillment"
            [ngClass]="{
                'water-tube__fulfillment--disconnected': !waterReservoir.connectionStatus,
                'water-tank__fulfillment--level-min': waterReservoir.levelPercent < 20,
                'water-tank__fulfillment--level-1': waterReservoir.levelPercent < 30 && waterReservoir.levelPercent >= 20,
                'water-tank__fulfillment--level-2': waterReservoir.levelPercent < 40 && waterReservoir.levelPercent >= 30,
                'water-tank__fulfillment--level-3': waterReservoir.levelPercent < 50 && waterReservoir.levelPercent >= 40,
                'water-tank__fulfillment--level-4': waterReservoir.levelPercent < 60 && waterReservoir.levelPercent >= 50,
                'water-tank__fulfillment--level-5': waterReservoir.levelPercent < 70 && waterReservoir.levelPercent >= 80,
                'water-tank__fulfillment--level-max': waterReservoir.levelPercent > 80
            }"
        >
        </div>
        <span class="water-tank__name">{{ waterReservoir.symbol }}</span>
        <span class="water-tank__level">
          <ng-container *ngIf="
            !waterReservoir.configuration
            || waterReservoir.configuration.levelDisplay !== 'volume' && waterReservoir.configuration.levelDisplay !== 'both'
            ; else waterReservoirCurrentVolume2
          ">
            {{ waterReservoir.connectionStatus ? (waterReservoir.currentWaterLevel|number:'1.2-2') : '--' }}m
          </ng-container>
          <ng-template #waterReservoirCurrentVolume2>
            {{ waterReservoir.connectionStatus ? (waterReservoir.currentWaterVolume|number:'1.0-0') : '--' }}m<sup>3</sup>
            <ng-container *ngIf="waterReservoir.configuration.levelDisplay === 'both' ">
              <br />
              {{ waterReservoir.connectionStatus ? (waterReservoir.currentWaterLevel|number:'1.2-2') : '--' }}m
            </ng-container>
          </ng-template>
          <span *ngIf="
            size === 'large'
            && (!waterReservoir.configuration || waterReservoir.configuration.hasWaterThermometer)
          " class="water-tank__temp">
            <br />
            {{ waterReservoir.connectionStatus ? (waterReservoir.currentWaterTemperature|number:'1.1-1') : '--' }}&deg;C
          </span>
        </span>
      </div>
    </ng-template>
</ng-template>
</ng-container>