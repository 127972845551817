import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { DrawableComponent, Sensor } from 'src/app/shared/models/pump-room';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[ss-full-schema-sensor]',
  templateUrl: './sensor.component.html',
  styleUrls: ['./sensor.component.sass']
})
export class SensorComponent implements OnInit {

  constructor() { }

  @Input()
  public allowEdit = false;

  @Input()
  public drawable: DrawableComponent<Sensor>;

  ngOnInit() {
  }

  @HostBinding('class.breakdown')
  get breakdown(): boolean {
    const component = this.drawable.component as Sensor;
    return this.hasSensorData
      && ['connection-lost', 'breakdown'].includes(component.computedStatus);
  }

  @HostBinding('class.alert-indicator')
  get isAlertIndicator(): boolean {
    const component = this.drawable.component as Sensor;
    return component.subType === 'alertIndicator';
  }
  @HostBinding('class.indicate-error')
  get indicateError(): boolean {
    const component = this.drawable.component as Sensor;
    return this.hasSensorData
      && component.subType === 'alertIndicator'
      && !!component.value;
  }

  @HostBinding('class.working')
  get working(): boolean {
    const component = this.drawable.component as Sensor;
    return this.drawable
      && ['working'].includes(component.computedStatus)
      && component.subType !== 'alertIndicator';
  }

  @HostBinding('class.sensored')
  get hasSensorData(): boolean {
    const component = this.drawable.component as Sensor;
    return this.drawable
      && component.hasSensorData;
  }
}
