import { Component, OnInit, Input } from '@angular/core';
import { ModeOfOperation } from 'src/app/shared/models/pump-room';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';

@Component({
  selector: 'ss-additional-mode-of-operation-tile',
  templateUrl: './additional-mode-of-operation-tile.component.html',
  styleUrls: []
})
export class AdditionalModeOfOperationTileComponent implements OnInit {

  @Input()
  public mode: ModeOfOperation;

  constructor(private cfgService: ConfigureClientService) { }


  public runStartTimer;
  public runStopTimer;

  private runStart() {
    this.cfgService.configurePumpRoom(this.mode.pumpRoom, {
      startAdditionalModeOfOperationWithSymbol: this.mode.symbol
    }).subscribe();
  }
  public stopStarting() {
    clearInterval(this.runStartTimer);
    this.runStartTimer = undefined;
  }
  public start() {
    this.stopStarting();
    this.runStart();
    this.runStartTimer = setInterval(() => {
      this.runStart();
    }, 1500);
  }
  private runStop() {
    this.cfgService.configurePumpRoom(this.mode.pumpRoom, {
      stopAdditionalModeOfOperationWithSymbol: this.mode.symbol
    }).subscribe();
  }
  public stopStopping() {
    clearInterval(this.runStopTimer);
    this.runStopTimer = undefined;
  }
  public stop() {
    this.stopStopping();
    this.runStop();
    this.runStopTimer = setInterval(() => {
      this.runStop();
    }, 1500);
  }

  ngOnInit() {
  }

}
