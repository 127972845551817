import { ReportPartType } from '..';
import { trn } from './translation-wrapper.function';

export const translateReportPartType = (partType: ReportPartType): string => {
  switch(partType) {
    case 'chart':
      return trn($localize`:Wykres|Etykieta wykresu 'Wykres'@@reportPartTypeChart:Wykres`);
    case 'sum-chart':
      return trn($localize`:Wykres sumujący|Etykieta wykresu 'Wykres sumujący'@@reportPartTypeSumChart:Wykres sumujący`);
    case 'avg-chart':
      return trn($localize`:Wykres uśredniający|Etykieta wykresu 'Wykres uśredniający'@@reportPartTypeAvgChart:Wykres uśredniający`);
    case 'sum':
      return trn($localize`:Suma|Etykieta wykresu 'Suma'@@reportPartTypeSum:Suma`);
    case 'avg':
      return trn($localize`:Średnia|Etykieta wykresu 'Średnia'@@reportPartTypeAvg:Średnia`);
    case 'min':
      return trn($localize`:Najamniejsza wartość|Etykieta wykresu 'Najamniejsza wartość'@@reportPartTypeMin:Najamniejsza wartość`);
    case 'max':
      return trn($localize`:Największa wartość|Etykieta wykresu 'Największa wartość'@@reportPartTypeMax:Największa wartość`);
    case 'first':
      return trn($localize`:Pierwsza wartość|Etykieta wykresu 'Pierwsza wartość'@@reportPartTypeFirst:Pierwsza wartość`);
    case 'last':
      return trn($localize`:Ostatnia wartość|Etykieta wykresu 'Ostatnia wartość'@@reportPartTypeLast:Ostatnia wartość`);
    case 'diff':
      return trn($localize`:Różnica ostatniej i pierwszej wartości|Etykieta wykresu
        'Różnica ostatniej i pierwszej wartości'@@reportPartTypeDiff:Różnica ostatniej i pierwszej wartości w okresie`);
  }
};
