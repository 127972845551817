import { Component, OnInit, Input, QueryList, ContentChildren } from '@angular/core';
import { PageAccordionBlockComponent } from './page-accordion-block/page-accordion-block.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ss-page-accordion-panel',
  template: '<ng-content></ng-content>',
  styleUrls: []
})
export class PageAccordionPanelComponent implements OnInit {

  constructor() { }
  @ContentChildren(PageAccordionBlockComponent)
  set blocks(val) {
    this._blocks = val;
    this.updateSubscriptionsAndInitilConditions();
  }
  get blocks(): QueryList<PageAccordionBlockComponent> {
    return this._blocks;
  }

  private subscriptions: Subscription[] = [];

  @Input()
  public onlyOne = false;

  private _blocks: QueryList<PageAccordionBlockComponent>;

  ngOnInit() {
  }

  private updateSubscriptionsAndInitilConditions() {
    this.subscriptions.forEach(e => e.unsubscribe());
    this.subscriptions.length = 0;
    if (this.onlyOne) {
      let isAnyExpanded = false;
      this.blocks.forEach(b => {
        if (isAnyExpanded) {
          b.expand = false;
        }
        isAnyExpanded = isAnyExpanded || b.expand;
        this.subscriptions.push(b.expandedChanged.subscribe(ev =>
          this.blockExpansionChanged(b, ev)
        ));
      });
    }
  }

  private blockExpansionChanged(currentBlock: PageAccordionBlockComponent, status: boolean) {
    if (status) {
      this.blocks.forEach(b => {
        if (b !== currentBlock) {
          b.expand = false;
        }
      });
    }
  }
}
