import { Component } from '@angular/core';
import { HeaderExtensionComponent } from '../../header-extension-host.directive';

@Component({
  selector: 'ss-pump-room-header-extension',
  templateUrl: './pump-room-header-extension.component.html',
  styleUrls: ['./pump-room-header-extension.component.sass']
})
export class PumpRoomHeaderExtensionComponent implements HeaderExtensionComponent {
  constructor() {}

  data: any;

  updateData(data: any) {
    this.data = data;
  }

}
