import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[formNumberMinValidator], form-number[min]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinNumberValidatorDirective, multi: true }]
})
export class MinNumberValidatorDirective implements Validator {
  @Input() min: number;

  validate(control: AbstractControl): { [key: string]: any } {
    return Validators.min(this.min)(control);
  }
}
