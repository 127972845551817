<button class="device-button"
  [ngClass]="{
    'device-button--x-small': size === 'extra-small',
    'device-button--small': size === 'small',
    'device-button--manual': mode === 'manual',
    'device-button--auto': mode === 'auto',
    'is-active': !isBreakdown && isActive,
    'is-breakdown': isBreakdown
  }"
  [ngSwitch]="deviceType"
  >
    <svg *ngSwitchCase="'heater'" class="device-button__icon">
      <use xlink:href='#icon-heater'/>
    </svg>
    <svg *ngSwitchCase="'hydraulic-pump'" class="device-button__icon">
      <use xlink:href='#icon-pump'/>
    </svg>
    <svg *ngSwitchCase="'air-compressor'" class="device-button__icon">
      <use xlink:href='#icon-pressure'/>
    </svg>
    <svg *ngSwitchCase="'main-fan'" class="device-button__icon">
      <use xlink:href='#icon-blower'/>
    </svg>
    <svg *ngSwitchCase="'nozzle-row'" class="device-button__icon">
      <use xlink:href='#icon-valve'/>
    </svg>
    <svg *ngSwitchCase="'hydrant-drive'" class="device-button__icon">
      <use xlink:href='#icon-hydrant'/>
    </svg>
    <svg *ngSwitchCase="'lamp'" class="device-button__icon">
      <use xlink:href='#icon-lamp'/>
    </svg>
    <svg *ngSwitchCase="'oscillator'" class="device-button__icon">
      <use xlink:href='#icon-range'/>
    </svg>

    <span class="device-button__bar">
      <span *ngIf="mode === 'auto'" class="device-button__bar__auto">AUTO</span>
    </span>

    <ss-badge *ngIf="deviceNumber" class="device-button__number" size="small">{{ deviceNumber }}</ss-badge>
</button>
