import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from './auth.service';
import { ResortConfigurationService } from './resort-configuration.service';
import { ResortConfigurationChecks } from './shared/models';

@Directive({
  selector: '[ssdHideIfNotGranted]'
})
export class HideIfNotGrantedDirective {

  private requiresRole: string;
  @Input()
  public set ssdHideIfNotGranted(val: string) {
    this.requiresRole = val;
    this.check();
  }
  public get ssdHideIfNotGranted(): string {
    return this.requiresRole;
  }

  private requiresCapability: ResortConfigurationChecks;
  @Input()
  public set ssdHideIfNotGrantedRequiresCapability(v: ResortConfigurationChecks) {
    this.requiresCapability = v;
    this.check();
  }
  public get ssdHideIfNotGrantedRequiresCapability(): ResortConfigurationChecks {
    return this.requiresCapability;
  }

  private check() {
    if (
      this.as.isAuthorized(this.requiresRole)
      && (
        !this.requiresCapability
        || this.rcs.checkCapability(this.requiresCapability)
      )
    ) {
      this.makeItEnabled();
    } else {
      this.makeItDisabled();
    }
  }

  private makeItDisabled() {
    this.viewContainer.clear();
  }

  private makeItEnabled() {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private as: AuthService,
    private rcs: ResortConfigurationService
  ) { }

}
