export class UserNotificationsSchedule {
  public isSmsNotificationsEnabled = false;
  public isAppNotificationsEnabled = false;

  public weeklySchedule: number[][];
  public snowCannonMinmumNotificationLevel: string[] = ['alert'];
  public pumpRoomMinmumNotificationLevel: string[] = ['alert'];

  public static create(data: any): UserNotificationsSchedule {
    return Object.assign(new UserNotificationsSchedule(), data || {});
  }

  public clone(): UserNotificationsSchedule {
    return Object.assign(new UserNotificationsSchedule(), { ... this });
  }
}
