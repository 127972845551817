import { defer, forkJoin, Observable, of, Subject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

export const forkJoinWithProgress = (arrayOfObservables: Observable<any>[]): Observable<Observable<any>[]>  => defer(() => {
    let counter = 0;
    const percentSubject: Subject<number> = new Subject();

    const modilefiedObservablesList = arrayOfObservables.map(
      item => item.pipe(
        finalize(() => {
          const percentValue = ++counter * 100 /  arrayOfObservables.length;
          percentSubject.next(percentValue);
        })
      )
    );

    const finalResultObservable = forkJoin(modilefiedObservablesList).pipe(
      tap(() => {
       percentSubject.next(100);
       percentSubject.complete();
      })
    );

    return of([finalResultObservable, percentSubject.asObservable()]);
  });
