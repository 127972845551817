import { TimedChartConfigSeriesType } from '.';
import { UnitType } from '../..';
import { D3DrawerChartState } from './d3-drawer-chart-state.model';

export class TimedChartConfigSeries {
  seriesType: TimedChartConfigSeriesType;
  animationTime: number;
  valueFieldName: string;
  labelFieldName = 'labels';
  unit: UnitType;
  storedUnitOverride?: string;
  grid = false;
  isVisibleOnLegend = true;
  opacity: 1|0 = 1;
  seriesName: string;
  primaryColor?: string;
  secondaryColor?: string;
  valueFormatter: (value: any, state: D3DrawerChartState, series: TimedChartConfigSeries) => string;
  fixedMin: number = undefined;
  fixedMax: number = undefined;
}
