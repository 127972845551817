import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ReportingService } from '../reporting.service';
import { Injectable } from '@angular/core';
import { ReportDefinition } from '../../../../../../common';

@Injectable({
  providedIn: 'root'
})
export class ListResolverService implements Resolve<ReportDefinition[]> {

  constructor(private rds: ReportingService) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): ReportDefinition[]
    | Observable<ReportDefinition[]>
    | Observable<never> {
    return this.rds.getDefinitions();
  }
}
