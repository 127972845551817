import { Injectable } from '@angular/core';
import { EndpointMapService } from './endpoint-map.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType, HttpRequest} from '@angular/common/http';
import { AlertsClientService, SettingsClientService, ScheduleClientService, DevicesClientService } from './http-clients';
import { ResortService } from './resort.service';
import { SettingsService } from './settings.service';
import { DocumentationFilesClientService } from './http-clients/documentation-files.client.service';
import { UserNotesClientService } from './http-clients/user-notes.client.service';
import { UserNotesService } from './shared/components/user-notes/user-notes.service';
import { DocumentationFilesService } from './shared/components/documentation-files/documentation-files.service';
import { UserNote, DocumentationFile } from './shared/models';
import { ResortStatisticsService } from './resort-statistics.service';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';
import { UnitConverterService } from './unit-converter.service';
import { MapService } from './application/resort/area/area-map/map.service';
import { environment } from '../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class DataPreloaderService {

  constructor(private resort: ResortService,
    private authService: AuthService,
    private resortStatistics: ResortStatisticsService,
    private scheduleClient: ScheduleClientService,
    private alertsClient: AlertsClientService,
    private documentationFilesClient: DocumentationFilesClientService,
    private userNotesClient: UserNotesClientService,
    private settingsService: SettingsService,
    private uns: UserNotesService,
    private dfs: DocumentationFilesService,
    private e: EndpointMapService,
    private devicesClient: DevicesClientService,
    private settingsClient: SettingsClientService,
    private http: HttpClient,
    private uss: UnitConverterService,
    private ms: MapService
  ) {}

  private preloadedPreviewImage;

  private documentationFilesData: DocumentationFile[];
  private userNotesData: UserNote[];
  private deviceData: any;
  private deviceStatisticsData: any;
  private deviceSeasonLimitsData: any;
  private settingsData: any;
  private alertsData: any;
  private schedules: any;

  private prepareResortDataService() {
    this.settingsService.initialize(this.settingsData, this.preloadedPreviewImage);
    this.uss.updateUnitSet();
    this.uns.initialize(this.userNotesData);
    this.dfs.initialize(this.documentationFilesData);
    this.resort.initialize(this.deviceData, this.alertsData, this.schedules);
    this.resortStatistics.initialize(this.deviceStatisticsData, this.deviceSeasonLimitsData);
  }

  public preload(resortBounds?: any[]): Observable<number> {
    let devicesS = 0;
    let statisticsS = 0;
    let limitsS = 0;
    let settingsS = 0;
    let alertsS = 0;
    let schedulesS = 0;
    let previewImageS = 0;
    let tilesS = 0;

    let documentationFilesS = 0;
    let userNotesS = 0;

    let loadLevel = 0;




    const getLoadLevel = (): number => {
      const newLevel = 0.2 * tilesS
        + 0.15 * devicesS
        + 0.15 * statisticsS
        + 0.15 * limitsS
        + 0.10 * previewImageS
        + 0.05 * settingsS
        + 0.05 * alertsS
        + 0.05 * schedulesS
        + 0.05 * documentationFilesS
        + 0.05 * userNotesS;
      loadLevel = loadLevel > newLevel ? loadLevel : newLevel;

      return loadLevel;
    };

    return new Observable<number>(sub => {
      const interv = setInterval(() => {
        if (loadLevel < 0.95) {
          loadLevel += 0.01;
          sub.next(getLoadLevel());
        }
      }, 11000);
      const updateSub = (
        devicesP?: number,
        statisticsP?: number,
        settingsP?: number,
        alertsP?: number,
        schedulesP?: number,
        documentationFilesP?: number,
        userNotesP?: number,
        previewImageP?: number,
        limitsP?: number,
        tilesP?: number,
      ) => {
        if (previewImageP) {
          previewImageS = previewImageP;
        }
        if (devicesP) {
          devicesS = devicesP;
        }
        if (statisticsP) {
          statisticsS = statisticsP;
        }
        if (settingsP) {
          settingsS = settingsP;
        }
        if (alertsP) {
          alertsS = alertsP;
        }
        if (schedulesP) {
          schedulesS = schedulesP;
        }
        if (documentationFilesP) {
          documentationFilesS = documentationFilesP;
        }
        if (userNotesP) {
          userNotesS = userNotesP;
        }
        if (limitsP) {
          limitsS = limitsP;
        }
        if (tilesP) {
          tilesS = tilesP;
        }

        sub.next(getLoadLevel());

        if (
          devicesS === 1
          && statisticsS === 1
          && settingsS === 1
          && alertsS === 1
          && schedulesS === 1
          && documentationFilesS === 1
          && userNotesS === 1
          && previewImageS === 1
          && limitsS === 1
          && tilesS === 1
        ) {
          this.prepareResortDataService();
          clearInterval(interv);
          sub.complete();
        }
      };

      if(resortBounds && environment.useMapCache) {
        this.ms.seedTilesCache(resortBounds).subscribe(data => {
          updateSub(null, null, null, null, null, null, null, null, null, data.progress);
        });
      } else {
        updateSub(null, null, null, null, null, null, null, null, null, 1);
      }

      this.documentationFilesClient.getAll().subscribe(data => {
        this.documentationFilesData = data;

        updateSub(null, null, null, null, null, 1, null, null, null, null);
      }, (e) => {
        sub.error(e);
      });

      this.userNotesClient.getAll().subscribe(data => {
        this.userNotesData = data;

        updateSub(null, null, null, null, null, null, 1, null, null, null);
      }, (e) => {
        sub.error(e);
      });


      this.scheduleClient.getAll().subscribe(data => {
        this.schedules = data;

        updateSub(null, null, null, null, 1, null, null, null, null, null);
      }, (e) => {
        sub.error(e);
      });

      this.alertsClient.getActive().subscribe(data => {
        this.alertsData = data;

        updateSub(null, null, null, 1, null, null, null, null, null, null);
      }, (e) => {
        sub.error(e);
      });

      this.settingsClient.getAll().subscribe(data => {
        this.settingsData = data;

        updateSub(null, null, 1, null, null, null, null, null, null, null);
      }, (e) => {
        sub.error(e);
      });

      this.getPreviewImage().subscribe(event => {
        if (event.type === HttpEventType.Response) {
          let binary = '';
          const bytes = new Uint8Array( event.body );
          const len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
              binary += String.fromCharCode( bytes[ i ] );
          }
          this.preloadedPreviewImage = 'data:image;base64,' + window.btoa(binary);

          updateSub(null, null, null, null, null, null, null, 1, null, null);
        }

        if (event.type === HttpEventType.DownloadProgress) {
          updateSub(null, null, null, null, null, null, null, event.loaded / (event.total + 1), null, null);
        }
      }, () => {
        sub.error({});
      });

      this.getAllDevices().subscribe(event => {
        if (event.type === HttpEventType.Response) {
          this.deviceData = event.body;

          updateSub(1, null, null, null, null, null, null, null, null, null);
        }

        if (event.type === HttpEventType.DownloadProgress) {
          updateSub(event.loaded / (event.total + 1), null, null, null, null, null, null, null, null);
        }
      }, () => {
        sub.error({});
      });

      this.getAllDeviceStatistics().subscribe(event => {
        if (event.type === HttpEventType.Response) {
          this.deviceStatisticsData = event.body;

          updateSub(null, 1, null, null, null, null, null, null, null, null);
        }

        if (event.type === HttpEventType.DownloadProgress) {
          updateSub(null, event.loaded / (event.total + 1), null, null, null, null, null, null, null);
        }
      }, () => {
        sub.error({});
      });

      this.getAllDeviceSeasonLimits().subscribe(event => {
        if (event.type === HttpEventType.Response) {
          this.deviceSeasonLimitsData = event.body;

          updateSub(null, null, null, null, null, null, null, null, 1, null);
        }

        if (event.type === HttpEventType.DownloadProgress) {
          updateSub(null, null, null, null, null, null, null, event.loaded / (event.total + 1), null);
        }
      }, () => {
        sub.error({});
      });
    });
  }

  private getPreviewImage(): Observable<any> {
    const options = {
      ... httpOptions,
      reportProgress: true,
      responseType: 'arraybuffer' as any
    };

    return this.http.request(
      new HttpRequest('GET', this.e.tilesPreview + '?bearer=' + this.authService.getApiKey(), null, options));
  }

  private getAllDevices(): Observable<any> {
    const options = { ... httpOptions, reportProgress: true };

    return this.http.request(new HttpRequest('GET', this.e.devices, null, options));
  }

  private getAllDeviceStatistics(): Observable<any> {
    const options = { ... httpOptions, reportProgress: true };

    return this.http.request(new HttpRequest('GET', this.e.deviceStatistics, null, options));
  }
  private getAllDeviceSeasonLimits(): Observable<any> {
    const options = { ... httpOptions, reportProgress: true };

    return this.http.request(new HttpRequest('GET', this.e.deviceSeasonLimits, null, options));
  }
}
