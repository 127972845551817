import { DeviceSystemType } from '../../../../../common';
import { AbstractDevice } from './abstract-device.model';
import { PumpRoom } from './pump-room.model';
import { WaterPipeline } from './water-pipeline.model';

export class WaterReservoir extends AbstractDevice {
    systemType: DeviceSystemType = 'WaterReservoir';

    position: any;
    symbol: string;
    configuration: {
      levelDisplay: 'height'|'volume'|'both';
      hasWaterThermometer: boolean;
      volumeCalculationSlices: [
        {
          lowerSurfaceHeight: number;
          lowerSurfaceArea: number;
          upperSurfaceHeight: number;
          upperSurfaceArea: number;
        }
      ];
    };

    currentWaterTemperature: number;
    currentWaterLevel: number;
    currentWaterVolume: number;
    minimumWaterLevel: number;
    maximumWaterLevel: number;

    get levelPercent(): number {
        const min = parseFloat(`${this.minimumWaterLevel}`) || 0;
        const max = parseFloat(`${this.maximumWaterLevel}`) || 1;
        const cur = parseFloat(`${this.currentWaterLevel}`) || 0;
        const lvl = (cur - min > 0 ? cur - min : 0) / (max - min > 0 ? max - min : 1);
        return 100 * (lvl >= 1 ? 1 : lvl);
    }

    waterPipelines: Map<string, WaterPipeline> = new Map();

}
