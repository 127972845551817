<div class="component" [ngClass]="{
    'highlighted': valve.isSelectedByDeviceIds(selectedPipelineIds),
    'breakdown': valve.isBreakdown
}">
    <div class="info-row">
      <div class="row">
        <div class="col">
          <svg class="icon">
            <use xlink:href='#icon-valve-2' />
          </svg>
          <p>{{ valve.symbol }}</p>
          <p>{{ valve.typeDescription }}&nbsp;</p>
        </div>
        <div class="col-auto" *ngIf="valve.openedPercentVar">
          <p>{{ (valve.openedPercent || 0) | number: '1.0-0' }}%</p>
        </div>
      </div>
    </div>
    <div class="status-row" *ngIf="valve.openCommandVar && valve.closeCommandVar">
        <button
            (mousedown)="open()"
            (mouseup)="stopOpening()"
            (mouseout)="stopOpening()"
            [ngClass]="{
                'btn-default': !runOpenTimer,
                'btn-primary': runOpenTimer
            }"
            [disabled]="!valve.isContinous && valve.isWorking"
            class="btn">
            <svg class="icon">
                <use xlink:href='#icon-chevron-left' />
            </svg>
            <ng-container i18n="Otwórz|Ststus pracy w pompownii@@pumproomDetailStatusOpen">Otwórz</ng-container>

        </button>
        <button
            (mousedown)="close()"
            (mouseup)="stopClosing()"
            (mouseout)="stopClosing()"
            [ngClass]="{
                'btn-default': !runCloseTimer,
                'btn-primary': runCloseTimer
            }"
            [disabled]="!valve.isContinous && valve.isWorking"
            class="btn">
            <ng-container i18n="Zamknij|Ststus pracy w pompownii@@pumproomDetailStatusClose">Zamknij</ng-container>
            <svg class="icon">
                <use xlink:href='#icon-chevron-right' />
            </svg>
        </button>
    </div>
    <div class="status-row">
        <div>
            <ss-pump-room-detail-status [status]="valve.computedStatus"></ss-pump-room-detail-status>
        </div>
        <div>
            <ss-pump-room-detail-status *ngIf="valve.isOpen" status="valve-is-open"></ss-pump-room-detail-status>
            <ss-pump-room-detail-status *ngIf="valve.isClosed" status="valve-is-closed"></ss-pump-room-detail-status>
        </div>
    </div>
    <div class="status-row" *ngIf="valve.isManual">
      <ss-pump-room-detail-status status="working-in-manual-mode" class="w-100"></ss-pump-room-detail-status>
    </div>
</div>
