import { AbstractDevice } from './abstract-device.model';
import { DeviceSystemType } from '../../../../../common';

export class CompressorRoom extends AbstractDevice {
    systemType: DeviceSystemType = 'CompressorRoom';

    position: any;
    symbol: string;

    isLocked = false;

    computedAirFlow: number;
    maximumAirFlow: number;
    computedAirPressure: number;
    maximumAirPressure: number;
}
