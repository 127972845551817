<ss-page>

  <ss-weather-forecast [forecast]="forecast" [meteoStations]="meteoStations" [selectedSchedule]="selectedSchedule"></ss-weather-forecast>

  <!-- <ss-single-schema-editor *ngFor="let s of schedules"
    [schedule]="s"
    [areas]="areas"
    [statistics]="statistics"
    [isSelected]="s === selectedSchedule"
    (openTimeRangePopup)="currentPopupRequest = $event"
    (selectSchedule)="selectSchedule(s)">
  </ss-single-schema-editor> -->

</ss-page>

<ss-time-range-popup [data]="currentPopupRequest" (needsSaving)="saveSchedule($event)"></ss-time-range-popup>
