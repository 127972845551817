<span class="white-space-nowrap">
    {{ (windSpeed || 0)|number:'1.0-0' }}m/s
    <ng-container *ngIf="windDirection < 225 && windDirection >= 135"
        i18n-title="Północ|Kierunki świata - pełne nazwy@@globalSymbolWorldDirNorthFull"
        title="Północ"
        i18n="N|Kierunki świata - skróty@@globalSymbolWorldDirNorthAbbr">N</ng-container>
    <ng-container *ngIf="windDirection < 315 && windDirection >= 225"
        i18n-title="Wschód|Kierunki świata - pełne nazwy@@globalSymbolWorldDirEastFull"
        title="Wschód"
        i18n="E|Kierunki świata - skróty@@globalSymbolWorldDirEastAbbr">E</ng-container>
    <ng-container *ngIf="windDirection < 45 && windDirection >= 315"
        i18n-title="Południe|Kierunki świata - pełne nazwy@@globalSymbolWorldDirSouthFull"
        title="Południe"
        i18n="S|Kierunki świata - skróty@@globalSymbolWorldDirSouthAbbr">S</ng-container>
    <ng-container *ngIf="windDirection < 135 && windDirection >= 45"
        i18n-title="Zachód|Kierunki świata - pełne nazwy@@globalSymbolWorldDirWestFull"
        title="Zachód"
        i18n="W|Kierunki świata - skróty@@globalSymbolWorldDirWestAbbr">W</ng-container>
</span>
<div class="dotted-scale dotted-scale--small">
    <span class="dotted-scale__dot dotted-scale__dot--filled"></span>
    <span class="dotted-scale__dot" [ngClass]="{ 'dotted-scale__dot--filled': windSpeed>2 }"></span>
    <span class="dotted-scale__dot" [ngClass]="{ 'dotted-scale__dot--filled': windSpeed>4 }"></span>
    <span class="dotted-scale__dot" [ngClass]="{ 'dotted-scale__dot--filled': windSpeed>6 }"></span>
    <span class="dotted-scale__dot" [ngClass]="{ 'dotted-scale__dot--filled': windSpeed>9 }"></span>
    <span class="dotted-scale__dot" [ngClass]="{ 'dotted-scale__dot--filled': windSpeed>12 }"></span>
    <span class="dotted-scale__dot" [ngClass]="{ 'dotted-scale__dot--filled': windSpeed>16 }"></span>
    <span class="dotted-scale__dot" [ngClass]="{ 'dotted-scale__dot--filled': windSpeed>20 }"></span>
    <span class="dotted-scale__dot" [ngClass]="{ 'dotted-scale__dot--filled': windSpeed>25 }"></span>
</div>
