<div class="component" [ngClass]="{
    'highlighted': coolingTower.isSelectedByDeviceIds(selectedPipelineIds),
    'breakdown': coolingTower.isBreakdown
}">
    <div class="info-row">
        <svg class="icon">
            <use xlink:href='#icon-cooling-tower' />
        </svg>
        <p>{{ coolingTower.symbol }}</p>
        <p>{{ coolingTower.typeDescription }}&nbsp;</p>
    </div>

    <div class="status-row">
        <ss-pump-room-detail-status [status]="coolingTower.computedStatus"></ss-pump-room-detail-status>
        <p *ngIf="coolingTower.currentWaterTemperatureVar">
            <svg>
                <use xlink:href="#icon-tap" />
            </svg>
            {{ (coolingTower.currentWaterTemperature || 0) | number:'1.1-1' }}&nbsp;&deg;C
        </p>
        <p *ngIf="coolingTower.currentAirTemperatureVar">
            <svg>
                <use xlink:href="#icon-termo-dry"/>
            </svg>
            {{ (coolingTower.currentAirTemperature || 0) | number:'1.1-1' }}&nbsp;&deg;C
        </p>
        <p *ngIf="coolingTower.isHeaterOnVar">
          <svg [ngClass]="{
            'fill-light-grey': !coolingTower.isHeaterOn,
            'fill-green': coolingTower.isHeaterOn
          }">
            <use xlink:href="#icon-heater" />
          </svg>
        </p>
    </div>
    <div class="status-row" *ngIf="coolingTower.isManual">
      <ss-pump-room-detail-status status="working-in-manual-mode" class="w-100"></ss-pump-room-detail-status>
    </div>
</div>
