// hacks for enabling leaflet plugins
import PouchDB from 'pouchdb';
(window as any).PouchDB = PouchDB;
import 'leaflet';
import 'leaflet-draw';
import 'leaflet-edgebuffer';
import 'leaflet.tilelayer.pouchdbcached';

import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  ErrorHandler,
  TRANSLATIONS_FORMAT,
  Injectable,
  LOCALE_ID,
  Inject
} from '@angular/core';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule} from '@asymmetrik/ngx-leaflet-draw';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragulaModule } from 'ng2-dragula';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AuthJwtTokenInterceptor } from './auth-jwt-token.interceptor';
import { AppComponent } from './app.component';
import { StartScreenComponent, StartScreenAuthGuard } from './start-screen/start-screen.component';
import { ErrorsHandler } from './error-handler';

import { ApplicationComponent } from './application/application.component';
import { ApplicationGuard } from './application-guard.service';
import { PumpRoomsComponent } from './application/pump-rooms/pump-rooms.component';
import { RecentNotificationsComponent } from './application/recent-notifications/recent-notifications.component';
import { SettingsComponent } from './application/settings/settings.component';
import { ResortComponent } from './application/resort/resort.component';
import { AreaListComponent } from './application/resort/area/area-list/area-list.component';
import { AreaMapComponent } from './application/resort/area/area-map/area-map.component';
import { SnowCannonPanelComponent } from './application/resort/area/area-map/snow-cannon-panel/snow-cannon-panel.component';
import { AreaPanelComponent } from './application/resort/area/area-map/area-panel/area-panel.component';
import { HeaderExtensionHostDirective } from './application/header-extension-host.directive';
import { AreasRecentComponent } from './application/resort/areas-recent/areas-recent.component';
import { AreasListComponent } from './application/resort/areas/areas-list/areas-list.component';
import { AreasMapComponent } from './application/resort/areas/areas-map/areas-map.component';
import { AreaHeaderExtensionComponent } from './application/resort/area/area-header-extension/area-header-extension.component';
import { AreasHeaderExtensionComponent } from './application/resort/areas/areas-header-extension/areas-header-extension.component';
import { PumpRoomsListComponent } from './application/pump-rooms/pump-rooms-list/pump-rooms-list.component';
import { PumpRoomDetailComponent } from './application/pump-rooms/pump-room-detail/pump-room-detail.component';
import { UsersComponent } from './application/users/users.component';
import { UsersListComponent } from './application/users/users-list/users-list.component';
import { UserEditComponent } from './application/users/user-edit/user-edit.component';
import { WeatherAndSchedulesComponent } from './application/weather-and-schedules/weather-and-schedules.component';
import { WeatherAndSchedulesEditScheduleComponent } from './application/weather-and-schedules/edit-schedule/edit-schedule.component';
import { WeatherAndSchedulesListComponent } from './application/weather-and-schedules/list/list.component';
import { SingleSchemaEditorComponent } from './application/weather-and-schedules/list/single-schema-editor/single-schema-editor.component';
import { TimeRangePopupComponent } from './application/weather-and-schedules/time-range-popup/time-range-popup.component';
import { WeatherForecastComponent } from './application/weather-and-schedules/list/weather-forecast/weather-forecast.component';
import { AuditTrailComponent } from './application/audit-trail/audit-trail.component';
import { UserProfileComponent } from './application/user-profile/user-profile.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SnowCannonPositionHistoryComponent } from './application/snow-cannon-position-history/snow-cannon-position-history.component';
import { AreaComponent } from './application/resort/area/area.component';
import { AreasComponent } from './application/resort/areas/areas.component';
import { DisableIfNotGrantedDirective } from './disable-if-not-granted.directive';
import { HideIfNotGrantedDirective } from './hide-if-not-granted.directive';
import {
  ConfigureWaterPipelineModalComponent
} from './application/pump-rooms/pump-room-detail/configure-water-pipeline-modal/configure-water-pipeline-modal.component';
import {
  FullSchemaComponent,
  DrawableComponentComponent,
  LineComponent,
  TextComponent,
  ConnectorComponent,
  PumpComponent,
  FilterComponent,
  ValveComponent,
  CoolingTowerComponent,
  SensorComponent,
  VisualComponentComponent,
  LevelIndicatorComponent
} from './application/pump-rooms/pump-room-detail/full-schema';

import {
  WaterPipelineTileComponent,
  PumpTileComponent,
  FilterTileComponent,
  ValveTileComponent,
  CoolingTowerTileComponent,
  AdditionalModeOfOperationTileComponent,
  CompressorTileComponent,
  AerationLevelTileComponent
} from './application/pump-rooms/pump-room-detail/simplified-schema-tiles';

import {
  EditorComponent,
  ResortEditorComponent,
  EditorMenuComponent,
  EditConnectionPointsComponent,
  EditAreasComponent,
  PumpRoomFullSchemaEditorComponent
} from './application/editor';

import {
  ResetUserPasswordComponent
} from './application/users/user-edit/reset-user-password/reset-user-password.component';
import {
  ResizedDirective
} from './resized-event.directive';

import { SnowCannonConfigurationModalComponent } from
'./application/resort/area/snow-cannon-configuration-modal/snow-cannon-configuration-modal.component';
import { ResortAreaConfigurationModalComponent } from
'./application/resort/area/resort-area-configuration-modal/resort-area-configuration-modal.component';
import { WorkingAreaConfigurationModalComponent } from
'./application/resort/area/working-area-configuration-modal/working-area-configuration-modal.component';
import { ConnectionPointConfigurationModalComponent } from
'./application/resort/area/connection-point-configuration-modal/connection-point-configuration-modal.component';
import { AreaConfigurationComponent } from
'./application/resort/area/area-configuration/area-configuration.component';
import { AreaStatsAndReportsTabComponent } from
'./application/resort/area/area-stats-and-reports-tab/area-stats-and-reports-tab.component';
import { SnowCannonStatsAndReportsTabComponent } from
'./application/resort/area/snow-cannon-stats-and-reports-tab/snow-cannon-stats-and-reports-tab.component';
import { SnowCannonSettingsTabComponent } from
'./application/resort/area/snow-cannon-settings-tab/snow-cannon-settings-tab.component';
import { AreaSettingsTabComponent } from
'./application/resort/area/area-settings-tab/area-settings-tab.component';
import { MeteoStationConfigurationModalComponent } from
'./application/weather-and-schedules/meteo-station-configuration-modal/meteo-station-configuration-modal.component';
import { HydrantComponent } from
'./application/resort/area/area-configuration/info-components/hydrant/hydrant.component';
import { CannonComponent } from
'./application/resort/area/area-configuration/info-components/cannon/cannon.component';
import { AreaCannonsStatusDisplayComponent } from
'./application/resort/area/area-cannons-status-display/area-cannons-status-display.component';
import { PumpRoomHeaderExtensionComponent } from
'./application/pump-rooms/pump-room-header-extension/pump-room-header-extension.component';
import { SnowCannonPanelMeteoBarComponent } from './application/resort/area/area-map/snow-cannon-panel/meteo-bar/meteo-bar.component';

import {
  MeteoConditionsSourceSelectionModalComponent,
  ProgressOfObservablesModalComponent,
  MassOperationsModalComponent
} from './application/modals';
import {
  ReportingComponent,
  ReportingDefinitionsListComponent,
  ReportingSingleReportComponent,
  ReportingSingleReportPageHeaderComponent,
  ReportingSingleReportPageToolbarComponent,
  ReportingSingleReportTimeRangeToolbarComponent,
  ReportingSingleReportPageFooterComponent,
  ReportingSingleTimeSelectionToolbarComponent,
  ReportingSingleReportPartDisplayComponent
} from './application/reporting';
import {
  PowerSourcesComponent,
  PowerSourcesDetailComponent,
  PowerSourcesListComponent,
  PowerSourceHeaderExtensionComponent,
  ElectricalLineTileComponent,
  ConfigureElectricalLineModalComponent,
  ConfigurePowerSourceModalComponent
} from './application/power-sources';
import { RenameModalComponent } from './application/modals/rename-modal/rename-modal.component';
import { ReportingPartCreatorModalComponent } from './application/reporting/single/part-creator-modal/part-creator-modal.component';
import { ReportingHeaderExtensionComponent } from './application/reporting/header-extension/header-extension.component';
import { setupDayJSLocale } from '../../../common';
import { ReportingExportsRecentComponent } from './application/reporting/exports/recent/recent.component';

@Injectable()
export class MyUrlSerializer extends DefaultUrlSerializer implements UrlSerializer  {
  serialize(tree: UrlTree): string {
    return super.serialize(tree).replace(/\(|\/\w+(-\w+)*:[^)]*\)/g, '');
  }
}

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        LeafletModule,
        LeafletDrawModule,
        PerfectScrollbarModule,
        NgSelectModule,
        DragulaModule.forRoot(),
        AngularMyDatePickerModule,
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    declarations: [
        AppComponent,
        StartScreenComponent,
        ApplicationComponent,
        PumpRoomsComponent,
        RecentNotificationsComponent,
        SettingsComponent,
        ResortComponent,
        AreaListComponent,
        AreaMapComponent,
        SnowCannonPanelComponent,
        AreaPanelComponent,
        HeaderExtensionHostDirective,
        AreaHeaderExtensionComponent,
        AreasRecentComponent,
        AreasListComponent,
        AreasMapComponent,
        AreasHeaderExtensionComponent,
        PumpRoomsListComponent,
        PumpRoomDetailComponent,
        UsersComponent,
        UsersListComponent,
        UserEditComponent,
        WeatherAndSchedulesEditScheduleComponent,
        WeatherAndSchedulesComponent,
        WeatherAndSchedulesListComponent,
        SingleSchemaEditorComponent,
        TimeRangePopupComponent,
        WeatherForecastComponent,
        AuditTrailComponent,
        UserProfileComponent,
        SnowCannonPositionHistoryComponent,
        AreaComponent,
        AreasComponent,
        DisableIfNotGrantedDirective,
        HideIfNotGrantedDirective,
        MassOperationsModalComponent,
        WaterPipelineTileComponent,
        PumpTileComponent,
        FilterTileComponent,
        ValveTileComponent,
        CoolingTowerTileComponent,
        ConfigureWaterPipelineModalComponent,
        FullSchemaComponent,
        DrawableComponentComponent,
        LineComponent,
        TextComponent,
        ConnectorComponent,
        PumpComponent,
        FilterComponent,
        ValveComponent,
        CoolingTowerComponent,
        SensorComponent,
        VisualComponentComponent,
        EditorComponent,
        ResortEditorComponent,
        EditorMenuComponent,
        EditConnectionPointsComponent,
        EditAreasComponent,
        PumpRoomFullSchemaEditorComponent,
        ResetUserPasswordComponent,
        ResizedDirective,
        SnowCannonConfigurationModalComponent,
        ResortAreaConfigurationModalComponent,
        WorkingAreaConfigurationModalComponent,
        ConnectionPointConfigurationModalComponent,
        AreaConfigurationComponent,
        AreaStatsAndReportsTabComponent,
        SnowCannonStatsAndReportsTabComponent,
        SnowCannonSettingsTabComponent,
        AreaSettingsTabComponent,
        AdditionalModeOfOperationTileComponent,
        MeteoStationConfigurationModalComponent,
        ProgressOfObservablesModalComponent,
        HydrantComponent,
        CannonComponent,
        AreaCannonsStatusDisplayComponent,
        PumpRoomHeaderExtensionComponent,
        MeteoConditionsSourceSelectionModalComponent,
        SnowCannonPanelMeteoBarComponent,
        ReportingComponent,
        ReportingDefinitionsListComponent,
        ReportingSingleReportComponent,
        ReportingSingleReportPageHeaderComponent,
        ReportingSingleReportPageToolbarComponent,
        ReportingSingleReportTimeRangeToolbarComponent,
        ReportingSingleReportPageFooterComponent,
        PowerSourcesComponent,
        PowerSourcesDetailComponent,
        PowerSourcesListComponent,
        PowerSourceHeaderExtensionComponent,
        ElectricalLineTileComponent,
        ConfigureElectricalLineModalComponent,
        RenameModalComponent,
        ConfigurePowerSourceModalComponent,
        CompressorTileComponent,
        AerationLevelTileComponent,
        LevelIndicatorComponent,
        ReportingPartCreatorModalComponent,
        ReportingSingleReportPartDisplayComponent,
        ReportingSingleTimeSelectionToolbarComponent,
        ReportingHeaderExtensionComponent,
        ReportingExportsRecentComponent,
    ],
    providers: [
        StartScreenAuthGuard,
        ApplicationGuard,
        { provide: HTTP_INTERCEPTORS, useClass: AuthJwtTokenInterceptor, multi: true },
        { provide: ErrorHandler, useClass: ErrorsHandler },
        { provide: UrlSerializer, useClass: MyUrlSerializer },
        { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(@Inject(LOCALE_ID) locale) {
    setupDayJSLocale(locale);
  }
}
