import { Device, DeviceSystemType } from '../../../../../common';

export abstract class AbstractDevice implements Device {
  public abstract symbol;
  public abstract systemType: DeviceSystemType;

  public id: string;
  public connectionStatus: number;
  public operationMode: number;
  public lastConnected: string;

  public hardwareAlerts: string[];
  public hardwareWarnings: string[];
  public hardwareNotifications: string[];

  public notifyAboutReachingComputedStatusses: string[];

  public isPurelySynthetic: boolean;

  private internalComputedStatus: string;
  public get computedStatus(): string {
    return this.internalComputedStatus || 'connection-lost';
  }
  public set computedStatus(val: string) {
    this.internalComputedStatus = val;
  }

  public update(data: any) {
    return Object.assign(this, data);
  }
}
