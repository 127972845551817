import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[ssdAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit{
  @Input()
  public ssdAutoFocus: boolean;

  constructor(
      private elementRef: ElementRef
  ){}

  ngAfterViewInit(){
    if(this.ssdAutoFocus) {
      this.elementRef.nativeElement.focus();
    }
  }

}
