import { Injectable, EventEmitter, Type, ComponentRef } from '@angular/core';
import { HeaderExtensionComponent } from './application/header-extension-host.directive';

export class HeaderExtensionComponentItem {
    component: Type<HeaderExtensionComponent>;
    data: any;
    ref?: ComponentRef<HeaderExtensionComponent>;
}

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    onTitleChange: EventEmitter<string> = new EventEmitter();
    onComponentChange: EventEmitter<HeaderExtensionComponentItem> = new EventEmitter();

    setUpdateHeader(title: string, component: Type<HeaderExtensionComponent> = null, data: any = null) {
        this.onTitleChange.emit(title);
        if (component) {
            this.onComponentChange.emit({ component, data });
        } else {
            this.onComponentChange.emit(null);
        }
    }

}
