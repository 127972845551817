<div class="row">
  <div *ngFor="let f of files" [ngClass]="{ 'col-6': !extraWide, 'col-3': extraWide }">
    <div class="file">
      <div class="type">
        <svg>
          <use xlink:href='#icon-flat-file' />
        </svg>
        <span>{{ f.format }}</span>
      </div>
      <a href="{{ f.fileUrl }}" target="_blank"><span>{{ f.title }}</span></a>
    </div>
  </div>
</div>
