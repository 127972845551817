<ng-container *ngIf="data">
  <div class="navbar__group stretch">
    <h2 class="navbar__subheader" *ngIf="data.resortName">
      <a routerLink="/application/resort">{{ data.resortName }}</a>
    </h2>
  </div>
  <div class="navbar__group pl-4">
    <button class="navbar__btn navbar__btn--switchable"
      [ngClass]="{ 'is-active': data.isMap }" [routerLink]="['/application/resort/areas/map']">
      <svg>
        <use xlink:href="#icon-map" />
      </svg>
      <span i18n="Mapa|Etykieta przycisku przełączającego na widok mapy@@areaHeaderExtMapLabel">Mapa</span>
    </button>
    <button class="navbar__btn navbar__btn--switchable"
      [ngClass]="{ 'is-active': !data.isMap }" [routerLink]="['/application/resort/areas/list']">
      <svg>
        <use xlink:href="#icon-list"/>
      </svg>
      <span i18n="Lista|Etykieta przycisku przełączającego na widok listy@@areaHeaderExtListLabel">Lista</span>
    </button>
  </div>
</ng-container>