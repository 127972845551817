<ss-page-toolbar>
  <div class="flex-row w-100 justify-self-stretch">
    <div class="d-flex align-items-center">
      <p i18n="Zakres dat raportu|Wybór parametrów raportu Zakres dat raportu@@report-detail-time-range">Zakres dat raportu</p>

      <button class="date-range--link"
        [disabled]="result.isLive"
        (click)="isShowDateRagneToolbarVisible = !isShowDateRagneToolbarVisible">
        <span>{{ result.from.tz().format('YYYY-MM-DD') }} - {{ result.to.tz().format('YYYY-MM-DD') }}</span>
        <svg class="date-range--chevron" [ngClass]="{'date-range--chevron--rotated': isShowDateRagneToolbarVisible }">
          <use xlink:href="#icon-chevron-down" />
        </svg>
      </button>
      <div class="filler"></div>
      <p i18n="Długość wyświetlanego przedziału czasu|Wybór parametrów raportu Długość wyświetlanego przedziału czasu@@report-detail-display-duration">Długość wyświetlanego przedziału czasu</p>
      <div class="btn-group">
        <button [disabled]="isLoading || result.isLive || precision !== '5m'"
          [ngClass]="{ 'btn-pressed': duration === '4h' }"
          (click)="changeDuration('4h')"
          class="btn btn-dark">4h</button>
        <button [disabled]="isLoading || result.isLive || precision !== '5m'"
          [ngClass]="{ 'btn-pressed': duration === '8h' }"
          (click)="changeDuration('8h')"
          class="btn btn-dark">8h</button>
        <button [disabled]="isLoading || result.isLive"
          [ngClass]="{ 'btn-pressed': duration === '12h' }"
          (click)="changeDuration('12h')"
          class="btn btn-dark">12h</button>
        <button [disabled]="isLoading || result.isLive"
          [ngClass]="{ 'btn-pressed': duration === '1d' }"
          (click)="changeDuration('1d')"
          class="btn btn-dark">1d</button>
        <button [disabled]="isLoading || result.isLive || precision === '5m' || precision === '15m' || overallDuration < 3"
          [ngClass]="{ 'btn-pressed': duration === '3d' }"
          (click)="changeDuration('3d')"
          class="btn btn-dark">3d</button>
        <button [disabled]="isLoading || result.isLive || precision === '5m' || precision === '15m' || overallDuration < 7"
          [ngClass]="{ 'btn-pressed': duration === '7d' }"
          (click)="changeDuration('7d')"
          class="btn btn-dark">7d</button>
        <button [disabled]="isLoading
          || result.isLive
          || (precision === '5m' && overallDuration > 2)
          || (precision === '15m' && overallDuration > 7)
          || (precision === '1h' && overallDuration > 28)
        " [ngClass]="{ 'btn-pressed': duration === 'all' }"
          (click)="changeDuration('all')"
          class="btn btn-dark">wszystko</button>
      </div>
      <p i18n="Dokładność|Wybór parametrów raportu Dokładność@@report-detail-precision">Dokładność</p>
      <div class="btn-group">
        <button [disabled]="isLoading || result.isLive || overallDuration > 14"
          [ngClass]="{ 'btn-pressed': precision === '5m' }"
          (click)="changePrecision('5m')"
          class="btn btn-dark">5m</button>
        <button [disabled]="isLoading || result.isLive || overallDuration > 14"
          [ngClass]="{ 'btn-pressed': precision === '15m' }"
          (click)="changePrecision('15m')"
          class="btn btn-dark">15m</button>
        <button [disabled]="isLoading || result.isLive || overallDuration > 14"
          [ngClass]="{ 'btn-pressed': precision === '1h' }"
          (click)="changePrecision('1h')"
          class="btn btn-dark">1h</button>
      </div>

      <div class="is-live-display" [ngClass]="{ 'is-live-display-on': result.isLive }">

        <ss-checkbox [isChecked]="result.isLive" (hasChanged)="changeLiveReload($event)">
          <ng-container i18n="Odświeżanie na żywo|Odświeżanie raportu na żywo@@report-detail-live-refresh">Odświeżanie na żywo</ng-container>
        </ss-checkbox>
      </div>


    </div>
  </div>

  <div class="date-range-toolbar" [ngClass]="{'date-range-toolbar--visible': isShowDateRagneToolbarVisible && !result.isLive}">
    <div class="d-flex align-items-center pt-2">
      <div class="align-self-start">
        <p class="date-range-toolbar--list">
          <ng-container i18n="Raport za|Wybór nazwanego okresu za który można wybrać raport@@report-detail-time-toolbar-report-for">Raport za</ng-container><br />
          <ng-container *ngIf="predefinedReportRanges.currentSeason">
            <a (click)="changeDate(predefinedReportRanges.currentSeason)">
              <ng-container i18n="Bieżący sezon|Okres Bieżący sezon@@report-detail-time-toolbar-current season">Bieżący sezon</ng-container>
              ({{ predefinedReportRanges.currentSeason.label }})</a><br /><br />
          </ng-container>
          <a (click)="changeDate(predefinedReportRanges.lastWeek)" i18n="Ostatni tydzień|Okres Ostatni tydzień@@report-detail-time-toolbar-last-week">Ostatni tydzień</a><br />
          <a (click)="changeDate(predefinedReportRanges.lastMonth)" i18n="Ostatni miesiąc|Okres Ostatni miesiąc@@report-detail-time-toolbar-last-month">Ostatni miesiąc</a><br />
          <a (click)="changeDate(predefinedReportRanges.currentMonth)" i18n="Bieżący miesiąc kalendarzowy|Okres Bieżący miesiąc kalendarzowy@@report-detail-time-toolbar-current-month">Bieżący miesiąc kalendarzowy</a><br />
          <a (click)="changeDate(predefinedReportRanges.previousMonth)" i18n="Poprzedni miesiąc kalendarzowy|Okres Poprzedni miesiąc kalendarzowy@@report-detail-time-toolbar-previous-month">Poprzedni miesiąc kalendarzowy</a><br />
        </p>
      </div>
      <div class="align-self-start">
        <p class="date-range-toolbar--list">
          <ng-container i18n="Sezon|Okres Sezon@@report-detail-time-toolbar-any-season">Sezon</ng-container><br />
          <ng-container *ngFor="let x of predefinedReportRanges.seasons; let last = last">
            <a [ngClass]="{ 'selected': selectedRange === x }"
              (click)="changeDate(x)">{{ x.label }}</a><ng-container *ngIf="!last">, </ng-container>
          </ng-container>
          <br /><br />
          <ng-container i18n="Raport za rok|Okres Raport za rok@@report-detail-time-toolbar-any-year">Raport za rok</ng-container><br />
          <ng-container *ngFor="let x of predefinedReportRanges.years; let last = last">
            <a [ngClass]="{ 'selected': selectedRange === x }"
              (click)="changeDate(x)">{{ x.label }}</a><ng-container *ngIf="!last">, </ng-container>
          </ng-container>
        </p>
      </div>
      <div class="align-self-start" *ngIf="selectedRange && selectedRange.months">
        <p class="date-range-toolbar--list">
          <ng-container i18n="Miesiące w okresie|Okres Miesiące w okresie@@report-detail-time-toolbar-any-month">Miesiące w okresie</ng-container> {{ selectedRange.label }}<br />
          <ng-container *ngFor="let x of selectedRange.months; let last = last">
            <a (click)="changeDate(x)">{{ x.label }}</a><ng-container *ngIf="!last">, </ng-container>
          </ng-container>
        </p>
      </div>
      <div class="filler"></div>
      <div class="align-self-start">
        <div class="d-flex align-items-center">
          <p class="white-space-nowrap" i18n="Inny zakres dat|Inny zakres dat@@report-detail-time-toolbar-custom-dates">Inny zakres dat</p>
          <ss-range-datepicker
            [disabled]="isLoading || result.isLive"
            [min]="minStartDate"
            [max]="maxEndDate"
            [selectedStartDate]="from"
            [selectedEndDate]="to"
            (startDateChanged)="fromDateChanged($event)"
            (endDateChanged)="toDateChanged($event)"
            ></ss-range-datepicker>
          <button [disabled]="isLoading || result.isLive"
            type="button"
            class="btn white-space-nowrap px-2 btn-primary"
            (click)="setCustomDate({from: from, to: to})"
            i18n="Zmień zakres|Button Zmień zakres@@report-detail-time-toolbar-btn-set-custom-date">Zmień zakres</button>
        </div>
        <p class="date-range-toolbar--list" style="max-width: 100%;" *ngIf="predefinedReportRanges.lastUsed.length > 0">
          <ng-container i18n="Ostatnio szukane zakresy dat|Okres Ostatnio szukane zakresy dat@@report-detail-time-toolbar-last-searched-time-ranges">Ostatnio szukane zakresy dat</ng-container>:<br />
          <ng-container *ngFor="let x of predefinedReportRanges.lastUsed; let last = last">
            <a (click)="setCustomDate(x)">{{ x.label }}</a><ng-container *ngIf="!last">, </ng-container>
          </ng-container>
        </p>
      </div>
    </div>
  </div>
</ss-page-toolbar>