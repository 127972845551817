import { Component, OnInit, OnDestroy } from '@angular/core';
import { User, UserStats } from 'src/app/shared/models';
import { ActivatedRoute } from '@angular/router';
import { SocketioClientService, UsersClientService } from 'src/app/http-clients';
import { Subscription } from 'rxjs';
import { dayjs, isArray } from '../../../../../../common';

@Component({
  selector: 'ss-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.sass']
})
export class UsersListComponent implements OnInit, OnDestroy {

  users: User[];
  chartData: UserStats;

  loggedInUsersSubscription: Subscription;

  refreshInterval: any;

  constructor(
    private ucs: UsersClientService,
    private socket: SocketioClientService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data.subscribe((data: { users: User[]; stats: UserStats }) => {
      this.users = data.users;
      this.chartData = data.stats;
    });

    this.refreshInterval = setInterval(() => {
      const hnow = Math.ceil((dayjs().valueOf() / 1000 / 60)) * 60;
      this.ucs.getStats('0', hnow - 7 * 24 * 3600, hnow).subscribe(data => {
        this.chartData = data;
      });
    }, 10000);

    this.loggedInUsersSubscription = this.socket.loggedInUsers.subscribe((ids: number[]) => {
      if (!isArray(ids)) {
        ids = [];
      }
      if (isArray(this.users)) {
        this.users.forEach(u => {
          u.isLoggedIn = ids.includes(u.id);
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.loggedInUsersSubscription) {
      this.loggedInUsersSubscription.unsubscribe();
    }
    clearInterval(this.refreshInterval);
  }

}
