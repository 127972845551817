<form #form="ngForm" (submit)="save()">
  <ss-page [modal]="true">
    <ss-page-header>
      <ss-translated-report-part-type [partType]="part.partType"></ss-translated-report-part-type>
    </ss-page-header>
    <ng-container *ngIf="!part.partType ; else partTypeIsSelected">
      <ss-page-panel panelTitle="Wykresy" i18n-panelTitle="Wykresy@@reportingPartCreatorAddChartPart">
        <button class="btn d-block w-100 mb-1 btn-primary" (click)="part.partType = 'chart'">
          <ss-translated-report-part-type [partType]="'chart'"></ss-translated-report-part-type>
        </button>
        <!-- <button class="btn d-block w-100 mb-1 btn-primary" (click)="part.partType = 'sum-chart'">
          <ss-translated-report-part-type [partType]="'sum-chart'"></ss-translated-report-part-type>
        </button>
        <button class="btn d-block w-100 mb-1 btn-primary" (click)="part.partType = 'avg-chart'">
          <ss-translated-report-part-type [partType]="'avg-chart'"></ss-translated-report-part-type>
        </button> -->
      </ss-page-panel>
      <ss-page-panel panelTitle="Podsumowania" i18n-panelTitle="Podsumowania@@reportingPartCreatorAddSumamryPart">
        <button class="btn d-block w-100 mb-1 btn-primary" (click)="part.partType = 'sum'">
          <ss-translated-report-part-type [partType]="'sum'"></ss-translated-report-part-type>
        </button>
        <button class="btn d-block w-100 mb-1 btn-primary" (click)="part.partType = 'avg'">
          <ss-translated-report-part-type [partType]="'avg'"></ss-translated-report-part-type>
        </button>
        <button class="btn d-block w-100 mb-1 btn-primary" (click)="part.partType = 'min'">
          <ss-translated-report-part-type [partType]="'min'"></ss-translated-report-part-type>
        </button>
        <button class="btn d-block w-100 mb-1 btn-primary" (click)="part.partType = 'max'">
          <ss-translated-report-part-type [partType]="'max'"></ss-translated-report-part-type>
        </button>
        <button class="btn d-block w-100 mb-1 btn-primary" (click)="part.partType = 'first'">
          <ss-translated-report-part-type [partType]="'first'"></ss-translated-report-part-type>
        </button>
        <button class="btn d-block w-100 mb-1 btn-primary" (click)="part.partType = 'last'">
          <ss-translated-report-part-type [partType]="'last'"></ss-translated-report-part-type>
        </button>
        <button class="btn d-block w-100 mb-1 btn-primary" (click)="part.partType = 'diff'">
          <ss-translated-report-part-type [partType]="'diff'"></ss-translated-report-part-type>
        </button>
      </ss-page-panel>
    </ng-container>
    <ng-template #partTypeIsSelected>
      <ss-page-panel panelTitle="Dodaj serię danych dla" i18n-panelTitle="Dodaj serię danych dla@@reportingPartCreatorAddSeriesForDeviceSelector">
        <div class="row" *ngIf="part.series.length < maxSeriesNumber; else tooMuchSeries">
          <div class="px-1" *ngFor="let d of usedDevices" [ngClass]="{
            'col': usedDevices.length === 1,
            'col-6': usedDevices.length === 2,
            'col-4': usedDevices.length === 3,
            'col-3': usedDevices.length > 3
          }">
            <button type="button" [disabled]="!canEdit" class="btn w-100 btn-link" (click)="addSeries(d)">
              <ss-translated-device-type-symbol [device]="d"></ss-translated-device-type-symbol>
            </button>
          </div>
          <div class="col px-1">
            <button type="button" [disabled]="!canEdit" class="btn w-100 btn-link" (click)="addSeries()" i18n="Opcja dodawania nowej seriir dla Nowego urządzenia@@reportingPartCreatorAddSeriesForNewDevice">Nowego urządzenia</button>
          </div>
        </div>
        <ng-template #tooMuchSeries>
          <p i18n="Można dodać cojanwyżej X serie@@reportingPartCreatorAddSeriesNoOfSeriesMaxedOut">Można dodać cojanwyżej {{ maxSeriesNumber }} serie.</p>
        </ng-template>
      </ss-page-panel>
      <ss-page-panel *ngFor="let s of part.series; index as i;">
        <div class="row">
          <div class="col">
            <p *ngIf="!s.subjectType">
              <ng-container i18n="@@reportingPartCreatorAddSeriesSelectTypeOfDevice">Wybierz rodzaj urządzenia</ng-container>:
              <ng-container *ngFor="let x of acceptableSubjectTypes; let l = last">
                <button type="button" class="btn p-0 btn-inline btn-link"
                  (click)="selectSubjectType(s, x)">
                  <ss-translated-device-type [systemType]="x"></ss-translated-device-type>
                </button><ng-container *ngIf="!l">, </ng-container>
              </ng-container>
            </p>
            <p *ngIf="s.subjectType && !s.subjectId">
              <ng-container i18n="@@reportingPartCreatorAddSeriesSelectDeviceOfType">Wybierz urządzenie z typu</ng-container>&nbsp;
              <ss-translated-device-type [systemType]="s.subjectType"></ss-translated-device-type>:
              <ng-container *ngFor="let x of devicesForSubjectType; let l = last">
                <button type="button" class="btn p-0 btn-inline btn-link"
                  (click)="selectSubjectId(s, x.id)">
                  <ss-translated-device-type-symbol [onlySymbol]="true" [device]="x"></ss-translated-device-type-symbol>
                </button><ng-container *ngIf="!l">, </ng-container>
              </ng-container>
            </p>
            <p *ngIf="s.subjectType && s.subjectId">
              <ng-container i18n="@@reportingPartCreatorSeriesForDeviceName">Seria danych z urządzenia</ng-container>&nbsp;
              <ss-translated-device-type-symbol [device]="getDeviceById(s.subjectId)"></ss-translated-device-type-symbol>
            </p>
          </div>
          <div class="col-auto">
            <button type="button" (click)="removeSeries(s)" class="btn btn-danger" i18n="@@reportingPartCreatorSeriesForDevideButtonRemove">Usuń</button>
          </div>
        </div>
        <div class="row" *ngIf="s.subjectType && s.subjectId">
          <div class="col">
            <form-select [(ngModel)]="s.subjectField" (ngModelChange)="validateSeries()" [name]="'form-select-for-subject-field-series-' + s.subjectId + '-' + s.subjectField + '-' + i">
              <form-label i18n="@@reportingPartCreatorSeriesForDeviceDataFieldLabel">Wybierz pole z danymi</form-label>
              <form-select-option *ngFor="let x of getFieldDescriptionsBySeries(s)" [value]="x.fieldName" [deviceField]="x.fieldName"></form-select-option>
            </form-select>
          </div>
        </div>
      </ss-page-panel>
    </ng-template>

    <ss-page-footer>
      <div></div>
      <ss-page-button-group>
        <button type="submit" [disabled]="!canSave" class="btn btn-primary" i18n="@@reportingPartCreatorButtonSave">Zapisz</button>
        <button type="button" class="btn btn-default" (click)="close()" i18n="@@reportingPartCreatorButtonCancel">Anuluj</button>
      </ss-page-button-group>
    </ss-page-footer>
  </ss-page>
  </form>