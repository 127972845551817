<ng-container *ngIf="!drawable.component.subType || drawable.component.subType==='regular'">
    <svg:use width="101" height="101" xlink:href="#filter" [ngStyle]="{
        transform: drawable.rot
    }"></svg:use>
</ng-container>
<ng-container *ngIf="drawable.component.subType==='auto'">
    <svg:use width="101" height="101" xlink:href="#auto-filter" [ngStyle]="{
        transform: drawable.rot
    }"></svg:use>
</ng-container>
<svg:text *ngIf="drawable.d || drawable.u" x="35" y="50.5" dominant-baseline="middle" text-anchor="end">{{ drawable.component.symbol }}</svg:text>
<svg:text *ngIf="drawable.l || drawable.r" x="50.5" y="75" dominant-baseline="middle" text-anchor="middle">{{ drawable.component.symbol }}</svg:text>
