<div class="form-row" [ngClass]="{ 'is-required': markIsRequired }">
  <div class="form-label" *ngIf="!hideLabel">
    <label [attr.for]="identifier">
      <span #labelContent><ng-content select="form-label"></ng-content></span>
      <ng-container *ngIf="!labelContent.innerHTML.trim()">{{ label }}</ng-container>
    </label>
  </div>
  <div class="form-control" [ngClass]="{invalid: (invalid | async)}">
    <form-errors-display [extraErrorComponents]="extraErrorComponents" [messages]="failures | async">
      <ng-content select="form-error"></ng-content>
    </form-errors-display>
    <textarea
      [disabled]="disabled"
      class="input"
      autocomplete="off"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      [name]="name"
      [id]="identifier">
    </textarea>
  </div>
</div>
