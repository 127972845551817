import { Component, OnInit, Input, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { Forecast, MeteoStation, Schedule } from 'src/app/shared/models';
import { ForecastClientService } from 'src/app/http-clients';
import * as cSpline from 'cubic-spline';
import { ModalRouteService } from 'src/app/application/modal-route.service';
import { debounceTime } from 'rxjs/operators';
import { dayjs, DAYJS, TimedChartConfig, TimedChartConfigSeries, TimedChartData } from '../../../../../../../common';

@Component({
  selector: 'ss-weather-forecast',
  templateUrl: './weather-forecast.component.html',
  styleUrls: ['./weather-forecast.component.sass']
})
export class WeatherForecastComponent {

  constructor(
    private hes: ModalRouteService,
    private fc: ForecastClientService
  ) {
    this.dataChange.pipe(debounceTime(10)).subscribe(() => {
      if (this.forecast) {
        const series: TimedChartConfigSeries[] = [];
        const labels = this.forecast.labels;
        const rawData = { labels };

        let startTemps = labels.map(v => undefined);
        if(!!this._selectedSchedule) {
          startTemps = labels.map(v => this._selectedSchedule.schema.startTemperature);
        }

        rawData['startTemps'] = startTemps;
        series.push({
          ... new TimedChartConfigSeries(),
          unit: 'temperature',
          seriesType: 'line',
          grid: true,
          primaryColor: '#00589C',
          opacity: !!this._selectedSchedule ? 1 : 0,
          valueFieldName: 'startTemps'
        });

        // if(this._selectedSchedule) {
          // this._selectedSchedule.areaIds
          // this._selectedSchedule.schema.startTemperature
          // this.forecast.performedSnowing[0].snowProductionVolume;
        // }

        this.forecast.providers.forEach(prov => {
          const pid = prov.providerId;
          if(!this.forecast.forecast[ pid ]) {
            return;
          }
          rawData[`forecast-${pid}-rainfall`] = this.forecast.forecast[ pid ].rainfall;
          series.push({
            ... new TimedChartConfigSeries(),
            seriesType: 'bar',
            primaryColor: '#50ccff',
            opacity: pid === this._selectedProvider.providerId ? 1 : 0,
            fixedMax: 25,
            valueFieldName: `forecast-${pid}-rainfall`,
          });
          rawData[`forecast-${pid}-sleetfall`] = this.forecast.forecast[ pid ].sleetfall;
          series.push({
            ... new TimedChartConfigSeries(),
            seriesType: 'bar',
            primaryColor: '#60ff9e',
            opacity: pid === this._selectedProvider.providerId ? 1 : 0,
            fixedMax: 25,
            valueFieldName: `forecast-${pid}-sleetfall`,
          });
          rawData[`forecast-${pid}-snowfall`] = this.forecast.forecast[ pid ].snowfall;
          series.push({
            ... new TimedChartConfigSeries(),
            seriesType: 'bar',
            primaryColor: '#60ff9e',
            opacity: pid === this._selectedProvider.providerId ? 1 : 0,
            fixedMax: 25,
            valueFieldName: `forecast-${pid}-snowfall`,
          });
          rawData[`forecast-${pid}-temperature`] = this.interpolate(this.forecast.forecast[ pid ].temperature);
          series.push({
            ... new TimedChartConfigSeries(),
            seriesType: 'line',
            grid: true,
            unit: 'temperature',
            primaryColor: this.getForecastColor(this.forecast.providers, prov),
            opacity: pid === this._selectedProvider.providerId ? 1 : 0,
            valueFieldName: `forecast-${pid}-temperature`,
          });
        });

        if(
          this.meteoStations
          && this.meteoStations.length > 0
        ) {
          const now = Date.now() / 1000;
          const recordedLabels = [ ...labels ];
          let splitIdx = recordedLabels.findIndex(v => v > now);
          splitIdx = splitIdx < 0 ? recordedLabels.length : splitIdx;
          recordedLabels.splice(splitIdx, 0, now);
          rawData['recorded-labels'] = recordedLabels;

          this.meteoStations.forEach(ms => {
            let seriesData = labels.map(v => undefined);
            if(
              !!this.forecast.recorderWeather[ms.id]
              && !!this.forecast.recorderWeather[ms.id].temperature
              && this.forecast.recorderWeather[ms.id].temperature.length > 0
            ) {
              seriesData = [...this.forecast.recorderWeather[ms.id].temperature];
              seriesData.splice(splitIdx, 0, ms.wetBulbTemperature);
            }


            rawData[`recorded-${ms.id}-temperature`] = seriesData;
            series.push({
              ... new TimedChartConfigSeries(),
              seriesType: 'line',
              grid: true,
              unit: 'temperature',
              primaryColor: this.getMeteoStationColor(this.meteoStations, ms),
              opacity: this.isMeteoStationChecked(ms.id) ? 1 : 0,
              valueFieldName: `recorded-${ms.id}-temperature`,
              labelFieldName: 'recorded-labels'
            });
          });
        }

        series.push({
          ...new TimedChartConfigSeries(),
          seriesType: 'now-delimiter',
          primaryColor: '#EE2142'
        });
        this.data = new TimedChartData(rawData);
        this.config = {
          ... new TimedChartConfig(),
          xAxisType: 'up-and-bottom',
          yAxisType: 'single',
          selectedDate: this.selectedDate,
          grid: true,
          series
        };
      }
    });
  }

  private dataChange: EventEmitter<any> = new EventEmitter();

  @Input()
  public selectedDate: DAYJS;

  public data: TimedChartData;
  public config: TimedChartConfig;

  private _forecast: Forecast;
  @Input()
  get forecast(): Forecast {
    return this._forecast;
  }
  set forecast(val: Forecast) {
    this._forecast = val;

    if (
      this._forecast.providers.length > 0
      && !this.selectedProvider
    ) {
        this._selectedProvider = this._forecast.providers[0];
    }

    this.dataChange.emit();
  }

  private _selectedSchedule: Schedule;
  @Input()
  get selectedSchedule(): Schedule {
    return this._selectedSchedule;
  }
  set selectedSchedule(s: Schedule) {
    this._selectedSchedule = s;

    this.dataChange.emit();
  }
  private _meteoStations: MeteoStation[];
  @Input()
  get meteoStations(): MeteoStation[] {
    return this._meteoStations;
  }
  set meteoStations(sts: MeteoStation[]) {
    this._meteoStations = [...sts].sort((s, z) => z.isPurelySynthetic ? -1 : `${s.symbol}`.localeCompare(`${z.symbol}`));

    if (this._meteoStations.length > 0) {
      this.checkedMeteoStations.length = 0;
      this.toggleMeteoStation(this._meteoStations[0].id);
    }
    this.dataChange.emit();
  }

  private _selectedProvider;
  get selectedProvider(): { providerId: string; name: string } {
    return this._selectedProvider;
  }
  set selectedProvider(dt: { providerId: string; name: string }) {
    this._selectedProvider = dt;

    this.dataChange.emit();
  }

  private checkedMeteoStations: string[] = [];
  toggleMeteoStation(id: string) {
    const ind = this.checkedMeteoStations.indexOf(id);
    if (ind >= 0) {
      this.checkedMeteoStations.splice(ind, 1);
    } else {
      this.checkedMeteoStations.push(id);
    }
    this.dataChange.emit();
  }
  isMeteoStationChecked(id: string): boolean {
    return this.checkedMeteoStations.indexOf(id) >= 0;
  }

  public isStationsDropdownExpanded = false;

  public loadingInProgress = false;
  public lowBound: string = null;
  public highBound: string = null;
  setLowBound(val: string) {
    this.lowBound = val;

    this.reloadForecast();
  }
  setHighBound(val: string) {
    this.highBound = val;

    this.reloadForecast();
  }
  reloadForecast() {
    let from: number = null;
    let to: number = null;

    const hNow = Math.floor(dayjs().valueOf() / 1000 / 3600) * 3600;

    switch (this.lowBound) {
      case '-7d':
        from = hNow - 7 * 24 * 3600;
        break;
      case '-3d':
        from = hNow - 3 * 24 * 3600;
        break;
      case '-1d':
        from = hNow - 24 * 3600;
        break;
    }

    switch (this.highBound) {
      case '4h':
        to = hNow + 4 * 3600;
        break;
      case '8h':
        to = hNow + 8 * 3600;
        break;
      case '12h':
        to = hNow + 12 * 3600;
        break;
      case '1d':
        to = hNow + 1 * 24 * 3600;
        break;
      case '3d':
        to = hNow + 3 * 24 * 3600;
        break;
      case '7d':
        to = hNow + 7 * 24 * 3600;
        break;
    }

    this.loadingInProgress = true;

    this.fc.getAll(from, to).subscribe(data => {
      this.loadingInProgress = false;
      this.forecast = data;

      this.dataChange.emit();
    }, () => {
      this.loadingInProgress = false;
    });
  }

  public openMeteoSimulatorConfig(id: string) {
    this.hes.showModalRoute('meteo-station-modal/' + id);
  }

  private getMeteoStationColorByIndex(i: number): string {
    return ['#067a45', '#9566ab', '#faf3b2', '#4a90e2', '#60ff9e'][i % 5];
  }

  public getMeteoStationColor(list: any[], item: any): string {
    const i = list.indexOf(item);
    return this.getMeteoStationColorByIndex(i < 0 ? 0 : i);
  }

  private getForecastColorByIndex(i: number): string {
    return ['#e94f75', '#4774ff', '#8a7bff', '#ff33ff', '#50ccff'][i % 5];
  }

  public getForecastColor(list: any[], item: any): string {
    const i = list.indexOf(item);
    return this.getForecastColorByIndex(i < 0 ? 0 : i);
  }


  //#region schedule series

  // startTemperatureSeries: Chart.ChartDataSets = {
  //   type: 'line',
  //   label: 'Start temperature',
  //   yAxisID: 'temperatureAxis',
  //   data: [],
  //   fill: null,
  //   hidden: true,
  //   borderWidth: 1.5,
  //   borderColor: '#00589C',
  //   pointHoverRadius: 0,
  //   pointRadius: 0
  // };
  // #endregion

  //#region dataset initialization

  // weatherXAxis = {
  //   position: 'top',
  //   stacked: true,
  //   categorySpacing: 0,
  //   ticks: {
  //     autoSkip: false,
  //     padding: 10,
  //     maxRotation: 0,
  //     fontColor: '#ffffff',
  //     fontFamily: 'Montserrat',
  //     fontSize: 16,
  //     labelOffset: 30,
  //     callback: (value, index, values) => {
  //       if (
  //         this.forecast
  //         && this.forecast.from
  //         && value !== '' &&
  //         value % 24 === 0
  //         && values.length - index > 4
  //       ) {
  //         return moment(1000 * (this.forecast.from + 3600 * index + 60 * moment().utcOffset()))
  //           .tz().format('DD.MM dd')
  //           .toLocaleUpperCase();
  //       }
  //       return '';
  //     }
  //   },
  //   gridLines: {
  //     display: false,
  //     drawTicks: false
  //   }
  // };
  // mainXAxis = {
  //   stacked: true,
  //   categorySpacing: 0,
  //   ticks: {
  //     autoSkip: false,
  //     padding: 5,
  //     maxRotation: 0,
  //     fontColor: 'rgba(200,200,200,0.9)',
  //     fontFamily: 'Montserrat',
  //     fontSize: 12
  //   },
  //   gridLines: {
  //     color: [],
  //     drawTicks: false,
  //     drawBorder: false
  //   }
  // };
  // mainYAxis = {
  //   afterFit(scaleInstance) {
  //     scaleInstance.width = 62;
  //   },
  //   id: 'temperatureAxis',
  //   type: 'linear',
  //   gridLines:
  //   {
  //     zeroLineColor: '#223366',
  //     color: 'rgba(80,80,80,0.1)',
  //     drawTicks: false
  //   },
  //   ticks: {
  //     beginAtZero: true,
  //     fontColor: 'rgba(200,200,200,0.9)',
  //     fontFamily: 'Montserrat',
  //     fontSize: 14,
  //     padding: 15,
  //     suggestedMax: 1,
  //     suggestedMin: -1
  //     // callback: (value, index, values) => {
  //     //   return '' + value + 'C';
  //     // }
  //   }
  // };
  // nowTimeSeries: Chart.ChartDataSets = {
  //   type: 'bar',
  //   yAxisID: 'nowAxis',
  //   data: [],
  //   backgroundColor: '#EE2142',
  //   borderWidth: 0,
  //   // datalabels: {
  //   //   display: true,
  //   //   anchor: 'end',
  //   //   align: 'right',
  //   //   offset: -3,
  //   //   backgroundColor: '#EE2142',
  //   //   color: '#FFFFFF',
  //   //   formatter: () => moment().tz().format('HH:mm')
  //   // }
  // };

  // forecastDatasets: Map<string, Chart.ChartDataSets> = new Map();
  // forecastSnowFallDatasets: Map<string, Chart.ChartDataSets> = new Map();
  // recordedDatasets: Map<string, Chart.ChartDataSets> = new Map();

  // // #endregion

  // series: Chart.ChartData;
  // options: Chart.ChartOptions;



  private interpolate(data: number[]): number[] {
    const nX = [];
    const nY = [];
    let firstI = 0;
    let lastI = 0;
    data.forEach((v, i) => {
      if (v) {
        if (!firstI) {
          firstI = i;
        }
        nX.push(i);
        nY.push(v);

        lastI = i;
      }
    });
    if (nX.length === 0 || nY.length === 0) {
      return [];
    }
    const splineO = new cSpline(nX, nY);
    return data.map((x, i) => {
      if (i < firstI || i > lastI) {
        return null;
      }
      try {
        return splineO.at(i);
      } catch (e) {
        return null;
      }
    });
  }

  // getMeteoStationColor(id: string): string {
  //   return this.meteoStationColorIndex.get(id) || '#ffffff';
  // }
  // getForecastColor(id: string): string {
  //   return this.forecastColorIndex.get(id) || '#ffffff';
  // }

  // private updateChart() {
    // if (this.chartHandler && this.chartHandler.chart) {
    //   this.chartHandler.chart.update();
    //   console.log('this.chartHandler.chart.draw();');
    //   setTimeout(() => {
    //     this.chartHandler.chart.draw();
    //   }, 100);
    // }
  // }

  // private checkWithSchedule(from: number, offset: number): boolean {
  //   const dow = dayjs(1000 * (from + 3600 * (offset))).isoWeekday();
  //   const hourTimestamp = from + 3600 * (offset) + 60 * dayjs().utcOffset();
  //   const roundedHourTimestamp = Math.floor((hourTimestamp - 3600 * 24 * Math.floor(hourTimestamp / 24 / 3600)) / 60);

  //   let resp = false;
  //   this._selectedSchedule.getDay(dow).forEach(el => {
  //     resp = resp || (el.start <= roundedHourTimestamp && el.end >= roundedHourTimestamp);
  //   });

  //   return resp;
  // }

  // private calcScheduleSeries() {
  //   if (this.forecast && this.forecast.timeline && this._selectedSchedule) {
  //     const hNow = Math.floor(moment().valueOf() / 1000 / 3600) - Math.floor(this.forecast.from / 3600);

  //     this.forecastDatasets.forEach(series => {
  //       if (series['data']) {
  //         series['fill'] = 'start';
  //         series['gradientColors'] = (series.data as number[]).map((el, i) => {
  //           if (el === null || hNow > i || !this.checkWithSchedule(this.forecast.from, i)) {
  //             return 'rgba(0,0,0,0)';
  //           }
  //           return el < this._selectedSchedule.schema.startTemperature ? 'rgba(255,255,255,0.9)' : 'rgba(138,123,255,0.25)';
  //         });
  //       }
  //     });

  //     this.recordedDatasets.forEach(series => {
  //       if (series['data']) {
  //         series['fill'] = 'start';
  //         series['gradientColors'] = (series.data as number[]).map((el, i) => {
  //           if (hNow >= i) {
  //             let wasSnowing = false;
  //             this._selectedSchedule.areaIds.forEach(aid => {
  //               wasSnowing = wasSnowing || (
  //                 this.forecast.performedSnowing[aid]
  //                 && this.forecast.performedSnowing[aid].snowProductionVolume[i] != null
  //               );
  //             });
  //             if (wasSnowing) {
  //               return 'rgba(71,116,255,0.75)';
  //             }
  //             if (el != null && el <= this._selectedSchedule.schema.startTemperature) {
  //               return 'rgba(255,51,255,0.25)';
  //             }
  //           }

  //           return 'rgba(0,0,0,0)';
  //         });
  //       }
  //     });

  //     this.startTemperatureSeries['data'] = this.forecast.timeline.map(el => this._selectedSchedule.schema.startTemperature);
  //     this.startTemperatureSeries['hidden'] = false;
  //   } else {
  //     this.forecastDatasets.forEach(series => {
  //       series['gradientColors'] = [];
  //       series['backgroundColor'] = 'transparent';
  //       series['fill'] = false;
  //     });
  //     this.recordedDatasets.forEach(series => {
  //       series['gradientColors'] = [];
  //       series['backgroundColor'] = 'transparent';
  //       series['fill'] = false;
  //     });

  //     this.startTemperatureSeries['data'] = [];
  //     this.startTemperatureSeries['hidden'] = true;
  //   }

  //   this.updateChart();
  // }

  // private calcDatasets(data: Forecast) {
  //   const now = Math.floor(moment().valueOf() / 1000 / 3600);
  //   this.nowTimeSeries.data = data.timeline.map(el => null);
  //   this.nowTimeSeries.data[now - Math.floor(data.from / 3600)] = 1;

  //   data.forecast.forEach((v, k) => {
  //     let datasetT = this.forecastDatasets.get(k);
  //     let datasetSn = this.forecastSnowFallDatasets.get(k);
  //     if (!datasetT) {
  //       datasetT = {
  //         type: 'line',
  //         label: 'twb ' + k,
  //         yAxisID: 'temperatureAxis',
  //         spanGaps: true,
  //         data: [],
  //         fill: 'start',
  //         hidden: !(this.selectedProvider && this.selectedProvider.providerId === k),
  //         borderWidth: 1.5,
  //         borderColor: this.getForecastColor(k),
  //         pointHoverRadius: 0,
  //         pointRadius: 0,
  //         // enableGradient: true,
  //         backgroundColor: 'transparent'
  //       };

  //       datasetSn = {
  //         type: 'bar',
  //         label: 'snow fall ' + k,
  //         yAxisID: 'snowfallAxis',
  //         data: [],
  //         fill: 'start',
  //         hidden: !(this.selectedProvider && this.selectedProvider.providerId === k),
  //         pointHoverRadius: 0,
  //         pointRadius: 0
  //       };
  //     }

  //     const percipData = [];
  //     const percipColors = [];

  //     data.timeline.forEach((el, i) => {
  //       if (v.snowfall[i] != null) {
  //         percipData[i] = v.snowfall[i];
  //         percipColors[i] = '#50ccff';
  //       } else if (v.sleetfall[i] != null) {
  //         percipData[i] = v.sleetfall[i];
  //         percipColors[i] = '#60ff9e';
  //       } else if (v.rainfall[i] != null) {
  //         percipData[i] = v.rainfall[i];
  //         percipColors[i] = '#60ff9e';
  //       } else {
  //         percipData[i] = null;
  //         percipColors[i] = null;
  //       }
  //     });

  //     datasetT['data'] = this.interpolate(v.temperature);
  //     this.forecastDatasets.set(k, datasetT);

  //     datasetSn['data'] = percipData;
  //     datasetSn['backgroundColor'] = percipColors;
  //     this.forecastSnowFallDatasets.set(k, datasetSn);
  //   });

  //   data.recorderWeather.forEach((v, k) => {
  //     let dataset = this.recordedDatasets.get(k);
  //     if (!dataset) {
  //       dataset = {
  //         type: 'line',
  //         label: 'real twb ' + k,
  //         yAxisID: 'temperatureAxis',
  //         spanGaps: true,
  //         data: [],
  //         fill: 'start',
  //         hidden: this.checkedMeteoStations.indexOf(k) < 0,
  //         borderWidth: 1.5,
  //         borderColor: this.getMeteoStationColor(k),
  //         pointHoverRadius: 0,
  //         pointRadius: 0,
  //         // enableGradient: true,
  //         backgroundColor: 'transparent'
  //       };
  //     }
  //     dataset['data'] = this.interpolate(v.temperature);

  //     this.recordedDatasets.set(k, dataset);
  //   });

  //   const cnt = this.forecast.timeline.length;
  //   let divider = 12;
  //   if (cnt < 72) {
  //     divider = 1;
  //   } else if (cnt < 170) {
  //     divider = 2;
  //   } else if (cnt < 260) {
  //     divider = 4;
  //   } else if (cnt < 340) {
  //     divider = 8;
  //   }

  //   this.series = {
  //     labels: this.forecast.timeline.map((el, i) => el % divider === 0 ? el : ''),
  //     datasets: [
  //       this.nowTimeSeries,
  //       this.startTemperatureSeries,
  //       ...Array.from(this.forecastSnowFallDatasets.values()),
  //       ...Array.from(this.recordedDatasets.values()),
  //       ...Array.from(this.forecastDatasets.values())
  //     ]
  //   };
  //   this.nowTimeSeries['hidden'] = false;
  //   const gridlines = ['transparent'];
  //   this.forecast.timeline.forEach(el => {
  //     gridlines.push(el % 12 === 11 ? 'rgba(200,200,200,0.2)' : 'rgba(80,80,80,0.1)');
  //   });
  //   this.mainXAxis.gridLines.color = gridlines;

  //   this.calcScheduleSeries();
  // }
  // calcChartOptions() {

  //   this.options = {
  //     plugins: {
  //       datalabels: {
  //         display: false
  //       }
  //     },
  //     animation: {
  //       duration: 400
  //     },
  //     responsive: true,
  //     maintainAspectRatio: false,
  //     scales: {
  //       xAxes: [
  //         this.mainXAxis,
  //         this.weatherXAxis
  //       ],
  //       yAxes: [
  //         this.mainYAxis,
  //         {
  //           id: 'nowAxis',
  //           display: false,
  //           stacked: false,
  //           ticks: {
  //             suggestedMax: 1
  //           }
  //         },
  //         {
  //           id: 'snowfallAxis',
  //           display: false,
  //           stacked: false,
  //           ticks: {
  //             suggestedMax: 25
  //           }
  //         }
  //       ]
  //     },
  //     legend: { display: false},
  //     tooltips: { enabled: false},
  //     layout: {
  //       padding: {
  //         top: 10,
  //         left: 10,
  //         right: 30,
  //         bottom: 10
  //       }
  //     }
  //   };
  // }
}
