import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { isArray, isObject, UnitSet, updateConvertUnitDecoratorUnitSet } from '../../../common';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class UnitConverterService {

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private settings: SettingsService
  ) {
    this.updateUnitSet();
  }

  protected currentUnitSet: UnitSet;

  public readonly lengthUnits: string[] = ['m'];
  public readonly temperatureUnits: string[] = ['C'];
  public readonly atmosphericAirPressureUnits: string[] = ['hPa'];
  public readonly humidityUnits: string[] = ['%'];
  public readonly windSpeedUnits: string[] = ['m/s', 'km/h'];
  public readonly airPressureUnits: string[] = ['bar'];
  public readonly airFlowUnits: string[] = ['l/s', 'm3/h'];
  public readonly waterPressureUnits: string[] = ['bar'];
  public readonly waterFlowUnits: string[] = ['l/s', 'm3/h'];
  public readonly productionUnits: string[] = ['m3/h'];
  public readonly powerDrawUnits: string[] = ['kW'];
  public readonly airUsageUnits: string[] = ['l', 'm3'];
  public readonly waterUsageUnits: string[] = ['l', 'm3'];
  public readonly powerUsageUnits: string[] = ['kWh'];

  public updateUnitSet() {
    const set = UnitSet.create(this.locale);

    const validatedData = {};
    const userData = this.settings.get<any>('unitSet');
    if (isObject(userData)) {
      for (const k of Object.keys(userData)) {
        if (
          this[k + 'Units']
          && isArray(this[k + 'Units'])
          && this[k + 'Units'].includes(userData[k])
        ) {
          validatedData[k] = userData[k];
        }
      }
    }

    set.extend(validatedData);
    updateConvertUnitDecoratorUnitSet(set);
    this.currentUnitSet = set;
  }

  public getUnitSet(): UnitSet {
    return this.currentUnitSet;
  }
}
