import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';

const socketIoUrl = environment.websocketHost;

@Injectable({
  providedIn: 'root'
})
export class SocketioClientService {

  public deviceNotification: EventEmitter<string> = new EventEmitter();
  public alertNotification: EventEmitter<string> = new EventEmitter();
  public schemaNotification: EventEmitter<string> = new EventEmitter();
  public notesNotification: EventEmitter<string> = new EventEmitter();
  public fileExportsNotification: EventEmitter<string> = new EventEmitter();
  public loggedInUsers: EventEmitter<number[]> = new EventEmitter();

  public serverTime: EventEmitter<string> = new EventEmitter();
  public disconnected: EventEmitter<boolean> = new EventEmitter();
  public unauthorized: EventEmitter<boolean> = new EventEmitter();

  private client: Socket = null;

  private disconnectionTimer = null;
  private extendDisconnectionTimer() {
    this.disconnected.emit(false);

    if (this.disconnectionTimer) {
      clearTimeout(this.disconnectionTimer);
      this.disconnectionTimer = null;
    }

    this.disconnectionTimer = setTimeout(() => {
      this.disconnected.emit(true);
    }, 10000);
  }

  private getClient() {
    if (this.client == null) {
      this.extendDisconnectionTimer();
      this.client = io(socketIoUrl);
      this.client.on('notification', (uuids) => {
        this.deviceNotification.emit(uuids);
        this.extendDisconnectionTimer();
      });
      this.client.on('alert', (ids) => {
        this.alertNotification.emit(ids);
        this.extendDisconnectionTimer();
      });
      this.client.on('notes', (ids) => {
        this.notesNotification.emit(ids);
        this.extendDisconnectionTimer();
      });
      this.client.on('schedule', (ids) => {
        this.schemaNotification.emit(ids);
        this.extendDisconnectionTimer();
      });
      this.client.on('file-exports', (ids) => {
        this.fileExportsNotification.emit(ids);
        this.extendDisconnectionTimer();
      });
      this.client.on('logged-in-users', (ids) => {
        this.loggedInUsers.emit(ids);
      });
      this.client.on('server-time', (serverTime) => {
        this.extendDisconnectionTimer();
        this.serverTime.emit(serverTime);
      });
      this.client.on('reconnect' , (el) => {
        this.extendDisconnectionTimer();
      });
      this.client.on('connect' , () => {
        this.extendDisconnectionTimer();
      });
      this.client.on('disconnect' , (el) => {
        this.disconnected.emit(true);
      });
      this.client.on('unauthorized' , (el) => {
        this.unauthorized.emit(true);
      });
    }

    return this.client;
  }

  public markUserActivity(apiKey: string) {
    this.getClient().emit('activity', apiKey);
  }

  public sendTestSms(phoneNo: string): Observable<string> {
    return new Observable(sb => {
      this.getClient().emit('test-phone-no', phoneNo);
      this.getClient().once('test-phone-no-completed', () => {
        sb.next('done');
        sb.complete();
      });
    });
  }

}
