import { Component, OnInit, Injectable, ViewChild, ElementRef, LOCALE_ID, Inject } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '../auth.service';
import { User } from '../shared/models';
import { DataPreloaderService } from '../data-preloader.service';
import { ResortService } from '../resort.service';
import { environment } from 'src/environments/environment';
import { EndpointMapService } from '../endpoint-map.service';
import { Observable } from 'rxjs';
import { ResortConfigurationService } from '../resort-configuration.service';

@Injectable()
export class StartScreenAuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    if (this.authService.isAuthenticated()) {
      return this.router.createUrlTree(['/application']);
    }

    return true;
  }
}

@Component({
  selector: 'ss-start-screen',
  templateUrl: './start-screen.component.html',
  styleUrls: ['./start-screen.component.sass']
})
export class StartScreenComponent implements OnInit {

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private authService: AuthService,
    private dataPreloader: DataPreloaderService,
    private resortService: ResortService,
    private e: EndpointMapService,
    private router: Router
  ) { }


  @ViewChild('emailInputRef', { static: true }) emailInput: ElementRef;

  email: string = environment.defaultLogin;
  password: string = environment.defaultPassword;

  users: User[] = [];
  user: User = null;

  allowAzureOauthLogin = false;
  resortName = '';
  resortBounds: any;

  version: string = environment.version;

  errorCode = 0;
  isError = false;
  isInProgress = false;

  isValidatingSessionStorege = true;
  isStagedPreloadInProgress = false;
  isLoginScreenVisible = false;

  isLoginSelected = false;

  progress = 0;

  selectUser(user?: User, event = null) {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (user) {
      this.email = user.email;
    } else {
      this.email = '';
    }

    this.isError = false;
    this.isLoginSelected = true;
  }

  startOAuthFlow(event = null) {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    this.isInProgress = true;
    this.isError = false;

    const options = 'left=100,top=10,width=400,height=500';
    window.open(this.e.authOauthSignIn, '_blank', options);
  }

  tryLogin() {
    this.isInProgress = true;
    this.isError = false;

    this.authService.tryLogIn(this.email, this.password).subscribe((data) => {
      if (data) {
        this.preloadDevicesAndRedirect();
      } else {
        this.errorCode = 400;
        this.isInProgress = false;
        this.isError = true;
        this.focusOnLogin();
      }
    }, (errorCode) => {
      this.errorCode = errorCode;
      this.isInProgress = false;
      this.isError = true;
      this.focusOnLogin();
    });
  }

  private done() {
    this.isStagedPreloadInProgress = false;
    setTimeout(() => {
      if (this.authService.lastLoginUrl) {
        this.router.navigateByUrl(this.authService.lastLoginUrl);
      } else {
        this.router.navigate(['/application']);
      }
    }, 500);
  }

  preloadDevicesAndRedirect() {
    this.user = this.authService.getUser();


    this.isLoginScreenVisible = false;
    this.isStagedPreloadInProgress = true;

    this.resortService.onReady.subscribe(() => {
      this.done();
    });

    this.authService.fetchPreLoginData().subscribe(data => {
      this.dataPreloader.preload(data.resortBounds).subscribe((progress) => {
        this.progress = 100 * progress;
        // if (progress === 1) {
        //   this.done();
        // } // seems redundant because of this.resortService.onReady - why to leave it here?
      }, (error) => {
        console.error('error while loading data ', error);
        setTimeout(() => {
          window.location.reload();
        }, 10000);
      });
    });
  }

  prepareLoginList() {
    this.authService.fetchPreLoginData().subscribe((data) => {
      this.users = data.users;
      this.allowAzureOauthLogin = data.allowAzureOauthLogin;
      this.resortName = data.resortName;
      this.resortBounds = data.resortBounds;

      if (!this.users || this.users.length === 0) {
        this.isLoginSelected = true;
      } else if (this.users.length === 1) {
        this.email = this.users[0].email;
        this.isLoginSelected = true;
      }

      this.isLoginScreenVisible = true;
      this.focusOnLogin();
    }, err => {
      this.users = [];

      this.isLoginScreenVisible = true;
      this.focusOnLogin();
    });

  }

  ngOnInit() {
    window.addEventListener('message', (event) => {
      if (event.data.api_key) {
        this.authService.validateOauthApiKey(event.data.api_key).subscribe(data => {
          if (data) {
            this.preloadDevicesAndRedirect();
          } else {
            this.isLoginSelected = true;
            this.isInProgress = false;
            this.isError = true;
            this.focusOnLogin();
          }
        });
      } else if (event.data.error) {
        switch (event.data.error) {
          case 'account-expired':
            this.errorCode = 403;
            break;
          case 'restricted-to-local-ip':
            this.errorCode = 406;
            break;
          case 'user-does-not-exist':
          case 'can-not-fetch-identity':
          case 'error-fetching-identity':
            this.errorCode = 401;
            break;
        }
        this.isLoginSelected = true;
        this.isInProgress = false;
        this.isError = true;
        this.focusOnLogin();
      }
    }, false);

    this.authService.validateApiKey().subscribe((data) => {
      this.isValidatingSessionStorege = false;

      if (data) {
        this.isStagedPreloadInProgress = true;

        this.preloadDevicesAndRedirect();
      } else {
        this.prepareLoginList();
      }

    }, () => {
      this.isValidatingSessionStorege = false;
      this.prepareLoginList();
    });
  }

  private focusOnLogin() {
    this.emailInput.nativeElement.focus();
  }

}
