import { Component, OnInit, HostBinding, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Drawable, DrawableText } from 'src/app/shared/models/pump-room';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[ss-full-schema-text]',
  template: '{{ drawableLabel }}',
  styleUrls: []
})
export class TextComponent implements OnInit {

  constructor() { }

  @HostBinding('attr.x')
  public get x(): number {
    return this.drawable.x;
  }

  @HostBinding('attr.y')
  public get y(): number {
    return this.drawable.y;
  }

  @HostBinding('class.selected')
  public get isSelected(): boolean {
    return this.drawable.isSelected;
  }

  @HostBinding('class.drag')
  public get isDragged(): boolean {
    return this.drawable.isDragged;
  }

  @Input()
  public allowEdit = false;

  @Input()
  public drawable: Drawable;

  @Output()
  public selected: EventEmitter<Drawable> = new EventEmitter();

  @Output()
  public remove: EventEmitter<Drawable> = new EventEmitter();

  public get drawableLabel(): string {
    return this.drawable ? (this.drawable as DrawableText).label : '';
  }

  startDragOffsetX = 0;
  startDragOffsetY = 0;
  startDragPositionX = 0;
  startDragPositionY = 0;

  @HostListener('click')
  public onClick(ev: MouseEvent) {
    if (!this.allowEdit) {
 return;
}
    if (!this.drawable.isSelected) {
      this.selected.emit(this.drawable);
    }
  }

  @HostListener('mouseenter')
  public onMouseEnter(ev: MouseEvent) {
    if (!this.allowEdit) {
 return;
}
    this.drawable.isHover = true;
  }

  @HostListener('mouseleave')
  public onMouseLeave(ev: MouseEvent) {
    if (!this.allowEdit) {
 return;
}
    this.drawable.isHover = false;
    this.drawable.isDragged = false;
  }

  @HostListener('dblclick')
  public onDblClick(ev: MouseEvent) {
    if (!this.allowEdit) {
 return;
}
    if (this.drawable.isSelected) {
      this.remove.emit(this.drawable);
    }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(ev: MouseEvent) {
    if (this.drawable.isSelected) {
      this.drawable.isDragged = true;

      this.startDragPositionX = this.drawable.x;
      this.startDragPositionY = this.drawable.y;
      this.startDragOffsetX = ev.screenX;
      this.startDragOffsetY = ev.screenY;
    }
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(ev: MouseEvent) {
    if (this.drawable.isDragged) {
      const newx = this.startDragPositionX + ev.screenX - this.startDragOffsetX;
      const newy = this.startDragPositionY + ev.screenY - this.startDragOffsetY;

      this.drawable.from = [newx, newy];
    }
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(ev: MouseEvent) {
    this.drawable.isDragged = false;
  }

  ngOnInit() {
  }

}
