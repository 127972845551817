import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isArray } from '../../../../../common';
import { ModalRouteService } from '../modal-route.service';

@Injectable({
  providedIn: 'root'
})
export class ProgressOfObservablesService {

  constructor(private mr: ModalRouteService) { }

  private lastObservableCollection: Observable<any>[];
  public runProgressAction(obs: Observable<any>[]) {
    if (
      isArray(obs)
      && obs.length > 0
    ) {
      this.lastObservableCollection = obs;

      this.mr.showModalRoute('progress-modal');
    }
  }

  public getLastObservableCollection(): Observable<any>[] {
    return this.lastObservableCollection;
  }
}
