import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ss-snowing-angle',
  templateUrl: './snowing-angle.component.html',
  styleUrls: ['./snowing-angle.component.sass']
})
export class SnowingAngleComponent implements OnInit {

  @Input()
  set snowingAngle(val: number) {
    if (val < 0) {
      val = 0;
    } else if (val > 43) {
      val = 43;
    }
    this._snowingAngle = val;
  }
  get snowingAngle(): number {
    return this._snowingAngle;
  }

  private _snowingAngle: number;

  constructor() { }

  ngOnInit() {
  }

}
