<div class="component" [ngClass]="{
  'highlighted': compressor.isSelectedByDeviceIds(selectedPipelineIds),
  'breakdown': compressor.isBreakdown
}">
  <div class="info-row">
      <svg class="icon">
          <use xlink:href='#icon-compressor' />
      </svg>
      <p>{{ compressor.symbol }}</p>
      <p>{{ compressor.typeDescription }}&nbsp;</p>
  </div>
  <div class="status-row">
      <ss-pump-room-detail-status [status]="compressor.computedStatus"></ss-pump-room-detail-status>
  </div>
  <div class="status-row" *ngIf="compressor.isManual">
    <ss-pump-room-detail-status status="working-in-manual-mode" class="w-100"></ss-pump-room-detail-status>
  </div>
</div>
