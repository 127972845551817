import { ResortArea, SnowCannon, ConnectionPoint } from '.';
import { ConvertUnit, DeviceSystemType } from '../../../../../common';
import { AbstractDevice } from './abstract-device.model';
import { MeteoDevice } from './meteo-device.interface';

export class WorkingArea extends AbstractDevice {
    systemType: DeviceSystemType = 'WorkingArea';

    symbol: string;
    mapBoundaries: any;
    isSystem: boolean;
    remoteMeteoStation: string;

    snowQualityType: number;
    snowQualityConstant: number;
    snowQualityLow: number;
    snowQualityHigh: number;

    priority: number;
    displayOrder: number;
    extraParameters: any[];

    @ConvertUnit('production', 'm3/h')
    snowProductionVolume: number;
    @ConvertUnit('waterFlow', 'l/s')
    computedWaterFlow: number;
    computedPowerDraw: number;
    computedAirFlow: number;
    limitSnowProductionVolume: number;
    limitWaterConsumption: number;
    limitPowerConsumption: number;
    limitWindSpeed: number;
    airHumidity: number;
    airTemperature: number;
    wetBulbTemperature: number;

    isLocked = false;

    resortArea: string;

    resortAreaRef?: ResortArea;
    remoteMeteoStationRef?: MeteoDevice;

    snowCannonRefs: Map<string, SnowCannon> = new Map();
    conenctionPointRefs: Map<string, ConnectionPoint> = new Map();


    getSnowCannons(): SnowCannon[] {
      return Array.from(this.snowCannonRefs.values());
    }

    getConnectionPoints(): ConnectionPoint[] {
      return Array.from(this.conenctionPointRefs.values());
    }


    countBySnowQuality(): number[] {
      const resp: number[] = [0, 0, 0, 0, 0, 0, 0];

      this.getSnowCannons().filter(sc => sc.connectionStatus).forEach(sc => {
        if (sc.snowQuality > 0 && sc.snowQuality <= 7) {
          resp[sc.snowQuality - 1]++;
        }
      });

      return resp;
    }

    getStartTemperatureRange(): { min: number; max: number } {
      let min = Infinity;
      let max = -Infinity;
      this.getSnowCannons().filter(sc => sc.connectionStatus && sc.CAP_CHANGE_START_TEMPERATURE).forEach(sc => {
        min = Math.min(min, sc.startTemperature);
        max = Math.max(max, sc.startTemperature);
      });
      if (min === Infinity && max === -Infinity) {
        min = 0;
        max = 0;
      }
      min = 0.1 * Math.round(10 * min);
      max = 0.1 * Math.round(10 * max);

      return { min, max };
    }

    isAnyCannonStarted(): boolean {
      return this.getSnowCannons().filter(sc => sc.connectionStatus).findIndex(sc =>
        sc.operationMode === 0 && sc.commandStatusCannonStart) >= 0;
    }

    countByPreparing(): number {
      return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'preparing-to-work').length;
    }

    countByStandingBy(): number {
      return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'standing-by').length;
    }

    countBySnoozing(): number {
      return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'snoozing').length;
    }

    countByBreakdown(): number {
      return this.getSnowCannons().filter(e => e.computedStatus === 'breakdown' || e.computedStatus === 'connection-lost').length;
    }

    countByAwaitingForConditions(): number {
      return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'awaiting-for-conditions').length;
    }

    countByReadyToWork(): number {
      return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'ready-to-work').length;
    }

    countByStarting(): number {
      return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'starting').length;
    }

    countByWorking(): number {
      return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'working').length;
    }

    countByWorkingInManualMode(): number {
      return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'working-in-manual-mode').length;
    }

    countByStopping(): number {
      return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'stopping').length;
    }
}
