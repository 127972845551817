<form-error validator="min"
  *ngIf="messagesMinIsPresent"
  i18n="Aktualna wartość musi być większa lub równa|Wiadomość o błędzie w formularzu dla std walidatora@@validatorMessagesMin"
  >Aktualna wartość musi być większa lub równa {{ messagesMinCondition }}</form-error>
<form-error validator="max"
  *ngIf="messagesMaxIsPresent"
  i18n="Aktualna wartość musi być mniejsza lub równa|Wiadomość o błędzie w formularzu dla std walidatora@@validatorMessagesMax"
  >Aktualna wartość musi być mniejsza lub równa {{ messagesMaxCondition }}</form-error>
<form-error validator="required"
  *ngIf="messagesRequiredIsPresent"
  i18n="Ta wartość jest wymagana|Wiadomość o błędzie w formularzu dla std walidatora@@validatorMessagesRequired"
  >Ta wartość jest wymagana</form-error>
<form-error validator="requiredTrue"
  *ngIf="messagesRequiredTrueIsPresent"
  i18n="Ta wartość jest wymagana|Wiadomość o błędzie w formularzu dla std walidatora@@validatorMessagesRequiredTrue"
  >Ta wartość jest wymagana</form-error>
<form-error validator="email"
  *ngIf="messagesEmailIsPresent"
  i18n="Ta wartość nie jest poprawnym adresem e-mail|Wiadomość o błędzie w formularzu dla std walidatora@@validatorMessagesEmail"
  >Ta wartość nie jest poprawnym adresem e-mail</form-error>
<form-error validator="minlength"
  *ngIf="messagesMinlengthIsPresent"
  i18n="Ta wartość musi mieć conajmniej znaków|Wiadomość o błędzie w formularzu dla std walidatora@@validatorMessagesMinlength"
  >Ta wartość musi mieć conajmniej {{ messagesMinlengthCondition }} znaków</form-error>
<form-error validator="maxlength"
  *ngIf="messagesMaxlengthIsPresent"
  i18n="Ta wartość musi mieć conajwyżej znaków|Wiadomość o błędzie w formularzu dla std walidatora@@validatorMessagesMaxlength"
  >Ta wartość musi mieć conajwyżej {{ messagesMaxlengthCondition }} znaków</form-error>
<form-error validator="pattern"
  *ngIf="messagesPatternIsPresent"
  i18n="Ta wartość musi być zgodna ze wzorcem|Wiadomość o błędzie w formularzu dla std walidatora@@validatorMessagesPattern"
  >Ta wartość musi być zgodna ze wzorcem {{ messagesPatternCondition }}</form-error>

<ng-content></ng-content>