import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { HeaderService } from 'src/app/header.service';
import { UsersClientService } from 'src/app/http-clients';
import { ResortService } from 'src/app/resort.service';
import { SettingsService } from 'src/app/settings.service';
import { User } from 'src/app/shared/models';
import { UserNotificationsSchedule } from 'src/app/shared/models/user-notifications-schedule.model';
import { UnitConverterService } from 'src/app/unit-converter.service';
import { UnitSetInterface } from '../../../../../common';
import { ResortConfigurationService } from '../../resort-configuration.service';

@Component({
  selector: 'ss-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.sass']
})
export class SettingsComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private rcs: ResortConfigurationService,
    private titleService: HeaderService,
    private settingsService: SettingsService,
    private unitConverter: UnitConverterService,
    private userClientService: UsersClientService,
    private resort: ResortService
  ) { }

  public isInProgress = false;
  public animationSpeed: string;
  public defaultChartPeriod: string;
  public machineRoomFullSchemaDarkMode: string;

  public allowNotificationSchedule = true;

  public user: User;
  public notificationSchedule: UserNotificationsSchedule;

  public unitSet: UnitSetInterface;
  public windSpeedUnits: string[];
  public productionUnits: string[];
  public airFlowUnits: string[];
  public waterFlowUnits: string[];
  public airUsageUnits: string[];
  public waterUsageUnits: string[];

  public notificationScheduleChanged(notificationSchedule: UserNotificationsSchedule) {
    this.notificationSchedule = notificationSchedule;
  }

  loadSettings() {
    this.user = this.authService.getUser();

    this.allowNotificationSchedule = this.rcs.checkCapability('hasRemoteNotifications')
      && this.authService.isAuthorized('ROLE_OPERATOR');

    this.windSpeedUnits = this.unitConverter.windSpeedUnits;
    this.waterFlowUnits = this.unitConverter.waterFlowUnits;
    this.productionUnits = this.unitConverter.productionUnits;
    this.airFlowUnits = this.unitConverter.airFlowUnits;
    this.airUsageUnits = this.unitConverter.airUsageUnits;
    this.waterUsageUnits = this.unitConverter.waterUsageUnits;
    this.unitSet = { ... this.unitConverter.getUnitSet() };

    this.animationSpeed = `${this.settingsService.get('animationSpeed')}`;
    this.defaultChartPeriod = `${this.settingsService.get('defaultChartPeriod')}`;
    this.machineRoomFullSchemaDarkMode = this.settingsService.get('machineRoomFullSchemaDarkMode') ? '1' : '0';
  }

  saveSettings() {
    this.isInProgress = true;

    const requests = [
      this.settingsService.set('animationSpeed', this.animationSpeed, false),
      this.settingsService.set('defaultChartPeriod', this.defaultChartPeriod, false),
      this.settingsService.set('machineRoomFullSchemaDarkMode', this.machineRoomFullSchemaDarkMode === '1', false),
      this.settingsService.set('unitSet', this.unitSet, false)
    ];

    if (this.notificationSchedule) {
      requests.push(this.userClientService.updateNotificationsSchedule(this.user.id, this.notificationSchedule));
    }

    forkJoin(requests).subscribe(() => {
      this.isInProgress = false;
      this.user.notificationsSchedule = this.notificationSchedule;
      this.notificationSchedule = undefined;
      this.unitConverter.updateUnitSet();
      this.loadSettings();
    });
  }
  ngOnInit() {
    this.titleService.setUpdateHeader('menuSettings');
    this.loadSettings();
  }

}
