import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResortArea, PumpRoom, ChartData, Resort, WaterReservoir } from 'src/app/shared/models';
import { SettingsService } from 'src/app/settings.service';
import { HeaderService } from 'src/app/header.service';
import { AreasData } from '../areas-data-resolver.service';
import { AreasHeaderExtensionComponent } from '../areas-header-extension/areas-header-extension.component';
import { DevicesClientService } from 'src/app/http-clients';
import { AuthService } from 'src/app/auth.service';
import { EndpointMapService } from 'src/app/endpoint-map.service';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { ResortService } from 'src/app/resort.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { toSearchString } from '../../../../../../../common';

@Component({
  selector: 'ss-areas-map',
  templateUrl: './areas-map.component.html',
  styleUrls: ['./areas-map.component.sass']
})
export class AreasMapComponent implements OnInit {

  constructor(
    private settingsService: SettingsService,
    private rs: ResortService,
    private titleService: HeaderService,
    authService: AuthService,
    endpointMapService: EndpointMapService,
    httpClient: HttpClient,
    private route: ActivatedRoute
  ) {
    this.perspectiveMapImage = settingsService.preloadedPreviewImage;
  }

  public duration: number = null;

  areasExpanded = false;
  areasFilter = '';

  areas: ResortArea[] = [];
  pumpRooms: PumpRoom[] = [];

  perspectiveMapImage: string;

  resort: Resort;
  recentAreas: ResortArea[] = [];
  waterReservoirs: WaterReservoir[];

  @ViewChild(PerfectScrollbarComponent, { static: true }) componentRef?: PerfectScrollbarComponent;

  public setDuration(val: number) {
    this.duration = val;
  }

  ngOnInit() {
    this.route.data
      .subscribe((data: { areasData: AreasData }) => {
        Object.assign(this, data.areasData);

        this.titleService.setUpdateHeader('menuResortAreas', AreasHeaderExtensionComponent, {
          resortName: this.resort.symbol,
          isMap: true
        });

        this.recentAreas = [];
        const recentAreaIds = this.settingsService.get<string[]>('recentAreaIds');
        recentAreaIds.forEach(recentId => {
          const found = this.rs.getResortArea(recentId);
          if (found) {
            this.recentAreas.push(found);
          }
        });
      });
  }
  moveToSearchResults() {
    if (this.areasExpanded) {
      setTimeout(() => {
        this.componentRef.directiveRef.scrollToElement('.areas__body', 0, 500);
      }, 100);
    }
  }
  filteredAreas(): ResortArea[] {
    return this.areas.filter(a => toSearchString(`${a.symbol}`)
      .includes(toSearchString(`${this.areasFilter}`)))
      .sort((a, b) => a.symbol.localeCompare(b.symbol));
  }

}
