import { DeviceSystemType } from '..';

export class ReportSeries {

  subjectId?: string;
  subjectType: DeviceSystemType;
  subjectField: string;

  seriesName?: string;

  public static create(data: any, forceSeriesClone: boolean = false): ReportSeries {
    if(!forceSeriesClone && data instanceof ReportSeries) {
      return data;
    }
    return Object.assign(new ReportSeries(), data);
  }
}
