<div class="d-flex align-items-center"
  [ngClass]="{
    'grid': type === 'grid' ,
    'linear': type === 'linear'
  }"
  *ngIf="sourceDevice">
  <div class="item item--highlighted">
    <svg>
      <use xlink:href="#icon-termo-wet"/>
    </svg>
    <ng-container *ngIf="sourceDevice.connectionStatus ; else noMeteoData">
      {{ (sourceDevice.wetBulbTemperature || 0) |number:'0.1-1' }}
    </ng-container>
    <ng-container>&#176;C</ng-container>
  </div>
  <div class="item">
    <svg>
      <use xlink:href="#icon-termo-dry" />
    </svg>
    <ng-container *ngIf="sourceDevice.connectionStatus ; else noMeteoData">
      {{ (sourceDevice.airTemperature || 0) |number:'0.1-1' }}
    </ng-container>
    <ng-container>&#176;C</ng-container>
  </div>
  <div class="item item--humidity">
    <ng-container *ngIf="sourceDevice.connectionStatus ; else noMeteoData">
      {{ (sourceDevice.airHumidity || 0) |number:'1.0-0' }}
    </ng-container>
    <ng-container>%</ng-container>
  </div>
  <div *ngIf="sourceDevice.connectionStatus && sourceDevice.canHaveWindDirection" class="item item--wind">
    <svg>
      <use xlink:href="#icon-wind" />
    </svg>
    <div>
      <ss-wind-speed-and-direction [windSpeed]="sourceDevice.windSpeed" [windDirection]="sourceDevice.windDirection"></ss-wind-speed-and-direction>
    </div>
  </div>
  <ng-template #noMeteoData>--</ng-template>
</div>