import { ReportDefinition } from './definition';
import { ReportData } from './data';
import { dayjs, DAYJS } from '../..';

export class ReportResult extends ReportDefinition {

  public from: DAYJS;
  public to: DAYJS;

  public data: ReportData;

  public static parseData(data: any): any {
    const parsedData = ReportDefinition.parseData({ ... data });

    parsedData.from = dayjs(data.from, 'YYYY-MM-DD').startOf('d').startOf('hour');
    parsedData.to = dayjs(data.to, 'YYYY-MM-DD').endOf('d').endOf('hour');

    if(data.data) {
      parsedData.data = ReportData.create(data.data);
    }

    return parsedData;
  }

  public static create(data: any): ReportResult {
    const parsedData = ReportResult.parseData({ ... data });

    return Object.assign(new ReportResult(), parsedData);
  }

  public toHttpRequest(): any {
    const resp = {
      ... super.toHttpRequest(),
      from: this.from.tz().format('YYYY-MM-DD'),
      to: this.to.tz().format('YYYY-MM-DD')
    };

    return resp;
  }
}
