import { Component, OnInit } from '@angular/core';
import { HeaderExtensionComponent } from '../../header-extension-host.directive';

@Component({
  selector: 'ss-header-extension',
  templateUrl: './header-extension.component.html',
  styleUrls: ['./header-extension.component.sass']
})
export class ReportingHeaderExtensionComponent implements HeaderExtensionComponent {
  constructor() {}

  data: any;

  updateData(data: any) {
    this.data = data;
  }

}

