<div class="tabs-content">
  <div class="row">

    <ng-container *ssdHideIfNotGranted="'ROLE_OBSERVER';requiresCapability:'hasPumprooms'">
      <div class="col white-space-nowrap tabs-content__box px-3 pb-3 pt-1" *ngFor="let pumpRoom of pumpRooms">
        <p class="window-overview__header py-1">
          <ng-container i18n="Pompownia|Etykieta pompowni@@pumpRoomLabel">Pompownia</ng-container>:
          <span  [routerLink]="['/application/pump-rooms/detail', pumpRoom.id]"
            class="color-light-blue fs-12 clickable">
            {{ pumpRoom.symbol }}
          </span>
        </p>

        <div class="indicator my-1">
          <p class="mr-1" *ngIf="pumpRoom.waterPipelineRefs.size === 1">
            <ss-pump-room-detail-status [status]="pumpRoom.getWaterPipelines()[0].computedStatus"></ss-pump-room-detail-status>
          </p>
          <p class="mr-1" *ngIf="pumpRoom.waterPipelineRefs.size > 1">
            <ss-pump-room-detail-multi-status [hideDescriptions]="true" [components]="pumpRoom.getWaterPipelines()">
              <ss-pump-room-detail-status status="working" class="first-compacted"></ss-pump-room-detail-status>
              <ss-pump-room-detail-status status="filling" [optional]="true"></ss-pump-room-detail-status>
              <ss-pump-room-detail-status status="snoozing"></ss-pump-room-detail-status>
              <ss-pump-room-detail-status status="ready-to-work"></ss-pump-room-detail-status>
              <ss-pump-room-detail-status status="standing-by" class="last-compacted"></ss-pump-room-detail-status>
              <ss-pump-room-detail-status status="breakdown" [standalone]="true"></ss-pump-room-detail-status>
              <ss-pump-room-detail-status status="connection-lost" [standalone]="true"></ss-pump-room-detail-status>
            </ss-pump-room-detail-multi-status>
          </p>
          <svg class="indicator__icon indicator__icon--l">
            <use xlink:href='#icon-waves'/>
          </svg>
          <p>
            <span class="indicator__value pl-1">
              {{ pumpRoom|convertedValue:'computedWaterFlow' }}
            </span>
            <span class="indicator__unit pl-1">
              {{ pumpRoom|convertedUnit:'computedWaterFlow' }}
            </span>
          </p>
          <p class="ml-2">
            <span class="indicator__value pl-1">{{ (pumpRoom.computedWaterPressure || 0)|number:'1.1-1' }}</span>
            <span class="indicator__unit pl-1">bar</span>
          </p>
        </div>

      </div>
    </ng-container>



    <ng-container *ssdHideIfNotGranted="'ROLE_OBSERVER';requiresCapability:'hasPowerSources'">
      <div class="col tabs-content__box px-3 pb-3 pt-1" *ngFor="let powerSource of powerSources">
        <p class="window-overview__header pt-1">
        <ng-container i18n="Zużycie energii|Nazwa pola przedstawiającego wielkość zużycia energii@@energyConsumption">Zużycie energii</ng-container>
          <span  [routerLink]="['/application/power-sources/detail', powerSource.id]"
            class="color-light-blue fs-12 clickable">
            {{ powerSource.symbol }}
          </span>
        </p>
        <div class="indicator mt-3">
          <p class="mr-1" *ngIf="powerSource.electricalLinesRefs.size === 1">
            <ss-pump-room-detail-status [status]="powerSource.getElectricalLines()[0].computedStatus"></ss-pump-room-detail-status>
          </p>
          <p class="mr-1" *ngIf="powerSource.electricalLinesRefs.size > 1">
            <ss-pump-room-detail-multi-status [hideDescriptions]="true" [components]="powerSource.getElectricalLines()">
              <ss-pump-room-detail-status status="standing-by" class="first-compacted last-compacted"></ss-pump-room-detail-status>
              <ss-pump-room-detail-status status="breakdown" [standalone]="true"></ss-pump-room-detail-status>
              <ss-pump-room-detail-status status="connection-lost" [standalone]="true"></ss-pump-room-detail-status>
            </ss-pump-room-detail-multi-status>
          </p>

          <ng-container *ngIf="powerSource.canDisplayCurrentPowerDraw()">
            <svg class="indicator__icon">
              <use xlink:href='#icon-thunder'/>
            </svg>
            <p>
              <span class="indicator__value pl-1">
                {{ powerSource|convertedValue:'computedPowerDraw' }}
              </span>
              <span class="indicator__unit pl-1">
                {{ powerSource|convertedUnit:'computedPowerDraw' }}
              </span>
            </p>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ssdHideIfNotGranted="'ROLE_OBSERVER';requiresCapability:'hasAirSources'">
      <div class="col tabs-content__box px-3 pb-3 pt-1" style="display:none">
        <p class="window-overview__header pt-1" i18n="Kompresorownia|Etykieta kompresorowni@@compressorRoomLabel">Kompresorownia</p>
        <div class="indicator mt-3">
          <svg class="indicator__icon indicator__icon--l">
            <use xlink:href='#icon-air'/>
          </svg>
          <p>
            <span class="indicator__value pl-1">{{ (area.computedAirFlow || 0)|number:'1.0-0' }}</span>
            <span class="indicator__unit pl-1">m<sup>3</sup>/h</span>
          </p>
        </div>
      </div>
    </ng-container>

  </div>
</div>

<ng-container *ssdHideIfNotGranted="'ROLE_OBSERVER';requiresCapability:'hasPumprooms'">
<div class="tabs-content" *ngIf="waterReservoirs && waterReservoirs.length >0">
  <div class="row">
    <div class="col tabs-content__box px-3 py-1">
      <p class="window-overview__header pb-2" i18n="Zbiorniki wodne|Nazwa pola przedstawiającego poziom wód w zbiornikach wodnych@@waterReservoirs">Zbiorniki wody</p>
      <ss-water-tank *ngFor="let waterReservoir of waterReservoirs"
        [waterReservoir]="waterReservoir"
        size="small" minLevel="0"></ss-water-tank>
    </div>
  </div>
</div>
</ng-container>
