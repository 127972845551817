<div [ngClass]="{ 'disabled': lock }">
  <form #form="ngForm" (submit)="save()">
  <ss-page [modal]="true">
      <ss-page-header>
          <ss-page-header-title i18n="Ustawienia hydrantu|Tytuł modala@@connectionPointConfigModalTitle">Ustawienia hydrantu {{ device.symbol }}</ss-page-header-title>
      </ss-page-header>
      <ss-page-panel i18n-panelTitle="Ogólne|Tytuł panelu@@deviceConfigModalPanelTitleGeneral"
        panelTitle="Ogólne">
        <div class="row">
          <div class="col-10">
            <form-range [min]="0" [max]="355" [step]="5" [(ngModel)]="baseOrientationOffsetFromNorth" name="form-cfg-baseOrientationOffsetFromNorth">
              <form-label i18n="Kąt ustawienia podstawy względem północy|Etykieta inputu, którym ustawia się kat podstawy studni wzgl północy@@connectionPointConfigBaseAzimuthFromNorth">Kąt ustawienia podstawy względem północy</form-label>
            </form-range>
          </div>
        </div>
        <div class="row">
          <div class="col-10">
            <form-number [min]="0" [max]="100" [step]="1" [(ngModel)]="priority" name="form-cfg-priority">
              <form-label i18n="Priorytet śnieżenia|Etykiwta inputu@@deviceConfigModalInputLabelSnowmakingPriority">Priorytet śnieżenia</form-label>
            </form-number>
          </div>
        </div>
      </ss-page-panel>
<div style="display:none">

      <ss-page-panel panelTitle="Grupa robocza"
        i18n-panelTitle="Grupa robocza|Tytuł panelu@@connectionPointConfigModalWorkGroupPanel">
        <form-checkbox [hideLabel]="true" [(ngModel)]="workingAreaEnabled" name="form-cfg-workingAreaEnabled">
          <form-label i18n="Hydrant jest czescią grupy roboczej|Etykieta checkboxa, po którego zaznaczeniu można wybrać grupę@@connectionPointConfigModalWorkGroupCheckbox">Hydrant jest czescią grupy roboczej</form-label>
        </form-checkbox>
        <div class="detailed-settings" [ngClass]="{ 'visible': workingAreaEnabled }">
          <p i18n="Tak działają grupy|Akapit z informacjami@@connectionPointConfigModalWorkGroupInfo">Wybierz grupę roboczą do której należy hydrant.</p>
          <div class="row align-items-center">
            <div class="col-10">
              <form-select name="selectedworkingArea"
                  label="Grupa robocza"
                  i18n-label="Grupa robocza|Etykieta inputa@@connectionPointConfigModalWorkGroupInputLabel"
                  [(ngModel)]="workingArea">
                  <form-select-option *ngFor="let c of workingAreas" [value]="c" [device]="c"></form-select-option>
              </form-select>
            </div>
          </div>
        </div>
      </ss-page-panel>

</div>
      <ss-page-panel panelTitle="Limit produkcji śniegu"
        i18n-panelTitle="Limit produkcji śniegu|Tytuł panelu@@deviceConfigModalSnowProductionLimitPanelTitle">
        <form-checkbox [hideLabel]="true" [(ngModel)]="limitSnowProductionVolumeEnabled" name="form-cfg-limitSnowProductionVolumeEnabled">
          <form-label i18n="Włącz limit produkcji śniegu|Etykieta checkboxa, po którego zaznaczeniu można ustawić limit@@deviceConfigModalSnowProductionLimitCheckbox">Włącz limit produkcji śniegu</form-label>
        </form-checkbox>
        <div class="detailed-settings" [ngClass]="{ 'visible': limitSnowProductionVolumeEnabled }">
          <p i18n="Tak działa limit produkcji śniegu dla hydrantu|Akapit z informacjami@@connectionPointConfigModalSnowProductionLimitInfo">Jeśli sumaryczna produkcja śniegu armatek podpiętych do tego hydrantu w tym sezonie przekroczy tą wartość armatki pracujące w trybie Snowmatic nie będą uruchamiane.</p>
          <div class="row align-items-center">
            <div class="col-10">
              <form-number [min]="0" [max]="100000" [step]="100" [(ngModel)]="limitSnowProductionVolume" name="form-cfg-limitSnowProductionVolume">
                <form-label i18n="Limit ilości wyprodukowanego śniegu|Etykieta inputa@@deviceConfigModalSnowProductionLimitInputLabel">Limit ilości wyprodukowanego śniegu</form-label>
              </form-number>
            </div>
            <div class="col ml-1">m<sup>3</sup></div>
          </div>
          <ng-container *ngIf="lastSeasonsSnowProductionVolume && lastSeasonsSnowProductionVolume.length > 0">
            <p>
              <ng-container i18n="W poprzednich sezonach wyprodukowano|Etykiwta nad listą lat i produkcji@@deviceConfigModalProducedInPreviousSeasons">W poprzednich sezonach wyprodukowano</ng-container>:<br />
              <ng-container *ngFor="let s of lastSeasonsSnowProductionVolume; let last = last">
                {{ s.l }} - <a href="#" class="color-light-blue clickable" (click)="$event.preventDefault(); limitSnowProductionVolume = s.v">{{ s.v | number:'1.0-0' }}m<sup>3</sup></a>
                <ng-container *ngIf="!last">, </ng-container>
              </ng-container>
            </p>
          </ng-container>
        </div>
      </ss-page-panel>
      <ss-page-panel panelTitle="Limit zużycia wody"
        i18n-panelTitle="Limit zużycia wody|Tytuł panelu@@deviceConfigModalWaterConsumptionLimitPanelTitle">
        <form-checkbox [hideLabel]="true" [(ngModel)]="limitWaterConsumptionEnabled" name="form-cfg-limitWaterConsumptionEnabled">
          <form-label i18n="Włącz limit zużycia wody|Etykieta checkboxa, po którego zaznaczeniu można ustawić limit@@deviceConfigModalWaterConsumptionLimitCheckbox">Włącz limit zużycia wody</form-label>
        </form-checkbox>
        <div class="detailed-settings" [ngClass]="{ 'visible': limitWaterConsumptionEnabled }">
          <p i18n="Tak działa limit zużycia wody dla hydrantu|Akapit z informacjami@@connectionPointConfigModalWaterConsumptionLimitInfo">Jeśli sumaryczne zużycie wody armatek podpiętych do tego hydrantu w tym sezonie przekroczy tą wartość armatki pracujące w trybie Snowmatic nie będą uruchamiane.</p>
          <div class="row align-items-center">
            <div class="col-10">
              <form-number [min]="0" [max]="100000" [step]="100" [(ngModel)]="limitWaterConsumption" name="form-cfg-limitWaterConsumption">
                <form-label i18n="Limit ilości zużytej wody|Etykieta inputa@@deviceConfigModalWaterConsumptionLimitInputLabel">Limit ilości zużytej wody</form-label>
              </form-number>
            </div>
            <div class="col ml-1">m<sup>3</sup></div>
          </div>
          <ng-container *ngIf="lastSeasonsWaterConsumption && lastSeasonsWaterConsumption.length > 0">
            <p>
              <ng-container i18n="W poprzednich sezonach zużyto|Etykiwta nad listą lat i zużyć@@deviceConfigModalConsumedInPreviousSeasons">W poprzednich sezonach zużyto</ng-container>:<br />
              <ng-container *ngFor="let s of lastSeasonsWaterConsumption; let last = last">
                {{ s.l }} - <a href="#" class="color-light-blue clickable" (click)="$event.preventDefault(); limitWaterConsumption = s.v">{{ s.v | number:'1.0-0' }}m<sup>3</sup></a>
                <ng-container *ngIf="!last">, </ng-container>
              </ng-container>
            </p>
          </ng-container>
        </div>
      </ss-page-panel>
      <ss-page-panel panelTitle="Limit zużycia prądu"
        i18n-panelTitle="Limit zużycia prądu|Tytuł panelu@@deviceConfigModalPowerConsumptionLimitPanelTitle">
        <form-checkbox [hideLabel]="true" [(ngModel)]="limitPowerConsumptionEnabled" name="form-cfg-limitPowerConsumptionEnabled">
          <form-label i18n="Włącz limit zużycia prądu|Etykieta checkboxa, po którego zaznaczeniu można ustawić limit@@deviceConfigModalPowerConsumptionLimitCheckbox">Włącz limit zużycia prądu</form-label>
        </form-checkbox>
        <div class="detailed-settings" [ngClass]="{ 'visible': limitPowerConsumptionEnabled }">
          <p i18n="Tak działa limit zużycia prądu dla hydrantu|Akapit z informacjami@@connectionPointConfigModalPowerConsumptionLimitInfo">Jeśli sumaryczne zużycie prądu armatek podpiętych do tego hydrantu w tym sezonie przekroczy tą wartość armatki pracujące w trybie Snowmatic nie będą uruchamiane.</p>
          <div class="row align-items-center">
            <div class="col-10">
              <form-number [min]="0" [max]="1000000" [step]="100" [(ngModel)]="limitPowerConsumption" name="form-cfg-limitPowerConsumption">
                <form-label i18n="Limit ilości prądu|Etykieta inputa@@deviceConfigModalPowerConsumptionLimitInputLabel">Limit ilości prądu</form-label>
              </form-number>
            </div>
            <div class="col ml-1">kWh</div>
          </div>
          <ng-container *ngIf="lastSeasonsPowerConsumption && lastSeasonsPowerConsumption.length > 0">
            <p>
              <ng-container i18n="W poprzednich sezonach zużyto|Etykiwta nad listą lat i zużyć@@deviceConfigModalConsumedInPreviousSeasons">W poprzednich sezonach zużyto</ng-container>:<br />
              <ng-container *ngFor="let s of lastSeasonsPowerConsumption; let last = last">
                {{ s.l }} - <a href="#" class="color-light-blue clickable" (click)="$event.preventDefault(); limitPowerConsumption = s.v">{{ s.v | number:'1.0-0' }}kWh</a>
                <ng-container *ngIf="!last">, </ng-container>
              </ng-container>
            </p>
          </ng-container>
        </div>
      </ss-page-panel>

      <ss-page-footer>
          <div></div>
          <ss-page-button-group>
            <button type="submit" class="btn btn-primary" i18n="Zapisz konfigurację|Przycisk zapisz@@connectionPointConfigModalSaveBtn">Zapisz konfigurację</button>
            <button type="button" class="btn btn-default" (click)="close()" i18n="Anuluj|Anuluj@@massOperationsModalCancelButon">Anuluj</button>
          </ss-page-button-group>
      </ss-page-footer>
  </ss-page>
  </form>
</div>
