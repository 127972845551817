import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndpointMapService } from '../endpoint-map.service';
import { User, PreAuthLoginData } from '../shared/models';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthClientService {

  constructor(private e: EndpointMapService, private http: HttpClient) {}

  public tryLogin(email: string, password: string): Observable<any> {
    return this.http.post(this.e.authSignIn, {
      email,
      password,
    }, httpOptions);
  }

  public validateApiKey(apiKey: string): Observable<User> {
    const options = { ... httpOptions };

    options.headers = options.headers.append('Authorization', 'Bearer ' + apiKey);

    return this.http.get(this.e.auth, options).pipe(map(e => Object.assign(new User(), e)));
  }

  public fetchPreLoginData(): Observable<PreAuthLoginData> {
    return this.http.get(this.e.authPreLoginData, httpOptions).pipe(map(e => PreAuthLoginData.create(e)));
  }

}
