import { ElectricalLine } from '.';
import { ConvertUnit, DeviceSystemType } from '../../../../../common';
import { AbstractDevice } from './abstract-device.model';

export class PowerSource extends AbstractDevice {
    systemType: DeviceSystemType = 'PowerSource';

    position: any;
    symbol: string;

    @ConvertUnit('powerDraw', 'kW')
    maximumPowerDraw: number;
    @ConvertUnit('powerDraw', 'kW')
    computedPowerDraw: number;
    limitPowerDraw: number;

    isLocked = false;

    electricalLinesRefs: Map<string, ElectricalLine> = new Map();

    getElectricalLines(): ElectricalLine[] {
      return Array.from(this.electricalLinesRefs.values());
    }


    canDisplayCurrentPowerDraw(): boolean {
      return this.getElectricalLines().filter(p => p.configuration.hasCurrentPowerDraw).length > 0;
    }

}
