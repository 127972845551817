import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { TabComponent } from '../tab.component';

@Component({
  selector: 'ss-documentation-tab',
  templateUrl: './documentation-tab.component.html',
  styleUrls: [],
  providers: [
    {provide: TabComponent, useExisting: forwardRef(() => DocumentationTabComponent), multi: true}
  ]
})
export class DocumentationTabComponent extends TabComponent {

  @Input()
  public context: string;

  @Input()
  public extraWide: boolean;

  public setVisibility(ev: boolean) {
    setTimeout(() => {
      this.hidden = ev;
    });
  }

  constructor() {
    super();
    this.hidden = true;
  }

}
