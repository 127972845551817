<div [ngClass]="{ 'disabled': lock }">
    <ss-page [modal]="true">
        <ss-page-header>
            <ss-page-header-title *ngIf="noteId" i18n="Edycja notatki|Modal edycji notatek - Edycja notatki@@userNotesEditorTitleEdit">Edycja notatki</ss-page-header-title>
            <ss-page-header-title *ngIf="!noteId" i18n="Dodaj notatkę|Modal edycji notatek - Dodaj notatkę@@userNotesEditorTitleAdd">Dodaj notatkę</ss-page-header-title>
        </ss-page-header>
    <ss-page-panel>
        <p class="pt-2" i18n="Treść notatki|Modal edycji notatek - Treść notatki@@userNotesEditorFieldContent">Treść notatki</p>
        <form-textarea [(ngModel)]="noteContent" [hideLabel]="true"
          i18n-placeholder="Treść notatki|Modal edycji notatek - Treść notatki@@userNotesEditorFieldContent"
          placeholder="Treść notatki" required></form-textarea>
        <p class="pt-2" i18n="Czy ta notatka jest prywatna?|Modal edycji notatek - Czy ta notatka jest prywatna?@@userNotesEditorFieldPrivateOrPublic">Czy ta notatka jest prywatna?</p>
        <form-checkbox [(ngModel)]="noteIsPrivate" [hideLabel]="true">
            <form-label>
              <ng-container *ngIf="noteIsPrivate"
                i18n="ta notatka jest prywatna i będziesz widział ją tylko ty|Modal edycji notatek - ta notatka jest prywatna i będziesz widział ją tylko ty@@userNotesEditorFieldPrivateNote">ta notatka jest prywatna i będziesz widział ją tylko ty</ng-container>
              <ng-container *ngIf="!noteIsPrivate"
                i18n="ta notatka jest publiczna i bądą ją widzieć wszyscy użytkownicy|Modal edycji notatek - ta notatka jest publiczna i bądą ją widzieć wszyscy użytkownicy@@userNotesEditorFieldPublicNote">ta notatka jest publiczna i bądą ją widzieć wszyscy użytkownicy</ng-container>
            </form-label>
        </form-checkbox>
    </ss-page-panel>
    <ss-page-footer>
        <div>
            <button class="btn btn-danger"
              i18n="Usuń notatkę|Modal edycji notatek - Usuń notatkę@@userNotesEditorButtonRemoveNote"
              *ngIf="noteId" (click)="remove()">Usuń notatkę</button>
        </div>
        <ss-page-button-group>
            <button class="btn btn-primary"
              i18n="Zapisz zmiany|Modal edycji notatek - Zapisz zmiany@@userNotesEditorButtonChangeNote"
              *ngIf="noteId" (click)="save()">Zapisz zmiany</button>
            <button class="btn btn-primary"
              i18n="Dodaj notatkę|Modal edycji notatek - Dodaj notatkę@@userNotesEditorButtonAddNote"
              *ngIf="!noteId" (click)="save()">Dodaj notatkę</button>
            <button class="btn btn-default"
              i18n="Anuluj|Modal edycji notatek - Anuluj@@userNotesEditorButtonCancelNoteEdit"
              (click)="close()">Anuluj</button>
        </ss-page-button-group>
    </ss-page-footer>
</ss-page>
</div>
