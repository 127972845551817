<div [ngClass]="{ 'disabled': lock }">
  <form #form="ngForm" (submit)="save()">
  <ss-page [modal]="true">
      <ss-page-header>
          <ss-page-header-title i18n="Ustawienia grupy roboczej|Tytuł modala@@workingAreaConfigModalTitle">Ustawienia grupy roboczej {{ device.symbol }}</ss-page-header-title>
      </ss-page-header>
      <ss-page-panel i18n-panelTitle="Ogólne|Tytuł panelu@@deviceConfigModalPanelTitleGeneral"
        panelTitle="Ogólne">
        <div class="row">
          <div class="col-10">
            <form-number [min]="0" [max]="100" [step]="1" [(ngModel)]="priority" name="form-cfg-priority">
              <form-label i18n="Priorytet śnieżenia|Etykiwta inputu@@deviceConfigModalInputLabelSnowmakingPriority">Priorytet śnieżenia</form-label>
            </form-number>
          </div>
        </div>
      </ss-page-panel>
      <ss-page-panel i18n-panelTitle="Stacja meteo|Etykiwta inputu@@deviceConfigModalInputLabelMeteoStation"
        panelTitle="Stacja meteo">
        <ss-meteo-conditions-source-selector
          [sourceId]="device.remoteMeteoStation"
          [limitToResortArea]="device.id"
          (sourceIdChanged)="onMeteoSourceSelection($event)"></ss-meteo-conditions-source-selector>
      </ss-page-panel>
      <ss-page-panel i18n-panelTitle="Kontrola wiatru|Tytuł panelu@@deviceConfigModalWindControllPanel"
        panelTitle="Kontrola wiatru">
        <form-checkbox [hideLabel]="true" [(ngModel)]="limitWindSpeedEnabled" name="form-cfg-limitWindSpeedEnabled">
          <form-label i18n="Włącz kontrole wiatru|Etykieta checkboxa@@deviceConfigModalWindControllCheckbox">Włącz kontrole wiatru</form-label>
        </form-checkbox>
        <div class="detailed-settings" [ngClass]="{ 'visible': limitWindSpeedEnabled }">
          <p i18n="Tak działa kontrola wiatru dla grupy|Akapit z informacjami@@workingAreaConfigModalWindControllInfo">Jeśli stacja meteo skonfigurowana dla grupy roboczej poda prędkość wiatru powyżej tej wartości armatki pracujące w trybie Snowmatic zostaną automatycznie zatrzymane. Jeśli kontrola wiatru dla grupy roboczej jest wyłączona to armatki w grupie obowiązują ustawienia dla całego stoku.</p>
          <div class="row align-items-center">
            <div class="col-10">
              <form-number [min]="0" [max]="100" [step]="1" [(ngModel)]="limitWindSpeed" name="form-cfg-limitWindSpeed">
                <form-label i18n="Maksymalna prędkość wiatru|Etykieta pola do wprowadzenia max predkosci wiatru@@deviceConfigModalWindControllInputMaxSpeed">Maksymalna prędkość wiatru</form-label>
              </form-number>
            </div>
            <div class="col ml-1">m/s</div>
          </div>
        </div>
      </ss-page-panel>
      <ss-page-panel panelTitle="Limit produkcji śniegu"
        i18n-panelTitle="Limit produkcji śniegu|Tytuł panelu@@deviceConfigModalSnowProductionLimitPanelTitle">
        <form-checkbox [hideLabel]="true" [(ngModel)]="limitSnowProductionVolumeEnabled" name="form-cfg-limitSnowProductionVolumeEnabled">
          <form-label i18n="Włącz limit produkcji śniegu|Etykieta checkboxa, po którego zaznaczeniu można ustawić limit@@deviceConfigModalSnowProductionLimitCheckbox">Włącz limit produkcji śniegu</form-label>
        </form-checkbox>
        <div class="detailed-settings" [ngClass]="{ 'visible': limitSnowProductionVolumeEnabled }">
          <p i18n="Tak działa limit produkcji śniegu dla grupy|Akapit z informacjami@@workingAreaConfigModalSnowProductionLimitInfo">Jeśli sumaryczna produkcja śniegu wszystkich armatek w grupie w tym sezonie przekroczy tą wartość armatki pracujące w trybie Snowmatic nie będą uruchamiane.</p>
          <div class="row align-items-center">
            <div class="col-10">
              <form-number [min]="0" [max]="100000" [step]="100" [(ngModel)]="limitSnowProductionVolume" name="form-cfg-limitSnowProductionVolume">
                <form-label i18n="Limit ilości wyprodukowanego śniegu|Etykieta inputa@@deviceConfigModalSnowProductionLimitInputLabel">Limit ilości wyprodukowanego śniegu</form-label>
              </form-number>
            </div>
            <div class="col ml-1">m<sup>3</sup></div>
          </div>
          <ng-container *ngIf="lastSeasonsSnowProductionVolume && lastSeasonsSnowProductionVolume.length > 0">
            <p>
              <ng-container i18n="W poprzednich sezonach wyprodukowano|Etykiwta nad listą lat i produkcji@@deviceConfigModalProducedInPreviousSeasons">W poprzednich sezonach wyprodukowano</ng-container>:<br />
              <ng-container *ngFor="let s of lastSeasonsSnowProductionVolume; let last = last">
                {{ s.l }} - <a href="#" class="color-light-blue clickable" (click)="$event.preventDefault(); limitSnowProductionVolume = s.v">{{ s.v | number:'1.0-0' }}m<sup>3</sup></a>
                <ng-container *ngIf="!last">, </ng-container>
              </ng-container>
            </p>
          </ng-container>
        </div>
      </ss-page-panel>
      <ss-page-panel panelTitle="Limit zużycia wody"
        i18n-panelTitle="Limit zużycia wody|Tytuł panelu@@deviceConfigModalWaterConsumptionLimitPanelTitle">
        <form-checkbox [hideLabel]="true" [(ngModel)]="limitWaterConsumptionEnabled" name="form-cfg-limitWaterConsumptionEnabled">
          <form-label i18n="Włącz limit zużycia wody|Etykieta checkboxa, po którego zaznaczeniu można ustawić limit@@deviceConfigModalWaterConsumptionLimitCheckbox">Włącz limit zużycia wody</form-label>
        </form-checkbox>
        <div class="detailed-settings" [ngClass]="{ 'visible': limitWaterConsumptionEnabled }">
          <p i18n="Tak działa limit zużycia wody dla grupy|Akapit z informacjami@@workingAreaConfigModalWaterConsumptionLimitInfo">Jeśli sumaryczne zużycie wody wszystkich armatek w grupie w tym sezonie przekroczy tą wartość armatki pracujące w trybie Snowmatic nie będą uruchamiane.</p>
          <div class="row align-items-center">
            <div class="col-10">
              <form-number [min]="0" [max]="100000" [step]="100" [(ngModel)]="limitWaterConsumption" name="form-cfg-limitWaterConsumption">
                <form-label i18n="Limit ilości zużytej wody|Etykieta inputa@@deviceConfigModalWaterConsumptionLimitInputLabel">Limit ilości zużytej wody</form-label>
              </form-number>
            </div>
            <div class="col ml-1">m<sup>3</sup></div>
          </div>
          <ng-container *ngIf="lastSeasonsWaterConsumption && lastSeasonsWaterConsumption.length > 0">
            <p>
              <ng-container i18n="W poprzednich sezonach zużyto|Etykiwta nad listą lat i zużyć@@deviceConfigModalConsumedInPreviousSeasons">W poprzednich sezonach zużyto</ng-container>:<br />
              <ng-container *ngFor="let s of lastSeasonsWaterConsumption; let last = last">
                {{ s.l }} - <a href="#" class="color-light-blue clickable" (click)="$event.preventDefault(); limitWaterConsumption = s.v">{{ s.v | number:'1.0-0' }}m<sup>3</sup></a>
                <ng-container *ngIf="!last">, </ng-container>
              </ng-container>
            </p>
          </ng-container>
        </div>
      </ss-page-panel>
      <ss-page-panel panelTitle="Limit zużycia prądu"
        i18n-panelTitle="Limit zużycia prądu|Tytuł panelu@@deviceConfigModalPowerConsumptionLimitPanelTitle">
        <form-checkbox [hideLabel]="true" [(ngModel)]="limitPowerConsumptionEnabled" name="form-cfg-limitPowerConsumptionEnabled">
          <form-label i18n="Włącz limit zużycia prądu|Etykieta checkboxa, po którego zaznaczeniu można ustawić limit@@deviceConfigModalPowerConsumptionLimitCheckbox">Włącz limit zużycia prądu</form-label>
        </form-checkbox>
        <div class="detailed-settings" [ngClass]="{ 'visible': limitPowerConsumptionEnabled }">
          <p i18n="Tak działa limit zużycia prądu dla grupy|Akapit z informacjami@@workingAreaConfigModalPowerConsumptionLimitInfo">Jeśli sumaryczne zużycie prądu armatek w grupie w tym sezonie przekroczy tą wartość armatki pracujące w trybie Snowmatic nie będą uruchamiane.</p>
          <div class="row align-items-center">
            <div class="col-10">
              <form-number [min]="0" [max]="1000000" [step]="100" [(ngModel)]="limitPowerConsumption" name="form-cfg-limitPowerConsumption">
                <form-label i18n="Limit ilości prądu|Etykieta inputa@@deviceConfigModalPowerConsumptionLimitInputLabel">Limit ilości prądu</form-label>
              </form-number>
            </div>
            <div class="col ml-1">kWh</div>
          </div>
          <ng-container *ngIf="lastSeasonsPowerConsumption && lastSeasonsPowerConsumption.length > 0">
            <p>
              <ng-container i18n="W poprzednich sezonach zużyto|Etykiwta nad listą lat i zużyć@@deviceConfigModalConsumedInPreviousSeasons">W poprzednich sezonach zużyto</ng-container>:<br />
              <ng-container *ngFor="let s of lastSeasonsPowerConsumption; let last = last">
                {{ s.l }} - <a href="#" class="color-light-blue clickable" (click)="$event.preventDefault(); limitPowerConsumption = s.v">{{ s.v | number:'1.0-0' }}kWh</a>
                <ng-container *ngIf="!last">, </ng-container>
              </ng-container>
            </p>
          </ng-container>
        </div>
      </ss-page-panel>

      <ss-page-footer>
          <div></div>
          <ss-page-button-group>
            <button type="submit" class="btn btn-primary" i18n="Zapisz konfigurację|Przycisk zapisz@@workingAreaConfigModalSaveBtn">Zapisz konfigurację</button>
            <button type="button" class="btn btn-default" (click)="close()" i18n="Anuluj|Anuluj@@massOperationsModalCancelButon">Anuluj</button>
          </ss-page-button-group>
      </ss-page-footer>
  </ss-page>
  </form>
</div>
