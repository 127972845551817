import { Injectable, EventEmitter } from '@angular/core';
import { latLngBounds, map, tileLayer, WMSOptions } from 'leaflet';
import { Observable } from 'rxjs';
import { SnowCannon, ResortArea, SnowThickness } from 'src/app/shared/models';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../auth.service';
import { EndpointMapService } from '../../../../endpoint-map.service';

declare let L;

@Injectable({
  providedIn: 'root'
})
export class MapService {

  public seedTilesCache(bounds: any[]): Observable<{type: 'progress'|'done'; progress: number}> {
    return new Observable(subject => {
      const div = window.document.createElement('div');
      div.setAttribute('width', '100');
      div.setAttribute('height', '100');

      const bnd = latLngBounds(bounds);
      const layer = (this.getSatelliteTileLayer() as any);
      const lMap = map(div).fitBounds(bnd);
      layer.addTo(lMap);

      let prg = 0;
      layer.on('seedprogress', (ev) => {
        const prg2 = 0.01 * Math.round(100 - 100 * ev.remainingLength / ev.queueLength);
        if(prg !== prg2) {
          prg = prg2;
          subject.next({ type: 'progress', progress: prg });
        }
      });
      layer.on('seedend', () => {
        subject.next({ type: 'done', progress: 1 });
        subject.complete();
      });
      layer.on('tilecacheerror', (t, e) => {
        console.error('tilecacheerror', t, e);

        subject.next({ type: 'done', progress: 1 });
        subject.complete();
      });
      layer.on('error', (t, e) => {
        console.error('tilecacheerror', t, e);

        subject.next({ type: 'done', progress: 1 });
        subject.complete();
      });
      layer.on('tileerror', (t, e) => {
        console.error('tilecacheerror', t, e);

        subject.next({ type: 'done', progress: 1 });
        subject.complete();
      });

      layer.seed(bnd, 15, 17);

      if(!layer.options.useCache) {
        subject.next({ type: 'done', progress: 1 });
        subject.complete();
      }
    });
  }

  public getSatelliteTileLayer() {
    const layerOptions = {
      minZoom: 15,
      maxZoom: 18,
      maxNativeZoom: 17,

      edgeBufferTiles: 2,
      cacheFormat: 'image/jpeg',
      useCache: environment.useMapCache,
      cacheURLMask: /\?bearer=.*/,
      cacheMaxAge: 30 * 24 * 3600 * 1000,
      opacity: 1,
      attribution: 'Custom cached map layers from Google Maps or ESRI'
    };
    return tileLayer(this.endpointMapService.satelliteTiles, layerOptions);
  }

  public getHipsoTileLayer(initialOpacity = 0) {
    const layerOptions = {
      minZoom: 15,
      maxZoom: 18,
      maxNativeZoom: 18,

      edgeBufferTiles: 2,
      opacity: initialOpacity,
      attribution: 'Custom cached map layers from thunderforest.com'
    };
    return tileLayer(this.endpointMapService.hipsoTiles, layerOptions);
  }

  public getGeoportalTileLayer(initialOpacity = 0) {
    const layerOptions = {
      layers: 'Raster',
      styles: 'default',
      format: 'image/jpeg',
      version: '1.3.0',
      transparent: false,
      edgeBufferTiles: 2,
      minZoom: 15,
      maxZoom: 18,
      maxNativeZoom: 18,
      opacity: initialOpacity,
      uppercase: true,
      crossOrigin: true
    };

    return tileLayer.wms(this.endpointMapService.geoportalSatelliteTiles, layerOptions);
  }

  public panMapTo: EventEmitter<string> = new EventEmitter();
  public mapBearing: EventEmitter<number> = new EventEmitter();
  public monitoredMarkerPosition: EventEmitter<{ x: number; y: number}> = new EventEmitter();
  public showLayers: EventEmitter<string[]> = new EventEmitter();
  public snowThicknesLayerUpdated: EventEmitter<SnowThickness> = new EventEmitter();

  public curretMapBearing = 0;

  setShowLayers(...layers: string[]) {
    this.showLayers.emit(layers);
  }

  updateSnowThicknesLayer(thk: SnowThickness) {
    this.snowThicknesLayerUpdated.emit(thk);
  }

  mapToSnowCannon(snowCannon: SnowCannon) {
    if (snowCannon) {
      this.panMapTo.emit(snowCannon.id);
    }
  }

  mapToArea(area: ResortArea) {
    this.panMapTo.emit('area');
  }

  mapToAreaWithoutMenus() {
    this.panMapTo.emit('area-wo-menus');
  }

  constructor(
    private authService: AuthService,
    private endpointMapService: EndpointMapService,
  ) {
    this.mapBearing.subscribe(bearing => {
      this.curretMapBearing = bearing;
    });
  }
}
