<ss-page [short]="true">
  <ss-page-toolbar>
    <div class="input-holder">
      <input type="text" class="input"
        [(ngModel)]="deviceSearch"
        (keyup)="searchDevices()"
        (blur)="searchDevices()"
        i18n-placeholder="Szukaj urządzenia, trasy, grupy|Placeholder dla wyszukwiarki powiadomień@@notificationsSearchPlaceholder" placeholder="Szukaj urządzenia, trasy, grupy">
      <svg>
        <use xlink:href='#icon-search'/>
      </svg>
    </div>
    <p i18n="Typ|Tytuł przed wyborem typu urządzeń@@notificationsSearchByDeviceTypeTitle">Typ</p>
    <div class="btn-group">
      <button class="btn btn-dark"
        (click)="toggleInArray('ResortArea', selectedTypes)"
        [ngClass]="{ 'btn-pressed': inArray('ResortArea', selectedTypes) }"
        i18n="Stok|Szykaj po typie urzadzenia 'Stok'@@notificationsSearchByDeviceTypeArea">Stok</button>
      <button class="btn btn-dark" *ngIf="showGroups"
        (click)="toggleInArray('WorkingArea', selectedTypes)"
        [ngClass]="{ 'btn-pressed': inArray('WorkingArea', selectedTypes) }"
        i18n="Grupa|Szykaj po typie urzadzenia 'Grupa'@@notificationsSearchByDeviceTypeGroup">Grupa</button>
      <button class="btn btn-dark"
        (click)="toggleInArray('PumpRoom|WaterPipeline', selectedTypes)"
        [ngClass]="{ 'btn-pressed': inArray('PumpRoom|WaterPipeline', selectedTypes) }"
        i18n="Pompownia|Szykaj po typie urzadzenia 'Pompownia'@@notificationsSearchByDeviceTypePumproom">Pompownia</button>
      <button class="btn btn-dark"
        (click)="toggleInArray('PowerSource|ElectricalLine', selectedTypes)"
        [ngClass]="{ 'btn-pressed': inArray('PowerSource|ElectricalLine', selectedTypes) }"
        i18n="Zasilanie|Szykaj po typie urzadzenia 'Zasilanie'@@notificationsSearchByDeviceTypePowerSource">Zasilanie</button>
      <button class="btn btn-dark" *ngIf="showMeteoStations"
        (click)="toggleInArray('MeteoStation', selectedTypes)"
        [ngClass]="{ 'btn-pressed': inArray('MeteoStation', selectedTypes) }"
        i18n="Stacja meteo|Szykaj po typie urzadzenia 'Stacja meteo'@@notificationsSearchByDeviceTypeMeteoStation">Stacja meteo</button>
      <button class="btn btn-dark"
        (click)="toggleInArray('SnowCannon', selectedTypes)"
        [ngClass]="{ 'btn-pressed': inArray('SnowCannon', selectedTypes) }"
        i18n="Armatka|Szykaj po typie urzadzenia 'Armatka'@@notificationsSearchByDeviceTypeCannon">Armatka</button>
      <button class="btn btn-dark"
        (click)="toggleInArray('ConnectionPoint', selectedTypes)"
        [ngClass]="{ 'btn-pressed': inArray('ConnectionPoint', selectedTypes) }"
        i18n="Hydrant|Szykaj po typie urzadzenia 'Hydrant'@@notificationsSearchByDeviceTypeHydrant">Hydrant</button>
    </div>
    <p i18n="Priorytet|Tytul przed wyborem poziomu priorytetu alarmu@@notificationsSearchByLevelTitle">Priorytet</p>
    <div class="btn-group">
      <button class="btn btn-dark"
        (click)="toggleInArray('alert', selectedLevels)"
        [ngClass]="{ 'btn-pressed': inArray('alert', selectedLevels) }"
        i18n="Alert|Szukaj alertów z powiomem 'Alert'@@notificationsSearchByLevelAlert">Alert</button>
      <button class="btn btn-dark"
        (click)="toggleInArray('warning', selectedLevels)"
        [ngClass]="{ 'btn-pressed': inArray('warning', selectedLevels) }"
        i18n="Ostrzeżenie|Szukaj alertów z powiomem 'Ostrzeżenie'@@notificationsSearchByLevelWarning">Ostrzeżenie</button>
      <button class="btn btn-dark"
        (click)="toggleInArray('notification', selectedLevels)"
        [ngClass]="{ 'btn-pressed': inArray('notification', selectedLevels) }"
        i18n="Notyfikacja|Szukaj alertów z powiomem 'Notyfikacja'@@notificationsSearchByLevelNotification">Notyfikacja</button>
    </div>
    <ss-checkbox [isChecked]="onlyActive" (hasChanged)="onlyActive=$event">
      <ng-container i18n="Tylko trwające|Checkbox warunku 'pokaż tylko trwające awarie'@@notificationsSearchOnlyNotExpired">Tylko trwające</ng-container>
    </ss-checkbox>
    <div class="filler"></div>
    <button class="btn btn-danger"
      (click)="resetHardwareAlerts()"
      i18n="Resetuj alarmy|Przycisk akcji 'Resetuj alarmy' w tabeli@@notificationsButtonResetAlerts">Resetuj alarmy</button>
  </ss-page-toolbar>
  <ss-page-panel>
    <div class="row">
      <div class="col">
        <p *ngIf="foundDevicesCount > 11"
          i18n="Znalezione urządzenia:|Etykieta 'Znalezione urządzenia:' przed liczbą znalezionych urządzeń@@notificationsResultInfoFoundDevicesCountInfo">
          Znalezione urządzenia: {{ foundDevicesCount }}
        </p>
        <p *ngIf="foundDevicesCount > 0 && foundDevicesCount <= 11">
          <ng-container i18n="Znalezione urządzenia:|Etykieta 'Znalezione urządzenia:' przed lista znalezionych urządzeń@@notificationsResultInfoFoundDevicesListInfo">
            Znalezione urządzenia:
          </ng-container>
          <button class="btn btn-inline btn-link"
            *ngFor="let d of foundDevices; let last = last" (click)="selectDevice(d.id)">
            <ss-translated-device-type-symbol [device]="d"></ss-translated-device-type-symbol><ng-container *ngIf="!last">,</ng-container>
          </button>
        </p>
      </div>
      <div class="col-auto">
        <p class="white-space-nowrap"
          i18n="Ilość notyfikacji|Etykieta 'Ilość notyfikacji' przed liczbą znalezionych notyfikacji@@notificationsResultInfoAlertsCount">Ilość notyfikacji: {{ alertCount }}</p>
      </div>
    </div>
    <div class="mx--4 my-3">
      <ss-notification-list
        (alertCount)="alertCount = $event"
        (clickedId)="selectDevice($event)"
        [allowDynamicLoading]="true"
        [context]="contextIds"
        [levels]="selectedLevels"
        [onlyActive]="onlyActive"></ss-notification-list>
    </div>
  </ss-page-panel>
</ss-page>
