import { Component, OnInit, Input, QueryList, ContentChildren, OnChanges, OnDestroy } from '@angular/core';
import { PumproomStatusComponent } from './status.component';

@Component({
  selector: 'ss-pump-room-detail-multi-status',
  template: '<ng-content></ng-content>',
  styleUrls: []
})
export class PumproomMultiStatusComponent implements OnInit, OnChanges, OnDestroy {

  constructor() { }

  @Input()
  public hideDescriptions = false;
  @Input()
  public components: { computedStatus: string }[];

  @ContentChildren(PumproomStatusComponent)
  public get statusses(): QueryList<PumproomStatusComponent> {
    return this._statusses;
  }
  public set statusses(v: QueryList<PumproomStatusComponent>) {
    this._statusses = v;
    this.countStatusses();
  }
  private _statusses: QueryList<PumproomStatusComponent>;

  refreshInterval;

  ngOnInit() {
    this.countStatusses();
    this.refreshInterval = setInterval(() => {
      this.countStatusses();
    }, 250);
  }

  ngOnChanges() {
    this.countStatusses();
  }

  ngOnDestroy() {
    clearInterval(this.refreshInterval);
  }

  private countStatusses() {
    if (this.statusses && this.components) {
      this.statusses.forEach(statusComponent => {
        statusComponent.hideDescription = this.hideDescriptions;
        statusComponent.count = this.components.filter(c => statusComponent && c.computedStatus === statusComponent.status).length;
      });
    }
  }

}
