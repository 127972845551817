<div class="badge d-inline-flex align-items-center justify-content-center px-2 py-1"
  [ngClass]='{
    "badge--small": size === "small",
    "badge--danger": color === "danger",
    "badge--success": color === "success",
    "badge--warning": color === "warning",
    "badge--info": color === "info"
  }'
>
  <ng-content></ng-content>
</div>
