<div class="row movable-cannon align-items-center">
  <div class="col-auto handler cannon-handle">
    <svg>
      <use xlink:href="#icon-move" />
    </svg>
  </div>
  <div class="col-auto symbol">
    {{ snowCannon.symbol }}
  </div>
  <div class="col">
    <ss-snow-cannon-status size="small" [state]="snowCannon.computedStatus"></ss-snow-cannon-status>
  </div>
  <div class="col-auto edits">
    <!-- <button class="btn btn-defaut" (click)="goToCannonConfig(snowCannon.id)">
      <svg>
        <use xlink:href="#icon-gear" />
      </svg>
    </button> -->
  </div>
</div>
