import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResortArea, PumpRoom, Resort, WaterReservoir } from 'src/app/shared/models';
import { SettingsService } from 'src/app/settings.service';
import { HeaderService } from 'src/app/header.service';
import { AreasData } from '../areas-data-resolver.service';
import { AreasHeaderExtensionComponent } from '../areas-header-extension/areas-header-extension.component';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { toSearchString } from '../../../../../../../common';

@Component({
  selector: 'ss-areas-list',
  templateUrl: './areas-list.component.html',
  styleUrls: ['./areas-list.component.sass']
})
export class AreasListComponent implements OnInit {

  constructor(
    private settingsService: SettingsService,
    private titleService: HeaderService,
    private route: ActivatedRoute
  ) {
  }

  public duration: number = null;

  areasExpanded = false;
  areasFilter = '';

  resort: Resort;
  areas: ResortArea[] = [];
  pumpRooms: PumpRoom[] = [];
  waterReservoirs: WaterReservoir[];

  @ViewChild(PerfectScrollbarComponent, { static: true }) componentRef?: PerfectScrollbarComponent;

  public setDuration(val: number) {
    this.duration = val;
  }

  ngOnInit() {
    this.route.data
      .subscribe((data: { areasData: AreasData }) => {
        Object.assign(this, data.areasData);

        this.titleService.setUpdateHeader('menuResortAreas', AreasHeaderExtensionComponent, {
          resortName: this.resort.symbol,
          isMap: false
        });
      });
  }
  filteredAreas(): ResortArea[] {
    return this.areas.filter(a => toSearchString(a.symbol)
      .includes(toSearchString(this.areasFilter)))
      .sort((a, b) => a.symbol.localeCompare(b.symbol));
  }
  moveToSearchResults() {
    if (!this.areasExpanded) {
      setTimeout(() => {
        this.componentRef.directiveRef.scrollToElement('.main-info__col', 0, 500);
      }, 100);
    }
    this.areasExpanded = true;
  }


  addShadows(event) {
    const prevEl = ((event.srcElement).previousElementSibling);
    const nextEl = ((event.srcElement).nextElementSibling);

    if (prevEl && Array.from(prevEl.classList).includes('table__row')) {
      prevEl.classList.add('table__row--hovered-prev');
    }
    if (nextEl && Array.from(nextEl.classList).includes('table__row')) {
      nextEl.classList.add('table__row--hovered-next');
    }
  }

  removeShadows(event) {
    const prevEl = ((event.srcElement).previousElementSibling);
    const nextEl = ((event.srcElement).nextElementSibling);

    if (prevEl && Array.from(prevEl.classList).includes('table__row')) {
      prevEl.classList.remove('table__row--hovered-prev');
    }
    if (nextEl && Array.from(nextEl.classList).includes('table__row')) {
      nextEl.classList.remove('table__row--hovered-next');
    }
  }
}
