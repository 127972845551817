import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { TabComponent } from 'src/app/shared/components/tabs/tab/tab.component';
import { ResortArea } from 'src/app/shared/models';
import { ModalRouteService } from 'src/app/application/modal-route.service';

@Component({
  selector: 'ss-area-settings-tab',
  templateUrl: './area-settings-tab.component.html',
  styleUrls: ['./area-settings-tab.component.sass'],
  providers: [
    {provide: TabComponent, useExisting: forwardRef(() => AreaSettingsTabComponent), multi: true}
  ]
})
export class AreaSettingsTabComponent extends TabComponent {

  @Input()
  public area: ResortArea;

  constructor(
    private hes: ModalRouteService,
  ) {
    super();
  }

  public goToAreaConfig() {
    this.hes.showModalRoute('resort-area-config-modal/' + this.area.id);
  }
}
