<div [ngStyle]="{
    top: areaCenterY + 'px',
    left: areaCenterX + 'px'
  }"
  [ngClass]="{
    show: areaCenterX && areaCenterY && snowCannon && snowCannon.connectionStatus
  }"
  class="position-editor">

  <div class="position-editor__view-switches">
    <ng-container *ngIf="snowCannon && (snowCannon.CAP_CHANGE_HORIZONTAL_POSITION_ANGLE || snowCannon.CAP_CHANGE_OSCILLATION_ANGLE)">
        <button [ngClass]="{
            'selected': selectedView === 'horizontal'
        }"
        (click)="selectedView = 'horizontal'">
            <svg>
                <use xlink:href='#icon-range'/>
            </svg>
        </button>
    </ng-container>
    <ng-container *ngIf="snowCannon && snowCannon.CAP_CHANGE_VERTICAL_POSITION_ANGLE">
        <button [ngClass]="{
            'selected': selectedView === 'inclination'
        }"
        (click)="selectedView = 'inclination'">
            <svg>
                <use xlink:href='#icon-angle'/>
            </svg>
        </button>
    </ng-container>
    <ng-container *ngIf="snowCannon && snowCannon.CAP_CHANGE_POSITION_MANUALLY">
        <button [ngClass]="{
            'selected': selectedView === 'manual'
        }"
        (click)="selectedView = 'manual'">
            <svg>
                <use xlink:href='#icon-move-any-direction'/>
            </svg>
        </button>
    </ng-container>
  </div>

  <div class="position-editor__controll-wrapper"
    [ngClass]="{
        show: selectedView==='horizontal'
    }">
    <ss-snow-cannon-position-editor-horizontal
        [currentMapBearing]="currentMapBearing"
        [snowCannon]="snowCannon"
        [areaCenterX]="areaCenterX"
        [areaCenterY]="areaCenterY"
        (executeCommand)="handleCommand($event)"
        ></ss-snow-cannon-position-editor-horizontal>
  </div>
  <div class="position-editor__controll-wrapper"
    [ngClass]="{
        show: selectedView==='inclination'
    }">
    <ss-snow-cannon-position-editor-inclination
        [snowCannon]="snowCannon"
        [areaCenterX]="areaCenterX"
        [areaCenterY]="areaCenterY"
        (executeCommand)="handleCommand($event)"
        ></ss-snow-cannon-position-editor-inclination>
  </div>
  <div class="position-editor__controll-wrapper"
    [ngClass]="{
        show: selectedView==='manual'
    }">
    <ss-snow-cannon-position-editor-manual
        [snowCannon]="snowCannon"
        (executeCommand)="handleCommand($event)"
        ></ss-snow-cannon-position-editor-manual>
  </div>

  <div class="position-editor__center" *ngIf="snowCannon">
    <ss-snow-cannon-icon size="bigest" [label]="snowCannon.symbol" [state]="snowCannon.computedStatus"></ss-snow-cannon-icon>
  </div>
</div>
