<div class="form-row" [ngClass]="{ 'is-required': markIsRequired }">
    <div class="form-label" *ngIf="!hideLabel">
      <label [attr.for]="identifier">
        <span #labelContent><ng-content select="form-label"></ng-content></span>
        <ng-container *ngIf="!labelContent.innerHTML.trim()">{{ label }}</ng-container>
      </label>
    </div>
    <div class="form-control" [ngClass]="{invalid: (invalid | async), 'form-control-openned': isOpenned}">
      <form-errors-display [extraErrorComponents]="extraErrorComponents" [messages]="failures | async">
        <ng-content select="form-error"></ng-content>
      </form-errors-display>

      <ng-select
        class="custom-select"
        [ngClass]="{ 'custom-select--wide': isExtraWide }"
        (open)="isOpenned = true"
        (close)="isOpenned = false"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [(ngModel)]="value"
        [clearable]="false"
        [searchable]="false"
        [name]="name"
        [id]="identifier">
        <ng-option *ngIf="placeholder" value="" selected>{{placeholder}}</ng-option>
        <ng-option *ngFor="let o of options" [value]="o.device ? o.device : o.value" [disabled]="o.disabled">
          <ng-container *ngIf="!o.device">{{ o.label }}</ng-container>
          <ss-translated-device-type-symbol *ngIf="o.device" [device]="o.device"></ss-translated-device-type-symbol>
          <ss-translated-report-field-name *ngIf="o.deviceField" [fieldName]="o.deviceField"></ss-translated-report-field-name>
        </ng-option>
      </ng-select>
  </div>
</div>
