import { Component, Input } from '@angular/core';
import { SnowCannon } from 'src/app/shared/models';

@Component({
  selector: 'ss-cannon-preview',
  templateUrl: './cannon-preview.component.html',
  styleUrls: []
})
export class CannonPreviewComponent {

  @Input()
  snowCannon: SnowCannon;

  @Input()
  direction: string;

}
