import { Component, OnInit } from '@angular/core';
import {
  Device,
  DeviceSystemType,
  ReportPart,
  ReportSeries,
  SubjectFieldInterface,
  subjectFieldMapping,
  translateDeviceType
} from '../../../../../../../common';
import { ResortService } from '../../../../resort.service';
import { ModalRouteService } from '../../../modal-route.service';
import { ReportingService } from '../../reporting.service';

@Component({
  selector: 'ss-part-creator-modal',
  templateUrl: './part-creator-modal.component.html',
  styleUrls: ['./part-creator-modal.component.sass']
})
export class ReportingPartCreatorModalComponent implements OnInit {

  constructor(
    private rs: ResortService,
    private rds: ReportingService,
    private mr: ModalRouteService
  ) { }

  public part: ReportPart;
  public devicesForSubjectType: Device[] = [];
  public usedDevices: Device[] = [];
  public canSave = false;
  public canEdit = false;
  public maxSeriesNumber = 2;

  public acceptableSubjectTypes: DeviceSystemType[] = [];

  ngOnInit(): void {
    const lrd = this.mr.lastRequestData || {};

    this.usedDevices = (lrd.usedDeviceUuids || []).map(uuid => this.rs.getById(uuid));
    this.part = ReportPart.create(lrd.part, true);


    this.acceptableSubjectTypes = Object.values(subjectFieldMapping)
        .filter(en => this.rds.getDevidesBySubjectType(en.systemType).length > 0)
        .map(en => en.systemType)
        .sort((a, b) => translateDeviceType(a).localeCompare(translateDeviceType(b)));

    this.validateSeries();
  }

  public close() {
    this.mr.closeModal();
  }

  public save() {
    this.mr.closeModal(this.part);
  }

  public getDeviceById(id: string): Device {
    return this.rs.getById(id);
  }

  public getFieldDescriptionsBySeries(s: ReportSeries): SubjectFieldInterface[] {
    const available = Object.values(subjectFieldMapping[s.subjectType].fields);

    return available.filter(f => f.fieldName === s.subjectField
        || this.part.series.filter(sf =>
          sf.subjectId === s.subjectId
          && sf.subjectField === f.fieldName
        ).length === 0);
  }

  public addSeries(dev?: Device) {
    this.part.series.push(ReportSeries.create(dev ? {
      subjectType: dev.systemType,
      subjectId: dev.id
    } : {}));

    this.validateSeries();
  }

  public selectSubjectType(s: ReportSeries, subjectType: DeviceSystemType) {
    s.subjectType = subjectType;
    const devicesForSubjectType = this.rds.getDevidesBySubjectType(subjectType)
      .sort((a, b) => {
        const anum = parseInt(`${a.symbol}`, 10);
        const bnum = parseInt(`${b.symbol}`, 10);
        return isNaN(anum) || isNaN(bnum) ? `${a.symbol}`.localeCompare(`${b.symbol}`) : anum - bnum;
      });

    if(devicesForSubjectType.length === 1) {
      s.subjectId = devicesForSubjectType[0].id;
    } else {
      this.devicesForSubjectType = devicesForSubjectType;
    }
    this.validateSeries();
  }

  public selectSubjectId(s: ReportSeries, id: string) {
    s.subjectId = id;
    this.validateSeries();
  }

  public removeSeries(s: ReportSeries) {
    const idx = this.part.series.indexOf(s);
    if(idx >= 0) {
      this.part.series.splice(idx, 1);
    }
    this.validateSeries();
  }

  public validateSeries() {
    this.maxSeriesNumber = ['chart', 'sum-chart'].includes(this.part.partType) ? 2 : 1;
    this.canEdit = this.part.series.reduce((prev, cur) => prev && !!cur.subjectId && !!cur.subjectType && !!cur.subjectField, true);
    this.canSave = this.part.series && this.part.series.length > 0 && this.canEdit;
  }

}
