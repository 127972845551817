<div class="row">
  <div class="col-auto">
    <p class="summary py-3">
      <ng-container i18n="Stan urządzeń|Etykieta pola wyświetlającego ilość urządzeń@@areaSnowCannonsAmount">Stan urządzeń</ng-container>
      <span class="pl-2">{{ (snowCannons ? snowCannons.length : 0)|number:'1.0-0' }}</span>
    </p>
  </div>
  <div class="col"></div>
  <div class="col-auto">
    <p class="summary py-2">
      <button class="btn btn-link text-uppercase" (click)="openMassOperationsForAll()" i18n="Zmień ustawienia wszystkich armat|Button masz operations dla armat@@areaSnowCannonsMassOperationsAll">Zmień ustawienia wszystkich armat</button>
    </p>
  </div>
</div>

<div *ngIf="area.countByBreakdown() > 0"
  [ngClass]="{ 'expanded': isAlertsDropdownExpanded }"
  class="cannon-alerts mb-3">
  <button class="cannon-alert p-0" (click)="isAlertsDropdownExpanded = !isAlertsDropdownExpanded">
    <span class="cannon-alert__number">{{ area.countByBreakdown() }}</span>
    <div class="cannon-alert__details px-3">
      <span i18n="Awaria|Etykieta listy komunikatów o błędzie działania armatki@@areaAlertsListLabel">Awaria</span>
      <svg class="cannon-alerts__angle" [ngClass]="{'cannon-alerts__angle--rotated': isAlertsDropdownExpanded}">
        <use xlink:href='#icon-chevron-up' />
      </svg>
    </div>
  </button>
  <ul class="cannon-alerts__list">
    <li *ngFor="let alert of cannonAlerts" class="cannon-alert">
      <span class="cannon-alert__thumbnail">

      </span>
      <div class="cannon-alert__details px-3">
        <div class="cannon-alert__name">
          <span>
              <ss-translated-device-type-symbol [device]="alert.deviceReference"></ss-translated-device-type-symbol>
          </span>
          <span class="pl-2">
            <ss-translated-device-alert [alert]="alert.alertType"></ss-translated-device-alert>
          </span>
        </div>
        <div class="cannon-alert__info">
          <span>{{ alert.occurred|date:'HH:mm:ss' }}</span>
          <span class="pl-1">{{ alert.occurred|date:'yyyy-MM-dd' }}</span>
          <a [routerLink]="['/application/resort/area', area.id, 'map', alert.deviceId]" class="pl-4" i18n="Szczegóły|Etykieta przycisku prowadzącego do urządzenia, które uległo awarii@@areaAlertDetails">Szczegóły</a>
        </div>
      </div>
    </li>
  </ul>

</div>
<div class="devices-states">
  <div class="devices-states__state devices-states__state--main">
    <span class="devices-states__number">{{ area.countByWorking() }}</span>
    <ng-container i18n="Pracuje|Nazwa stanu urządzenia@@stateWorking">Pracuje</ng-container>
  </div>
  <div class="devices-states__state devices-states__state--main">
    <span class="devices-states__number">{{ area.countByAwaitingForConditions() }}</span>
    <ng-container i18n="Czeka na warunki|Nazwa stanu urządzenia@@stateAwaitingForConditions">Czeka na warunki</ng-container>
  </div>
  <div class="devices-states__state devices-states__state--main">
    <span class="devices-states__number">{{ area.countByReadyToWork() }}</span>
    <ng-container i18n="Gotowość|Nazwa stanu urządzenia@@stateReadyToWork">Gotowość</ng-container>
  </div>
  <div class="devices-states__state devices-states__state--main">
    <span class="devices-states__number">{{ area.countByStandingBy() }}</span>
    <ng-container i18n="Czuwanie|Nazwa stanu urządzenia@@stateStandingBy">Czuwanie</ng-container>
  </div>
  <div class="devices-states__state">
    <span class="devices-states__number">{{ area.countByStarting() }}</span>
    <ng-container i18n="Uruchamianie|Nazwa stanu urządzenia@@stateStarting">Uruchamianie</ng-container>
  </div>
  <div class="devices-states__state">
    <span class="devices-states__number">{{ area.countBySnoozing() }}</span>
    <ng-container i18n="Usypianie|Nazwa stanu urządzenia@@stateSnoozing">Usypianie</ng-container>
  </div>
  <div class="devices-states__state">
    <span class="devices-states__number">{{ area.countByStopping() }}</span>
    <ng-container i18n="Zatrzymywanie|Nazwa stanu urządzenia@@stateStopping">Zatrzymywanie</ng-container>
  </div>
  <div class="devices-states__state">
    <span class="devices-states__number">{{ area.countByPreparing() }}</span>
    <ng-container i18n="Przygotowanie|Nazwa stanu urządzenia@@statePreparing">Przygotowanie</ng-container>
  </div>
</div>

<div class="cannon-manual-info mt-3" *ngIf="area.countByWorkingInManualMode() > 0">
  <span class="cannon-manual-info__number">{{ area.countByWorkingInManualMode() }}</span>
  <div class="cannon-manual-info__details px-3">
    <span i18n="Praca w trybie manualnym|Etykieta ilości armatek pracujących manualnie@@areaManualModeListLabel">Praca w trybie manualnym</span>
  </div>
</div>
