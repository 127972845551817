<ng-container *ngIf="!hideDescription ; else showOnlyNumbers">
  <span class="counter" *ngIf="count >= 0">{{ count | number }}</span>
  <ng-container [ngSwitch]="status">
    <span *ngSwitchCase="'breakdown'"
      i18n="Awaria|Ststus pracy w pompownii@@pumproomDetailStatusBreakdown">Awaria</span>
    <span *ngSwitchCase="'connection-lost'"
      i18n="Brak połączenia|Ststus pracy w pompownii@@pumproomDetailStatusConnectionLost">Brak połączenia</span>
    <span *ngSwitchCase="'disabled'"
      i18n="Wyłączone|Ststus pracy w pompownii@@pumproomDetailStatusModeOfOperationDisabled">Wyłączone</span>
    <span *ngSwitchCase="'dry-run'"
      i18n="Suchobieg|Ststus pracy w pompownii@@pumproomDetailStatusDryRun">Suchobieg</span>
    <span *ngSwitchCase="'enabled'"
      i18n="Włączone|Ststus pracy w pompownii@@pumproomDetailStatusModeOfOperationEnabled">Włączone</span>
    <span *ngSwitchCase="'filling'"
      i18n="Napełnianie|Ststus pracy w pompownii@@pumproomDetailStatusFilling">Napełnianie</span>
    <span *ngSwitchCase="'fsl'"
      i18n="Fsl|Ststus pracy w pompownii@@pumproomDetailStatusFsl">Fsl</span>
    <span *ngSwitchCase="'overcurrent'"
      i18n="Prąd|Ststus pracy w pompownii@@pumproomDetailStatusOvercurrent">Prąd</span>
    <span *ngSwitchCase="'overheated'"
      i18n="Przegrzanie|Ststus pracy w pompownii@@pumproomDetailStatusOverheat">Przegrzanie</span>
    <span *ngSwitchCase="'ready-to-work'"
      i18n="Gotowość|Ststus pracy w pompownii@@pumproomDetailStatusReadyToWork">Gotowość</span>
    <span *ngSwitchCase="'snoozing'"
      i18n="Uśpienie|Ststus pracy w pompownii@@pumproomDetailStatusSnoozing">Uśpienie</span>
    <span *ngSwitchCase="'standing-by'"
      i18n="Czuwanie|Ststus pracy w pompownii@@pumproomDetailStatusStandingBy">Czuwanie</span>
    <span *ngSwitchCase="'valve-is-closed'"
      i18n="Zamknięty|Ststus pracy w pompownii@@pumproomDetailStatusClosed">Zamknięty</span>
    <span *ngSwitchCase="'valve-is-open'"
      i18n="Otwarty|Ststus pracy w pompownii@@pumproomDetailStatusOpened">Otwarty</span>
    <span *ngSwitchCase="'working'"
      i18n="Praca|Ststus pracy w pompownii@@pumproomDetailStatusWorking">Praca</span>
    <span *ngSwitchCase="'working-in-manual-mode'"
      i18n="Sterowanie ręczne|Ststus pracy w pompownii@@pumproomDetailStatusManualMode">Sterowanie ręczne</span>
  </ng-container>
</ng-container>
<ng-template #showOnlyNumbers>
  <span class="counter hide-desctiptions">{{ (count || 0) | number }}</span>
</ng-template>