import {NgModel} from '@angular/forms';

import {Observable, of, never} from 'rxjs';

import {ValueAccessorBase} from './value-accessor';

import {
  AsyncValidatorArray,
  ValidatorArray,
  ValidationResult,
  validate,
} from './validate';
import { map } from 'rxjs/operators';
import { Input, ContentChildren, QueryList, Directive } from '@angular/core';
import { FormErrorComponent } from './form-error.component';

@Directive()
// eslint-disable-next-line
export abstract class ElementBase<T> extends ValueAccessorBase<T> {
  public abstract model: NgModel;


  @ContentChildren(FormErrorComponent)
  public extraErrorComponents = new QueryList<any>();

  @Input() public set requiredTrue(val: any) {
    this.markIsRequired = true;
  }
  @Input() public set required(val: any) {
    this.markIsRequired = true;
  }
  public markIsRequired = false;

  @Input() public name: string;

  @Input() public disabled = false;

  @Input() public hideLabel = false;

  constructor(
    private validators: ValidatorArray,
    private asyncValidators: AsyncValidatorArray,
  ) {
    super();
  }

  protected validate(): Observable<ValidationResult> {
    return validate
      (this.validators, this.asyncValidators)
      (this.model.control);
  }

  public get invalid(): Observable<boolean> {
    return this.validate().pipe(map(v => Object.keys(v || {}).length > 0 && this.model.dirty));
  }

  public get failures(): Observable<ValidationResult> {
    return this.validate();
  }
}

