<div [ngClass]="{ 'disabled': lock }">
  <ss-page [modal]="true" *ngIf="user">
      <ss-page-header>
          <ss-page-header-title i18n="Resetuj hasło użytkownika X|Resetuj hasło użytkownika X@@resetUserPasswordModalTitle">Resetuj hasło użytkownika {{ user.email }}</ss-page-header-title>
      </ss-page-header>
      <ss-page-panel>
        <p i18n="Info o resecie|Info o resecie@@resetUserPasswordModalDescription">Zresetowanie hasła jest nieodwracalne i natychmiastowe. Po naciśnieciu przycisku zostanie wygenerowane nowe hasło. Należy je skopiować lub zapisać i podać użytkownikowi. Użytkownik powinien zmienić hasło po pierwszym zalogowaniu się.</p>

        <div class="d-flex justify-content-center p-4 button-input-container" [ngClass]="{ 'locked': lock, 'got-button': !temporaryPassword, 'got-input': temporaryPassword }">
          <button (click)="reset()" class="btn p-4 btn-danger" i18n="Przycisk Zresetuj hasło|Przycisk Zresetuj hasło@@resetUserPasswordModalReserBurron">Zresetuj hasło</button>
          <input type="text" class="input d-flex text-center" readonly [value]="temporaryPassword" />
        </div>
      </ss-page-panel>
      <ss-page-footer>
          <div></div>
          <ss-page-button-group>
              <button class="btn btn-default" (click)="close()" i18n="Zamknij|Zamknij@@resetUserPasswordModalCloseButon">Zamknij</button>
          </ss-page-button-group>
      </ss-page-footer>
  </ss-page>
</div>
