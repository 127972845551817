import {
  Component,
  Optional,
  Inject,
  Input,
  ViewChild
} from '@angular/core';
import {
  NgModel,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NG_ASYNC_VALIDATORS,
} from '@angular/forms';
import { ElementBase } from '../element-base';

let identifier = 0;

@Component({
  selector: 'form-range',
  templateUrl: './form-range.component.html',
  styleUrls: [],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FormRangeComponent,
    multi: true,
  }],
})
export class FormRangeComponent  extends ElementBase<number> {
  public identifier = `form-range-${identifier++}-` + Math.random();

  public formatScale = '1.1-1';

  @Input() public label: string;
  @Input() public min = 0;
  @Input() public max = 100;
  @Input()
  public get step(): number {
    return this._step;
  }
  public set step(val: number) {
    let prec: number = Math.ceil(Math.log10(Math.abs(val)));
    prec = prec < 1 ? 1 : prec;

    const afterDecPoint: number = Math.abs(Math.round(val) - val);

    let scale = 0;
    if (afterDecPoint > 0) {
      scale = Math.ceil(Math.log10(afterDecPoint));
      scale = scale < -6 ? 6 : Math.abs(scale);
    }

    this.formatScale = prec + '.' + scale + '-' + scale;
    this.prec = prec;
    this.scale = scale;
    this._step = val;
  }
  private _step = 0;

  public prec = 0;
  public scale = 0;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }
}
