<div class="component">
  <ng-container *ngIf="mode.subType === 'smallToggle'; else defaultAMOOView">
    <button
      (mousedown)="start()"
      (mouseup)="stopStarting()"
      (mouseout)="stopStarting()"
      [disabled]="this.mode.pumpRoom.isLocked"
      class="btn btn-transparent d-block w-100">
      <div class="row">
        <div class="col-auto white-space-nowrap">{{ mode.typeDescription }}</div>
        <div class="col"></div>
        <div class="col-auto">
          <ss-pump-room-detail-status [status]="mode.computedStatus"></ss-pump-room-detail-status>
        </div>
      </div>
    </button>
  </ng-container>

  <ng-template #defaultAMOOView>
    <div class="info-row">
      <svg class="icon">
          <use xlink:href='#icon-valve-2' />
      </svg>
      <p>{{ mode.typeDescription }}</p>
    </div>
    <div class="status-row" *ngIf="mode.startCommandVar || mode.stopCommandVar">
        <button
            *ngIf="mode.startCommandVar && mode.computedStatus === 'disabled'"
            (mousedown)="start()"
            (mouseup)="stopStarting()"
            (mouseout)="stopStarting()"
            [ngClass]="{
                'btn-default': !runStartTimer,
                'btn-primary': runStartTimer
            }"
            class="btn w-100 d-block">
            <svg class="icon">
                <use xlink:href='#icon-start' />
            </svg>
            <ng-container i18n="Uruchom|Ststus pracy w pompownii@@pumproomDetailStatusModeOfOperationActivate">Uruchom</ng-container>
        </button>
        <button
            *ngIf="mode.stopCommandVar && mode.computedStatus === 'enabled'"
            (mousedown)="stop()"
            (mouseup)="stopStopping()"
            (mouseout)="stopStopping()"
            [ngClass]="{
                'btn-default': !runStopTimer,
                'btn-primary': runStopTimer
            }"
            class="btn w-100 d-block">
            <svg class="icon">
              <use xlink:href='#icon-stop' />
            </svg>
            <ng-container i18n="Zatrzymaj|Ststus pracy w pompownii@@pumproomDetailStatusModeOfOperationDeactivate">Zatrzymaj</ng-container>
        </button>
    </div>
    <div class="status-row">
      <ss-pump-room-detail-status [status]="mode.computedStatus"></ss-pump-room-detail-status>
    </div>

  </ng-template>
</div>
