<form-checkbox
  [disabled]="!user.phone || disabled"
  [hideLabel]="true"
  [(ngModel)]="isSmsNotificationsEnabled"
  name="cfg-notifSched-cfg-isSmsNotificationsEnabled">
  <form-label i18n="Włącz powiadomienia SMS (Wymaga ustawienia nr telefonu w profilu uzytkownika)|Checkbox to enable SMS notifications@@userNotificationsScheduleEnableSmsNotifications">Włącz powiadomienia SMS (Wymaga ustawienia nr telefonu w profilu uzytkownika)</form-label>
</form-checkbox>

<ng-template>
  <form-checkbox
    [disabled]="disabled"
    [hideLabel]="true"
    [(ngModel)]="isAppNotificationsEnabled"
    name="cfg-notifSched-cfg-isAppNotificationsEnabled">
    <form-label i18n="Włacz powiadomienia w apliakcji mobilnej|Checkbox to enable app push notifications@@userNotificationsScheduleEnableAppNotifications">Włacz powiadomienia w apliakcji mobilnej</form-label>
  </form-checkbox>
</ng-template>

<ng-container *ngIf="isSmsNotificationsEnabled || isAppNotificationsEnabled">
  <h3 i18n="Rodzaje powiadomień|Tytuł przy wyborze rodzajów powiadomień@@userNotificationsScheduleNotifTypesTitle">Rodzaje powiadomień</h3>

  <table class="user-notification-schedule-table">
    <tr>
      <th></th>
      <th colspan="4" class="level" i18n="Poziom notyfikacji@@userNotificationsScheduleNotifTypesLevelTitle">Poziom notyfikacji</th>
    </tr>
    <tr>
      <th class="device" (click)="toggleLevel(0, undefined)" i18n="Armatki@@userNotificationsScheduleNotifTypesDeviceSnowgun">Armatki</th>
      <td class="level"
        (click)="toggleLevel(0, 0)"
        [ngClass]="{ 'active': notificationIndex[0][0] }" i18n="Alert@@userNotificationsScheduleNotifTypesLevelAlert">Alert</td>
      <td class="level"
        (click)="toggleLevel(0, 1)"
        [ngClass]="{ 'active': notificationIndex[0][1] }" i18n="Ostrzeżenie@@userNotificationsScheduleNotifTypesLevelWarning">Ostrzeżenie</td>
      <td class="level"
        (click)="toggleLevel(0, 2)"
        [ngClass]="{ 'active': notificationIndex[0][2] }" i18n="Notyfikacja@@userNotificationsScheduleNotifTypesLevelNotification">Notyfikacja</td>
      <td class="level"
        (click)="toggleLevel(0, 3)"
        [ngClass]="{ 'active': notificationIndex[0][3] }" i18n="Rozłączenia@@userNotificationsScheduleNotifTypesLevelDisconnection">Rozłączenia</td>
    </tr>
    <tr>
      <th class="device" (click)="toggleLevel(1, undefined)" i18n="Pompownie@@userNotificationsScheduleNotifTypesDevicePumprooms">Pompownie</th>
      <td class="level"
        (click)="toggleLevel(1, 0)"
        [ngClass]="{ 'active': notificationIndex[1][0] }" i18n="Alert@@userNotificationsScheduleNotifTypesLevelAlert">Alert</td>
      <td class="level"
        (click)="toggleLevel(1, 1)"
        [ngClass]="{ 'active': notificationIndex[1][1] }" i18n="Ostrzeżenie@@userNotificationsScheduleNotifTypesLevelWarning">Ostrzeżenie</td>
      <td class="level"
        (click)="toggleLevel(1, 2)"
        [ngClass]="{ 'active': notificationIndex[1][2] }" i18n="Notyfikacja@@userNotificationsScheduleNotifTypesLevelNotification">Notyfikacja</td>
      <td class="level"
        (click)="toggleLevel(1, 3)"
        [ngClass]="{ 'active': notificationIndex[1][3] }" i18n="Rozłączenia@@userNotificationsScheduleNotifTypesLevelDisconnection">Rozłączenia</td>
    </tr>
  </table>

  <h3 i18n="Harmonogram powiadomień|Tutuł przy wyborze dni do harmonogramu@@userNotificationsScheduleScheduleSelectorTitle">Harmonogram powiadomień</h3>

  <form-select name="cfg-notifSched-cfg-isScheduleAlwaysValid"
    [isExtraWide]="true"
    [disabled]="disabled"
    [(ngModel)]="isScheduleAlwaysValid"
    label="Wysyłka powiadomień"
    i18n-label="Wysyłka powiadomień|Selekt kiedy powiadomienia mają być wysyłlane@@userNotificationsScheduleIsScheduleAlwaysValid">
    <form-select-option [value]="true"
      i18n-label="Zawsze wysyłaj powiadomienia|Opcja w selekcie@@userNotificationsScheduleIsScheduleAlwaysValidTrue"
      label="Zawsze wysyłaj powiadomienia"></form-select-option>
    <form-select-option [value]="false"
      i18n-label="Wysyłaj tylko w wybranych godzinach|Opcja w selekcie@@userNotificationsScheduleIsScheduleAlwaysValidFalse"
      label="Wysyłaj tylko w wybranych godzinach"></form-select-option>
  </form-select>

  <table class="user-notification-schedule-table" *ngIf="!isScheduleAlwaysValid">
    <tr>
      <th></th>
      <th class="hour"
        (click)="toggleHour(undefined, hi)"
        *ngFor="let h of hourLabels; index as hi">{{ h }}</th>
    </tr>
    <tr *ngFor="let d of dayLabels; index as di">
      <th class="day" (click)="toggleHour(di, undefined)">{{ d }}</th>
      <td class="hour"
        (click)="toggleHour(di, hi)"
        [ngClass]="{ 'active': hourlyIndex[di][hi] }"
        *ngFor="let h of hourLabels; index as hi">{{ h }}</td>
    </tr>
  </table>
</ng-container>
