import { Injectable } from '@angular/core';
import { DocumentationFile } from 'src/app/shared/models';
import { EndpointMapService } from 'src/app/endpoint-map.service';
import { AuthService } from 'src/app/auth.service';
import { isArray } from '../../../../../../common';

@Injectable({
  providedIn: 'root'
})
export class DocumentationFilesService {

  constructor(
    private a: AuthService,
    private e: EndpointMapService
  ) { }

  private files: DocumentationFile[] = [];

  public initialize(data: DocumentationFile[]) {
    if (isArray(data)) {
      data.forEach(v => {
        v.fileUrl = this.e.documentationFiles + '/' + v.fileHash + '?bearer=' + this.a.getApiKey();
      });
    }
    this.files = data;
  }

  public findByContextString(ctx: string): DocumentationFile[] {
    return this.files.filter((e) => e.devices && e.devices.indexOf(ctx) >= 0);
  }
}
