<div class="map-hover-preview" [ngClass]="{
    'map-hover-preview--bottom-right': !direction || direction === 'bottom-right',
    'map-hover-preview--top-right': direction === 'top-right',
    'map-hover-preview--top-left': direction === 'top-left',
    'map-hover-preview--bottom-left': direction === 'bottom-left'
  }" *ngIf="connectionPoint">
    <div class="map-hover-preview__header d-flex align-items-center">
      <div class="px-2">
        <p class="fs-30">{{ connectionPoint.symbol }}</p>
      </div>
      <div class="map-hover-preview__image">
        <ss-device-image [device]="connectionPoint"></ss-device-image>
      </div>
      <div  class="map-hover-preview__model-n-mount">
        <p></p>
        <span>
          <ss-translated-device-mount-type [device]="connectionPoint"></ss-translated-device-mount-type>
        </span>
      </div>
    </div>
    <div class="p-1">
      <div class="row align-items-center">
        <div class="col color-white white-space-nowrap fs-12 px-1">
          <ss-translated-device-type-symbol [device]="connectionPoint.waterPipelineRef"></ss-translated-device-type-symbol>
        </div>
        <div class="col-auto white-space-nowrap text-right px-1">
          <strong class="color-light-grey fs-20">{{ connectionPoint|convertedValue:'computedWaterFlow' }}</strong>
          <span class="fs-14 color-light-grey"> {{ connectionPoint|convertedUnit:'computedWaterFlow' }}</span>
        </div>
      </div>
    </div>

    <div *ngIf="connectionPoint.getSnowCannons().length === 0" class="map-hover-preview__bordered p-2"><!-- d-flex align-items-center -->
        <p class="fs-12 color-light-grey text-center">Do hydrantu nie jest podpięta żadna armatka</p>
    </div>
    <div *ngFor="let snowCannon of connectionPoint.getSnowCannons()"
        [routerLink]="['/application/resort/area-map', connectionPoint.resortAreaRef.id, 'snow-cannon', snowCannon.id]"
        class="map-hover-preview__bordered d-flex align-items-center clickable">
        <div class="px-3">
            <p class="fs-30">{{ snowCannon.symbol }}</p>
        </div>
        <div class="map-hover-preview__image">
          <ss-device-image [device]="snowCannon"></ss-device-image>
          <div class="steering-is-local-overlay" *ngIf="snowCannon.connectionStatus && snowCannon.isInLocalMode">
              <svg>
              <use xlink:]href='#icon-lock'/>
              </svg>
          </div>
        </div>
        <div class="map-hover-preview__model-n-mount">
            <p>{{ snowCannon.cannonModel }}</p>
            <span>
              <ss-translated-device-mount-type [device]="snowCannon"></ss-translated-device-mount-type>
            </span>
        </div>
    </div>
  </div>
