<ss-page>
  <ss-page-header>
    <p *ngIf="type === 'add'" class="fs-26 my-2 color-white"
      i18n="Dodaj schemat|Tytuł na podstronie dodawania schematów@@weatherAndSchedulesAddNewTitle">Dodaj schemat</p>
    <p *ngIf="type === 'edit'" class="fs-26 my-2 color-white"
      i18n="Edytuj schemat|Tytuł na podstronie edycji schematu@@weatherAndSchedulesAddNewEditTitle">Edytuj schemat</p>
    <ss-page-button-group>
      <button [routerLink]="['../']" class="btn btn-default"
        i18n="Anuluj|Przycisk Anuluj na podstronie dodawania schematów@@weatherAndSchedulesAddNewCancel">Anuluj</button>
      <button (click)="save()" [disabled]="!schedule.schema.name || schedule.getScheduleDays().length === 0"
        class="btn btn-primary"
        i18n="Zapisz schemat|Przycisk Zapisz schemat na podstronie dodawania schematów@@weatherAndSchedulesAddNewSave">Zapisz
        schemat</button>
    </ss-page-button-group>
  </ss-page-header>


  <ss-page-toolbar>
    <p>
      <ng-container
        i18n="Nazwa schematu|Tytuł inputu nazwa schematu na podstronie dodawania schematów@@weatherAndSchedulesAddNewSchemaName">
        Nazwa schematu</ng-container>:
    </p>

    <div class="input-holder">
      <input class="input" [(ngModel)]="schedule.schema.name" type="text"
        name="schedule-name" id="schedule-name" placeholder="Wpisz nazwę schematu"
        i18n-placeholder="Wpisz nazwę schematu|Placeholder inputu nazwa schematu na podstronie dodawania schematów@@weatherAndSchedulesAddNewSchemaNamePlaceholder">
    </div>

    <p class="fs-12 mr-1">
      <ng-container
        i18n="Temperatura startu TWB|Tytuł inputu Temperatura startu TWB na podstronie dodawania schematów@@weatherAndSchedulesAddNewStartTemperature">
        Temperatura startu TWB</ng-container>:
    </p>

    <span class="mr-3">
      <ss-number-input [min]="-15" [max]="5" [step]="0.1" [inputValue]="schedule.schema.startTemperature"></ss-number-input>
    </span>

    <svg class="add-schedule__icon">
      <use xlink:href='#icon-termo-wet' />
    </svg>
    <span class="fs-30 font-bold color-white pl-1">
      &deg;C
    </span>
  </ss-page-toolbar>



  <ss-page-panel>
    <div class="snowing-schedule" (window:mouseup)="draggingEnd(); movingEnd();"
      (window:mousemove)="changeRange($event)">
      <p class="snowing-schedule__header text-uppercase color-mid-grey">
        <ng-container
          i18n="Śnieżenie|Etykieta Śnieżenie nad edytorem godzin na podstronie dodawania schematów@@weatherAndSchedulesAddNewSnowingLabel">
          Śnieżenie</ng-container>
      </p>
      <div class="snowing-schedule__days">
        <div *ngFor="let dayNo of [0, 1, 2, 3, 4, 5, 6]" class="snowing-schedule__day">{{ dateRangeLabels[dayNo] }}
        </div>
      </div>
      <div #scrollableWrapper (scroll)="scrolling()" class="snowing-schedule__grid-wrapper">
        <div #container class="snowing-schedule__grid">
          <div *ngFor="let hour of hours; let i = index; let last = last;" class="snowing-schedule__hour" [ngClass]="{
            'snowing-schedule__hour--first': i === 0,
            'snowing-schedule__hour--third': i !== 0 && i%3 === 0 && i%6 !== 0,
            'snowing-schedule__hour--sixth': i !== 0 && i%6 === 0 && !last,
            'snowing-schedule__hour--last': last
          }">
            <span class="snowing-schedule__hour-label">{{hour}}</span>
          </div>
          <div class="snowing-schedule__schemes">
            <div *ngFor="let dayNo of [1, 2, 3, 4, 5, 6, 7]" #grid (mouseover)="mouseOverGrid(true, addBtn)"
              (mouseout)="mouseOverGrid(false, addBtn)" (mousemove)="stickAddBtn($event, addBtn)"
              class="snowing-schedule__row">


              <div *ngFor="let scheduleDay of schedule.getDay(dayNo)" #timeRangeBar
                (click)="editTimeRange($event, scheduleDay)"
                (mousedown)="movingStart($event, timeRangeBar, scheduleDay, dayNo)"
                (mouseover)="mouseOverTimeRange($event)"
                class="snowing-schedule__range-bar d-flex align-items-center justify-content-center"
                [ngClass]="{'is-moving': scheduleDay.isMoving}" [ngStyle]="{
                'left': scheduleDay.start / 15 * timeUnitWidth + 'px',
                'width': (scheduleDay.end / 15 - scheduleDay.start / 15) * timeUnitWidth + 'px'
              }">
                <button (mousedown)="draggingStart(timeRangeBar, scheduleDay, dayNo); setStartTime()"
                  (click)="$event.stopPropagation()" class="snowing-schedule__btn snowing-schedule__btn--first">
                  <svg>
                    <use xlink:href='#icon-move-range' />
                  </svg>
                </button>
                <span class="snowing-schedule__range-hours"
                  [ngClass]="{'snowing-schedule__range-hours--small': (scheduleDay.end / 15 - scheduleDay.start / 15) < 10}">

                  {{ '' + scheduleDay }}
                </span>
                <button (mousedown)="draggingStart(timeRangeBar, scheduleDay, dayNo); setEndTime()"
                  (click)="$event.stopPropagation()" class="snowing-schedule__btn snowing-schedule__btn--last">
                  <svg>
                    <use xlink:href='#icon-move-range' />
                  </svg>
                </button>
              </div>
              <button #addBtn (mouseover)="mouseOverButton(addBtn)" (click)="addTimeRange(dayNo, addBtn)"
                class="snowing-schedule__btn snowing-schedule__btn--add"
                [ngClass]="{'snowing-schedule__btn--alert': isFreeSpace === false}">
                <svg>
                  <use xlink:href='#icon-plus' />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ski-routes d-flex py-4">
      <div class="ski-routes__col">
        <p class="text-uppercase color-mid-grey">
          <ng-container
            i18n="trasy|Etykieta trasy na podstronie dodawania schematów@@weatherAndSchedulesAddNewResortAreas">trasy
          </ng-container>
        </p>
        <p class="color-white fs-26 pt-3">
          {{ schedule.areaIds.length }}
          &nbsp;
          <ng-container
            i18n="tras|Licznik tras na podstronie dodawania schematów@@weatherAndSchedulesAddNewResortAreasCounter">
            { schedule.areaIds.length, plural,
            =0 { tras }
            =1 { trasa }
            =2 { trasy }
            =3 { trasy }
            =4 { trasy }
            other { tras }
            }
          </ng-container>
        </p>
      </div>
      <div class="ski-routes__col">
        <div class="ski-routes__wrapper">
          <ng-container *ngFor="let area of resortAreas">
            <input class="route-checkbox__input" type="checkbox" (change)="toggleArea(area.id)"
              name="route{{ area.id }}" id="route{{ area.id }}">
            <label class="route-checkbox__label" [ngClass]="{'route-checkbox__label--checked': isAreaChecked(area.id) }"
              for="route{{area.id }}">{{ area.symbol }}</label>
          </ng-container>
        </div>
      </div>
    </div>
  </ss-page-panel>

  <ss-page-footer>
    <div>
      <button *ngIf="type === 'edit'" (click)="remove()" class="btn btn-danger mr-4"
        i18n="Usuń schemat|Przycisk Usuń schemat na podstronie dodawania schematów@@weatherAndSchedulesAddNewDelete">Usuń
        schemat</button>
    </div>

    <ss-page-button-group>
      <button [routerLink]="['../']" class="btn btn-default"
        i18n="Anuluj|Przycisk Anuluj na podstronie dodawania schematów@@weatherAndSchedulesAddNewCancel">Anuluj</button>
      <button (click)="save()" [disabled]="!schedule.schema.name || schedule.getScheduleDays().length === 0"
        class="btn btn-primary"
        i18n="Zapisz schemat|Przycisk Zapisz schemat na podstronie dodawania schematów@@weatherAndSchedulesAddNewSave">Zapisz
        schemat</button>
    </ss-page-button-group>
  </ss-page-footer>
</ss-page>

<ss-time-range-popup [data]="currentPopupRequest" [canDelete]="true"></ss-time-range-popup>
