export class RecorderWeather {
    temperature: number[];
}

export class PerformedSnowing {
    snowProductionVolume: number[];
}

export class SingleForecast {
    temperature: number[];
    rainfall: number[];
    snowfall: number[];
    sleetfall: number[];
    weatherSymbol: number[];
}

export class Forecast {
    from: number;
    to: number;
    providers: { providerId: string; name: string}[] = [];
    labels: number[] = [];

    forecast: { [key: string]: SingleForecast} = {};
    recorderWeather: { [key: string]: RecorderWeather} = {};
    performedSnowing: { [key: string]: PerformedSnowing} = {};

    static create(data): Forecast {
        return Object.assign(new Forecast(), data);

    }
}
