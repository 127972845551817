import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DragulaService } from 'ng2-dragula';
import { ModalRouteService } from 'src/app/application/modal-route.service';
import { ResortService } from 'src/app/resort.service';
import { ResortArea, WorkingArea, SnowCannon, ConnectionPoint } from 'src/app/shared/models';
import { AreaConfigurationResolverData, HydrantToCannonRef, WorkingAreaToHydrantRef } from './area-configuration.model';


@Component({
  selector: 'ss-area-configuration',
  templateUrl: './area-configuration.component.html',
  styleUrls: ['./area-configuration.component.sass']
})
export class AreaConfigurationComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private rs: ResortService,
    private hes: ModalRouteService,
    private dragulaService: DragulaService
  ) {
    dragulaService.createGroup(this.DG_HYDRANTS, {
      revertOnSpill: true,
      moves: (el, container, handle) => handle.className === 'col-auto handler hydrant-handle'
    });
    dragulaService.createGroup(this.DG_CANNONS, {
      revertOnSpill: true,
      moves: (el, container, handle) => handle.className === 'col-auto handler cannon-handle'
    });
    dragulaService.createGroup(this.DG_GROUPS, {
      revertOnSpill: true,
      moves: (el, container, handle) => handle.className === 'col-auto handler working-area-handle'
    });
  }

  public readonly DG_GROUPS: string = 'DG_GROUPS';
  public readonly DG_HYDRANTS: string = 'DG_HYDRANTS';
  public readonly DG_CANNONS: string = 'DG_CANNONS';

  public displayViews: string[] = [];

  public hydrantSearchString = '';

  public resortArea: ResortArea;
  public notAssignedSnowCannons: SnowCannon[];

  public assignedHydrants: WorkingAreaToHydrantRef[];
  public notAssignedHydrants: HydrantToCannonRef[];

  public workingAreasToAdd: WorkingArea[] = [];
  public workingAreasToDelete: WorkingArea[] = [];

  ngOnInit(): void {
    this.route.data
      .subscribe((data: { cfg: AreaConfigurationResolverData }) => {
        Object.assign(this, data.cfg);
        if (this.assignedHydrants.length > 0) {
          if (this.notAssignedSnowCannons.length > 0) {
            this.displayViews = ['groups', 'hydrants'];
          } else {
            this.displayViews = ['groups', 'hydrants']; // , 'map'];
          }
        } else if (this.notAssignedSnowCannons.length > 0) {
          this.displayViews = ['hydrants', 'cannons'];
        } else {
          this.displayViews = ['hydrants', 'cannons']; // , 'map'];
        }
      });
  }

  public hasNoVisible(hcr: HydrantToCannonRef[]): boolean {
    return hcr.length > 0 && hcr.filter(h => h.isVisible).length === 0;
  }

  public isViewSelected(v: string): boolean {
    return this.displayViews.includes(v);
  }

  public performDebouncedHydrantSearch() {
    this.performHydrantSearch();
  }

  private performHydrantSearch() {
    this.getAllHydrants()
    .forEach(h => {
      h.isVisible = `${h.hydrant.symbol}`.startsWith(`${this.hydrantSearchString}`);
    });
  }

  public selectView(v: string) {
    if (!this.isViewSelected(v)) {
      this.displayViews = [v, ...this.displayViews].splice(0, 2);
    }
  }

  private getAllHydrants(): HydrantToCannonRef[] {
    return [...this.notAssignedHydrants].concat(...
      this.assignedHydrants.map(hass => hass.hydrants)
    );
  }

  public addWorkingArea() {
    const wa: WorkingArea = new WorkingArea();
    wa.symbol = 'unnamed';
    wa.priority = 0;
    wa.displayOrder = 0;
    wa.limitSnowProductionVolume = 0;
    wa.limitWaterConsumption = 0;
    wa.limitPowerConsumption = 0;
    wa.limitWindSpeed = 0;
    wa.resortArea = this.resortArea.id;
    wa.resortAreaRef = this.resortArea;

    this.workingAreasToAdd.push(wa);
    this.assignedHydrants.unshift({
      workingArea: wa,
      hydrants: []
    });
    this.selectView('groups');
  }

  public removeWorkingArea(wr: WorkingArea) {
    if (this.workingAreasToAdd.includes(wr)) {
      this.workingAreasToAdd.splice(this.workingAreasToAdd.indexOf(wr), 1);
    } else {
      this.workingAreasToDelete.push(wr);
    }

    const idx = this.assignedHydrants.findIndex(a => a.workingArea === wr);
    if (idx >= 0) {
      this.notAssignedHydrants.unshift( ... this.assignedHydrants[idx].hydrants);
      this.assignedHydrants.splice(idx, 1);
    }
  }

  save() {
    // updating workingareaId and connectionPointId happens only as result of 'saveOperation',
    // after save show dialog thats everything fine and redirect to map
  }

  public goToResortAreaConfig(id: string) {
    this.hes.showModalRoute('resort-area-config-modal/' + id);
  }

  public goToWorkingAreaConfig(id: string) {
    this.hes.showModalRoute('working-area-config-modal/' + id);
  }


}
