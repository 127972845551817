<perfect-scrollbar>
  <div class="mx-2 my-4">
    <div class="main-info">
      <div class="main-info__col p-3">
        <input [(ngModel)]="areasFilter" (keyup)="moveToSearchResults()" type="text" i18n-placeholder="Szukaj trasy|Placeholder w wyszukiwarce tras@@areasMapSearch"  placeholder="Szukaj trasy" class="input main-info__search my-6">
      </div>
      <div class="main-info__col p-3 d-flex">
        <div class="pr-6 flex-grow-1" style="min-width: 300px">
          <p class="fs-12 text-uppercase color-light-grey" i18n="Produkcja śniegu|Nazwa pola przedstawiającego wielkość produkcji śniegu@@snowProduction">Produkcja śniegu</p>
          <p class="mt-3 white-space-nowrap">
            <span class="fs-42 color-green font-bold mb-1">{{ resort|convertedValue:'snowProductionVolume' }}</span>
            <span class="fs-20 color-light-grey"> {{ resort|convertedUnit:'snowProductionVolume' }}</span>
          </p>
        </div>
        <div style="width: 600px">
          <ss-d3-snow-production-and-twb-chart [device]="resort" (durationChanged)="setDuration($event)"></ss-d3-snow-production-and-twb-chart>
        </div>
      </div>
      <div class="main-info__col p-3">
        <p class="fs-12 text-uppercase color-light-grey" i18n="Zużycie wody|Nazwa pola przedstawiajacego wielkość zużycia wody@@waterConsumption">Zużycie wody</p>
        <div class="d-flex align-items-center my-4">
          <svg class="main-info__icon">
            <use xlink:href='#icon-waves' />
          </svg>
          <span class="fs-20 color-white pl-1">{{ resort|convertedValue:'computedWaterFlow' }}</span>
          <span class="fs-14 color-light-grey pl-1">{{ resort|convertedUnit:'computedWaterFlow' }}</span>
        </div>
      </div>
      <div class="main-info__col p-3">
        <p class="fs-12 text-uppercase color-light-grey" i18n="Zużycie energii|Nazwa pola przedstawiającego wielkość zużycia energii@@energyConsumption">Zużycie energii</p>
        <div class="d-flex align-items-center my-4">
          <svg class="main-info__icon main-info__icon--s">
            <use xlink:href='#icon-thunder' />
          </svg>
          <span class="fs-20 color-white pl-1">{{ (resort.computedPowerDraw || 0)|number:'1.0-0' }}</span>
          <span class="fs-14 color-light-grey pl-1">kW</span>
        </div>
      </div>
      <div class="main-info__col p-3">
        <p class="fs-12 text-uppercase color-light-grey" i18n="Zbiorniki wodne|Nazwa pola przedstawiającego poziom wód w zbiornikach wodnych@@waterReservoirs">Zbiorniki wody</p>
        <div>
          <ss-water-tank *ngFor="let waterReservoir of waterReservoirs"
            [waterReservoir]="waterReservoir"
            size="extra-small" minLevel="0" ></ss-water-tank>
        </div>
      </div>
    </div>
    <table class="table">
      <thead>
        <tr class="table__group-actions">
<!--
          <td class="table__cell px-4">
            <ss-checkbox inputType="checkbox" inputId="" inputValue="" inputName=""></ss-checkbox>
          </td>
-->
          <td class="table__cell" colspan="12">
<!--
            <ng-select class="custom-select custom-select--simple custom-select--filled fs-14 mt-1 ml-2"
              [clearable]="false"
              [searchable]="false"
              placeholder="Operacje masowe">
              <ng-template ng-label-tmp>
                Operacje masowe
              </ng-template>
              <ng-option>operacja X</ng-option>
              <ng-option>operacja Y</ng-option>
            </ng-select>
-->
          </td>
        </tr>
        <tr class="table__col-headings">
<!--
          <th>
            <div class="table__wrapper"></div>
          </th>
-->
          <th>
            <div class="table__wrapper px-2" i18n="nr|Nagłówek tabeli - nazwa kolumny z numerami grup@@areasListTableHeadingNumber">nr</div>
          </th>
          <th>
            <div class="table__wrapper px-2" i18n="stan śnieżenia|Nagłówek tabeli - nazwa kolumny ze stanem śnieżenia@@areasListTableHeadingSnowingState">stan śnieżenia</div>
          </th>
          <th colspan="2">
            <div class="table__wrapper px-2"><ng-container i18n="produkcja śniegu|Nagłówek tabeli - nazwa kolumny z wykresem produkcji śniegu@@areasListTableHeadingSnowProduction">produkcja śniegu</ng-container> m<sup>3</sup>/h</div>
          </th>
          <th colspan="2">
            <div class="table__wrapper px-2"i18n="stacja meteo|Nagłówek tabeli - nazwa kolumny z danymi ze stacji meteo@@areasListTableHeadingMeteo">stacja meteo</div>
          </th>
          <th>
            <div class="table__wrapper px-2" i18n="jakość śniegu|Nagłówek tabeli - nazwa kolumny ze skalą jakości śniegu@@areasListTableHeadingSnowQuality">jakość śniegu</div>
          </th>
          <th>
            <div class="table__wrapper"  i18n="stokiem steruje|Nagłówek tabeli - nazwa kolumny z trybem pracy@@areasListTableHeadingOperationMode">stokiem steruje</div>
          </th>
          <th>
            <div class="table__wrapper px-2 text-center">
              <ng-container i18n="zakres|Nagłówek tabeli - nazwa kolumny z zakresem godzin@@areasListTableHeadingTimeRange">zakres</ng-container>
              &nbsp;
              <ng-container i18n="TWB|Nagłówek tabeli - nazwa kolumny z wartością wskaźnika TWB@@areasListTableHeadingTWB">twb</ng-container>
            </div>
          </th>
          <th>
            <div class="table__wrapper"></div>
          </th>
          <th>
            <div class="table__wrapper"></div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="table__row" *ngFor="let area of filteredAreas(); let first = first; let last = last" [ngClass]="{
              'table__row--first': first,
              'table__row--last': last,
              'table__row--alert': area.computedStatus === 'breakdown' || area.computedStatus  === 'connection-lost' }"
          (mouseenter)="addShadows($event)" (mouseleave)="removeShadows($event)">
          <!-- checkbox -->
<!--
          <td class="table__cell table__cell--bordered-right text-center px-4">
            <div class="table__wrapper">
              <ss-checkbox inputType="checkbox" inputId="{{ area.id }}" inputValue="{{ area.id }}" inputName="area"
                [color]="area.computedStatus == 'breakdown' || area.computedStatus  == 'connection-lost' ? 'danger' : ''"></ss-checkbox>
            </div>
          </td>
-->
          <!-- nr -->
          <td class="table__cell pl-3 pr-6">
            <div class="table__wrapper" [ngClass]="{'fs-22': area.symbol.length < 4 }">
              {{ area.symbol }}
            </div>
          </td>
          <!-- stan śnieżenia -->
          <td class="table__cell pl-2">
            <div class="table__wrapper d-flex align-items-center">
              <span class="mr-2 fs-20">{{ area.snowCannonRefs.size }}:</span>
              <div class="devices-states devices-states--small mr-2">
                <div class="devices-states__state devices-states__state--main" title="Pracuje" i18n-title="Pracuje|Nazwa stanu urządzenia@@stateWorking">
                  <!-- devices-states__state--main -->
                  {{ area.countByWorking() }}
                  <ng-container *ngIf="area.countByWorkingInManualMode() > 0">
                    ({{ area.countByWorkingInManualMode() }})
                  </ng-container>
                </div>
                <div class="devices-states__state devices-states__state--main" title="Czeka na warunki" i18n-title="Czeka na warunki|Nazwa stanu urządzenia@@stateAwaitingForConditions">
                  <!-- devices-states__state--main -->
                  {{ area.countByAwaitingForConditions() }}
                </div>
                <div class="devices-states__state devices-states__state--main" title="Gotowość" i18n-title="Gotowość|Nazwa stanu urządzenia@@stateReadyToWork">
                  <!-- devices-states__state--main -->
                  {{ area.countByReadyToWork() }}
                </div>
                <div class="devices-states__state devices-states__state--main" title="Czuwanie" i18n-title="Czuwanie|Nazwa stanu urządzenia@@stateStandingBy">
                  <!-- devices-states__state--main -->
                  {{ area.countByStandingBy() }}
                </div>
                <div class="devices-states__state" title="Uruchamianie" i18n-title="Uruchamianie|Nazwa stanu urządzenia@@stateStarting">
                  {{ area.countByStarting() }}
                </div>
                <div class="devices-states__state" title="Usypianie" i18n-title="Usypianie|Nazwa stanu urządzenia@@stateSnoozing">
                  {{ area.countBySnoozing() }}
                </div>
                <div class="devices-states__state" title="Zatrzymywanie" i18n-title="Zatrzymywanie|Nazwa stanu urządzenia@@stateStopping">
                  {{ area.countByStopping() }}
                </div>
                <div class="devices-states__state" title="Przygotowanie" i18n-title="Przygotowanie|Nazwa stanu urządzenia@@statePreparing">
                  {{ area.countByPreparing() }}
                </div>
              </div>
              <ss-badge *ngIf="area.countByBreakdown() > 0" color="danger" size="small">
                {{ area.countByBreakdown() }}</ss-badge>
            </div>
          </td>
          <!-- produkcja śniegu -->
          <td *ngIf="area.connectionStatus === 1;else broken_device_cell" class="table__cell pl-3 pr-2" [ngClass]="{
                'color-green': area.computedStatus !== 'breakdown' && area.computedStatus  !== 'connection-lost',
                'color-light-orange': area.computedStatus === 'breakdown' || area.computedStatus  === 'connection-lost'}">
            <div class="table__wrapper white-space-nowrap">
              <span class="font-bold fs-20">{{ area|convertedValue:'snowProductionVolume' }}</span>
              <span class="color-mid-grey">{{ area|convertedUnit:'snowProductionVolume' }}</span>
            </div>
          </td>
          <!-- produkcja śniegu - wykres -->
          <td *ngIf="area.connectionStatus === 1;else broken_device_cell" class="table__cell pl-2 pr-3">
            <div class="table__wrapper">
              <div style="width: 140px">
                <ss-d3-linked-mini-bar-chart [viewBoxWidth]="140" tag="compare-areas" [duration]="duration" [device]="area"></ss-d3-linked-mini-bar-chart>
              </div>
            </div>
          </td>
          <!-- stacja meteo - nazwa -->
          <td class="table__cell pl-2">
            <div class="table__wrapper">
              <ss-translated-device-type-symbol
                *ngIf="area.remoteMeteoStationRef"
                [onlySymbol]="area.remoteMeteoStationRef.systemType === 'MeteoStation'"
                [device]="area.remoteMeteoStationRef"></ss-translated-device-type-symbol>
            </div>
          </td>
          <!-- stacja meteo - meteo-bar -->
          <td *ngIf="area.connectionStatus === 1; else broken_device_cell" class="table__cell px-3">
            <div class="table__wrapper">
              <ss-meteo-conditions-display [sourceId]="area.remoteMeteoStation"></ss-meteo-conditions-display>
            </div>
          </td>
          <!-- jakość śniegu -->
          <td *ngIf="area.connectionStatus === 1;else broken_device_cell" class="table__cell px-2">
            <div class="table__wrapper">
              <ss-snow-quality [isDisabled]="false" [snowQualityType]="area.snowQualityType" [currentSnowQuality]="area.snowQualityConstant"
                [snowQualityMax]="area.snowQualityHigh" [snowQualityMin]="area.snowQualityLow" size="small">
              </ss-snow-quality>
            </div>
          </td>
          <!-- tryb pracy -->
          <td *ngIf="area.connectionStatus === 1; else broken_device_cell" class="table__cell" [ngClass]="{'table__cell--green': area.operationMode, 'table__cell--orange': !area.operationMode}">
            <div class="table__wrapper">
              <div class="mode-dropdown" [ngClass]="{'mode-dropdown--manual': !area.operationMode}">
                <button class="mode-dropdown__btn" [ngClass]="{'mode-dropdown__btn--active': area.isModeDropdownExpanded}" (click)="area.isModeDropdownExpanded = !area.isModeDropdownExpanded">
                  <span *ngIf="area.operationMode" class="mode-dropdown__header" i18n="Snowmatic|Tryb pracy Snowmatic@@operationModeSnowmatic">Snowmatic</span>
                  <span *ngIf="!area.operationMode" class="mode-dropdown__header" i18n="Operator|Tryb pracy Operator@@operationModeOperator">Operator</span>
                  <svg class="mode-dropdown__angle" [ngClass]="{'mode-dropdown__angle--rotated': area.isModeDropdownExpanded}">
                    <use xlink:href='#icon-chevron-down' />
                  </svg>
                </button>
                <div class="mode-dropdown__menu" [ngClass]="{'mode-dropdown__menu--expanded': area.isModeDropdownExpanded, 'mode-dropdown__menu--auto': !area.operationMode}">
                  <button class="mode-dropdown__btn" (click)=" area.isModeDropdownExpanded = !area.isModeDropdownExpanded">
                    <span *ngIf="!area.operationMode" (click)="area.isModeDropdownExpanded = false" class="mode-dropdown__header" i18n="Snowmatic|Tryb pracy Snowmatic@@operationModeSnowmatic">Snowmatic</span>
                    <span *ngIf="area.operationMode" (click)="area.isModeDropdownExpanded = false" class="mode-dropdown__header" i18n="Operator|Tryb pracy Operator@@operationModeOperator">Operator</span>
                  </button>
                </div>
              </div>
            </div>
          </td>
          <!-- zakres i TWB -->
          <td colspan="2" class="table__cell fs-14" *ngIf="area.connectionStatus === 1;else broken_device_cell"
            [ngClass]="{
                'table__cell--dark-green': area.operationMode,
                'table__cell--orange': !area.operationMode }">
            <div class="table__wrapper">
              <div *ngIf="area.operationMode" class="d-flex align-items-center justify-content-between">
                <span class="pl-4 pr-2 white-space-nowrap">
                  <!-- {{ closestScheduleHours }} -->
                  22:00-09:00
                </span>
                <span class="px-2 white-space-nowrap">
                  <!--

                    {{ area.startTemperature|number:'1.1-1' }}&#176;C
                  -->
                </span>
              </div>
              <div *ngIf="!area.operationMode" class="d-flex justify-content-between align-items-center">
                <!--
                  <ss-number-input size="small" [min]="-15" [max]="2" [step]="0.1" [inputValue]="area.startTemperature"></ss-number-input>
                -->
                <ss-control-button *ngIf="area.isAnyCannonStarted()" action="stop" size="extra-small" class="px-2"></ss-control-button>
                <ss-control-button *ngIf="!area.isAnyCannonStarted()" action="start" size="extra-small" class="px-2"></ss-control-button>
              </div>
            </div>
          </td>
          <!-- szczegóły -->
          <td class="table__cell table__cell--light table__cell--shadowed fs-14 pl-2" *ngIf="area.connectionStatus === 1;else broken_device_cell">
            <div class="table__wrapper d-flex align-items-center justify-content-end">
              <a title="Szczegóły"
                i18n-title="Szczegóły|Nazwa przycisku przekierowującego na stronę danej trasy@@areasListDetails"
                [routerLink]="['/application/resort/area', area.id, 'list']"
                class="d-flex align-items-center justify-content-end">
                <svg class="table__details-icon">
                  <use xlink:href='#icon-chevron-right' />
                </svg>
              </a>
            </div>
          </td>
          <td *ngIf="area.connectionStatus === 0" class="table__cell">
            <div class="table__wrapper"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ng-template #broken_device_cell>
    <td class="table__cell">
      <div class="table__wrapper"></div>
    </td>
  </ng-template>
</perfect-scrollbar>
