import { Component, OnInit, Input } from '@angular/core';
import { Pump } from 'src/app/shared/models/pump-room';

@Component({
  selector: 'ss-pump-tile',
  templateUrl: './pump-tile.component.html',
  styleUrls: []
})
export class PumpTileComponent implements OnInit {

  constructor() { }

  @Input()
  public pump: Pump;

  @Input()
  public selectedPipelineIds: string[] = [];


  ngOnInit() {
  }

}
