import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, Subject, EMPTY } from 'rxjs';
import { ResortArea, PumpRoom, Resort, WaterReservoir } from '../../../shared/models';
import { ResortService } from '../../../resort.service';
import { DevicesClientService } from '../../../http-clients';

export class AreasData {
  resort: Resort;
  areas: ResortArea[];
  pumpRooms: PumpRoom[];
  waterReservoirs: WaterReservoir[];
  devIds: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AreasDataResolverService implements Resolve<AreasData> {
  constructor(
    private router: Router,
    private rs: ResortService,
    private dd: DevicesClientService) {}

  getData(allowRedirect: boolean): AreasData | Observable<AreasData> | Observable<never> {
    const sub: Subject<AreasData> = new Subject();
    const areas = this.rs.getResortAreas();
    const pumpRooms = this.rs.getPumpRooms();
    const waterReservoirs = this.rs.getWaterReservoirs();
    const resort = this.rs.getResort();

    if (areas) {
      if (allowRedirect && areas.length === 1) {
        this.router.navigate(['/application/resort/area', areas[0].id, 'map']);
        return EMPTY;
      }
      const devIds: string[] = [
        ... areas.map(a => a.id),
        ... areas.filter(a => a.remoteMeteoStation).map(a => a.remoteMeteoStation),
        ... pumpRooms.map(p => p.id)
      ];

      setTimeout(() => {
        sub.next({
          waterReservoirs,
          resort,
          areas,
          pumpRooms,
          devIds
        });
        sub.complete();
      }, 10);
    } else {
      sub.error('can not fetch area');
    }

    return sub.asObservable();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): AreasData | Observable<AreasData> | Observable<never> {
    return this.getData(route.outlet === 'primary');
  }
}

