import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { ResortService } from './resort.service';
import { Observable } from 'rxjs';

@Injectable()
export class ApplicationGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private resortService: ResortService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    if (!this.authService.isAuthenticated()) {
      this.authService.lastLoginUrl = this.router.getCurrentNavigation().finalUrl.toString();

      return this.router.createUrlTree(['/']);
    }

    if (!this.resortService.isReady()) {
      return this.resortService.onReady;
    }

    return true;
  }
}
