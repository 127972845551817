import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndpointMapService } from '../endpoint-map.service';
import { dayjs, DAYJS } from '../../../../common';

@Injectable({
  providedIn: 'root'
})
export class SnowThicknessClientService {

  constructor(private e: EndpointMapService, private http: HttpClient) {}

  public get(): Observable<any> {
    const dm = dayjs().subtract(dayjs().hour() > 6 ? 1 : 2, 'days').tz().format('YYYY-MM-DD');
    return this.http.get(this.e.snowThickness, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: {
        date_measured: dm
      }
    });
  }

  public getOne(providerId: string, date: DAYJS): Observable<any> {
    const dm = date.tz().format('YYYY-MM-DD');
    return this.http.get(this.e.snowThickness + '/' + providerId + '/' + dm, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }
}
