<ss-page>
    <ss-page-header>
        <ss-page-header-title>Ślad serwisowy</ss-page-header-title>
    </ss-page-header>
    <ss-page-toolbar>
      <p *ngIf="atFrom && atTo">{{ atFrom|date:'yyyy-MM-dd HH:mm:ss' }} - {{ atTo|date:'yyyy-MM-dd HH:mm:ss' }}</p>
    </ss-page-toolbar>
    <ss-page-panel>
      <table class="table my-3">
        <thead>
          <tr>
            <td colspan="2" i18n="Data|Data na panelu Historii akcji@@userProfileLatestActionsTableDate">Data</td>
            <td i18n="Login|Login na panelu Historii akcji@@userProfileLatestActionsTableLogin">Login</td>
            <td colspan="3" i18n="Akcja|Akcja na panelu Historii akcji@@userProfileLatestActionsTableAction">Akcja</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let at of atData ; trackBy: ident">
            <td>{{ (1000 * at.created)|date:'yyyy-MM-dd' }}</td>
            <td>{{ (1000 * at.created)|date:'HH:mm:ss' }}</td>
            <td>{{ at.user_login }}</td>
            <td>{{ at.action_type }}</td>
            <td>{{ at.object_symbol }}</td>
            <td>{{ at.action_description }}</td>
            <td>
              <ng-container *ngIf="at.values_after">
                <p *ngFor="let item of at.values_after | keyvalue">
                  {{ item.key }}: {{ item.value }}
                </p>

              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </ss-page-panel>
</ss-page>
