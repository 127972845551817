import { Component, OnInit } from '@angular/core';
import { FormTextComponent } from './form-text.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

let identifier = 0;

@Component({
  selector: 'form-password',
  templateUrl: './form-text.component.html',
  styleUrls: [],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FormPasswordComponent,
    multi: true,
  }],
})
export class FormPasswordComponent extends FormTextComponent {
  public type = 'password';
  public identifier = `form-password-${identifier++}-` + Math.random();
}
