import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EndpointMapService } from '../endpoint-map.service';
import { User, UserNotificationsSchedule, UserStats } from '../shared/models';
import { map, flatMap, tap, catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UsersClientService {

  constructor(private e: EndpointMapService, private http: HttpClient) {}

  public getStats(id, from: number, to: number): Observable<UserStats> {

    const options = { ... httpOptions, params: new HttpParams() };
    if (from) {
      options.params = options.params.set('from', from);
    }
    if (to) {
      options.params = options.params.set('to', to);
    }
    return this.http.get<UserStats>(this.e.users + '/stats/' + id, options)
      .pipe(
        catchError(e => {
          console.error('can not fetch user stats data', e);
          return of({
            from,
            to,
            auditTrailLogs: [],
            connected: []
          });
        }),
        map(data => Object.assign(new UserStats(), data))
      );
  }

  public getAll(): Observable<User[]> {
    return this.http.get<User[]>(this.e.users, httpOptions)
        .pipe(map((data) => data['users'] ? data['users'].map(u =>
            User.create(u)
        ) : [] ));
  }

  public get(id: number): Observable<User> {
    return this.http.get<User>(this.e.users + '/' + id, httpOptions)
        .pipe(map((e) => User.create(e['user'])));
  }

  public delete(id: number): Observable<any> {
    return this.http.delete<User>(this.e.users + '/' + id, httpOptions);
  }

  public resetPassword(id: number): Observable<User> {
    return this.http.post<User>(this.e.users + '/' + id + '/reset-password', {}, httpOptions);
  }

  public updateNotificationsSchedule(
    id: number,
    notificationSchedule: UserNotificationsSchedule
  ): Observable<any> {
    return this.http.put<User>(this.e.users + '/' + id + '/notification-schedule', notificationSchedule);
  }

  public update(
    id: number,
    name: string,
    surname: string,
    phone: string,
    role: string,
    isEnabled: boolean,
    isVisibleOnPreloginList: boolean,
    isAllowedToLogInRemotely: boolean
  ): Observable<any> {
    return this.http.put<User>(this.e.users + '/' + id, {
      name,
      surname,
      phone,
      roles: [role],
      isEnabled,
      isVisibleOnPreloginList,
      isAllowedToLogInRemotely,
    }, httpOptions);
  }

  public create(
    email: string,
    name: string,
    surname: string,
    phone: string,
    role: string,
    isEnabled: boolean,
    isVisibleOnPreloginList: boolean,
    isAllowedToLogInRemotely: boolean
  ): Observable<User> {
    return this.http.post<User>(this.e.users, {
      email,
      name,
      surname,
      phone,
      roles: [role],
      isEnabled,
      isVisibleOnPreloginList,
      isAllowedToLogInRemotely
    }, httpOptions)
      .pipe(map((e) => User.create(e)));
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return this.http.post(this.e.authChangePassword, {
      newPassword,
      oldPassword,
    }, httpOptions);
  }

  public changeProfileData(name: string, surname: string, phone: string): Observable<any> {
    return this.http.post(this.e.authEditProfile, {
      name,
      surname,
      phone,
    }, httpOptions);
  }

  public getProfileAuditTrail(from: number, to: number): Observable<any> {
    return this.http.post(this.e.authProfileAuditTrail, {
      from,
      to
    }, httpOptions);
  }

  public getUserAuditTrail(id: number, from: number, to: number): Observable<any> {
    return this.http.post(this.e.auditTrail, {
      from,
      to,
      userIds: !id ? [] : [id]
    }, httpOptions);
  }
}
