import { TimedChartConfigSeries } from '../..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { D3DrawerData } from '../../models/charts/d3-drawer-data.model';

export const drawSeriesNowDelimiter = (
  state: D3DrawerChartState,
  data: D3DrawerData,
  series: TimedChartConfigSeries,
  seriesIndex: number,
  seriesIndexInType: number
): D3DrawerChartState => {
  const color = series.primaryColor || '#ffffff';

  const xAxisScale = state.generatedValuesStorage.get('xAxisScale');
  if (!xAxisScale) {
    return state;
  }

  let range: [number, number] = [state.viewBoxHeight - 5, 5];
  if(state.config.xAxisType !== 'invisible') {
    range = [state.viewBoxHeight - 15, 15];
  }

  let initialDuration = false;
  let gridG = state.generatedCanvas.select(`.now-delimiter-series`);
  if (gridG.empty()) {
    gridG = state.generatedCanvas.append('g')
      .attr('class', `now-delimiter-series`);
      initialDuration = true;
  }

  const t = state.generatedCanvas.transition();
  const animationTime = series.animationTime || state.config.animationTime || 0;

  gridG.selectAll('line.now-delimiter-series-line')
    .data([Date.now()])
    .join(
      enter => enter
        .append('line')
        .attr('class', 'now-delimiter-series-line')
        .attr('x1', d => xAxisScale(d))
        .attr('x2', d => xAxisScale(d))
        .attr('y1', d => range[0])
        .attr('y2', d => range[0])
        .attr('opacity', 0)
        .attr('stroke', color)
        .attr('stroke-width', 2).call(el => el.transition(t)
          .duration(animationTime)
          .attr('opacity', 1)
          .attr('y1', d => range[0])
          .attr('y2', d => range[1])
        ),
      update => update
        .call(el => el.transition(t)
          .duration(initialDuration ? 0 : animationTime)
          .attr('y1', d => range[0])
          .attr('y2', d => range[0])
          .attr('opacity', 0)
          .transition()
          .attr('x1', d => xAxisScale(d))
          .attr('x2', d => xAxisScale(d))
          .transition()
          .duration(animationTime)
          .attr('y1', d => range[0])
          .attr('y2', d => range[1])
          .attr('opacity', 1)
        ),
      exit => exit.remove()
    );

  return state;
};
