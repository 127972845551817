<div class="form-row" [ngClass]="{ 'is-required': markIsRequired }">
  <div class="form-label" *ngIf="!hideLabel">
    <label [attr.for]="identifier">
      <span #labelContent><ng-content select="form-label"></ng-content></span>
      <ng-container *ngIf="!labelContent.innerHTML.trim()">{{ label }}</ng-container>
    </label>
  </div>
  <div class="form-control form-number" [ngClass]="{invalid: (failures | async)}">
    <form-errors-display [extraErrorComponents]="extraErrorComponents" [messages]="failures | async">
      <ng-content select="form-error"></ng-content>
    </form-errors-display>
    <input
      [disabled]="disabled"
      class="input"
      autocomplete="off"
      type="text"
      [placeholder]="placeholder"
      [ngModel]="value"
      [value]="value|number:formatScale"
      [min]="min"
      [max]="max"
      (change)="inputChanged($event)"
      [id]="identifier" />
    <button [disabled]="disabled" type="button" (click)="decrease()" class="form-number--prev">
      <svg>
        <use xlink:href='#icon-chevron-down'/>
      </svg>
    </button>
    <button [disabled]="disabled" type="button" (click)="increase()" class="form-number--next">
      <svg>
        <use xlink:href='#icon-chevron-down'/>
      </svg>
    </button>
  </div>
</div>
