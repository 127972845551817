import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from 'src/app/header.service';
import { ResortService } from 'src/app/resort.service';
import { PumpRoom, WaterReservoir } from 'src/app/shared/models';
import { Subscription } from 'rxjs';
import { SettingsService } from 'src/app/settings.service';

@Component({
  selector: 'ss-pump-rooms-list',
  templateUrl: './pump-rooms-list.component.html',
  styleUrls: ['./pump-rooms-list.component.sass']
})
export class PumpRoomsListComponent implements OnInit, OnDestroy {

  constructor(
    private settingsService: SettingsService,
    private titleService: HeaderService,
    private resortService: ResortService
  ) {
    this.perspectiveMapImage = settingsService.preloadedPreviewImage;
  }

  grid3 = false;
  deviceIds: string[] = [];

  perspectiveMapImage: string = null;

  pumpRooms: PumpRoom[] = [];
  waterReservoirs: WaterReservoir[];

  alerts: Map<string, { level: string; count: number}> = new Map();
  onAlertSubscription: Subscription;

  private parseAlerts() {
    this.alerts.clear();
    this.pumpRooms?.forEach(p => {
      const alerts = this.resortService.filterAlerts([
        p.id,
        ... p.getWaterPipelines().map(ap => ap.id)
      ], [], true);
      const count = alerts.length;
      let level = '';
      if (count > 0) {
        level = alerts.sort((a, b) => a.alertLevel - b.alertLevel).pop().level;
      }
      this.alerts.set(p.id, { count, level });
    });
  }

  getAlertLevel(id: string): string {
    if (this.alerts.has(id)) {
      return this.alerts.get(id).level;
    }
    return '';
  }

  getAlertCount(id: string): number {
    if (this.alerts.has(id)) {
      return this.alerts.get(id).count;
    }
    return 0;
  }

  ngOnInit() {
    this.titleService.setUpdateHeader('menuPumpRooms');

    this.pumpRooms = [ ... this.resortService.getPumpRooms() ].sort((a, b) => `${a.symbol}`.localeCompare(`${b.symbol}`));
    this.waterReservoirs = [ ... this.resortService.getWaterReservoirs() ].sort((a, b) => `${a.symbol}`.localeCompare(`${b.symbol}`));

    if([1, 2, 3, 5, 6, 9].includes(this.pumpRooms.length)) {
      this.grid3 = true;
    }

    this.parseAlerts();
    this.onAlertSubscription = this.resortService.onAlerts.subscribe(() => {
      this.parseAlerts();
    });
  }

  ngOnDestroy() {
    this.onAlertSubscription?.unsubscribe();
  }

}
