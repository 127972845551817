import { Injectable } from '@angular/core';
import { EndpointMapService } from '../endpoint-map.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { UserNote, UserNotes } from '../shared/models';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserNotesClientService {

  constructor(private e: EndpointMapService, private http: HttpClient) {}

  public getAll(): Observable<UserNote[]> {
    return this.http.get<UserNotes>(this.e.userNotes, httpOptions)
        .pipe(map((data) => data.notes ? data.notes.map(f =>
          UserNote.create(f)
        ) : []));
  }

  create(note: UserNote): Observable<any> {
    return this.http.post(this.e.userNotes, {
      context: note.context,
      content: note.content,
      isPrivate: note.isPrivate
    }, httpOptions);
  }

  edit(noteId: number, note: UserNote): Observable<any> {
    return this.http.put(this.e.userNotes + '/' + noteId, {
      content: note.content,
      isPrivate: note.isPrivate
    }, httpOptions);
  }

  remove(noteId: number): Observable<any> {
    return this.http.delete(this.e.userNotes + '/' + noteId, httpOptions);
  }
}
