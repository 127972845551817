<form #form="ngForm" autocomplete="off">

  <ss-page [modal]="true">
    <ss-page-header>
        <ss-page-header-title><ng-container i18n="Konfiguracja punktu pomiarowego|Tytuł modala Konfiguracja punktu pomiarowego@@modalConfigureElectricalLineTitle">Konfiguracja punktu pomiarowego</ng-container> {{ electricalLine.symbol }}</ss-page-header-title>
    </ss-page-header>

    <ss-page-panel
      panelTitle="Limit poboru mocy"
      i18n-panelTitle="Limit poboru mocy|input Limit poboru mocy@@modalConfigureElectricalLinelimitPowerDrawPanelTitle">
      <form-checkbox [hideLabel]="true" [(ngModel)]="isLimitPowerDrawEnabled" name="form-cfg-currentDrawLimitEnabled">
        <form-label i18n="Włącz limit poboru mocy|Włącz limit poboru mocy@@modalConfigureElectricalLinelimitPowerDrawCheckbox">Włącz limit poboru mocy</form-label>
      </form-checkbox>
      <div class="detailed-settings" [ngClass]="{ 'visible': isLimitPowerDrawEnabled }">
        <p class="pb-2" i18n="Przekroczenie limitu poboru mocy powoduje wygenerowanie ostrzeżenia oraz wysłanie powiadomienia do użytkowników|Akapit z info@@modalConfigureElectricalLinelimitPowerDrawInfoP">Przekroczenie limitu poboru mocy powoduje wygenerowanie ostrzeżenia oraz wysłanie powiadomienia do użytkowników</p>
        <form-range
          [disabled]="electricalLine.isLocked"
          [max]="electricalLine.maximumPowerDraw > 0 ? electricalLine.maximumPowerDraw : 10000"
          [min]="0"
          [step]="10"
          name="electricalLinelimitPowerDraw"
          label="Limit poboru mocy"
          i18n-label="Limit poboru mocy|input Limit poboru mocy@@modalConfigureElectricalLinelimitPowerDraw"
          [(ngModel)]="limitPowerDraw">
        </form-range>
      </div>
    </ss-page-panel>

    <ss-page-footer>
        <div></div>
        <ss-page-button-group>
            <button type="submit" [disabled]="electricalLine.isLocked || form.invalid" class="btn btn-primary" (click)="save()"
              i18n="Zapisz zmiany|Zapisz zmiany@@modalConfigureElectricalLineSaveButton">Zapisz zmiany</button>
            <button type="button" class="btn btn-default" (click)="close()"
              i18n="Anuluj|Anuluj@@modalConfigureElectricalLineCancelButton">Anuluj</button>
        </ss-page-button-group>
    </ss-page-footer>
  </ss-page>

</form>
