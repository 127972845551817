import { Component, Input } from '@angular/core';
import { ResortService } from 'src/app/resort.service';
import { MeteoDevice } from '../../../models';

@Component({
  selector: 'ss-meteo-conditions-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.sass']
})
export class MeteoConditionsDisplayComponent {

  private _sourceId: string;
  @Input()
  public get sourceId(): string {
    return this._sourceId;
  }
  public set sourceId(v: string) {
    this._sourceId = v;
    this.sourceDevice = this.rs.getSnowCannon(v) || this.rs.getMeteoStation(v);
  }

  @Input()
  public type: 'grid'|'linear' = 'linear';

  @Input()
  public sourceDevice: MeteoDevice;

  constructor(private rs: ResortService) { }
}
