import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'ss-page-header-title',
  templateUrl: './page-header-title.component.html',
  styleUrls: ['./page-header-title.component.sass']
})
export class PageHeaderTitleComponent implements OnInit {

  constructor() { }

  @HostBinding('class.subtitle')
  @Input()
  public isSubtitle = false;

  ngOnInit() {
  }

}
