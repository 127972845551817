import { DeviceSystemType } from '../../../../../common';
import { AbstractDevice } from './abstract-device.model';
import { MeteoDevice } from './meteo-device.interface';

export class MeteoStation extends AbstractDevice implements MeteoDevice {
    systemType: DeviceSystemType = 'MeteoStation';

    position: any;
    symbol: string;
    maslAltitude: number;
    canHaveWindDirection: boolean;

    isLocked = false;

    airTemperature: number;
    atmosphericAirPressure: number;
    wetBulbTemperature: number;
    windDirection: number;
    windSpeed: number;
    airHumidity: number;

    airHumidityOverride: number;
    airTemperatureOverride: number;
    wetBulbTemperatureOverride: number;

    computeMapStatus(): string {
      const t = Math.round(10 * this.wetBulbTemperature);
      const d = Math.round(10 * this.windDirection);
      const w = Math.round(10 * this.windSpeed);
      return `${this.computedStatus}|${t}|${d}|${w}`;
    }
}
