import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndpointMapService } from '../endpoint-map.service';
import { map } from 'rxjs/operators';
import { isArray, ReportDefinition, ReportExport, ReportResult } from '../../../../common';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ReportingClientService {
  constructor(private e: EndpointMapService, private http: HttpClient) {}

  public getReportDefinitions(): Observable<ReportDefinition[]> {
    return this.http.get(this.e.reportingDefinitions, httpOptions)
      .pipe(map((resp: { list: any[] }) => resp.list.map(r => ReportDefinition.create(r))));
  }

  public getReportDefinition(id: any): Observable<ReportDefinition> {
    return this.http.get(this.e.reportingDefinitions + '/' + id, httpOptions)
      .pipe(map(data => ReportDefinition.create(data)));
  }

  public getRreportingExportsLatest(): Observable<ReportExport[]> {
    return this.http.get(this.e.reportingExportsLatest, httpOptions)
      .pipe(map((resp: { list: any[] }) => {
        if(isArray(resp.list)) {
          return resp.list.map(r => ReportExport.create(r));
        }
        return [];
      }));
  }

  public saveReportDefinition(definition: ReportDefinition): Observable<any> {
    return this.http.post(
      this.e.reportingDefinitions,
      definition.toHttpRequest(),
      httpOptions
    );
  }

  public updateCustomDefinitionsOrder(data: any): Observable<any> {
    return this.http.post(
      this.e.reportingDefinitionsReorder,
      data,
      httpOptions
    );
  }

  public removeReportDefinition(id: any): Observable<any> {
    return this.http.delete(this.e.reportingDefinitions + '/' + id, httpOptions);
  }

  public fetch(request: ReportResult): Observable<ReportResult> {
    return this.http.post(
      this.e.reportingResults,
      request.toHttpRequest(),
      httpOptions
    ).pipe(map((resp: {fetchedReport: any}) => ReportResult.create(resp.fetchedReport)));
  }

  public scheduleExport(request: ReportExport) {
    return this.http.post(
      this.e.reportingExports,
      request.toHttpRequest(),
      httpOptions
    ).pipe(map((resp: any) => ReportExport.create(resp)));
  }

  public download(e: ReportExport, apiToken: string): Observable<any> {
    const headers = new HttpHeaders().set('authorization','Bearer ' + apiToken);
    return this.http.get(this.e.reportingExportsDownload + '/' + e.id, {
      headers,
      responseType: 'blob' as 'json'
    });
  }
}
