import { WaterPipeline } from '.';
import { PumpRoomConfiguration } from './pump-room';
import { AbstractDevice } from './abstract-device.model';
import { ConvertUnit, DeviceSystemType } from '../../../../../common';

export class PumpRoom extends AbstractDevice {
    systemType: DeviceSystemType = 'PumpRoom';

    position: any;
    symbol: string;

    alertCommunicationsError: boolean;
    alertBreakdown: boolean;
    alertEmergencyButtonPressed: boolean;
    alertPowerFault: boolean;
    infoRemoteModeEnabled: boolean;
    computedWaterPressure: number;
    maximumWaterPressure: number;
    @ConvertUnit('waterFlow', 'm3/h')
    computedWaterFlow: number;
    @ConvertUnit('waterFlow', 'm3/h')
    maximumWaterFlow: number;

    isLocked = false;

    configuration: PumpRoomConfiguration;
    extraParameters: any;

    waterPipelineRefs: Map<string, WaterPipeline> = new Map();

    public update(data: any) {
        if (!(this.configuration instanceof PumpRoomConfiguration)) {
            data.configuration = PumpRoomConfiguration.create(this, data.configuration);
        } else {
            delete data.configuration;
        }
        return Object.assign(this, data);
    }

    getWaterPipelines(): WaterPipeline[] {
      return Array.from(this.waterPipelineRefs.values());
    }

    countWaterPipelines(): number {
        return Array.from(this.waterPipelineRefs.values()).length;
    }

    countByStandingBy(): number {
        return this.getWaterPipelines().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'standing-by').length;
    }

    countBySnoozing(): number {
      return this.getWaterPipelines().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'snoozing').length;
    }

    countByReadyToWork(): number {
        return this.getWaterPipelines().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'ready-to-work').length;
    }

    countByBreakdown(): number {
        return this.getWaterPipelines().filter(e => e.computedStatus === 'breakdown' || e.computedStatus === 'connection-lost').length;
    }

    countByFilling(): number {
        return this.getWaterPipelines().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'filling').length;
    }

    countByWorking(): number {
        return this.getWaterPipelines().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'working').length;
    }


    canDisplayWaterFlow(): boolean {
      return this.getWaterPipelines().filter(p => p.configuration && p.configuration.hasFlowMeter).length > 0;
    }

    canDisplayWaterPressure(): boolean {
      return this.getWaterPipelines().filter(p => p.configuration && p.configuration.hasPressureMeter).length > 0;
    }
}
