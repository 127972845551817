<div id="resortRouterOutlet">
  <router-outlet></router-outlet>
</div>

<div id="mapButtons" [ngClass]="{'cannon-offset': isCannonOpenned}">
  <div class="cannons-groups" id="workGroups">
    <button (click)="groupsVisible = !groupsVisible" class="cannons-groups__button d-flex align-items-center justify-content-between mx-4" [ngClass]="{'is-active': groupsVisible}">
        <svg class="cannons-groups__group-icon">
          <use xlink:href='#icon-group'/>
        </svg>
        <ng-container i18n="Grupy urządzeń|Nazwa przycisku na mapie rozwijającego listę urządzeń@@areaMapDevicesGroups">Grupy urządzeń</ng-container>
        <svg class="cannons-groups__chevron-icon" [ngClass]="{'cannons-groups__chevron-icon--rotated': groupsVisible}">
          <use xlink:href='#icon-chevron-down'/>
        </svg>
    </button>
    <a class="cannons-groups__link d-flex align-items-center mx-4" [ngClass]="{'cannons-groups__link--visible': groupsVisible}">
        <svg>
        <use xlink:href='#icon-chevron-left'/>
        </svg>
        <ng-container i18n="Zobacz cały stok|Nazwa przycisku na mapie przełączającego na widok całego stoku@@areaMapShowAll">Zobacz cały stok</ng-container>
    </a>
    <div class="cannons-groups__container pt-4" [ngClass]="{'cannons-groups__container--visible': groupsVisible}">
        <!-- <ss-cannons-group name="armatki w użyciu" [numberOfDevices]="snowCannons.length"
          [performance]="area.snowProductionVolume"></ss-cannons-group> -->
        <!-- <ss-cannons-group notifications="2"></ss-cannons-group> -->
        <!-- <ss-cannons-group isWarning></ss-cannons-group> -->
        <!--
        <ss-cannons-group *ngFor="let group of cannonsGroups"
        [name]="group.name"
        [numberOfDevices]="group.numberOfDevices"
        [performance]="group.performance"
        [notifications]="group.notifications"
        [isWarning]="group.isWarning">
        </ss-cannons-group>
        -->
    </div>
  </div>
  <div class="cannons-groups" id="mapOptionsMenu" [ngClass]="{'hidden': optionsHidden}">
    <button (click)="toogleOptionsMenu()" class="cannons-groups__button d-flex align-items-center mx-4 is-active"
      i18n="Mapa lub zdjęcia satelitarne|Mapa lub zdjęcia satelitarne@@areaMapSelectTypeOpacity">
      Mapa lub zdjęcia satelitarne
    </button>
    <div class="map-options-menu-entry">
      <svg class="ml-1">
        <use xlink:href='#map-type-satellite'/>
      </svg>
      <div class="map-options-range-slider-holder">
        <form-range [min]="0" [max]="100" [step]="5" value="50" [hideLabel]="true" [(ngModel)]="hipsoTileLayerOpacity"></form-range>
      </div>
      <svg class="mr-1">
        <use xlink:href='#map-type-hipso'/>
      </svg>

    </div>

  </div>

  <div class="cannons-groups" id="layersControl" [ngClass]="{'hidden': layersHidden}">
    <button class="cannons-groups__button d-flex align-items-center mx-4 is-active">
        <svg class="cannons-groups__group-icon mr-2">
          <use xlink:href='#icon-layers'/>
        </svg>
        <ng-container i18n="Warstwy mapy|Etykieta listy warstw mapy@@areaMapLayerList">Warstwy mapy</ng-container>
    </button>
    <ng-container *ngFor="let l of layersControl">
      <div  class="cannons-groups__link d-flex cannons-groups__container--visible align-items-center mx-4 fs-14">
        <ss-checkbox (click)="$event.preventDefault();toggleLayers(l['id'])" [isChecked]="l['isVisible']">{{ l['label'] }}</ss-checkbox>
      </div>
      <div *ngIf="l['id'] === 'currentTWB' && l['isVisible']"
        class="cannons-groups__legend d-flex cannons-groups__container--visible align-items-center mx-4 fs-14">
        <div class="cannons-groups__legend__boxes">
          <span *ngFor="let c of currentTWBColorMap"
            [ngClass]="{ 'active': c.color === focusOnTwbColor }"
            [ngStyle]="{ background: c.color }"></span>
        </div>
        <div class="cannons-groups__legend__labels">
          <ng-container *ngFor="let c of currentTWBColorMap; let first = first; let index = index; let last = last">
            <span *ngIf="first"></span>
            <span *ngIf="index === 1">
              &gt;&nbsp;{{ c.temp|number:'1.1-1' }}&deg;C
            </span>
            <span *ngIf="index > 1 && !last">
              {{ c.temp|number:'1.1-1' }}&deg;C
            </span>
            <span *ngIf="last">
              &lt;&nbsp;{{ c.temp|number:'1.1-1' }}&deg;C
            </span>
          </ng-container>
        </div>

      </div>
      <div
        *ngIf="l['id'] === 'snowThickness' && l['isVisible']"
        class="cannons-groups__legend d-flex cannons-groups__container--visible align-items-center pt-2 mx-4 fs-14">
        <ng-container i18n="Pomiar z|Lista wartstw na mapie - Pomiar z dnia@@layerControlSnowDepthMeasurementTitle">Pomiar z</ng-container>:
        {{ snowDepthDate }}
      </div>
      <div *ngIf="l['id'] === 'snowThickness' && l['isVisible']"
        class="cannons-groups__legend d-flex cannons-groups__container--visible align-items-center mx-4 fs-14">
        <div class="cannons-groups__legend__boxes">
          <span *ngFor="let c of snowThicknessColorMap"
            [ngClass]="{ 'active': c.color === focusOnSnowDepthColor }"
            [ngStyle]="{ background: c.color }"></span>
        </div>
        <div class="cannons-groups__legend__labels">
          <ng-container *ngFor="let c of snowThicknessColorMap; let first = first; let index = index; let last = last">
            <span *ngIf="first"></span>
            <span *ngIf="index === 1">
              &gt;&nbsp;{{ c.depth|number:'1.0-0' }}cm
            </span>
            <span *ngIf="index > 1 && !last">
              {{ c.depth|number:'1.0-0' }}cm
            </span>
            <span *ngIf="last">
              &lt;&nbsp;{{ c.depth|number:'1.0-0' }}cm
            </span>
          </ng-container>
        </div>

      </div>

    </ng-container>
    <ng-template #layerControlPumpRoomsLayerLabel>
      <ng-container i18n="Pompownie|Lista wartstw na mapie - Pompownie@@layerControlPumpRoomsLayerLabel">Pompownie</ng-container>
    </ng-template>
    <ng-template #layerControlResortAreaLayerLabel>
      <ng-container i18n="Obszar stoku|Lista wartstw na mapie - Obszar stoku@@layerControlResortAreaLayerLabel">Obszar stoku</ng-container>
    </ng-template>
    <ng-template #layerControlSnowCannonsLayerLabel>
      <ng-container i18n="Armatki i lance|Lista wartstw na mapie - Armatki i lance@@layerControlSnowCannonsLayerLabel">Armatki i lance</ng-container>
    </ng-template>
    <ng-template #layerControlConnectionPointsLayerLabel>
      <ng-container i18n="Studnie fundamentowe|Lista wartstw na mapie - Studnie fundamentowe@@layerControlConnectionPointsLayerLabel">Studnie fundamentowe</ng-container>
    </ng-template>
    <ng-template #layerControlSnowThicknessLayerLabel>
      <ng-container i18n="Grubość pokrywy śnieżnej|Lista wartstw na mapie - Grubość pokrywy śnieżnej@@layerControlSnowThicknessLayerLabel">Grubość pokrywy śnieżnej</ng-container>
    </ng-template>
    <ng-template #layerControlWaterPipelineLayerLabel>
      <ng-container i18n="Rurociągi|Lista wartstw na mapie - Rurociągi@@layerControlWaterPipelineLayerLabel">Rurociągi</ng-container>
    </ng-template>
    <ng-template #layerControlCurrentTWBLayerLabel>
      <ng-container i18n="Aktualne TWB|Lista wartstw na mapie - Rurociągi@@layerControlCurrentTWBLayerLabel">Aktualne TWB</ng-container>
    </ng-template>
    <ng-template #layerControlMeteoStations>
      <ng-container i18n="Stacje meteo|Lista wartstw na mapie - Stacje meteo@@layerControlMeteoStations">Stacje meteo</ng-container>
    </ng-template>
  </div>

  <div class="map-buttons">
    <button [disabled]="canZoomIn" (click)="zoomIn()" class="map-buttons__button d-flex align-items-center justify-content-center">
      <svg>
        <use xlink:href='#icon-plus'/>
      </svg>
    </button>
    <button [disabled]="canZoomOut" (click)="zoomOut()" class="map-buttons__button d-flex align-items-center justify-content-center mb-2">
      <svg>
        <use xlink:href='#icon-minus'/>
      </svg>
    </button>
    <button (click)="setNorth()" [ngClass]="{'make-it-red': currentMapBearing < 1}"
      class="map-buttons__button d-flex align-items-center justify-content-center mb-2">
      <svg>
        <use xlink:href='#icon-north'/>
      </svg>
    </button>
    <div class="map-buttons__button map-buttons__button--double">
      <button (click)="toggleLayersMenu()" class="d-flex align-items-center justify-content-center">
        <svg>
          <use xlink:href='#icon-layers'/>
        </svg>
      </button>
      <button (click)="toogleOptionsMenu()" class="d-flex align-items-center justify-content-center">
        <svg>
          <use xlink:href='#icon-gear'/>
        </svg>
      </button>
    </div>
  </div>
</div>
<div id="resortMap" [ngClass]="{'cannon-offset': isCannonOpenned}">

  <div id="cannonHover" *ngIf="mapHoverSelectedSnowCannon" (mouseleave)="resetMapHoverSelectedImmediately()" [ngStyle]="{
    left: mapHoverPositionX + 'px',
    top: mapHoverPositionY + 'px'
  }">
    <ss-cannon-preview [snowCannon]="mapHoverSelectedSnowCannon" [direction]="mapHoverPositionDirection"></ss-cannon-preview>
  </div>

  <div id="connectionPointHover" *ngIf="mapHoverSelectedConnectionPoint" (mouseleave)="resetMapHoverSelectedImmediately()" [ngStyle]="{
    left: mapHoverPositionX + 'px',
    top: mapHoverPositionY + 'px'
  }">
    <ss-connection-point-preview [connectionPoint]="mapHoverSelectedConnectionPoint" [direction]="mapHoverPositionDirection"></ss-connection-point-preview>
  </div>

  <div id="meteStationHover" *ngIf="mapHoverSelectedMeteoStation" (mouseleave)="resetMapHoverSelectedImmediately()" [ngStyle]="{
    left: mapHoverPositionX + 'px',
    top: mapHoverPositionY + 'px'
  }">
    <ss-meteo-station-preview [meteoStation]="mapHoverSelectedMeteoStation" [direction]="mapHoverPositionDirection"></ss-meteo-station-preview>
  </div>


  <div class="leafletMap" #resortMap
    (leafletMapReady)="onMapReady($event)"
    [leafletOptions]="mapOptions"
    [leafletLayers]="layers"
    leaflet>
  </div>

</div>
