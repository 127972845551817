<div class="component" [ngClass]="{
    'highlighted': pump.isSelectedByDeviceIds(selectedPipelineIds),
    'breakdown': pump.isBreakdown
}">
    <div class="info-row">
        <svg>
            <use xlink:href='#icon-pump-2' />
        </svg>
        <p>{{ pump.symbol }}</p>
        <p>{{ pump.typeDescription }}&nbsp;</p>
    </div>
    <div class="status-row">
        <ss-pump-room-detail-status [status]="pump.computedStatus"></ss-pump-room-detail-status>

        <p *ngIf="pump.currentDrawVar">
            <svg>
                <use xlink:href='#icon-thunder' />
            </svg>
            {{ (pump.currentDraw || 0) | number:'1.1-1' }}&nbsp;A
        </p>
        <p *ngIf="pump.vfdFrequencyVar">
            <svg>
                <use xlink:href='#icon-thunder' />
            </svg>
            {{ (pump.vfdFrequency || 0) | number:'1.1-1' }}&nbsp;Hz
        </p>
        <p *ngIf="pump.isHeaterOnVar">
          <svg [ngClass]="{
            'fill-light-grey': !pump.isHeaterOn,
            'fill-green': pump.isHeaterOn
          }">
            <use xlink:href="#icon-heater" />
          </svg>
        </p>
    </div>
    <div class="status-row" *ngIf="pump.isManual">
      <ss-pump-room-detail-status status="working-in-manual-mode" class="w-100"></ss-pump-room-detail-status>
    </div>
</div>