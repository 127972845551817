import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { DAYJS, ReportPartView } from '../../../../../../../common';

@Component({
  selector: 'ss-reporting-single-part-display',
  templateUrl: './part-display.component.html',
  styleUrls: ['./part-display.component.sass']
})
export class ReportingSingleReportPartDisplayComponent {

  @Input()
  public partView: ReportPartView;
  @Input()
  public isEditMode: boolean;
  @Input()
  public selectedDate: DAYJS;
  @Input()
  public secondaryOffsetWidth: number;

  @HostBinding('class')
  public get isLong(): string {
    return this.isEditMode || this.isChart
      ? 'col-auto w-100'
      : 'col-3 pr-1';
  }

  public get isChart(): boolean {
    return this.partView && ['chart', 'sum-chart', 'avg-chart'].includes(this.partView.part.partType);
  }

  @Output()
  public renamePartRequest: EventEmitter<any> = new EventEmitter();
  @Output()
  public editPartRequest: EventEmitter<any> = new EventEmitter();
  @Output()
  public removePartRequest: EventEmitter<any> = new EventEmitter();

  public renamePart() {
    this.renamePartRequest.emit();
  }
  public editPart() {
    this.editPartRequest.emit();
  }
  public removePart() {
    this.removePartRequest.emit();
  }
}
