import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ResortService } from 'src/app/resort.service';
import {
  AreaConfigurationResolverData,
  HydrantToCannonRef,
  WorkingAreaToHydrantRef
} from './area-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class AreaConfigurationDataResolverService implements Resolve<AreaConfigurationResolverData> {
  constructor(
    private rs: ResortService,
    private router: Router
  ) {}

  private getData(id: string): AreaConfigurationResolverData {
    const resortArea = this.rs.getResortArea(id);
    const notAssignedSnowCannons = this.rs.getSnowCannons().filter(sc => !sc.connectionPoint);
    const notAssignedHydrants: HydrantToCannonRef[] = [];
    const assignedHydrantsMap: Map<string, WorkingAreaToHydrantRef> = new Map();
    this.rs.getWorkingAreas()
      .filter(wr => wr.resortArea === id)
      .sort((a, b) => a.symbol.localeCompare(b.symbol))
      .forEach(wr => {
        assignedHydrantsMap.set(wr.id, {
          workingArea: wr,
          hydrants: []
        });
      });

    this.rs.getConnectionPoints()
      .filter(cp => cp.resortArea === id)
      .sort((a, b) => a.symbolAsNumber - b.symbolAsNumber)
      .map(cp => ({
          hydrant: cp,
          isVisible: true,
          cannons: cp.getSnowCannons()
        })).forEach(cp => {
        if (cp.hydrant.workingArea) {
          assignedHydrantsMap.get(cp.hydrant.workingArea).hydrants.push(cp);
        } else {
          notAssignedHydrants.push(cp);
        }
      });

    return {
      resortArea,
      notAssignedSnowCannons,
      assignedHydrants: Array.from(assignedHydrantsMap.values()),
      notAssignedHydrants
    };
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    AreaConfigurationResolverData
    | Observable<AreaConfigurationResolverData>
    | Observable<never> {
    return this.getData(route.paramMap.get('id'));
  }
}
