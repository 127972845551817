import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { AreaData } from '../../area-data-resolver.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ResortArea,
  Schedule,
  MeteoStation,
  SnowCannon,
  ConnectionPoint,
  PumpRoom,
  CompressorRoom,
  PowerSource,
  Resort
} from 'src/app/shared/models';
import {  Observable, of, Subscription } from 'rxjs';
import { SettingsService } from 'src/app/settings.service';
import { HeaderService } from 'src/app/header.service';
import { ResortService } from 'src/app/resort.service';
import { DevicesClientService } from 'src/app/http-clients';
import { SnowQualityValue } from 'src/app/shared/components/snow-quality/snow-quality.component';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { AreaHeaderExtensionComponent } from '../../area-header-extension/area-header-extension.component';
import { MapService } from '../map.service';
import { ConfirmModalService } from 'src/app/application/confirm-modal.service';
import { AuthService } from 'src/app/auth.service';
import { SnowCannonData } from './snow-cannon-data-resolver.service';

@Component({
  selector: 'ss-snow-cannon',
  templateUrl: './snow-cannon-panel.component.html',
  styleUrls: ['./snow-cannon-panel.component.sass']
})
export class SnowCannonPanelComponent implements OnInit, OnDestroy {

  constructor(
    private confirmModalService: ConfirmModalService,
    private mapService: MapService,
    private route: ActivatedRoute,
    private dd: DevicesClientService,
    private rs: ResortService,
    private cs: ConfigureClientService,
    private settingsService: SettingsService,
    private titleService: HeaderService,
    private as: AuthService,
    private router: Router,
  ) { }

  isModeDropdownExpanded = false;

  snowCannonAdjustToWind = false;

  showWindOnMap = false;
  isFromList = false;

  area: ResortArea;
  schedules: Schedule[];
  currentSchedule: Schedule;
  meteoStation: MeteoStation;
  snowCannons: SnowCannon[];
  snowCannon: SnowCannon;
  connectionPoints: ConnectionPoint[];
  pumpRooms: PumpRoom[];
  compressorRooms: CompressorRoom[];
  powerSources: PowerSource[];
  meteoStations: MeteoStation[];

  previousSnowCannon: SnowCannon;
  nextSnowCannon: SnowCannon;
  recentCannons: SnowCannon[] = [];

  public devIds: string[] = [];

  closestScheduleHours: string;

  cannonsListExpanded = false;

  currentMapBearing = 0;
  snowingSettingsVisible = false;
  snowingSettingsAreaCenterX = 0;
  snowingSettingsAreaCenterY = 0;
  snowingSettingsAreaBoundsX = 0;
  snowingSettingsAreaBoundsY = 0;

  mapServiceMapBearingSubscription: Subscription;
  mapServiceMonitoredMarkerPositionSubscription: Subscription;

  public resort: Resort;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.target['tagName'] !== 'BODY') {
      return;
    }

    if (event.code === 'ArrowRight') {
      this.goToNextCannon();
    }

    if (event.code === 'ArrowLeft') {
      this.goToPrevCannon();
    }
  }

  goToPrevCannon() {
    if (this.previousSnowCannon) {
      this.router.navigate(['/application/resort/area', this.area.id, 'map', this.previousSnowCannon.id]);
    }
  }

  goToNextCannon() {
    if (this.nextSnowCannon) {
      this.router.navigate(['/application/resort/area', this.area.id, 'map', this.nextSnowCannon.id]);
    }
  }

  positionAndOscillationCommand(command: any) {
    this.configureSnowCannon(this.snowCannon, command);
  }

  toggleSnowCannonStartStop(snowCannon: SnowCannon) {
    let action = this.cs.startDevice(snowCannon.id);
    if (snowCannon.commandStatusCannonStart) {
      action = this.cs.stopDevice(snowCannon.id);
    }
    action.subscribe(() => {}, () => {});
  }

  setSnowCannonStartTemperature(snowCannon: SnowCannon, event) {
    this.configureSnowCannon(snowCannon, {
      setStartTemperature: 0.01 * Math.round(100 * event)
    });
  }

  setSnowCannonStartWaterFlow(snowCannon: SnowCannon, event) {
    this.configureSnowCannon(snowCannon, {
      setStartWaterFlow: 0.1 * Math.round(10 * event)
    });
  }

  setSnowCannonOscillationEnabled(snowCannon: SnowCannon, enable: boolean) {
    if (enable) {
      this.configureSnowCannon(snowCannon, {
        commandOscillationOn: true
      });
    } else {
      this.configureSnowCannon(snowCannon, {
        commandOscillationOff: true
      });
    }
  }

  setSnowCannonOperationMode(snowCannon: SnowCannon, mode: boolean) {
    let question: Observable<boolean> = of(true);
    const om = {
      operationMode: mode
    };

    if (
      mode
      && (
        snowCannon.snowQualityType > 0
        || snowCannon.snowQualityConstant !== snowCannon.snowQuality
      )
    ) {
      question = this.confirmModalService.openAlertChangeWillSetDifferentSnowQuality(
        snowCannon.snowQualityType,
        snowCannon.snowQuality,
        snowCannon.snowQualityConstant,
        snowCannon.snowQualityLow,
        snowCannon.snowQualityHigh,
      );
    }

    question.subscribe(answer => {
      if (!answer) {
        om['snowQualityType'] = 0;
        om['snowQualityConstant'] = snowCannon.snowQuality;
      }
      this.configureSnowCannon(snowCannon, om);
    });
  }

  calcWindSpeedScale(windSpeed: number): number {
    switch (true) {
      case windSpeed > 2:
        return 220;
      case windSpeed > 4:
        return 240;
      case windSpeed > 6:
        return 260;
      case windSpeed > 9:
        return 280;
      case windSpeed > 12:
        return 310;
      case windSpeed > 16:
        return 340;
      case windSpeed > 20:
        return 370;
      case windSpeed > 25:
        return 400;
      default:
        return 200;
    }
  }

  setCannonSnowQuality(cannon: SnowCannon, event: SnowQualityValue) {
    let om = {};
    if (cannon.operationMode) {
      om = {
        snowQualityType: event.type,
        snowQualityConstant: event.current,
        snowQualityLow: event.low,
        snowQualityHigh: event.high
      };
    } else {
      om = {
        setSnowQuality: event.current
      };
    }
    this.configureSnowCannon(cannon, om);
  }

  public resetAlerts(snowCannon: SnowCannon) {
    this.configureSnowCannon(snowCannon, {
      commandResetAlerts: true
    });
  }

  public toggleHeaters(snowCannon: SnowCannon) {
    if (snowCannon.commandStatusRingHeating) {
      this.configureSnowCannon(snowCannon, {
        commandRingHeatingOff: true
      });
    } else {
      this.configureSnowCannon(snowCannon, {
        commandRingHeatingOn: true
      });
    }
  }

  public toggleLampMode(snowCannon: SnowCannon) {
    const dt = {};
    if (snowCannon.commandStatusLightAutomatic) {
      if (snowCannon.isLampOn) {
        dt['commandLightTurnOff'] = true;
        dt['commandLightAutomaticOff'] = true;
      } else {
        dt['commandLightAutomaticOff'] = true;
      }
    } else {
      if (snowCannon.isLampOn) {
        dt['commandLightAutomaticOn'] = true;
      } else {
        dt['commandLightTurnOn'] = true;
      }
    }
    this.configureSnowCannon(snowCannon, dt);
  }

  private configureSnowCannon(snowCannon: SnowCannon, data: any) {
    this.cs.configureSnowCannon(snowCannon, data).subscribe(() => {}, () => {});
  }

  @HostListener('window:resize')
  calcSnowingSettingsPosition() {
    this.snowingSettingsAreaBoundsX = 960; // (window.innerWidth - 960);
    this.snowingSettingsAreaBoundsY = (window.innerHeight - 80);
  }

  trackFn(el): string {
    return el['id'];
  }

  ngOnInit() {
    this.snowingSettingsVisible = false;

    this.calcSnowingSettingsPosition();

    this.route.data
      .subscribe((data: { areaData: AreaData; snowCannonData: SnowCannonData; isFromList: boolean }) => {
        this.cannonsListExpanded = false;
        this.snowingSettingsVisible = false;
        this.isFromList = data.isFromList;

        if (data && data.areaData) {
          Object.assign(this, data.areaData);

          const snowCannon = data.snowCannonData.snowCannon;

          this.settingsService.append('recentSnowCannonIds', snowCannon.id, 10);

          this.snowCannon = snowCannon;

          this.recentCannons = [];
          const recentCannonIds = this.settingsService.get<string[]>('recentSnowCannonIds');
          recentCannonIds.forEach(recentId => {
            const found = this.rs.getSnowCannon(recentId);
            if (found) {
              this.recentCannons.push(found);
            }
          });

          for (let i = 0; i < this.snowCannons.length; i++) {
            const sc = this.snowCannons[i];
            if (sc.id === this.route.snapshot.params.snowCannonId) {
              const prevIndex: number = (i > 0 ? i : this.snowCannons.length) - 1;
              const nextIndex: number = (i === this.snowCannons.length - 1 ? 0 : i + 1);

              this.previousSnowCannon = this.snowCannons[prevIndex];
              this.nextSnowCannon = this.snowCannons[nextIndex];
              break;
            }
          }

          this.titleService.setUpdateHeader('menuSnowCannons', AreaHeaderExtensionComponent, {
            resortName: this.resort.symbol,
            area: this.area,
            isMap: !this.isFromList
          });

          this.devIds = [
            this.route.snapshot.params.snowCannonId
          ];

          this.closestScheduleHours = this.currentSchedule.getClosest();

          this.currentMapBearing = this.mapService.curretMapBearing;
          this.mapServiceMapBearingSubscription = this.mapService.mapBearing.subscribe(bearing => {
            this.currentMapBearing = bearing;
          });

          this.mapServiceMonitoredMarkerPositionSubscription = this.mapService.monitoredMarkerPosition.subscribe(position => {
            if (position != null) {
              this.snowingSettingsAreaCenterX = position.x;
              this.snowingSettingsAreaCenterY = position.y;

              if (
                this.snowingSettingsAreaCenterX - 50 > 0
                && this.snowingSettingsAreaCenterX + 50 < this.snowingSettingsAreaBoundsX
                && this.snowingSettingsAreaCenterY - 50 > 0
                && this.snowingSettingsAreaCenterY + 50 < this.snowingSettingsAreaBoundsY
              ) {
                this.snowingSettingsVisible = true;
              } else {
                this.snowingSettingsVisible = false;
              }
            } else {
              this.snowingSettingsVisible = false;
            }
          });

          this.mapService.mapToSnowCannon(this.snowCannon);
          this.mapService.setShowLayers('resortArea', 'snowCannons');
        }
      });
  }

  ngOnDestroy() {
    if(this.mapServiceMapBearingSubscription) {
      this.mapServiceMapBearingSubscription.unsubscribe();
    }
    if(this.mapServiceMonitoredMarkerPositionSubscription) {
      this.mapServiceMonitoredMarkerPositionSubscription.unsubscribe();
    }
  }
}
