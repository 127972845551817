<ss-page>
    <ss-page-header>
        <ss-page-header-title>Editor</ss-page-header-title>
    </ss-page-header>
    <ss-page-panel>
        <a class="btn my-1 btn-default" [routerLink]="['/application', 'editor', 'resort']">Resort</a>
        <a class="btn my-1 btn-default" [routerLink]="['/application', 'editor', 'areas']">Areas</a>
        <a class="btn my-1 btn-default" [routerLink]="['/application', 'editor', 'conenction-points']">Connection points</a>


        <a class="btn my-1 btn-default"
            *ngFor="let pr of prs"
          [routerLink]="['/application', 'editor-pump-room-full-schema', pr.id]">Full pumproom schema for {{ pr.symbol }}</a>

        <a class="btn my-1 btn-default" [routerLink]="['/application', 'editor', 'water-pipelines']">Water pipelines</a>
    </ss-page-panel>
</ss-page>
