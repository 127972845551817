import { Component, OnInit, HostBinding, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Drawable, DrawableLine } from 'src/app/shared/models/pump-room';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[ss-full-schema-line]',
  template: '',
  styleUrls: []
})
export class LineComponent implements OnInit {

  constructor() { }

  @Input()
  public allowEdit = false;

  @HostBinding('attr.x1')
  public get x1(): number {
    return this.drawable.x;
  }

  @HostBinding('attr.y1')
  public get y1(): number {
    return this.drawable.y;
  }

  @HostBinding('attr.x2')
  public get x2(): number {
    return (this.drawable as DrawableLine).x2;
  }

  @HostBinding('attr.y2')
  public get y2(): number {
    return (this.drawable as DrawableLine).y2;
  }

  @HostBinding('class.selected')
  public get isSelected(): boolean {
    return this.drawable.isSelected;
  }

  @Input()
  public drawable: Drawable;


  @Output()
  public selected: EventEmitter<Drawable> = new EventEmitter();

  @Output()
  public remove: EventEmitter<Drawable> = new EventEmitter();

  @HostListener('click')
  public onClick(ev: MouseEvent) {
    if (!this.allowEdit) {
      return;
    }
    if (!this.drawable.isSelected) {
      this.selected.emit(this.drawable);
    }
  }

  @HostListener('dblclick')
  public onDblClick(ev: MouseEvent) {
    if (!this.allowEdit) {
 return;
}
    if (this.drawable.isSelected) {
      this.remove.emit(this.drawable);
    }
  }

  ngOnInit() {
  }

}
