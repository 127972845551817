import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { merge } from 'rxjs';
import { ignoreElements, mergeMap, tap } from 'rxjs/operators';
import { forkJoinWithProgress } from 'src/app/fork-join-with-progress.rx';
import { isArray } from '../../../../../../common';
import { ModalRouteService } from '../../modal-route.service';
import { ProgressOfObservablesService } from '../progress-of-observables.service';

@Component({
  selector: 'ss-progress-of-observables-modal',
  templateUrl: './progress-of-observables-modal.component.html',
  styleUrls: ['./progress-of-observables-modal.component.sass']
})
export class ProgressOfObservablesModalComponent implements OnInit {

  public isVisible = false;
  public percent = 0;

  constructor(
    private ar: ActivatedRoute,
    private mr: ModalRouteService,
    private ps: ProgressOfObservablesService
  ) { }

  ngOnInit(): void {
    const data = this.ps.getLastObservableCollection();
    if (
      !isArray(data)
      || data.length <= 0
    ) {
      this.mr.closeModal();
    }
    const visibilityTimeout = setTimeout(() => {
      this.isVisible = true;
    }, 1000);
    forkJoinWithProgress(data).pipe(
      mergeMap(([result, progress]) => merge(
        progress.pipe(
          tap((value) => {
            this.percent = value;
          }),
          ignoreElements()
        ),
        result
      ))
    )
    .subscribe((asd) => {
      clearTimeout(visibilityTimeout);
      this.mr.closeModal();
    }, (err) => {
      clearTimeout(visibilityTimeout);
      this.mr.closeModal();
    });
  }

  moveToBackground() {
    this.mr.closeModal();
  }

}
