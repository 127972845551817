import { TimedChartConfigSeries } from '../..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { D3DrawerData } from '../../models/charts/d3-drawer-data.model';
import { getSeriesColor } from './get-series-color.function';

export const drawGridHorizontal = (
  state: D3DrawerChartState,
  data: D3DrawerData,
  series: TimedChartConfigSeries,
  seriesIndex: number
): D3DrawerChartState => {
  const yAxisScale = state.generatedValuesStorage.get(`series${seriesIndex}YAxisScale`);
  if(!yAxisScale) {
    return state;
  }

  let gridG = state.generatedCanvas.select(`.grid-series${seriesIndex}`);
  if (gridG.empty()) {
    gridG = state.generatedCanvas.append('g')
      .attr('class', `grid-series${seriesIndex}`);
  }

  const color = series.primaryColor || getSeriesColor(series, seriesIndex);

  const marginLeft = state.config.yAxisType !== 'none' ? 85 : 0;
  const marginRight = state.viewBoxWidth - (['double','double-with-line' ].includes(state.config.yAxisType) ? 85 : 20);


  const ticks = yAxisScale.ticks(4);
  ticks.shift();
  ticks.pop();
  const colorLines = gridG.selectAll('line.color-grid-lines')
    .data(ticks);
  colorLines.exit().remove();
  colorLines.enter()
    .append('line')
    .attr('class', 'color-grid-lines')
    .attr('x1', marginLeft)
    .attr('x2', marginRight)
    .attr('y1', d => yAxisScale(d))
    .attr('y2', d => yAxisScale(d))
    .attr('stroke', color)
    .attr('stroke-width', 0.5)
    .attr('opacity', d => d === 0 ? 0.8 : 0.2);
  colorLines.merge(colorLines)
    .attr('x1', marginLeft)
    .attr('x2', marginRight)
    .attr('y1', d => yAxisScale(d))
    .attr('y2', d => yAxisScale(d));

  return state;
};
