import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UserNotesService } from './user-notes.service';
import { UserNote } from 'src/app/shared/models';
import { Subscription } from 'rxjs';
import { ModalRouteService } from 'src/app/application/modal-route.service';
import { isArray } from '../../../../../../common';

@Component({
  selector: 'ss-user-notes',
  templateUrl: './user-notes.component.html',
  styleUrls: ['./user-notes.component.sass']
})
export class UserNotesComponent implements OnInit, OnDestroy {

  constructor(
    private mrs: ModalRouteService,
    private uns: UserNotesService
  ) { }

  @Input()
  public get context(): string {
    return this._context;
  }
  public set context(v: string) {
    this._context = v;
    this.update();
  }
  private _context: string;

  @Output()
  public notesCount: EventEmitter<number> = new EventEmitter();
  notes: UserNote[] = [];

  userNotesUpdatedSubscription: Subscription;

  private update() {
    this.notes = this.uns.findByContextString(this.context);

    this.notesCount.emit(isArray(this.notes) ? this.notes.length : 0);
  }
  ngOnInit() {
    this.userNotesUpdatedSubscription = this.uns.updated.subscribe(() => {
      this.update();
    });
    this.update();
  }

  edit(id: number) {
    this.mrs.showModalRoute('user-notes-editor-modal/' + this.context + '/' + (id ? id : ''));
  }

  showHistory(note: UserNote) {
    this.notes.forEach(n => {
      n.isHistoryShown = false;
    });
    note.isHistoryShown = true;
  }

  ngOnDestroy() {
    if (this.userNotesUpdatedSubscription) {
      this.userNotesUpdatedSubscription.unsubscribe();
    }
  }

}
