import { Component, Input, OnInit } from '@angular/core';
import { MeteoStation } from 'src/app/shared/models';

@Component({
  selector: 'ss-meteo-station-preview',
  templateUrl: './meteo-station-preview.component.html',
  styleUrls: []
})
export class MeteoStationPreviewComponent {

  @Input()
  meteoStation: MeteoStation;

  @Input()
  direction: string;

}
