import { Injectable } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';

import { User, PreAuthLoginData } from './shared/models';
import { AuthClientService, SocketioClientService } from './http-clients';

// Created only for local session sharing. On "real" session sharing and webrowker data pull will be dropped
window.addEventListener('storage', (event) => {
  if (!event) {
    event = (window as any)['event'];
  }
  if (!event.newValue) {
 return;
}
  if (event.key === 'getSessionStorage') {
    localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
    localStorage.removeItem('sessionStorage');
  } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
    const data = JSON.parse(event.newValue);
    for (const key of Object.keys(data)) {
      sessionStorage.setItem(key, data[key]);
    }
  }
}, false);
if (!sessionStorage.length) {
  localStorage.setItem('getSessionStorage', 'foobar');
  localStorage.removeItem('getSessionStorage');
}
// Created only for local session sharing. On "real" session sharing and webrowker data pull will be dropped

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private authClientService: AuthClientService, private socketClient: SocketioClientService) { }

  private user: User = null;
  private apiKey: string = null;

  public lastLoginUrl: string = null;

  private unauthorizedSubscription: Subscription;
  private apiKeyNotifier: any = null;
  private setApiKey(apiKey: string) {
    this.apiKey = apiKey;

    console.log('Bearer ' + apiKey);

    if (this.apiKeyNotifier != null) {
      clearInterval(this.apiKeyNotifier);
      this.apiKeyNotifier = null;
    }

    if (this.unauthorizedSubscription != null) {
      this.unauthorizedSubscription.unsubscribe();
      this.unauthorizedSubscription = null;
    }

    if (apiKey) {
      this.unauthorizedSubscription = this.socketClient.unauthorized.subscribe(() => {
        this.logout();
      });
      this.socketClient.markUserActivity(apiKey);
      this.apiKeyNotifier = setInterval(() => {
        this.socketClient.markUserActivity(apiKey);
      }, 10000);
    }
  }

  public fetchPreLoginData(): Observable<PreAuthLoginData> {
    return this.authClientService.fetchPreLoginData();
  }

  validateOauthApiKey(apiKey: string): Observable<boolean> {
    sessionStorage.setItem('apiKey', apiKey);

    return this.validateApiKey();
  }

  validateApiKey(): Observable<boolean> {

    return new Observable<boolean>((subscriber) => {
      setTimeout(() => { // Created only for local session sharing. On "real" session sharing and webrowker data pull will be dropped
        const apiKey: string = sessionStorage.getItem('apiKey');

        if (!apiKey) {
          subscriber.next(false);
          subscriber.complete();
          return;
        }

        this.authClientService.validateApiKey(apiKey).subscribe((data) => {
          if (data) {
            this.user = User.create(data);
            this.setApiKey(apiKey);

            subscriber.next(true);
          } else {
            subscriber.next(false);
          }
          subscriber.complete();
        }, (data) => {
          subscriber.error(data);
        });
      }, 500);
    });
  }

  logout() {
    this.user = null;
    this.setApiKey(null);

    sessionStorage.removeItem('apiKey');

    window.location.reload();
  }

  tryLogIn(email: string, password: string): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      this.authClientService.tryLogin(email, password).subscribe((data) => {
        if (data['user'] && data['api_key']) {
          this.user = User.create(data['user']);
          this.setApiKey(data['api_key']);

          sessionStorage.setItem('apiKey', this.apiKey);

          subscriber.next(true);
        } else {
          subscriber.next(false);
        }
        subscriber.complete();
      }, (err) => {
        subscriber.error(err['status']);
      });
    });
  }

  setUser(user: User) {
    this.user = user;
  }

  getUser(): User {
    return this.user;
  }

  getApiKey(): string {
    if (this.isAuthenticated()) {
      return this.apiKey;
    }

    return null;
  }

  isAuthenticated(): boolean {
    return this.user != null;
  }

  isAuthorized(role: string): boolean {
    if (this.isAuthenticated()) {
      return this.user.hasRole(role);
    }

    return false;
  }
}
