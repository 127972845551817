import { isArray, User as UserInterface } from '../../../../../common';
import { UserNotificationsSchedule } from './user-notifications-schedule.model';

export class User implements UserInterface {

  public static readonly ROLE_OBSERVER = 'ROLE_OBSERVER';
  public static readonly ROLE_OPERATOR = 'ROLE_OPERATOR';
  public static readonly ROLE_ADMIN = 'ROLE_ADMIN';
  public static readonly ROLE_SUPERADMIN = 'ROLE_SUPERADMIN';

  public static readonly AVAILABLE_ROLES = [ User.ROLE_OBSERVER, User.ROLE_OPERATOR, User.ROLE_ADMIN, User.ROLE_SUPERADMIN ];

  public id: number;
  public email: string;
  public name?: string;
  public surname?: string;
  public phone?: string;
  public avatarUrl?: string;
  public validTo?: string;
  public passwordValidTo?: string;
  public roles?: string[];
  public isEnabled: boolean;
  public created?: boolean;
  public isLoggedIn?: boolean;
  public isVisibleOnPreloginList?: boolean;
  public isAllowedToLogInRemotely?: boolean;
  public temporaryPassword?: string;

  public lastActivity?: { activeFrom: string; activeTo: string; remoteAddress: string };

  public notificationsSchedule: UserNotificationsSchedule;

  public static create(data: any): User {
    const user = Object.assign(new User(), data);
    user.notificationsSchedule = UserNotificationsSchedule.create(data['notificationsSchedule']);
    return user;
  }

  public hasRole(roleToCheck: string): boolean {
    const roles = isArray(this.roles) ? this.roles : [];
    let rolesCheck = [];
    switch (roleToCheck) {
      case User.ROLE_OBSERVER:
        rolesCheck = User.AVAILABLE_ROLES;
        break;
      case User.ROLE_OPERATOR:
        rolesCheck = [User.ROLE_OPERATOR, User.ROLE_ADMIN, User.ROLE_SUPERADMIN];
        break;
      case User.ROLE_ADMIN:
        rolesCheck = [User.ROLE_ADMIN, User.ROLE_SUPERADMIN];
        break;
      case User.ROLE_SUPERADMIN:
        rolesCheck = [User.ROLE_SUPERADMIN];
        break;
    }

    return roles.filter(value => rolesCheck.includes(value)).length > 0;
  }
}

