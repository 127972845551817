import { Injectable } from '@angular/core';
import { SnowCannon } from 'src/app/shared/models';
import { Resolve, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ResortService } from 'src/app/resort.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SnowCannonIsFromListDataResolver implements Resolve<boolean> {
  constructor(
    private rs: ResortService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Observable<never> {
    return !!route.queryParamMap.get('fromList');
  }
}
