import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ResortService } from 'src/app/resort.service';
import {
  ImageOverlay,
  featureGroup,
  polygon as leafletPolygon,
  Polygon as LeafletPolygon,
  FeatureGroup,
  Draw,
  DrawMap,
  drawLocal,
  LeafletEvent
} from 'leaflet';
import { Resort, ResortArea } from 'src/app/shared/models';
import { EditorMapControllerService } from '../editor-map-controller.service';

import difference from '@turf/difference';
import { getCoords, getGeom } from '@turf/invariant';
import union from '@turf/union';
import unkinkPolygon from '@turf/unkink-polygon';
import {
  polygon,
  MultiPolygon,
  Feature,
  Polygon,
  Position,
} from '@turf/helpers';
import { isArray } from '../../../../../../common';

class ARTuple {
  public p: LeafletPolygon;
  public a: ResortArea;

  public static create(ar: ResortArea): ARTuple {
    const ret = new ARTuple();
    ret.a = Object.assign(new ResortArea(), ar);

    ret.updatePolyline();

    return ret;
  }

  public updatePolyline() {
    this.p = leafletPolygon(this.a.mapBoundaries, { opacity: 0.5, color: 'white' });
  }
}

@Component({
  selector: 'ss-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.sass']
})
export class EditAreasComponent implements OnInit, OnDestroy {

  constructor(
    private mapCtrl: EditorMapControllerService,
    private resortService: ResortService
  ) {
    drawLocal.draw.handlers.polygon.tooltip.cont = null;
    drawLocal.draw.handlers.polygon.tooltip.end = null;
    drawLocal.draw.handlers.polygon.tooltip.start = null;
    drawLocal.edit.handlers.edit.tooltip.text = null;
  }

  polylines: FeatureGroup<LeafletPolygon>;
  image: ImageOverlay;
  resort: Resort;

  areas: ARTuple[] = [];

  addingPoly = false;
  addingHole = false;
  editingAll = false;

  imageOpacity = 0.5;
  polygonDrawer: any;

  holeDrawer: any;

  editedArea: ARTuple;
  editedName: string;
  editedMapBoundaries: any;

  unionAll(parts: Feature<Polygon, {
    [name: string]: any;
  }>[]): Feature<Polygon|MultiPolygon, {
    [name: string]: any;
  }> {
    let all: Feature<Polygon|MultiPolygon, {
      [name: string]: any;
    }> = parts.pop();
    parts.forEach(p => {
      all = union(all, p);
    });
    return all;
  }

  ngOnInit() {
    this.resort = this.resortService.getResort();
    this.areas = this.resortService.getResortAreas().map(er => ARTuple.create(er));

    this.image = this.mapCtrl.createProjectImage(this.resort.mapBoundaries);
    this.imageOpacityChanged();

    this.updatePolylines();

    this.mapCtrl.map.on('draw:created', (e: LeafletEvent) => {
      if (this.editedArea && (this.polygonDrawer || this.holeDrawer)) {
        const polyCoords: Position[][][] = [];

        const newGeo = e.layer.toGeoJSON();
        const newGeoCoords: Position[][][] = [];
        const kinkRes = unkinkPolygon(newGeo);
        kinkRes.features.forEach(fp => {
          newGeoCoords.push(getCoords(fp));
        });
        const newGeoArray = newGeoCoords.map(p => polygon(p));

        const oldGeo = getGeom(this.editedArea.p.toGeoJSON());
        if (oldGeo.type === 'MultiPolygon') {
          oldGeo.coordinates.forEach(c => {
            if (isArray(c) && c.length > 0 && isArray(c[0]) && c[0].length > 0) {
              polyCoords.push(c);
            }
          });
        } else {
          if (
            isArray(oldGeo.coordinates)
            && oldGeo.coordinates.length > 0
            && isArray(oldGeo.coordinates[0])
            && oldGeo.coordinates[0].length > 1
          ) {
            polyCoords.push(oldGeo.coordinates);
          }
        }
        const polyArray = polyCoords.map(p => polygon(p));

        let result: Feature<Polygon | MultiPolygon> = null;
        if (this.polygonDrawer) {
          result = this.unionAll([...polyArray, ...newGeoArray]);
        } else if (this.holeDrawer) {
          result = difference(this.unionAll(polyArray), this.unionAll(newGeoArray));
        }

        if (result) {
          this.editedArea.a.mapBoundaries = this.fixCoords(result);

          this.editedArea.updatePolyline();
          this.updatePolylines();
          this.editedArea.p.setStyle({opacity: 1});
        }
      }
    });
  }

  fixCoords(feature: Feature<any>): any {
    feature.geometry.coordinates.forEach(v => {
      v.forEach(w => {
        if (isArray(w[0])) {
          w.forEach(x => {
            const f = x.shift();
            x.push(f);
          });
        } else {
          const f = w.shift();
          w.push(f);
        }
      });
    });

    return feature.geometry.coordinates;
  }
  addPoly() {
    this.addingPoly = true;

    this.polygonDrawer = new Draw.Polygon(this.mapCtrl.map as DrawMap, {
      repeatMode: true
    });

    this.polygonDrawer.enable();
  }
  addHole() {
    this.addingHole = true;
    this.holeDrawer = new Draw.Polygon(this.mapCtrl.map as DrawMap, {
      shapeOptions: {
        color: 'red'
      }
    });

    this.holeDrawer.enable();
  }

  editAll() {
    if (this.editedArea.p['_rings'].length <= 1) {
      this.editingAll = true;
      this.editedArea.p['editing'].enable();
    }
  }

  apply() {
    if (this.polygonDrawer) {
      this.polygonDrawer.disable();
      this.polygonDrawer = null;
    }
    if (this.editingAll) {
        const polyCoords: Position[][][] = [];

        const newGeo = this.editedArea.p.toGeoJSON();

        const kinkRes = unkinkPolygon(newGeo);
        kinkRes.features.forEach(fp => {
          polyCoords.push(getCoords(fp));
        });

        const polyArray = polyCoords.map(p => polygon(p));
        const polyUnion = this.unionAll(polyArray);

        this.editedArea.a.mapBoundaries = this.fixCoords(polyUnion);
        this.editedArea.p['editing'].disable();

        this.editedArea.updatePolyline();
        this.updatePolylines();
        this.editedArea.p.setStyle({opacity: 1});
    }

    this.addingPoly = false;
    this.addingHole = false;
    this.editingAll = false;
  }
  edit(area: ARTuple) {
    this.cancelEdit();
    this.editedArea = area;
    this.editedName = area.a.symbol;
    setTimeout(() => {
      this.editedArea.p.setStyle({
        opacity: 1
      });
    }, 100);
  }

  saveEdit() {
    this.editedArea.a.symbol = this.editedName;
    if (this.editedMapBoundaries) {
      this.editedArea.a.mapBoundaries = this.editedMapBoundaries;
    }
    this.cancelEdit();
  }

  cancelEdit() {
    if (this.editedArea) {
      this.editedArea.updatePolyline();
      this.editedArea.p.setStyle({
        opacity: 0.5
      });
    }
    this.editedName = null;
    this.editedMapBoundaries = null;
    this.editedArea = null;
    this.updatePolylines();
  }

  add() {
    const area = new ResortArea();
    area.update({
      name: 'The New One',
      mapBoundaries: []
    });

    const areaT = ARTuple.create(area);
    this.areas.push(areaT);

    this.updatePolylines();

    this.edit(areaT);
  }

  save() {
    if (this.editedArea) {
      return;
    }

    this.areas.forEach(area => {
      console.log(area.a.symbol + ' ' + JSON.stringify(area.a.mapBoundaries));
    });
  }

  imageOpacityChanged() {
    this.image.setOpacity(this.imageOpacity);
  }

  private updatePolylines() {
    this.polylines = featureGroup(this.areas.map(cp => cp.p));
    this.mapCtrl.updateWorkingLayer(featureGroup([
      this.image,
      this.polylines
    ]));
  }

  ngOnDestroy() {
    this.mapCtrl.resetWorkingLayer();
    this.mapCtrl.map.off('draw:created');
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      if (this.editedArea) {
        this.cancelEdit();
        event.preventDefault();
      }
    } else if (event.code === 'KeyS' && event.ctrlKey && event.shiftKey) {
      this.save();
    } else if (event.code === 'KeyS' && event.ctrlKey) {
      if (this.editedArea) {
        this.saveEdit();
        event.preventDefault();
      }
    }
  }
}
