import { Component, Input, OnInit } from '@angular/core';
import { ModalRouteService } from 'src/app/application/modal-route.service';
import { ConnectionPoint } from 'src/app/shared/models';

@Component({
  selector: 'ss-area-configuration-info-hydrant',
  templateUrl: './hydrant.component.html',
  styleUrls: []
})
export class HydrantComponent implements OnInit {

  @Input()
  public connectionPoint: ConnectionPoint;

  constructor(private hes: ModalRouteService) { }

  ngOnInit(): void {
  }

  public goToHydrantConfig(id: string) {
    this.hes.showModalRoute('connection-point-config-modal/' + id);
  }
}
