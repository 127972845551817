import { Component, Input, EventEmitter, Output, KeyValueDiffers, DoCheck } from '@angular/core';
import { SnowCannon } from 'src/app/shared/models';
import { AuthService } from '../../../../../auth.service';

@Component({
  selector: 'ss-snow-cannon-position-editor-horizontal',
  templateUrl: './horizontal.component.html',
  styleUrls: ['./horizontal.component.sass']
})
export class SnowCannonPositionEditorHorizontalComponent implements DoCheck {

  private _snowCannon: SnowCannon;
  @Input()
  public get snowCannon(): SnowCannon {
    return this._snowCannon;
  }
  public set snowCannon(val: SnowCannon) {
    this._snowCannon = val;
    this.updateDisplayParams();
  }

  constructor(
    private as: AuthService,
    differs: KeyValueDiffers
  ) {
    this.differ = differs.find({}).create();
  }

  private _currentMapBearing: number;
  @Input()
  public get currentMapBearing(): number {
    return this._currentMapBearing;
  }
  public set currentMapBearing(v: number) {
    if(this._currentMapBearing !== v) {
      this._currentMapBearing = v;
      this.updateDisplayParams();
    }
  }

  @Input()
  public areaCenterX: number;

  @Input()
  public areaCenterY: number;

  @Output()
  public executeCommand: EventEmitter<any> = new EventEmitter();

  public baseAngle = 0;
  public directionAngle = 0;
  public oscillationAngle = 15;

  public moveOscillationInProgress = false;

  public movePositionInProgress = false;

  differ: any;
  putOscillationInProgress() {
    if(!this.as.isAuthorized('ROLE_OPERATOR')) {
      return;
    }
    this.moveOscillationInProgress = true;
  }

  moveOscillationIndicator(e) {
    if (!this.moveOscillationInProgress) {
      return;
    }

    let pageX = e.pageX;
    let pageY = e.pageY;
    if(
      e.type === 'touchmove'
      && e.touches
      && e.touches.length > 0
    ) {
      pageX = e.touches[0].pageX;
      pageY = e.touches[0].pageY;
    }

    let deg = Math.atan2(pageX - this.areaCenterX, pageY - this.areaCenterY - 80);
    deg = Math.round(deg * 180 / Math.PI);
    deg = deg + this.baseAngle + 180;
    deg = Math.abs(deg + this.directionAngle);
    deg = (15 * Math.round(deg / 15)) % 360;

    if (deg > 180) {
      deg = 360 - deg;
    }

    if (deg < 15) {
      deg = 15;
    } else if (deg > 120) {
      deg = 120;
    }

    if (this.oscillationAngle !== deg) {
      this.oscillationAngle = deg;
    }
  }
  putPositionInProgress() {
    if(!this.as.isAuthorized('ROLE_OPERATOR')) {
      return;
    }
    this.movePositionInProgress = true;
  }
  movePositionIndicator(e) {
    if (!this.movePositionInProgress) {
      return;
    }

    let pageX = e.pageX;
    let pageY = e.pageY;
    if(
      e.type === 'touchmove'
      && e.touches
      && e.touches.length > 0
    ) {
      pageX = e.touches[0].pageX;
      pageY = e.touches[0].pageY;
    }

    let deg = Math.atan2(pageX - this.areaCenterX, pageY - this.areaCenterY - 80);
    deg = Math.round(360 - deg * 180 / Math.PI);
    deg = deg - this.baseAngle + 360 + 180;
    deg = deg % 360;
    deg = 5 * Math.round((deg) / 5);

    if (deg < 10) {
      deg = 10;
    } else if (deg > 350) {
      deg = 350;
    }

    if (this.directionAngle !== deg) {
      this.directionAngle = deg;
    }
  }

  releaseMoveProgress() {
    if (this.moveOscillationInProgress) {
      this.setOscillation();
    }
    this.moveOscillationInProgress = false;
    if (this.movePositionInProgress) {
      this.setPosition();
    }
    this.movePositionInProgress = false;
  }

  private updateDisplayParams() {
    if(!this.snowCannon) {
      return;
    }
    this.oscillationAngle = this.snowCannon.oscillationTargetAngle || 0;
    this.baseAngle =
      + (this.snowCannon.conenctionPointRef ? this.snowCannon.conenctionPointRef.baseOrientationOffsetFromNorth : 0)
      + this.currentMapBearing;
    if (this.snowCannon.CAP_CHANGE_HORIZONTAL_POSITION_ANGLE) {
      this.directionAngle = this.snowCannon.tubeHorizontalSetAngle || 0;
    } else {
      this.directionAngle = 180;
    }
  }

  ngDoCheck() {
    const changes = this.differ.diff(this.snowCannon);

    if (changes) {
      let updateNeeded = false;

      changes.forEachChangedItem(r => {
        updateNeeded = updateNeeded
          || ['oscillationSetAngle', 'tubeHorizontalSetAngle'].indexOf(r.key) >= 0;
      });

      if (updateNeeded) {
        this.updateDisplayParams();
      }
    }
  }

  cancelMovement() {
    this.executeCommand.emit({
      commandHorizontalPositioningOff: true,
      commandOscillationOff: true
    });
  }

  setOscillation() {
    this.executeCommand.emit({
      setOscillationSetAngle: this.oscillationAngle
    });

  }
  setPosition() {
    this.executeCommand.emit({
      commandHorizontalPositioningOn: true,
      setTubeHorizontalSetAngle: this.directionAngle
    });
  }
}
