import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TimedChartConfig, TimedChartConfigSeries, TimedChartData } from '../../../../../../../common';
import { ResortStatisticsService } from '../../../../resort-statistics.service';
import { SettingsService } from '../../../../settings.service';

@Component({
  selector: 'ss-d3-power-draw-chart',
  templateUrl: './power-draw-chart.component.html',
  styleUrls: ['./power-draw-chart.component.sass']
})
export class PowerDrawChartComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public device: { id: string };
  @Output()
  public durationChanged: EventEmitter<number> = new EventEmitter();
  public data: TimedChartData;
  public config: TimedChartConfig;
  public duration = 12;

  private statsUpdatedSubscribtion: Subscription;
  private dataChange: EventEmitter<any> = new EventEmitter();

  public constructor(
    private el: ElementRef,
    private settings: SettingsService,
    private resortStatisticsService: ResortStatisticsService
  ) {
    this.duration = this.settings.get('defaultChartPeriod');

    this.dataChange.pipe(debounceTime(10)).subscribe(() => {
      const series: TimedChartConfigSeries[] = [
        {
          ... new TimedChartConfigSeries(),
          seriesType: 'line-w-rounded-end',
          seriesName: 'power draw',
          valueFieldName: 'powerDraw',
          primaryColor: '#00A1E4',
          fixedMin: 0,
          grid: true,
          unit: 'powerDraw'
        },
      ];
      if(this.device['limitPowerDraw'] > 0) {
        series.push({
          ...new TimedChartConfigSeries(),
          seriesType: 'line-stepped',
          seriesName: `power limit`,
          labelFieldName: 'statusLabels',
          valueFieldName: 'limitPowerDraw',
          primaryColor: '#f00',
          fixedMin: 0,
          unit: 'powerDraw'
        });
      }
      this.config = {
        ...new TimedChartConfig(),
        xAxisType: 'bottom',
        yAxisType: 'single',
        series
      };
      this.data = this.fetchChartDataById();
    });
  }

  public setDuration(val: number) {
    this.duration = val;
    this.durationChanged.emit(val);
    this.updateData();
  }

  protected fetchChartDataById(): TimedChartData {
    const duration = this.duration || this.settings.get('defaultChartPeriod');

    const rawData = this.resortStatisticsService.getStats(this.device.id, duration);
    const preparedData = {
      labels: rawData.aggregatedLabels,
      powerDraw: rawData.powerDraw,
    };

    return new TimedChartData(preparedData);
  }

  private updateData() {
    this.dataChange.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.device
      || changes.duration
    ) {
      this.updateData();
    }
  }

  ngOnInit() {
    this.statsUpdatedSubscribtion = this.resortStatisticsService.statsUpdated.subscribe(() => {
      this.updateData();
    });

    this.updateData();
  }

  ngOnDestroy() {
    if (this.statsUpdatedSubscribtion) {
      this.statsUpdatedSubscribtion.unsubscribe();
    }
  }

}
