import { isNullOrUndefined, TimedChartConfigSeries } from '../..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { D3DrawerData } from '../../models/charts/d3-drawer-data.model';

const zeroNans = (d: any): number => isNaN(d) || isNullOrUndefined(d) ? 0 : d;

export const drawSeriesBarWRoundedTop = (
  state: D3DrawerChartState,
  data: D3DrawerData,
  series: TimedChartConfigSeries,
  seriesIndex: number,
  seriesIndexInType: number
): D3DrawerChartState => {
  const xAxisScale = state.generatedValuesStorage.get('xAxisScale');
  const barsColorScale = state.generatedValuesStorage.get(`series${seriesIndex}ColorScale`);
  const barsYAxisScale = state.generatedValuesStorage.get(`series${seriesIndex}YAxisScale`);
  const xAxisBandScale = state.generatedValuesStorage.get('xAxisBandScale');
  const t = state.generatedCanvas.transition();
  const animationTime = series.animationTime || state.config.animationTime || 0;

  let halfWidthOfBar = xAxisBandScale.bandwidth() * 0.5;
  halfWidthOfBar = halfWidthOfBar > 3 ? 3 : halfWidthOfBar;

  let initialDuration = false;
  let seriesG = state.generatedCanvas.select(`.series${seriesIndex}`);
  if (seriesG.empty()) {
    seriesG = state.generatedCanvas.append('g')
      .attr('opacity', 0)
      .attr('class', `bars-g series${seriesIndex}`);
    initialDuration = true;
  }
  seriesG.transition().duration(initialDuration ? 0 : animationTime)
    .attr('opacity', series.opacity);

  const bars = seriesG
    .selectAll('.bar')
    .data(data, (d: { labels }) => d.labels);

  let yOffset = 15;
  if(state.config.xAxisType === 'invisible') {
    yOffset = 5;
  }

  bars.exit()
    .remove();
  const barG = bars.enter()
    .append('g')
    .attr('class', 'bar')
    .attr('transform', d => 'translate(' + (xAxisScale(series.labelFieldName ? +d[series.labelFieldName] : d.labels) - 3) + ',0)');
  barG
    .append('rect')
    .attr('fill', (d, i, c) => i === c.length - 1 ? barsColorScale(zeroNans(d[series.valueFieldName])) : '#ffffff')
    .attr('fill-opacity', (d, i, c) => i === c.length - 1 ? 0.9 : 0.1)
    .attr('stroke-width', 0)
    .attr('width', 2 * halfWidthOfBar)
    .attr('x', 0)
    .attr('height', d => state.viewBoxHeight - yOffset - barsYAxisScale(zeroNans(d[series.valueFieldName])) || 0 )
    .attr('y', d => (barsYAxisScale(zeroNans(d[series.valueFieldName]))) || 0 );

  barG
    .append('circle')
    .attr('fill', d =>
      isNaN(+d[series.valueFieldName]) || isNullOrUndefined(d[series.valueFieldName])
      ? '#ee2142'
      : barsColorScale(+d[series.valueFieldName])
    )
    .attr('stroke-width', 0)
    .attr('cx', halfWidthOfBar)
    .attr('cy', d => barsYAxisScale(zeroNans(d[series.valueFieldName])) || 0 )
    .attr('r', d =>
      isNaN(+d[series.valueFieldName]) || isNullOrUndefined(d[series.valueFieldName])
      ? 1
      : halfWidthOfBar + (state.config.xAxisType === 'invisible' ? 0 : 1)
    );
  bars.merge(bars)
    .attr('transform', d => 'translate(' + (
      xAxisScale(series.labelFieldName ? +d[series.labelFieldName] : d.labels) - halfWidthOfBar
    ) + ',0)');
  bars.merge(bars)
    .select('rect')
    .attr('height', d => state.viewBoxHeight - yOffset - barsYAxisScale(zeroNans(d[series.valueFieldName])) || 0 )
    .attr('y', d => (barsYAxisScale(zeroNans(d[series.valueFieldName]))) || 0 )
    .attr('fill', (d, i, c) => i === c.length - 1 ? barsColorScale(zeroNans(d[series.valueFieldName])) : '#ffffff')
    .attr('fill-opacity', (d, i, c) => i === c.length - 1 ? 0.9 : 0.1);
  bars.merge(bars)
    .select('circle')
    .attr('fill', d =>
      isNaN(+d[series.valueFieldName]) || isNullOrUndefined(d[series.valueFieldName])
      ? '#ee2142'
      : barsColorScale(+d[series.valueFieldName])
    )
    .attr('cy', d => barsYAxisScale(zeroNans(d[series.valueFieldName])) || 0 )
    .attr('r', d =>
      isNaN(+d[series.valueFieldName]) || isNullOrUndefined(d[series.valueFieldName])
      ? 1
      : halfWidthOfBar + (state.config.xAxisType === 'invisible' ? 0 : 1)
    );

  return state;
};
