import { D3 } from '..';
import { isNullOrUndefined, TimedChartConfigSeries } from '../..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { D3DrawerData, D3DrawerDataRow } from '../../models/charts/d3-drawer-data.model';
import { getSeriesColor } from './get-series-color.function';

export const drawSeriesLine = (
  state: D3DrawerChartState,
  data: D3DrawerData,
  series: TimedChartConfigSeries,
  seriesIndex: number,
  seriesIndexInType: number
): D3DrawerChartState => {
  const xAxisScale = state.generatedValuesStorage.get('xAxisScale');
  const linesYAxisScale = state.generatedValuesStorage.get(`series${seriesIndex}YAxisScale`);
  const t = state.generatedCanvas.transition();
  const animationTime = series.animationTime || state.config.animationTime || 0;

  const color = series.primaryColor || getSeriesColor(series, seriesIndexInType);
  state.generatedValuesStorage.set(`series${seriesIndex}BaseColor`, color);

  let initialDuration = false;
  let seriesG = state.generatedCanvas.select(`.series${seriesIndex}`);
  if (seriesG.empty()) {
    seriesG = state.generatedCanvas.append('g')
      .attr('opacity', 0)
      .attr('class', `lines-g series${seriesIndex}`);
    seriesG.append('path')
      .style('pointer-events', 'none')
      .attr('opacity', 0)
      // .attr('transform', 'scale(1 0)')
      .attr('transform-origin', 'center bottom')
      .attr('fill', 'transparent')
      .attr('stroke-width', 3);
    initialDuration = true;
  }

  seriesG.transition().duration(initialDuration ? 0 : animationTime)
    .attr('opacity', series.opacity);

  seriesG.select('path')
    .datum(data)
    // .transition(t)
    //   .duration(initialDuration ? 0 : animationTime)
    //   .attr('transform', 'scale(1 0)')
    //   .attr('opacity', 0)
    //   .transition()
      .attr('stroke', color)
      .attr('d', D3.line<D3DrawerDataRow>()
        .curve(D3.curveBasis)
        .defined(d => !isNullOrUndefined(d[series.valueFieldName]) && !isNaN(+d[series.valueFieldName]))
        .x(d => xAxisScale((series.labelFieldName ? +d[series.labelFieldName] : d.labels) + 30 * 60))
        .y(d => linesYAxisScale(+d[series.valueFieldName]))
      )
      // .transition()
      // .duration(animationTime)
      .attr('opacity', series.opacity)
      // .attr('transform', 'scale(1 1)')
    ;
  return state;
};
