import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ResortService } from 'src/app/resort.service';
import { isArray, toSearchString } from '../../../../../../../common';
import { MeteoDevice } from '../../../models';

@Component({
  selector: 'ss-meteo-conditions-source-selector',
  templateUrl: './source-selector.component.html',
  styleUrls: ['./source-selector.component.sass']
})
export class MeteoConditionsSourceSelectorComponent implements OnInit, OnDestroy {

  constructor(private rs: ResortService) { }

  @Input()
  public sourceId: string;

  @Input()
  public exceludeIds: string[];

  @Input()
  public limitToResortArea: string;

  @Input()
  public limitToWorkingArea: string;

  @Output()
  public sourceIdChanged: EventEmitter<string> = new EventEmitter();

  private _searchString: string;
  public get searchString(): string {
    return this._searchString;
  }
  public set searchString(v: string) {
    this._searchString = v;
    this.searchEvent.emit(v);
  }

  public searchEventSubscription: Subscription;
  public searchEvent: EventEmitter<string> = new EventEmitter();

  public searchResults: MeteoDevice[] = [];
  public displayResults: MeteoDevice[] = [];
  public availableDevices: MeteoDevice[] = [];
  public hiddenResults = 0;

  private preformSearch(search: string, limitResults: number = 6) {
    search = toSearchString(search);
    if(this.availableDevices.length === 0) {
      let cannons = this.rs.getSnowCannons();
      if(this.limitToWorkingArea) {
        cannons = cannons
          .filter(v =>
            v.conenctionPointRef && v.conenctionPointRef.workingArea === this.limitToWorkingArea
          );
      } else if(this.limitToResortArea) {
        cannons = cannons
          .filter(v =>
            v.conenctionPointRef && v.conenctionPointRef.resortArea === this.limitToResortArea
          );
      }

      let col = [... this.rs.getMeteoStations(), ... cannons];
      if(isArray(this.exceludeIds) && this.exceludeIds.length > 0) {
        col = col.filter(v => !this.exceludeIds.includes(v.id));
      }
      this.availableDevices.push(... col);
    }

    this.searchResults = this.availableDevices.filter(d => toSearchString(d.symbol).includes(search));
    this.displayResults = this.searchResults.slice(0, limitResults);
    this.hiddenResults = this.searchResults.length - limitResults;
  }

  public showAll() {
    this.preformSearch(this.searchString, 10000);
  }

  public select(id: string) {
    this.sourceId = id;
    this._searchString = '';
    this.searchResults = [];
    this.displayResults = [];
    this.sourceIdChanged.emit(id);
  }

  ngOnInit(): void {
    this.searchEventSubscription = this.searchEvent.pipe(debounceTime(300)).subscribe(v => {
      this.preformSearch(v);
    });
  }

  ngOnDestroy(): void {
    if(this.searchEventSubscription) {
      this.searchEventSubscription.unsubscribe();
    }
  }

}
