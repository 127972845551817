<div class="form-row" [ngClass]="{ 'is-required': markIsRequired }">
  <div class="form-label" *ngIf="!hideLabel"></div>
  <div class="form-control checkbox" [ngClass]="{invalid: (invalid | async), 'checkbox--disabled': disabled}">
    <label [attr.for]="identifier" class="checkbox__label">
      <input
        [disabled]="disabled"
        class="checkbox__input"
        autocomplete="off"
        type="checkbox"
        [(ngModel)]="value"
        [name]="name"
        [id]="identifier" />
      <span class="checkbox__check">
        <svg [ngStyle]="{ 'display': value ? 'block' : 'none' }">
          <use xlink:href='#icon-check'/>
        </svg>
      </span>
      <span class="ml-2">
        <span #labelContent><ng-content select="form-label"></ng-content></span>
        <ng-container *ngIf="!labelContent.innerHTML.trim()">{{ label }}</ng-container>
      </span>
    </label>
  </div>
</div>
