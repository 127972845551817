import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportResult } from '../../../../../../../common';

@Component({
  selector: 'ss-reporting-single-report-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.sass']
})
export class ReportingSingleReportPageFooterComponent {

  public saveDefinition(saveAsCopy: boolean = false) {
    this.saveRequest.emit(saveAsCopy);
  }

  public removeDefinition() {
    this.removeRequest.emit(true);
  }

  public startEditMode() {
    this.enterEditMode.emit(true);
  }

  public stopEditMode() {
    this.enterEditMode.emit(false);
  }

  @Output()
  public saveRequest: EventEmitter<any> = new EventEmitter();

  @Output()
  public removeRequest: EventEmitter<any> = new EventEmitter();

  @Output()
  public enterEditMode: EventEmitter<any> = new EventEmitter();

  @Input()
  public isEditMode = false;

  @Input()
  public isLoading = false;

  @Input()
  public result: ReportResult;

}
