import { Injectable } from '@angular/core';
import { SnowCannon } from 'src/app/shared/models';
import { Resolve, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ResortService } from 'src/app/resort.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class SnowCannonData {
  snowCannon: SnowCannon;
}

@Injectable({
  providedIn: 'root'
})
export class SnowCannonDataResolverService implements Resolve<SnowCannonData> {
  constructor(
    private rs: ResortService,
    private router: Router
  ) { }

  getData(areaId: string, snowCannonId: string): SnowCannonData | Observable<SnowCannonData> | Observable<never> {
    if (this.rs.isReady) {
      return this.getRaw(areaId, snowCannonId);
    }
    return this.rs.onReady.pipe(map(x => this.getRaw(areaId, snowCannonId)));
  }

  private getRaw(areaId: string, snowCannonId: string) {
    const area = this.rs.getResortArea(areaId);
    if (!area || !area.snowCannonRefs.has(snowCannonId)) {
      this.router.navigate(['/application/resort']);
    }
    const snowCannon = area.snowCannonRefs.get(snowCannonId);

    return {
      snowCannon
    };
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): SnowCannonData | Observable<SnowCannonData> | Observable<never> {
    return this.getData(route.paramMap.get('id'), route.paramMap.get('snowCannonId'));
  }
}
