<perfect-scrollbar>
  <div class="areas">
    <div class="areas__header-wrapper px-2">
      <div class="areas__photomap" [ngStyle]="{
        'background-image': perspectiveMapImage ? 'url(\'' + perspectiveMapImage + '\')' : 'url(assets/images/placeholder_bkg.png)',
        'background-size':'cover',
        'background-position': '50% 0',
        'background-repeat': 'no-repeat'
      }"></div>
      <div class="areas__header">
        <p *ngIf="recentAreas && recentAreas.length > 0" class="fs-26 color-white font-bold px-4 my-2" i18n="Ostatnio przeglądane trasy|Tytuł listy ostatnio przeglądanych tras@@areasMapHeader">Ostatnio przeglądane trasy</p>
        <div class="d-flex flex-wrap" *ngIf="recentAreas && recentAreas.length > 0">
          <div *ngFor="let area of recentAreas" class="area-column">
            <div [routerLink]="['/application/resort/area', area.id, 'map']" class="area-preview">
              <div class="area-preview__header font-bold" [ngClass]="{'area-preview__header--warning': !area.operationMode}">
                <strong>{{ area.symbol }}</strong>
                <span *ngIf="area.countByBreakdown() > 0" class="poser">
                  <ss-badge color="danger" size="small">{{ area.countByBreakdown() }}</ss-badge>
                </span>
              </div>
              <div class="area-preview__content">
                <div class="devices-states devices-states--small">
                  <div class="devices-states__state" i18n-title="Pracuje|Nazwa stanu urządzenia@@stateWorking" title="Pracuje"><!-- devices-states__state--main -->
                    {{ area.countByWorking() }}
                    <ng-container *ngIf="area.countByWorkingInManualMode() > 0">
                      ({{ area.countByWorkingInManualMode() }})
                    </ng-container>
                  </div>
                  <div class="devices-states__state" i18n-title="Czeka na warunki|Nazwa stanu urządzenia@@stateAwaitingForConditions" title="Czeka na warunki"><!-- devices-states__state--main -->
                    {{ area.countByAwaitingForConditions() }}
                  </div>
                  <div class="devices-states__state" i18n-title="Gotowość|Nazwa stanu urządzenia@@stateReadyToWork" title="Gotowość"><!-- devices-states__state--main -->
                    {{ area.countByReadyToWork() }}
                  </div>
                  <div class="devices-states__state" i18n-title="Czuwanie|Nazwa stanu urządzenia@@stateStandingBy" title="Czuwanie"><!-- devices-states__state--main -->
                    {{ area.countByStandingBy() }}
                  </div>
                  <div class="devices-states__state" i18n-title="Uruchamianie|Nazwa stanu urządzenia@@stateStarting" title="Uruchamianie">
                    {{ area.countByStarting() }}
                  </div>
                  <div class="devices-states__state" i18n-title="Usypianie|Nazwa stanu urządzenia@@stateSnoozing" title="Usypianie">
                    {{ area.countBySnoozing() }}
                  </div>
                  <div class="devices-states__state" i18n-title="Zatrzymywanie|Nazwa stanu urządzenia@@stateStopping" title="Zatrzymywanie">
                    {{ area.countByStopping() }}
                  </div>
                  <div class="devices-states__state" i18n-title="Przygotowanie|Nazwa stanu urządzenia@@statePreparing" title="Przygotowanie">
                    {{ area.countByPreparing() }}
                  </div>
                </div>
              </div>
              <div class="area-preview__chart">
                <div class="row-forced align-items-center">
                  <div class="col-4">
                    <p class="color-white white-space-nowrap text-right pr-2">
                      <strong class="color-green fs-20">{{ area|convertedValue:'snowProductionVolume' }}</strong>
                      <span class="fs-14 color-light-grey">{{ area|convertedUnit:'snowProductionVolume' }}</span>
                    </p>
                  </div>
                  <div class="col-8">
                    <div #miniBarChartContainer1>
                      <ss-d3-linked-mini-bar-chart [viewBoxWidth]="miniBarChartContainer1.offsetWidth" tag="compare-areas" [duration]="duration" [device]="area"></ss-d3-linked-mini-bar-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main-info">
          <div class="main-info__col p-3">
            <input [(ngModel)]="areasFilter" (keyup)="areasExpanded = true; moveToSearchResults()" type="text" i18n-placeholder="Szukaj trasy|Placeholder w wyszukiwarce tras@@areasMapSearch" placeholder="Szukaj trasy" class="input main-info__search my-6">
          </div>
          <div class="main-info__col p-3 d-flex">
            <div class="pr-6" style="min-width: 300px">
              <p class="fs-12 text-uppercase color-light-grey" i18n="Produkcja śniegu|Nazwa pola przedstawiającego wielkość produkcji śniegu@@snowProduction">Produkcja śniegu</p>
              <p class="white-space-nowrap mt-3">
                <span class="fs-42 color-green font-bold  mb-1">{{ resort|convertedValue:'snowProductionVolume' }}</span>
                <span class="fs-20 color-light-grey">{{ resort|convertedUnit:'snowProductionVolume' }}</span>
              </p>
            </div>
            <div style="width: 600px">
              <ss-d3-snow-production-and-twb-chart [device]="resort" (durationChanged)="setDuration($event)"></ss-d3-snow-production-and-twb-chart>
            </div>
          </div>
          <div class="main-info__col p-3">
            <p class="fs-12 text-uppercase color-light-grey" i18n="Zużycie wody|Nazwa pola przedstawiajacego wielkość zużycia wody@@waterConsumption">Zużycie wody</p>
            <div class="d-flex align-items-center my-4">
              <svg class="main-info__icon">
                <use xlink:href='#icon-waves'/>
              </svg>
              <span class="fs-20 color-white pl-1">{{ resort|convertedValue:'computedWaterFlow' }}</span>
              <span class="fs-14 color-light-grey pl-1">{{ resort|convertedUnit:'computedWaterFlow' }}</span>
            </div>
          </div>
          <div class="main-info__col p-3">
            <p class="fs-12 text-uppercase color-light-grey" i18n="Zużycie energii|Nazwa pola przedstawiającego wielkość zużycia energii@@energyConsumption">Zużycie energii</p>
            <div class="d-flex align-items-center my-4">
              <svg class="main-info__icon main-info__icon--s">
                <use xlink:href='#icon-thunder'/>
              </svg>
              <span class="fs-20 color-white pl-1">{{ (resort.computedPowerDraw || 0)|number:'1.0-0' }}</span>
              <span class="fs-14 color-light-grey pl-1">kW</span>
            </div>
          </div>
          <div class="main-info__col p-3">
            <p class="fs-12 text-uppercase color-light-grey" i18n="Zbiorniki wodne|Nazwa pola przedstawiającego poziom wód w zbiornikach wodnych@@waterReservoirs">Zbiorniki wody</p>
            <div>
              <ss-water-tank *ngFor="let waterReservoir of waterReservoirs"
                [waterReservoir]="waterReservoir"
                size="extra-small" minLevel="0"></ss-water-tank>
            </div>
          </div>
          <button class="main-info__button" [ngClass]="{'main-info__button--rotated': areasExpanded }" (click)="areasExpanded = !areasExpanded; moveToSearchResults()">
            <svg>
              <use xlink:href='#icon-chevron-down'/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="areas__body d-flex flex-wrap" [ngClass]="{'areas__body--expanded': areasExpanded }">
      <div *ngFor="let area of filteredAreas()" class="area-column">
        <div [routerLink]="['/application/resort/area', area.id, 'map']" class="area-preview">
          <div class="area-preview__header font-bold" [ngClass]="{'area-preview__header--warning': !area.operationMode}">
            <strong>{{ area.symbol }}</strong>
            <span *ngIf="area.countByBreakdown() > 0" class="poser">
              <ss-badge color="danger" size="small">{{ area.countByBreakdown() }}</ss-badge>
            </span>
          </div>
          <div class="area-preview__content">
            <div class="devices-states devices-states--small">
              <div class="devices-states__state" title="Pracuje" i18n-title="Pracuje|Nazwa stanu urządzenia@@stateWorking"><!-- devices-states__state--main -->
                {{ area.countByWorking() }}
                <ng-container *ngIf="area.countByWorkingInManualMode() > 0">
                  ({{ area.countByWorkingInManualMode() }})
                </ng-container>
              </div>
              <div class="devices-states__state" title="Czeka na warunki" i18n-title="Czeka na warunki|Nazwa stanu urządzenia@@stateAwaitingForConditions"><!-- devices-states__state--main -->
                {{ area.countByAwaitingForConditions() }}
              </div>
              <div class="devices-states__state" title="Gotowość" i18n-title="Gotowość|Nazwa stanu urządzenia@@stateReadyToWork"><!-- devices-states__state--main -->
                {{ area.countByReadyToWork() }}
              </div>
              <div class="devices-states__state" title="Czuwanie" i18n-title="Czuwanie|Nazwa stanu urządzenia@@stateStandingBy"><!-- devices-states__state--main -->
                {{ area.countByStandingBy() }}
              </div>
              <div class="devices-states__state" title="Uruchamianie" i18n-title="Uruchamianie|Nazwa stanu urządzenia@@stateStarting">
                {{ area.countByStarting() }}
              </div>
              <div class="devices-states__state" title="Usypianie" i18n-title="Usypianie|Nazwa stanu urządzenia@@stateSnoozing">
                {{ area.countBySnoozing() }}
              </div>
              <div class="devices-states__state" title="Zatrzymywanie" i18n-title="Zatrzymywanie|Nazwa stanu urządzenia@@stateStopping">
                {{ area.countByStopping() }}
              </div>
              <div class="devices-states__state" title="Przygotowanie" i18n-title="Przygotowanie|Nazwa stanu urządzenia@@statePreparing">
                {{ area.countByPreparing() }}
              </div>
            </div>
          </div>
          <div class="area-preview__chart">
            <div class="row-forced align-items-center">
              <div class="col-4">
                <p class="color-white white-space-nowrap text-right pr-2">
                  <strong class="color-green fs-20">{{ area|convertedValue:'snowProductionVolume' }}</strong>
                  <span class="fs-14 color-light-grey">{{ area|convertedUnit:'snowProductionVolume' }}</span>
                </p>
              </div>
              <div class="col-8">
                <div #miniBarChartContainer2>
                  <ss-d3-linked-mini-bar-chart [viewBoxWidth]="miniBarChartContainer2.offsetWidth" tag="compare-areas" [duration]="duration" [device]="area"></ss-d3-linked-mini-bar-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</perfect-scrollbar>

