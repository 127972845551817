import { Component, OnInit } from '@angular/core';
import { ModalRouteService } from 'src/app/application/modal-route.service';
import { ActivatedRoute } from '@angular/router';
import { UsersClientService } from 'src/app/http-clients';
import { User } from 'src/app/shared/models';

@Component({
  selector: 'ss-reset-user-password',
  templateUrl: './reset-user-password.component.html',
  styleUrls: ['./reset-user-password.component.sass']
})
export class ResetUserPasswordComponent implements OnInit {

  public lock = false;
  public user: User = null;
  public temporaryPassword: string = null;

  constructor(
    private ucs: UsersClientService,
    private ar: ActivatedRoute,
    private mr: ModalRouteService) { }

  ngOnInit() {
    this.ar.paramMap.subscribe(params => {
      const userId = parseInt(params.get('userId'), 10);

      this.lock = true;
      this.ucs.get(userId).subscribe(user => {
        this.lock = false;
        this.user = user;
      }, () => {
        this.lock = false;
        this.close();
      });
    });
  }

  reset() {
    this.lock = true;
    this.ucs.resetPassword(this.user.id).subscribe((userWithResettedPassword) => {

      this.temporaryPassword = userWithResettedPassword.temporaryPassword;

      this.lock = false;
    }, () => {
      this.lock = false;
    });
  }

  close() {
    this.mr.closeModal();
  }

}
