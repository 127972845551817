import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ss-wind-speed-and-direction',
  templateUrl: './wind-speed-and-direction.component.html',
  styleUrls: ['./wind-speed-and-direction.component.sass']
})
export class WindSpeedAndDirectionComponent implements OnInit {

  constructor() { }

  @Input()
  windSpeed: number;

  @Input()
  windDirection: number;

  ngOnInit() {
  }

}
