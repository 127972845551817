export class DocumentationFiles {
    public files: DocumentationFile[];
}
export class DocumentationFile {
    public title: string;
    public fileHash: string;
    public permission: string;
    public devices: string[] = [];
    public format: string;

    public fileUrl?: string;
}
