import { Component, Input, EventEmitter, Output, KeyValueDiffers, DoCheck } from '@angular/core';
import { SnowCannon } from 'src/app/shared/models';
import { AuthService } from '../../../../../auth.service';

@Component({
  selector: 'ss-snow-cannon-position-editor-inclination',
  templateUrl: './inclination.component.html',
  styleUrls: ['./inclination.component.sass']
})
export class SnowCannonPositionEditorInclinationComponent  implements DoCheck {

  @Input()
  public get snowCannon(): SnowCannon {
    return this._snowCannon;
  }
  public set snowCannon(val: SnowCannon) {
    this._snowCannon = val;
    if (this._snowCannon) {
      this.updateDisplayParams();
    }
  }

  constructor(
    private as: AuthService,
    differs: KeyValueDiffers
  ) {
    this.differ = differs.find({}).create();
  }

  @Input()
  public areaCenterX: number;

  @Input()
  public areaCenterY: number;
  private _snowCannon: SnowCannon;

  @Output()
  public executeCommand: EventEmitter<any> = new EventEmitter();

  inclinationAngle = 30;

  moveInclinationInProgress = false;

  differ: any;
  putInclinationInProgress() {
    if(!this.as.isAuthorized('ROLE_OPERATOR')) {
      return;
    }
    this.moveInclinationInProgress = true;
  }
  moveInclinationIndicator(e) {
    if (!this.moveInclinationInProgress) {
      return;
    }

    let pageX = e.pageX;
    let pageY = e.pageY;
    if(
      e.type === 'touchmove'
      && e.touches
      && e.touches.length > 0
    ) {
      pageX = e.touches[0].pageX;
      pageY = e.touches[0].pageY;
    }

    let deg = Math.atan2(pageY - this.areaCenterY - 80, pageX - this.areaCenterX);
    deg = -deg * 180 / Math.PI;

    if (deg < 0) {
      deg = 0;
    } else if (deg > 42) {
      deg = 42;
    }

    deg = 7 * Math.round(deg / 7);

    if (this.inclinationAngle !== deg) {
      this.inclinationAngle = deg;
    }
  }


  releaseMoveProgress() {
    if (this.moveInclinationInProgress) {
      this.setPosition();
    }
    this.moveInclinationInProgress = false;

  }

  private updateDisplayParams() {
    this.inclinationAngle = this.snowCannon.tubeVerticalSetAngle;
  }

  ngDoCheck() {
    const changes = this.differ.diff(this.snowCannon);

    if (changes) {
      let updateNeeded = false;

      changes.forEachChangedItem(r => {
        updateNeeded = updateNeeded
          || ['tubeVerticalSetAngle'].indexOf(r.key) >= 0;
      });

      if (updateNeeded) {
        this.updateDisplayParams();
      }
    }
  }

  cancelMovement() {
    this.executeCommand.emit({
      commandVerticalPositioningOff: true,
    });
  }

  setPosition() {
    this.executeCommand.emit({
      commandVerticalPositioningOn: true,
      setTubeVerticalSetAngle: this.inclinationAngle
    });
  }
}
