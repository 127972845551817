<ss-page>
  <ng-container *ngIf="result">
    <ss-reporting-single-report-page-header
      (updateResult)="updateResult($event)"
      (saveRequest)="saveReportDefinition($event)"
      (enterEditMode)="isEditMode = $event"
      (renameRequest)="renameReport()"
      (exportRequest)="export($event)"
      [result]="resultView || result"
      [isEditMode]="isEditMode"
      [isLoading]="isLoading">
    </ss-reporting-single-report-page-header>

    <ss-reporting-single-report-page-toolbar
      (updateResult)="updateResult($event)"
      (updateView)="updateView($event)"
      [result]="resultView || result"
      [isLoading]="isLoading">
    </ss-reporting-single-report-page-toolbar>
  </ng-container>

  <div class="loader" [ngClass]="{ 'is-loading': isLoading }">
    <div class="spinner"></div>
  </div>

  <div class="data-display"
    *ngIf="resultView"
    (window:keyup.arrowleft)="updateView({ displayTo: resultView.prevDisplayDelim })"
    (window:keyup.arrowright)="updateView({ displayTo: resultView.nextDisplayDelim })"
    (window:keyup.end)="updateView({ displayTo: resultView.lastDisplayDelim })"
    (window:keyup.home)="updateView({ displayTo: resultView.firstDisplayDelim })"
    (window:keyup.pagedown)="updateView({ displayTo: resultView.nextWeekDisplayDelim })"
    (window:keyup.pageup)="updateView({ displayTo: resultView.prevWeekDisplayDelim })"
    (window:keyup.shift.arrowleft)="updateView({ displayTo: resultView.prevDayDisplayDelim })"
    (window:keyup.shift.arrowright)="updateView({ displayTo: resultView.nextDayDisplayDelim })"
    (window:keyup.shift.pagedown)="updateView({ displayTo: resultView.nextMonthDisplayDelim })"
    (window:keyup.shift.pageup)="updateView({ displayTo: resultView.prevMonthDisplayDelim })"
    [ngClass]="{ 'is-loading': isLoading }">

    <ss-reporting-single-report-time-range-toolbar
      (updateResult)="updateResult($event)"
      (updateView)="updateView($event)"
      [resultView]="resultView"
      [refresher]="refresher"
      [selectedDate]="selectedDate"
      [viewBoxWidth]="offsetWidth"
      [showPreviewBox]="true"
      [isLoading]="isLoading">
    </ss-reporting-single-report-time-range-toolbar>

    <div class="data-container">

      <ng-container *ngIf="!isEditMode">
        <div class="data-overlay" (resized)="onResize($event)" [debounceTime]="100" >
          <ss-reporting-single-time-selection-toolbar
            *ngIf="!isLoading"
            [from]="resultView.displayFrom"
            [to]="resultView.displayTo"
            [viewBoxWidth]="offsetWidth"
            [viewBoxHeight]="offsetHeight"
            (selectedDate)="setSelectedDate($event)">
          </ss-reporting-single-time-selection-toolbar>
        </div>
      </ng-container>

      <div class="drop-container-holder">
        <div class="drop-container row"
          [dragula]="DG_PARTS"
          (dragulaModelChange)="reorderResultParts($event)"
          [dragulaModel]="resultView.parts">

          <ng-container *ngFor="let part of resultView.parts; let i = index">

            <ss-reporting-single-part-display
              [partView]="resultView.partViews[i]"
              [isEditMode]="isEditMode"
              [selectedDate]="selectedDate"
              [secondaryOffsetWidth]="secondaryOffsetWidth"
              (renamePartRequest)="renamePart(part)"
              (editPartRequest)="editPart(part)"
              (removePartRequest)="removePart(part)"
            ></ss-reporting-single-part-display>

          </ng-container>

        </div>
      </div>

    </div>

    <div class="row" *ngIf="isEditMode">
      <button class="btn flex-grow-1 btn-link"
        (click)="addPart()"
        [disabled]="isLoading">
        <svg>
          <use xlink:href="#icon-plus" />
        </svg>
        <ng-container i18n="Dodaj wykres lub podsumowanie|Przycisk Dodaj wykres lub podsumowanie@@report-detail-btn-add-part">Dodaj wykres lub podsumowanie</ng-container>
      </button>
    </div>


    <ss-reporting-single-report-time-range-toolbar
      (updateView)="updateView($event)"
      [resultView]="resultView"
      [isLoading]="isLoading">
    </ss-reporting-single-report-time-range-toolbar>

    <ss-reporting-single-report-page-footer
      (saveRequest)="saveReportDefinition($event)"
      (removeRequest)="removeReportDefinition()"
      (enterEditMode)="isEditMode = $event"
      [result]="resultView"
      [isLoading]="isLoading"
      [isEditMode]="isEditMode">
    </ss-reporting-single-report-page-footer>

  </div>
</ss-page>