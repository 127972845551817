import { Injectable, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResortArea } from 'src/app/shared/models';
import { AreasData } from '../areas/areas-data-resolver.service';
import { SettingsService } from 'src/app/settings.service';
import { ResortService } from 'src/app/resort.service';

@Component({
  selector: 'ss-areas-recent',
  templateUrl: './areas-recent.component.html',
  styleUrls: ['./areas-recent.component.sass']
})
export class AreasRecentComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private rs: ResortService
  ) { }

  recentAreas: ResortArea[] = [];
  areas: ResortArea[];

  areasFilter = '';

  moveToSearchResults() {
  }
  filteredAreas(): ResortArea[] {
    return this.areas.filter(a => a.symbol.toLocaleLowerCase()
      .includes(this.areasFilter.toLocaleLowerCase()))
      .sort((a, b) => a.symbol.localeCompare(b.symbol));
  }

  ngOnInit() {
    this.route.data
    .subscribe((data: { areasData: AreasData }) => {
      this.areas = data.areasData.areas;

      this.recentAreas = [];
      const recentAreaIds = this.settingsService.get<string[]>('recentAreaIds');
      recentAreaIds.forEach(recentId => {
        const found = this.rs.getResortArea(recentId);
        if (found) {
          this.recentAreas.push(found);
        }
      });
    });
  }

}
