import { Directive, ElementRef, EventEmitter, OnInit, Output, OnDestroy, Input } from '@angular/core';
import { ResizeSensor } from 'css-element-queries';
import { debounceTime } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';

export class ResizedEvent {
  constructor(
    readonly element: ElementRef,
    readonly newWidth: number,
    readonly newHeight: number,
    readonly oldWidth: number,
    readonly oldHeight: number
  ) {}
}
@Directive({
  // eslint-disable-next-line
  selector: '[resized]'
})
export class ResizedDirective implements OnInit, OnDestroy {

  @Output()
  readonly resized = new EventEmitter<ResizedEvent>();

  private readonly internalResizedEmitter = new EventEmitter<ResizedEvent>();
  private internalResizedEventSubscription: Subscription;

  private _debounceTime = 0;
  @Input()
  public get debounceTime(): number {
    return this._debounceTime;
  }
  public set debounceTime(v: number) {
    if (isNaN(v) || v < 0) {
      v = 0;
    }

    this._debounceTime = v;

    this.updateInternalSubscription();
  }

  private oldWidth: number;
  private oldHeight: number;

  private resizeSensor: ResizeSensor;

  constructor(private readonly element: ElementRef) {
  }

  ngOnInit() {
    // only initialize resize watching if sensor is available
    if (ResizeSensor) {
      this.resizeSensor = new ResizeSensor(this.element.nativeElement, () => this.onResized());
    }
    this.updateInternalSubscription();
  }

  updateInternalSubscription() {
    if (this.internalResizedEventSubscription) {
      this.internalResizedEventSubscription.unsubscribe();
    }
    let p: Observable<ResizedEvent> = this.internalResizedEmitter;
    if (this.debounceTime > 0) {
      p = p.pipe(debounceTime(this.debounceTime));
    }
    this.internalResizedEventSubscription = p.subscribe((el) => {
      this.resized.emit(el);
    });
  }

  ngOnDestroy() {
    if (this.resizeSensor) {
      this.resizeSensor.detach();
    }
  }

  private onResized() {
    const newWidth = this.element.nativeElement.clientWidth;
    const newHeight = this.element.nativeElement.clientHeight;

    if (newWidth === this.oldWidth && newHeight === this.oldHeight) {
      return;
    }

    const event = new ResizedEvent(
      this.element,
      newWidth,
      newHeight,
      this.oldWidth,
      this.oldHeight
    );

    this.oldWidth = this.element.nativeElement.clientWidth;
    this.oldHeight = this.element.nativeElement.clientHeight;

    this.internalResizedEmitter.emit(event);
  }
}
