<ss-page>
    <ss-page-header>
        <ss-page-header-title>Resort</ss-page-header-title>
        <ss-page-button-group>
            <button
              (click)="save()"
              [disabled]="
                editingBoundsRect
                || editingMapCenter
              " class="btn btn-primary">Save <kbd>Ctrl+Shift+S</kbd></button>
            <a class="btn btn-default" [routerLink]="['/application', 'editor']">Go back</a>
        </ss-page-button-group>
    </ss-page-header>
    <ss-page-panel panelTitle="Project image opacity">
        <form-range [min]="0" [max]="1" [step]="0.05" [(ngModel)]="imageOpacity" (change)="imageOpacityChanged()" [hideLabel]="true"></form-range>
    </ss-page-panel>
    <ss-page-panel panelTitle="Map center">
        <button
            [disabled]="editingBoundsRect"
            *ngIf="!editingMapCenter"
            (click)="editMapCenter()"
            class="btn btn-default w-100">edit map center</button >
        <button
            [disabled]="editingBoundsRect"
            *ngIf="editingMapCenter"
            (click)="setMapCenter()"
            class="btn btn-primary w-100">set map center</button >
        <pre *ngIf="resortCenter">{{ resortCenter | json }}</pre>
    </ss-page-panel>
    <ss-page-panel panelTitle="Map bounds">
        <button
            [disabled]="editingMapCenter"
            *ngIf="!editingBoundsRect"
            (click)="editMapBounds()"
            class="btn btn-default w-100">edit map bounds</button>
        <button
            [disabled]="editingMapCenter"
            *ngIf="editingBoundsRect"
            (click)="setMapBounds()"
            class="btn btn-primary w-100">set map bounds</button>
        <pre *ngIf="resortBounds">{{ resortBounds | json }}</pre>

    </ss-page-panel>
</ss-page>
