import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeviceSystemType, ReportExportFormat, ReportResult } from '../../../../../../../common';
import { AbstractDevice } from '../../../../shared/models/abstract-device.model';
import { ReportingService } from '../../reporting.service';

@Component({
  selector: 'ss-reporting-single-report-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.sass']
})
export class ReportingSingleReportPageHeaderComponent {

  public constructor(
    private rds: ReportingService
  ) {}

  public saveDefinition(saveAsCopy: boolean = false) {
    this.saveRequest.emit(saveAsCopy);
  }

  public removeDefinition() {
    this.removeRequest.emit(true);
  }

  public startEditMode() {
    this.enterEditMode.emit(true);
  }

  public stopEditMode() {
    this.enterEditMode.emit(false);
  }

  @Output()
  public exportRequest: EventEmitter<ReportExportFormat> = new EventEmitter();

  @Output()
  public saveRequest: EventEmitter<any> = new EventEmitter();

  @Output()
  public removeRequest: EventEmitter<any> = new EventEmitter();

  @Output()
  public renameRequest: EventEmitter<any> = new EventEmitter();

  @Output()
  public updateResult: EventEmitter<any> = new EventEmitter();

  @Output()
  public enterEditMode: EventEmitter<any> = new EventEmitter();

  @Input()
  public isEditMode = false;

  @Input()
  public isLoading = false;

  @Input()
  public get result(): ReportResult {
    return this._result;
  };
  public set result(v: ReportResult) {
    const deviceIds: Map<string, string[]> = new Map();
    let type: DeviceSystemType;
    v.parts.forEach(p => {
      p.series.forEach(s => {
        if(!deviceIds.has(s.subjectType)) {
          deviceIds.set(s.subjectType, []);
        }
        if(!s.subjectId) {
          const dev = this.rds.getDevidesBySubjectType(s.subjectType);
          if(dev.length <= 0) {
            throw Error('invalid device type ' + s.subjectType + ' or no devices of that type');
          }
          s.subjectId = dev[0].id;
        }
        if(deviceIds.get(s.subjectType).indexOf(s.subjectId) < 0) {
          deviceIds.get(s.subjectType).push(s.subjectId);
          type = s.subjectType;
        }
      });
    });

    if(deviceIds.size === 1) {
      this.isSingleType = true;
      this.isSingleDevice = deviceIds.get(type).length === 1;

      this.selectedDevices = this.rds.getDevidesBySubjectType(type)
        .sort((a, b) => {
          const anum = parseInt(`${a.symbol}`, 10);
          const bnum = parseInt(`${b.symbol}`, 10);
          return isNaN(anum) || isNaN(bnum) ? `${a.symbol}`.localeCompare(`${b.symbol}`) : anum - bnum;
        });
      this.selectedDevice = this.selectedDevices.find(d => d.id === deviceIds.get(type)[0]);


      const i = this.selectedDevices.indexOf(this.selectedDevice);
      const l = this.selectedDevices.length;
      if (l > 1) {
        if (i === 0) {
          this.prevDeviceId = this.selectedDevices[l - 1].id;
          this.nextDeviceId = this.selectedDevices[1].id;
        } else if (i === l - 1) {
          this.prevDeviceId = this.selectedDevices[l - 2].id;
          this.nextDeviceId = this.selectedDevices[0].id;
        } else {
          this.prevDeviceId = this.selectedDevices[i - 1].id;
          this.nextDeviceId = this.selectedDevices[i + 1].id;
        }
      }
    }
    this._result = v;
  }
  private _result: ReportResult;

  public isSingleType = false;
  public isSingleDevice = false;

  public selectedDevices: AbstractDevice[] = [];
  public selectedDevice: AbstractDevice;

  public prevDeviceId: string;
  public nextDeviceId: string;

  public devicessListExpanded = false;

  public changeSelectedSingleDevice(id: string) {
    this.devicessListExpanded = false;
    this.updateResult.emit({
      parts: this.result.parts.map(p => ({
          ...p,
          series: p.series.map(s => ({
              ...s,
              subjectId: this.selectedDevice && this.selectedDevice.id === s.subjectId
                ? id
                : s.subjectId
            }))
        }))
    });
  }

  public renameReport() {
    this.renameRequest.emit();
  }

  public export(format: ReportExportFormat) {
    this.exportRequest.emit(format);
  }

}
