export type ResortConfigurationChecks =
'hasSnowmakingCapabilities'
|'hasAutomaticSnowmakingCapabilities'
|'hasPumprooms'
|'hasPowerSources'
|'hasAirSources'
|'hasRemoteNotifications'
|'hasUserManagement'
|'hasReports'
|'hasAuditTrail';

export class ResortConfiguration {
  hasSnowmakingCapabilities = false;
  hasAutomaticSnowmakingCapabilities = false; // not yet used
  hasPumprooms = false;
  hasPowerSources = false;
  hasAirSources = false;
  hasRemoteNotifications = false; // not yet used
  hasUserManagement = false;
  hasReports = false;
  hasAuditTrail = false;

  public static create(data: any): ResortConfiguration {
    return Object.assign(new ResortConfiguration(), data);
  }

}
