import { Component, OnInit, Input } from '@angular/core';
import { isString, translateDeviceAlert } from '../../../../../../common';

@Component({
  selector: 'ss-translated-device-alert',
  template: '{{ parsedAlert }}',
  styleUrls: []
})
export class TranslatedDeviceAlertComponent {

  public parsedAlert: string;

  private _alert = '';
  @Input()
  get alert(): string {
    return this._alert;
  }
  set alert(a: string) {
    if (isString(a)) {
      this._alert = a;

      this.parsedAlert = translateDeviceAlert(this._alert);
    }
  }

}
