import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DrawableComponent, LevelIndicator } from 'src/app/shared/models/pump-room';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[ss-full-schema-level-indicator]',
  templateUrl: './level-indicator.component.html',
  styleUrls: ['./level-indicator.component.sass']
})
export class LevelIndicatorComponent implements OnInit {

  constructor() { }

  @Input()
  public allowEdit = false;

  @Input()
  public drawable: DrawableComponent<LevelIndicator>;

  ngOnInit(): void {
  }

  @HostBinding('class.breakdown')
  get breakdown(): boolean {
    return this.hasSensorData && ['connection-lost', 'breakdown'].includes(this.drawable.component['computedStatus']);
  }

  @HostBinding('class.sensored')
  get hasSensorData(): boolean {
    return this.drawable && this.drawable.component['hasSensorData'];
  }

}
