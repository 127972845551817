import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgSelectModule } from '@ng-select/ng-select';

import { BadgeComponent } from './components/badge/badge.component';
import { CannonsGroupComponent } from './components/cannons-group/cannons-group.component';
import { CannonsGroupsComponent } from './components/cannons-groups/cannons-groups.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ControlButtonComponent } from './components/control-button/control-button.component';
import { DebugTableComponent } from './components/debug-table/debug-table.component';
import { DeviceImageComponent } from './components/device-image/device-image.component';
import { DocumentationFilesComponent } from './components/documentation-files/documentation-files.component';
import { MapButtonsComponent } from './components/map-buttons/map-buttons.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { PopoverComponent } from './components/popover/popover.component';
import { RangeDatepickerComponent } from './components/range-datepicker/range-datepicker.component';
import { SnowingAngleComponent } from './components/snowing-angle/snowing-angle.component';
import { SnowQualityComponent } from './components/snow-quality/snow-quality.component';
import { TimeInputComponent } from './components/time-input/time-input.component';
import { TranslatedDeviceAlertComponent } from './components/translated-device-alert/translated-device-alert.component';
import { TranslatedDeviceMountTypeComponent } from './components/translated-device-mount-type/translated-device-mount-type.component';
import { TranslatedDeviceTypeComponent } from './components/translated-device-type/translated-device-type.component';
import { TranslatedDeviceTypeSymbolComponent } from './components/translated-device-type-symbol/translated-device-type-symbol.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { UserNotesComponent } from './components/user-notes/user-notes.component';
import { UserRolesComponent } from './components/user-roles/user-roles.component';
import { WaterTankComponent } from './components/water-tank/water-tank.component';
import { WindSpeedAndDirectionComponent } from './components/wind-speed-and-direction/wind-speed-and-direction.component';

import { ConvertedUnitPipe } from './converted-unit.pipe';
import { ConvertedValuePipe } from './converted-value.pipe';

import {
  SnowCannonIconComponent,
  SnowCannonStatusComponent,
  PumproomMultiStatusComponent,
  PumproomStatusComponent,
} from './components/device-statuses';

import {
  SnowCannonComponentButtonComponent,
  SnowCannonComponentButtonHeaterComponent,
  SnowCannonComponentButtonOscillatorComponent,
  SnowCannonComponentButtonLampComponent,
  SnowCannonComponentButtonHydraulicPumpComponent,
  SnowCannonComponentButtonAirCompressorComponent,
  SnowCannonComponentButtonMainFanComponent,
  SnowCannonComponentButtonHydrantDriveComponent,
  SnowCannonComponentButtonNozzleRowComponent,
} from './components/snow-cannon-component-buttons';

import {
  TabsComponent,
  TabComponent,
  NotificationsTabComponent,
  DocumentationTabComponent,
  UserNotesTabComponent,
} from './components/tabs';

import {
  FormTextareaComponent,
  FormTextComponent,
  FormCheckboxComponent,
  FormSelectComponent,
  FormSelectOptionComponent,
  FormErrorsDisplayComponent,
  FormRangeComponent,
  FormEmailComponent,
  FormPasswordComponent,
  FormNumberComponent,
  RequiredCheckboxValidatorDirective,
  MinNumberValidatorDirective,
  MaxNumberValidatorDirective,
  FormLabelComponent,
  FormErrorComponent,
} from './forms';

import {
  SnowCannonBasePositionIndicatorComponent,
  SnowCannonPositionIndicatorComponent,
  CompassComponent,
  SnowCannonPositionEditorComponent,
  SnowCannonPositionEditorInclinationComponent,
  SnowCannonPositionEditorHorizontalComponent,
  SnowCannonPositionEditorManualComponent,
} from './components/position-indicators';

import {
  VisualTimeRangeSelectorComponent,
  SimpleVisualTimeRangeSelectorComponent,
} from './components/visual-time-range-selector';

import {
  CannonPreviewComponent,
  ConnectionPointPreviewComponent,
  MeteoStationPreviewComponent,
} from './components/map-hover-previews';

import {
  PageComponent,
  PageButtonGroupComponent ,
  PageFooterComponent,
  PageHeaderComponent,
  PageHeaderTitleComponent,
  PagePanelComponent,
  PageToolbarComponent,
  PageAccordionPanelComponent,
  PageAccordionBlockComponent,
  PageAccordionBlockHeaderComponent,
} from './components/page';
import { UserNotesEditorModalComponent } from './components/user-notes/editor/editor.component';
import {
  PowerDrawChartComponent,
  PressureAndFlowChartComponent,
  SnowProductionAndTwbChartComponent,
  LinkedMiniBarChartComponent,
  D3ChartDrawerComponent,
  UserActivityChartComponent
} from './components/charts';
import {
  SvgSymbolsComponent
} from './components/svg-symbols/svg-symbols.component';
import {
  NotificationListComponent
} from './components/notification-list/notification-list.component';
import {
  UserNotificationsScheduleComponent
} from './components/user-notifications-schedule/user-notifications-schedule.component';
import {
  MeteoConditionsDisplayComponent,
  MeteoConditionsSourceSelectorComponent,
  MeteoConditionsNamedDisplayComponent
} from './components/meteo-conditions';
import { AutoFocusDirective } from './auto-focus.directive';
import { TranslatedReportPartTypeComponent } from './components/translated-report-part-type/translated-report-part-type.component';
import { TranslatedReportFieldNameComponent } from './components/translated-report-field-name/translated-report-field-name.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    PerfectScrollbarModule,
    AngularMyDatePickerModule
  ],

  declarations: [
    BadgeComponent,
    CannonPreviewComponent,
    ConnectionPointPreviewComponent,
    MeteoStationPreviewComponent,
    CannonsGroupComponent,
    CannonsGroupsComponent,
    SnowCannonStatusComponent,
    SnowCannonIconComponent,
    CheckboxComponent,
    ControlButtonComponent,
    SnowCannonComponentButtonComponent,
    DocumentationFilesComponent,
    FormCheckboxComponent,
    FormEmailComponent,
    FormErrorComponent,
    FormErrorsDisplayComponent,
    FormLabelComponent,
    FormNumberComponent,
    FormPasswordComponent,
    FormRangeComponent,
    FormSelectComponent,
    FormSelectOptionComponent,
    FormTextareaComponent,
    FormTextComponent,
    MapButtonsComponent,
    MaxNumberValidatorDirective,
    MinNumberValidatorDirective,
    NumberInputComponent,
    PageButtonGroupComponent,
    PageComponent,
    PageFooterComponent,
    PageHeaderComponent,
    PageHeaderTitleComponent,
    PagePanelComponent,
    PageToolbarComponent,
    PopoverComponent,
    RangeDatepickerComponent,
    RequiredCheckboxValidatorDirective,
    SimpleVisualTimeRangeSelectorComponent,
    SnowingAngleComponent,
    SnowQualityComponent,
    TabComponent,
    TabsComponent,
    NotificationsTabComponent,
    DocumentationTabComponent,
    UserNotesTabComponent,
    TimeInputComponent,
    TranslatedDeviceAlertComponent,
    TranslatedDeviceTypeSymbolComponent,
    TranslatedDeviceTypeComponent,
    UserActivityChartComponent,
    UserAvatarComponent,
    UserNotesComponent,
    UserRolesComponent,
    VisualTimeRangeSelectorComponent,
    WaterTankComponent,
    WindSpeedAndDirectionComponent,
    DebugTableComponent,
    SnowCannonBasePositionIndicatorComponent,
    SnowCannonPositionIndicatorComponent,
    CompassComponent,
    SnowCannonPositionEditorComponent,
    SnowCannonPositionEditorInclinationComponent,
    SnowCannonPositionEditorHorizontalComponent,
    SnowCannonPositionEditorManualComponent,
    PageAccordionPanelComponent,
    PageAccordionBlockComponent,
    PageAccordionBlockHeaderComponent,
    UserNotesEditorModalComponent,
    PressureAndFlowChartComponent,
    PowerDrawChartComponent,
    SnowProductionAndTwbChartComponent,
    LinkedMiniBarChartComponent,
    D3ChartDrawerComponent,
    SvgSymbolsComponent,
    ConvertedUnitPipe,
    ConvertedValuePipe,
    NotificationListComponent,
    PumproomStatusComponent,
    PumproomMultiStatusComponent,
    SnowCannonComponentButtonHeaterComponent,
    SnowCannonComponentButtonOscillatorComponent,
    SnowCannonComponentButtonLampComponent,
    SnowCannonComponentButtonHydraulicPumpComponent,
    SnowCannonComponentButtonAirCompressorComponent,
    SnowCannonComponentButtonMainFanComponent,
    SnowCannonComponentButtonHydrantDriveComponent,
    SnowCannonComponentButtonNozzleRowComponent,
    UserNotificationsScheduleComponent,
    MeteoConditionsSourceSelectorComponent,
    MeteoConditionsDisplayComponent,
    MeteoConditionsNamedDisplayComponent,
    DeviceImageComponent,
    TranslatedDeviceMountTypeComponent,
    AutoFocusDirective,
    TranslatedReportPartTypeComponent,
    TranslatedReportFieldNameComponent
  ],
  exports: [
    BadgeComponent,
    CannonPreviewComponent,
    ConnectionPointPreviewComponent,
    MeteoStationPreviewComponent,
    CannonsGroupComponent,
    CannonsGroupsComponent,
    SnowCannonStatusComponent,
    SnowCannonIconComponent,
    CheckboxComponent,
    ControlButtonComponent,
    SnowCannonComponentButtonComponent,
    DocumentationFilesComponent,
    FormCheckboxComponent,
    FormEmailComponent,
    FormErrorComponent,
    FormLabelComponent,
    FormNumberComponent,
    FormPasswordComponent,
    FormRangeComponent,
    FormSelectComponent,
    FormSelectOptionComponent,
    FormTextareaComponent,
    FormTextComponent,
    MapButtonsComponent,
    MaxNumberValidatorDirective,
    MinNumberValidatorDirective,
    NumberInputComponent,
    PageButtonGroupComponent,
    PageComponent,
    PageFooterComponent,
    PageHeaderComponent,
    PageHeaderTitleComponent,
    PagePanelComponent,
    PageToolbarComponent,
    PopoverComponent,
    RangeDatepickerComponent,
    RequiredCheckboxValidatorDirective,
    SnowingAngleComponent,
    SnowQualityComponent,
    TabComponent,
    TabsComponent,
    NotificationsTabComponent,
    DocumentationTabComponent,
    UserNotesTabComponent,
    TimeInputComponent,
    TranslatedDeviceAlertComponent,
    TranslatedDeviceTypeSymbolComponent,
    TranslatedDeviceTypeComponent,
    UserActivityChartComponent,
    UserAvatarComponent,
    UserNotesComponent,
    UserRolesComponent,
    VisualTimeRangeSelectorComponent,
    WaterTankComponent,
    WindSpeedAndDirectionComponent,
    DebugTableComponent,
    SnowCannonBasePositionIndicatorComponent,
    SnowCannonPositionIndicatorComponent,
    SnowCannonPositionEditorComponent,
    PageAccordionPanelComponent,
    PageAccordionBlockComponent,
    PageAccordionBlockHeaderComponent,
    UserNotesEditorModalComponent,
    PowerDrawChartComponent,
    PressureAndFlowChartComponent,
    SnowProductionAndTwbChartComponent,
    LinkedMiniBarChartComponent,
    D3ChartDrawerComponent,
    SvgSymbolsComponent,
    ConvertedUnitPipe,
    ConvertedValuePipe,
    NotificationListComponent,
    PumproomStatusComponent,
    PumproomMultiStatusComponent,
    SnowCannonComponentButtonHeaterComponent,
    SnowCannonComponentButtonOscillatorComponent,
    SnowCannonComponentButtonLampComponent,
    SnowCannonComponentButtonHydraulicPumpComponent,
    SnowCannonComponentButtonAirCompressorComponent,
    SnowCannonComponentButtonMainFanComponent,
    SnowCannonComponentButtonHydrantDriveComponent,
    SnowCannonComponentButtonNozzleRowComponent,
    UserNotificationsScheduleComponent,
    MeteoConditionsSourceSelectorComponent,
    MeteoConditionsDisplayComponent,
    MeteoConditionsNamedDisplayComponent,
    DeviceImageComponent,
    TranslatedDeviceMountTypeComponent,
    AutoFocusDirective,
    TranslatedReportPartTypeComponent,
    TranslatedReportFieldNameComponent
  ]
})
export class SharedModule { }
