import { Component, Input } from '@angular/core';

@Component({
  selector: 'ss-snow-cannon-icon',
  templateUrl: './icon.component.html',
  styleUrls: []
})
export class SnowCannonIconComponent {
  @Input() state: string;
  @Input() size: string;

  @Input() label: string;
  @Input() isHighlighted: boolean;
  @Input() isDimmed: boolean;
}
