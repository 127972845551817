<div class="row">
  <div class="col">
    <ng-container *ngIf="sourceId; else noSource">
      <p i18n="Aktualnie wybrane źródło danych meteo:|@@meteoConditionsSourceSelectorSelectedSource">Aktualnie wybrane źródło danych meteo:</p>
      <ss-meteo-conditions-named-display [sourceId]="sourceId"></ss-meteo-conditions-named-display>
    </ng-container>
    <ng-template #noSource>
      <p class="p-2 text-center" i18n="Nie wybrano źródła danych meteo|@@meteoConditionsSourceSelectorNoSource">Nie wybrano źródła danych meteo</p>
    </ng-template>
  </div>
</div>
<div class="row">
  <div class="col">
    <p i18n="Zmień źródło danych meteo:|Label inputa@@meteoConditionsSourceSelectorInputLabel">Zmień źródło danych meteo:</p>
    <form-text
      [hideLabel]="true"
      [isExtraWide]="true"
      placeholder="numer armatki lub nazwa stacji meteo"
      i18n-placeholder="numer armatki lub nazwa stacji meteo|Placeholder inputa@@meteoConditionsSourceSelectorInputPlaceholder"
      [(ngModel)]="searchString"></form-text>
  </div>
</div>
<div class="row" *ngIf="searchResults.length > 0">
  <div class="col-6"
    *ngFor="let ms of displayResults; let index = index">
    <div class="result"
      [ngClass]="{
        'ml-0': index%2 === 0,
        'mr-0': index%2 === 1,
        'selected': ms.id === sourceId
      }"
      (click)="select(ms.id)">
      <span>
        <ss-translated-device-type-symbol [device]="ms"></ss-translated-device-type-symbol>
      </span>
      <ss-meteo-conditions-display type="grid" [sourceDevice]="ms"></ss-meteo-conditions-display>
    </div>
  </div>
  <div class="col p-2 text-center clickable color-light-blue"
    *ngIf="hiddenResults > 0"
    (click)="showAll()"
    i18n="pokaż pozostałych X wyników|@@meteoConditionsSourceSelectorMoreResults">pokaż pozostałych {{ hiddenResults }} wyników</div>
</div>
<!--
<p>sourceId</p>
<pre></pre>
<p>exceludeIds</p>
<pre>{{ exceludeIds|json }}</pre>
<p>limitToResortArea</p>
<pre>{{ limitToResortArea|json }}</pre>
<p>limitToWorkingArea</p>
<pre>{{ limitToWorkingArea|json }}</pre> -->