import { Component } from '@angular/core';

@Component({
  selector: 'ss-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.sass']
})

export class PopoverComponent {

  isPopoverVisible = false;

  showPopup() {
    this.isPopoverVisible = true;
  }

  hidePopup() {

    this.isPopoverVisible = false;
  }

}
