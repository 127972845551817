export class UserStats {
    from: number;
    to: number;
    auditTrailLogs: { [uid: number]: { [t: number]: {
      'action_type': string;
      'object_symbol': string;
      'action_description': string;
    };}; } = {};
    connected: { [uid: number]: { [t: number]: number} } = {};
    uuidToUsername: { [uid: number]: string } = {};
  }
