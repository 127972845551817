import { Component, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ss-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.sass']
})
export class CheckboxComponent implements OnInit {

  @Input()
  color: string;

  @Input()
  size: string;

  @Input() isChecked: boolean;

  @Output() hasChanged = new EventEmitter<boolean>();

  colorClassName = '';
  sizeClassName = '';
  hasLabel = false;

  private _checkboxLabel;
  @ViewChild('checkboxLabel', { static: true })
  set checkboxLabel(cl) {
    this.hasLabel = !!cl;
    this._checkboxLabel = cl;
  }
  get checkboxLabel() {
    return this._checkboxLabel;
  }


  constructor() { }

  ngOnInit() { }

  changed() {
    this.hasChanged.emit(this.isChecked);
  }

}
