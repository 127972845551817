<form #form="ngForm" (submit)="save()">
<ss-page [modal]="true">
    <ss-page-header>
        <ss-page-header-title>
          <ng-container *ngIf="displayTitleText">{{ displayTitleText }}</ng-container>
          <ng-container *ngIf="!displayTitleText" i18n="Zmień nazwę|Tytuł modala Zmień nazwę@@renameModalTitle">Zmień nazwę</ng-container>
        </ss-page-header-title>
    </ss-page-header>
    <ss-page-panel *ngIf="!displayInputLabel"
      i18n-panelTitle="Zmień nazwę|Tytuł panelu Zmień nazwę@@renameModalPanelTitle"
      panelTitle="Zmień nazwę">
      <form-text
        [(ngModel)]="renameString"
        name="form-cfg-rename-string"
        [isAutoFocus]="true"
        [hideLabel]="true"
        [isReadOnly]="isReadOnly"
        [isExtraWide]="true">
      </form-text>
    </ss-page-panel>
    <ss-page-panel *ngIf="displayInputLabel"
      [panelTitle]="displayInputLabel">
      <form-text
        [(ngModel)]="renameString"
        name="form-cfg-rename-string"
        [isAutoFocus]="true"
        [hideLabel]="true"
        [isReadOnly]="isReadOnly"
        [isExtraWide]="true">
      </form-text>
    </ss-page-panel>
    <ss-page-footer>
      <div></div>
      <ss-page-button-group>
        <ng-container *ngIf="isReadOnly ; else defaultrenameModalButtons">
          <button type="submit" class="btn btn-primary" i18n="Zamknij|Przycisk Zamknij@@renameModalCloseBtn">Zamknij</button>
        </ng-container>
        <ng-template #defaultrenameModalButtons>
          <button type="submit" class="btn btn-primary" i18n="Zapisz|Przycisk zapisz@@renameModalSaveBtn">Zapisz</button>
          <button type="button" class="btn btn-default" (click)="close()" i18n="Anuluj|Anuluj@@renameModalCancelBtn">Anuluj</button>
        </ng-template>
      </ss-page-button-group>
  </ss-page-footer>
</ss-page>
</form>
