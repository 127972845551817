import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ResortConfigurationService } from './resort-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class RequiresRoleGuard implements CanActivate {
  public constructor(
    private router: Router,
    private as: AuthService,
    private rcs: ResortConfigurationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAuth = this.as.isAuthorized(next.data.requiredRole || 'ROLE_OBSERVER')
      && (
        !next.data.requiredCapability
        || this.rcs.checkCapability(next.data.requiredCapability)
      );

    if(!isAuth && next.outlet !== 'modal') {
      return this.router.createUrlTree(this.rcs.getAllowedPath());
    }

    return isAuth;
  }

}
