import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ResortService } from 'src/app/resort.service';
import { Resort } from 'src/app/shared/models';
import { EditorMapControllerService } from '../editor-map-controller.service';
import { featureGroup, Marker, marker, ImageOverlay, Rectangle, rectangle, LatLngBounds } from 'leaflet';

@Component({
  selector: 'ss-resort',
  templateUrl: './resort.component.html',
  styleUrls: ['./resort.component.sass']
})
export class ResortEditorComponent implements OnInit, OnDestroy {

  constructor(
    private mapCtrl: EditorMapControllerService,
    private resortService: ResortService
  ) { }

  image: ImageOverlay;
  boundsRect: Rectangle;

  editingMapCenter = false;
  editingBoundsRect = false;

  centerMarker: Marker;
  resort: Resort;

  resortBounds: any;
  resortCenter: any;

  imageOpacity = 0.5;

  ngOnInit() {
    this.resort = this.resortService.getResort();

    this.image = this.mapCtrl.createProjectImage(this.resort.mapBoundaries);
    this.imageOpacityChanged();

    this.boundsRect = rectangle(this.resort.mapBoundaries, { fillOpacity: 0 });
    this.centerMarker = marker(this.resort.mapCenter);

    this.mapCtrl.map.on('draw:editresize draw:editmove', (ev) => {
      if (ev['layer'] === this.boundsRect) {
        this.updateImageBounds(ev['layer'].getBounds());
      }
      if (ev['layer'] === this.centerMarker) {
        this.resortCenter = [ev['layer'].getLatLng().lat, ev['layer'].getLatLng().lng];
      }
    });

    this.mapCtrl.updateWorkingLayer(featureGroup([
      this.image,
      this.boundsRect,
      this.centerMarker
    ]));
  }

  updateImageBounds(bounds: LatLngBounds) {
    this.resortBounds = [
      [bounds.getNorth(), bounds.getEast()],
      [bounds.getSouth(), bounds.getWest()]
    ];
    this.image.setBounds(bounds);
  }

  imageOpacityChanged() {
    this.image.setOpacity(this.imageOpacity);
  }

  editMapCenter() {
    this.editingMapCenter = true;
    this.centerMarker['editing'].enable();
  }

  setMapCenter() {
    this.editingMapCenter = false;
    this.centerMarker['editing'].disable();
  }

  editMapBounds() {
    this.editingBoundsRect = true;
    this.boundsRect['editing'].enable();
  }

  setMapBounds() {
    this.editingBoundsRect = false;
    this.boundsRect['editing'].disable();
  }

  ngOnDestroy() {
    this.mapCtrl.resetWorkingLayer();
    this.mapCtrl.map.off('draw:editresize draw:editmove');
  }

  save() {
    if (this.editingBoundsRect || this.editingMapCenter) {
      return;
    }
    if (this.resortBounds || this.resortCenter) {
      console.log('resortBounds', JSON.stringify(this.resortBounds));
      console.log('resortCenter', JSON.stringify(this.resortCenter));
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.code === 'KeyS' && event.ctrlKey && event.shiftKey) {
      this.save();
    }
  }
}
