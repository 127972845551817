import { Component } from '@angular/core';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { AbstractSnowCannonComponentButtonComponent } from '../abstract-snow-cannon-component-button.component';

@Component({
  selector: 'ss-snow-cannon-component-button-lamp',
  templateUrl: './component.html',
  styleUrls: []
})
export class SnowCannonComponentButtonLampComponent extends AbstractSnowCannonComponentButtonComponent {

  constructor(cs: ConfigureClientService) {
    super(cs);
  }

  public toggleLamp() {
    const dt: {
      commandLightTurnOff?: boolean;
      commandLightAutomaticOff?: boolean;
      commandLightAutomaticOn?: boolean;
      commandLightTurnOn?: boolean;
    } = {};
    if (this.snowCannon.commandStatusLightAutomatic) {
      if (this.snowCannon.isLampOn) {
        dt.commandLightTurnOff = true;
        dt.commandLightAutomaticOff = true;
      } else {
        dt.commandLightAutomaticOff = true;
      }
    } else {
      if (this.snowCannon.isLampOn) {
        dt.commandLightAutomaticOn = true;
      } else {
        dt.commandLightTurnOn = true;
      }
    }
    this.configureSnowCannon(dt);
  }

}
