<ng-container *ngIf="visibleAlerts.length > 0; else emptyTpl">
  <ng-container *ngIf="allowDynamicLoading; else tableTpl">
    <div class="height-limit">
      <perfect-scrollbar (psScrollY)="logScrollY($event)">
        <ng-container [ngTemplateOutlet]="tableTpl"></ng-container>
      </perfect-scrollbar>
    </div>
  </ng-container>
</ng-container>

<ng-template #emptyTpl>
  <p class="empty" i18n="Nie ma notyfikacji do wyświetlenia|Info o tym, że lista jest pusta@@notificationsTableIsEmpty">Nie ma notyfikacji do wyświetlenia</p>
</ng-template>

<ng-template #tableTpl>
  <table class="table"
    [ngClass]="{
      'hide-hidable-fields': compact ,
      'hide-filtering-link': shouldHideFilterLinks
    }">
    <thead>
      <tr class="table__row">
        <th class="text-center" i18n="Priorytet|Nagłówek kolumny w tabeli powiadomień@@notificationsTableColHeaderPriority">Priorytet</th>
        <th class="hidable" i18n="Lokalizacja|Nagłówek kolumny w tabeli powiadomień@@notificationsTableColHeaderLocation">Lokalizacja</th>
        <th class="text-center" i18n="Typ|Nagłówek kolumny w tabeli powiadomień@@notificationsTableColHeaderType" colspan="2">Typ</th>
        <th class="" i18n="Urządzenie|Nagłówek kolumny w tabeli powiadomień@@notificationsTableColHeaderDevice">Urządzenie</th>
        <th class="" i18n="Powiadomienie|Nagłówek kolumny w tabeli powiadomień@@notificationsTableColHeaderNotification">Powiadomienie</th>
        <th class="text-center">
          <span class="white-space-nowrap" i18n="Pierwsze wystąpienie|Nagłówek kolumny w tabeli powiadomień@@notificationsTableColHeaderFirstTime">Pierwsze wystąpienie</span>
          <br class="hidable " />
          <span class="hidable white-space-nowrap" i18n="Ostatnie wystąpienie|Nagłówek kolumny w tabeli powiadomień@@notificationsTableColHeaderLastTime">Ostatnie wystąpienie</span>
        </th>
        <th class="text-center"
          [ngClass]="{
            'hidable': compact && onlyActive && !isShowingOnlyOneSnowCannon
          }">
          <span class="white-space-nowrap" i18n="Data ustąpienia|Nagłówek kolumny w tabeli powiadomień@@notificationsTableColHeaderStoppedTime">Data ustąpienia</span>
        </th>
        <th class="hidable hidable-filtering-link"></th>
        <th class="hidable"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let alert of visibleAlerts; trackBy:trackByFn;">
        <tr class="table__row"
          (mouseenter)="addShadows($event)"
          (mouseleave)="removeShadows($event)">
          <td class="text-center fs-14 text-uppercase px-3"
            [ngClass]="{
              'table__alert': !alert.confirmed && alert.level === 'alert',
              'table__warning': !alert.confirmed && alert.level === 'warning',
              'table__notification': !alert.confirmed && alert.level === 'notification'
            }">
            <ng-container *ngIf="alert.level === 'alert'" i18n="Alert|Priorytet powiadomienia@@notificationsAlertLevelAlert">Alert</ng-container>
            <ng-container *ngIf="alert.level === 'warning'" i18n="Ostrzeżenie|Priorytet powiadomienia@@notificationsAlertLevelWarning">Ostrzeżenie</ng-container>
            <ng-container *ngIf="alert.level === 'notification'" i18n="Notyfikacja|Priorytet powiadomienia@@notificationsAlertLeveInfo">Notyfikacja</ng-container>
          </td>
          <ng-container *ngIf="alert.deviceReference && alert.deviceReference['systemType'] === 'SnowCannon'">
            <td class="hidable color-light-grey fs-14">
              <ng-container *ngIf="alert.deviceReference['conenctionPointRef']">
                <a *ngIf="alert.deviceReference['conenctionPointRef'].resortAreaRef"
                  [routerLink]="getLinkToDevice(alert.deviceReference['conenctionPointRef'].resortAreaRef)">
                  <ss-translated-device-type-symbol [device]="alert.deviceReference['conenctionPointRef'].resortAreaRef"></ss-translated-device-type-symbol>
                </a>
<!--
                <ng-container *ngIf="alert.deviceReference['conenctionPointRef'].workingAreaRef">
                  /
                  <a [routerLink]="getLinkToDevice(alert.deviceReference['conenctionPointRef'].workingAreaRef)">
                    <ss-translated-device-type-symbol [device]="alert.deviceReference['conenctionPointRef'].workingAreaRef"></ss-translated-device-type-symbol>
                  </a>
                </ng-container>
-->
              </ng-container>
            </td>
            <td class="table__image">
              <ss-device-image [device]="alert.deviceReference"></ss-device-image>
            </td>
            <td class="fs-14 pl-3">
              {{ alert.deviceReference['cannonModel'] }}
            </td>
            <td class="px-2 white-space-nowrap">
              <a [routerLink]="getLinkToDevice(alert.deviceReference)">
                <ss-translated-device-type-symbol [device]="alert.deviceReference"></ss-translated-device-type-symbol>
              </a>
            </td>
          </ng-container>
          <ng-container *ngIf="!alert.deviceReference || alert.deviceReference['systemType'] !== 'SnowCannon'">
            <td class="hidable"></td>
            <td></td>
            <td colspan="2" class="px-2 white-space-nowrap">
              <a [routerLink]="getLinkToDevice(alert.deviceReference)">
                <ss-translated-device-type-symbol [device]="alert.deviceReference"></ss-translated-device-type-symbol>
              </a>
            </td>
          </ng-container>


          <td class="w-50 fs-14">
            <ss-translated-device-alert [alert]="alert.alertType"></ss-translated-device-alert>
          </td>
          <td class="color-light-grey white-space-nowrap fs-14 text-center"
            [ngClass]="{
              'table__active-alert': !alert.expired
            }">
            {{ alert.occurred|date:'yyyy-MM-dd HH:mm:ss' }}
            <span class="hidable">
              <br />
              {{ alert.lastOccurred|date:'yyyy-MM-dd HH:mm:ss' }}
            </span>
          </td>
          <td class="color-light-grey white-space-nowrap text-center"
            [ngClass]="{
              'table__active-alert': !alert.expired,
              'hidable': compact && onlyActive && !isShowingOnlyOneSnowCannon
            }">
            {{ alert.expired|date:'yyyy-MM-dd HH:mm:ss' }}
            <ng-container *ngIf="!alert.expired && alert.deviceReference && alert.deviceReference['systemType'] === 'PumpRoom'">
              <button class="btn w-100 btn-default"
                [disabled]="isInProgress === alert.deviceId"
                (click)="resetPumpRoomAlerts(alert.deviceReference)"
                *ngIf="alert.alertType !== 'connection-lost'"
                i18n="Resetuj alarmy|Przycisk akcji 'Resetuj alarmy' w tabeli@@notificationsButtonResetAlerts">Resetuj alarmy</button>
            </ng-container>
            <ng-container *ngIf="!alert.expired && alert.deviceReference && alert.deviceReference['systemType'] === 'SnowCannon'">
              <!-- acc pump rooms with commandResetAlerts -->
              <button class="btn w-100 btn-default"
                [disabled]="isInProgress === alert.deviceId"
                (click)="resetSnowCannonAlerts(alert.deviceReference)"
                *ngIf="alert.alertType !== 'connection-lost'"
                i18n="Resetuj alarmy|Przycisk akcji 'Resetuj alarmy' w tabeli@@notificationsButtonResetAlerts">Resetuj alarmy</button>
              <button class="btn w-100 btn-default"
                [disabled]="isInProgress === alert.deviceId"
                (click)="resetSnowCannonAlerts(alert.deviceReference)"
                *ngIf="alert.alertType === 'connection-lost'"
                i18n="Wymuś połączenie|Przycisk akcji 'Wymuś połączenie' w tabeli@@notificationsButtonResetConnection">Wymuś połączenie</button>
            </ng-container>
          </td>
          <td class="hidable hidable-filtering-link table__link">
            <a href="#" (click)="$event.preventDefault(); clickedId.emit([alert.deviceId])">
              <svg>
                <use xlink:href='#icon-search' />
              </svg>
            </a>
          </td>
          <td class="hidable table__link">
            <a [routerLink]="getLinkToDevice(alert.deviceReference)">
              <svg>
                <use xlink:href='#icon-chevron-right' />
              </svg>
            </a>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-template>
