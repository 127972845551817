import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SnowCannon } from 'src/app/shared/models';

@Component({
  selector: 'ss-snow-cannon-position-editor',
  templateUrl: './snow-cannon-position-editor.component.html',
  styleUrls: ['./snow-cannon-position-editor.component.sass']
})
export class SnowCannonPositionEditorComponent {

  @Input()
  public currentMapBearing: number;

  @Input()
  public areaCenterX: number;

  @Input()
  public areaCenterY: number;

  @Input()
  public get snowCannon(): SnowCannon {
    return this._snowCannon;
  }
  public set snowCannon(val: SnowCannon) {
    clearTimeout(this.selectedViewTimeout);
    this.selectedView = '';
    if (!val.connectionStatus) {
      this._snowCannon = val;
    }
    this.selectedViewTimeout = setTimeout(() => {
      this._snowCannon = val;
      if (val.connectionStatus) {
        if (val.CAP_CHANGE_HORIZONTAL_POSITION_ANGLE || val.CAP_CHANGE_OSCILLATION_ANGLE) {
          this.selectedView = 'horizontal';
        } else if (val.CAP_CHANGE_POSITION_MANUALLY) {
          this.selectedView = 'manual';
        } else if (val.CAP_CHANGE_HORIZONTAL_POSITION_ANGLE) {
          this.selectedView = 'inclination';
        } else {
          this.selectedView = '';
        }
      }
    }, 300);
  }
  private _snowCannon: SnowCannon;
  private selectedViewTimeout;

  @Output()
  public executeCommand: EventEmitter<any> = new EventEmitter();

  public selectedView: string;

  public handleCommand(command: any) {
    this.executeCommand.emit(command);
  }

}
