<div *ngIf="user" [ngClass]="{ 'big': size === 'big' }">
  <span>
    <svg *ngIf="role === 'ROLE_OBSERVER'">
      <use xlink:href="#user-role-user" /></svg>
    <svg *ngIf="role === 'ROLE_OPERATOR'">
      <use xlink:href="#user-role-operator" /></svg>
    <svg *ngIf="role === 'ROLE_ADMIN'">
      <use xlink:href="#user-role-admin" /></svg>
    <svg *ngIf="role === 'ROLE_SUPERADMIN'">
      <use xlink:href="#user-role-superadmin" /></svg>
  </span>

  <ss-user-roles *ngIf="showDescription" [roles]="user.roles"></ss-user-roles>
</div>
