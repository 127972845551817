import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { ResortService } from 'src/app/resort.service';
import { ResortArea, WorkingArea } from 'src/app/shared/models';
import { ModalRouteService } from '../../modal-route.service';

@Component({
  selector: 'ss-meteo-conditions-source-selection-modal',
  templateUrl: './meteo-conditions-source-selection-modal.component.html',
  styleUrls: ['./meteo-conditions-source-selection-modal.component.sass']
})
export class MeteoConditionsSourceSelectionModalComponent implements OnInit {

  public lock = false;

  constructor(
    private rs: ResortService,
    private cs: ConfigureClientService,
    private ar: ActivatedRoute,
    private mr: ModalRouteService
  ) { }

  public resortArea: ResortArea;
  public workinngArea: WorkingArea;

  public selectedMeteoSourceId: string;

  ngOnInit(): void {
    this.ar.paramMap.subscribe(params => {
      const resortAreaId = params.get('resortAreaId');
      const workinngAreaId = params.get('workinngAreaId');

      this.resortArea = this.rs.getResortArea(resortAreaId);
      this.workinngArea = this.rs.getWorkingArea(workinngAreaId);

      if(!this.resortArea) {
        this.close();
      }

      if(this.workinngArea) {
        this.selectedMeteoSourceId = this.workinngArea.remoteMeteoStation;
      } else {
        this.selectedMeteoSourceId = this.resortArea.remoteMeteoStation;
      }
    });
  }

  public onMeteoSourceSelection(id: string) {
    this.mr.closeModal(id);
  }

  public close() {
    this.mr.closeModal();
  }

}
