import {
  Component, Input
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { FormRangeComponent } from '../form-range/form-range.component';

let identifier = 0;

@Component({
  selector: 'form-number',
  templateUrl: './form-number.component.html',
  styleUrls: [],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FormNumberComponent,
    multi: true,
  }],
})
export class FormNumberComponent extends FormRangeComponent {
  @Input() public placeholder: string;

  public identifier = `form-number-${identifier++}-` + Math.random();

  private rounded(val: number) {
    const rnd = Math.pow(10, this.scale);
    return Math.round(val * rnd) / rnd;
  }

  inputChanged($event) {
    let val: any = '' + $event.target.value;
    val = val.replace(',', '.');
    val = parseFloat(val);

    val = this.rounded(val);

    if (val > this.max) {
      this.value = this.max;
    } else if (val < this.min) {
      this.value = this.min;
    } else {
      this.value = val;
    }
  }

  increase() {
    if (this.rounded(this.value) <= this.max) {
      this.value = this.rounded(+this.value + +this.step);
    } else {
      this.value = this.max;
    }
  }

  decrease() {
    if (this.rounded(this.value) >= this.min) {
      this.value = this.rounded(+this.value - +this.step);
    } else {
      this.value = this.min;
    }
  }

}
