import { Component, Input } from '@angular/core';
import { UserStats } from '../../../models';
import { isObject, TimedChartConfig, TimedChartConfigSeries, TimedChartData } from '../../../../../../../common';
import { getSeriesColor } from '../../../../../../../common/d3';

@Component({
  selector: 'ss-d3-user-activity-chart',
  templateUrl: './user-activity-chart.component.html',
  styleUrls: ['./user-activity-chart.component.sass']
})
export class UserActivityChartComponent {

  public legendEntries: { name: string; color: string }[] = [];

  private _chartData: UserStats;
  @Input()
  public get chartData(): UserStats {
    return this._chartData;
  };
  public set chartData(v: UserStats) {
    if(v) {
      this._chartData = v;

      const legendEntries: { name: string; color: string }[] = [];
      const dataParams = { labels: [] };
      const config: TimedChartConfig = {
        ... new TimedChartConfig(),
        xAxisType: 'up-and-bottom',
        yAxisType: 'none',
        legend: true,
        grid: true,
        series: [],
      };
      const userIds = [ ... new Set([ ... Object.keys(v.auditTrailLogs), ... Object.keys(v.connected) ]) ];

      const allLabels = [];
      userIds.forEach((uid, idx) => {
        const color = getSeriesColor({ ... new TimedChartConfigSeries(), seriesType: 'bar' }, idx);
        const atKeys = isObject(v.auditTrailLogs[uid]) ? Object.keys(v.auditTrailLogs[uid]).map(x => parseInt(`${x}`, 10)) : [];
        const cKeys = isObject(v.connected[uid]) ? Object.keys(v.connected[uid]).map(x => parseInt(`${x}`, 10)) : [];
        allLabels.push(...new Set([...atKeys, ...cKeys]));

        config.series.push({
          ...new TimedChartConfigSeries(),
          labelFieldName: `cnSeries${uid}labels`,
          valueFieldName: `cnSeries${uid}values`,
          seriesType: 'line-stepped',
          primaryColor: color,
          seriesName: `${this._chartData.uuidToUsername[uid]}`,
          fixedMin: 0,
          isVisibleOnLegend: true,
          fixedMax: userIds.length + 1
        });

        config.series.push({
          ...new TimedChartConfigSeries(),
          labelFieldName: `atSeries${uid}labels`,
          valueFieldName: `atSeries${uid}values`,
          primaryColor: color,
          seriesType: 'point',
          seriesName: `AT ${uid}`,
          fixedMin: 0,
          isVisibleOnLegend: false,
          fixedMax: userIds.length + 1
        });



        legendEntries.push({
          name: `${this._chartData.uuidToUsername[uid]}`,
          color
        });

        dataParams[`cnSeries${uid}labels`] = cKeys;
        dataParams[`cnSeries${uid}values`] = Object.values(isObject(v.connected[uid]) ? v.connected[uid] : {})
          .map(
          (x: number) => x * (userIds.length - idx) + 0.1 * (userIds.length - idx - 1)
          );
        dataParams[`atSeries${uid}labels`] = atKeys;
        dataParams[`atSeries${uid}values`] = Object.values(isObject(v.auditTrailLogs[uid]) ? v.auditTrailLogs[uid] : {})
          .map(() => 1.1 * (userIds.length - idx - 1) + 1 );
      });

      dataParams.labels = [ ...new Set(allLabels)].sort();

      this.data = new TimedChartData(dataParams);
      this.config = config;

      this.legendEntries = legendEntries;
    }
  };

  public data: TimedChartData;
  public config: TimedChartConfig;

}
