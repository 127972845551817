<div class="posr">
  <input angular-mydatepicker
    #dp="angular-mydatepicker" name="mydate"
    (click)="dp.toggleCalendar()"
    (dateChanged)="onDateChanged($event)"
    [disabled]="disabled"
    [ngClass]="{'input--disabled': disabled }"
    [(ngModel)]="model"
    [options]="options"
    [locale]="locale"
    placeholder="Wybierz datę"
    i18n-placeholder="Wybierz datę|Placeholder pola daty@@datepickerPlaceholder"
    class="input"
    autocomplete="off" />
</div>
