import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndpointMapService } from '../endpoint-map.service';
import { Schedule, ScheduleSchema, ScheduleDay } from '../shared/models';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ScheduleClientService {

  constructor(private e: EndpointMapService, private http: HttpClient) {}

  public save(schedule: Schedule): Observable<Schedule[]> {
    return this.http.post<Schedule[]>(this.e.schedules, schedule, httpOptions);
  }

  public remove(schedule: Schedule): Observable<any> {
    return this.http.delete(this.e.schedules + '/' + schedule.id, httpOptions);
  }

  mapScheduleDays(data: any[]): ScheduleDay[] {
    const resp = [];
    for (const d of data) {
      resp.push(Object.assign(new ScheduleDay(), d));
    }
    return resp;
  }

  private mapSchedule(data: any): Schedule {
    const resp = Object.assign(new Schedule(), data) as Schedule;

    resp.schema = Object.assign(new ScheduleSchema(), resp.schema);

    resp.monday = this.mapScheduleDays(data['monday']);
    resp.tuesday = this.mapScheduleDays(data['tuesday']);
    resp.wednesday = this.mapScheduleDays(data['wednesday']);
    resp.thursday = this.mapScheduleDays(data['thursday']);
    resp.friday = this.mapScheduleDays(data['friday']);
    resp.saturday = this.mapScheduleDays(data['saturday']);
    resp.sunday = this.mapScheduleDays(data['sunday']);

    return resp;
  }

  public getAll(): Observable<Schedule[]> {
    return this.http.get<Schedule[]>(this.e.schedules, httpOptions)
      .pipe(map(schedules => schedules.map(s => this.mapSchedule(s))));
  }

}
