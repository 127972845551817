<div class="manual" *ngIf="snowCannon">
  <div class="circle"></div>
  <button
    (mousedown)="manualPositioningStart('commandCannonMoveLeft')"
    (mouseout)="manualPositioningStop()"
    (touchstart)="manualPositioningStart('commandCannonMoveLeft')"
    (touchend)="manualPositioningStop()"
    (contextmenu)="$event.preventDefault()"
    (window:mouseup)="manualPositioningStop()"
    [disabled]="manualPositioningCommand && manualPositioningCommand!=='commandCannonMoveLeft'"
    [ngClass]="{ 'active': manualPositioningCommand==='commandCannonMoveLeft' }"
    class="left">
    <svg>
      <use xlink:href='#icon-chevron-left'/>
    </svg>
  </button>
  <button
    (mousedown)="manualPositioningStart('commandCannonMoveRight')"
    (mouseout)="manualPositioningStop()"
    (touchstart)="manualPositioningStart('commandCannonMoveRight')"
    (touchend)="manualPositioningStop()"
    (contextmenu)="$event.preventDefault()"
    (window:mouseup)="manualPositioningStop()"
    [disabled]="manualPositioningCommand && manualPositioningCommand!=='commandCannonMoveRight'"
    [ngClass]="{ 'active': manualPositioningCommand==='commandCannonMoveRight' }"
    class="right">
    <svg>
      <use xlink:href='#icon-chevron-right'/>
    </svg>
  </button>
  <button
    (mousedown)="manualPositioningStart('commandTubeMoveDown')"
    (mouseout)="manualPositioningStop()"
    (touchstart)="manualPositioningStart('commandTubeMoveDown')"
    (touchend)="manualPositioningStop()"
    (contextmenu)="$event.preventDefault()"
    (window:mouseup)="manualPositioningStop()"
    [disabled]="manualPositioningCommand && manualPositioningCommand!=='commandTubeMoveDown'"
    [ngClass]="{ 'active': manualPositioningCommand==='commandTubeMoveDown' }"
    class="bottom">
    <svg>
      <use xlink:href='#icon-chevron-down'/>
    </svg>
  </button>
  <button
    (mousedown)="manualPositioningStart('commandTubeMoveUp')"
    (mouseout)="manualPositioningStop()"
    (touchstart)="manualPositioningStart('commandTubeMoveUp')"
    (touchend)="manualPositioningStop()"
    (contextmenu)="$event.preventDefault()"
    (window:mouseup)="manualPositioningStop()"
    [disabled]="manualPositioningCommand && manualPositioningCommand!=='commandTubeMoveUp'"
    [ngClass]="{ 'active': manualPositioningCommand==='commandTubeMoveUp' }"
    class="top">
    <svg>
      <use xlink:href='#icon-chevron-up'/>
    </svg>
  </button>
</div>
