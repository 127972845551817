import { Component, Input } from '@angular/core';

@Component({
  selector: 'ss-snow-cannon-component-button',
  templateUrl: './snow-cannon-component-button.component.html',
  styleUrls: ['./snow-cannon-component-button.component.sass']
})
export class SnowCannonComponentButtonComponent {

  @Input() deviceType: 'heater'|'hydraulic-pump'|'air-compressor'|'main-fan'|'nozzle-row'|'hydrant-drive'|'lamp'|'oscillator';
  @Input() deviceNumber: number;

  @Input() isActive: boolean;
  @Input() isBreakdown: boolean;

  @Input() mode: string;
  @Input() size: string;
}
