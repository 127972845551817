<ss-page>
  <ss-page-header>
    <ss-page-header-title>
      <ng-container i18n="Mój profil|Tytuł zakładki Mój profil@@userProfileTitle">Mój profil</ng-container>
    </ss-page-header-title>
    <ss-page-button-group>
      <button (click)="logout()" class="btn btn-danger"  i18n="Wyloguj się|Przycis wyloguj się w zakładce Mój profil@@userProfileLogoutBtn">Wyloguj się</button>
    </ss-page-button-group>
  </ss-page-header>

  <ss-page-panel>
    <ss-d3-user-activity-chart [chartData]="chartData"></ss-d3-user-activity-chart>
  </ss-page-panel>

  <div class="row" *ngIf="allowNotificationSchedule">
    <div class="col-4 pr-1">
      <ng-container [ngTemplateOutlet]="acountInfoDataEditor"></ng-container>
    </div>
    <div class="col-8 pl-1">
      <ss-page-panel panelTitle="Ustawienia powiadomień" i18n-panelTitle="Ustawienia powiadomień|Wysuł ustawienia powiadomień na panelu Historii akcji@@userProfileNotificationsSettingsTitle">
        <ss-user-notifications-schedule [user]="user"
          [disabled]="accountInfoDataDisable"
          (changed)="notificationScheduleChanged($event)"></ss-user-notifications-schedule>
        <ss-page-button-group class="mt-2">
          <div class="filler"></div>
          <button type="button" class="btn btn-default"
            (click)="resetAccountInfoData()"
             i18n="Anuluj|Anuluj na panelu Dane konta@@userProfileCancelButton">Anuluj</button>
          <button type="button" class="btn btn-primary"
            (click)="saveNotificationScheduleSettings()"
            [disabled]="!notificationSchedule"
             i18n="Zapisz zmiany|Zapisz zmiany na panelu Dane konta@@userProfileAccountPanelSave">Zapisz zmiany</button>
        </ss-page-button-group>
      </ss-page-panel>
    </div>
  </div>

  <div class="row">
    <div class="col-4 pr-1">
      <ng-container *ngIf="!allowNotificationSchedule">
        <ng-container [ngTemplateOutlet]="acountInfoDataEditor"></ng-container>
      </ng-container>
      <ng-template #acountInfoDataEditor>
        <ss-page-panel panelTitle="Dane konta" i18n-panelTitle="Dane konta|Tytuł panelu Dane konta@@userProfileAccountPanelTitle">
          <form #fAccountInfoData="ngForm" (submit)="accountInfoDataSubmit()" novalidate>
            <form-text
              retuired
              [disabled]="accountInfoDataDisable"
              [(ngModel)]="name"
              name="nameAccountInfoData"
              placeholder=""
              label="Imię"
              i18n-label="Imię|Imię użytkownika na panelu Dane konta@@userProfileAccountPanelUserName"></form-text>
            <form-text
              retuired
              [disabled]="accountInfoDataDisable"
              [(ngModel)]="surname"
              name="surnameAccountInfoData"
              placeholder=""
              label="Nazwisko"
              i18n-label="Nazwisko|Nazwisko użytkownika na panelu Dane konta@@userProfileAccountPanelUserSurname"></form-text>
            <form-text
              [disabled]="accountInfoDataDisable"
              [(ngModel)]="phone"
              name="phoneAccountInfoData"
              placeholder="Numer telefonu używany do powiadomień"
              i18n-placeholder="Numer telefonu używany do powiadomień|placeholder pola 'Telefon kontaktowy' użytkownika na panelu Dane konta@@userProfileAccountPanelUserPhoneInfo"
              label="Telefon kontaktowy"
              i18n-label="Telefon kontaktowy|Telefon kontaktowy użytkownika na panelu Dane konta@@userProfileAccountPanelUserPhone"></form-text>

            <p class="text-right my-2" *ngIf="allowNotificationSchedule && phone">
              <button type="button" [disabled]="phoneNoTestInProgress" (click)="testPhoneNo()" class="btn btn-inline btn-link">TEST - <ng-container i18n="@@userProfileAccountPanelUserPhone">Telefon kontaktowy</ng-container></button>
            </p>

            <ss-page-button-group>
              <div class="filler"></div>
              <button type="button" (click)="resetAccountInfoData()" class="btn btn-default" i18n="Anuluj|Anuluj na panelu Dane konta@@userProfileCancelButton">Anuluj</button>
              <button type="submit" [disabled]="accountInfoDataDisable || !fAccountInfoData.valid" class="btn btn-primary" i18n="Zapisz zmiany|Zapisz zmiany na panelu Dane konta@@userProfileAccountPanelSave">Zapisz zmiany</button>
            </ss-page-button-group>
          </form>
        </ss-page-panel>
      </ng-template>
      <ss-page-panel panelTitle="Zmiana hasła"  i18n-panelTitle="Zmiana hasła|Zmiana hasła na panelu Zmiana hasła@@userProfileChangePasswordPanelTitle">
        <form #fPasswordData="ngForm" (submit)="passwordDataSubmit()" novalidate>

          <form-password
            required
            [disabled]="passwordDataDisable"
            [(ngModel)]="oldPassword"
            name="oldPasswordPasswordData"
            placeholder=""
            label="Aktualne hasło"
            i18n-label="Aktualne hasło|Aktualne hasło na panelu Zmiana hasła@@userProfileChangePasswordPanelCurrentPassword"></form-password>
          <form-password
            required
            [disabled]="passwordDataDisable"
            [(ngModel)]="newPassword"
            name="newPasswordPasswordData"
            placeholder=""
            label="Nowe hasło"
            i18n-label="Nowe hasło|Nowe hasło na panelu Zmiana hasła@@userProfileChangePasswordPanelNewPassword"></form-password>
          <form-password
            required
            [disabled]="passwordDataDisable"
            [(ngModel)]="newPasswordRepeat"
            name="newPasswordRepeatPasswordData"
            placeholder=""
            label="Potwierdź nowe hasło"
            i18n-label="Potwierdź nowe hasło|Potwierdź nowe hasło na panelu Zmiana hasła@@userProfileChangePasswordPanelReNewPassword"></form-password>

          <ss-page-button-group>
            <div class="filler"></div>
            <button type="button" (click)="newPassword = ''; newPasswordRepeat = '';" class="btn btn-default" i18n="Anuluj|Anuluj na panelu Dane konta@@userProfileCancelButton">Anuluj</button>
            <button type="submit" [disabled]="passwordDataDisable || !fPasswordData.valid" class="btn btn-primary" i18n="Zmień hasło|Zmień hasło na panelu Zmiana hasła@@userProfileChangePasswordPanelSaveButton">Zmień hasło</button>
          </ss-page-button-group>
        </form>

      </ss-page-panel>
    </div>
    <div class="col-8 pl-1 d-flex">
      <ss-page-panel panelTitle="Ostatnie akcje wykonane w systemie" i18n-panelTitle="Ostatnie akcje wykonane w systemie|Ostatnie akcje wykonane w systemie na panelu Historii akcji@@userProfileLatestActionsTitle">
        <p *ngIf="atFrom && atTo">{{ atFrom|date:'yyyy-MM-dd HH:mm:ss' }} - {{ atTo|date:'yyyy-MM-dd HH:mm:ss' }}</p>

        <table class="table my-3">
          <thead>
            <tr>
              <td colspan="2" i18n="Data|Data na panelu Historii akcji@@userProfileLatestActionsTableDate">Data</td>
              <td colspan="3" i18n="Akcja|Akcja na panelu Historii akcji@@userProfileLatestActionsTableAction">Akcja</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let at of atData">
              <td>{{ (1000 * at.created)|date:'yyyy-MM-dd' }}</td>
              <td>{{ (1000 * at.created)|date:'HH:mm:ss' }}</td>
              <td>{{ at.action_type }}</td>
              <td>{{ at.object_symbol }}</td>
              <td>{{ at.action_description }}</td>
            </tr>
          </tbody>
        </table>
      </ss-page-panel>
    </div>
  </div>

</ss-page>
