import { formatNumber } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertedValue',
  pure: false
})
export class ConvertedValuePipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private _locale: string) {}

  transform(device: any, fieldName: string = '', digitsInfo?: string, locale?: string): string {
    let value = 0;
    locale = locale || this._locale;

    if (device && device[fieldName + 'Converted']) {
      value = +device[fieldName + 'Converted'];
    }

    if (!digitsInfo) {
      if (value === 0) {
        digitsInfo = '1.1-1';
      } else {
        const log = Math.log10(Math.abs(value));
        if (log >= 2) {
          digitsInfo = '1.0-0';
        } else if (log < 0) {
          digitsInfo = '1.2-2';
        } else {
          digitsInfo = '1.1-1';
        }
      }
    }


    return formatNumber(value, locale, digitsInfo);
  }

}
