import { Component, Input } from '@angular/core';
import { Device, translateDeviceTypeAndSymbol } from '../../../../../../common';

@Component({
  selector: 'ss-translated-device-type-symbol',
  template: '{{ text }}',
  styleUrls: []
})
export class TranslatedDeviceTypeSymbolComponent {
  public text = '';

  private _device: Device;
  @Input()
  public get device(): Device {
    return this._device;
  }
  public set device(v: Device) {
    this._device = v;
    this.update();
  }

  private _onlySymbol = false;
  @Input()
  public get onlySymbol(): boolean {
    return this._onlySymbol;
  }
  public set onlySymbol(v: boolean) {
    this._onlySymbol = v;
    this.update();
  }

  private update() {
    this.text = translateDeviceTypeAndSymbol(this.device, this.onlySymbol);
  }
}
