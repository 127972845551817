import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthJwtTokenInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.getApiKey()) {
            const duplicate = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + this.authService.getApiKey()) });

            return next.handle(duplicate);
        }

        return next.handle(req);
    }
}
