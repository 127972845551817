import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { TabComponent } from 'src/app/shared/components/tabs/tab/tab.component';
import { ResortArea, PumpRoom, WaterReservoir, PowerSource } from 'src/app/shared/models';

@Component({
  selector: 'ss-area-stats-and-reports-tab',
  templateUrl: './area-stats-and-reports-tab.component.html',
  styleUrls: ['./area-stats-and-reports-tab.component.sass'],
  providers: [
    {provide: TabComponent, useExisting: forwardRef(() => AreaStatsAndReportsTabComponent), multi: true}
  ]
})
export class AreaStatsAndReportsTabComponent extends TabComponent {

  @Input()
  public area: ResortArea;
  @Input()
  public pumpRooms: PumpRoom[];
  @Input()
  public powerSources: PowerSource[];
  @Input()
  public waterReservoirs: WaterReservoir[];

  constructor() {
    super();
  }

}
