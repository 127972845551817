<ss-page-header>
  <button class="btn btn-dark mr-4"
    [disabled]="isLoading"
    (click)="renameReport()"
    *ngIf="isEditMode" i18n="@@reportingReportDetailRenameWholeReport">Zmień nazwę</button>
  <ss-page-header-title *ngIf="result">
    {{ result.reportName }}
    <ng-container *ngIf="isSingleDevice && selectedDevices.length === 1">{{ selectedDevice.symbol }}</ng-container>
  </ss-page-header-title>
  <div *ngIf="result">
    <ng-container *ngIf="isSingleDevice && selectedDevices.length > 1">
      <div class="device-select">
        <button [disabled]="isLoading"
          (click)="changeSelectedSingleDevice(prevDeviceId)"
          class="device-select__btn device-select__btn--prev">
          <svg>
            <use xlink:href="#icon-chevron-down" />
          </svg>
        </button>
        <div class="device-select__box number-dropdown" (click)="devicessListExpanded = !devicessListExpanded">
          {{ selectedDevice.symbol }}
          <button class="device-select__chevron" [disabled]="isLoading"
            [ngClass]="{'device-select__chevron--rotated': devicessListExpanded}">
            <svg>
              <use xlink:href="#icon-chevron-down" />
            </svg>
          </button>
        </div>
        <button [disabled]="isLoading"
          (click)="changeSelectedSingleDevice(nextDeviceId)"
          class="device-select__btn device-select__btn--next">
          <svg>
            <use xlink:href="#icon-chevron-down" />
          </svg>
        </button>
      </div>
    </ng-container>
  </div>
  <div class="flex-grow-1"></div>

  <ng-container *ngIf="result && !isEditMode">
    <p class="mr-2" i18n="Eksport|Wybór parametrów raportu Eksport@@report-detail-export-to">Eksport</p>
    <div class="btn-group mr-4">
      <button [disabled]="isLoading || result.isLive" class="btn btn-dark" type="button" (click)="export('csv')">
        <svg>
          <use xlink:href="#icon-file-csv" />
        </svg>
        CSV
      </button>
      <button [disabled]="isLoading || result.isLive" class="btn btn-dark" type="button" (click)="export('xlsx')">
        <svg>
          <use xlink:href="#icon-file-excel" />
        </svg>
        XLSX
      </button>
      <button *ssdHideIfNotGranted="'ROLE_SUPERADMIN'"
        [disabled]="isLoading || result.isLive"
        class="btn btn-dark"
        type="button"
        (click)="export('pdf')">
        <svg>
          <use xlink:href="#icon-file-pdf" />
        </svg>
        PDF
      </button>
    </div>
  </ng-container>

  <ss-page-button-group>
    <ng-container *ngIf="result">
      <button (click)="saveDefinition(true)"
        *ngIf="isEditMode"
        [disabled]="isLoading"
        class="btn btn-primary"
        i18n="Zapisz jako nowy szablon|Przycisk na podglądzie raportu Zapisz jako nowy szablon@@report-detail-btn-save-definition-as-copy">Zapisz jako nowy szablon</button>
      <button (click)="saveDefinition()"
        *ngIf="isEditMode && result.isCustom"
        [disabled]="isLoading"
        class="btn btn-primary"
        i18n="Zapisz szablon|Przycisk na podglądzie raportu Zapisz szablon@@report-detail-btn-save-definition">Zapisz szablon</button>
      <button (click)="stopEditMode()"
        *ngIf="isEditMode"
        [disabled]="isLoading"
        class="btn btn-dark"
        i18n="Podgląd|Przycisk na podglądzie raportu Podgląd@@report-detail-btn-preview">Podgląd</button>
      <button (click)="startEditMode()"
        *ngIf="!isEditMode"
        [disabled]="isLoading"
        class="btn btn-primary"
        i18n="Edytuj szablon|Przycisk na podglądzie raportu Edytuj szablon@@report-detail-btn-edit-definition">Edytuj szablon</button>
    </ng-container>

    <a [routerLink]="['/', 'application', 'reporting']"
      *ngIf="!isEditMode"
      class="btn btn-default"
      i18n="Powrót do listy|Przycisk na podglądzie raportu Powrót do listy@@report-detail-btn-back">Powrót do listy</a>
  </ss-page-button-group>
</ss-page-header>
<ng-container *ngIf="result && isSingleDevice && devicessListExpanded">
  <ss-page-panel>
    <div class="devices-list">
      <a class="devices-list__label"
        *ngFor="let sd of selectedDevices"
        (click)="changeSelectedSingleDevice(sd.id)">{{ sd.symbol }}</a>
    </div>
  </ss-page-panel>
</ng-container>
