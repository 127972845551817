import { Component, Input } from '@angular/core';

@Component({
  selector: 'ss-snow-cannon-status',
  templateUrl: './status.component.html',
  styleUrls: []
})
export class SnowCannonStatusComponent {
  @Input() state: string;
  @Input() size: string;
}
