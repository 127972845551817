<div class="popup-overlay" *ngIf="data" (click)="closeTimeRangePopup()">

    <div class="popup" [ngClass]="{
      'popup--visible': isVisible,
      'popup--inverted': false
    }" [ngStyle]="{
      'left': data.centerX + 'px',
      'top': data.centerY + 'px'
    }" (click)="$event.stopPropagation()">
      <div class="popup__tail">
        <img src="assets/images/gradient.png" alt="" [ngStyle]="{'max-width': data.maxTailWidth + 'px'}">
      </div>
      <div class="popup__body pl-4 pr-2 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <p class="color-white fs-22" i18n="Czas śnieżenia|Tytył popupu zmiany czasu śnieżenia@@timeRangePopupTitle">Czas śnieżenia</p>
          <button (click)="closeTimeRangePopup()" class="popup__close">
            <svg>
              <use xlink:href='#icon-close'/>
            </svg>
          </button>
        </div>
        <div class="d-flex justify-content-around pb-5 pt-3">
          <ss-time-input [inputValue]="data.timeRange.hourFrom" (changed)="setHourFrom($event)"></ss-time-input>
          <span class="fs-26 px-3 color-white">-</span>
          <ss-time-input [inputValue]="data.timeRange.hourTo" (changed)="setHourTo($event)"></ss-time-input>

          <button class="remove" (click)="remove()" *ngIf="canDelete">
            <svg>
              <use xlink:href='#icon-trash'/>
            </svg>
          </button>
        </div>

        <!-- <ss-checkbox isChecked="true" inputId="one-time-change" inputType="checkbox" inputValue="one-time-change" inputName="one-time-change">&nbsp;Zmiana jednorazowa (tylko najbliższy tydzień)</ss-checkbox> -->
      </div>
    </div>

</div>

