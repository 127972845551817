import { Injectable, ApplicationRef, EventEmitter } from '@angular/core';
import { SocketioClientService, DevicesClientService, AlertsClientService, ScheduleClientService } from './http-clients';
import { interval, Observable, Subject, NEVER, Subscription, of } from 'rxjs';
import { filter, switchMap, buffer, mergeMap, map } from 'rxjs/operators';
import {
  AirPipeline,
  CommunicationLine,
  CompressorRoom,
  ConnectionPoint,
  ElectricalLine,
  MeteoStation,
  PowerSource,
  PumpRoom,
  SnowCannon,
  WaterPipeline,
  Alert,
  ResortArea,
  WorkingArea,
  Schedule,
  Resort,
  WaterReservoir
} from './shared/models';
import { AbstractDevice } from './shared/models/abstract-device.model';
import { Device, isArray } from '../../../common';

const mainLoopInterval = 250;

@Injectable({
  providedIn: 'root'
})
export class ResortService {

  constructor(
    private cd: ApplicationRef,
    private alertsClient: AlertsClientService,
    private schedulesClient: ScheduleClientService,
    private devicesClient: DevicesClientService,
    private socketioClient: SocketioClientService
  ) {
    this.onReadyHandler = new Subject();
    this.onReady = this.onReadyHandler.asObservable();
  }

  public onAlerts: EventEmitter<any> = new EventEmitter();

  private _isReady = false;
  private onReadyHandler: Subject<boolean>;
  public onReady: Observable<boolean>;

  private deviceChangeNotificationSubscription: Subscription;
  private alertNotificationSubscription: Subscription;
  private schemaNotificationSubscription: Subscription;

  private sortedAlertsCache: Alert[] = [];
  private alerts: Map<string, Alert> = new Map();
  private schedules: Map<string, Schedule> = new Map();

  private resort: Map<string, Resort> = new Map();
  private resortAreas: Map<string, ResortArea> = new Map();
  private workingAreas: Map<string, WorkingArea> = new Map();

  private airPipelines: Map<string, AirPipeline> = new Map();
  private communicationLines: Map<string, CommunicationLine> = new Map();
  private compressorRooms: Map<string, CompressorRoom> = new Map();
  private connectionPoints: Map<string, ConnectionPoint> = new Map();
  private electricalLines: Map<string, ElectricalLine> = new Map();
  private meteoStations: Map<string, MeteoStation> = new Map();
  private powerSources: Map<string, PowerSource> = new Map();
  private pumpRooms: Map<string, PumpRoom> = new Map();
  private snowCannons: Map<string, SnowCannon> = new Map();
  private waterPipelines: Map<string, WaterPipeline> = new Map();
  private waterReservoirs: Map<string, WaterReservoir> = new Map();

  private index: Map<string, AbstractDevice> = new Map();

  public isReady(): boolean {
    return this._isReady;
  }

  private setReady() {
    this._isReady = true;
    this.onReadyHandler.next(true);
    this.onReadyHandler.complete();
  }

  public initialize(devices, alerts, schedules) {
    this.mapDevices(devices);
    this.mapAlerts(alerts);
    this.mapSchedules(schedules);

    this.alertNotificationSubscription = this.socketioClient.alertNotification
      .pipe(mergeMap((e) => e))
      .pipe(buffer(interval(mainLoopInterval)))
      .pipe(map(e => e.map(i => parseInt(i, 10)).filter(i => !isNaN(i))))
      .pipe(filter(e => e.length > 0))
      .subscribe(val => {
        const minId = Math.min(...val);
        const maxId = Math.max(...val);
        this.alertsClient.get(minId, maxId).subscribe((data) => {
          this.mapAlerts(data);
        }, () => {});
      });

    this.schemaNotificationSubscription = this.socketioClient.schemaNotification
      .pipe(mergeMap((e) => e))
      .pipe(buffer(interval(mainLoopInterval)))
      .pipe(filter(e => e.length > 0))
      .subscribe(val => {
        this.schedulesClient.getAll().subscribe((data) => {
          this.mapSchedules(data);
        }, () => {});
      });

    const pauser = new Subject();
    const pausable = pauser.pipe(switchMap(paused => paused ? NEVER : interval(mainLoopInterval)));

    this.deviceChangeNotificationSubscription = this.socketioClient.deviceNotification
      .pipe(mergeMap((e) => e))
      .pipe(buffer(pausable))
      .pipe(filter(e => e.length > 0))
      .subscribe(val => {
        val = val.filter((value, index) => val.indexOf(value) === index);
        pauser.next(true);

        this.devicesClient.getByIds(val).subscribe((data) => {
          this.mapDevices(data);

          pauser.next(false);
        }, () => {
          pauser.next(false);
        });
      });
    pauser.next(false);

    this.setReady();
  }

  public getByIds(): Map<string, Device> {
    return this.index;
  }

  public getById(id: string): any {
    return this.index.get(id);
  }

  public getResort(): Resort {
    return Array.from(this.resort.values()).shift();
  }

  public getResortAreas(): ResortArea[] {
    return Array.from(this.resortAreas.values());
  }

  public getWorkingAreas(): WorkingArea[] {
    return Array.from(this.workingAreas.values());
  }

  getAirPipelines(): AirPipeline[] {
    return Array.from(this.airPipelines.values());
  }

  getCommunicationLines(): CommunicationLine[] {
    return Array.from(this.communicationLines.values());
  }

  getCompressorRooms(): CompressorRoom[] {
    return Array.from(this.compressorRooms.values());
  }

  getConnectionPoints(): ConnectionPoint[] {
    return Array.from(this.connectionPoints.values());
  }

  getConnectionPoint(id: string): ConnectionPoint {
    return this.connectionPoints.get(id);
  }

  getElectricalLines(): ElectricalLine[] {
    return Array.from(this.electricalLines.values());
  }

  getMeteoStations(): MeteoStation[] {
    return Array.from(this.meteoStations.values());
  }

  getPowerSources(): PowerSource[] {
    return Array.from(this.powerSources.values());
  }

  getPumpRooms(): PumpRoom[] {
    return Array.from(this.pumpRooms.values());
  }

  getWaterReservoirs(): WaterReservoir[] {
    return Array.from(this.waterReservoirs.values());
  }

  getSnowCannons(): SnowCannon[] {
    return Array.from(this.snowCannons.values());
  }

  getWaterPipeline(id: string): WaterPipeline {
    return this.waterPipelines.get(id);
  }

  getWaterPipelines(): WaterPipeline[] {
    return Array.from(this.waterPipelines.values());
  }

  getResortArea(id: string): ResortArea {
    return this.resortAreas.get(id);
  }

  getWorkingArea(id: string): WorkingArea {
    return this.workingAreas.get(id);
  }

  getSnowCannon(id: string): SnowCannon {
    return this.snowCannons.get(id);
  }

  getElectricalLine(id: string): ElectricalLine {
    return this.electricalLines.get(id);
  }

  getPowerSource(id: string): PowerSource {
    return this.powerSources.get(id);
  }

  getPumpRoom(id: string): PumpRoom {
    return this.pumpRooms.get(id);
  }

  getWaterReservoir(id: string): WaterReservoir {
    return this.waterReservoirs.get(id);
  }

  getMeteoStation(id: string): MeteoStation {
    return this.meteoStations.get(id);
  }

  setLocalLockOnDevice(id: string) {
    const lockedDevice = this.index.get(id);
    if (lockedDevice) {
      lockedDevice['isLocked'] = true;
      this.index.set(id, lockedDevice);
    }
  }

  private mapDeviceGroup<T extends AbstractDevice>(data: any[], model: Map<string, T>, c: new() => T): Map<string, T> {
    if (!model) {
      model = new Map<string, T>();
    }
    if (data && data.forEach) {
      data.forEach(d => {
        if (d['id']) {
          let newData = new c();
          if (model.has(d['id'])) {
            newData = model.get(d['id']);
          }
          newData.update(d);

          model.set(d['id'], newData);
          this.index.set(d['id'], newData);
        }
      });
    }
    return model;
  }

  private mapDevices(dv) {
    this.resort = this.mapDeviceGroup<Resort>(dv['resort'], this.resort, Resort);
    this.resortAreas = this.mapDeviceGroup<ResortArea>(dv['resortAreas'], this.resortAreas, ResortArea);
    this.workingAreas = this.mapDeviceGroup<WorkingArea>(dv['workingAreas'], this.workingAreas, WorkingArea);
    this.airPipelines = this.mapDeviceGroup<AirPipeline>(dv['airPipelines'], this.airPipelines, AirPipeline);
    this.communicationLines = this.mapDeviceGroup<CommunicationLine>(dv['communicationLines'], this.communicationLines, CommunicationLine);
    this.compressorRooms = this.mapDeviceGroup<CompressorRoom>(dv['compressorRooms'], this.compressorRooms, CompressorRoom);
    this.connectionPoints = this.mapDeviceGroup<ConnectionPoint>(dv['connectionPoints'], this.connectionPoints, ConnectionPoint);
    this.electricalLines = this.mapDeviceGroup<ElectricalLine>(dv['electricalLines'], this.electricalLines, ElectricalLine);
    this.meteoStations = this.mapDeviceGroup<MeteoStation>(dv['meteoStations'], this.meteoStations, MeteoStation);
    this.powerSources = this.mapDeviceGroup<PowerSource>(dv['powerSources'], this.powerSources, PowerSource);
    this.pumpRooms = this.mapDeviceGroup<PumpRoom>(dv['pumpRooms'], this.pumpRooms, PumpRoom);
    this.snowCannons = this.mapDeviceGroup<SnowCannon>(dv['snowCannons'], this.snowCannons, SnowCannon);
    this.waterPipelines = this.mapDeviceGroup<WaterPipeline>(dv['waterPipelines'], this.waterPipelines, WaterPipeline);
    this.waterReservoirs = this.mapDeviceGroup<WaterReservoir>(dv['waterReservoirs'], this.waterReservoirs, WaterReservoir);

    this.resortAreas.forEach(ar => {
      if (ar.remoteMeteoStation) {
        ar.remoteMeteoStationRef = this.meteoStations.get(ar.remoteMeteoStation);
        if (!ar.remoteMeteoStationRef) {
          ar.remoteMeteoStationRef = this.snowCannons.get(ar.remoteMeteoStation);
        }
      }
    });

    this.workingAreas.forEach(wr => {
      if (!wr.resortArea && wr.resortAreaRef) {
        wr.resortAreaRef.workingAreasRefs.delete(wr.id);
      } else if (wr.resortArea) {
        wr.resortAreaRef = this.resortAreas.get(wr.resortArea);
        if(wr.resortAreaRef) {
          wr.resortAreaRef.workingAreasRefs.set(wr.id, wr);
        }
      }

      if (wr.remoteMeteoStation) {
        wr.remoteMeteoStationRef = this.meteoStations.get(wr.remoteMeteoStation);
        if (!wr.remoteMeteoStationRef) {
          wr.remoteMeteoStationRef = this.snowCannons.get(wr.remoteMeteoStation);
        }
      }
    });

    this.electricalLines.forEach(el => {
      if(el.powerSource) {
        el.powerSourceRef = this.powerSources.get(el.powerSource);
        if(el.powerSourceRef) {
          el.powerSourceRef.electricalLinesRefs.set(el.id, el);
        }
      }
    });

    this.waterPipelines.forEach(wp => {
      if (wp.waterSource) {
        wp.pumpRoomRef = this.pumpRooms.get(wp.waterSource);
        if (wp.pumpRoomRef) {
          wp.pumpRoomRef.waterPipelineRefs.set(wp.id, wp);
        }
      }
      if (wp.waterReservoir) {
        wp.waterReservoirRef = this.waterReservoirs.get(wp.waterReservoir);
        if (wp.waterReservoirRef) {
          wp.waterReservoirRef.waterPipelines.set(wp.id, wp);
        }
      }
    });

    this.connectionPoints.forEach((cp) => {
      if (cp.waterPipeline) {
          cp.waterPipelineRef = this.waterPipelines.get(cp.waterPipeline);
          if (cp.waterPipelineRef.waterSource) {
            cp.waterPipelineRef.pumpRoomRef = this.pumpRooms.get(cp.waterPipelineRef.waterSource);
          }
      }
      if (cp.airPipeline) {
          cp.airPipelineRef = this.airPipelines.get(cp.airPipeline);
      }
      if (cp.electricalLine) {
          cp.electricalLineRef = this.electricalLines.get(cp.electricalLine);
      }
      if (cp.communicationLine) {
          cp.communicationLineRef = this.communicationLines.get(cp.communicationLine);
      }
      if (cp.resortArea) {
        cp.resortAreaRef = this.resortAreas.get(cp.resortArea);
        if (cp.resortAreaRef) {
          cp.resortAreaRef.conenctionPointRefs.set(cp.id, cp);

          if (cp.waterPipelineRef) {
            cp.waterPipelineRef.resortAreaRefs.set(cp.resortAreaRef.id, cp.resortAreaRef);
            cp.resortAreaRef.waterPipelineRefs.set(cp.waterPipelineRef.id, cp.waterPipelineRef);
          }

          if (cp.electricalLineRef) {
            cp.electricalLineRef.resortAreaRefs.set(cp.resortAreaRef.id, cp.resortAreaRef);
            cp.resortAreaRef.electricalLineRefs.set(cp.electricalLineRef.id, cp.electricalLineRef);
          }

          if (cp.waterPipelineRef && cp.waterPipelineRef.waterSource) {
            cp.resortAreaRef.pumpRoomRefs
              .set(cp.waterPipelineRef.waterSource, this.pumpRooms.get(cp.waterPipelineRef.waterSource));

            if (cp.waterPipelineRef.waterReservoirRef) {
              cp.resortAreaRef.waterReservoirRefs
                .set(cp.waterPipelineRef.waterReservoirRef.id, cp.waterPipelineRef.waterReservoirRef);
            }
          }
          if (cp.airPipelineRef && cp.airPipelineRef.airSource) {
            cp.resortAreaRef.compressorRoomRefs
              .set(cp.airPipelineRef.airSource, this.compressorRooms.get(cp.airPipelineRef.airSource));
          }
          if (cp.electricalLineRef && cp.electricalLineRef.powerSource) {
            cp.resortAreaRef.powerSourceRefs
              .set(cp.electricalLineRef.powerSource, this.powerSources.get(cp.electricalLineRef.powerSource));
          }
        }
      }
      if (!cp.workingArea && cp.workingAreaRef) {
        cp.workingAreaRef = null;
      } else if (cp.workingArea) {
        cp.workingAreaRef = this.workingAreas.get(cp.workingArea);
        if (cp.workingAreaRef) {
          cp.workingAreaRef.conenctionPointRefs.set(cp.id, cp);
        }
      }
    });

    this.snowCannons.forEach((sc) => {
      sc.calculateCapabilities();
      // add resetting of connection point relations
      // if sc.connectionPoint is null and sc.conenctionPointRef is not
      if (sc.connectionPoint) {
        sc.conenctionPointRef = this.connectionPoints.get(sc.connectionPoint);
        if (sc.conenctionPointRef.resortAreaRef) {
          sc.conenctionPointRef.resortAreaRef.snowCannonRefs.set(sc.id, sc);
        } else {
          this.resortAreas.forEach(wr => {
            wr.snowCannonRefs.delete(sc.id);
          });
        }

        if (sc.conenctionPointRef.workingAreaRef) {
          sc.conenctionPointRef.workingAreaRef.snowCannonRefs.set(sc.id, sc);
        } else {
          this.workingAreas.forEach(wr => {
            wr.snowCannonRefs.delete(sc.id);
          });
        }
        if (sc.conenctionPointRef) {
          sc.conenctionPointRef.snowCannonRefs.set(sc.id, sc);
        }

        if (sc.remoteMeteoStation) {
          sc.remoteMeteoStationRef = this.meteoStations.get(sc.remoteMeteoStation);
          if (!sc.remoteMeteoStationRef) {
            sc.remoteMeteoStationRef = this.snowCannons.get(sc.remoteMeteoStation);
          }
        } else {
          sc.remoteMeteoStationRef = null;
        }
      }
    });
  }

  private mapSchedules(schedules) {
    if (schedules) {
      const scheduleIds: string[] = [];
      for (const s of Object.keys(schedules)) {
        const dt = schedules[s];
        const id = '' + dt['id'];
        scheduleIds.push(id);
        if (id) {
          this.schedules.set(id, dt);
        }
      }

      const existingKeys: string[] = Array.from(this.schedules.keys());
      for (const a of Object.keys(existingKeys)) {
        if (!scheduleIds.includes(existingKeys[a])) {
          this.schedules.delete(existingKeys[a]);
        }
      }
    }
  }

  getSchedules(): Schedule[] {
    return Array.from(this.schedules.values());
  }

  getSchedule(id: string): Schedule {
    // console.log(this.schedules, id, this.schedules.get(id));
    return this.schedules.get(id);
  }

  private mapAlerts(alerts) {
    let hasChanged = false;
    let addingNewAlerts = false;
    if (alerts && alerts['alerts']) {
      for (const dt of Object.values(alerts['alerts'])) {
        const id = `${dt['id']}`;
        if (id) {
          const alert = Alert.create(dt);
          if (!alert.deviceReference && alert.deviceId) {
            alert.deviceReference = this.index.get(alert.deviceId);
          }
          if (this.alerts.has(id)) {
            if (!this.alerts.get(id).isEqual(alert)) {
              hasChanged = hasChanged || true;
              this.alerts.set(id, Object.assign(this.alerts.get(id), alert));
            }
          } else {
            hasChanged = hasChanged || true;
            addingNewAlerts = addingNewAlerts || true;
            this.alerts.set(id, alert);
          }
        }
      }
    }

    if (addingNewAlerts) {
      this.sortedAlertsCache = Array.from(this.alerts.values()).sort((a, b) => b.id - a.id);
    }
    if (hasChanged) {
      this.onAlerts.emit(null);
    }
  }

  public requestNewerAlerts(): Observable<any> {
    return of(true);
  }

  public requestOlderAlerts(): Observable<any> {
    return of(true);
  }

  public filterAlerts(ids: string[], levels: string[], onlyActive: boolean = false, idTo: number = 0, idFrom: number = 0): Alert[] {
    return this.sortedAlertsCache.filter(el => {
      let ret = false;
      if (el.id >= idFrom) {
        ret = true;
      }
      if (ret && idTo !== 0 && el.id < idTo) {
        ret = true;
      }

      if (ret && onlyActive) {
        ret = !el.expired;
      }
      if (ret && levels && isArray(levels) && levels.length > 0) {
        ret = levels.includes(el.level);
      }
      if (ret && ids && isArray(ids) && ids.length > 0) {
        ret = ids.includes(el.deviceId);
      }
      return ret;
    });
  }

  public getAlerts(): Alert[] {
    return this.sortedAlertsCache;
  }

}
