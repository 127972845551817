<div class="electrical-line" [ngClass]="{'electrical-line--expanded': areasListExpanded}">

  <div class="px-2 py-2">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <svg class="electrical-line__icon pr-1">
          <use xlink:href="#icon-thunder" />
        </svg>
        <div>
          <p class="fs-20 color-white pb-1">{{ electricalLine.symbol }}</p>
        </div>
      </div>
    </div>

    <div class="row pt-3">
      <div class="col">
        <ss-d3-power-draw-chart [device]="electricalLine"></ss-d3-power-draw-chart>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center pt-2">
      <div class="col-4">
        <ss-pump-room-detail-status [status]="electricalLine.computedStatus"></ss-pump-room-detail-status>
      </div>

      <div *ngIf="electricalLine.configuration.hasMeteredPowerConsumption">
        <p class="fs-14 color-mid-grey pb-2" i18n="Zużycie mocy|Etykieta pola Zużycie mocy@@powerSourceElectricalPowerDraw">Zużycie mocy</p>
        <p class="fs-14 color-white white-space-nowrap">{{ electricalLine.meteredPowerConsumption|number:'1.0-0' }} kWh</p>
      </div>
      <div *ngIf="electricalLine.configuration.hasCurrentPowerDraw">
        <p class="fs-14 color-mid-grey pb-2" i18n="Moc chwilowa|Etykieta pola Moc chwilowa@@powerSourceElectricalCurrentPowerDraw">Moc chwilowa</p>
        <p class="fs-14 color-white white-space-nowrap">{{ electricalLine|convertedValue:'currentPowerDraw' }} {{ electricalLine|convertedUnit:'currentPowerDraw' }}</p>
      </div>
      <div *ngIf="electricalLine.configuration.hasAveragePowerDraw">
        <p class="fs-14 color-mid-grey pb-2" i18n="Moc średnia|Etykieta pola Moc średnia@@powerSourceElectricalAveragePowerDraw">Moc średnia</p>
        <p class="fs-14 color-white white-space-nowrap">{{ electricalLine|convertedValue:'averagePowerDraw' }} {{ electricalLine|convertedUnit:'averagePowerDraw' }}</p>
      </div>
      <div *ngIf="electricalLine.configuration.hasReactivePowerDraw">
        <p class="fs-14 color-mid-grey pb-2" i18n="Moc bierna|Etykieta pola Moc bierna@@powerSourceElectricalReactivePowerDraw">Moc bierna</p>
        <p class="fs-14 color-white white-space-nowrap">{{ electricalLine.reactivePowerDraw | number:'1.1-1' }} kVA</p>
      </div>
      <div *ngIf="electricalLine.configuration.hasAverageCurrentOfAllPhases">
        <p class="fs-14 color-mid-grey pb-2" i18n="Średni prąd|Etykieta pola Średni prąd@@powerSourceElectricalAverageCurrentOfAllPhases">Średni prąd</p>
        <p class="fs-14 color-white white-space-nowrap">{{ electricalLine.averageCurrentOfAllPhases | number:'1.1-1' }} A</p>
      </div>


    </div>
  </div>
  <div class="electrical-line__footer d-flex">
    <div class="col-6">
      <button (click)="areasListExpanded = !areasListExpanded"
        class="w-100 py-2 px-4 color-white d-flex align-items-center justify-content-between">
        <ng-container i18n="Trasy|Nazwa rozwijanej listy z dostępnymi trasami@@powerSourceElectricalLineRoutes">Trasy
        </ng-container> ({{ electricalLine.countResortAreas() }})
        <svg class="electrical-line__chevron"
          [ngClass]="{'electrical-line__chevron--rotated': areasListExpanded}">
          <use xlink:href='#icon-chevron-down' />
        </svg>
      </button>
    </div>
    <div class="col-6">
        <button class="w-100 py-2 px-4 d-flex align-items-center justify-content-start"
          *ngIf="electricalLine.configuration.hasCurrentPowerDraw"
          [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
          [disabled]="!electricalLine.connectionStatus"
          (click)="oppenConfigModal()">
          <svg class="button-icon pr-1">
              <use xlink:href="#icon-gear" />
          </svg>
          <ng-container i18n="Ustawienia|Ustawienia@@powerSourceElectricalLineSettings">Ustawienia</ng-container>
        </button>
    </div>
  </div>
  <div class="footer-dropdown" [ngClass]="{'footer-dropdown--expanded': areasListExpanded}">
    <table class="footer-dropdown__table w-100">
      <tr class="footer-dropdown__row" *ngFor="let a of electricalLine.getResortAreas()">
        <td class="footer-dropdown__cell w-100 py-2 pl-4 pr-2 color-white">
            {{ a.symbol}}
        </td>
        <td class="footer-dropdown__cell py-2 pr-2 text-right white-space-nowrap color-green">
          {{ a|convertedValue:'computedPowerDraw' }}
          {{ a|convertedUnit:'computedPowerDraw' }}
        </td>
        <td class="footer-dropdown__cell py-2">
          <div style="width: 200px">
            <ss-d3-linked-mini-bar-chart [viewBoxWidth]="200" tag="compare-areas" [device]="a"></ss-d3-linked-mini-bar-chart>
          </div>
        </td>
        <td class="footer-dropdown__cell text-right">
          <button [routerLink]="['/application/resort/area', a.id, 'list']">
            <svg class="footer-dropdown__icon">
              <use xlink:href='#icon-chevron-down' />
            </svg>
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
