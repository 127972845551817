import { trn } from './translation-wrapper.function';

export const translateReportFieldName = (fieldName: string): string => {
  switch(fieldName) {
    case 'airHumidity':
      return trn($localize`:Nazwa pola w raportach 'Wilgotność względna powietrza'|
        @@deviceFieldNameAirHumidity:Wilgotność względna powietrza`);
    case 'airTemperature':
      return trn($localize`:Nazwa pola w raportach 'Temperatura powietrza'|
        @@deviceFieldNameAirTemperature:Temperatura powietrza`);
    case 'atmosphericAirPressure':
      return trn($localize`:Nazwa pola w raportach 'Ciśnienie atmosferyczne'|
        @@deviceFieldNameAtmosphericAirPressure:Ciśnienie atmosferyczne`);
    case 'averageCurrentOfAllPhases':
      return trn($localize`:Nazwa pola w raportach 'Średni prąd dla wszystkich faz'|
        @@deviceFieldNameAverageCurrentOfAllPhases:Średni prąd dla wszystkich faz`);
    case 'averagePowerDraw':
      return trn($localize`:Nazwa pola w raportach 'Moc średnia'|
        @@deviceFieldNameAveragePowerDraw:Moc średnia`);
    case 'averageVoltagePhaseToNeutral':
      return trn($localize`:Nazwa pola w raportach 'Średnie napięcie L-N'|
        @@deviceFieldNameAverageVoltagePhaseToNeutral:Średnie napięcie L-N`);
    case 'averageVoltagePhaseToPhase':
      return trn($localize`:Nazwa pola w raportach 'Średnie napięcie dla wszystkich faz'|
        @@deviceFieldNameAverageVoltagePhaseToPhase:Średnie napięcie dla wszystkich faz`);
    case 'currentPhaseL1':
      return trn($localize`:Nazwa pola w raportach 'Prąd L1'|
        @@deviceFieldNameCurrentPhaseL1:Prąd L1`);
    case 'currentPhaseL2':
      return trn($localize`:Nazwa pola w raportach 'Prąd L2'|
        @@deviceFieldNameCurrentPhaseL2:Prąd L2`);
    case 'currentPhaseL3':
      return trn($localize`:Nazwa pola w raportach 'Prąd L3'|
        @@deviceFieldNameCurrentPhaseL3:Prąd L3`);
    case 'meteredPowerConsumption':
      return trn($localize`:Nazwa pola w raportach 'Zużycie prądu'|
        @@deviceFieldNameMeteredPowerConsumption:Zużycie prądu`);
    case 'meteredWaterConsumption':
      return trn($localize`:Nazwa pola w raportach 'Zużycie wody'|
        @@deviceFieldNameMeteredWaterConsumption:Zużycie wody`);
    case 'powerConsumption':
      return trn($localize`:Nazwa pola w raportach 'Zużycie prądu'|
        @@deviceFieldNamePowerConsumption:Zużycie prądu`);
    case 'powerDraw':
      return trn($localize`:Nazwa pola w raportach 'Moc chwilowa'|
        @@deviceFieldNamePowerDraw:Moc chwilowa`);
    case 'reactivePowerDraw':
      return trn($localize`:Nazwa pola w raportach 'Moc bierna'|
        @@deviceFieldNameReactivePowerDraw:Moc bierna`);
    case 'snowProductionVolume':
      return trn($localize`:Nazwa pola w raportach 'Produkcja śniegu'|
        @@deviceFieldNameSnowProductionVolume:Produkcja śniegu`);
    case 'voltagePhaseL1ToL2':
      return trn($localize`:Nazwa pola w raportach 'Napięcie L1-L2'|
        @@deviceFieldNameVoltagePhaseL1ToL2:Napięcie L1-L2`);
    case 'voltagePhaseL1ToN':
      return trn($localize`:Nazwa pola w raportach 'Napięcie L1-N'|
        @@deviceFieldNameVoltagePhaseL1ToN:Napięcie L1-N`);
    case 'voltagePhaseL2ToL3':
      return trn($localize`:Nazwa pola w raportach 'Napięcie L2-L3'|
        @@deviceFieldNameVoltagePhaseL2ToL3:Napięcie L2-L3`);
    case 'voltagePhaseL2ToN':
      return trn($localize`:Nazwa pola w raportach 'Napięcie L2-N'|
        @@deviceFieldNameVoltagePhaseL2ToN:Napięcie L2-N`);
    case 'voltagePhaseL3ToL1':
      return trn($localize`:Nazwa pola w raportach 'Napięcie L3-L1'|
        @@deviceFieldNameVoltagePhaseL3ToL1:Napięcie L3-L1`);
    case 'voltagePhaseL3ToN':
      return trn($localize`:Nazwa pola w raportach 'Napięcie L3-N'|
        @@deviceFieldNameVoltagePhaseL3ToN:Napięcie L3-N`);
    case 'waterFlow':
      return trn($localize`:Nazwa pola w raportach 'Przepływ wody'|
        @@deviceFieldNameWaterFlow:Przepływ wody`);
    case 'waterLevel':
      return trn($localize`:Nazwa pola w raportach 'Poziom zbiornika'|
        @@deviceFieldNameWaterLevel:Poziom zbiornika`);
    case 'waterPressure':
      return trn($localize`:Nazwa pola w raportach 'Ciśnienie wody'|
        @@deviceFieldNameWaterPressure:Ciśnienie wody`);
    case 'waterTemperature':
      return trn($localize`:Nazwa pola w raportach 'Temperatura wody'|
        @@deviceFieldNameWaterTemperature:Temperatura wody`);
    case 'waterVolume':
      return trn($localize`:Nazwa pola w raportach 'Ilość wody'|
        @@deviceFieldNameWaterVolume:Ilość wody`);
    case 'wetBulbTemperature':
      return trn($localize`:Nazwa pola w raportach 'Temperatura mokrego termometru'|
        @@deviceFieldNameWetBulbTemperature:Temperatura mokrego termometru`);
    case 'windSpeed':
      return trn($localize`:Nazwa pola w raportach 'Prędkość wiatru'|
        @@deviceFieldNameWindSpeed:Prędkość wiatru`);
  }
  return fieldName;
};
