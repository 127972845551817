import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'ss-control-button',
  templateUrl: './control-button.component.html',
  styleUrls: ['./control-button.component.sass']
})
export class ControlButtonComponent implements OnInit {

  @Input() size = '';
  @Input() action = '';
  @Input() disabled = false;

  constructor() { }

  ngOnInit() {
  }

}
