import { dayjs } from '../../../../../common';

export class ScheduleSchema {
    id?: string = null;
    name: string;
    startTemperature = 0;
}

export class ScheduleDay {
    id?: string = null;
    hourFrom: number;
    hourTo: number;
    weekDay: number;

    isMoving = false;
    isEdited = false;

    get from(): number {
        return 10000 * this.weekDay + this.hourFrom;
    }

    get to(): number {
        return 10000 * this.weekDay + this.hourTo;
    }

    get start(): number {
        return this.hourFrom;
    }
    set start(val: number) {
        this.hourFrom = val;
    }

    get end(): number {
        return this.hourTo;
    }
    set end(val: number) {
        this.hourTo = val;
    }

    isBetween(t: number): boolean {
        return t >= this.from && t <= this.to;
    }

    constructor(day: number = 0, from: number = 0, to: number = 1440) {
        this.weekDay = day;
        this.hourFrom = from;
        this.hourTo = to;
    }

    toString(): string {
        const p = (n: number): string  => ('' + n).padStart(2, '0');
        const h = (n: number): string  => p(Math.floor(n / 60));
        const m = (n: number): string  => p((n - 60 * Math.floor(n / 60)));
        const f = (n: number): string  => h(n) + ':' + m(n);

        return f(this.hourFrom) + ' - ' + f(this.hourTo);
    }
}

export class Schedule {
    id?: string = null;
    schema: ScheduleSchema;
    monday: ScheduleDay[] = [];
    tuesday: ScheduleDay[] = [];
    wednesday: ScheduleDay[] = [];
    thursday: ScheduleDay[] = [];
    friday: ScheduleDay[] = [];
    saturday: ScheduleDay[] = [];
    sunday: ScheduleDay[] = [];


    areaIds: string[] = [];

    public static create(): Schedule {
        return Object.assign(new Schedule(), {
            id: null,
            schema: Object.assign(new ScheduleSchema(), {
              id: null,
              name: '',
              startTemperature: 0.0
            }),
            monday: [new ScheduleDay(1)],
            tuesday: [new ScheduleDay(2)],
            wednesday: [new ScheduleDay(3)],
            thursday: [new ScheduleDay(4)],
            friday: [new ScheduleDay(5)],
            saturday: [new ScheduleDay(6)],
            sunday: [new ScheduleDay(7)]
        });
    }

    public clone(): Schedule {
        const deepCopy = Object.assign(new Schedule(), { ... this });
        deepCopy.schema = Object.assign(new ScheduleSchema(), { ... deepCopy.schema });
        deepCopy.monday = deepCopy.monday.map(dc => Object.assign(new ScheduleDay(), { ... dc }) );
        deepCopy.tuesday = deepCopy.tuesday.map(dc => Object.assign(new ScheduleDay(), { ... dc }) );
        deepCopy.wednesday = deepCopy.wednesday.map(dc => Object.assign(new ScheduleDay(), { ... dc }) );
        deepCopy.thursday = deepCopy.thursday.map(dc => Object.assign(new ScheduleDay(), { ... dc }) );
        deepCopy.friday = deepCopy.friday.map(dc => Object.assign(new ScheduleDay(), { ... dc }) );
        deepCopy.saturday = deepCopy.saturday.map(dc => Object.assign(new ScheduleDay(), { ... dc }) );
        deepCopy.sunday = deepCopy.sunday.map(dc => Object.assign(new ScheduleDay(), { ... dc }) );
        return deepCopy;
    }

    getDay(position: number): ScheduleDay[] {
        switch (position) {
            case 1:
                return this.monday;
            case 2:
                return this.tuesday;
            case 3:
                return this.wednesday;
            case 4:
                return this.thursday;
            case 5:
                return this.friday;
            case 6:
                return this.saturday;
            case 7:
                return this.sunday;
        }
        return [];
    }

    removeScheduleDay(day: ScheduleDay) {
        const dArr = this.getDay(day.weekDay);
        dArr.splice(dArr.indexOf(day), 1);
    }

    getScheduleDays(): ScheduleDay[] {
        return [].concat(
            this.monday,
            this.tuesday,
            this.wednesday,
            this.thursday,
            this.friday,
            this.saturday,
            this.sunday
        );
    }

    getClosest(): string {
      const t = dayjs();
        let day = t.day();
        const hour = 60 * t.hour() + t.minute();
        day = day === 0 ? 7 : day;

        const hourDay = day * 10000 + hour;

        const schemasByDay = this.getScheduleDays().sort((a, b) => a.from - b.to);

        const suplement = schemasByDay.map(d => {
            const sd = Object.assign(new ScheduleDay(), d);
            sd.weekDay = sd.weekDay + 7;
            return sd;
        });

        schemasByDay.push(... suplement);

        let schemaDay: ScheduleDay;
        schemasByDay.forEach(s => {
            if (!schemaDay) {
                if (s.isBetween(hourDay) || s.from > hourDay) {
                    schemaDay = s;
                }
            }
        });

        if (schemaDay === undefined) {
            return '??:?? - ??:??';
        }

        return schemaDay.toString();
    }
}
