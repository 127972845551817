<ng-container *ngIf="snowCannon.CAP_SHOW_HEATERS_STATE">
  <ng-container *ngIf="snowCannon.CAP_CHANGE_HEATERS_STATE; else canNotChange">
    <ss-snow-cannon-component-button deviceType="heater"
      [isBreakdown]="snowCannon.failureRingHeatersFault"
      [isActive]="snowCannon.isHeatersOn"
      (click)="toggleHeater()"
      [mode]="snowCannon.commandStatusRingHeating ? 'auto' : 'manual'"
      [size]="size"></ss-snow-cannon-component-button>
  </ng-container>
  <ng-template #canNotChange>
    <ss-snow-cannon-component-button deviceType="heater"
      [isBreakdown]="snowCannon.failureRingHeatersFault"
      [isActive]="snowCannon.isHeatersOn"
      [size]="size"></ss-snow-cannon-component-button>
  </ng-template>
</ng-container>