import { Injectable } from '@angular/core';
import { ResortService } from 'src/app/resort.service';
import { DevicesClientService } from 'src/app/http-clients';
import { Observable, Subject } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { ResortArea, Schedule } from 'src/app/shared/models';

export class ScheduleEditData {
  type: string;
  areas: ResortArea[];
  schedule: Schedule;
}

@Injectable({
  providedIn: 'root'
})
export class EditDataResolverService implements Resolve<ScheduleEditData> {

  constructor(private rs: ResortService, private dd: DevicesClientService) {}

  getData(id: string): Observable<ScheduleEditData> | Observable<never> | ScheduleEditData {
    const areas = this.rs.getResortAreas();
    let schedule = this.rs.getSchedule(id);

    let type = 'edit';

    if (!schedule) {
      type = 'add';
      schedule = Schedule.create();
    }

    return {
      type,
      areas,
      schedule
    };
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ScheduleEditData> | Observable<never> | ScheduleEditData {
    const id = route.paramMap.get('id') || route.parent.paramMap.get('id');

    return this.getData(id);
  }
}
