import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { TabComponent } from '../tab.component';

@Component({
  selector: 'ss-user-notes-tab',
  templateUrl: './user-notes-tab.component.html',
  styleUrls: [],
  providers: [
    {provide: TabComponent, useExisting: forwardRef(() => UserNotesTabComponent), multi: true}
  ]
})
export class UserNotesTabComponent extends TabComponent {

  @Input()
  public context: string;

  public updateCount(ev) {
    setTimeout(() => {
      this.noLevelInfos = ev;
    });
  }

  constructor() {
    super();
  }

}
