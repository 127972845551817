<div class="info-row">
  <div class="row">
    <div class="col">
      <h3 i18n="Konfiguracja stoku|Tytuł@@resortAreaSettingsTabAreaConfigTitle">Konfiguracja stoku</h3>
      <dl>
        <dt i18n="Źródło danych meteo|Nagłówek@@resortAreaSettingsTabRemoteMeteoStationOfResortAreaTitle">Źródło danych meteo</dt>
        <dd *ngIf="area.remoteMeteoStationRef; else resortAreaSettingsTabRemoteMeteoStationOfResortArea">
          <ng-container i18n="Stok wykorzystje dane meteo z|Info@@resortAreaSettingsTabRemoteMeteoStationOfResortAreaDesc">Stok wykorzystje dane meteo z</ng-container>&nbsp;
          <span><ss-translated-device-type-symbol [device]="area.remoteMeteoStationRef"></ss-translated-device-type-symbol></span>
        </dd>
        <ng-template #resortAreaSettingsTabRemoteMeteoStationOfResortArea>
          <dd i18n="Nie zdefiniowano stacji meteo dla stoku|Brak@@resortAreaSettingsTabRemoteMeteoStationOfResortAreaNotDefined">Nie zdefiniowano stacji meteo dla stoku</dd>
        </ng-template>
      </dl>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-default"
        i18n="Zmień ustawienia limitów stoku|Przycisk ustawień stoku@@resortAreaSettingsTabBtnCfgResortArea"
        (click)="goToAreaConfig()">Zmień ustawienia limitów stoku</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
<!--
      <dl>
        <dt>Priorytet śnieżenia</dt>
        <dd *ngIf="area.priority; else resortAreaSettingsTabAreaPriority">
          <ng-container>Stok ma priorytet w wysokości</ng-container>&nbsp;
          <span>{{ area.priority|number }}</span>
        </dd>
        <ng-template #resortAreaSettingsTabAreaPriority>
          <dd>Stok pracuje z domyślnym priorytetem</dd>
        </ng-template>
        <dt>Limity całego stoku na sezon</dt>
        <dd *ngIf="area.limitWindSpeed > 0">
          Armatki zostaną wyłączona przy wietrze o prędkości powyżej
          <span>{{ area.limitWindSpeed|number:'1.0-0' }}m/s</span>
        </dd>
        <dd *ngIf="area.limitWindSpeed <= 0">Wyłączona</dd>
        <dt>Limit produkcji śniegu</dt>
        <dd *ngIf="area.limitSnowProductionVolume > 0">
          Armatki zostaną wyłączona po wyprodukowaniu
          <span>{{ area.limitSnowProductionVolume|number:'1.0-0' }}m3</span>
        </dd>
        <dd *ngIf="area.limitSnowProductionVolume <= 0">Wyłączony</dd>
        <dt>Limit zużycia wody</dt>
        <dd *ngIf="area.limitWaterConsumption > 0">
          Armatki zostaną wyłączona po zużyciu
          <span>{{ area.limitWaterConsumption|number:'1.0-0' }}m3</span>
        </dd>
        <dd *ngIf="area.limitWaterConsumption <= 0">Wyłączony</dd>
        <dt>Limit zużycia prądu</dt>
        <dd *ngIf="area.limitPowerConsumption > 0">
          Armatki zostaną wyłączona po zużyciu
          <span>{{ area.limitPowerConsumption|number:'1.0-0' }}kWh</span>
        </dd>
        <dd *ngIf="area.limitPowerConsumption <= 0">Wyłączonay</dd>
      </dl>
-->


    </div>
  </div>
</div>

<div class="info-row" *ssdHideIfNotGranted="'ROLE_SUPERADMIN'">
  <div class="row">
    <div class="col">
      <h3 i18n="Konfiguracja grup|Tytuł@@resortAreaSettingsTabGroupConfigTitle">Konfiguracja grup</h3>
      <dl *ngIf="!area.getWorkingAreas() || area.getWorkingAreas().length <= 0">
        <dt i18n="Brak zdefiniowanych grup|Brak@@resortAreaSettingsTabNoGroupsDefined">Brak zdefiniowanych grup</dt>
      </dl>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-default"
        i18n="Konfiguruj grupy hydrantów|Przejście do konfiguratora grup@@resortAreaSettingsTabBtnCfgWorkingGroups"
        [routerLink]="['/application/resort/area', area.id, 'configure']">Konfiguruj grupy hydrantów</button>
    </div>
  </div>
  <div class="row" *ngIf="area.getWorkingAreas() && area.getWorkingAreas().length > 0">
    <div class="col">
      <ng-container *ngFor="let workingArea of area.getWorkingAreas()">
        <h4><ss-translated-device-type-symbol [device]="workingArea"></ss-translated-device-type-symbol></h4>
        <dl>
          <dt i18n="Źródło danych meteo|Nagłówek@@resortAreaSettingsTabRemoteMeteoStationOfWorkingAreaTitle">Źródło danych meteo</dt>
          <dd *ngIf="workingArea.remoteMeteoStationRef; else resortAreaSettingsTabRemoteMeteoStationOfWorkingArea">
            <ng-container i18n="Grupa wykorzystje dane meteo z|Info@@resortAreaSettingsTabRemoteMeteoStationOfWorkingAreaDesc">Grupa wykorzystje dane meteo z</ng-container>&nbsp;
            <span><ss-translated-device-type-symbol [device]="workingArea.remoteMeteoStationRef"></ss-translated-device-type-symbol></span>
          </dd>
          <ng-template #resortAreaSettingsTabRemoteMeteoStationOfWorkingArea>
            <dd i18n="Nie zdefiniowano stacji meteo dla grupy|Brak@@resortAreaSettingsTabRemoteMeteoStationOfWorkingAreaNotDefined">Nie zdefiniowano stacji meteo dla grupy</dd>
          </ng-template>
        </dl>
<!--
        <dl>
          <dt>Priorytet śnieżenia</dt>
          <dd *ngIf="area.priority; else workingAreaSettingsTabAreaPriority">
            <ng-container>Grupa ma priorytet w wysokości</ng-container>&nbsp;
            <span>{{ area.priority|number }}</span>
          </dd>
          <ng-template #workingAreaSettingsTabAreaPriority>
            <dd>Grupa pracuje z domyślnym priorytetem</dd>
          </ng-template>
          <dt>Limity grupy na sezon</dt>
          <dd *ngIf="workingArea.limitWindSpeed <= 0 && workingArea.limitSnowProductionVolume <= 0 && workingArea.limitWaterConsumption <= 0 && workingArea.limitPowerConsumption <= 0"
            >Brak ograniczeń dla grupy. Do wszystkich armatek w grupie będą stosowane ograniczenia stoku.</dd>
          <dd *ngIf="workingArea.limitWindSpeed > 0"
            >Armatki zostaną wyłączona przy wietrze o prędkości powyżej <span>{{ workingArea.limitWindSpeed|number:'1.0-0' }}m/s</span></dd>
          <dd *ngIf="workingArea.limitSnowProductionVolume > 0"
            >Armatki zostaną wyłączona po wyprodukowaniu <span>{{ workingArea.limitSnowProductionVolume|number:'1.0-0' }}m3</span></dd>
          <dd *ngIf="workingArea.limitWaterConsumption > 0"
            >Armatki zostaną wyłączona po zużyciu <span>{{ workingArea.limitWaterConsumption|number:'1.0-0' }}m3</span></dd>
          <dd *ngIf="workingArea.limitPowerConsumption > 0"
            >Armatki zostaną wyłączona po zużyciu <span>{{ workingArea.limitPowerConsumption|number:'1.0-0' }}kWh</span></dd>
        </dl>
-->
      </ng-container>
    </div>
  </div>
</div>
<!-- TODO -->
