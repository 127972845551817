import { Component, Input, OnInit } from '@angular/core';
import { ModalRouteService } from 'src/app/application/modal-route.service';
import { SnowCannon } from 'src/app/shared/models';

@Component({
  selector: 'ss-area-configuration-info-cannon',
  templateUrl: './cannon.component.html',
  styleUrls: []
})
export class CannonComponent implements OnInit {

  @Input()
  public snowCannon: SnowCannon;

  constructor(private hes: ModalRouteService) { }

  ngOnInit(): void {
  }

  public goToCannonConfig(id: string) {
    this.hes.showModalRoute('snow-cannon-config-modal/' + id);
  }
}
