<div [ngClass]="{ 'disabled': lock }" *ngIf="resortArea">
  <ss-page [modal]="true">
      <ss-page-header>
          <ss-page-header-title i18n="Wybór źródła danych meteo|Tytuł modala@@meteoConditionsSourceSelectionModalTitle">Wybór źródła danych meteo</ss-page-header-title>
      </ss-page-header>
      <ss-page-panel>
        <ss-meteo-conditions-source-selector
          [sourceId]="selectedMeteoSourceId"
          [limitToResortArea]="resortArea.id"
          [limitToWorkingArea]="workinngArea ? workinngArea.id : ''"
          [exceludeIds]=""
          (sourceIdChanged)="onMeteoSourceSelection($event)"></ss-meteo-conditions-source-selector>
      </ss-page-panel>
      <ss-page-footer>
          <div></div>
          <ss-page-button-group>
              <button class="btn btn-default" (click)="close()" i18n="Anuluj|Anuluj@@massOperationsModalCancelButon">Anuluj</button>
          </ss-page-button-group>
      </ss-page-footer>
  </ss-page>
</div>
