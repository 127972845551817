export const environment = {
  production: true,
  useMapCache: false,
  version: 'developmentVersionNumber',
  apiHost: window.location.protocol + '//' + window.location.hostname + (
    window.location.port !== undefined && window.location.port !== '80' ? (':' + window.location.port) : ''
  ),
  websocketHost: window.location.protocol + '//' + window.location.hostname + (
    window.location.port !== undefined && window.location.port !== '80' ? (':' + window.location.port) : ''
  ),
  defaultLogin: '',
  defaultPassword: ''
};
