import { Component, OnInit } from '@angular/core';
import { ResortService } from 'src/app/resort.service';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { ActivatedRoute } from '@angular/router';
import { ModalRouteService } from 'src/app/application/modal-route.service';
import { ConnectionPoint, WorkingArea } from 'src/app/shared/models';
import { NIL } from 'uuid';
import { ResortStatisticsService } from 'src/app/resort-statistics.service';
import { isArray } from '../../../../../../../common';

@Component({
  selector: 'ss-connection-point-configuration-modal',
  templateUrl: './connection-point-configuration-modal.component.html',
  styleUrls: ['./connection-point-configuration-modal.component.sass']
})
export class ConnectionPointConfigurationModalComponent implements OnInit {

  public lock = false;
  public device: ConnectionPoint;

  constructor(
    private rs: ResortService,
    private rss: ResortStatisticsService,
    private cs: ConfigureClientService,
    private ar: ActivatedRoute,
    private mr: ModalRouteService
  ) { }

  public baseOrientationOffsetFromNorth: number;
  public priority: number;
  public workingAreaEnabled: boolean;
  public workingArea: WorkingArea;
  public workingAreas: WorkingArea[];
  public limitSnowProductionVolume: number;
  public limitSnowProductionVolumeEnabled: boolean;
  public lastSeasonsSnowProductionVolume: {l: string; v: number }[];
  public limitWaterConsumption: number;
  public limitWaterConsumptionEnabled: boolean;
  public lastSeasonsWaterConsumption: {l: string; v: number }[];
  public lastSeasonsAirConsumption: {l: string; v: number }[];
  public limitPowerConsumption: number;
  public limitPowerConsumptionEnabled: boolean;
  public lastSeasonsPowerConsumption: {l: string; v: number }[];

  close() {
    this.mr.closeModal();
  }

  save() {
    this.lock = true;
    this.cs.configureConnectionPoint(this.device, {
      priority: this.priority,
      baseOrientationOffsetFromNorth: this.baseOrientationOffsetFromNorth,
      workingArea: this.workingArea ? this.workingArea.id : NIL,
      limitSnowProductionVolume: this.limitSnowProductionVolumeEnabled ? this.limitSnowProductionVolume : 0,
      limitWaterConsumption: this.limitWaterConsumptionEnabled ? this.limitWaterConsumption : 0,
      limitPowerConsumption: this.limitPowerConsumptionEnabled ? this.limitPowerConsumption : 0,
    }).subscribe(() => {
      this.lock = false;
      this.close();
    }, (err) => {
      console.error(err);
      this.lock = false;
    });
  }

  ngOnInit(): void {
    this.ar.paramMap.subscribe(params => {
      const deviceId = params.get('connectionPointId');
      const device: ConnectionPoint = this.rs.getById(deviceId);

      if (device) {
        this.device = device;

        this.baseOrientationOffsetFromNorth = device.baseOrientationOffsetFromNorth || 0;
        this.priority = device.priority || 0;
        this.limitSnowProductionVolume = device.limitSnowProductionVolume || 0;
        this.limitSnowProductionVolumeEnabled = !!this.limitSnowProductionVolume;
        this.limitWaterConsumption = device.limitWaterConsumption || 0;
        this.limitWaterConsumptionEnabled = !!this.limitWaterConsumption;
        this.limitPowerConsumption = device.limitPowerConsumption || 0;
        this.limitPowerConsumptionEnabled = !!this.limitPowerConsumption;

        const limits = this.rss.getLimits(deviceId);

        const lastSeasonsSnowProductionVolume = [];
        const lastSeasonsWaterConsumption = [];
        const lastSeasonsAirConsumption = [];
        const lastSeasonsPowerConsumption = [];
        if (limits && isArray(limits.seasonLabels)) {
          limits.seasonLabels.forEach((l, i) => {
            lastSeasonsSnowProductionVolume.push({
              l,
              v: Math.floor(limits.snowProductionVolume[i])
            });
            lastSeasonsWaterConsumption.push({
              l,
              v: Math.floor(limits.waterFlow[i])
            });
            lastSeasonsAirConsumption.push({
              l,
              v: Math.floor(limits.airFlow[i])
            });
            lastSeasonsPowerConsumption.push({
              l,
              v: Math.floor(limits.powerConsumption[i])
            });
          });
        }
        this.lastSeasonsSnowProductionVolume = lastSeasonsSnowProductionVolume;
        this.lastSeasonsWaterConsumption = lastSeasonsWaterConsumption;
        this.lastSeasonsAirConsumption = lastSeasonsAirConsumption;
        this.lastSeasonsPowerConsumption = lastSeasonsPowerConsumption;

        this.workingAreaEnabled = !!device.workingAreaRef;
        this.workingArea = device.workingAreaRef;
        this.workingAreas = device.resortAreaRef.getWorkingAreas();

      } else {
        this.close();
      }
    });
  }

}
