<ss-compass [shadowDirection]="( snowCannon.conenctionPointRef ? snowCannon.conenctionPointRef.baseOrientationOffsetFromNorth : 0 )">
    <div
        [ngStyle]="{ 'transform': 'rotate(' + ( snowCannon.conenctionPointRef ? snowCannon.conenctionPointRef.baseOrientationOffsetFromNorth : 0 ) + 'deg)' }"
        [ngClass]="{ 'in-progress': !snowCannon.canChangePositionOrOscillation }"
        class="snow-cannon-position-indicator">
        <div
            [ngStyle]="{ 'transform': 'rotate(' + snowCannon.tubeHorizontalSetAngle + 'deg)' }"
            class="r">
            <div class="c">
                <div class="u i">
                    <div
                        [ngStyle]="{ 'transform': 'rotate(' + snowCannon.oscillationTargetAngle + 'deg)' }"
                        class="u k"></div>
                </div>
                <div class="u j">
                    <div
                        [ngStyle]="{ 'transform': 'rotate(-' + snowCannon.oscillationTargetAngle + 'deg)' }"
                        class="u l"></div>
                </div>
            </div>
            <div class="d">
                <div></div>
            </div>
        </div>
        <div
            [ngStyle]="{ 'transform': 'rotate(' + snowCannon.tubeHorizontalCurrentAngle + 'deg)' }"
            class="e">
            <div></div>
        </div>
        <div
            [ngStyle]="{ 'transform': 'rotate(' + snowCannon.tubeHorizontalStartAngle + 'deg)' }"
            class="f">
            <div></div>
        </div>
    </div>
</ss-compass>
