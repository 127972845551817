<ng-container *ngIf="data && data.area">
  <div class="navbar__group stretch">
    <div class="routes-dropdown" *ngIf="allowExpand; else onlyTitle">
      <button class="routes-dropdown__btn" (click)="toggleRecentRoutes();">
        <span class="mt-1">{{ data.area.symbol }}</span>
        <svg class="routes-dropdown__angle"
          [ngClass]="{'routes-dropdown__angle--rotated': isRecentRoutesExpanded }">
          <use xlink:href="#icon-chevron-down"/>
        </svg>
      </button>
    </div>
    <ng-template #onlyTitle>
      <h2 class="navbar__subheader">
        <a routerLink="/application/resort">{{ data.area.symbol }}</a>
      </h2>
    </ng-template>
  </div>
  <div class="navbar__group pl-4">
    <button class="navbar__btn navbar__btn--switchable"
      [ngClass]="{ 'is-active': data.isMap }" [routerLink]="['/application/resort/area', data.area.id, 'map']">
      <svg>
        <use xlink:href="#icon-map" />
      </svg>
      <span i18n="Mapa|Etykieta przycisku przełączającego na widok mapy@@areaHeaderExtMapLabel">Mapa</span>
    </button>
    <button class="navbar__btn navbar__btn--switchable"
      [ngClass]="{ 'is-active': !data.isMap }" [routerLink]="['/application/resort/area', data.area.id, 'list']">
      <svg>
        <use xlink:href="#icon-list"/>
      </svg>
      <span i18n="Lista|Etykieta przycisku przełączającego na widok listy@@areaHeaderExtListLabel">Lista</span>
    </button>
  </div>
</ng-container>