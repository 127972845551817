<div *ngIf="size==='large'; else smallScale" class="row">
  <div class="col-4">
    <ng-container>
        <div class="d-flex align-items-center">
          <p class="text-uppercase color-light-grey fs-12" i18n="Jakość śniegu|Skala jakości śniegu - etykieta@@snowQualityLabel">Jakość śniegu</p>
          <ss-popover class="ml-2" i18n="Opis|Skala jakości śniegu - opis ustawień@@snowQualitySettingsDescription">Auto zmiana jakości, pozwala na wybranie zakresu na skali jakości śniegu. System będzie dbał, aby śnieżenie odbywało się z najlepszą z możliwych jakości śniegu, przy dostępnych warunkach atmosferycznych.</ss-popover>
        </div>
        <ng-select class="custom-select custom-select--simple fs-14 mt-1"
          [(ngModel)]="snowQualityType"
          [clearable]="false"
          [searchable]="false"
          [disabled]="isDisabled || disableSnowQualitySelection"
          (change)="setSnowQualityType($event)">
          <ng-option [value]="0" i18n="Stała jakość śniegu|Ustawienia jakości śniegu@@snowQualitySettingsConstant">Stała jakość śniegu</ng-option>
          <ng-option [value]="1" i18n="Najlepsza jakość śniegu|Ustawienia jakości śniegu@@snowQualitySettingsTheBest">Najlepsza jakość</ng-option>
          <ng-option [value]="2" i18n="Największa objętość|Ustawienia jakości śniegu@@snowQualitySettingsTheLargest">Największa objętość</ng-option>
        </ng-select>
    </ng-container>
  </div>
  <div class="col-8">
    <div class="snow-quality snow-quality--l">
      <svg *ngIf="showIcons" class="snow-quality__icon">
        <use xlink:href='#icon-snow'/>
      </svg>

      <div *ngIf="!isDisabled" class="snow-quality__scale">
        <input type="checkbox"
          class="snow-quality__input"
          (change)="changeSnowQualitySelection(1)"
          [value]="1"
          name="{{uniqElementId}}"
          id="{{uniqElementId}}-1"
        />
        <label for="{{uniqElementId}}-1"
          class="snow-quality__box"
          [ngClass]="{
            'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 1 : snowQualityMin <= 1 && snowQualityMax >= 1,
            'is-checked': currentSnowQualityToShow === 1,
            'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 1 : snowQualityMin === 1,
            'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 1 : snowQualityMax === 1
          }"
        >
          <span>1</span>
          <span *ngIf="noOfDevices1>0" class="snow-quality__num-of-devices">({{ noOfDevices1 }})</span>
        </label>
        <input type="checkbox"
          class="snow-quality__input"
          (change)="changeSnowQualitySelection(2)"
          [value]="2"
          name="{{uniqElementId}}"
          id="{{uniqElementId}}-2"
        />
        <label for="{{uniqElementId}}-2"
          class="snow-quality__box"
          [ngClass]="{
            'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 2 : snowQualityMin <= 2 && snowQualityMax >= 2,
            'is-checked': currentSnowQualityToShow === 2,
            'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 2 : snowQualityMin === 2,
            'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 2 : snowQualityMax === 2
          }"
        >
          <span>2</span>
          <span *ngIf="noOfDevices2>0" class="snow-quality__num-of-devices">({{ noOfDevices2 }})</span>
        </label>
        <input type="checkbox"
          class="snow-quality__input"
          (change)="changeSnowQualitySelection(3)"
          [value]="3"
          name="{{uniqElementId}}"
          id="{{uniqElementId}}-3"
        />
        <label for="{{uniqElementId}}-3"
          class="snow-quality__box"
          [ngClass]="{
            'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 3 : snowQualityMin <= 3 && snowQualityMax >= 3,
            'is-checked': currentSnowQualityToShow === 3,
            'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 3 : snowQualityMin === 3,
            'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 3 : snowQualityMax === 3
          }"
        >
          <span>3</span>
          <span *ngIf="noOfDevices3>0" class="snow-quality__num-of-devices">({{ noOfDevices3 }})</span>
        </label>
        <input type="checkbox"
          class="snow-quality__input"
          (change)="changeSnowQualitySelection(4)"
          [value]="4"
          name="{{uniqElementId}}"
          id="{{uniqElementId}}-4"
        />
        <label for="{{uniqElementId}}-4"
          class="snow-quality__box"
          [ngClass]="{
            'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 4 : snowQualityMin <= 4 && snowQualityMax >= 4,
            'is-checked': currentSnowQualityToShow === 4,
            'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 4 : snowQualityMin === 4,
            'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 4 : snowQualityMax === 4
          }"
        >
          <span>4</span>
          <span *ngIf="noOfDevices4>0" class="snow-quality__num-of-devices">({{ noOfDevices4 }})</span>
        </label>
        <input type="checkbox"
          class="snow-quality__input"
          (change)="changeSnowQualitySelection(5)"
          [value]="5"
          name="{{uniqElementId}}"
          id="{{uniqElementId}}-5"
        />
        <label for="{{uniqElementId}}-5"
          class="snow-quality__box"
          [ngClass]="{
            'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 5 : snowQualityMin <= 5 && snowQualityMax >= 5,
            'is-checked': currentSnowQualityToShow === 5,
            'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 5 : snowQualityMin === 5,
            'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 5 : snowQualityMax === 5
          }"
        >
          <span>5</span>
          <span *ngIf="noOfDevices5>0" class="snow-quality__num-of-devices">({{ noOfDevices5 }})</span>
        </label>
        <input type="checkbox"
          class="snow-quality__input"
          (change)="changeSnowQualitySelection(6)"
          [value]="6"
          name="{{uniqElementId}}"
          id="{{uniqElementId}}-6"
        />
        <label for="{{uniqElementId}}-6"
          class="snow-quality__box"
          [ngClass]="{
            'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 6 : snowQualityMin <= 6 && snowQualityMax >= 6,
            'is-checked': currentSnowQualityToShow === 6,
            'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 6 : snowQualityMin === 6,
            'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 6 : snowQualityMax === 6
          }"
        >
          <span>6</span>
          <span *ngIf="noOfDevices6>0" class="snow-quality__num-of-devices">({{ noOfDevices6 }})</span>
        </label>
        <input type="checkbox"
          class="snow-quality__input"
          (change)="changeSnowQualitySelection(7)"
          [value]="7"
          name="{{uniqElementId}}"
          id="{{uniqElementId}}-7"
        />
        <label for="{{uniqElementId}}-7"
          class="snow-quality__box"
          [ngClass]="{
            'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 7 : snowQualityMin <= 7 && snowQualityMax >= 7,
            'is-checked': currentSnowQualityToShow === 7,
            'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 7 : snowQualityMin === 7,
            'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 7 : snowQualityMax === 7
          }"
        >
          <span>7</span>
          <span *ngIf="noOfDevices7>0" class="snow-quality__num-of-devices">({{ noOfDevices7 }})</span>
        </label>
      </div>

      <div *ngIf="isDisabled" class="snow-quality__scale snow-quality__scale--non-editable">
        <div class="snow-quality__box" [ngClass]="{
          'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 1 : snowQualityMin <= 1 && snowQualityMax >= 1,
          'is-checked': currentSnowQualityToShow === 1,
          'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 1 : snowQualityMin === 1,
          'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 1 : snowQualityMax === 1
        }">
          <span>1</span>
          <span *ngIf="noOfDevices1 > 0" class="snow-quality__num-of-devices">({{ noOfDevices1 }})</span>
        </div>
        <div class="snow-quality__box" [ngClass]="{
          'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 2 : snowQualityMin <= 2 && snowQualityMax >= 2,
          'is-checked': currentSnowQualityToShow === 2,
          'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 2 : snowQualityMin === 2,
          'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 2 : snowQualityMax === 2
        }">
          <span>2</span>
          <span *ngIf="noOfDevices2 > 0" class="snow-quality__num-of-devices">({{ noOfDevices2 }})</span>
        </div>
        <div class="snow-quality__box" [ngClass]="{
          'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 3 : snowQualityMin <= 3 && snowQualityMax >= 3,
          'is-checked': currentSnowQualityToShow === 3,
          'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 3 : snowQualityMin === 3,
          'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 3 : snowQualityMax === 3
        }">
          <span>3</span>
          <span *ngIf="noOfDevices3 > 0" class="snow-quality__num-of-devices">({{ noOfDevices3 }})</span>
        </div>
        <div class="snow-quality__box" [ngClass]="{
          'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 4 : snowQualityMin <= 4 && snowQualityMax >= 4,
          'is-checked': currentSnowQualityToShow === 4,
          'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 4 : snowQualityMin === 4,
          'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 4 : snowQualityMax === 4
        }">
          <span>4</span>
          <span *ngIf="noOfDevices4 > 0" class="snow-quality__num-of-devices">({{ noOfDevices4 }})</span>
        </div>
        <div class="snow-quality__box" [ngClass]="{
          'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 5 : snowQualityMin <= 5 && snowQualityMax >= 5,
          'is-checked': currentSnowQualityToShow === 5,
          'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 5 : snowQualityMin === 5,
          'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 5 : snowQualityMax === 5
        }">
          <span>5</span>
          <span *ngIf="noOfDevices5 > 0" class="snow-quality__num-of-devices">({{ noOfDevices5 }})</span>
        </div>
        <div class="snow-quality__box" [ngClass]="{
          'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 6 : snowQualityMin <= 6 && snowQualityMax >= 6,
          'is-checked': currentSnowQualityToShow === 6,
          'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 6 : snowQualityMin === 6,
          'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 6 : snowQualityMax === 6
        }">
          <span>6</span>
          <span *ngIf="noOfDevices6 > 0" class="snow-quality__num-of-devices">({{ noOfDevices6 }})</span>
        </div>
        <div class="snow-quality__box" [ngClass]="{
          'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 7 : snowQualityMin <= 7 && snowQualityMax >= 7,
          'is-checked': currentSnowQualityToShow === 7,
          'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 7 : snowQualityMin === 7,
          'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 7 : snowQualityMax === 7
        }">
          <span>7</span>
          <span *ngIf="noOfDevices7 > 0" class="snow-quality__num-of-devices">({{ noOfDevices7 }})</span>
        </div>
      </div>

      <svg *ngIf="showIcons" class="snow-quality__icon">
        <use xlink:href='#icon-water'/>
      </svg>
    </div>
  </div>
</div>
<ng-template #smallScale>
  <div class="d-flex justify-content-end">
    <div class="snow-quality"
      [ngClass]="{
        'snow-quality--xs': size === 'extra-small',
        'snow-quality--s': size === 'small'
      }">
      <svg *ngIf="showIcons" class="snow-quality__icon">
        <use xlink:href='#icon-snow'/>
      </svg>
      <div *ngIf="!isDisabled" class="snow-quality__scale">
        <input type="checkbox"
          class="snow-quality__input"
          (change)="changeSnowQualitySelection(1)"
          [value]="1"
          name="{{uniqElementId}}"
          id="{{uniqElementId}}-1"
        />
        <label for="{{uniqElementId}}-1"
          class="snow-quality__box"
          [ngClass]="{
            'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 1 : snowQualityMin <= 1 && snowQualityMax >= 1,
            'is-checked': currentSnowQualityToShow === 1,
            'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 1 : snowQualityMin === 1,
            'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 1 : snowQualityMax === 1
          }"
        >
          <span>1</span>
          <span *ngIf="noOfDevices1>0" class="snow-quality__num-of-devices">({{ noOfDevices1 }})</span>
        </label>
        <input type="checkbox"
          class="snow-quality__input"
          (change)="changeSnowQualitySelection(2)"
          [value]="2"
          name="{{uniqElementId}}"
          id="{{uniqElementId}}-2"
        />
        <label for="{{uniqElementId}}-2"
          class="snow-quality__box"
          [ngClass]="{
            'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 2 : snowQualityMin <= 2 && snowQualityMax >= 2,
            'is-checked': currentSnowQualityToShow === 2,
            'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 2 : snowQualityMin === 2,
            'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 2 : snowQualityMax === 2
          }"
        >
          <span>2</span>
          <span *ngIf="noOfDevices2>0" class="snow-quality__num-of-devices">({{ noOfDevices2 }})</span>
        </label>
        <input type="checkbox"
          class="snow-quality__input"
          (change)="changeSnowQualitySelection(3)"
          [value]="3"
          name="{{uniqElementId}}"
          id="{{uniqElementId}}-3"
        />
        <label for="{{uniqElementId}}-3"
          class="snow-quality__box"
          [ngClass]="{
            'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 3 : snowQualityMin <= 3 && snowQualityMax >= 3,
            'is-checked': currentSnowQualityToShow === 3,
            'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 3 : snowQualityMin === 3,
            'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 3 : snowQualityMax === 3
          }"
        >
          <span>3</span>
          <span *ngIf="noOfDevices3>0" class="snow-quality__num-of-devices">({{ noOfDevices3 }})</span>
        </label>
        <input type="checkbox"
          class="snow-quality__input"
          (change)="changeSnowQualitySelection(4)"
          [value]="4"
          name="{{uniqElementId}}"
          id="{{uniqElementId}}-4"
        />
        <label for="{{uniqElementId}}-4"
          class="snow-quality__box"
          [ngClass]="{
            'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 4 : snowQualityMin <= 4 && snowQualityMax >= 4,
            'is-checked': currentSnowQualityToShow === 4,
            'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 4 : snowQualityMin === 4,
            'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 4 : snowQualityMax === 4
          }"
        >
          <span>4</span>
          <span *ngIf="noOfDevices4>0" class="snow-quality__num-of-devices">({{ noOfDevices4 }})</span>
        </label>
        <input type="checkbox"
          class="snow-quality__input"
          (change)="changeSnowQualitySelection(5)"
          [value]="5"
          name="{{uniqElementId}}"
          id="{{uniqElementId}}-5"
        />
        <label for="{{uniqElementId}}-5"
          class="snow-quality__box"
          [ngClass]="{
            'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 5 : snowQualityMin <= 5 && snowQualityMax >= 5,
            'is-checked': currentSnowQualityToShow === 5,
            'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 5 : snowQualityMin === 5,
            'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 5 : snowQualityMax === 5
          }"
        >
          <span>5</span>
          <span *ngIf="noOfDevices5>0" class="snow-quality__num-of-devices">({{ noOfDevices5 }})</span>
        </label>
        <input type="checkbox"
          class="snow-quality__input"
          (change)="changeSnowQualitySelection(6)"
          [value]="6"
          name="{{uniqElementId}}"
          id="{{uniqElementId}}-6"
        />
        <label for="{{uniqElementId}}-6"
          class="snow-quality__box"
          [ngClass]="{
            'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 6 : snowQualityMin <= 6 && snowQualityMax >= 6,
            'is-checked': currentSnowQualityToShow === 6,
            'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 6 : snowQualityMin === 6,
            'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 6 : snowQualityMax === 6
          }"
        >
          <span>6</span>
          <span *ngIf="noOfDevices6>0" class="snow-quality__num-of-devices">({{ noOfDevices6 }})</span>
        </label>
        <input type="checkbox"
          class="snow-quality__input"
          (change)="changeSnowQualitySelection(7)"
          [value]="7"
          name="{{uniqElementId}}"
          id="{{uniqElementId}}-7"
        />
        <label for="{{uniqElementId}}-7"
          class="snow-quality__box"
          [ngClass]="{
            'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 7 : snowQualityMin <= 7 && snowQualityMax >= 7,
            'is-checked': currentSnowQualityToShow === 7,
            'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 7 : snowQualityMin === 7,
            'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 7 : snowQualityMax === 7
          }"
        >
          <span>7</span>
          <span *ngIf="noOfDevices7>0" class="snow-quality__num-of-devices">({{ noOfDevices7 }})</span>
        </label>
      </div>

      <div *ngIf="isDisabled" class="snow-quality__scale snow-quality__scale--non-editable">
        <div class="snow-quality__box" [ngClass]="{
          'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 1 : snowQualityMin <= 1 && snowQualityMax >= 1,
          'is-checked': currentSnowQualityToShow === 1,
          'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 1 : snowQualityMin === 1,
          'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 1 : snowQualityMax === 1
        }">
          <span>1</span>
          <span *ngIf="noOfDevices1 > 0" class="snow-quality__num-of-devices">({{ noOfDevices1 }})</span>
        </div>
        <div class="snow-quality__box" [ngClass]="{
          'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 2 : snowQualityMin <= 2 && snowQualityMax >= 2,
          'is-checked': currentSnowQualityToShow === 2,
          'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 2 : snowQualityMin === 2,
          'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 2 : snowQualityMax === 2
        }">
          <span>2</span>
          <span *ngIf="noOfDevices2 > 0" class="snow-quality__num-of-devices">({{ noOfDevices2 }})</span>
        </div>
        <div class="snow-quality__box" [ngClass]="{
          'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 3 : snowQualityMin <= 3 && snowQualityMax >= 3,
          'is-checked': currentSnowQualityToShow === 3,
          'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 3 : snowQualityMin === 3,
          'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 3 : snowQualityMax === 3
        }">
          <span>3</span>
          <span *ngIf="noOfDevices3 > 0" class="snow-quality__num-of-devices">({{ noOfDevices3 }})</span>
        </div>
        <div class="snow-quality__box" [ngClass]="{
          'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 4 : snowQualityMin <= 4 && snowQualityMax >= 4,
          'is-checked': currentSnowQualityToShow === 4,
          'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 4 : snowQualityMin === 4,
          'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 4 : snowQualityMax === 4
        }">
          <span>4</span>
          <span *ngIf="noOfDevices4 > 0" class="snow-quality__num-of-devices">({{ noOfDevices4 }})</span>
        </div>
        <div class="snow-quality__box" [ngClass]="{
          'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 5 : snowQualityMin <= 5 && snowQualityMax >= 5,
          'is-checked': currentSnowQualityToShow === 5,
          'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 5 : snowQualityMin === 5,
          'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 5 : snowQualityMax === 5
        }">
          <span>5</span>
          <span *ngIf="noOfDevices5 > 0" class="snow-quality__num-of-devices">({{ noOfDevices5 }})</span>
        </div>
        <div class="snow-quality__box" [ngClass]="{
          'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 6 : snowQualityMin <= 6 && snowQualityMax >= 6,
          'is-checked': currentSnowQualityToShow === 6,
          'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 6 : snowQualityMin === 6,
          'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 6 : snowQualityMax === 6
        }">
          <span>6</span>
          <span *ngIf="noOfDevices6 > 0" class="snow-quality__num-of-devices">({{ noOfDevices6 }})</span>
        </div>
        <div class="snow-quality__box" [ngClass]="{
          'is-enabled': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 7 : snowQualityMin <= 7 && snowQualityMax >= 7,
          'is-checked': currentSnowQualityToShow === 7,
          'is-enabled--first': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 7 : snowQualityMin === 7,
          'is-enabled--last': (disableSnowQualitySelection || snowQualityType === 0) ? currentSnowQualityToShow === 7 : snowQualityMax === 7
        }">
          <span>7</span>
          <span *ngIf="noOfDevices7 > 0" class="snow-quality__num-of-devices">({{ noOfDevices7 }})</span>
        </div>
      </div>

      <svg *ngIf="showIcons" class="snow-quality__icon">
        <use xlink:href='#icon-water'/>
      </svg>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <ng-select class="custom-select custom-select--simple fs-14"
      [(ngModel)]="snowQualityType"
      [clearable]="false"
      [searchable]="false"
      [disabled]="isDisabled || disableSnowQualitySelection"
      (change)="setSnowQualityType($event)">
      <ng-option [value]="0" i18n="Stała jakość śniegu|Ustawienia jakości śniegu@@snowQualitySettingsConstant">Stała jakość śniegu</ng-option>
      <ng-option [value]="1" i18n="Najlepsza jakość śniegu|Ustawienia jakości śniegu@@snowQualitySettingsTheBest">Najlepsza jakość</ng-option>
      <ng-option [value]="2" i18n="Największa objętość|Ustawienia jakości śniegu@@snowQualitySettingsTheLargest">Największa objętość</ng-option>
    </ng-select>
  </div>
</ng-template>
