import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ResortStatisticsService } from 'src/app/resort-statistics.service';
import { SettingsService } from 'src/app/settings.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isNullOrUndefined, TimedChartConfig, TimedChartConfigSeries, TimedChartData } from '../../../../../../../common';


@Component({
  selector: 'ss-d3-snow-production-and-twb-chart',
  templateUrl: './snow-production-and-twb-chart.component.html',
  styleUrls: ['./snow-production-and-twb-chart.component.sass']
})
export class SnowProductionAndTwbChartComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public device: { id: string };
  @Input()
  public suplementaryDevice: { id: string };
  @Output()
  public durationChanged: EventEmitter<number> = new EventEmitter();

  public data: TimedChartData;
  public config: TimedChartConfig;
  public duration = 12;

  private statsUpdatedSubscribtion: Subscription;
  private dataChange: EventEmitter<any> = new EventEmitter();

  public hasTemp = false;

  public constructor(
    private settings: SettingsService,
    private resortStatisticsService: ResortStatisticsService
  ) {
    this.duration = this.settings.get('defaultChartPeriod');

    this.dataChange.pipe(debounceTime(10)).subscribe(() => {
      this.data = this.fetchChartDataById();
    });
  }

  public setDuration(val: number) {
    this.duration = val;
    this.durationChanged.emit(val);
    this.updateData();
  }

  protected fetchChartDataById(): TimedChartData {
    const duration = this.duration || this.settings.get('defaultChartPeriod');

    const rawData = this.resortStatisticsService.getStats(this.device.id, duration);
    let suplData;
    if (this.suplementaryDevice) {
      suplData = this.resortStatisticsService.getStats(this.suplementaryDevice.id, duration);
    }

    const preparedData = {
      labels: rawData.aggregatedLabels,
      spv: rawData.snowProductionVolume,
      twb: rawData.wetBulbTemperature
    };

    const series: TimedChartConfigSeries[] = [
      {
        ... new TimedChartConfigSeries(),
        seriesType: 'bar-w-rounded-top',
        seriesName: 'snow production', // no need to translate - not shown anywhere
        valueFieldName: 'spv',
        primaryColor: '#1BD374',
        secondaryColor: '#FD994C',
        fixedMin: 0,
        grid: true,
        unit: 'production'
      }
    ];

    this.hasTemp = (rawData.wetBulbTemperature || []).reduce((p, c) => p || !isNullOrUndefined(c), false);
    if (suplData && suplData.wetBulbTemperature) {
      this.hasTemp = (suplData.wetBulbTemperature || []).reduce((p, c) => p || !isNullOrUndefined(c), false);
      if (this.hasTemp) {
        preparedData.twb = suplData.wetBulbTemperature;
      }
    }
    if (this.hasTemp) {
      series.push({
        ... new TimedChartConfigSeries(),
        seriesType: 'line-w-rounded-end',
        seriesName: 'TWB', // no need to translate - not shown anywhere
        valueFieldName: 'twb',
        primaryColor: '#00A1E4',
        unit: 'temperature'
      });
    }

    this.config = {
      ...new TimedChartConfig(),
      xAxisType: 'bottom',
      yAxisType: series.length > 1 ? 'double' : 'single',
      series
    };

    return new TimedChartData(preparedData);
  }

  private updateData() {
    this.dataChange.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.device
      || changes.suplementaryDevice
      || changes.duration) {
      this.updateData();
    }
  }

  ngOnInit() {
    this.statsUpdatedSubscribtion = this.resortStatisticsService.statsUpdated.subscribe(() => {
      this.updateData();
    });

    this.updateData();
  }

  ngOnDestroy() {
    if (this.statsUpdatedSubscribtion) {
      this.statsUpdatedSubscribtion.unsubscribe();
    }
  }


}
