import { AbstractDevice } from './abstract-device.model';
import { DeviceSystemType } from '../../../../../common';

export class AirPipeline extends AbstractDevice {
    systemType: DeviceSystemType = 'AirPipeline';

    points: any;
    symbol: string;
    airSource: string;

    currentAirFlow: number;
    limitAirFlow: number;
    maximumAirFlow: number;
    currentAirPressure: number;
    targetAirPressure: number;
    maximumAirPressure: number;

    isLocked = false;
}
