import { Component, Input, OnInit } from '@angular/core';
import { ReportPartType, translateReportPartType } from '../../../../../../common';

@Component({
  selector: 'ss-translated-report-part-type',
  template: '{{ type }}',
  styleUrls: []
})
export class TranslatedReportPartTypeComponent {

  public type: string;

  private _partType: ReportPartType;
  @Input()
  public get partType(): ReportPartType {
    return this._partType;
  }
  public set partType(v: ReportPartType) {
    this._partType = v;
    this.type = translateReportPartType(v);
  }
}
