import { TimedChartConfigSeries, TimedChartConfigXAxisType, TimedChartConfigYAxisType } from '.';
import { DAYJS, UnitSet } from '../..';

export class TimedChartConfig {
  animationTime: number;
  unitSet: UnitSet;
  locale: string;
  xAxisType: TimedChartConfigXAxisType;
  yAxisType: TimedChartConfigYAxisType = 'double';
  grid = false;
  legend = false;
  labelFieldName = 'labels';
  selectedDate: DAYJS;
  series: TimedChartConfigSeries[] = [];
}
