import { isArray, isNullOrUndefined } from '../..';
import { D3DrawerData } from './d3-drawer-data.model';

export class TimedChartData {
  public labels: any[];

  public constructor(params: {labels: any[]}) {
    for (const k of Object.keys(params)) {
      this[k] = params[k];
    }
  }

  public getD3DrawabeSeries(labelsFieldName: string = 'labels'): D3DrawerData {
    const ret: D3DrawerData = [];

    if (isArray(this[labelsFieldName])) {
      const l = this[labelsFieldName].length;

      ret.push(...this[labelsFieldName].map(v => {
        const r = {};
        r[labelsFieldName] = parseInt(`${v}`, 10) * 1000;
        return r;
      }));

      for (const k of Object.keys(this)) {
        if (k !== labelsFieldName && isArray(this[k]) && this[k].length === l) {
          const kIsAllNumbers = [...this[k]].reduce((p, c) => p && (
              isNullOrUndefined(c)
              || !isNaN(parseFloat(c))
            ), true);

          for (let i = 0; i < l; i++) {
            const v = this[k][i];
            if (!isNullOrUndefined(v)) {
              ret[i][k] = kIsAllNumbers ? parseFloat(v) : `${v}`;
            } else {
              ret[i][k] = undefined;
            }
          }
        }
      }
    }

    return ret;
  }
}
