import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { NEVER, Observable, of } from 'rxjs';
import { ReportingService } from '../reporting.service';
import { Injectable } from '@angular/core';
import { catchError, switchMap } from 'rxjs/operators';
import { ReportDefinition } from '../../../../../../common';

@Injectable({
  providedIn: 'root'
})
export class DefinitionResolverService  implements Resolve<ReportDefinition> {

  constructor(
    private router: Router,
    private rds: ReportingService
  ) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): ReportDefinition
    | Observable<ReportDefinition>
    | Observable<never> {
    return this.rds.getDefinition(route.paramMap.get('reportId')).pipe(
      catchError(e => of(null)),
      switchMap(v => {
        if(!v) {
          this.router.navigate(['/', 'application', 'reporting', 'list']);
          return NEVER;
        }
        return of(v);
      })
    );
  }
}
