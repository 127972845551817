import { Component, OnInit, Input } from '@angular/core';
import { SnowCannon } from 'src/app/shared/models';

@Component({
  selector: 'ss-snow-cannon-position-indicator',
  templateUrl: './snow-cannon-position-indicator.component.html',
  styleUrls: ['./snow-cannon-position-indicator.component.sass']
})
export class SnowCannonPositionIndicatorComponent implements OnInit {


  @Input()
  public snowCannon: SnowCannon;

  constructor() { }

  ngOnInit() {
  }

}
