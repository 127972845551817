<ss-page>
  <ss-page-header>
    <ss-page-header-title i18n="Tytuł Wyeksportowane raporty|@@reportingExportedReportsListTitle">Wyeksportowane raporty</ss-page-header-title>
  </ss-page-header>
  <ss-page-panel>
    <div class="mx--4 my-3">
    <table class="table">
      <thead>
        <tr class="table__row">
          <th i18n="Nazwa kolumny w tabeli Nazwa|@@reportingExportedReportsTableHeaderName">Nazwa</th>
          <th i18n="Nazwa kolumny w tabeli Od|@@reportingExportedReportsTableHeaderFrom">Od</th>
          <th i18n="Nazwa kolumny w tabeli Do|@@reportingExportedReportsTableHeaderTo">Do</th>
          <th i18n="Nazwa kolumny w tabeli Utworzono|@@reportingExportedReportsTableHeaderCreated">Utworzono</th>
          <th i18n="Nazwa kolumny w tabeli Ukończono|@@reportingExportedReportsTableHeaderCompleted">Ukończono</th>
          <th i18n="Nazwa kolumny w tabeli Format|@@reportingExportedReportsTableHeaderFormat">Format</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let e of exports ; trackBy: ident" class="table__row">
          <td>{{ e.reportName }}</td>
          <td>{{ e.from.format('YYYY-MM-DD') }}</td>
          <td>{{ e.to.format('YYYY-MM-DD') }}</td>
          <td>{{ e.created.format('YYYY-MM-DD HH:mm:ss') }}</td>
          <td *ngIf="!e.done">{{ e.progress }}%</td>
          <td *ngIf="!!e.done">{{ e.done.format('YYYY-MM-DD HH:mm:ss') }}</td>
          <td>{{ e.format }}</td>
          <td *ngIf="!e.done" i18n="Info o postępie|@@reportingExportedReportsTableProgressInfo">trwa generowanie pliku</td>
          <td *ngIf="!!e.done">
            <button [disabled]="isDownlaodInProgress" (click)="download(e)" class="btn btn-primary" i18n="Przycisk pobierz|@@reportingExportedReportsTableDownload">Pobierz plik</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  </ss-page-panel>
</ss-page>