<ss-page-footer>
  <ss-page-button-group *ngIf="isEditMode">
    <button (click)="removeDefinition()"
      *ngIf="result && result.isCustom"
      [disabled]="isLoading"
      class="btn btn-danger"
      i18n="Usuń szablon|Przycisk na podglądzie raportu Usuń szablon@@report-detail-remove-template">Usuń szablon</button>
  </ss-page-button-group>

  <div class="flex-grow-1"></div>

  <ss-page-button-group>
    <ng-container *ngIf="result">
      <button (click)="saveDefinition(true)"
        *ngIf="isEditMode"
        [disabled]="isLoading"
        class="btn btn-primary"
        i18n="Zapisz jako nowy szablon|Przycisk na podglądzie raportu Zapisz jako nowy szablon@@report-detail-btn-save-definition-as-copy">Zapisz jako nowy szablon</button>
      <button (click)="saveDefinition()"
        *ngIf="isEditMode && result.isCustom"
        [disabled]="isLoading"
        class="btn btn-primary"
        i18n="Zapisz szablon|Przycisk na podglądzie raportu Zapisz szablon@@report-detail-btn-save-definition">Zapisz szablon</button>
      <button (click)="stopEditMode()"
        *ngIf="isEditMode"
        [disabled]="isLoading"
        class="btn btn-dark"
        i18n="Podgląd|Przycisk na podglądzie raportu Podgląd@@report-detail-btn-preview">Podgląd</button>
      <button (click)="startEditMode()"
        *ngIf="!isEditMode"
        [disabled]="isLoading"
        class="btn btn-primary"
        i18n="Edytuj szablon|Przycisk na podglądzie raportu Edytuj szablon@@report-detail-btn-edit-definition">Edytuj szablon</button>
    </ng-container>
    <a [routerLink]="['/', 'application', 'reporting']"
      *ngIf="!isEditMode"
      class="btn btn-default"
      i18n="Powrót do listy|Przycisk na podglądzie raportu Powrót do listy@@report-detail-btn-back">Powrót do listy</a>
  </ss-page-button-group>
</ss-page-footer>
