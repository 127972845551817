<div class="water-pipeline" [ngClass]="{'water-pipeline--expanded': areasListExpanded}">
    <div class="mode">
      <button
        [disabled]="waterPipeline.isLocked"
        *ngIf="waterPipeline.operationMode" (click)="operationModeDropdownExpanded = !operationModeDropdownExpanded"
        class="mode__button color-white px-3 py-2 d-flex align-items-center justify-content-between">
        <ng-container i18n="Rurociągiem steruje: Snowmatic|Rurociągiem steruje: Snowmatic@@pumpRoomPipeLineOperationModeSnowmatic">Rurociągiem steruje: Snowmatic</ng-container>
        <svg class="water-pipeline__chevron"
          [ngClass]="{'water-pipeline__chevron--rotated': operationModeDropdownExpanded}">
          <use xlink:href='#icon-chevron-down' />
        </svg>
      </button>
      <button
        [disabled]="waterPipeline.isLocked"
        *ngIf="!waterPipeline.operationMode" (click)="operationModeDropdownExpanded = !operationModeDropdownExpanded;"
        class="mode__button mode__button--orange color-white px-3 py-2 d-flex align-items-center justify-content-between">
        <ng-container i18n="Rurociągiem steruje: Operator|Rurociągiem steruje: Operator@@pumpRoomPipeLineOperationModeOperator">Rurociągiem steruje: Operator</ng-container>
        <svg class="water-pipeline__chevron"
          [ngClass]="{'water-pipeline__chevron--rotated': operationModeDropdownExpanded}">
          <use xlink:href='#icon-chevron-down' />
        </svg>
      </button>
      <div class="mode__dropdown color-white d-flex align-items-center"
        [ngClass]="{'mode__dropdown--expanded': operationModeDropdownExpanded}">
        <button
          *ngIf="waterPipeline.operationMode"
          [disabled]="waterPipeline.isLocked"
          (click)="setManualMode()"
          class="mode__button mode__button--orange h-100 text-left px-3 py-2">
          <ng-container i18n="Rurociągiem steruje: Operator|Rurociągiem steruje: Operator@@pumpRoomPipeLineOperationModeOperator">Rurociągiem steruje: Operator</ng-container>
        </button>
        <button
          *ngIf="!waterPipeline.operationMode"
          [disabled]="waterPipeline.isLocked"
          (click)="setAutoMode()"
          class="mode__button h-100 text-left px-3 py-2">
          <ng-container i18n="Rurociągiem steruje: Snowmatic|Rurociągiem steruje: Snowmatic@@pumpRoomPipeLineOperationModeSnowmatic">Rurociągiem steruje: Snowmatic</ng-container>
        </button>
      </div>
    </div>
    <div class="px-2 py-4">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <svg class="water-pipeline__icon pr-1">
            <use xlink:href="#icon-pipeline" />
          </svg>
          <div>
            <p class="fs-20 color-white pb-1">{{ waterPipeline.symbol }}</p>
            <p class="fs-14 color-white" *ngIf="waterPipeline.targetWaterPressure > 0">
              <ng-container
                i18n="Ciśnienie zadane|Etykieta dla wartości zadanego ciśnienia@@pumpRoomPipeLinePressureSet">
                Ciśnienie zadane</ng-container>: {{ (waterPipeline.targetWaterPressure || 0)|number:'1.1-1' }} bar
            </p>
          </div>
        </div>
        <div *ngIf="waterPipeline.connectionStatus && waterPipeline.configuration && waterPipeline.configuration.canBeStarted">
            <ss-control-button
              [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
              [disabled]="waterPipeline.isLocked || !waterPipeline.infoRemoteModeEnabled"
              (click)="start()" *ngIf="!waterPipeline.operationMode && !waterPipeline.startCommand"
              size="medium" action="start" i18n="Start|Etykieta przycisku uruchamiającego@@controlButtonStart">Start</ss-control-button>
            <ss-control-button
              [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
              [disabled]="waterPipeline.isLocked"
              (click)="stop()" *ngIf="!waterPipeline.operationMode && waterPipeline.startCommand" size="medium"
              action="stop" i18n="Stop|Etykieta przycisku zatrzymującego@@controlButtonStop">Stop</ss-control-button>
        </div>
      </div>

      <div class="row pt-5">
        <div class="col">
          <ss-d3-pressure-and-flow-chart [device]="waterPipeline"></ss-d3-pressure-and-flow-chart>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center pt-2">
        <div class="col-4">
          <ss-pump-room-detail-status [status]="waterPipeline.computedStatus"></ss-pump-room-detail-status>
        </div>


          <div *ngIf="waterPipeline.configuration && waterPipeline.configuration.hasMeteredWaterConsumption">
            <p class="fs-14 color-mid-grey pb-2" i18n="Zużycie wody|Etykieta pola Zużycie wody@@pumpRoomPipeLineMeteredWaterConsumption">
              Zużycie wody</p>
            <p class="fs-14 color-white white-space-nowrap">{{ (waterPipeline.meteredWaterConsumption || 0)|number:'1.0-0' }} m<sup>3</sup></p>
          </div>
          <div *ngIf="waterPipeline.configuration && waterPipeline.configuration.hasMeteredWaterConsumption1h">
            <p class="fs-14 color-mid-grey pb-2" i18n="Zużycie wody 1h|Etykieta pola Zużycie wody w ostatniej godzinie@@pumpRoomPipeLineMeteredWaterConsumptionInLastHour">
              Zużycie wody 1h</p>
            <p class="fs-14 color-white white-space-nowrap">{{ (waterPipeline.meteredWaterConsumption - lastHourMeteredFlow || 0)|number:'1.0-0' }} m<sup>3</sup></p>
          </div>

        <div *ngIf="waterPipeline.configuration && waterPipeline.configuration.hasFlowMeter">
          <p class="fs-14 color-mid-grey pb-2" i18n="Przepływ|Etykieta pola przepływ@@pumpRoomPipeLineFlow">
            Przepływ</p>
          <p class="fs-14 color-white white-space-nowrap">{{ waterPipeline|convertedValue:'currentWaterFlow' }} {{ waterPipeline|convertedUnit:'currentWaterFlow' }}</p>
        </div>
        <div *ngIf="waterPipeline.configuration && waterPipeline.configuration.hasPressureMeter">
          <p class="fs-14 color-mid-grey pb-2"
            i18n="Ciśnienie|Etykieta pola ciśnienie@@pumpRoomPipeLinePressure">Ciśnienie</p>
          <p class="fs-14 color-white white-space-nowrap">{{ (waterPipeline.currentWaterPressure || 0)|number:'1.1-1' }} bar</p>
        </div>
        <div *ngIf="waterPipeline.configuration && waterPipeline.configuration.hasWaterThermometer">
          <p class="fs-14 color-mid-grey pb-2"
            i18n="Temperautra wody|Etykieta pola temperatura wody@@pumpRoomPipeLineWaterTemp">Temperautra wody</p>
          <p class="fs-14 color-white white-space-nowrap">{{ (waterPipeline.currentWaterTemperature || 0)|number:'1.1-1' }}&deg;C</p>
        </div>
      </div>
    </div>
    <div class="water-pipeline__footer d-flex">
      <div class="col-4">
        <button (click)="areasListExpanded = !areasListExpanded"
          class="w-100 py-2 px-4 color-white d-flex align-items-center justify-content-between">
          <ng-container i18n="Trasy|Nazwa rozwijanej listy z dostępnymi trasami@@pumpRoomPipeLineRoutes">Trasy
          </ng-container> ({{ waterPipeline.countResortAreas() }})
          <svg class="water-pipeline__chevron"
            [ngClass]="{'water-pipeline__chevron--rotated': areasListExpanded}">
            <use xlink:href='#icon-chevron-down' />
          </svg>
        </button>
      </div>
      <div class="col-4" *ngIf="waterPipeline.configuration && waterPipeline.configuration.canChangeTargetWatterPressure">
          <button class="w-100 py-2 px-4 d-flex align-items-center justify-content-start"
            [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
            [disabled]="!waterPipeline.connectionStatus"
            (click)="oppenConfigModal()">
            <svg class="button-icon pr-1">
                <use xlink:href="#icon-gear" />
            </svg>
            <ng-container i18n="Ustawienia|Ustawienia@@pumpRoomPipeLineSettings">Ustawienia</ng-container>
          </button>
      </div>
      <div class="col-4 p-2" [ngClass]="{'bg-blue': highlightComponents}">
        <ss-checkbox inputType="checkbox" [isChecked]="highlightComponents"
          (hasChanged)="highlightComponentsChange($event)">
          <span class="pl-2 fs-12"
            i18n="Pokaż komponenty|Etykieta przycisku przełączającego widoczność komponentów dla danego rurociągu@@pumpRoomPipeLineShowComponents">Pokaż
            komponenty</span>
        </ss-checkbox>
      </div>
    </div>
    <div class="footer-dropdown" [ngClass]="{'footer-dropdown--expanded': areasListExpanded}">
      <table class="footer-dropdown__table w-100">
        <tr class="footer-dropdown__row" *ngFor="let a of waterPipeline.getResortAreas()">
          <td class="footer-dropdown__cell w-100 py-2 pl-4 pr-2 color-white">
              {{ a.symbol }}
          </td>
          <td class="footer-dropdown__cell py-2 pr-2 text-right white-space-nowrap color-green">
            {{ a|convertedValue:'snowProductionVolume' }}
            {{ a|convertedUnit:'snowProductionVolume' }}
          </td>
          <td class="footer-dropdown__cell py-2">
            <div style="width: 200px">
              <ss-d3-linked-mini-bar-chart [viewBoxWidth]="200" tag="compare-areas" [device]="a"></ss-d3-linked-mini-bar-chart>
            </div>
          </td>
          <td class="footer-dropdown__cell text-right">
            <button [routerLink]="['/application/resort/area', a.id, 'list']">
              <svg class="footer-dropdown__icon">
                <use xlink:href='#icon-chevron-down' />
              </svg>
            </button>
          </td>
        </tr>
      </table>
    </div>
</div>
