import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EndpointMapService {

  authOauthSignIn: string = null;
  authSignIn: string = null;
  authPreLoginData: string = null;
  alerts: string = null;
  auth: string = null;
  devices: string = null;
  settings: string = null;
  schedules: string = null;
  configureMultipleDevices: string = null;
  configurePumpRoom: string = null;
  configureSnowCannon: string = null;
  configureResortArea: string = null;
  configureResort: string = null;
  configureElectricalLines: string = null;
  configurePowerSources: string = null;
  configureAirPipelines: string = null;
  configureConnectionPoint: string = null;
  configureWorkingArea: string = null;
  configureMeteoStation: string = null;
  startDevice: string = null;
  stopDevice: string = null;
  configureWaterPipeline: string = null;
  users: string = null;
  forecast: string = null;
  satelliteTiles: string = null;
  geoportalSatelliteTiles: string = null;
  hipsoTiles: string = null;
  tilesPreview: string = null;
  snowThickness: string = null;
  documentationFiles: string = null;
  userNotes: string = null;
  deviceStatistics: string = null;
  deviceSeasonLimits: string = null;
  snowCannonPositionHistory: string = null;
  authChangePassword: string = null;
  authEditProfile: string = null;
  authProfileAuditTrail: string = null;
  auditTrail: string = null;
  tilesProject: string = null;

  reports: string = null;
  reportDefinitions: string = null;

  reportingDefinitions: string = null;
  reportingDefinitionsReorder: string = null;
  reportingResults: string = null;
  reportingExports: string = null;
  reportingExportsDownload: string = null;
  reportingExportsLatest: string = null;

  constructor() {
    const h = environment.apiHost;

    this.authOauthSignIn = h + '/api/auth/sign-in/connect-azure';
    this.authPreLoginData = h + '/api/auth/sign-in/pre-login-data';
    this.authSignIn = h + '/api/auth/sign-in';
    this.auth = h + '/api/auth';
    this.devices = h + '/api/devices';
    this.alerts = h + '/api/alerts';
    this.settings = h + '/api/settings';
    this.schedules = h + '/api/schedules';

    this.startDevice = h + '/api/start';
    this.stopDevice = h + '/api/stop';

    this.configureResort = h + '/api/configure/resort';
    this.configureMultipleDevices = h + '/api/configure';
    this.configurePumpRoom = h + '/api/configure/pump-room';
    this.configureSnowCannon = h + '/api/configure/snow-cannon';
    this.configureResortArea = h + '/api/configure/resort-area';
    this.configureWaterPipeline = h + '/api/configure/water-pipeline';
    this.configureElectricalLines = h + '/api/configure/electrical-lines';
    this.configurePowerSources = h + '/api/configure/power-sources';
    this.configureAirPipelines = h + '/api/configure/air-pipelines';
    this.configureConnectionPoint = h + '/api/configure/connection-point';
    this.configureWorkingArea = h + '/api/configure/working-area';
    this.configureMeteoStation = h + '/api/configure/meteo-station';

    this.deviceStatistics = h + '/api/devices/statistics';
    this.deviceSeasonLimits = h + '/api/devices/season-limits';
    this.users = h + '/api/users';

    this.geoportalSatelliteTiles = h + '/api/tiles-geoportal';
    this.satelliteTiles = h + '/api/tiles-satellite/{x}/{y}/{z}';
    this.hipsoTiles = h + '/api/tiles-hipso/{x}/{y}/{z}';
    this.tilesPreview = h + '/api/tiles/preview';
    this.tilesProject = h + '/api/tiles/project';

    this.forecast = h + '/api/forecast';
    this.snowThickness = h + '/api/snow-thickness';
    this.snowCannonPositionHistory = h + '/api/snow-cannon-position-history';

    this.documentationFiles = h + '/api/documentation-files';
    this.userNotes = h + '/api/user-notes';

    this.authChangePassword = h + '/api/auth/change-password';
    this.authEditProfile = h + '/api/auth/edit-profile';
    this.authProfileAuditTrail = h + '/api/profile-audit-trail';

    this.auditTrail = h + '/api/audit-trail';

    this.reports = h + '/api/reports';
    this.reportDefinitions = h + '/api/report-definitions';

    this.reportingDefinitions = h + '/api/reporting/definitions';
    this.reportingDefinitionsReorder = h + '/api/reporting/definitions/reorder';
    this.reportingResults = h + '/api/reporting/results';
    this.reportingExports = h + '/api/reporting/exports';
    this.reportingExportsDownload = h + '/api/reporting/download-export';
    this.reportingExportsLatest = h + '/api/reporting/exports-latest';
  }
}
