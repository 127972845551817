import { Component, OnInit } from '@angular/core';
import { ModalRouteService } from 'src/app/application/modal-route.service';
import { ActivatedRoute } from '@angular/router';
import { UserNote } from 'src/app/shared/models';
import { UserNotesService } from '../user-notes.service';

@Component({
  selector: 'ss-user-notes-editor-modal',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.sass']
})
export class UserNotesEditorModalComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private uns: UserNotesService,
    private mrs: ModalRouteService
  ) { }

  public note: UserNote;
  public noteId: number;
  public lock = false;

  public noteContent = '';
  public noteIsPrivate = false;

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.noteId = parseInt(params.get('noteId'), 10) || 0;
      if (this.noteId) {
        this.note = this.uns.findById(this.noteId);
      } else {
        this.note = UserNote.create({ context: params.get('noteContext') });
      }

      if (!this.note) {
        this.close();
      }

      this.noteContent = this.note.content || '';
      this.noteIsPrivate = !!this.note.isPrivate;
    });
  }

  remove() {
    if (this.noteId) {
      this.lock = true;
      this.uns.remove(this.noteId).subscribe(() => {
        this.lock = false;
        this.close();
      });
    }
  }

  save() {
    this.lock = true;
    const nts = UserNote.create({ ... this.note });
    nts.content = this.noteContent;
    nts.isPrivate = this.noteIsPrivate;
    if (!this.noteId) {
      this.uns.create(nts).subscribe(() => {
        this.lock = false;
        this.close();
      });
    } else {
      this.uns.edit(this.noteId, nts).subscribe(() => {
        this.lock = false;
        this.close();
      });
    }
  }

  close() {
    this.mrs.closeModal();
  }
}
