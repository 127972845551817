import {
  Component,
  Optional,
  Inject,
  Input,
  ViewChild
} from '@angular/core';
import {
  NgModel,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NG_ASYNC_VALIDATORS,
} from '@angular/forms';
import { ElementBase } from '../element-base';

let identifier = 0;

@Component({
  selector: 'form-text',
  templateUrl: './form-text.component.html',
  styleUrls: [],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FormTextComponent,
    multi: true,
  }],
})
export class FormTextComponent extends ElementBase<string> {
  @Input() public label: string;
  @Input() public placeholder = '';
  @Input() public isExtraWide: boolean;
  @Input() public isAutoFocus: boolean;
  @Input() public isReadOnly: boolean;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  public type = 'text';
  public identifier = `form-text-${identifier++}-` + Math.random();

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }
}
