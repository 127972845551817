<div class="row hydrant-info align-items-center">
  <div class="col-auto handler hydrant-handle">
    <svg>
      <use xlink:href="#icon-move" />
    </svg>
  </div>
  <div class="col-auto symbol">
    {{ connectionPoint.symbol }}
  </div>
  <div class="col-auto">
    {{ connectionPoint.priority || 0 }}
  </div>
  <div class="col"></div>
  <div class="col-auto edits">
    <button class="btn btn-defaut" (click)="goToHydrantConfig(connectionPoint.id)">
      <svg>
        <use xlink:href="#icon-gear" />
      </svg>
    </button>
  </div>
</div>
