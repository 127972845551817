import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ComponentFactoryResolver,
  LOCALE_ID,
  Inject,
  OnDestroy,
  HostListener
} from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AuthService } from '../auth.service';
import { ResortService } from '../resort.service';
import { User, Alert, Setting } from '../shared/models';
import { HeaderService, HeaderExtensionComponentItem } from '../header.service';
import { HeaderExtensionHostDirective } from './header-extension-host.directive';
import { debounceTime, filter } from 'rxjs/operators';
import { ModalRouteService } from './modal-route.service';
import { ConfirmModalService } from './confirm-modal.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { SocketioClientService } from '../http-clients';
import { SettingsService } from '../settings.service';
import { dayjs, setupDayJSTimezone } from '../../../../common';
import { ReportingReportExportService } from './reporting/report-export.service';

@Component({
  selector: 'ss-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.sass']
})

export class ApplicationComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private resortService: ResortService,
    private authService: AuthService,
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private hes: ModalRouteService,
    private titleService: HeaderService,
    private confirmModalService: ConfirmModalService,
    private settingsService: SettingsService,
    private socketIoClient: SocketioClientService,
    private rres: ReportingReportExportService
  ) {
    setupDayJSTimezone(this.settingsService.get<string>('serverTimezone'));

    this.router.events.pipe(filter((val) => val instanceof NavigationStart)).pipe(debounceTime(10)).subscribe((val) => {
      this.closeMenu();
    });

    this.socketIoClient.serverTime.subscribe((val: number) => {
      const t = dayjs(1000 * val).tz();

      this.todayDate = t.tz().format('YYYY-MM-DD');
      this.todayDateHour = t.tz().format('HH');
      this.todayDateDot = t.second() % 2 === 0 ? '' : ':';
      this.todayDateMinute = t.tz().format('mm');
    });
  }

  isRecentNotificationsExpanded = false;
  isRecentReportExportsExpanded = false;

  isUserProfileExpanded = false;

  version: string = environment.version + (environment.production ? '' : '[dev build]');

  isMenuExpanded = false;
  todayDate: string = dayjs().tz().format('YYYY-MM-DD');
  todayDateHour: string = dayjs().tz().format('HH');
  todayDateDot = ':';
  todayDateMinute: string = dayjs().tz().format('mm');

  user: User;
  resortName: string;
  areas: any[];

  title: string;
  titleLink: string;

  @ViewChild(HeaderExtensionHostDirective, { static: true }) extensionHost: HeaderExtensionHostDirective;
  componentInfo: HeaderExtensionComponentItem;

  alertsCount = 0;
  alertsLevel = '';

  reportExportsInProgressCount = 0;
  reportExportsCount = 0;

  @ViewChild('content', { static: true }) content: ElementRef;
  scrollTop: number;
  scrollLeft: number;

  isConfirmModalShown = false;
  confirmModalContent: string = null;

  isModalShownSubscription: Subscription;

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.hideModalContent();
    }
  }
  toggleRecentNotifications() {
    this.hes.showModalRoute('notifications-recent');
  }
  toggleRecentReportExports() {
    this.hes.showModalRoute('reporting-exports-recent');
  }
  toggleUserProfile() {
    this.hes.showModalRoute('user-profile');
  }

  toggleMenu() {
    this.isMenuExpanded = !this.isMenuExpanded;
  }

  closeMenu() {
    this.isMenuExpanded = false;
  }

  private setAlerts() {
    const alerts = this.resortService.filterAlerts([], [], true);
    this.alertsCount = alerts.length;
    if (this.alertsCount > 0) {
      const lvl = Math.min(...alerts.map(a => a.alertLevel));
      this.alertsLevel = alerts.find(a => a.alertLevel === lvl).level;
    } else {
      this.alertsLevel = '';
    }
  }

  private setReportExports() {
    this.reportExportsCount = this.rres.recentExports.length;
    this.reportExportsInProgressCount = this.rres.recentExports.filter(re => !re.done).length;
  }

  ngOnInit() {
    this.user = this.authService.getUser();
    this.areas = this.resortService.getResortAreas();

    this.setAlerts();
    this.resortService.onAlerts.subscribe(data => {
      this.setAlerts();
    });
    this.rres.recentExportChanged.subscribe(data => {
      this.setReportExports();
    });

    this.titleService.onTitleChange.subscribe(title => {
      setTimeout(() => {
        switch(title) {
          case 'menuResortAreas':
            title = $localize`:Trasy|Wpis w menu - Trasy@@menuResortAreas:Trasy`;
            break;
          case 'menuSnowCannons':
            title = $localize`:Armatki i lance|Wpis w menu - armatki i lance@@menuSnowCannons:Armatki i lance`;
            break;
          case 'menuPumpRooms':
            title = $localize`:Pompownie|Wpis w menu - pompownie@@menuPumpRooms:Pompownie`;
            break;
          case 'menuPowerSources':
            title = $localize`:Zasilanie|Wpis w menu - zasilanie@@menuPowerSources:Zasilanie`;
            break;
          case 'menuWeatherAndSchedules':
            title = $localize`:Pogoda i schematy|Wpis w menu - pogoda i schematy@@menuWeatherAndSchedules:Pogoda i schematy`;
            break;
          // case 'menuConnectionPoints':
          //   title = $localize`:Studnie fundamentowe|Wpis w menu - studnie fundamentowe@@menuConnectionPoints:Studnie`;
          //   break;
          case 'menuReports':
            title = $localize`:Raporty|Wpis w menu - raporty@@menuReports:Raporty`;
            break;
          // case 'menuNotifications':
          //   title = $localize`:Notyfikacje|Wpis w menu - notyfikcaje@@menuNotifications:Notyfikacje`;
          //   break;
          case 'menuSettings':
            title = $localize`:Ustawienia|Wpis w menu - ustawienia@@menuSettings:Ustawienia`;
            break;
          case 'menuUsers':
            title = $localize`:Użytkownicy|Wpis w menu - użytkownicy@@menuUsers:Użytkownicy`;
            break;
          case 'menuAuditTrail':
            title = $localize`:Ślad serwisowy|Wpis w menu - ślad serwisowy@@menuAuditTrail:Ślad serwisowy`;
            break;
          case 'menuEditor':
            title = $localize`:Edytor|Wpis w menu - edytor@@menuEditor:Edytor`;
            break;
        }

        this.title = title;
      });
    });

    this.titleService.onComponentChange.pipe(debounceTime(100)).subscribe(componentInfo => {
      this.updateHeaderExtensionComponent(componentInfo);
    });

    this.confirmModalService.modalRequest.subscribe(message => {
      this.confirmModalContent = message;
      this.isConfirmModalShown = true;
    });

    this.isModalShownSubscription = this.hes.isModalShown.subscribe(data => {
      this.isRecentNotificationsExpanded = data === 'notifications-recent';
      this.isRecentReportExportsExpanded = data === 'reportExports-recent';
      this.isUserProfileExpanded = data === 'user-profile';
    });
  }

  ngOnDestroy(): void {
    this.isModalShownSubscription.unsubscribe();
  }

  hideModalContent() {
    this.hes.closeModal();
  }

  configrmModalOnDecline() {
    this.isConfirmModalShown = false;
    this.confirmModalService.modalResponse.emit(false);
  }

  configrmModalOnAccept() {
    this.isConfirmModalShown = false;
    this.confirmModalService.modalResponse.emit(true);
  }

  updateHeaderExtensionComponent(componentInfo: HeaderExtensionComponentItem) {
    const viewContainerRef = this.extensionHost.viewContainerRef;

    if (!componentInfo || !componentInfo.component) {
      viewContainerRef.clear();

      this.componentInfo = null;

      this.hideModalContent();
    } else {
      if (!this.componentInfo || this.componentInfo.component !== componentInfo.component) {
        viewContainerRef.clear();
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentInfo.component);

        const componentRef = viewContainerRef.createComponent(componentFactory);

        this.componentInfo = componentInfo;
        this.componentInfo.ref = componentRef;
      }

      if (!componentInfo.data) {
        componentInfo.data = {};
      }

      this.componentInfo.ref.instance.updateData({ ... componentInfo.data });
      this.componentInfo.data = componentInfo.data;
    }
  }

  openInNewWindow(ev: MouseEvent) {
    ev.preventDefault();
    window.open(window.location.href, '_blank');
  }

}

