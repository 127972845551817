import { Component, Input } from '@angular/core';
import { SnowCannon } from 'src/app/shared/models';

@Component({
  selector: 'ss-snow-cannon-panel-meteo-bar',
  templateUrl: './meteo-bar.component.html',
  styleUrls: ['./meteo-bar.component.sass']
})
export class SnowCannonPanelMeteoBarComponent {

  @Input()
  public snowCannon: SnowCannon;

}
