<perfect-scrollbar>
  <div class="pump-rooms px-2 pb-2" [ngStyle]="{
    'background-image': perspectiveMapImage ? 'url(\'' + perspectiveMapImage + '\')' : 'url(assets/images/placeholder_bkg.png)',
    'background-size':'cover',
    'background-position': '50% 0',
    'background-repeat': 'no-repeat'
  }">
    <div class="pump-rooms__container">
      <div class="pump-rooms__row pump-rooms__row--bordered px-2 py-4">
        <p class="fs-26 color-white font-bold px-2 mb-4" i18n="Pompownie|Nagłówek na stronie Pompownie@@pumpRoomsHeader">Pompownie</p>
        <div class="d-flex flex-wrap">
          <div *ngFor="let p of pumpRooms"
            [routerLink]="['/application/pump-rooms/detail', p.id]"
            [ngClass]="{ 'pump-room__33wide': grid3, 'pump-room__25wide': !grid3 }"
            class="pump-room clickable mx-2 mb-4 px-2 py-3">
            <p class="fs-22 color-white mb-2">{{ p.symbol }}</p>
            <div class="row mb-4">
              <div class="col-auto">



                  <ng-container *ngIf="p.waterPipelineRefs.size === 1">
                    <ss-pump-room-detail-status [status]="p.getWaterPipelines()[0].computedStatus"></ss-pump-room-detail-status>
                  </ng-container>
                  <ng-container *ngIf="p.waterPipelineRefs.size > 1">
                    <ss-pump-room-detail-multi-status [hideDescriptions]="true" [components]="p.getWaterPipelines()">
                      <ss-pump-room-detail-status status="working" class="first-compacted"></ss-pump-room-detail-status>
                      <ss-pump-room-detail-status status="filling" [optional]="true"></ss-pump-room-detail-status>
                      <ss-pump-room-detail-status status="snoozing"></ss-pump-room-detail-status>
                      <ss-pump-room-detail-status status="ready-to-work"></ss-pump-room-detail-status>
                      <ss-pump-room-detail-status status="standing-by" class="last-compacted"></ss-pump-room-detail-status>
                      <ss-pump-room-detail-status status="breakdown" [standalone]="true"></ss-pump-room-detail-status>
                      <ss-pump-room-detail-status status="connection-lost" [standalone]="true"></ss-pump-room-detail-status>
                    </ss-pump-room-detail-multi-status>
                  </ng-container>




              </div>
              <div class="col"></div>
              <div class="col-auto pl-4" *ngIf="p.canDisplayWaterPressure()">
                <p class="fs-14 color-light-grey" i18n="Ciśnienie|Etykieta pola ciśnienie@@pumpRoomPipeLinePressure">Ciśnienie</p>
                <p class="text-right">
                  <span class="fs-20 color-white">{{ (p.computedWaterPressure || 0)|number:'1.1-1' }}</span>
                  <span class="fs-14 color-light-grey"> bar</span>
                </p>
              </div>
              <div class="col-auto pl-4" *ngIf="p.canDisplayWaterFlow()">
                <p class="fs-14 color-light-grey" i18n="Przepływ aktualny|Etykieta wskaźnika aktualnego przepływu@@pumpRoomCurrentFlow">Przepływ aktualny</p>
                <p class="text-right">
                  <span class="fs-20 color-white">{{ p|convertedValue:'computedWaterFlow' }}</span>
                  <span class="fs-14 color-light-grey"> {{ p|convertedUnit:'computedWaterFlow' }}</span>
                </p>
              </div>
            </div>

            <ss-d3-pressure-and-flow-chart [device]="p"></ss-d3-pressure-and-flow-chart>
            <div class="cleafrix"></div>

            <ng-container *ngIf="getAlertCount(p.id) > 0">
              <ss-badge class="pump-room__alert"
                size="small" color="info"
                *ngIf="getAlertLevel(p.id) === 'notification'" >{{ getAlertCount(p.id) }}</ss-badge>
              <ss-badge class="pump-room__alert"
                size="small" color="warning"
                *ngIf="getAlertLevel(p.id) === 'warning'" >{{ getAlertCount(p.id) }}</ss-badge>
              <ss-badge class="pump-room__alert"
                size="small" color="danger"
                *ngIf="getAlertLevel(p.id) === 'alert'" >{{ getAlertCount(p.id) }}</ss-badge>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="pump-rooms__row px-2 py-4" *ngIf="this.waterReservoirs.length > 0">
        <p class="fs-26 color-white font-bold px-2 mb-4"
          i18n="Zbiorniki|Nagłówek sekcji ze zbiornikami wodnymi@@pumpRoomReservoirs">Zbiorniki</p>
        <div class="reservoirs d-flex flex-wrap">

          <div class="reservoir" *ngFor="let waterReservoir of waterReservoirs">
            <ss-water-tank
              [waterReservoir]="waterReservoir"
              size="large" minLevel="0">
            </ss-water-tank>
          </div>

        </div>
      </div>
    </div>
  </div>
</perfect-scrollbar>
