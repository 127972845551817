import { AbstractDevice } from './abstract-device.model';
import { DeviceSystemType } from '../../../../../common';

export class CommunicationLine extends AbstractDevice {
    systemType: DeviceSystemType = 'CommunicationLine';

    points: any;
    symbol: string;
    connectedDevicesCount: number;
    activeDevicesCount: number;
    lostDevicesCount: number;

    isLocked = false;
}
