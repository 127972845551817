import { subjectFieldMapping } from '..';
import { ConvertUnit, isArray, isNumber, isObject } from '../../..';
import { ReportPartView } from '../part-view';
import { ReportResultView } from '../result-view';

export class ReportAggrSumPartView extends ReportPartView {
  public calculate(resultView: ReportResultView) {
    this.calculateAutoLabel(resultView);
    if(this.part.series.length === 1) {
      const type = this.part.series[0].subjectType;
      const field = this.part.series[0].subjectField;
      const id = this.part.series[0].subjectId;

      if(subjectFieldMapping[type] && subjectFieldMapping[type].fields[field]) {
        const dataByIds = this.getDataByIds(resultView);

        if(!isObject(dataByIds[id]) || !isArray(dataByIds[id][field])) {
          return;
        }

        const values = (dataByIds[id][field] as number[] || []).filter(v => isNumber(v));
        if(values.length > 0) {
          const numOfHours = resultView.to.diff(resultView.from, 'hours');

          let unitType = subjectFieldMapping[type].fields[field].unitType;
          let storedUnitOverride = subjectFieldMapping[type].fields[field].storedUnitOverride;

          switch(unitType) {
            case 'waterFlow':
              // waterFlow: 'l/s'|'m3/h';
              // waterUsage: 'l'|'m3';
              unitType = 'waterUsage';
              storedUnitOverride = 'm3';
              break;
            case 'production':
              // production: 'm3/h';
              // waterUsage: 'l'|'m3';
              unitType = 'waterUsage';
              storedUnitOverride = 'm3';
              break;
            case 'powerDraw':
              // powerDraw: 'kW';
              // powerUsage: 'kWh';
              unitType = 'powerUsage';
              storedUnitOverride = null;
              break;
            default:
              return;
          }

          let avg = values.reduce((p, c) => p + c, 0) / values.length;
          avg = avg * numOfHours;

          const scalarData = { value: avg };
          ConvertUnit(
            unitType,
            storedUnitOverride
          )(scalarData, 'value');
          scalarData.value = avg;
          this.scalarData = scalarData as any;
        }
      }
    }
  }
}
