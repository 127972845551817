import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from 'src/app/header.service';
import { ResortService } from 'src/app/resort.service';
import {
  Map as LeafletMap, latLng, tileLayer, TileLayer, ImageOverlay, FeatureGroup, MapOptions
} from 'leaflet';
import { Resort } from 'src/app/shared/models';
import { AuthService } from 'src/app/auth.service';
import { EndpointMapService } from 'src/app/endpoint-map.service';
import { Subscription } from 'rxjs';
import { EditorMapControllerService } from './editor-map-controller.service';

declare const L;

@Component({
  selector: 'ss-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.sass']
})
export class EditorComponent implements OnInit {

  constructor(
    private titleService: HeaderService,
    private resortService: ResortService,
    private mapCtrl: EditorMapControllerService
  ) { }

  resort: Resort;

  options: MapOptions;

  onMapReady(map: LeafletMap) {
    this.mapCtrl.map = map;
    this.mapCtrl.updateControls();
  }

  ngOnInit() {
    this.titleService.setUpdateHeader('menuEditor');

    this.resort = this.resortService.getResort();

    this.options = this.mapCtrl.getMapOptions(this.resort.mapZoom, this.resort.mapCenter);
  }

}
