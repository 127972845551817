import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ModalRouteService {

  constructor(private router: Router) { }

  public isModalShown: EventEmitter<string> = new EventEmitter();
  public results: EventEmitter<any> = new EventEmitter();

  public lastRequestData: any;

  public showModalRoute(route: string) {
    this.isModalShown.emit(route);
    this.router.navigate(['/application', { outlets: { modal: route }}], { skipLocationChange: true});
  }

  public showDialog(route: string, onClose: (resp: any) => void, lastRequestData?: any) {
    this.lastRequestData = lastRequestData;

    const renameSubscription = this.results.subscribe(resp => {
      renameSubscription.unsubscribe();
      onClose(resp);
    });

    this.showModalRoute(route);
  }

  public closeModal(result?: any) {
    this.router.navigate(['/application', { outlets: { modal: null }}], { skipLocationChange: true});

    this.lastRequestData = undefined;
    this.isModalShown.emit('');

    this.results.emit(result);
  }
}
