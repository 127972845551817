<ss-page>
  <ss-page-header>
    <ss-page-header-title i18n="Raporty|Tytił na liście raportów@@report-list-title">Raporty</ss-page-header-title>

    <div class="flex-grow-1"></div>
    <ss-page-button-group>

      <a [routerLink]="['/', 'application', 'reporting', 'blank-definition']"
        class="btn btn-default"
        i18n="Dodaj nowy raport|Przycisk na liście raportów dodaj nowy raport@@report-list-btn-new-report">Dodaj nowy raport</a>
    </ss-page-button-group>

  </ss-page-header>


  <ng-container *ngIf="standardDefinitions && standardDefinitions.length > 0">
    <ss-page-header class="pt-4">
      <ss-page-header-title [isSubtitle]="true" i18n="Raporty standardowe|Nagłówek Raporty standardowe@@report-list-subtitle-std-reports">Raporty standardowe</ss-page-header-title>
    </ss-page-header>

    <ss-page-accordion-panel>
      <ng-container *ngFor="let def of standardDefinitions">


        <ss-page-accordion-block>
          <ss-page-accordion-block-header>
            <div class="row align-items-center">
              <div class="col clickable" [routerLink]="['/', 'application', 'reporting', def.id ]">
                <h3 class="m-0 mb-1">{{ def.reportName }}</h3>
              </div>
              <div class="col-auto">
                <a [routerLink]="['/', 'application', 'reporting', def.id ]"
                  class="btn btn-primary" i18n="Wyświetl raport|Link na liście raoprtów Wyświetl raport|@@report-list-link-preview">Wyświetl raport</a>
              </div>
            </div>
          </ss-page-accordion-block-header>
        </ss-page-accordion-block>

      </ng-container>
    </ss-page-accordion-panel>
  </ng-container>

  <ng-container *ngIf="customDefinitions && customDefinitions.length > 0">

    <ss-page-header class="pt-4">
      <ss-page-header-title [isSubtitle]="true" i18n="Raporty użytkowników|Nagłówek Raporty użytkowników@@report-list-subtitle-custom-reports">Raporty użytkowników</ss-page-header-title>
    </ss-page-header>
    <ss-page-accordion-panel>

      <div class="drop-container-holder">
        <div class="drop-container"
          [dragula]="DG_REPORTS"
          (dragulaModelChange)="reorderCustomDefinitions($event)"
          [dragulaModel]="customDefinitions">

          <div class="clickable"
            *ngFor="let def of customDefinitions" >
            <ss-page-accordion-block>
              <ss-page-accordion-block-header>
                <div class="row align-items-center" [routerLink]="['/', 'application', 'reporting', def.id ]">
                  <div class="col">
                    <h3 class="m-0 mb-1">{{ def.reportName }}</h3>
                  </div>
                  <div class="col" [routerLink]="['/', 'application', 'reporting', def.id ]">
                    <small class="color-light-grey" i18n="Raporty użytkowników info|@@report-list-custom-reports-info-devices">Raport obejmuje urządzenia:</small><br />
                    <ng-container *ngFor="let deviceKP of def.devicesInParts | keyvalue; let l = last">
                      <span class="color-light-blue">
                        <ss-translated-device-type-symbol [device]="deviceKP.value"></ss-translated-device-type-symbol>
                      </span>
                      <ng-container *ngIf="!l">, </ng-container>
                    </ng-container>
                    <strong></strong>
                  </div>
                  <div class="col" [routerLink]="['/', 'application', 'reporting', def.id ]">
                    <small class="color-light-grey" i18n="Raporty użytkowników info@@report-list-custom-reports-info-precision">Dokładność:</small> &nbsp;
                    <span class="color-light-blue">{{ def.precision }}</span><br />
                    <small class="color-light-grey" i18n="Raporty użytkowników info@@report-list-custom-reports-info-duration">Długość wyświetlanego przedziału:</small> &nbsp;
                    <span class="color-light-blue">{{ def.duration }}</span><br />
                    <small class="color-light-grey" i18n="Raporty użytkowników info@@report-list-custom-reports-info-is-live">Wyświetlanie na żywo:</small> &nbsp;
                    <span class="color-light-blue">{{ def.isLive ? 'tak' : 'nie' }}</span>
                  </div>
                  <div class="col-auto">
                    <a [routerLink]="['/', 'application', 'reporting', def.id ]"
                      class="btn btn-primary" i18n="Wyświetl raport|Link na liście raoprtów Wyświetl raport|@@report-list-link-preview">Wyświetl raport</a>
                  </div>
                  <div class="col-auto handler report-handle">
                    <small i18n="Raporty użytkowników uchwyt-sortowania|@@reportinSortHandlerDesctiption">przeciągnij by zmienić kolejność</small>
                    <svg>
                      <use xlink:href="#icon-move" />
                    </svg>
                  </div>
                </div>
              </ss-page-accordion-block-header>
            </ss-page-accordion-block>
          </div>

        </div>
      </div>

    </ss-page-accordion-panel>

  </ng-container>



</ss-page>
