import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { EndpointMapService } from '../endpoint-map.service';
import {
  PumpRoom,
  ResortArea,
  SnowCannon,
  WaterPipeline,
  WorkingArea,
  ConnectionPoint,
  MeteoStation,
  ElectricalLine,
  PowerSource
} from '../shared/models';
import { tap } from 'rxjs/operators';
import { ResortService } from '../resort.service';
import { forkJoinWithProgress } from '../fork-join-with-progress.rx';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ConfigureClientService {

  constructor(private e: EndpointMapService, private rs: ResortService, private http: HttpClient) {}

  public startDevice(id) {
    this.rs.setLocalLockOnDevice(id);
    return this.http.post(this.e.startDevice + '/' + id, null, httpOptions);
  }

  public stopDevice(id) {
    this.rs.setLocalLockOnDevice(id);
    return this.http.post(this.e.stopDevice + '/' + id, null, httpOptions);
  }

  public configureElectricalLine(area: ElectricalLine, callData: any) {
    return this.configureElectricalLineById(area.id, callData);
  }

  public configureElectricalLineById(id: string, callData: any) {
    this.rs.setLocalLockOnDevice(id);
    return this.http.post(this.e.configureElectricalLines + '/' + id, callData, httpOptions);
  }

  public configurePowerSource(area: PowerSource, callData: any) {
    return this.configurePowerSourceById(area.id, callData);
  }

  public configurePowerSourceById(id: string, callData: any) {
    this.rs.setLocalLockOnDevice(id);
    return this.http.post(this.e.configurePowerSources + '/' + id, callData, httpOptions);
  }

  public configureConnectionPoint(area: ConnectionPoint, callData: any) {
    return this.configureConnectionPointById(area.id, callData);
  }

  public configureConnectionPointById(id: string, callData: any) {
    this.rs.setLocalLockOnDevice(id);
    return this.http.post(this.e.configureConnectionPoint + '/' + id, callData, httpOptions);
  }

  public configureMeteoStation(area: MeteoStation, callData: any) {
    return this.configureMeteoStationById(area.id, callData);
  }

  public configureMeteoStationById(id: string, callData: any) {
    this.rs.setLocalLockOnDevice(id);
    return this.http.post(this.e.configureMeteoStation + '/' + id, callData, httpOptions);
  }

  public configureArea(area: ResortArea, callData: any) {
    return this.configureAreaById(area.id, callData);
  }

  public configureAreaById(id: string, callData: any) {
    this.rs.setLocalLockOnDevice(id);
    return this.http.post(this.e.configureResortArea + '/' + id, callData, httpOptions);
  }

  public configureWorkingArea(area: WorkingArea, callData: any) {
    return this.configureWorkingAreaById(area.id, callData);
  }

  public configureWorkingAreaById(id: string, callData: any) {
    this.rs.setLocalLockOnDevice(id);
    return this.http.post(this.e.configureWorkingArea + '/' + id, callData, httpOptions);
  }

  public configureSnowCannon(sc: SnowCannon, callData: any) {
    return this.configureSnowCannonById(sc.id, callData);
  }

  public configureSnowCannonById(id: string, callData: any) {
    this.rs.setLocalLockOnDevice(id);
    return this.http.post(this.e.configureSnowCannon + '/' + id, callData, httpOptions);
  }




  // public startMultipleSnowCannons(ids: string[]): Observable<any> {
  //   return forkJoinWithProgress(ids.map(id => {
  //     this.rs.setLocalLockOnDevice(id);
  //     return this.http.post(this.e.startDevice + '/' + id, null, httpOptions);
  //   }));
  // }

  // public stopMultipleSnowCannons(ids: string[]): Observable<any> {
  //   return forkJoinWithProgress(ids.map(id => {
  //     this.rs.setLocalLockOnDevice(id);
  //     return this.http.post(this.e.stopDevice + '/' + id, null, httpOptions);
  //   }));
  // }

  // public configureMultipleSnowCannons(ids: string[], callData: any): Observable<any> {
  //   return forkJoinWithProgress(ids.map(id => {
  //     this.rs.setLocalLockOnDevice(id);
  //     return this.http.post(this.e.configureSnowCannon + '/' + id, callData, httpOptions);
  //   }));
  // }




  public configurePumpRoom(p: PumpRoom, data: any) {
    return this.http.post(this.e.configurePumpRoom + '/' + p.id, data, httpOptions);
  }

  public configurePumpRoomById(id: string, data: any) {
    return this.http.post(this.e.configurePumpRoom + '/' + id, data, httpOptions);
  }

  public configureWaterPipeline(p: WaterPipeline, automaticOperationMode?: boolean, targetWaterPressure?: number): Observable<any> {
    this.rs.setLocalLockOnDevice(p.id);
    const callData = {};
    if (automaticOperationMode !== null) {
      callData['operationMode'] = (automaticOperationMode ? 1 : 0);
    }
    if (targetWaterPressure !== null) {
      callData['setTargetWaterPressure'] = targetWaterPressure;
    }

    return this.http.post(this.e.configureWaterPipeline + '/' + p.id, callData, httpOptions);
  }

}
