<div class="number-input" [ngClass]="{
    'number-input--small': size === 'small',
    'number-input--large': size === 'large'
}">
    <button [disabled]="disabled" (click)="decrease()" class="number-input__btn number-input__btn--prev">
        <svg>
          <use xlink:href='#icon-chevron-down'/>
        </svg>
    </button>
    <div class="number-input__box" [ngClass]="{'number-dropdown': size === 'large'}">
        <span class="number-input__extra-content" [ngClass]="{'hidden': !extraContent}">{{ extraContent }}</span>
        <input [disabled]="disabled" type="text" [value]="inputValue | number:'1.0-1'" (change)="inputChanged($event)"/>
        <ng-container *ngIf="size === 'large'">
            <button class="number-dropdown__btn">
                <svg>
                  <use xlink:href='#icon-chevron-down'/>
                </svg>
            </button>
        </ng-container>
    </div>
    <button [disabled]="disabled" (click)="increase()" class="number-input__btn number-input__btn--next">
        <svg>
          <use xlink:href='#icon-chevron-down'/>
        </svg>
    </button>
</div>
