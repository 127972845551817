import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { AuthService } from '../../../auth.service';

export class SnowQualityValue {
  current?: number;
  high?: number;
  low?: number;
  type?: number;
}

@Component({
  selector: 'ss-snow-quality',
  templateUrl: './snow-quality.component.html',
  styleUrls: ['./snow-quality.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnowQualityComponent implements OnInit {

  @Input()
  set disableSnowQualitySelection(val: boolean) {
    this._disableSnowQualitySelection = val;

    this.calcCanShowSingleSnowQuality();
  }
  get disableSnowQualitySelection(): boolean {
    return this._disableSnowQualitySelection;
  }
  @Input()
  set snowQualityType(v: number) {
    this._snowQualityType = v;

    this.calcCanShowSingleSnowQuality();
  }
  get snowQualityType(): number {
    return this._snowQualityType;
  }

  @Input()
  set noOfDevicesBySnowQuality(v: number[]) {
    this._noOfDevicesBySnowQuality = v;

    this.noOfDevices1 = this.getNoOfDevices(1);
    this.noOfDevices2 = this.getNoOfDevices(2);
    this.noOfDevices3 = this.getNoOfDevices(3);
    this.noOfDevices4 = this.getNoOfDevices(4);
    this.noOfDevices5 = this.getNoOfDevices(5);
    this.noOfDevices6 = this.getNoOfDevices(6);
    this.noOfDevices7 = this.getNoOfDevices(7);

    this.calcCanShowSingleSnowQuality();
  }

  @Input()
  set currentSnowQuality(v: number) {
    this._currentSnowQuality = v;

    this.calcCanShowSingleSnowQuality();
  }
  get currentSnowQuality(): number {
    return this._currentSnowQuality;
  }

  constructor(private as: AuthService) {
    this.uniqElementId = 'snow-quality-' + Math.random() + '-el';
  }

  isDropdownExpanded = false;

  @Output() snowQualityChange: EventEmitter<SnowQualityValue> = new EventEmitter();

  private _isDisabled = false;
  @Input()
  public get isDisabled(): boolean {
    return this._isDisabled || !this.as.isAuthorized('ROLE_OPERATOR');
  }
  public set isDisabled(v: boolean) {
    this._isDisabled = v;
  }
  @Input()
  public size: 'extra-small'|'small'|'large'|'' = '';

  @Input()
  public showIcons = false;

  private _disableSnowQualitySelection = false;
  private _snowQualityType: number;
  private _noOfDevicesBySnowQuality: number[];

  noOfDevices1 = 0;
  noOfDevices2 = 0;
  noOfDevices3 = 0;
  noOfDevices4 = 0;
  noOfDevices5 = 0;
  noOfDevices6 = 0;
  noOfDevices7 = 0;

  @Input()
  set snowQualityMin(val: number) {
    this._snowQualityMin = val;

    this.calcCanShowSingleSnowQuality();
  }
  get snowQualityMin(): number {
    return this._snowQualityMin;
  }
  private _snowQualityMin: number;
  private previousSnowQualityMin = NaN;

  @Input()
  set snowQualityMax(val: number) {
    this._snowQualityMax = val;

    this.calcCanShowSingleSnowQuality();
  }
  get snowQualityMax(): number {
    return this._snowQualityMax;
  }
  private _snowQualityMax: number;
  private previousSnowQualityMax = NaN;

  @Input()
  set snowQualityConstant(val: number) {
    this._snowQualityConstant = val;

    this.calcCanShowSingleSnowQuality();
  }
  get snowQualityConstant(): number {
    return this._snowQualityConstant;
  }
  private _snowQualityConstant: number;

  private _currentSnowQuality: number;

  currentSnowQualityToShow: number = null;

  changeSnowQualitySelectionTimeut = null;

  uniqElementId: string;
  toggleDropdown() {
    this.isDropdownExpanded = !this.isDropdownExpanded;
  }
  private calcCanShowSingleSnowQuality() {
    let isDiffrentFrom0SnowQuality = this.currentSnowQuality;

    let howManyDifferFrom0 = 0;
    for (let i = 1; i <= 7; i++) {
      if (this.getNoOfDevices(i) > 0) {
        howManyDifferFrom0++;
        isDiffrentFrom0SnowQuality = i;
      }
    }

    if (howManyDifferFrom0 <= 1) {
      this.currentSnowQualityToShow = isDiffrentFrom0SnowQuality;
    } else {
      this.currentSnowQualityToShow = null;
    }
  }

  private getNoOfDevices(quality: number): number {
    if (
      this._noOfDevicesBySnowQuality
      && this._noOfDevicesBySnowQuality.length > 0
      && this._noOfDevicesBySnowQuality.length >= quality
    ) {
      return this._noOfDevicesBySnowQuality[ quality - 1 ];
    }
    return 0;
  }

  setSnowQualityType(type: number) {
    this.snowQualityType = type;

    this.emitChange();
  }
  changeSnowQualitySelection(quality: number) {
    if (this.disableSnowQualitySelection || this.snowQualityType === 0) {
      this.currentSnowQuality = quality;

      this.emitChange();
    } else {
      if (this.changeSnowQualitySelectionTimeut === null) {
        clearTimeout(this.changeSnowQualitySelectionTimeut);
        this.changeSnowQualitySelectionTimeut = setTimeout(() => {
          this.snowQualityMin = this.previousSnowQualityMin;
          this.snowQualityMax = this.previousSnowQualityMax;

          this.changeSnowQualitySelectionTimeut = null;
        }, 3000);
        if (isNaN(this.previousSnowQualityMin)) {
          this.previousSnowQualityMin = this.snowQualityMin;
        }
        if (isNaN(this.previousSnowQualityMax)) {
          this.previousSnowQualityMax = this.snowQualityMax;
        }
        this.snowQualityMin = quality;
        this.snowQualityMax = quality;
      } else {
        clearTimeout(this.changeSnowQualitySelectionTimeut);
        this.changeSnowQualitySelectionTimeut = null;
        if (this.snowQualityMax > quality) {
          this.snowQualityMin = quality;
        } else {
          this.snowQualityMax = quality;
        }
        this.emitChange();
      }
    }
  }

  emitChange() {
    if (this.disableSnowQualitySelection) {
      this.snowQualityChange.emit({
        current: this.currentSnowQuality
      });
    } else if (
      this.snowQualityType === 0
      && this.currentSnowQuality > 0
    ) {
      this.snowQualityChange.emit({
        type: this.snowQualityType,
        current: this.currentSnowQuality
      });
    } else if (
      this.snowQualityType > 0
      && this.snowQualityMin > 0
      && this.snowQualityMax > 0
      && this.snowQualityMin <= this.snowQualityMax
    ) {
      this.snowQualityChange.emit({
        type: this.snowQualityType,
        low: this.snowQualityMin,
        high: this.snowQualityMax
      });
    }
  }

  ngOnInit() {
  }

}
