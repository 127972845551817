import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from 'src/app/header.service';
import { ResortService } from 'src/app/resort.service';
import { ProgressOfObservablesService } from '../modals/progress-of-observables.service';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { AbstractDevice } from 'src/app/shared/models/abstract-device.model';
import { toSearchString } from '../../../../../common';

@Component({
  selector: 'ss-recent-notifications',
  templateUrl: './recent-notifications.component.html',
  styleUrls: ['./recent-notifications.component.sass']
})

export class RecentNotificationsComponent implements OnInit, OnDestroy {

  constructor(
    private titleService: HeaderService,
    private cs: ConfigureClientService,
    private ps: ProgressOfObservablesService,
    private rs: ResortService
  ) { }

  devices: AbstractDevice[] = [];
  showMeteoStations = false;
  showGroups = false;

  deviceSearch = '';
  selectedLevels: string[] = [];
  selectedTypes: string[] = [];
  foundDevices: AbstractDevice[] = [];
  foundDevicesCount = 0;

  onlyActive = true;

  contextIds: string[] = [];

  alertCount = 0;

  ngOnInit() {
    const devs = [];
    this.rs.getResortAreas().forEach(d => {
      devs.push(d);
    });

    if (this.rs.getWorkingAreas().length > 0) {
      this.showGroups = true;
      this.rs.getWorkingAreas().forEach(d => {
        devs.push(d);
      });
    }
    this.selectedTypes.push('PumpRoom|WaterPipeline');
    this.rs.getPumpRooms().forEach(d => {
      devs.push(d);
    });
    this.rs.getWaterPipelines().forEach(d => {
      devs.push(d);
    });
    this.selectedTypes.push('PowerSource|ElectricalLine');
    this.rs.getPowerSources().forEach(d => {
      devs.push(d);
    });
    this.rs.getElectricalLines().forEach(d => {
      devs.push(d);
    });
    if (this.rs.getMeteoStations().length > 0) {
      this.showMeteoStations = true;
      this.selectedTypes.push('MeteoStation');
      this.rs.getMeteoStations().forEach(d => {
        devs.push(d);
      });
    }
    this.selectedTypes.push('SnowCannon');
    this.rs.getSnowCannons().forEach(d => {
      devs.push(d);
    });
    this.rs.getConnectionPoints().forEach(d => {
      devs.push(d);
    });

    this.devices = devs.sort((a, b) => {
      const nA = parseInt(a.symbol, 10);
      const nB = parseInt(b.symbol, 10);

      if (isNaN(nA) !== isNaN(nB)) {
        return isNaN(nA) ? 1 : -1;
      }

      if (isNaN(nA) && isNaN(nB)) {
        return `${a.symbol}`.localeCompare(`${b.symbol}`);
      }

      return nA - nB;
    });

    this.searchDevices();
  }

  public resetHardwareAlerts() {
    const scids = this.rs.getSnowCannons().map(d => d.id);
    const prids = this.rs.getPumpRooms().map(d => d.id);

    const actions = [];
    if (scids.length > 0) {
      actions.push(...scids.map(id => this.cs.configureSnowCannonById(id, {
        commandResetAlerts: true
      })));
    }
    if (prids.length > 0) {
      actions.push(...prids.map(id => this.cs.configurePumpRoomById(id, {
        commandResetAlerts: true
      })));
    }
    this.ps.runProgressAction(actions);
  }

  public toggleInArray(value: string, target: string[]) {
    const idx = target.indexOf(value);
    if (idx >= 0) {
      target.splice(idx, 1);
    } else {
      target.push(value);
    }

    this.searchDevices();
  }

  public inArray(needle: string, haystack: string[]): boolean {
    return haystack.length === 0 || haystack.includes(needle);
  }

  public searchDevices() {
    let fd = this.devices.filter(a => toSearchString(`${a.symbol}`).startsWith(toSearchString(`${this.deviceSearch}`)));

    fd.filter(d => d.systemType === 'ResortArea').forEach(a => {
      fd.push(...this.rs.getResortArea(a.id).getSnowCannons());
      fd.push(...this.rs.getResortArea(a.id).getConnectionPoints());
    });

    if (this.selectedTypes.length > 0) {
      fd = fd.filter(d => d.systemType.match('^' + this.selectedTypes.join('|') + '$'));
    }

    this.foundDevices = fd.filter((value, index, self) => self.indexOf(value) === index);
    this.foundDevicesCount = this.foundDevices.length;
    this.contextIds = this.foundDevices.map(d => d.id);
  }

  public selectDevice(id) {
    if (this.foundDevices.length === 1 && this.foundDevices[0].id === id) {
      this.deviceSearch = '';
      this.searchDevices();
    } else {
      this.foundDevices = this.devices.filter(d => d.id === id);
      this.contextIds = [id];
      this.foundDevicesCount = 1;
    }
  }

  ngOnDestroy() {

  }

}
