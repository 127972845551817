import { Component, OnInit, Input, EventEmitter, SimpleChanges, OnChanges, ElementRef, Output } from '@angular/core';
import { debounceTime, tap, throttleTime } from 'rxjs/operators';

import { SettingsService } from '../../../../settings.service';
import {
  DAYJS,
  dayjs,
  isArray,
  isNullOrUndefined,
  isNumber,
  ReportPartView,
  ReportResultView,
  TimedChartData
} from '../../../../../../../common';
import { D3 } from '../../../../../../../common/d3';

@Component({
  selector: 'ss-reporting-single-report-time-range-toolbar',
  templateUrl: './time-range-toolbar.component.html',
  styleUrls: ['./time-range-toolbar.component.sass']
})
export class ReportingSingleReportTimeRangeToolbarComponent implements OnInit, OnChanges {

  @Output()
  public updateResult: EventEmitter<any> = new EventEmitter();

  @Output()
  public updateView: EventEmitter<any> = new EventEmitter();

  // getter setter zamiast onchanges i oninit
  @Input()
  public resultView: ReportResultView;

  @Input()
  public isLoading = false;

  @Input()
  public refresher: string;

  @Input()
  public selectedDate: DAYJS;

  public previewBoxDataSeriesFieldName: string;
  public previewBoxDataSeriesLabelsName: string;
  public previewBoxDataSeries: TimedChartData;

  @Input()
  public showPreviewBox = false;

  @Input()
  public viewBoxWidth = 1800;

  private viewBoxHeight = 60;
  private axisOffset = 40;
  private hostElement: any;
  private svg;
  private g;
  private eventsRect;
  private xAxisGroup;
  private secondaryXAxisGroup;
  private xAxisScale: D3.ScaleTime<number, number>;
  private yAxisScale: D3.ScaleLinear<number, number>;

  public canDisplayChart = false;

  private dataChange: EventEmitter<any> = new EventEmitter();
  private selectionsChange: EventEmitter<any> = new EventEmitter();
  private dragRangeChanges: EventEmitter<[boolean, number?, number?]> = new EventEmitter();

  moveToDate(date: DAYJS) {
    if(!this.resultView.isLive) {
      this.updateView.emit({ displayTo: date });
    }
  }

  private emmitUpdateResult(from: DAYJS, to: DAYJS) {
    const minStartDate: DAYJS = dayjs(1000 * this.settings.get<number>('timestampOfOldestStatusEntry'));
    const now = dayjs();
    from = minStartDate.isAfter(from) ? minStartDate : from;
    to = now.isBefore(to) ? now : to;
    from = from.startOf('day');
    to = to.endOf('day');
    if(!this.resultView.isLive) {
      this.updateResult.emit({ from, to });
    }
  }
  public constructor(
    private settings: SettingsService,
    private elRef: ElementRef
  ) {
    this.hostElement = this.elRef.nativeElement;

    this.selectionsChange.pipe(debounceTime(10)).subscribe(() => {
      if (this.svg) {
        this.updateSelections();
      }
    });
    this.dragRangeChanges.pipe(debounceTime(10)).subscribe(argx => {
      this.updateDragRanges(argx);
    });
    this.dataChange.pipe(debounceTime(100)).subscribe(() => {
      let data: { labels }[];

      let previewBoxDataSeriesLabelsName;
      if(this.showPreviewBox) {
        let previewBoxChartConfigSeries;
        let previewBoxDataSeries;
        let previewBoxDataSeriesFieldName;
        const unitHierarhy = [
          'length',
          'temperature',
          'airPressure',
          'waterPressure',
          'airFlow',
          'powerDraw',
          'waterFlow',
          'airUsage',
          'powerUsage',
          'waterUsage',
          'production',
        ];
        if(this.resultView && isArray(this.resultView.partViews)) {
          this.resultView.partViews.forEach(pv => {
            pv = ReportPartView.create(pv.part);
            pv.calculate(this.resultView, this.resultView.from, this.resultView.to);
            if(pv.chartConfig && isArray(pv.chartConfig.series)) {
              pv.chartConfig.series.forEach(s => {
                if(
                  !previewBoxChartConfigSeries
                  || unitHierarhy.indexOf(s.unit) > unitHierarhy.indexOf(previewBoxChartConfigSeries.unit)
                ) {
                  previewBoxDataSeries = pv.chartData;
                  previewBoxChartConfigSeries = s;
                  previewBoxDataSeriesFieldName = s.valueFieldName;
                  previewBoxDataSeriesLabelsName = s.labelFieldName;
                }
              });
            }
          });
        }
        this.previewBoxDataSeries = previewBoxDataSeries;
        this.previewBoxDataSeriesFieldName = previewBoxDataSeriesFieldName;
        this.previewBoxDataSeriesLabelsName = previewBoxDataSeriesLabelsName;
      }

      if (this.previewBoxDataSeries && this.previewBoxDataSeriesFieldName && this.previewBoxDataSeriesLabelsName) {
        data = this.previewBoxDataSeries.getD3DrawabeSeries(this.previewBoxDataSeriesLabelsName) as any;
      }
      if (!data || data.length === 0) {
        this.canDisplayChart = false;
        return;
      }
      this.canDisplayChart = true;
      if (!this.svg) {
        this.createChart(data);
      } else {
        this.updateChart(data);
      }
      this.updateSelections();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.viewBoxWidth
      || changes.showPreviewBox
      || changes.resultView
      || changes.refresher // brakuje doładowywania zielonego wykresu za całą długość raportu przy dodawaniu serii
    ) {
      this.dataChange.emit();
    }

    if (
      changes.selectedDate
      || changes.refresher
    ) {
      this.selectionsChange.emit();
    }
  }

  ngOnInit() {
    this.dataChange.emit();
  }

  private createChart(data: { labels }[]) {
    this.setChartDimensions();

    this.draw(data);
  }

  private updateChart(data: { labels }[]) {
    this.setChartDimensions();
    this.draw(data);
  }

  private setChartDimensions() {
    if (!this.svg) {
      this.svg = D3.select(this.hostElement).select('.chart-background').append('svg')
          .attr('width', '100%')
          .style('display', 'block');

      const filter = this.svg.append('defs')
        .append('filter')
        .attr('id', 'drop-shadow-rtb')
        .attr('filterUnits', 'userSpaceOnUse');

      filter.append('feGaussianBlur')
        .attr('in', 'SourceAlpha')
        .attr('stdDeviation', 5);

      filter.append('feOffset')
        .attr('dx', 0)
        .attr('dy', 0);
      const feTransfer = filter.append('feComponentTransfer');

      feTransfer.append('feFuncA')
        .attr('type', 'linear')
        .attr('slope', 1);

      const feMerge = filter.append('feMerge');
      feMerge.append('feMergeNode');
      feMerge.append('feMergeNode')
        .attr('in', 'SourceGraphic');
    }
    this.svg.attr('viewBox', '0 0 ' + this.viewBoxWidth + ' ' + this.viewBoxHeight);

    if (!this.g) {
      this.g = this.svg.append('g')
        .attr('class', 'ragne-toolbar')
        .attr('transform', 'translate(0,0)');
    }

    if (!this.eventsRect) {
      let isDrag = false;
      let disableMouseUpe = false;
      let dragStartPosition = NaN;
      let isDragTimeout;
      const clientX = (evt: MouseEvent) => (evt.offsetX < this.axisOffset)
          ? this.axisOffset
          : (
            evt.offsetX > this.viewBoxWidth - this.axisOffset
            ? this.viewBoxWidth - this.axisOffset
            : evt.offsetX
          );
      this.eventsRect = this.g.append('rect')
        .attr('class', 'event-catcher')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', this.viewBoxWidth)
        .attr('height', this.viewBoxHeight)
        .on('contextmenu', (ev: MouseEvent) => {
          ev.preventDefault();

          const delta = Math.ceil(this.resultView.to.diff(this.resultView.from, 'days', true));
          const center = dayjs(this.xAxisScale.invert(clientX(ev)));
          const from = center.subtract(delta, 'days').startOf('days');
          const to = center.add(delta, 'days').endOf('days');

          this.emmitUpdateResult(from, to);

          disableMouseUpe = true;
        })
        .on('mousedown', (event: MouseEvent) => {
          clearTimeout(isDragTimeout);
          isDragTimeout = undefined;
          dragStartPosition = clientX(event);
          disableMouseUpe = false;
          this.dragRangeChanges.emit([isDrag, dragStartPosition, dragStartPosition]);
        })
        .on('mouseleave',  (event: MouseEvent) => {
          clearTimeout(isDragTimeout);
          isDragTimeout = undefined;
          isDrag = false;
          dragStartPosition = NaN;
          disableMouseUpe = true;
          this.dragRangeChanges.emit([isDrag]);
        })
        .on('mouseenter',  (event: MouseEvent) => {
          clearTimeout(isDragTimeout);
          isDragTimeout = undefined;
          isDrag = false;
          dragStartPosition = NaN;
          this.dragRangeChanges.emit([isDrag]);
          disableMouseUpe = true;
        })
        .on('mousemove',  (event: MouseEvent) => {
          const cx = clientX(event);
          if (!isDragTimeout && Math.abs(dragStartPosition - cx) > 10) {
            isDragTimeout = setTimeout(() => {
              isDrag = true;
              this.dragRangeChanges.emit([isDrag, dragStartPosition, cx]);
            }, 500);
          }
          this.dragRangeChanges.emit([isDrag, dragStartPosition, cx]);
        })
        .on('mouseup',  (event: MouseEvent) => {
          clearTimeout(isDragTimeout);
          isDragTimeout = undefined;
          if (!disableMouseUpe) {
            if (isDrag)  {
              let a = dragStartPosition;
              let b = clientX(event);
              if (a > b) {
                a = b;
                b = dragStartPosition;
              }

              const from = dayjs(this.xAxisScale.invert(a)).startOf('days');
              const to = dayjs(this.xAxisScale.invert(b)).endOf('days');

              this.emmitUpdateResult(from, to);
            } else {
              const centerDate = this.xAxisScale.invert(clientX(event));

              if(!this.resultView.isLive) {
                this.updateView.emit({
                  displayTo: this.resultView.calcCenterOnDate(dayjs(centerDate))
                });
              }
            }
          }

          disableMouseUpe = false;
          isDrag = false;
          dragStartPosition = NaN;
          this.dragRangeChanges.emit([isDrag]);
        });
    }
    this.eventsRect
      .attr('width', this.viewBoxWidth)
      .attr('height', this.viewBoxHeight);

  }

  private draw(data: { labels }[]) {
    let linesG = this.g.select('.lines-g');
    if (linesG.empty()) {
      linesG = this.g.append('g')
        .attr('class', 'lines-g');
      linesG.append('path')
        .attr('class', 'fill');
      linesG.append('path')
        .attr('class', 'stroke');
      linesG.append('circle')
        .attr('class', 'start')
        .attr('r', 2)
        .attr('cx', this.axisOffset)
        .attr('cy', 0);
      linesG.append('circle')
        .attr('class', 'end')
        .attr('r', 2)
        .attr('cx', this.viewBoxWidth - this.axisOffset)
        .attr('cy', 0);
    }
    if (!this.yAxisScale) {
      this.yAxisScale = D3.scaleSymlog() as any;
    }

    if (!this.xAxisScale) {
      this.xAxisScale = D3.scaleUtc();

      this.xAxisGroup = this.g.append('g')
        .attr('class', 'x-axis-group')
        .attr('transform', 'translate(0, ' + (this.viewBoxHeight - 10) + ')');
      this.secondaryXAxisGroup = this.g.append('g')
        .attr('class', 'x-axis-group')
        .attr('transform', 'translate(0,20)');
    }

    const tmin = D3.min(data, d => d[this.previewBoxDataSeriesLabelsName]);
    const tmax = D3.max(data, d => d[this.previewBoxDataSeriesLabelsName]);
    this.xAxisScale
      .range([
        this.axisOffset,
        this.viewBoxWidth - this.axisOffset
      ])
      .domain([
        tmin,
        tmax
      ]);

    let ticksFilterFn = D3.utcHour.filter(d => {
      const m = dayjs(d);
      return m.hour() === 0;
    });
    let ticksFormat = 'DD-MM';
    const maxTicks = Math.floor(this.viewBoxWidth / 35);
    const dt = (tmax - tmin) / 1000 / 60 / 60;
    const hoursBetweenTicks = dt / maxTicks;
    const daysBetweenTicks = hoursBetweenTicks / 24;

    if (hoursBetweenTicks < 1) {
      ticksFormat = 'HH:mm';
      ticksFilterFn = D3.utcHour.filter(d => dayjs(d).hour() % 2 === 0);
    } else if (hoursBetweenTicks < 3) {
      ticksFormat = 'HH:mm';
      ticksFilterFn = D3.utcHour.filter(d => dayjs(d).hour() % 4 === 0);
    } else if (hoursBetweenTicks < 4) {
      ticksFilterFn = D3.utcHour.filter(d => dayjs(d).hour() % 8 === 0);
      ticksFormat = 'HH:mm';
    } else if (hoursBetweenTicks < 10) {
      ticksFilterFn = D3.utcHour.filter(d => dayjs(d).hour() % 12 === 0);
      ticksFormat = 'HH:mm';
    } else if (daysBetweenTicks < 1) {
      ticksFilterFn = D3.utcDay;
    } else if (daysBetweenTicks < 5) {
      ticksFilterFn = D3.utcDay.filter(d => d.getTime() === tmin || d.getTime() === tmax || d.getDate() === 1 || d.getDate() === 14);
    } else {
      ticksFilterFn = D3.utcDay.filter(d => d.getTime() === tmin || d.getTime() === tmax || d.getDate() === 1);
    }

    const prefilteredData = data.filter(d =>
      !isNullOrUndefined(d[this.previewBoxDataSeriesFieldName])
      && !isNaN(d[this.previewBoxDataSeriesFieldName])
    );
    let min = Math.ceil(D3.min(prefilteredData, d => d[this.previewBoxDataSeriesFieldName]));
    let max = Math.ceil(D3.max(prefilteredData, d => d[this.previewBoxDataSeriesFieldName]));
    min = isNaN(min) ? 0 : min;
    if (min === 0) {
      min = 0;
    }
    max = isNaN(max) ? 0 : max;
    if (max === 0) {
      max = 1;
    }
    if(max === min) {
      min = min - 1;
      max = max - 1;
    }
    min = Math.floor(min);
    max = Math.ceil(max);
    this.yAxisScale
      .domain([ min, max ])
      .range([this.viewBoxHeight, 22]);

    linesG.select('path.fill')
      .datum(data)
      .attr('d', D3.area()
        .curve(D3.curveBasis)
        .defined(d => !isNullOrUndefined(d[this.previewBoxDataSeriesFieldName]) && !isNaN(+d[this.previewBoxDataSeriesFieldName]))
        .x(d => this.xAxisScale(d[this.previewBoxDataSeriesLabelsName] + 30 * 60))
        .y0(this.viewBoxHeight)
        .y1(d => this.yAxisScale(isNumber(d[this.previewBoxDataSeriesFieldName]) ? d[this.previewBoxDataSeriesFieldName] : 0) - 2)
      );
    linesG.select('path.stroke')
      .datum(data)
      .attr('d', D3.line()
        .curve(D3.curveBasis)
        .defined(d => !isNullOrUndefined(d[this.previewBoxDataSeriesFieldName]) && !isNaN(+d[this.previewBoxDataSeriesFieldName]))
        .x(d => this.xAxisScale(d[this.previewBoxDataSeriesLabelsName] + 30 * 60))
        .y(d => this.yAxisScale(isNumber(d[this.previewBoxDataSeriesFieldName]) ? d[this.previewBoxDataSeriesFieldName] : 0) - 2)
      );

    linesG.select('circle.start')
      .attr('cx', this.axisOffset)
      .attr('cy', this.yAxisScale(data[0][this.previewBoxDataSeriesFieldName] ?? 0) - 2);
    linesG.select('circle.end')
      .attr('cx', this.viewBoxWidth - this.axisOffset)
      .attr('cy', this.yAxisScale(data[data.length - 1][this.previewBoxDataSeriesFieldName] ?? 0) - 2);

    const xAxis = D3.axisBottom(this.xAxisScale)
      .tickSize(0)
      .tickPadding(0)
      .ticks(ticksFilterFn)
      .tickFormat((val, ind) => dayjs(+val).tz().format(ticksFormat));

    const xAxis2 = D3.axisTop(this.xAxisScale)
      .tickSize(0)
      .tickPadding(0)
      .tickValues([tmin, tmax])
      .tickFormat((val, ind) => dayjs(+val).tz().format('DD-MM-YYYY'));

    this.secondaryXAxisGroup.call(xAxis2);
    this.xAxisGroup.call(xAxis);
  }

  private updateSelections() {
    if (this.xAxisScale && this.g) {
      let lineGroup = this.g.select('g.selected-date-line');
      let rangeGroup = this.g.select('g.range-group');
      let rangeLabelRight = rangeGroup.select('g.range-label-right');
      let rangeLabelLeft = rangeGroup.select('g.range-label-left');
      const rangeH = this.viewBoxHeight - 20;

      if (lineGroup.empty()) {
        rangeGroup = this.g.append('g')
          .attr('class', 'range-group');
        rangeGroup.append('rect')
          .attr('class', 'range-rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', 0)
          .attr('height', rangeH)
          .style('filter', 'url(#drop-shadow-rtb)');
        rangeLabelRight = rangeGroup.append('g')
          .attr('class', 'range-label-right');
        rangeLabelRight.append('polyline')
          .style('filter', 'url(#drop-shadow-rtb)');
        rangeLabelRight.append('text')
          .style('filter', 'url(#drop-shadow-rtb)')
          .attr('y', 0.9 * rangeH - 5);

        rangeLabelLeft = rangeGroup.append('g')
          .attr('class', 'range-label-left');
        rangeLabelLeft.append('polyline')
          .style('filter', 'url(#drop-shadow-rtb)');
        rangeLabelLeft.append('text')
          .style('filter', 'url(#drop-shadow-rtb)')
          .attr('y', 0.1 * rangeH + 7);

        lineGroup = this.g.append('g')
          .attr('class', 'selected-date-line');
        lineGroup.append('line')
          .attr('x1', 0)
          .attr('x2', 0)
          .attr('y1', 20)
          .attr('y2', this.viewBoxHeight);
      }

      if (this.selectedDate) {
        const sel = this.xAxisScale(this.selectedDate.valueOf());
        lineGroup.attr('transform', 'translate(' + sel + ', 0)');
        lineGroup.style('opacity', 1);
      } else {
        lineGroup.style('opacity', 0);
      }

      const from = this.xAxisScale(this.resultView.displayFrom.valueOf());
      const to = this.xAxisScale(this.resultView.displayTo.valueOf());
      const w = to - from;
      rangeGroup.attr('transform', 'translate(' + from + ', 19)');
      rangeGroup.select('rect')
        .attr('width', w);

      rangeLabelLeft.select('text')
        .text(this.resultView.displayFrom.tz().format(this.resultView.format));
      rangeLabelRight.select('text')
        .text(this.resultView.displayTo.add(1, 'minute').tz().format(this.resultView.format));

      if (from > 120) {
        rangeLabelLeft.attr('transform', 'translate(-120,0)');
        rangeLabelLeft.select('polyline')
          .attr('points', [
            '0,' + (0.1 * rangeH),
            (120 - 0.4 * rangeH) + ',' + (0.1 * rangeH),
            '120,' + (rangeH / 2)
          ].join(' '));
        rangeLabelLeft.select('text')
          .attr('x', 50);
      } else {
        rangeLabelLeft.attr('transform', 'translate(0,0)');
        rangeLabelLeft.select('polyline')
          .attr('points', [
            '0,' + (rangeH / 2),
            (0.4 * rangeH) + ',' + (0.1 * rangeH),
            '120,' + (0.1 * rangeH)
          ].join(' '));
        rangeLabelLeft.select('text')
          .attr('x', 70);
      }

      if (this.viewBoxWidth - this.axisOffset - to > 120) {
        rangeLabelRight.attr('transform', 'translate(' + w + ',0)');
        rangeLabelRight.select('polyline')
          .attr('points', [
            '0,' + (rangeH / 2),
            (0.4 * rangeH) + ',' + (0.9 * rangeH),
            '120,' + (0.9 * rangeH)
          ].join(' '));
        rangeLabelRight.select('text')
          .attr('x', 70);
      } else {
        rangeLabelRight.attr('transform', 'translate(' + (w - 120) + ',0)');
        rangeLabelRight.select('polyline')
        .attr('points', [
          '120,' + (rangeH / 2),
          (120 - 0.4 * rangeH) + ',' + (0.9 * rangeH),
          '0,' + (0.9 * rangeH)
        ].join(' '));
        rangeLabelRight.select('text')
          .attr('x', 50);
      }
    }
  }

  private updateDragRanges(argx: [boolean, number?, number?]) {

    if (this.xAxisScale && this.g) {
      let dataRangeGroup = this.g.select('g.update-data-range-group');
      let rangeGroup = dataRangeGroup.select('g.range-group');
      let rangeLabelRight = rangeGroup.select('g.range-label-right');
      let rangeLabelLeft = rangeGroup.select('g.range-label-left');
      const rangeH = this.viewBoxHeight - 20;

      if (dataRangeGroup.empty()) {
        dataRangeGroup = this.g.append('g')
          .attr('class', 'update-data-range-group');
        dataRangeGroup.append('line')
          .attr('class', 'update-data-range-group-hover')
          .attr('x1', 0)
          .attr('x2', 0)
          .attr('y1', 20)
          .attr('y2', this.viewBoxHeight);

        rangeGroup = dataRangeGroup.append('g')
          .attr('class', 'range-group');
        rangeGroup.append('rect')
          .attr('class', 'range-rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', 0)
          .attr('height', rangeH)
          .style('filter', 'url(#drop-shadow-rtb)');
        rangeLabelRight = rangeGroup.append('g')
          .attr('class', 'range-label-right');
        rangeLabelRight.append('polyline')
          .style('filter', 'url(#drop-shadow-rtb)');
        rangeLabelRight.append('text')
          .style('filter', 'url(#drop-shadow-rtb)')
          .attr('y', 0.9 * rangeH - 5);

        rangeLabelLeft = rangeGroup.append('g')
          .attr('class', 'range-label-left');
        rangeLabelLeft.append('polyline')
          .style('filter', 'url(#drop-shadow-rtb)');
        rangeLabelLeft.append('text')
          .style('filter', 'url(#drop-shadow-rtb)')
          .attr('y', 0.1 * rangeH + 7);
      }

      const dataRangeHover = dataRangeGroup.select('.update-data-range-group-hover');
      let isDrag;
      isDrag = false;
      let from;
      let to;
      [isDrag, from, to] = argx;
      if (isDrag && !isNaN(from) && !isNaN(to)) {
        this.eventsRect.style('cursor', 'zoom-in');
        dataRangeHover.style('opacity', 0);
        rangeGroup.style('opacity', 1);

        if (from > to) {
          const c = from;
          from = to;
          to = c;
        }

        const w = to - from;
        rangeGroup.attr('transform', 'translate(' + from + ', 19)');
        rangeGroup.select('rect')
          .attr('width', w);

        const fromDate = dayjs(this.xAxisScale.invert(from)).startOf('day');
        const toDate = dayjs(this.xAxisScale.invert(to)).endOf('day');

        rangeLabelLeft.select('text')
          .text( fromDate.tz().format(this.resultView.format));
        rangeLabelRight.select('text')
          .text( toDate.add(1, 'minute').tz().format(this.resultView.format));

        if (from > 120) {
          rangeLabelLeft.attr('transform', 'translate(-120,0)');
          rangeLabelLeft.select('polyline')
            .attr('points', [
              '0,' + (rangeH / 2),
              '0,' + (0.1 * rangeH),
              (120 - 0.4 * rangeH) + ',' + (0.1 * rangeH),
              '120,' + (rangeH / 2)
            ].join(' '));
          rangeLabelLeft.select('text')
            .attr('x', 50);
        } else {
          rangeLabelLeft.attr('transform', 'translate(0,0)');
          rangeLabelLeft.select('polyline')
            .attr('points', [
              '0,' + (rangeH / 2),
              (0.4 * rangeH) + ',' + (0.1 * rangeH),
              '120,' + (0.1 * rangeH),
              '120,' + (rangeH / 2)
            ].join(' '));
          rangeLabelLeft.select('text')
            .attr('x', 70);
        }

        if (this.viewBoxWidth - this.axisOffset - to > 120) {
          rangeLabelRight.attr('transform', 'translate(' + w + ',0)');
          rangeLabelRight.select('polyline')
            .attr('points', [
              '0,' + (rangeH / 2),
              (0.4 * rangeH) + ',' + (0.9 * rangeH),
              '120,' + (0.9 * rangeH),
              '120,' + (rangeH / 2)
            ].join(' '));
          rangeLabelRight.select('text')
            .attr('x', 70);
        } else {
          rangeLabelRight.attr('transform', 'translate(' + (w - 120) + ',0)');
          rangeLabelRight.select('polyline')
          .attr('points', [
            '120,' + (rangeH / 2),
            (120 - 0.4 * rangeH) + ',' + (0.9 * rangeH),
            '0,' + (0.9 * rangeH),
            '0,' + (rangeH / 2)
          ].join(' '));
          rangeLabelRight.select('text')
            .attr('x', 50);
        }
      } else {
        rangeGroup.style('opacity', 0);
        this.eventsRect.style('cursor', 'crosshair');
        if (to > 0) {
          dataRangeHover.style('opacity', 1);
          dataRangeHover.attr('x1', to);
          dataRangeHover.attr('x2', to);
        } else {
          dataRangeHover.style('opacity', 0);
          dataRangeHover.attr('x1', 0);
          dataRangeHover.attr('x2', 0);
        }
      }
    }
  }
}
