import { WorkingArea } from './working-area.model';
import { PumpRoom } from './pump-room.model';
import { AbstractDevice } from './abstract-device.model';
import { MeteoDevice } from './meteo-device.interface';
import { Schedule } from './schedule.model';
import { SnowCannon } from './snow-cannon.model';
import { ConnectionPoint } from './connection-point.model';
import { WaterPipeline } from './water-pipeline.model';
import { CompressorRoom } from './compressor-room.model';
import { WaterReservoir } from './water-reservoir.model';
import { PowerSource } from './power-source.model';
import { ElectricalLine } from './electrical-line.model';
import { ConvertUnit, DeviceSystemType } from '../../../../../common';

export class ResortArea extends AbstractDevice {
  systemType: DeviceSystemType = 'ResortArea';

  isModeDropdownExpanded?: boolean;
  temporaryChange?: any;

  symbol: string;
  mapBoundaries: any[];
  mapBearing: number;
  scheduleSchema: string;
  remoteMeteoStation: string;

  priority: number;

  snowQualityType: number;
  snowQualityConstant: number;
  snowQualityLow: number;
  snowQualityHigh: number;

  @ConvertUnit('production', 'm3/h')
  snowProductionVolume: number;
  @ConvertUnit('waterFlow', 'l/s')
  computedWaterFlow: number;
  @ConvertUnit('powerDraw', 'kW')
  computedPowerDraw: number;
  computedAirFlow: number;
  limitSnowProductionVolume: number;
  limitWaterConsumption: number;
  limitPowerConsumption: number;
  limitWindSpeed: number;
  airHumidity: number;
  airTemperature: number;
  wetBulbTemperature: number;

  isLocked = false;

  extraParameters: any[];

  scheduleSchemaRef?: Schedule;
  remoteMeteoStationRef?: MeteoDevice;

  workingAreasRefs: Map<string, WorkingArea> = new Map();

  snowCannonRefs: Map<string, SnowCannon> = new Map();
  conenctionPointRefs: Map<string, ConnectionPoint> = new Map();

  pumpRoomRefs: Map<string, PumpRoom> = new Map();
  waterPipelineRefs: Map<string, WaterPipeline> = new Map();
  waterReservoirRefs: Map<string, WaterReservoir> = new Map();
  compressorRoomRefs: Map<string, CompressorRoom> = new Map();
  powerSourceRefs: Map<string, PowerSource> = new Map();
  electricalLineRefs?: Map<string, ElectricalLine> = new Map();

  getSnowCannons(): SnowCannon[] {
    return Array.from(this.snowCannonRefs.values());
  }
  getConnectionPoints(): ConnectionPoint[] {
    return Array.from(this.conenctionPointRefs.values());
  }
  getWorkingAreas(): WorkingArea[] {
    return Array.from(this.workingAreasRefs.values());
  }
  getPumpRooms(): PumpRoom[] {
    return Array.from(this.pumpRoomRefs.values());
  }
  getWaterPipelines(): WaterPipeline[] {
    return Array.from(this.waterPipelineRefs.values());
  }
  getWaterReservoirs(): WaterReservoir[] {
    return Array.from(this.waterReservoirRefs.values());
  }
  getCompressorRooms(): CompressorRoom[] {
    return Array.from(this.compressorRoomRefs.values());
  }
  getPowerSources(): PowerSource[] {
    return Array.from(this.powerSourceRefs.values());
  }

  countBySnowQuality(): number[] {
    const resp: number[] = [0, 0, 0, 0, 0, 0, 0];

    this.getSnowCannons().filter(sc => sc.connectionStatus).forEach(sc => {
      if (sc.snowQuality > 0 && sc.snowQuality <= 7) {
        resp[sc.snowQuality - 1]++;
      }
    });

    return resp;
  }

  getStartTemperatureRange(): { min: number; max: number } {
    let min = Infinity;
    let max = -Infinity;
    this.getSnowCannons().filter(sc => sc.connectionStatus && sc.CAP_CHANGE_START_TEMPERATURE).forEach(sc => {
      min = Math.min(min, sc.startTemperature);
      max = Math.max(max, sc.startTemperature);
    });
    if (min === Infinity && max === -Infinity) {
      min = 0;
      max = 0;
    }
    min = 0.1 * Math.round(10 * min);
    max = 0.1 * Math.round(10 * max);

    return { min, max };
  }

  isAnyCannonStarted(): boolean {
    return this.getSnowCannons().filter(sc => sc.connectionStatus).findIndex(sc =>
      sc.operationMode === 0 && sc.commandStatusCannonStart) >= 0;
  }

  countByPreparing(): number {
    return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'preparing-to-work').length;
  }

  countByStandingBy(): number {
    return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'standing-by').length;
  }

  countBySnoozing(): number {
    return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'snoozing').length;
  }

  countByBreakdown(): number {
    return this.getSnowCannons().filter(e => e.computedStatus === 'breakdown' || e.computedStatus === 'connection-lost').length;
  }

  countByAwaitingForConditions(): number {
    return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'awaiting-for-conditions').length;
  }

  countByReadyToWork(): number {
    return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'ready-to-work').length;
  }

  countByStarting(): number {
    return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'starting').length;
  }

  countByWorking(): number {
    return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'working').length;
  }

  countByWorkingInManualMode(): number {
    return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'working-in-manual-mode').length;
  }

  countByStopping(): number {
    return this.getSnowCannons().filter(sc => sc.connectionStatus).filter(e => e.computedStatus === 'stopping').length;
  }
}
