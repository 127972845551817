import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { SnowCannon } from 'src/app/shared/models';
import { AuthService } from '../../../../../auth.service';

@Component({
  selector: 'ss-snow-cannon-position-editor-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.sass']
})
export class SnowCannonPositionEditorManualComponent implements OnInit, OnDestroy {

  @Input()
  public snowCannon: SnowCannon;

  @Output()
  public executeCommand: EventEmitter<any> = new EventEmitter();

  constructor(private as: AuthService) { }

  manualPositioningTimer: any = null;
  manualPositioningCommand: string = null;

  manualPositioningStart(command: string) {
    if(!this.as.isAuthorized('ROLE_OPERATOR')) {
      return;
    }
    this.manualPositioningCommand = command;
  }

  manualPositioningStop() {
    this.manualPositioningCommand = null;
  }

  ngOnInit() {
    clearInterval(this.manualPositioningTimer);
    this.manualPositioningTimer = setInterval(() => {
      if (this.manualPositioningCommand) {
        const req = {};
        req[this.manualPositioningCommand] = true;
        this.executeCommand.emit(req);
      }
    }, 1000);
  }

  ngOnDestroy() {
    this.manualPositioningCommand = null;
    clearInterval(this.manualPositioningTimer);
  }
}
