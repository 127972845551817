<div class="outbox">

  <div class="center-col hidden" [ngClass]="{'hidden': !isLoginScreenVisible }">
    <div class="center-col-header">
      <svg class="snowmatic-logo">
        <use xlink:href='#snowmatic-logo-horizontal'/>
      </svg>

      <svg class="supersnow-logo">
        <use xlink:href='#supersnow-logo'/>
      </svg>

      <ul>
        <li [ngClass]="{'selected': locale === 'pl' }">
          <a href="/pl">pl</a>
        </li>
        <li [ngClass]="{'selected': locale === 'en' }">
          <a href="/en">en</a>
        </li>
        <li [ngClass]="{'selected': locale === 'de' }">
          <a href="/de">de</a>
        </li>
<!--
        <li [ngClass]="{'selected': locale == 'sk' }">
          <a href="/sk">sk</a>
        </li>
        <li [ngClass]="{'selected': locale == 'cz' }">
          <a href="/cz">cz</a>
        </li>
-->

      </ul>

      <h3 *ngIf="resortName">
        <span>{{ resortName }}</span>
      </h3>
    </div>

    <div class="center-col-form" [ngClass]="{'center-col-form--user-selected': isLoginSelected}">
      <div class="center-col-form--cropper">

      <perfect-scrollbar>

      <div class="center-col-form--avatar-space">
        <div *ngFor="let u of users" class="center-col-form--user-box" (click)="selectUser(u, $event)">
          <ss-user-avatar size="big" [user]="u"></ss-user-avatar>
          <div>{{ u.name }}</div>
          <div><strong>{{ u.surname }}</strong></div>
          <div><small>{{ u.email }}</small></div>
        </div>
        <div class="center-col-form--avatar-space--btn-tile">
          <a href="#" class="btn btn-link" (click)="selectUser(null, $event)">
            <ng-container i18n="Zaloguj jako inny uzytkownik|Opis przycisku zaloguj sięjako inny użytkownik@@startScreenLoginDifferentUser">Zaloguj jako inny uzytkownik</ng-container>
            <svg class="ml-2">
              <use xlink:href='#icon-lock'/>
            </svg>
          </a>
          <a href="#" class="btn btn-link" *ngIf="allowAzureOauthLogin" (click)="startOAuthFlow($event)">
            <ng-container i18n="Zaloguj loginem SuperSnow|Opis przycisku zaloguj się loginem Supersnow@@startScreenLoginAzureOauth">Zaloguj loginem SuperSnow</ng-container>
            <svg class="ml-2">
              <use xlink:href='#icon-windows-login'/>
            </svg>
          </a>
        </div>
      </div>

      <div class="center-col-form--login-space">
        <h1 i18n="Login|Tytuł na forlumarzu logowania się@@startScreenLoginHeader">Login</h1>
        <p [ngClass]="{'is-error-shown': isError}">
          <ng-container *ngIf="errorCode !== 403 && errorCode !== 406" i18n="Nieprawidłowy login lub hasło|Informacja nad formularzem logowania się w przypadku niepoprawnych danych użytkownika@@startScreenLoginInvalidCredentials">Nieprawidłowy login lub hasło</ng-container>
          <ng-container *ngIf="errorCode === 403" i18n="Ważność konta wygasła|Informacja nad formularzem logowania się w przypadku niepoprawnych danych użytkownika@@startScreenLoginAccountExpired">Ważność konta wygasła</ng-container>
          <ng-container *ngIf="errorCode === 406" i18n="To konto nie pozwala na dostęp przez internet|Informacja nad formularzem logowania się w przypadku niepoprawnych danych użytkownika@@startScreenLoginRestrictedToLocalIp">To konto nie pozwala na dostęp przez internet</ng-container>
        </p>
        <form (submit)="tryLogin()">
          <input #emailInputRef [disabled]="isInProgress" name="emailInput" type="text" i18n-placeholder="Adres e-mail|Placeholder loginu w formularzu logowania się@@startScreenLoginInputEmail" placeholder="Adres e-mail" [(ngModel)]="email" class="input"/>
          <input [disabled]="isInProgress" name="emailPassword" type="password" i18n-placeholder="Hasło|Placeholder hasła w formularzu logowania się@@startScreenLoginInputPassword" placeholder="Hasło" [(ngModel)]="password" class="input"/>

          <div class="d-flex justify-content-end">
            <button *ngIf="users && users.length > 0" (click)="isLoginSelected = false; email = '';" class="btn btn-link" type="button">Cancel</button>
            <button [disabled]="isInProgress" class="btn btn-submit" type="submit" i18n="Zaloguj się|Opis przycisku zaloguj się@@startScreenLoginSubmit">Zaloguj się</button>
          </div>
        </form>
      </div>

</perfect-scrollbar>

      </div>
    </div>
  </div>

  <div class="staged-preload hidden" [ngClass]="{'hidden': !isStagedPreloadInProgress && !isValidatingSessionStorege }">
    <div  [ngClass]="{'hidden': !isValidatingSessionStorege}"
      i18n="Trwa wznawianie sesji użytkownika|Opis wyświetlany podczas łądowania danych ostatniego zalogowanego użytkownika@@startScreenRetrveLastLoginInfo"
      class="text">Trwa wznawianie sesji użytkownika</div>
    <div class="text" [ngClass]="{'hidden': !isStagedPreloadInProgress}">
      <span *ngIf="user">
        <ng-container i18n="Witaj|Opis wyświetlany przed loginem użytkownika podczas ładowania danych stoku@@startScreenProgressWelcome">Witaj</ng-container>
        <br />
        {{ user.name }} {{ user.surname }}!
        <br />
      </span>
      <strong>{{ progress|number:'0.0-0' }}%</strong>
      <br />
      <ng-container i18n="Trwa ładowanie elementów stoku|Opis wyświetlany podczas łądowania danych stoku@@startScreenProgressInfo">Trwa ładowanie elementów stoku</ng-container>
    </div>
    <div class="spinner"></div>
  </div>

  <p class="version">{{ version }}</p>
</div>
