import { Device } from '../../../../../common';

export class Alert {
    deviceId: string;
    id: number;
    alertType: string;
    alertLevel: number;
    alertDescription: string;
    occurred: string;
    lastOccurred: string;
    expired: string;
    isAllowedToBeConfirmed: boolean;
    confirmed: string;

    deviceReference: Device;

    public static create(data: any): Alert {
      return Object.assign(new Alert(), data);
    }

    public get level(): string {
      if (this.alertLevel > 1) {
        return 'notification';
      } else if (this.alertLevel === 1) {
        return 'warning';
      }
      return 'alert';
    }

    public isEqual(other: Alert): boolean {
      return this.deviceId === other.deviceId
        && this.id === other.id
        && this.alertType === other.alertType
        && this.alertLevel === other.alertLevel
        && this.alertDescription === other.alertDescription
        && this.occurred === other.occurred
        && this.lastOccurred === other.lastOccurred
        && this.expired === other.expired
        && this.isAllowedToBeConfirmed === other.isAllowedToBeConfirmed
        && this.confirmed === other.confirmed;
    }
}
