import { Component, Inject, OnInit, LOCALE_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { environment } from '../environments/environment';
import { DOCUMENT } from '@angular/common';
import { SocketioClientService } from './http-clients/socketio.client.service';

@Component({
  selector: 'ss-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})

export class AppComponent implements OnInit {

  showWindowButtons = false;

  isRoutingDone = false;
  isRoutingInProgress = false;

  timeoutHandler: any;

  isDisconnected = false;

  windowTitle: string = 'Snowmatic [' + environment.version + ']';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private socketIoClient: SocketioClientService,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle(this.windowTitle);
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.cancel(60000);
        this.isRoutingDone = false;
        this.isRoutingInProgress = true;
      }
      if (
        val instanceof NavigationEnd
        || val instanceof NavigationCancel
        || val instanceof NavigationError
      ) {
        this.isRoutingDone = true;
        this.isRoutingInProgress = false;

        this.cancel(1000);
      }
    });

    this.socketIoClient.disconnected.subscribe((val) => {
      if (this.isDisconnected !== val) {
        this.isDisconnected = val;
      }
    });
  }
  restartApp() {
    window.location.assign('/');
  }
  cancel(dura) {
    clearTimeout(this.timeoutHandler);
    this.timeoutHandler = setTimeout(() => {
      this.isRoutingInProgress = false; this.isRoutingDone = false;
    }, dura);
  }

  ngOnInit() {
    this.document.body.classList.remove('preloader-visible');

    if ((navigator as any).standalone || window.matchMedia('(display-mode: standalone)').matches) {
      if (this.document.documentElement.requestFullscreen) {
        this.document.documentElement.requestFullscreen({ navigationUI: 'hide' });
      }
      this.showWindowButtons = true;
    }
  }

  closeWindow() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    }
    window.close();
  }

  toggleFullscreen() {
    if (!!this.document.fullscreenElement) {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      }
    } else {
      if (this.document.documentElement.requestFullscreen) {
        this.document.documentElement.requestFullscreen({ navigationUI: 'hide' });
      }
    }
  }
}
