import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigureClientService } from '../../../../http-clients/configure.client.service';
import { ResortService } from '../../../../resort.service';
import { ElectricalLine } from '../../../../shared/models';
import { ModalRouteService } from '../../../modal-route.service';

@Component({
  selector: 'ss-configure-electrical-line-modal',
  templateUrl: './configure-electrical-line-modal.component.html',
  styleUrls: ['./configure-electrical-line-modal.component.sass']
})
export class ConfigureElectricalLineModalComponent implements OnInit {

  constructor(
    private rs: ResortService,
    private cs: ConfigureClientService,
    private ar: ActivatedRoute,
    private mr: ModalRouteService
  ) { }

  public electricalLine: ElectricalLine;
  public limitPowerDraw: number;
  public isLimitPowerDrawEnabled: boolean;

  ngOnInit(): void {
    this.ar.paramMap.subscribe(params => {
      const id = params.get('electricalLineId');

      this.electricalLine = this.rs.getElectricalLine(id);

      if (!this.electricalLine) {
        this.mr.closeModal();
        return;
      }

      this.limitPowerDraw = this.electricalLine.limitPowerDraw;
      this.isLimitPowerDrawEnabled = this.electricalLine.limitPowerDraw > 0;
    });

  }

  save() {
    this.cs.configureElectricalLine(this.electricalLine, {
      limitPowerDraw: this.isLimitPowerDrawEnabled ? this.limitPowerDraw : 0
    }).subscribe(() => {
      this.close();
    });
  }

  close() {
    this.mr.closeModal();
  }
}
