<ul class="tabs-nav">
  <ng-container *ngFor="let tab of tabs; let last = last">
    <li *ngIf="tab.position === 'right'" class="spacer"></li>
    <li [hidden]="tab.hidden"
      [ngClass]="{
        'is-active': tab.active,
        'is-right': tab.position === 'right'
      }"
      (click)="selectNamedTabOrFirstTab(tab.tabId)"
      class="item">
      <ss-badge *ngIf="tab.alerts" color="danger" size="small">{{ tab.alerts }}</ss-badge>
      <ss-badge *ngIf="tab.warnings" color="warning" size="small">{{ tab.warnings }}</ss-badge>
      <ss-badge *ngIf="tab.notifications" color="info" size="small">{{ tab.notifications }}</ss-badge>
      <ss-badge *ngIf="tab.noLevelInfos" size="small">{{ tab.noLevelInfos }}</ss-badge>
      {{ tab.tabTitle }}
    </li>
    <li *ngIf="last && tab.position !== 'right'" class="spacer"></li>
  </ng-container>
</ul>
<div class="tabs-container" [ngClass]="{
  'show-overflow': showOverflow
}">
  <ng-content></ng-content>
</div>
