import {
  ReportAggrAvgPartView,
  ReportAggrDiffPartView,
  ReportAggrFirstPartView,
  ReportAggrLastPartView,
  ReportAggrMaxPartView,
  ReportAggrMinPartView,
  ReportAggrSumPartView,
  ReportChartAvgPartView,
  ReportChartPartView,
  ReportChartSumPartView,
  subjectFieldMapping
} from '.';
import {
  DAYJS,
  TimedChartConfig,
  TimedChartData,
  translateDeviceTypeAndSymbol,
  translateReportFieldName,
  translateReportPartType,
  UnitType
} from '../..';
import { ReportPart } from './part';
import { ReportResultView } from './result-view';

export abstract class ReportPartView { // make abstract

  public autoLabel = '';

  constructor(part: ReportPart) {
    this.part = part;
  }

  public static create(part: ReportPart): ReportPartView {
    switch(part.partType) {
      case 'chart':
        return new ReportChartPartView(part);
      case 'avg-chart':
        return new ReportChartAvgPartView(part);
      case 'sum-chart':
        return new ReportChartSumPartView(part);
      case 'avg':
        return new ReportAggrAvgPartView(part);
      case 'sum':
        return new ReportAggrSumPartView(part);
      case 'min':
        return new ReportAggrMinPartView(part);
      case 'max':
        return new ReportAggrMaxPartView(part);
      case 'first':
        return new ReportAggrFirstPartView(part);
      case 'last':
        return new ReportAggrLastPartView(part);
      case 'diff':
        return new ReportAggrDiffPartView(part);
      default:
        throw new Error('unknown part type ' + part.partType);
    }
  }

  public part: ReportPart;

  public scalarData: { value: number; valueConverted: number; valueUnit: UnitType };

  public chartData: TimedChartData;
  public chartConfig: TimedChartConfig;

  protected getDataByIds(resultView: ReportResultView): any {
    const idFieldMap: Map<string, string[]> = new Map();
    this.part.series.forEach(s => {
      if(!idFieldMap.has(s.subjectId)) {
        idFieldMap.set(s.subjectId, []);
      }
      idFieldMap.get(s.subjectId).push(s.subjectField);
    });

    const dataById = {};
    idFieldMap.forEach((fields, id) => {
      dataById[id] = resultView.data.getData(id, ...fields);
    });

    return dataById;
  }

  protected calculateAutoLabel(resultView: ReportResultView) {
    const onlyOneDeviceType = [
      ... new Set(
        resultView.parts.reduce((previous: string[], part) => [
            ...previous,
            ...part.series.reduce(
              (prev: string[], series) => [series.subjectType, ...prev], []
            )
            .filter(id => !!id)
          ], [])
      )
    ].length <= 1;

    const deviceNames: string[] = [
      ... new Set(
        this.part.series.reduce((prev: string[], series) => resultView.devicesInParts
            && resultView.devicesInParts[series.subjectId]
            ? [series.subjectId, ...prev]
            : prev, []).filter(id => !!id)
      )
    ].map(id => translateDeviceTypeAndSymbol(resultView.devicesInParts[id], onlyOneDeviceType));

    const fieldNames: string[] = [
      ... new Set(
        this.part.series.reduce((prev: string[], series) => {
          const f = subjectFieldMapping[series.subjectType]
            && subjectFieldMapping[series.subjectType].fields[series.subjectField]
            ? translateReportFieldName(series.subjectField)
            : undefined;
          return f ? [f, ...prev] : prev;
        }, []).filter(fieldName => !!fieldName)
      )
    ];

    let autoLabel = translateReportPartType(this.part.partType);

    if(fieldNames.length > 0) {
      autoLabel = autoLabel + ' ' + fieldNames.join(', ');
    }
    if(!onlyOneDeviceType || deviceNames.length > 1) {
      autoLabel = autoLabel + ': ' + deviceNames.join(', ');
    }

    this.autoLabel = autoLabel;
  }

  public abstract calculate(resultView: ReportResultView, from?: DAYJS, to?: DAYJS);
}
