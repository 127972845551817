<div class="tabs-content">

  <div class="row">

    <ng-container *ssdHideIfNotGranted="'ROLE_OBSERVER';requiresCapability:'hasPumprooms'">
      <div class="col white-space-nowrap tabs-content__box px-3 pb-3 pt-1" *ngFor="let pumpRoom of pumpRooms">
        <p class="window-overview__header py-1">
          <ng-container i18n="Pompownia|Etykieta pompowni@@pumpRoomLabel">Pompownia</ng-container>:
          <span [routerLink]="['/application/pump-rooms/detail', pumpRoom.id]"
            class="color-light-blue fs-12 clickable">
            {{ pumpRoom.symbol }}
          </span>
        </p>
        <div class="indicator my-1">
          <p class="mr-1" *ngIf="pumpRoom.waterPipelineRefs.size === 1">
            <ss-pump-room-detail-status [status]="pumpRoom.getWaterPipelines()[0].computedStatus"></ss-pump-room-detail-status>
          </p>
          <p class="mr-1" *ngIf="pumpRoom.waterPipelineRefs.size > 1">
            <ss-pump-room-detail-multi-status [hideDescriptions]="true" [components]="pumpRoom.getWaterPipelines()">
              <ss-pump-room-detail-status status="working" class="first-compacted"></ss-pump-room-detail-status>
              <ss-pump-room-detail-status status="filling" [optional]="true"></ss-pump-room-detail-status>
              <ss-pump-room-detail-status status="snoozing"></ss-pump-room-detail-status>
              <ss-pump-room-detail-status status="ready-to-work"></ss-pump-room-detail-status>
              <ss-pump-room-detail-status status="standing-by" class="last-compacted"></ss-pump-room-detail-status>
              <ss-pump-room-detail-status status="breakdown" [standalone]="true"></ss-pump-room-detail-status>
              <ss-pump-room-detail-status status="connection-lost" [standalone]="true"></ss-pump-room-detail-status>
            </ss-pump-room-detail-multi-status>
          </p>
          <svg class="indicator__icon indicator__icon--l">
            <use xlink:href='#icon-waves' />
          </svg>
          <p>
            <span class="indicator__value pl-1">
              {{ pumpRoom|convertedValue:'computedWaterFlow' }}
            </span>
            <span class="indicator__unit pl-1">
              {{ pumpRoom|convertedUnit:'computedWaterFlow' }}
            </span>
          </p>
          <p class="ml-2">
            <span class="indicator__value pl-1">{{ (pumpRoom.computedWaterPressure || 0)|number:'1.1-1' }}</span>
            <span class="indicator__unit pl-1">bar</span>
          </p>
        </div>
      </div>
    </ng-container>

    <div class="col tabs-content__box px-3 pb-3 pt-1">
      <p class="window-overview__header pt-1"
        i18n="Zużycie energii|Nazwa pola przedstawiającego wielkość zużycia energii@@energyConsumption">Zużycie energii</p>
      <div class="indicator mt-3">
        <svg class="indicator__icon">
          <use xlink:href='#icon-thunder' />
        </svg>
        <p>
          <span
            class="indicator__value pl-1">{{ (snowCannon.currrentPowerConsumption || 0)|number:'1.2-2' }}</span>
          <span class="indicator__unit pl-1">kW</span>
        </p>
      </div>
    </div>

    <div class="col tabs-content__box px-3 pb-3 pt-1" style="display:none">
      <p class="window-overview__header pt-1"
        i18n="Kompresorownia|Etykieta kompresorowni@@compressorRoomLabel">Kompresorownia</p>
      <div class="indicator mt-3">
        <svg class="indicator__icon indicator__icon--l">
          <use xlink:href='#icon-air' />
        </svg>
        <p>
          <span class="indicator__value pl-1">{{ (snowCannon.currentAirFlow || 0)|number:'1.0-0' }}</span>
          <span class="indicator__unit pl-1">m<sup>3</sup>/h</span>
        </p>
      </div>
    </div>


    <div class="col tabs-content__box px-3 pb-3 pt-1" *ngIf="snowCannon.CAP_CAN_SHOW_OVERALL_WORK_TIME">
      <p class="window-overview__header pt-1"
      i18n="Całkowity czas pracy|Opi pola całkowity czas pracy w zakładce statystyki widoku armatki@@snowCannonStatsOveralWorkTime">
          Całkowity czas pracy</p>
      <div class="indicator mt-3">
        <svg class="indicator__icon indicator__icon--l">
          <use xlink:href='#icon-time' />
        </svg>
        <p>
          <span class="indicator__value pl-1">{{ (snowCannon.cannonOperationTime || 0)|number:'1.0-0' }}</span>
          <span class="indicator__unit pl-1">h</span>
        </p>
      </div>
    </div>

  </div>
</div>
<div class="tabs-content" *ngIf="snowCannon.isGpsEnabled">
  <div class="p-3">
    <ng-container *ngIf="snowCannon.isGpsEnabled">
      <p class="window-overview__header"
        i18n="Informacje GPS|Nagłówek pola z informacjami GPS@@snowCannonGPSTitle">Informacje GPS</p>
      <div class="row">
        <div class="col">
          <ng-container *ngIf="snowCannon.gpsStatus">
            <p class="window-overview__text">
              <ng-container i18n="Zsynchronizowano z|Informacje GPS na panelu armatki@@snowCannonGPSSyncInfo1">Zsynchronizowano z</ng-container>
              {{ snowCannon.satellites || "0" }}
              <ng-container i18n="satelitami ze statusem|Informacje GPS na panelu armatki@@snowCannonGPSSyncInfo2">satelitami ze statusem</ng-container>
              {{ snowCannon.gpsStatus || "0" }}.
              <ng-container i18n="Dane pobrano|Informacje GPS na panelu armatki@@snowCannonGPSDataWasGathered">Dane pobrano</ng-container>
              {{ snowCannon.gpsTime ? (snowCannon.gpsTime | date:'yyyy-MM-dd HH:mm:ss') : '--' }}.<br />
              [{{ snowCannon.latitude || '--' }},{{ snowCannon.longitude || '--' }}]
              {{ (snowCannon.altitude || 0) |number:'1.0-0' }}m
            </p>
          </ng-container>
          <ng-container *ngIf="!snowCannon.gpsStatus">
            <p class="window-overview__text">
              <ng-container i18n="Brak namiaru GPS|Informacje GPS na panelu armatki@@snowCannonGPSNoStatus">Brak namiaru GPS</ng-container>
              <ng-container *ngIf="snowCannon.gpsTime">
                <ng-container i18n="Ostatnia próba pobrania namiaru GPS|Informacje GPS na panelu armatki@@snowCannonGPSLastStatusAttempt">Ostatnia próba pobrania namiaru GPS</ng-container>
                {{ snowCannon.gpsTime ? (snowCannon.gpsTime | date:'yyyy-MM-dd HH:mm:ss') : '--' }}
              </ng-container>
            </p>
          </ng-container>
        </div>
        <div class="col-auto">
          <button class="btn btn-primary" (click)="toggleHistoryModal()"
            i18n="Pokaż raport pozycjonowania|Informacje GPS na panelu armatki@@snowCannonGPSShowReport">Pokaż raport pozycjonowania</button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
