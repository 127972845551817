export class ChartData {

    aggregatedLabels: string[];
    wetBulbTemperature?: number[];
    waterTemperature?: number[];
    snowProductionVolume?: number[];
    waterPressure?: number[];
    waterFlow?: number[];
    meteredWaterConsumption?: number[];
    powerConsumption?: number[];
    powerDraw?: number[];
    limitPowerDraw?: number[];
    airFlow?: number[];
    waterLevel?: number[];

    statusLabels: string[];
    operationMode?: number[];
    computedStatus?: string[];
    snowQuality?: number[];
    startTemperature?: number[];

    seasonLabels?: string[];
}
