import { ReportDefinitionDuration, ReportDefinitionPrecision } from './constants';
import { ReportPart } from './part';
import { dayjs, DAYJS, Device, isArray, isObject } from '../..';

export class ReportDefinition {
  public id?: string;
  public reportName?: string;

  public precision: ReportDefinitionPrecision;
  public duration: ReportDefinitionDuration;

  public parts: ReportPart[] = [];
  public isLive = false;

  public priority = 0;
  public isHidden = false;
  public isCustom = false;
  public created: DAYJS;

  public devicesInParts: { [id: string]: Device } = {};

  public static parseData(data: any): any {
    const parsedData = {
      id: data.id,
      reportName: data.reportName,
      priority: data.priority || 0,
      created: dayjs(data.created),
      duration: ['4h', '8h', '12h', '1d', '3d', '7d'].includes(data.duration) ? data.duration : '7d',
      precision: ['1h', '15m', '5m'].includes(data.precision) ? data.precision : '1h',
      parts: [],
      isLive: !!data.isLive,
      isHidden: !!data.isHidden,
      isCustom: !!data.isCustom,
      devicesInParts: {}
    };

    if(isObject(data.devicesInParts)) {
      parsedData.devicesInParts = data.devicesInParts;
    }

    if(isArray(data.parts)) {
      parsedData.parts = data.parts.map(dp => ReportPart.create(dp));
    }

    return parsedData;
  }
  public static create(data: any): ReportDefinition {
    const parsedData = ReportDefinition.parseData(data);

    return Object.assign(new ReportDefinition(), parsedData);
  }

  public toHttpRequest(): any {
    const x = {
      id: undefined,
      reportName: this.reportName,
      precision: this.precision,
      duration: this.duration,
      priority: this.priority,
      isLive: this.isLive,
      isCustom: this.isCustom,
      parts: this.parts.map(p => p.toHttpRequest())
    };

    if(this.id) {
      x.id = this.id;
    }

    return x;
  }

  public decorateWithDevicesFromParts(deviceMap: Map<string, Device>) {
    if(isArray(this.parts)) {
      const devicesInParts = {};
      this.parts.forEach(part => {
        if(isArray(part.series)) {
          part.series.forEach(serie => {
            if(deviceMap.has(serie.subjectId)) {
              devicesInParts[serie.subjectId] = deviceMap.get(serie.subjectId);
            }
          });
        }
      });
      this.devicesInParts = devicesInParts;
    }
  }

  public getDevicesInParts(): Device[] {
    return Object.values(this.devicesInParts);
  }
}
