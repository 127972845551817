import {
  ReportDefinition,
  ReportExportFormat,
  ReportResult
} from '.';
import { dayjs, DAYJS } from '../..';

export class ReportExport extends ReportDefinition {

  public from: DAYJS;
  public to: DAYJS;

  public locale: string;
  public reportId: string;
  public format: ReportExportFormat;
  public progress: string;
  public user: { id: number };
  public done: DAYJS;

  public numberOfExportAttempts = 0;

  public static create(data: any): ReportExport {
    const parsedData = ReportDefinition.parseData({ ... data });

    parsedData.from = dayjs(data.from, 'YYYY-MM-DD').startOf('d').startOf('hour');
    parsedData.to = dayjs(data.to, 'YYYY-MM-DD').endOf('d').endOf('hour');

    parsedData.locale = data.locale;
    parsedData.user = data.user;
    parsedData.format = data.format;
    parsedData.progress = data.progress;
    parsedData.reportId = `${data.reportId}`;
    parsedData.done = data.done ? dayjs(data.done) : undefined;

    return Object.assign(new ReportExport(), parsedData);
  }

  public toHttpRequest(): any {
    const resp = {
      ... super.toHttpRequest(),
      reportId: this.reportId,
      format: this.format,
      locale: this.locale,
      from: this.from.tz().format('YYYY-MM-DD'),
      to: this.to.tz().format('YYYY-MM-DD')
    };

    delete resp.id;
    delete resp.isCustom;
    delete resp.isLive;
    delete resp.priority;

    return resp;
  }
}
