<ng-container *ngIf="!drawable.component.subType">
    <svg:use  width="101" height="101" xlink:href="#sensor"></svg:use>

    <ng-container *ngIf="!drawable.component.number">
        <svg:text x="50.5" y="52.5" dominant-baseline="middle" text-anchor="middle">{{ drawable.component.module }}</svg:text>
    </ng-container>
    <ng-container *ngIf="drawable.component.number">
        <svg:rect x="35" y="50" width="31" height="1"></svg:rect>
        <svg:text x="50.5" y="48.5" dominant-baseline="baseline" text-anchor="middle">{{ drawable.component.module }}</svg:text>
        <svg:text x="50.5" y="52.5" dominant-baseline="hanging" text-anchor="middle">{{ drawable.component.number }}</svg:text>
    </ng-container>

    <svg:text x="50.5" y="27" *ngIf="drawable.component.valueVar" dominant-baseline="middle" text-anchor="middle">
        <ng-container *ngIf="drawable.component.valueUnit">
            {{ drawable.component.value|number }}
            {{ drawable.component.valueUnit }}
        </ng-container>
        <ng-container *ngIf="!drawable.component.valueUnit">
            {{ drawable.component.value ? 'ON' : 'OFF' }}
        </ng-container>
    </svg:text>
</ng-container>
<ng-container *ngIf="drawable.component.subType==='alertIndicator'">

    <svg:use x="20" y="0" width="61" height="61" xlink:href="#icon-exclamation"></svg:use>

    <foreignObject x="0" y="61" width="101" height="40">
      <p xmlns="http://www.w3.org/1999/xhtml">{{ drawable.component.symbol }}</p>
    </foreignObject>

</ng-container>
<ng-container *ngIf="drawable.component.subType==='em-flow'">
    <ng-container *ngIf="drawable.r || drawable.l">
        <svg:use  width="101" height="101" xlink:href="#em-flow-sensor" [ngStyle]="{
            transform: 'rotate(0deg)'
        }"></svg:use>

        <ng-container *ngIf="!drawable.component.number">
            <svg:text x="50.5" y="22.5" dominant-baseline="middle" text-anchor="middle">{{ drawable.component.module }}</svg:text>
        </ng-container>
        <ng-container *ngIf="drawable.component.number">
            <svg:rect x="35" y="22" width="31" height="1"></svg:rect>
            <svg:text x="50.5" y="20.5" dominant-baseline="baseline" text-anchor="middle">{{ drawable.component.module }}</svg:text>
            <svg:text x="50.5" y="24.5" dominant-baseline="hanging" text-anchor="middle">{{ drawable.component.number }}</svg:text>
        </ng-container>

        <svg:text x="50.5" y="83" *ngIf="drawable.component.valueVar" dominant-baseline="middle" text-anchor="middle">
            <ng-container *ngIf="drawable.component.valueUnit">
                {{ drawable.component.value|number }}
                {{ drawable.component.valueUnit }}
            </ng-container>
            <ng-container *ngIf="!drawable.component.valueUnit">
                {{ drawable.component.value ? 'ON' : 'OFF' }}
            </ng-container>
        </svg:text>
    </ng-container>

    <ng-container *ngIf="drawable.u || drawable.d">
        <svg:use  width="101" height="101" xlink:href="#em-flow-sensor" [ngStyle]="{
            transform: 'rotate(90deg)'
        }"></svg:use>

        <svg:rect x="64" y="50" width="31" height="1"></svg:rect>
        <ng-container *ngIf="!drawable.component.number">
            <svg:text x="79.5" y="50.5" dominant-baseline="middle" text-anchor="middle">{{ drawable.component.module }}</svg:text>
        </ng-container>
        <ng-container *ngIf="drawable.component.number">
            <svg:text x="79.5" y="48.5" dominant-baseline="baseline" text-anchor="middle">{{ drawable.component.module }}</svg:text>
            <svg:text x="79.5" y="52.5" dominant-baseline="hanging" text-anchor="middle">{{ drawable.component.number }}</svg:text>
        </ng-container>

        <svg:text x="38" y="50.5" *ngIf="drawable.component.valueVar" dominant-baseline="middle" text-anchor="end">
            <ng-container *ngIf="drawable.component.valueUnit">
                {{ drawable.component.value|number }}
                {{ drawable.component.valueUnit }}
            </ng-container>
            <ng-container *ngIf="!drawable.component.valueUnit">
                {{ drawable.component.value ? 'ON' : 'OFF' }}
            </ng-container>
        </svg:text>
    </ng-container>
</ng-container>

