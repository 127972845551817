import {
  Component,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ScheduleClientService } from 'src/app/http-clients';
import { ResortService } from 'src/app/resort.service';
import { Schedule, ResortArea, ScheduleSchema, ScheduleDay } from 'src/app/shared/models';
import { Router, ActivatedRoute } from '@angular/router';
import { ScheduleEditData } from '../edit-data-resolver.service';
import { TimeRangePopupRequest } from '../time-range-popup/time-range-popup.component';
import { dayjs } from '../../../../../../common';

@Component({
  selector: 'ss-edit-schedule',
  templateUrl: './edit-schedule.component.html',
  styleUrls: ['./edit-schedule.component.sass']
})
export class WeatherAndSchedulesEditScheduleComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private schedulesService: ScheduleClientService,
    private router: Router,
    private rs: ResortService
  ) { }

  dateRangeLabels: string[] = ['', '', '', '', '', '', ''];

  currentPopupRequest: TimeRangePopupRequest;

  type: string;
  schedule: Schedule;
  resortAreas: ResortArea[] = [];

  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChild('scrollableWrapper', { static: true }) scrollableWrapper: ElementRef;
  @ViewChild('addBtn') addBtn: ElementRef;
  hours: string[] = [];

  isDragging = false;
  isMoving = false;
  isSettingStartTime = false;
  isSettingEndTime = false;
  isMouseOverGrid = false;
  rangeBar;
  rangeBarProp;
  containerProp;
  scrollOffsetLeft: number;
  initialScrollOffsetLeft: number;
  timeUnitWidth: number;
  clickDiffLeft: number;
  clickDiffRight: number;

  currentDay: number;
  currentTimeRange: ScheduleDay;
  currentTimeRangeMin = 0;
  currentTimeRangeMax = 1440;
  isFreeSpace: boolean;
  oldX = 0;

  isAreaChecked(id: string): boolean {
    return this.schedule.areaIds.indexOf(id) >= 0;
  }

  toggleArea(id: string) {
    if (this.isAreaChecked(id)) {
      this.schedule.areaIds.splice(this.schedule.areaIds.indexOf(id), 1);
    } else {
      this.schedule.areaIds.push(id);
    }
  }

  remove() {
    this.schedulesService.remove(this.schedule).subscribe(() => {
      this.router.navigate(['application', 'weather-and-schedules', 'list']);
    }, () => {

    });
  }

  save() {
    this.schedulesService.save(this.schedule).subscribe(() => {
      this.router.navigate(['application', 'weather-and-schedules', 'list']);
    }, () => {

    });
  }

  editTimeRange($event, timeRange) {
    this.currentPopupRequest = {
      centerY: $event.target.getBoundingClientRect().top + 0.5 * $event.target.getBoundingClientRect().height,
      centerX: $event.target.getBoundingClientRect().left + 0.5 * $event.target.getBoundingClientRect().width,
      maxTailWidth: $event.target.getBoundingClientRect().width,
      schedule: this.schedule,
      timeRange
    };
  }

  scrolling() {
    this.scrollOffsetLeft = this.scrollableWrapper.nativeElement.scrollLeft;
  }

  calcTimeRangeLimits(timeRange, existingRanges) {
    existingRanges.forEach(otherTimeRange => {
      if (timeRange.start >= otherTimeRange.end) {
        this.currentTimeRangeMin = otherTimeRange.end;
      }
      if (timeRange.end <= otherTimeRange.start) {
        this.currentTimeRangeMax = otherTimeRange.start;
      }
    });
  }

  mergeOverlappingRanges() {
    if (this.currentTimeRange.end === this.currentTimeRangeMax) {
      this.schedule.getDay(this.currentDay).forEach((el, i) => {
        if (el.start === this.currentTimeRangeMax) {
          this.currentTimeRange.end = el.end;
          this.schedule.getDay(this.currentDay).splice(i, 1);
        }
      });
    } else if (this.currentTimeRange.start === this.currentTimeRangeMin) {
      this.schedule.getDay(this.currentDay).forEach((el, i) => {
        if (el.end === this.currentTimeRangeMin) {
          this.currentTimeRange.start = el.start;
          this.schedule.getDay(this.currentDay).splice(i, 1);
        }
      });
    }
  }

  draggingStart(rangeBar, timeRange: ScheduleDay, dayNo: number) {
    this.initialScrollOffsetLeft = this.scrollableWrapper.nativeElement.scrollLeft;
    this.isDragging = true;
    this.rangeBar = rangeBar;
    this.rangeBarProp = rangeBar.getBoundingClientRect();
    this.currentTimeRange = timeRange;
    this.calcTimeRangeLimits(timeRange, this.schedule.getDay(dayNo));
  }

  draggingEnd() {
    if (this.isDragging) {
      this.isDragging = false;
      this.isSettingStartTime = false;
      this.isSettingEndTime = false;
      this.mergeOverlappingRanges();
      this.currentTimeRangeMax = 1440;
      this.currentTimeRangeMin = 0;
    }
  }

  movingStart(e, rangeBar, timeRange: ScheduleDay, dayNo: number) {
    this.initialScrollOffsetLeft = this.scrollableWrapper.nativeElement.scrollLeft;
    this.isMoving = true;
    timeRange.isMoving = true;
    this.rangeBar = rangeBar;
    this.rangeBarProp = rangeBar.getBoundingClientRect();
    this.clickDiffLeft = e.x - this.rangeBarProp.left;
    this.clickDiffRight = this.rangeBarProp.right - e.x;
    this.currentTimeRange = timeRange;
    this.currentDay = dayNo;
    this.calcTimeRangeLimits(timeRange, this.schedule.getDay(dayNo));
  }

  movingEnd() {
    if (this.isMoving) {
      this.isMoving = false;
      this.schedule.getScheduleDays().forEach(tr => {
        tr.isMoving = false;
      });
      this.mergeOverlappingRanges();
      this.currentTimeRangeMax = 1440;
      this.currentTimeRangeMin = 0;
    }
  }

  adjustScrollOnMove(e, movedElement, wrapper, scrollOffset) {
    const movedElProp = movedElement.getBoundingClientRect();
    const wrapperProp = wrapper.getBoundingClientRect();

    if (e.pageX < this.oldX) {
      if (movedElProp.left < wrapperProp.left) {
        wrapper.scrollLeft = movedElProp.left + scrollOffset - wrapperProp.left;
      }
    } else if (e.pageX > this.oldX) {
      if (movedElProp.right > wrapperProp.right) {
        wrapper.scrollLeft = movedElProp.right + scrollOffset - wrapperProp.right;
      }
    }

    this.oldX = e.pageX;
  }

  changeRange(e) {

    if (this.isMoving || this.isDragging) {
      const cursorOffsetLeft = e.x + this.initialScrollOffsetLeft;
      const offsetLeft = Math.round(
        (cursorOffsetLeft - this.containerProp.left - this.clickDiffLeft) / this.timeUnitWidth
      ) * this.timeUnitWidth;
      const draggedDiff = parseFloat(
        (offsetLeft - this.rangeBarProp.left + this.containerProp.left - this.initialScrollOffsetLeft).toFixed(4)
      );
      let width = this.rangeBarProp.width;
      let start;
      let end;

      // moving
      if (this.isMoving && !this.isDragging) {
        start = Math.round(offsetLeft / this.timeUnitWidth) * 15;
        end = Math.round(width / this.timeUnitWidth) * 15 + start;

        if (
          offsetLeft >= 0
          && offsetLeft + this.containerProp.left + width <= this.containerProp.right
          && start >= this.currentTimeRangeMin && end <= this.currentTimeRangeMax
        ) {
          this.currentTimeRange.start = start;
          this.currentTimeRange.end = end;
        }

        // dragging start button
      } else if (this.isDragging && this.isSettingStartTime) {

        width = parseFloat((Math.round((width - draggedDiff) / this.timeUnitWidth) * this.timeUnitWidth).toFixed(4));
        start = Math.round(offsetLeft / this.timeUnitWidth) * 15;
        end = Math.round(width / this.timeUnitWidth) * 15 + start;

        if (
          offsetLeft >= 0
          && cursorOffsetLeft < this.containerProp.right
          && width >= this.timeUnitWidth * 8
          && start >= this.currentTimeRangeMin
          && end <= this.currentTimeRangeMax
        ) {
          this.currentTimeRange.start = start;
          this.currentTimeRange.end = end;
        }

        // dragging end button
      } else if (this.isDragging && this.isSettingEndTime) {

        width = parseFloat(
          (Math.round((width + draggedDiff) / this.timeUnitWidth) * this.timeUnitWidth).toFixed(4)
        );
        start = Math.round(
          (this.rangeBarProp.left - this.containerProp.left + this.initialScrollOffsetLeft) / this.timeUnitWidth
        ) * 15;
        end = Math.round(width / this.timeUnitWidth) * 15 + start;

        if (
          cursorOffsetLeft + this.clickDiffRight < this.containerProp.right
          && width >= this.timeUnitWidth * 8
          && start >= this.currentTimeRangeMin
          && end <= this.currentTimeRangeMax
        ) {
          this.currentTimeRange.start = start;
          this.currentTimeRange.end = end;
        }

      }

      this.adjustScrollOnMove(e, this.rangeBar, this.scrollableWrapper.nativeElement, this.scrollOffsetLeft);

    }

  }

  setStartTime() {
    this.isSettingStartTime = true;
  }

  setEndTime() {
    this.isSettingEndTime = true;
  }

  addTimeRange(dayNo:  number, btn) {
    const btnProp = btn.getBoundingClientRect();
    const newTimeRangeStart = Math.round(
      (btnProp.x - this.containerProp.left + this.scrollOffsetLeft) / this.timeUnitWidth
    ) * 15;
    const newTimeRangeEnd = newTimeRangeStart + 120;
    let sumOfTimeRanges = 0; // 1440 max
    this.isFreeSpace = true;

    this.schedule.getDay(dayNo).forEach(timeRange => {
      sumOfTimeRanges = sumOfTimeRanges + timeRange.end - timeRange.start;
      if (
        timeRange.start < newTimeRangeStart && timeRange.end > newTimeRangeStart
        || timeRange.start < newTimeRangeEnd && timeRange.end > newTimeRangeEnd
        || newTimeRangeEnd > 1440
        || newTimeRangeStart < 0
      ) {
        this.isFreeSpace = false;
      }
    });

    if (sumOfTimeRanges < 1320 && this.isFreeSpace) {
      this.schedule.getDay(dayNo).push(new ScheduleDay(dayNo, newTimeRangeStart, newTimeRangeEnd));
    }
  }

  mouseOverGrid(isOverGrid: boolean, btn) {
    if (isOverGrid) {
      if (this.isMoving || this.isDragging) {
        this.isMouseOverGrid = false;
      } else {
        this.isMouseOverGrid = true;
        this.isFreeSpace = true;
      }
    } else {
      btn.style.opacity = 0;
    }
  }

  mouseOverTimeRange(e) {
    e.stopPropagation();
    this.isMouseOverGrid = false;
  }

  mouseOverButton(btn) {
    this.isMouseOverGrid = true;
    btn.style.opacity = 1;
  }

  stickAddBtn(e, btn) {
    this.isFreeSpace = true;
    const btnProp = btn.getBoundingClientRect();
    if (this.isMouseOverGrid && e.x < this.containerProp.right) {
      btn.style.opacity = 1;
      btn.style.left = e.x - this.containerProp.left + this.scrollOffsetLeft - btnProp.width / 2 + 'px';
    } else {
      btn.style.opacity = 0;
    }
  }

  ngOnInit() {
    const sof = dayjs().startOf('isoWeek');

    this.dateRangeLabels = [
      sof.tz().format('dddd'),
      sof.add(1, 'd').tz().format('dddd'),
      sof.add(1, 'd').tz().format('dddd'),
      sof.add(1, 'd').tz().format('dddd'),
      sof.add(1, 'd').tz().format('dddd'),
      sof.add(1, 'd').tz().format('dddd'),
      sof.add(1, 'd').tz().format('dddd')
    ];

    this.route.data
    .subscribe((data: { areaData: ScheduleEditData }) => {
      this.schedule = data.areaData.schedule;
      this.type = data.areaData.type;
      this.resortAreas = data.areaData.areas;
    });

    for (let i = 0; i <= 24; i++) {
      let el;
      if (i < 10) {
        el = '0' + i;
      } else if (i === 24) {
        el = '00';
      } else {
        el = i;
      }

      this.hours.push(el);
    }

    this.scrollOffsetLeft = this.scrollableWrapper.nativeElement.scrollLeft;
    this.containerProp = this.container.nativeElement.getBoundingClientRect();
    this.timeUnitWidth = parseFloat((this.containerProp.width / 96).toFixed(4));
  }

}
