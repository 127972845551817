import { trn } from './translation-wrapper.function';

export const translateDeviceAlert = (alert: string): string => {
  const parts = alert.split(':');
  const alertWithoutParts = parts.length > 0 ? parts[0] : alert;
  const part = parts.length > 1 ? parts[1] : '';

  switch(alertWithoutParts) {
    case 'connection-lost':
      return trn($localize`:Alert sprzętowy 'Brak połaczenia'|
        @@deviceAlertConnectionLost:Brak połaczenia`);
    case 'water-pipeline-in-manual-mode':
      return trn($localize`:Alert sprzętowy 'Rurociąg pracuje w trybie lokalnym - brak możliwości sterowania'|
        @@deviceAlertWaterPipelineInManualMode:Rurociąg pracuje w trybie lokalnym - brak możliwości sterowania`);
    case 'water-pipeline-emergency-button-pressed':
      return trn($localize`:Alert sprzętowy 'Wciśnięto przycisk zatrzymania awaryjnego'|
        @@deviceAlertWaterPipelineEmergencyButtonPressed:Wciśnięto przycisk zatrzymania awaryjnego`);
    case 'snow-cannon-is-in-local-mode':
      return trn($localize`:Alert sprzętowy 'Armatka pracuje w trybie lokalnym'|
        @@deviceAlertSnowCannonIsInLocalMode:Armatka pracuje w trybie lokalnym`);
    case 'snow-cannon-alertCompressorFailure':
      return trn($localize`:Alert sprzętowy 'Awaria kompresor.'|
        @@deviceAlertSnowCannonAlertCompressorFailure:Awaria kompresor.`);
    case 'snow-cannon-alertEmergencyButtonPressed':
      return trn($localize`:Alert sprzętowy 'Awaria wciśnięty przycisk awaryjny.'|
        @@deviceAlertSnowCannonAlertEmergencyButtonPressed:Awaria wciśnięty przycisk awaryjny.`);
    case 'snow-cannon-alertHydraulicsFailure':
      return trn($localize`:Alert sprzętowy 'Awaria układ hydrauliczny.'|
        @@deviceAlertSnowCannonAlertHydraulicsFailure:Awaria układ hydrauliczny.`);
    case 'snow-cannon-alertMainFanFailure':
      return trn($localize`:Alert sprzętowy 'Awaria wentylator.'|
        @@deviceAlertSnowCannonAlertMainFanFailure:Awaria wentylator.`);
    case 'snow-cannon-alertOscillationFailure':
      return trn($localize`:Alert sprzętowy 'Uwaga nie działa oscylacja.'|
        @@deviceAlertSnowCannonAlertOscillationFailure:Uwaga nie działa oscylacja.`);
    case 'snow-cannon-alertPowerSupplyFailure':
      return trn($localize`:Alert sprzętowy 'Awaria zasilanie.'|
        @@deviceAlertSnowCannonAlertPowerSupplyFailure:Awaria zasilanie.`);
    case 'snow-cannon-alertRingHeatersFailure':
      return trn($localize`:Alert sprzętowy 'Awaria grzałki pierścienia.'|
        @@deviceAlertSnowCannonAlertRingHeatersFailure:Awaria grzałki pierścienia.`);
    case 'snow-cannon-alertTemperatureSensorFailure':
      return trn($localize`:Alert sprzętowy 'Awaria czujnik temperatury powietrza.'|
        @@deviceAlertSnowCannonAlertTemperatureSensorFailure:Awaria czujnik temperatury powietrza.`);
    case 'snow-cannon-alertTooLowAirPressure':
      return trn($localize`:Alert sprzętowy 'Awaria zbyt niskie ciśnienie powietrza.'|
        @@deviceAlertSnowCannonAlertTooLowAirPressure:Awaria zbyt niskie ciśnienie powietrza.`);
    case 'snow-cannon-alertTooLowWaterPressure':
      return trn($localize`:Alert sprzętowy 'Awaria zbyt niskie ciśnienie wody.'|
        @@deviceAlertSnowCannonAlertTooLowWaterPressure:Awaria zbyt niskie ciśnienie wody.`);
    case 'snow-cannon-alertWaterPressureSensorFaultOrTooLowWaterPressure':
      return trn($localize`:Alert sprzętowy 'Awaria Zbyt niskie ciśnienie wody/niesprawny czujnik.'|
        @@deviceAlertSnowCannonAlertWaterPressureSensorFaultOrTooLowWaterPressure:Awaria Zbyt niskie ciśnienie wody/niesprawny czujnik.`);
    case 'snow-cannon-alertWaterValvesFailure':
      return trn($localize`:Alert sprzętowy 'Awaria zwory wodne.'|
        @@deviceAlertSnowCannonAlertWaterValvesFailure:Awaria zwory wodne.`);
    case 'snow-cannon-warningAirHumiditiSensorFailure':
      return trn($localize`:Alert sprzętowy 'Uwaga nie działa czujnik wilgotności powietrza.'|
        @@deviceAlertSnowCannonWarningAirHumiditiSensorFailure:Uwaga nie działa czujnik wilgotności powietrza.`);
    case 'snow-cannon-warningNoHydrantDrive':
      return trn($localize`:Alert sprzętowy 'Uwaga brak napędu hydrantu.'|
        @@deviceAlertSnowCannonWarningNoHydrantDrive:Uwaga brak napędu hydrantu.`);
    case 'snow-cannon-warningPositioningFailure':
      return trn($localize`:Alert sprzętowy 'Uwaga nie działa pozycjonowanie.'|
        @@deviceAlertSnowCannonWarningPositioningFailure:Uwaga nie działa pozycjonowanie.`);
    case 'snow-cannon-warningTooHighWaterPressure':
      return trn($localize`:Alert sprzętowy 'Uwaga zbyt wysokie ciśnienie wody.'|
        @@deviceAlertSnowCannonWarningTooHighWaterPressure:Uwaga zbyt wysokie ciśnienie wody.`);
    case 'snow-cannon-warningTooLowOilPressure':
      return trn($localize`:Alert sprzętowy 'Uwaga zbyt niskie ciśnienie oleju.'|
        @@deviceAlertSnowCannonWarningTooLowOilPressure:Uwaga zbyt niskie ciśnienie oleju.`);
    case 'snow-cannon-warningWaterSensorFailure':
      return trn($localize`:Alert sprzętowy 'Uwaga nie działa czujnik temperatury wody.'|
        @@deviceAlertSnowCannonWarningWaterSensorFailure:Uwaga nie działa czujnik temperatury wody.`);
    case 'snow-cannon-reachedComputedStatus-connection-lost':
      return trn($localize`:Alert sprzętowy 'Armatka przeszła w stan Brak połączenia'|
        @@deviceAlertSnowCannonReachedComputedStatusConnectionLost:Armatka przeszła w stan Brak połączenia`);
    case 'snow-cannon-reachedComputedStatus-breakdown':
      return trn($localize`:Alert sprzętowy 'Armatka przeszła w stan Awaria'|
        @@deviceAlertSnowCannonReachedComputedStatusBreakdown:Armatka przeszła w stan Awaria`);
    case 'snow-cannon-reachedComputedStatus-working-in-manual-mode':
      return trn($localize`:Alert sprzętowy 'Armatka przeszła w stan Tryb manualny'|
        @@deviceAlertSnowCannonReachedComputedStatusWorkingInManualMode:Armatka przeszła w stan Tryb manualny`);
    case 'snow-cannon-reachedComputedStatus-starting':
      return trn($localize`:Alert sprzętowy 'Armatka przeszła w stan Uruchamianie'|
        @@deviceAlertSnowCannonReachedComputedStatusStarting:Armatka przeszła w stan Uruchamianie`);
    case 'snow-cannon-reachedComputedStatus-working':
      return trn($localize`:Alert sprzętowy 'Armatka przeszła w stan Pracuje'|
        @@deviceAlertSnowCannonReachedComputedStatusWorking:Armatka przeszła w stan Pracuje`);
    case 'snow-cannon-reachedComputedStatus-stopping':
      return trn($localize`:Alert sprzętowy 'Armatka przeszła w stan Zatrzymywanie'|
        @@deviceAlertSnowCannonReachedComputedStatusStopping:Armatka przeszła w stan Zatrzymywanie`);
    case 'snow-cannon-reachedComputedStatus-awaiting-for-conditions':
      return trn($localize`:Alert sprzętowy 'Armatka przeszła w stan Czeka na warunki'|
        @@deviceAlertSnowCannonReachedComputedStatusAwaitingForConditions:Armatka przeszła w stan Czeka na warunki`);
    case 'snow-cannon-reachedComputedStatus-ready-to-work':
      return trn($localize`:Alert sprzętowy 'Armatka przeszła w stan Gotowość'|
        @@deviceAlertSnowCannonReachedComputedStatusReadyToWork:Armatka przeszła w stan Gotowość`);
    case 'snow-cannon-reachedComputedStatus-snoozing':
      return trn($localize`:Alert sprzętowy 'Armatka przeszła w stan Usypianie'|
        @@deviceAlertSnowCannonReachedComputedStatusSnoozing:Armatka przeszła w stan Usypianie`);
    case 'snow-cannon-reachedComputedStatus-standing-by':
      return trn($localize`:Alert sprzętowy 'Armatka przeszła w stan Czuwanie'|
        @@deviceAlertSnowCannonReachedComputedStatusStandingBy:Armatka przeszła w stan Czuwanie`);
    case 'snow-cannon-reachedComputedStatus-preparing-to-work':
      return trn($localize`:Alert sprzętowy 'Armatka przeszła w stan Przygotowanie'|
        @@deviceAlertSnowCannonReachedComputedStatusPreparingToWork:Armatka przeszła w stan Przygotowanie`);
    case 'meteo-station-alertCompassFault':
      return trn($localize`:Alert sprzętowy 'Awaria kompasu'|
        @@deviceAlertMeteoStationAlertCompassFault:Awaria kompasu`);
    case 'meteo-station-alertDewpointFault':
      return trn($localize`:Alert sprzętowy 'Awaria pomiaru punktu rosy'|
        @@deviceAlertMeteoStationAlertDewpointFault:Awaria pomiaru punktu rosy`);
    case 'meteo-station-alertHumidityFault':
      return trn($localize`:Alert sprzętowy 'Awaria czujnika wilgotności powietrza'|
        @@deviceAlertMeteoStationAlertHumidityFault:Awaria czujnika wilgotności powietrza`);
    case 'meteo-station-alertPressureSensorWarning':
      return trn($localize`:Alert sprzętowy 'Awaria czujnika ciśnienia atmosferycznego'|
        @@deviceAlertMeteoStationAlertPressureSensorWarning:Awaria czujnika ciśnienia atmosferycznego`);
    case 'meteo-station-alertTemperatureMeasurementFault':
      return trn($localize`:Alert sprzętowy 'Awaria czujnika temperatury powietrza'|
        @@deviceAlertMeteoStationAlertTemperatureMeasurementFault:Awaria czujnika temperatury powietrza`);
    case 'meteo-station-alertWindMeasurementFault':
      return trn($localize`:Alert sprzętowy 'Awaria czujnika pomiaru prędkości wiatru'|
        @@deviceAlertMeteoStationAlertWindMeasurementFault:Awaria czujnika pomiaru prędkości wiatru`);
    case 'electrical-line-warning-power-draw-over-limit':
      return trn($localize`:Alert sprzętowy 'Przekroczono limit poboru prądu'|
        @@deviceAlertElectricalLineWarningPowerDrawOverLimit:Przekroczono limit poboru prądu`);
    case 'electrical-line-alert-power-draw-over-maximum-safe-level':
      return trn($localize`:Alert sprzętowy 'Przekroczono maksymalną bezpieczną wartość poboru prądu'|
        @@deviceAlertElectricalLineAlertPowerDrawOverMaximumSafeLevel:Przekroczono maksymalną bezpieczną wartość poboru prądu`);
    case 'power-source-warning-power-draw-over-limit':
      return trn($localize`:Alert sprzętowy 'Przekroczono limit poboru prądu'|
        @@deviceAlertPowerSourceWarningPowerDrawOverLimit:Przekroczono limit poboru prądu`);
    case 'power-source-alert-power-draw-over-maximum-safe-level':
      return trn($localize`:Alert sprzętowy 'Przekroczono maksymalną bezpieczną wartość poboru prądu'|
        @@deviceAlertPowerSourceAlertPowerDrawOverMaximumSafeLevel:Przekroczono maksymalną bezpieczną wartość poboru prądu`);
    case 'pump-room-breakdown':
      return trn($localize`:Alert sprzętowy 'Awaria pompowni - brak możliwości pracy w trybie automatycznym'|
        @@deviceAlertPumpRoomBreakdown:Awaria pompowni - brak możliwości pracy w trybie automatycznym`);
    case 'pump-room-in-manual-mode':
      return trn($localize`:Alert sprzętowy 'Pompownia pracuje w trybie lokalnym - brak możliwości sterowania'|
        @@deviceAlertPumpRoomInManualMode:Pompownia pracuje w trybie lokalnym - brak możliwości sterowania`);
    case 'pump-room-communication-with-component-failed':
      return trn($localize`:Alert sprzętowy 'Brak komunikacji pomiędzy sterownikami pompowni'|
        @@deviceAlertPumpRoomCommunicationWithComponentFailed:Brak komunikacji pomiędzy sterownikami pompowni`);
    case 'pump-room-power-fault':
      return trn($localize`:Alert sprzętowy 'Awaria zasilania'|
        @@deviceAlertPumpRoomPowerFault:Awaria zasilania`);
    case 'pump-room-emergency-button-pressed':
      return trn($localize`:Alert sprzętowy 'Wciśnięto przycisk zatrzymania awaryjnego'|
        @@deviceAlertPumpRoomEmergencyButtonPressed:Wciśnięto przycisk zatrzymania awaryjnego`);
    case 'pump-room-warning-component-indicate-alert':
      return trn($localize`:Alert sprzętowy 'Awaria' z dodatkowym komentarzem generowanym na podst stanu pompowni|
        @@deviceAlertPumpRoomWarningComponentIndicateAlert:Awaria ${part}`);
    case 'pump-room-warning-component-breakdown-valve':
      return trn($localize`:Alert sprzętowy 'Awaria zaworu'|
        @@deviceAlertPumpRoomWarningComponentBreakdownValve:Awaria zaworu ${part}`);
    case 'pump-room-warning-component-breakdown-pump':
      return trn($localize`:Alert sprzętowy 'Awaria pompy'|
        @@deviceAlertPumpRoomWarningComponentBreakdownPump:Awaria pompy ${part}`);
    case 'pump-room-warning-component-breakdown-filter':
      return trn($localize`:Alert sprzętowy 'Awaria filtra'|
        @@deviceAlertPumpRoomWarningComponentBreakdownFilter:Awaria filtra ${part}`);
    case 'pump-room-warning-component-breakdown-sensor':
      return trn($localize`:Alert sprzętowy 'Awaria czujnika'|
        @@deviceAlertPumpRoomWarningComponentBreakdownSensor:Awaria czujnika ${part}`);
    case 'pump-room-warning-component-breakdown-coolingTower':
      return trn($localize`:Alert sprzętowy 'Awaria czujnika'|
        @@deviceAlertPumpRoomWarningComponentBreakdownCoolingTower:Awaria chłodni ${part}`);
    case 'pump-room-warning-component-manual-mode-valve':
      return trn($localize`:Alert sprzętowy 'Zawór pracuje w trybie ręcznym'|
        @@deviceAlertPumpRoomWarningComponentManualModeValve:Zawór ${part} pracuje w trybie ręcznym`);
    case 'pump-room-warning-component-manual-mode-pump':
      return trn($localize`:Alert sprzętowy 'Pompa pracuje w trybie ręcznym'|
        @@deviceAlertPumpRoomWarningComponentManualModePump:Pompa ${part} pracuje w trybie ręcznym `);
    case 'pump-room-warning-component-dryrun-pump':
      return trn($localize`:Alert sprzętowy 'Awaria pompy suchobieg'|
        @@deviceAlertPumpRoomWarningComponentDryrunPump:Awaria pompy ${part} - suchobieg`);
    case 'pump-room-warning-component-overheated-pump':
      return trn($localize`:Alert sprzętowy 'Awaria pompy przegrzanie'|
        @@deviceAlertPumpRoomWarningComponentOverheatedPump:Awaria pompy ${part} - przegrzanie`);
    case 'pump-room-warning-component-overcurrent-pump':
      return trn($localize`:Alert sprzętowy 'Awaria pompy zbyt duży prąd'|
        @@deviceAlertPumpRoomWarningComponentOvercurrentPump:Awaria pompy ${part} - zbyt duży prąd`);
  }

  return trn(alert);
};
