<div [ngClass]="{ 'disabled': lock }">
  <form #form="ngForm" (submit)="save()">
  <ss-page [modal]="true">
      <ss-page-header>
          <ss-page-header-title
            i18n="Ustawienia stacji meteo|Tytuł modala@@meteoStationConfigurationModalTitle">Ustawienia stacji meteo {{ device.symbol }}</ss-page-header-title>
      </ss-page-header>

      <ss-page-panel panelTitle="Symulacja warunków meteo" i18n-panelTitle="Symulacja warunków meteo|Tytuł panelu@@meteoStationConfigurationModalSimulPanelTitle">
        <form-checkbox [hideLabel]="true" [(ngModel)]="enableSimulation" name="form-cfg-enableSimulation">
          <form-label i18n="Włącz symulację warunków meteo|Checkbox@@meteoStationConfigurationModalSimulEnableCheckbox">Włącz symulację warunków meteo</form-label>
        </form-checkbox>
        <div class="detailed-settings" [ngClass]="{ 'visible': enableSimulation }">
          <div class="row align-items-center">
            <div class="col-10">
              <form-number [min]="-25" [max]="5" [step]="1" [(ngModel)]="wetBulbTemperatureOverride"
                name="form-cfg-wetBulbTemperatureOverride">
                <form-label i18n="Wartość TWB|Etykieta inputu@@meteoStationConfigurationModalSimulTWBValue">Wartość TWB</form-label>
              </form-number>
            </div>
            <div class="col ml-1">&deg;C</div>
          </div>
          <div class="row align-items-center">
            <div class="col-10">
              <form-number [min]="-25" [max]="5" [step]="1" [(ngModel)]="airTemperatureOverride"
                name="form-cfg-airTemperatureOverride">
                <form-label i18n="Wartość temperatury powietrza|Etykieta inputu@@meteoStationConfigurationModalSimulAirTempValue">Wartość temperatury powietrza</form-label>
              </form-number>
            </div>
            <div class="col ml-1">&deg;C</div>
          </div>
          <div class="row align-items-center">
            <div class="col-10">
              <form-number [min]="0" [max]="100" [step]="10" [(ngModel)]="airHumidityOverride"
                name="form-cfg-airHumidityOverride">
                <form-label i18n="Wartość wilgotności|Etykieta inputu@@meteoStationConfigurationModalSimulHumidityValue">Wartość wilgotności</form-label>
              </form-number>
            </div>
            <div class="col ml-1">%</div>
          </div>
        </div>
      </ss-page-panel>

      <ss-page-footer>
          <div></div>
          <ss-page-button-group>
            <button type="submit" class="btn btn-primary" i18n="Zapisz konfigurację|Przycisk zapisz@@meteoStationConfigurationModalSave">Zapisz konfigurację</button>
            <button type="button" class="btn btn-default" (click)="close()" i18n="Anuluj|Anuluj@@massOperationsModalCancelButon">Anuluj</button>
          </ss-page-button-group>
      </ss-page-footer>
  </ss-page>
  </form>
</div>
