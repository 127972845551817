import {
  Component,
  Optional,
  Inject,
  Input,
  ViewChild,
  ContentChildren,
  QueryList
} from '@angular/core';
import {
  NgModel,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NG_ASYNC_VALIDATORS,
} from '@angular/forms';
import { ElementBase } from '../element-base';
import { FormSelectOptionComponent } from './form-select-option.component';

let identifier = 0;

@Component({
  selector: 'form-select',
  templateUrl: './form-select.component.html',
  styleUrls: [],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FormSelectComponent,
    multi: true,
  }],
})
export class FormSelectComponent extends ElementBase<string> {
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public isExtraWide: boolean;

  @ContentChildren(FormSelectOptionComponent)
  public options: QueryList<FormSelectOptionComponent>;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  public isOpenned = false;
  public identifier = `form-select-${identifier++}-` + Math.random();

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }
}
