export { Dayjs as DAYJS } from 'dayjs';

import * as DAYJS_Prototype from 'dayjs';
import 'dayjs/locale/pl';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/cs';
import 'dayjs/locale/sk';

import * as DAYJS_utc from 'dayjs/plugin/utc';
import * as DAYJS_isoWeek from 'dayjs/plugin/isoWeek';
import * as DAYJS_duration from 'dayjs/plugin/duration';
import * as DAYJS_isBetween from 'dayjs/plugin/isBetween';
import * as DAYJS_isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as DAYJS_isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as DAYJS_advancedFormat from 'dayjs/plugin/advancedFormat';
import * as DAYJS_timezone from 'dayjs/plugin/timezone';

DAYJS_Prototype.extend(DAYJS_utc);
DAYJS_Prototype.extend(DAYJS_isoWeek);
DAYJS_Prototype.extend(DAYJS_advancedFormat);
DAYJS_Prototype.extend(DAYJS_timezone);
DAYJS_Prototype.extend(DAYJS_duration);
DAYJS_Prototype.extend(DAYJS_isBetween);
DAYJS_Prototype.extend(DAYJS_isSameOrAfter);
DAYJS_Prototype.extend(DAYJS_isSameOrBefore);

export const dayjs = DAYJS_Prototype;

DAYJS_Prototype.tz.guess();
export const setupDayJSTimezone = (tz: string) => {
  DAYJS_Prototype.tz.setDefault(tz);
};

DAYJS_Prototype.locale('pl');
export const setupDayJSLocale = (locale: string) => {
  if(['de', 'en', 'cs', 'sk'].includes(locale)) {
    DAYJS_Prototype.locale(locale);
  } else {
    DAYJS_Prototype.locale('pl');
  }
};

