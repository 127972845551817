import { Component, Input, } from '@angular/core';
import { ConnectionPoint } from 'src/app/shared/models';

@Component({
  selector: 'ss-connection-point-preview',
  templateUrl: './connection-point-preview.component.html',
  styleUrls: []
})
export class ConnectionPointPreviewComponent {

  @Input()
  connectionPoint: ConnectionPoint;

  @Input()
  direction: string;

}
