import { ReportResult } from './result';
import { ReportDefinitionDuration } from './constants';
import { ReportPart, ReportPartView } from '.';
import { dayjs, DAYJS } from '../..';

export class ReportResultView extends ReportResult {
  public displayFrom: DAYJS;
  public displayTo: DAYJS;

  public format = 'YYYY-MM-DD';
  public overallDuration = 7;

  public nextDisplayDelim?: DAYJS;
  public prevDisplayDelim?: DAYJS;

  public firstDisplayDelim?: DAYJS;
  public prevMonthDisplayDelim?: DAYJS;
  public prevWeekDisplayDelim?: DAYJS;
  public prevDayDisplayDelim?: DAYJS;
  public nextDayDisplayDelim?: DAYJS;
  public nextWeekDisplayDelim?: DAYJS;
  public nextMonthDisplayDelim?: DAYJS;
  public lastDisplayDelim?: DAYJS;

  public partViews: ReportPartView[] = [];

  public static create(data: any, previousInstance?: ReportResultView): ReportResultView {
    const parsedData = ReportResult.create({
      ... data
    });

    const resp = Object.assign(new ReportResultView(), parsedData, {
      displayFrom: undefined,
      displayTo: dayjs(data.displayTo, 'YYYY-MM-DD').endOf('d')
    });
    if(previousInstance) {
      if(resp.equals(previousInstance)) {
        previousInstance.data = resp.data;
        previousInstance.update({});
        return previousInstance;
      }

      resp.displayTo = previousInstance.displayTo;
    }

    resp.update({});
    return resp;
  }

  public equals(previousInstance: ReportResultView) {
    return JSON.stringify(previousInstance.toHttpRequest()) === JSON.stringify(this.toHttpRequest());
  }

  public updatePart(part: ReportPart) {
    const parts = [... this.parts];
    if(!parts.includes(part)) {
      parts.push(part);
    }
    this.rebuildParts(parts, false);
  }

  public removePart(part: ReportPart) {
    const parts = [... this.parts];
    const idx = parts.indexOf(part);
    if(idx >= 0) {
      parts.splice(idx, 1);
    }
    this.rebuildParts(parts, false);
  }

  public reorderParts(parts: ReportPart[]) {
    this.rebuildParts(parts, false);
  }

  public update(data: any = {}) {
    let displayTo = this.displayTo;
    let duration = this.duration;
    let isLive = this.isLive;
    if(data.isLive !== undefined) {
      isLive = data.isLive;
    }
    this.isLive = isLive;
    if(data.displayTo && dayjs(data.displayTo).isValid) {
      displayTo = data.displayTo;
    }
    if(data.duration) {
      duration = data.duration;
    }

    this.recalcForSelectedDisplayPeriod(displayTo, duration);

    this.rebuildParts([...this.parts]);
  }


  private rebuildParts(parts: ReportPart[], allowUpdates: boolean = true) {
    if(this.data) {
      const partViews = [...this.partViews];
      parts.forEach((part, partIdx) => {
        if(partViews[partIdx] && allowUpdates) {
          partViews[partIdx].part = part;
        } else {
          partViews[partIdx] = ReportPartView.create(part);
        }
      });

      Object.assign(this, {
        parts,
        partViews
      });
      partViews.forEach(pv => pv.calculate(this));
    }
  }

  private recalcFormat(duration: ReportDefinitionDuration): string {
    if (['4h', '8h', '12h'].includes(duration)) {
      return 'YYYY-MM-DD HH:mm';
    }
    return 'YYYY-MM-DD';
  }

  private recalcDisplayTo(displayFrom: DAYJS, duration: ReportDefinitionDuration): DAYJS {
    const tmpDate = displayFrom.endOf('hour');
    switch (duration) {
      case 'all':
        return this.to;
      case '4h':
        return tmpDate.add(3, 'hour');
      case '8h':
        return tmpDate.add(7, 'hour');
      case '12h':
        return tmpDate.add(11, 'hour');
      case '1d':
        return tmpDate.endOf('day');
      case '3d':
        return tmpDate.add(2, 'day').endOf('day');
    }
    return tmpDate.add(6, 'day').endOf('day');
  }

  private recalcDisplayFrom(displayTo: DAYJS, duration: ReportDefinitionDuration): DAYJS {
    const tmpDate = displayTo.startOf('hour');
    switch (duration) {
      case 'all':
        return this.from;
      case '4h':
        return tmpDate.subtract(3, 'hour');
      case '8h':
        return tmpDate.subtract(7, 'hour');
      case '12h':
        return tmpDate.subtract(11, 'hour');
      case '1d':
        return tmpDate.startOf('day');
      case '3d':
        return tmpDate.endOf('day').subtract(2, 'day').startOf('day');
    }
    return tmpDate.endOf('day').subtract(6, 'day').startOf('day');
  }

  private recalcForSelectedDisplayPeriod(_displayTo: DAYJS, _duration: ReportDefinitionDuration) {
    const calculatedFix = {
      displayTo: _displayTo,
      duration: _duration,
      displayFrom: undefined,
      nextDisplayDelim: undefined,
      prevDisplayDelim: undefined,
      firstDisplayDelim: undefined,
      prevMonthDisplayDelim: undefined,
      prevWeekDisplayDelim: undefined,
      prevDayDisplayDelim: undefined,
      nextDayDisplayDelim: undefined,
      nextWeekDisplayDelim: undefined,
      nextMonthDisplayDelim: undefined,
      lastDisplayDelim: undefined,
      format: 'YYYY-MM-DD',
      overallDuration: Math.round(this.to.diff(this.from, 'd', true))
    };

    if (calculatedFix.displayTo.isAfter(this.to)) {
      calculatedFix.displayTo = this.to;
    }

    const displayFromCandidate = this.recalcDisplayFrom(calculatedFix.displayTo, calculatedFix.duration);
    if (
      displayFromCandidate.isBefore(this.from)
      || (
        calculatedFix.duration === '1d'
        && 23 !== calculatedFix.displayTo.diff(displayFromCandidate, 'hour')
        )
    ) {
      calculatedFix.displayFrom = this.from;
      calculatedFix.displayTo = this.recalcDisplayTo(calculatedFix.displayFrom, calculatedFix.duration);
    } else {
      calculatedFix.displayFrom = displayFromCandidate;
    }

    calculatedFix.nextDisplayDelim = calculatedFix.displayTo.endOf('hour');
    calculatedFix.prevDisplayDelim = calculatedFix.displayTo.endOf('hour');

    switch (calculatedFix.duration) {
      case '4h':
        calculatedFix.nextDisplayDelim = calculatedFix.nextDisplayDelim.add(4, 'hour');
        calculatedFix.prevDisplayDelim = calculatedFix.prevDisplayDelim.subtract(4, 'hour');
        break;
      case '8h':
        calculatedFix.nextDisplayDelim = calculatedFix.nextDisplayDelim.add(8, 'hour');
        calculatedFix.prevDisplayDelim = calculatedFix.prevDisplayDelim.subtract(8, 'hour');
        break;
      case '12h':
        calculatedFix.nextDisplayDelim = calculatedFix.nextDisplayDelim.add(12, 'hour');
        calculatedFix.prevDisplayDelim = calculatedFix.prevDisplayDelim.subtract(12, 'hour');
        break;
      case '1d':
        calculatedFix.nextDisplayDelim = calculatedFix.nextDisplayDelim.add(1, 'd').endOf('d');
        calculatedFix.prevDisplayDelim = calculatedFix.prevDisplayDelim.subtract(1, 'd').endOf('d');
        break;
      case '3d':
        calculatedFix.nextDisplayDelim = calculatedFix.nextDisplayDelim.add(3, 'd').endOf('d');
        calculatedFix.prevDisplayDelim = calculatedFix.prevDisplayDelim.subtract(3, 'd').endOf('d');
        break;
      default:
        calculatedFix.nextDisplayDelim = calculatedFix.nextDisplayDelim.add(7, 'd').endOf('d');
        calculatedFix.prevDisplayDelim = calculatedFix.prevDisplayDelim.subtract(7, 'd').endOf('d');
        break;
    }

    calculatedFix.nextDisplayDelim = calculatedFix.displayTo.isSameOrAfter(this.to) ? null : calculatedFix.nextDisplayDelim;
    calculatedFix.prevDisplayDelim = calculatedFix.prevDisplayDelim.isBefore(this.from) ? null : calculatedFix.prevDisplayDelim;

    calculatedFix.firstDisplayDelim = this.recalcDisplayTo(this.from, calculatedFix.duration);

    calculatedFix.prevMonthDisplayDelim = calculatedFix.displayTo.subtract(1, 'month');
    calculatedFix.prevMonthDisplayDelim = calculatedFix.displayFrom.subtract(1, 'month').isBefore(this.from)
      ? null
      : calculatedFix.prevMonthDisplayDelim;
    calculatedFix.prevWeekDisplayDelim = calculatedFix.displayTo.subtract(1, 'week');
    calculatedFix.prevWeekDisplayDelim = calculatedFix.displayFrom.subtract(1, 'week').isBefore(this.from)
      ? null
      : calculatedFix.prevWeekDisplayDelim;
    calculatedFix.prevDayDisplayDelim = calculatedFix.displayTo.subtract(1, 'day');
    calculatedFix.prevDayDisplayDelim = calculatedFix.displayFrom.subtract(1, 'day').isBefore(this.from)
      ? null
      : calculatedFix.prevDayDisplayDelim;

    calculatedFix.nextDayDisplayDelim = calculatedFix.displayTo.add(1, 'day');
    calculatedFix.nextDayDisplayDelim = calculatedFix.nextDayDisplayDelim.isAfter(this.to) ? null : calculatedFix.nextDayDisplayDelim;
    calculatedFix.nextWeekDisplayDelim = calculatedFix.displayTo.add(1, 'week');
    calculatedFix.nextWeekDisplayDelim = calculatedFix.nextWeekDisplayDelim.isAfter(this.to) ? null : calculatedFix.nextWeekDisplayDelim;
    calculatedFix.nextMonthDisplayDelim = calculatedFix.displayTo.add(1, 'month');
    calculatedFix.nextMonthDisplayDelim = calculatedFix.nextMonthDisplayDelim.isAfter(this.to) ? null : calculatedFix.nextMonthDisplayDelim;

    calculatedFix.lastDisplayDelim = this.to.endOf('d').endOf('hour');

    calculatedFix.format = this.recalcFormat(calculatedFix.duration);
    Object.assign(this, calculatedFix);
  }

  public calcCenterOnDate(date: DAYJS): DAYJS {
    if (dayjs.isDayjs(date)) {
      let correctedDate: DAYJS = date.endOf('day').add(3, 'days');
      switch (this.duration) {
        case '4h':
          // correctedDate = date.endOf('day').subtract(24 - 4 - date.hour() + date.hour() % 4, 'hours');
          correctedDate = date.endOf('hour').add(1, 'hour');
          break;
        case '8h':
          // correctedDate = date.endOf('day').subtract(24 - 8 - date.hour() + date.hour() % 8, 'hours');
          correctedDate = date.endOf('hour').add(3, 'hour');
          break;
        case '12h':
          // correctedDate = date.endOf('day').subtract(24 - 12 - date.hour() + date.hour() % 12, 'hours');
          correctedDate = date.endOf('hour').add(5, 'hour');
          break;
        case '1d':
          correctedDate = date.endOf('day');
          break;
        case '3d':
          correctedDate = date.endOf('day').add(1, 'days');
          break;
      }

      return correctedDate;
    }
    return undefined;
  }
}
