import { Component, OnInit, Input } from '@angular/core';
import { CoolingTower } from 'src/app/shared/models/pump-room';

@Component({
  selector: 'ss-cooling-tower-tile',
  templateUrl: './cooling-tower-tile.component.html',
  styleUrls: []
})
export class CoolingTowerTileComponent implements OnInit {

  constructor() { }

  @Input()
  public coolingTower: CoolingTower;

  @Input()
  public selectedPipelineIds: string[] = [];

  ngOnInit() {
  }

}
