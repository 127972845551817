import { Component, Input } from '@angular/core';
import { AbstractDevice } from '../../models/abstract-device.model';

@Component({
  selector: 'form-select-option',
  template: '',
  styles: []
})
export class FormSelectOptionComponent {

  @Input()
  public label = '';

  @Input()
  public value: any = '';

  @Input()
  public disabled = false;

  @Input()
  public device: AbstractDevice;

  @Input()
  public deviceField: string;

}
