<div class="component" [ngClass]="{
  'highlighted': aerationLevel.isSelectedByDeviceIds(selectedPipelineIds),
  'breakdown': aerationLevel.isBreakdown
}">
  <div class="info-row">
      <svg class="icon">
          <use xlink:href='#icon-aeration-level' />
      </svg>
      <p>{{ aerationLevel.symbol }}</p>
      <p>{{ aerationLevel.typeDescription }}&nbsp;</p>
  </div>
  <div class="status-row">
      <ss-pump-room-detail-status [status]="aerationLevel.computedStatus"></ss-pump-room-detail-status>
  </div>
  <div class="status-row" *ngIf="aerationLevel.isManual">
    <ss-pump-room-detail-status status="working-in-manual-mode" class="w-100"></ss-pump-room-detail-status>
  </div>
</div>
