import { Component, OnInit, SimpleChanges, EventEmitter, Input, OnChanges, OnDestroy } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { ResortStatisticsService } from 'src/app/resort-statistics.service';
import { SettingsService } from 'src/app/settings.service';
import { Subscription } from 'rxjs';
import { TimedChartConfig, TimedChartConfigSeries, TimedChartData } from '../../../../../../../common';

@Component({
  selector: 'ss-d3-linked-mini-bar-chart',
  templateUrl: './linked-mini-bar-chart.component.html',
  styleUrls: ['./linked-mini-bar-chart.component.sass']
})
export class LinkedMiniBarChartComponent implements OnInit, OnChanges, OnDestroy {

  private static taggedChartMaxes: Map<string, Map<string, number>> = new Map();
  private static taggedChartMaxChanged: EventEmitter<string> = new EventEmitter();

  @Input()
  public device: { id: string };
  @Input()
  public duration = 12;
  @Input()
  public tag: string;
  @Input()
  public viewBoxWidth = 200;

  public data: TimedChartData;
  public config: TimedChartConfig;

  public max: number = null;

  private taggedChartMaxChangedSubscribtion: Subscription;
  private statsUpdatedSubscribtion: Subscription;
  private dataChange: EventEmitter<any> = new EventEmitter();

  public constructor(
    private settings: SettingsService,
    private resortStatisticsService: ResortStatisticsService
  ) {
    this.duration = this.settings.get('defaultChartPeriod');

    this.dataChange.pipe(debounceTime(100)).subscribe(() => {
      if(!this.statsUpdatedSubscribtion) {
        this.statsUpdatedSubscribtion = this.resortStatisticsService.statsUpdated.subscribe(() => {
          this.updateData();
        });
      }

      const rawData = this.getRawData();

      const preparedData = {
        labels: rawData.aggregatedLabels,
        spv: rawData.snowProductionVolume
      };

      this.config = {
        ... new TimedChartConfig(),
        xAxisType: 'invisible',
        yAxisType: 'none',
        series: [
          {
            ... new TimedChartConfigSeries(),
            seriesType: 'bar-w-rounded-top',
            seriesName: 'snow production volume',
            valueFieldName: 'spv',
            primaryColor: '#1BD374',
            secondaryColor: '#FD994C',
            fixedMin: 0,
            fixedMax: this.max,
          }
        ]
      };
      this.data = new TimedChartData(preparedData);
    });
  }

  private getRawData() {
    const duration = this.duration || this.settings.get('defaultChartPeriod');
    const rawData = this.resortStatisticsService.getStats(this.device.id, duration);
    this.pushMax(Math.max(...(rawData.snowProductionVolume || [])));
    return rawData;
  }

  private popMax() {
    if (this.taggedChartMaxChangedSubscribtion) {
      this.taggedChartMaxChangedSubscribtion.unsubscribe();
    }
    if (this.tag) {
      const mx = LinkedMiniBarChartComponent.taggedChartMaxes;
      if (mx.has(this.tag)) {
        if (mx.get(this.tag).has(this.device.id)) {
          mx.get(this.tag).delete(this.device.id);
        }
        if (mx.get(this.tag).size === 0) {
          mx.delete(this.tag);
        }
      }
      LinkedMiniBarChartComponent.taggedChartMaxChanged.emit(this.tag);
    }
  }

  private pushMax(max: number) {
    if (this.tag) {
      const mx = LinkedMiniBarChartComponent.taggedChartMaxes;
      if (!mx.has(this.tag)) {
        mx.set(this.tag, new Map());
      }
      mx.get(this.tag).set(this.device.id, max);

      LinkedMiniBarChartComponent.taggedChartMaxChanged.emit(this.tag);
    }
  }

  private updateData() {
    this.dataChange.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.viewBoxWidth
      || (changes.device
      && (
        changes.device.firstChange
        || changes.device.currentValue.id !== changes.device.previousValue.id
      ))
      || (changes.duration
      && (
        changes.duration.firstChange
        || changes.duration.previousValue !== changes.duration.currentValue
      ))
    ) {
      this.updateData();
    }
  }

  ngOnInit() {
    this.taggedChartMaxChangedSubscribtion = LinkedMiniBarChartComponent
      .taggedChartMaxChanged
      .pipe(debounceTime(25))
      .subscribe(() => {
        if (this.tag) {
          const mx = LinkedMiniBarChartComponent.taggedChartMaxes;
          if (mx.has(this.tag)) {
            const nuMax = Math.max(...Array.from(mx.get(this.tag).values()));;
            if(this.max !== nuMax) {
              this.max = nuMax;
              this.updateData();
            }
          }
        }
    });

    this.getRawData();
  }

  ngOnDestroy() {
    if (this.statsUpdatedSubscribtion) {
      this.statsUpdatedSubscribtion.unsubscribe();
    }

    this.popMax();
  }


}
