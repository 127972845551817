import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DragulaService } from 'ng2-dragula';
import { ReportDefinition } from '../../../../../../common';
import { HeaderService } from '../../../header.service';
import { ResortService } from '../../../resort.service';
import { ReportingService } from '../reporting.service';

@Component({
  selector: 'ss-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class ReportingDefinitionsListComponent implements OnInit {

  public readonly DG_REPORTS: string = 'DG_REPORTS';

  constructor(
    private titleService: HeaderService,
    private rs: ResortService,
    private route: ActivatedRoute,
    private rds: ReportingService,
    private dragulaService: DragulaService
  ) {
    if(!dragulaService.find(this.DG_REPORTS)) {
      dragulaService.createGroup(this.DG_REPORTS, {
        revertOnSpill: true,
        moves: (el, container, handle) => handle.className === 'col-auto handler report-handle'
      });
    }
  }

  standardDefinitions: ReportDefinition[] = [];
  customDefinitions: ReportDefinition[] = [];

  public reorderCustomDefinitions(customDefinitions: ReportDefinition[]) {
    this.rds.updateCustomDefinitionsOrder(customDefinitions).subscribe(() => {}, () => {});
  }

  ngOnInit(): void {
    this.titleService.setUpdateHeader('menuReports');

    this.route.data.subscribe((data: { definitions: ReportDefinition[] }) => {
      if(Array.isArray(data.definitions)) {
        this.standardDefinitions = data.definitions.filter(d => !d.isCustom).sort((a, b) =>
          a.priority !== b.priority
          ? a.priority - b.priority
          : `${a.reportName}`.localeCompare(`${b.reportName}`)
        );
        this.customDefinitions = data.definitions.filter(d => d.isCustom).sort((a, b) =>
          a.priority !== b.priority
          ? a.priority - b.priority
          : `${a.reportName}`.localeCompare(`${b.reportName}`)
        );
      }
    });
  }

}
