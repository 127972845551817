import { D3 } from '..';
import { dayjs } from '../..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';

export const getXTickFormatAndFunction = (state: D3DrawerChartState, min: number, max: number): {
  ticksFormat: string;
  ticksFilterFn: any;
} => {

  let ticksFilterFn = D3.utcHour.filter(d => dayjs(d).tz().hour() % 12 === 0);
  let ticksFormat = 'HH:mm';
  let maxTicks = Math.floor(state.viewBoxWidth / 40);
  let minsBetweenTicks = Math.abs(max - min) / 1000 / 60 / maxTicks;

  if(minsBetweenTicks >= 30) {
    maxTicks = Math.floor(state.viewBoxWidth / 25);
    minsBetweenTicks = Math.abs(max - min) / 1000 / 60 / maxTicks;
    ticksFormat = 'HH';
  }

  if (ticksFormat !== 'HH' && minsBetweenTicks < 10) {
    ticksFilterFn = D3.utcMinute.filter(d => dayjs(d).tz().minute() % 10 === 0);
  } else if (ticksFormat !== 'HH' && minsBetweenTicks < 15) {
    ticksFilterFn = D3.utcMinute.filter(d => dayjs(d).tz().minute() % 15 === 0);
  } else if (ticksFormat !== 'HH' && minsBetweenTicks < 30) {
    ticksFilterFn = D3.utcMinute.filter(d => dayjs(d).tz().minute() % 30 === 0);
  } else if (minsBetweenTicks < 60) {
    ticksFilterFn = D3.utcHour.every(1);
  } else if (minsBetweenTicks < 120) {
    ticksFilterFn = D3.utcHour.filter(d => dayjs(d).tz().hour() % 2 === 0);
  } else if (minsBetweenTicks < 180) {
    ticksFilterFn = D3.utcHour.filter(d => dayjs(d).tz().hour() % 3 === 0);
  } else if (minsBetweenTicks < 360) {
    ticksFilterFn = D3.utcHour.filter(d => dayjs(d).tz().hour() % 6 === 0);
  }
  return {
    ticksFormat,
    ticksFilterFn
  };
};
