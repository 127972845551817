<div class="cannon-state"
[ngClass]='{
  "cannon-state--bigest": size === "bigest",
  "cannon-state--big": size === "big",
  "cannon-state--mid": size === "mid",
  "cannon-state--small": size === "small",
  "cannon-state--smallest": size === "smallest",
  "cannon-state--highlighted": isHighlighted,
  "cannon-state--dimmed": isDimmed,

  "working": state === "working",
  "awaiting-for-conditions": state === "awaiting-for-conditions",
  "ready-to-work": state === "ready-to-work",
  "standing-by": state === "standing-by",
  "preparing-to-work": state === "preparing-to-work",
  "snoozing": state === "snoozing",
  "stopping": state === "stopping",
  "starting": state === "starting",
  "working-in-manual-mode": state === "working-in-manual-mode",
  "breakdown": state === "breakdown" || state === "connection-lost"
}'
  >
  <div class="cannon-state__circle">
    <div class="cannon-state__icon">
      <span class="cannon-state__label" [ngClass]="{'cannon-state__label--small': label && label.length > 10}">
        {{ label }}
      </span>
    </div>
  </div>
</div>
