import { Component, Valve, Pump, CoolingTower, ModeOfOperation } from './component.model';
import { AerationLevel, Compressor, Filter } from '.';
import { isArray } from '../../../../../../common';

class DataEl {
    symbol: string;
    group: string;
    order: number;
}

export class SimplifiedSchema {
    additionalModesOfOperation: ModeOfOperation[] = [];
    filters: Filter[] = [];
    liftingPumps: Pump[] = [];
    firstStagePumps: Pump[] = [];
    secondStagePumps: Pump[] = [];
    coolingTowerPumps: Pump[] = [];
    valves: Valve[] = [];
    coolingTowers: CoolingTower[] = [];

    compressors: Compressor[] = [];
    aerationLevels: AerationLevel[] = [];

    public static create(
        data: DataEl[],
        componentsBySymbol: Map<string, Component>
    ): SimplifiedSchema {
        const ret = new SimplifiedSchema();

        if (isArray(data)) {
            data = data || [];
            data = data.sort((a, b) => a['order'] > b['order'] ? 1 : -1);
            data.forEach(v => {
                if (isArray(ret[v['group']]) && componentsBySymbol.has(v['symbol']) ) {
                    ret[v['group']].push(componentsBySymbol.get(v['symbol']));
                }
            });
        }

        return ret;
    }
}
