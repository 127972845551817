<ng-container *ngIf="drawable.component.subType==='auto-bleed-valve'">
    <svg:use  width="101" height="101" xlink:href="#auto-bleed-valve" [ngStyle]="{
        transform: 'rotate(' + drawable.rotation + 'deg)'
    }"></svg:use>
    <svg:text x="50.5" y="83" dominant-baseline="middle" text-anchor="middle">{{ drawable.component.symbol }}</svg:text>
    <svg:text x="50.5" y="83" dominant-baseline="middle" text-anchor="middle">{{ drawable.component.symbol }}</svg:text>
    <svg:text x="50.5" y="83" dominant-baseline="middle" text-anchor="middle">{{ drawable.component.symbol }}</svg:text>
    <svg:text x="50.5" y="83" dominant-baseline="middle" text-anchor="middle">{{ drawable.component.symbol }}</svg:text>
</ng-container>

<ng-container *ngIf="drawable.component.subType==='emergency-dump'">
    <svg:use  width="101" height="101" xlink:href="#emergency-dump" [ngStyle]="{
        transform: 'rotate(' + drawable.rotation + 'deg)'
    }"></svg:use>
</ng-container>

<ng-container *ngIf="drawable.component.subType==='reservoir'">
    <svg:use width="200" height="150" xlink:href="#reservoir"></svg:use>
</ng-container>
