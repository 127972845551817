import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { EditorMapControllerService } from '../editor-map-controller.service';
import { ResortService } from 'src/app/resort.service';
import { ImageOverlay, featureGroup, Marker, divIcon, FeatureGroup } from 'leaflet';
import { Resort, ConnectionPoint, ElectricalLine, AirPipeline, WaterPipeline, ResortArea, CommunicationLine } from 'src/app/shared/models';

class CPTuple {
  public m: Marker;
  public c: ConnectionPoint;

  static create(cp: ConnectionPoint): CPTuple {
    const ret = new CPTuple();
    ret.c = Object.assign(new ConnectionPoint(), cp);
    ret.m = new Marker(ret.c.position, {
      bubblingMouseEvents: true,
    });
    ret.m['tupleRef'] = ret;
    ret.updateMarker();
    return ret;
  }

  updateMarker() {
    this.m.setIcon(divIcon({
      className: 'cannon-state cannon-state--squared ',
      html: `<div class="cannon-state__circle absolute-on-the-map">
        <div class="cannon-state__icon">
          <span class="cannon-state__label">` + this.c.symbol + '</span></div></div>'
    }));
    this.m.setLatLng(this.c.position);
  }
}

@Component({
  selector: 'ss-connection-points',
  templateUrl: './connection-points.component.html',
  styleUrls: ['./connection-points.component.sass']
})
export class EditConnectionPointsComponent implements OnInit, OnDestroy {

  constructor(
    private mapCtrl: EditorMapControllerService,
    private resortService: ResortService
  ) { }

  image: ImageOverlay;
  resort: Resort;

  pointMarkers: FeatureGroup;
  connectionPoints: CPTuple[] = [];

  areas: ResortArea[] = [];
  waterPipelines: WaterPipeline[] = [];
  airPilelines: AirPipeline[] = [];
  powerLines: ElectricalLine[] = [];
  communicationLines: CommunicationLine[] = [];

  selectedArea: ResortArea;
  selectedWaterPipeline: WaterPipeline;
  selectedAirPileline: AirPipeline;
  selectedPowerLine: ElectricalLine;
  selectedCommunicationLine: CommunicationLine;

  editedConnectionPoint: CPTuple;
  editedSymbol: string;
  editedPosition: any;

  ngOnInit() {
    this.resort = this.resortService.getResort();
    this.image = this.mapCtrl.createProjectImage(this.resort.mapBoundaries);
    this.connectionPoints = this.resortService.getConnectionPoints().map(cp => CPTuple.create(cp));

    this.areas = this.resortService.getResortAreas();
    this.waterPipelines = this.resortService.getWaterPipelines();
    this.airPilelines = this.resortService.getAirPipelines();
    this.powerLines = this.resortService.getElectricalLines();
    this.communicationLines = this.resortService.getCommunicationLines();

    if (this.areas.length > 0) {
      this.selectedArea = this.areas[0];
    }
    if (this.waterPipelines.length > 0) {
      this.selectedWaterPipeline = this.waterPipelines[0];
    }
    if (this.airPilelines.length > 0) {
      this.selectedAirPileline = this.airPilelines[0];
    }
    if (this.powerLines.length > 0) {
      this.selectedPowerLine = this.powerLines[0];
    }
    if (this.communicationLines.length > 0) {
      this.selectedCommunicationLine = this.communicationLines[0];
    }

    this.mapCtrl.map.on('draw:editmove', (ev) => {
      if (ev['layer'] === this.editedConnectionPoint.m) {
        this.editedPosition = [ev['layer'].getLatLng().lat, ev['layer'].getLatLng().lng];
      }
    });

    this.updatePoinntMarkers();
  }
  public edit(cpt: CPTuple) {
    this.cancelEdit();
    this.editedConnectionPoint = cpt;
    this.editedSymbol = cpt.c.symbol;
    setTimeout(() => {
      this.editedConnectionPoint.m['editing'].enable();
    }, 100);
  }

  saveEdit() {
    this.editedConnectionPoint.c.symbol = this.editedSymbol;
    if (this.selectedArea) {
      this.editedConnectionPoint.c.resortArea = this.selectedArea.id;
    }
    if (this.selectedWaterPipeline) {
      this.editedConnectionPoint.c.waterPipeline = this.selectedWaterPipeline.id;
    }
    if (this.selectedAirPileline) {
      this.editedConnectionPoint.c.airPipeline = this.selectedAirPileline.id;
    }
    if (this.selectedPowerLine) {
      this.editedConnectionPoint.c.electricalLine = this.selectedPowerLine.id;
    }
    if (this.selectedCommunicationLine) {
      this.editedConnectionPoint.c.communicationLine = this.selectedCommunicationLine.id;
    }
    if (this.editedPosition) {
      this.editedConnectionPoint.c.position = this.editedPosition;
    }
    this.cancelEdit();
  }

  cancelEdit() {
    if (this.editedConnectionPoint) {
      this.editedConnectionPoint.updateMarker();
      this.editedConnectionPoint.m['editing'].disable();
    }
    this.editedSymbol = null;
    this.editedPosition = null;
    this.editedConnectionPoint = null;
  }

  add() {
    let maxSymbol = 0;
    this.connectionPoints.forEach(ccp => {
      const i = parseInt(ccp.c.symbol, 10);
      if (maxSymbol < i) {
        maxSymbol = i;
      }
    });
    const cp = new ConnectionPoint();
    cp.update({
      symbol: maxSymbol ? maxSymbol + 1 : 'New',
      position: this.mapCtrl.map.getCenter()
    });
    const cpt = CPTuple.create(cp);
    this.connectionPoints.push(cpt);

    this.updatePoinntMarkers();

    this.edit(cpt);
  }

  private updatePoinntMarkers() {
    this.pointMarkers = featureGroup(this.connectionPoints.map(cp => cp.m));
    this.pointMarkers.on('click', (ev) => {
      this.edit(ev['layer'].tupleRef);
    });
    this.mapCtrl.updateWorkingLayer(featureGroup([
      this.image,
      this.pointMarkers
    ]));
  }

  save() {
    if (this.editedConnectionPoint) {
      return;
    }
    this.connectionPoints.map(cp => {
      console.log(cp.c.symbol + ' [' + cp.c.position[0] + ',' + cp.c.position[1] + ']'
      + ' ' + cp.c.resortArea
      + ' ' + cp.c.waterPipeline
      + ' ' + cp.c.airPipeline
      + ' ' + cp.c.electricalLine
      + ' ' + cp.c.communicationLine
      );
    });
  }

  ngOnDestroy() {
    this.mapCtrl.resetWorkingLayer();
    this.mapCtrl.map.off('draw:editmove');
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      if (this.editedConnectionPoint) {
        this.cancelEdit();
        event.preventDefault();
      }
    } else if (event.code === 'KeyS' && event.ctrlKey && event.shiftKey) {
      this.save();
    } else if (event.code === 'KeyS' && event.ctrlKey) {
      if (this.editedConnectionPoint) {
        this.saveEdit();
        event.preventDefault();
      }
    } else if (event.code === 'KeyA' && event.ctrlKey) {
      if (!this.editedConnectionPoint) {
        this.add();
        event.preventDefault();
      }
    }
  }
}
