import { Component } from '@angular/core';

@Component({
  selector: 'form-label',
  template: '<ng-content></ng-content>',
  styles: []
})
export class FormLabelComponent {

}
