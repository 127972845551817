<form-text [(ngModel)]="filter" label="Filter fields" placeholder="that include this value..."></form-text>

<table class="debug-table">
    <tr>
        <th>Object key</th>
        <td>Object value</td>
    </tr>
    <tr *ngFor="let k of keys">
        <th>{{ k }}</th>
        <td>{{ data[k] }}</td>
    </tr>
    <ng-container *ngIf="extraParameterKeys.length > 0">
        <tr>
            <th colspan="2">extraParameters</th>
        </tr>
        <tr *ngFor="let k of extraParameterKeys">
            <th>{{ k }}</th>
            <td>{{ data['extraParameters'][k] }}</td>
        </tr>
    </ng-container>
</table>
