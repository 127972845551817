import { Component, Input } from '@angular/core';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { AbstractSnowCannonComponentButtonComponent } from '../abstract-snow-cannon-component-button.component';

@Component({
  selector: 'ss-snow-cannon-component-button-nozzle-row',
  templateUrl: './component.html',
  styleUrls: []
})
export class SnowCannonComponentButtonNozzleRowComponent extends AbstractSnowCannonComponentButtonComponent {

  @Input()
  public rowNumber: number;

  constructor(cs: ConfigureClientService) {
    super(cs);
  }

}
