<ng-container *ngIf="drawable.component.currentDrawVar">
    <svg:use  width="101" height="101" [ngStyle]="{
        transform: drawable.rot
    }" xlink:href="#pump"></svg:use>
    <svg:g *ngIf="drawable.component.subType==='vfd'" transform="translate(-20 50) rotate(-45)">
        <svg:rect x="0" y="50" width="101" height="1"></svg:rect>
        <svg:rect x="101" y="50" width="5" height="1" style="transform: rotate(-135deg); transform-origin: 101px 50.5px"></svg:rect>
        <svg:rect x="101" y="50" width="5" height="1" style="transform: rotate(135deg); transform-origin: 101px 50.5px"></svg:rect>
    </svg:g>
</ng-container>
<ng-container *ngIf="!drawable.component.currentDrawVar">
    <svg:use  width="101" height="101" [ngStyle]="{
        transform: drawable.rot
    }" xlink:href="#pump-no-sensors"></svg:use>
</ng-container>


<ng-container *ngIf="drawable.r">
    <svg:line *ngIf="drawable.component.isWorking" x1="30" y1="50.5" x2="81" y2="50.5" class="open"></svg:line>
    <svg:text x="44" y="52" dominant-baseline="middle" text-anchor="start">{{ drawable.component.symbol }}</svg:text>
    <ng-container *ngIf="drawable.component.currentDrawVar">
        <svg:g transform="translate(-17 35)">
            <svg:text x="50.5" y="52.5" dominant-baseline="middle" text-anchor="middle">TSH</svg:text>
        </svg:g>
        <svg:g transform="translate(-17 -35)">
            <svg:rect x="35" y="50" width="31" height="1"></svg:rect>
            <svg:text x="50.5" y="48.5" dominant-baseline="baseline" text-anchor="middle">{{ drawable.component.currentDrawSensorModule }}</svg:text>
            <svg:text x="50.5" y="52.5" dominant-baseline="hanging" text-anchor="middle">{{ drawable.component.currentDrawSensorNumber }}</svg:text>
        </svg:g>
        <svg:text x="52" y="20" dominant-baseline="middle" text-anchor="start">{{ drawable.component.currentDraw|number }} A</svg:text>
    </ng-container>

    <svg:text x="52" y="85" *ngIf="drawable.component.vfdFrequencyVar"
    dominant-baseline="middle" text-anchor="start">{{ drawable.component.vfdFrequency|number }} Hz</svg:text>
</ng-container>

<ng-container *ngIf="drawable.l">
    <svg:line *ngIf="drawable.component.isWorking" x1="20" y1="50.5" x2="71" y2="50.5" class="open"></svg:line>
    <svg:text x="56" y="50.5" dominant-baseline="middle" text-anchor="end">{{ drawable.component.symbol }}</svg:text>
    <ng-container *ngIf="drawable.component.currentDrawVar">
        <svg:g transform="translate(17 35)">
            <svg:text x="50.5" y="52.5" dominant-baseline="middle" text-anchor="middle">TSH</svg:text>
        </svg:g>
        <svg:g transform="translate(17 -35)">
            <svg:rect x="35" y="50" width="31" height="1"></svg:rect>
            <svg:text x="50.5" y="48.5" dominant-baseline="baseline" text-anchor="middle">{{ drawable.component.currentDrawSensorModule }}</svg:text>
            <svg:text x="50.5" y="52.5" dominant-baseline="hanging" text-anchor="middle">{{ drawable.component.currentDrawSensorNumber }}</svg:text>
        </svg:g>
        <svg:text x="49" y="20" dominant-baseline="middle" text-anchor="end">{{ drawable.component.currentDraw|number }} A</svg:text>
    </ng-container>

    <svg:text x="49" y="85" *ngIf="drawable.component.vfdFrequencyVar"
        dominant-baseline="middle" text-anchor="end">{{ drawable.component.vfdFrequency|number }} Hz</svg:text>
</ng-container>

<ng-container *ngIf="drawable.d">
    <svg:line *ngIf="drawable.component.isWorking" x1="50.5" y1="30" x2="50.5" y2="81" class="open"></svg:line>
    <svg:text x="50.5" y="51.5" dominant-baseline="middle" text-anchor="middle">{{ drawable.component.symbol }}</svg:text>
    <ng-container *ngIf="drawable.component.currentDrawVar">
        <svg:g transform="translate(35 -17)">
            <svg:text x="50.5" y="52.5" dominant-baseline="middle" text-anchor="middle">TSH</svg:text>
        </svg:g>
        <svg:g transform="translate(-35 -17)">
            <svg:rect x="35" y="50" width="31" height="1"></svg:rect>
            <svg:text x="50.5" y="48.5" dominant-baseline="baseline" text-anchor="middle">{{ drawable.component.currentDrawSensorModule }}</svg:text>
            <svg:text x="50.5" y="52.5" dominant-baseline="hanging" text-anchor="middle">{{ drawable.component.currentDrawSensorNumber }}</svg:text>
        </svg:g>
        <svg:text x="23" y="60" dominant-baseline="middle" text-anchor="end">{{ drawable.component.currentDraw|number }} A</svg:text>
    </ng-container>

    <svg:text x="78" y="60" *ngIf="drawable.component.vfdFrequencyVar"
        dominant-baseline="middle" text-anchor="start">{{ drawable.component.vfdFrequency|number }} Hz</svg:text>
</ng-container>

<ng-container *ngIf="drawable.u">
    <svg:line *ngIf="drawable.component.isWorking" x1="50.5" y1="20" x2="50.5" y2="71" class="open"></svg:line>
    <svg:text x="50.5" y="54" dominant-baseline="middle" text-anchor="middle">{{ drawable.component.symbol }}</svg:text>
    <ng-container *ngIf="drawable.component.currentDrawVar">
        <svg:g transform="translate(35 17)">
            <svg:text x="50.5" y="52.5" dominant-baseline="middle" text-anchor="middle">TSH</svg:text>
        </svg:g>
        <svg:g transform="translate(-35 17)">
            <svg:rect x="35" y="50" width="31" height="1"></svg:rect>
            <svg:text x="50.5" y="48.5" dominant-baseline="baseline" text-anchor="middle">{{ drawable.component.currentDrawSensorModule }}</svg:text>
            <svg:text x="50.5" y="52.5" dominant-baseline="hanging" text-anchor="middle">{{ drawable.component.currentDrawSensorNumber }}</svg:text>
        </svg:g>
        <svg:text x="23" y="47" dominant-baseline="middle" text-anchor="end">{{ drawable.component.currentDraw|number }} A</svg:text>
    </ng-container>

    <svg:text x="78" y="47" *ngIf="drawable.component.vfdFrequencyVar"
        dominant-baseline="middle" text-anchor="start">{{ drawable.component.vfdFrequency|number }} Hz</svg:text>
</ng-container>

