<ss-svg-symbols></ss-svg-symbols>

<div class="window-buttons" *ngIf="showWindowButtons">
  <button class="minimalize" (click)="toggleFullscreen()">
      <svg>
          <use xlink:href='#icon-window-fullscreen'/>
      </svg>
  </button>
</div>


<div class="disconnection__blocker" [ngClass]="{ 'disconnected': isDisconnected }">
    <div class="disconnection__blocker__information">
      <h1 i18n="Server disconnected|Tytuł komunikatu błędu aplikacji - rozłączono@@appErrotDisconnectedTitle">Server disconnected</h1>
      <p i18n="Application is trying to reconnect to server...|Opis komunikatu błędu aplikacji - rozłączono, trwa oczekiwanie na nawiązanie połączenia@@appErrotDisconnectedDescription">Application is trying to reconnect to server...</p>
      <p>
        <button (click)="restartApp()" class="btn btn-success" i18n="Restart application|Przycisk restart komunikatu błędu aplikacji - rozłączono@@appErrotDisconnectedRestartButton">Restart application</button>
      </p>
    </div>
</div>
<div class="navigation__blocker" [ngClass]="{ 'in-progress': isRoutingInProgress, 'done': isRoutingDone }"><div class="progress-bar"><div class="progress-bar-tip"></div></div></div>

<router-outlet></router-outlet>
