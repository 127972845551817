import { Drawable, DrawableConnector, DrawableComponent, DrawableText, DrawableLine } from './drawable.model';
import { Component } from './component.model';
import { isArray, isString } from '../../../../../../common';

class DataEl {
    type: string;
    from: [number, number];
}

export class FullSchema {
    public get lines(): DrawableLine[] {
        return this.all.filter(el => el.type === 'line') as DrawableLine[];
    }
    public get texts(): DrawableText[] {
        return this.all.filter(el => el.type === 'text') as DrawableText[];
    }
    public get connectors(): DrawableConnector[] {
        return this.all.filter(el => el.type === 'connector') as DrawableConnector[];
    }
    public get components(): DrawableComponent<any>[] {
        return this.all.filter(el => el.type === 'component') as DrawableComponent<any>[];
    }

    public all: Drawable[] = [];

    public static create(data: DataEl[], componentsBySymbol: Map<string, Component>): FullSchema {
        const ret = new FullSchema();

        if (isArray(data)) {
            data.forEach(v => {
                ret.all.push(Drawable.create(v, componentsBySymbol));
            });

            ret.connectors.forEach(con => {
                if (isString(con.toComponent) && con.toComponent) {
                    con.toComponentRef = ret.components.find(com => com.symbol === con.toComponent);
                }
                if (isString(con.fromComponent) && con.fromComponent) {
                    con.fromComponentRef = ret.components.find(com => com.symbol === con.fromComponent);
                }
            });
        }

        return ret;
    }

    public buildEntries(): DataEl[] {
        const entries = [];
        this.all.forEach(e => {
            const entry = new DataEl();
            entry.type = e.type;
            entry.from = e.from;

            switch (e.type) {
                case 'text':
                    entry['label'] = e['label'];
                    break;
                case 'line':
                    entry['to'] = e['to'];
                    break;
                case 'connector':
                    entry['to'] = e['to'];
                    entry['fromComponent'] = e['fromComponent'];
                    entry['toComponent'] = e['toComponent'];
                    break;
                case 'component':
                    entry['symbol'] = e['symbol'];
                    entry['rotation'] = e['rotation'];
                    break;
            }
            entries.push(entry);
        });
        return entries;
    }

}
