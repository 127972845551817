<ss-page (scrollY)="onScrollY($event)">
    <ss-page-header>
        <ss-page-header-title>Pumo room full schema editor for {{ pumpRoom.symbol }}</ss-page-header-title>

        <ss-page-button-group>
            <button
                (click)="save()"
                class="btn btn-primary">Save <kbd>Ctrl+Shift+S</kbd></button>
            <a class="btn btn-default" [routerLink]="['/application', 'editor']">Go back</a>
        </ss-page-button-group>
    </ss-page-header>
    <ss-page-panel [ngClass]="{ 'detached-toolbar': shouldToolbarDetach }">
        <ss-full-schema [allowEdit]="true" [pumpRoom]="pumpRoom"></ss-full-schema>
    </ss-page-panel>
    <ss-page-footer>
        <div></div>
        <ss-page-button-group>
            <button
                (click)="save()"
                class="btn btn-primary">Save <kbd>Ctrl+Shift+S</kbd></button>
            <a class="btn btn-default" [routerLink]="['/application', 'editor']">Go back</a>
        </ss-page-button-group>
    </ss-page-footer>
</ss-page>
