import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigureClientService } from '../../../../http-clients/configure.client.service';
import { ResortService } from '../../../../resort.service';
import { PowerSource } from '../../../../shared/models';
import { ModalRouteService } from '../../../modal-route.service';

@Component({
  selector: 'ss-configure-modal',
  templateUrl: './configure-modal.component.html',
  styleUrls: ['./configure-modal.component.sass']
})
export class ConfigurePowerSourceModalComponent implements OnInit {

  constructor(
    private rs: ResortService,
    private cs: ConfigureClientService,
    private ar: ActivatedRoute,
    private mr: ModalRouteService
  ) { }

  public powerSource: PowerSource;
  public limitPowerDraw: number;
  public isLimitPowerDrawEnabled: boolean;

  ngOnInit(): void {
    this.ar.paramMap.subscribe(params => {
      const id = params.get('powerSourceId');

      this.powerSource = this.rs.getPowerSource(id);

      if (!this.powerSource) {
        this.mr.closeModal();
        return;
      }

      this.limitPowerDraw = this.powerSource.limitPowerDraw;
      this.isLimitPowerDrawEnabled = this.powerSource.limitPowerDraw > 0;
    });

  }

  save() {
    this.cs.configurePowerSource(this.powerSource, {
      limitPowerDraw: this.isLimitPowerDrawEnabled ? this.limitPowerDraw : 0
    }).subscribe(() => {
      this.close();
    });
  }

  close() {
    this.mr.closeModal();
  }
}
