import { D3 } from '..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { D3DrawerData } from '../../models/charts/d3-drawer-data.model';
import { getYTickFormat } from './get-y-tick-format.function';
import { TimedChartConfigSeries } from '../..';

export const drawYAxisLineDefault = (
  state: D3DrawerChartState,
  data: D3DrawerData,
  series: TimedChartConfigSeries,
  seriesIndex: number,
  min: number,
  max: number,
  draw: 'left'|'right'|'no'
): D3DrawerChartState => {

  if(series.fixedMin && !isNaN(series.fixedMin)) {
    min = Math.min(series.fixedMin, min);
  }
  if(series.fixedMax && !isNaN(series.fixedMax)) {
    max = Math.max(series.fixedMax, max);
  }
  min = isNaN(min) ? 0 : min;
  max = isNaN(max) || max < min ? 0 : max;
  if (min === 0 && max === 0) {
    max = 1;
  } else if(min === max) {
    min -= 2;
    max += 2;
  }
  min = Math.floor(min);
  max = Math.ceil(max);

  let range: [number, number] = [state.viewBoxHeight - 5, state.config.legend ? 25 : 5];
  if(state.config.xAxisType !== 'invisible') {
    range = [state.viewBoxHeight - 15, state.config.legend ? 30 : 10];
  }

  let linesYAxisScale = state.generatedValuesStorage.get(`series${seriesIndex}YAxisScale`);
  if (!linesYAxisScale) {
    linesYAxisScale = D3.scaleLinear();
    state.generatedValuesStorage.set(`series${seriesIndex}YAxisScale`, linesYAxisScale);
  }

  linesYAxisScale
    .domain([ min, max ])
    .nice(4)
    .range(range);

  if(draw === 'no') {
    return state;
  }

  let linesYAxis = state.generatedValuesStorage.get(`series${seriesIndex}YAxis`);
  if(!linesYAxis) {
    if(draw === 'left') {
      linesYAxis = D3.axisLeft(linesYAxisScale);
    } else {
      linesYAxis = D3.axisRight(linesYAxisScale);
    }
    state.generatedValuesStorage.set(`series${seriesIndex}YAxis`, linesYAxis);
  }
  const format = series.valueFormatter || getYTickFormat;
  linesYAxis
    .ticks(4)
    .tickSize(5)
    .tickPadding(10)
    .tickFormat((val, ind) => format(val, state, series));

  let linesYAxisGroup = state.generatedCanvas.select(`.y-axis-series${seriesIndex}`);
  if(linesYAxisGroup.empty()) {
    linesYAxisGroup = state.generatedCanvas.append('g');
  }
  linesYAxisGroup
    .attr('class', `y-axis-series${seriesIndex}`)
    .attr('stroke-width', 0)
    .style('color', '#B9BBC8')
    .style('font-size', '12')
    .style('font-family', 'Montserrat')
    .call(linesYAxis);
  if(state.config.yAxisType === 'single-with-line' || state.config.yAxisType === 'double-with-line') {
    linesYAxisGroup.selectAll('path').attr('stroke-width', 1);
    linesYAxisGroup.selectAll('line').attr('stroke-width', 1);
  }

  if(draw === 'left') {
    linesYAxisGroup.attr('transform', 'translate(' + 85 + ',0)');
  } else {
    linesYAxisGroup.attr('transform', 'translate(' + (state.viewBoxWidth - 85) + ',0)');
  }
  linesYAxisGroup.call(linesYAxis);

  return state;
};
