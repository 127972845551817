import { ReportSeries } from '..';
import { ReportDefinition } from '../definition';
import { ReportPart } from '../part';

export const snowCannonDefinition: ReportDefinition = ReportDefinition.create({
  id: 'snow-cannon-definition',
  reportName: $localize`:Domyślna refinicja raportu - nazwa polaRaport pracy armatki|
    @@reportingDefaultDefinitionSnowCannonTitleSnowgunReport:Raport pracy armatki`,
  duration: '7d',
  precision: '1h',
  parts: [
    ReportPart.create({
      partType: 'sum',
      partName: $localize`:Domyślna refinicja raportu - nazwa polaCałkowita produkcja śniegu|
        @@reportingDefaultDefinitionSnowCannonFieldNameOverallSnowProduction:Całkowita produkcja śniegu`,
      series: [
        ReportSeries.create({
          subjectType: 'SnowCannon',
          subjectField: 'snowProductionVolume'
        })
      ]
    }),
    ReportPart.create({
      partType: 'sum',
      partName: $localize`:Domyślna refinicja raportu - nazwa polaCałkowity pobór wody|
        @@reportingDefaultDefinitionSnowCannonFieldNameOverallWaterConsumption:Całkowity pobór wody`,
      series: [
        ReportSeries.create({
          subjectType: 'SnowCannon',
          subjectField: 'waterFlow'
        })
      ]
    }),
    ReportPart.create({
      partType: 'sum',
      partName: $localize`:Domyślna refinicja raportu - nazwa polaCałkowity pobór prądu|
        @@reportingDefaultDefinitionSnowCannonFieldNameOverallPowerConsumption:Całkowity pobór prądu`,
      series: [
        ReportSeries.create({
          subjectType: 'SnowCannon',
          subjectField: 'powerConsumption'
        })
      ]
    }),
    ReportPart.create({
      partType: 'avg',
      partName: $localize`:Domyślna refinicja raportu - nazwa polaŚrednia temperatura|
        @@reportingDefaultDefinitionSnowCannonFieldNameAverageTemperature:Średnia temperatura`,
      series: [
        ReportSeries.create({
          subjectType: 'SnowCannon',
          subjectField: 'wetBulbTemperature'
        })
      ]
    }),
    ReportPart.create({
      partName: $localize`:Domyślna refinicja raportu - nazwa polaTWB i produkcja śniegu|
        @@reportingDefaultDefinitionSnowCannonFieldNameWetBulbTempAndSnowProductionChart:TWB i produkcja śniegu`,
      partType: 'chart',
      series: [
        ReportSeries.create({
          subjectType: 'SnowCannon',
          subjectField: 'snowProductionVolume'
        }),
        ReportSeries.create({
          subjectType: 'SnowCannon',
          subjectField: 'wetBulbTemperature'
        })
      ]
    }),
    ReportPart.create({
      partName: $localize`:Domyślna refinicja raportu - nazwa polaPrzepływ i ciśnienie wody|
        @@reportingDefaultDefinitionSnowCannonFieldNameWaterFlowAndPressureChart:Przepływ i ciśnienie wody`,
      partType: 'chart',
      series: [
        ReportSeries.create({
          subjectType: 'SnowCannon',
          subjectField: 'waterPressure'
        }),
        ReportSeries.create({
          subjectType: 'SnowCannon',
          subjectField: 'waterFlow'
        }),
      ]
    })
  ],
  isCustom: false,
  isLive: false,
  priority: -10
});
