<div class="number-input" [ngClass]="{
  'number-input--small': size === 'small'
}">
  <button [disabled]="disabled" (click)="decrease()" class="number-input__btn number-input__btn--prev">
      <svg>
        <use xlink:href='#icon-chevron-down'/>
      </svg>
  </button>
  <div class="number-input__box" [ngClass]="{'number-dropdown': size === 'large'}">


    <input [disabled]="disabled" type="number" [value]="hour|number:'2.0-0'" [ngClass]="{'active': !minutesHasFocus}" (change)="inputHoursChanged($event)" (focus)="minutesHasFocus = false"/>:<input [disabled]="disabled" type="number" [value]="minute|number:'2.0-0'" [ngClass]="{'active': minutesHasFocus}" (change)="inputMinutesChanged($event)" (focus)="minutesHasFocus = true"/>


  </div>
  <button [disabled]="disabled" (click)="increase()" class="number-input__btn number-input__btn--next">
      <svg>
        <use xlink:href='#icon-chevron-down'/>
      </svg>
  </button>
</div>
