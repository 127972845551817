<div class="block" [ngClass]="{
    'show-overflow': showOverflow,
    'show': expand
}">
    <div class="header">
        <div class="header-content">
            <div>
                <ng-content select="ss-page-accordion-block-header"></ng-content>
            </div>
        </div>
        <button class="header-button" (click)="toggleExpanded()">
          <svg>
            <use xlink:href='#icon-chevron-down' />
          </svg>
        </button>
    </div>

    <div class="body">
        <div class="body-content">
            <ng-content></ng-content>
        </div>
    </div>

</div>
