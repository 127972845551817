import { Component, OnChanges, Input, EventEmitter, ElementRef, SimpleChanges, Inject, LOCALE_ID } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { DAYJS, jsonEqual, TimedChartConfig, TimedChartData } from '../../../../../../common';
import { UnitConverterService } from '../../../unit-converter.service';
import { SettingsService } from '../../../settings.service';
import { D3DrawerChartState, drawFloatingToolTip, updateChart } from '../../../../../../common/d3';

@Component({
  selector: 'ss-d3-chart-drawer',
  template: '',
  styleUrls: []
})
export class D3ChartDrawerComponent implements OnChanges {

  @Input()
  public data: TimedChartData;
  @Input()
  public config: TimedChartConfig;

  @Input()
  public viewBoxHeight = 40;

  @Input()
  public viewBoxWidth = 200;

  @Input()
  public selectedDate: DAYJS;

  private dataChange: EventEmitter<any> = new EventEmitter();

  private chartState: D3DrawerChartState = new D3DrawerChartState();

  constructor(
    private elRef: ElementRef,
    private ucs: UnitConverterService,
    private settingsService: SettingsService,
    @Inject(LOCALE_ID) private locale
  ) {
    const hostEl = this.elRef.nativeElement;

    this.dataChange.pipe(debounceTime(10)).subscribe(() => {
      if(
        !this.data
        || !this.config
        || !this.config.series
        || this.config.series.length === 0
      ) {
        return;
      }

      if(!this.config.animationTime) {
        this.config = {
          ...this.config,
          animationTime: this.settingsService.get('animationSpeed')
        };
      }
      if(!this.config.unitSet) {
        this.config = {
          ...this.config,
          locale: this.locale,
          unitSet: this.ucs.getUnitSet()
        };
      }
      this.chartState = updateChart({
        ...this.chartState,
        hostEl,
        viewBoxWidth: this.viewBoxWidth,
        viewBoxHeight: this.viewBoxHeight,
        config: this.config
      }, this.data);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      ( changes.data && !jsonEqual(changes.data.currentValue, changes.data.previousValue) )
      || ( changes.config && !jsonEqual(changes.config.currentValue, changes.config.previousValue) )
      || ( changes.viewBoxHeight && changes.viewBoxHeight.currentValue !== changes.viewBoxHeight.previousValue )
      || ( changes.viewBoxWidth && changes.viewBoxWidth.currentValue !== changes.viewBoxWidth.previousValue )
    ) {
      this.dataChange.emit();
    }

    if(changes.selectedDate) {
      if(
        !this.data
        || !this.config
      ) {
        return;
      }

      this.chartState = drawFloatingToolTip({
        ...this.chartState,
        selectedDate: changes.selectedDate.currentValue
      });
    }
  }

}
