import { Directive, ElementRef, Input } from '@angular/core';
import { AuthService } from './auth.service';
import { ResortConfigurationService } from './resort-configuration.service';
import { ResortConfigurationChecks } from './shared/models';

@Directive({
  selector: '[ssdDisableIfNotGranted]'
})
export class DisableIfNotGrantedDirective {

  private elRef: HTMLElement;

  private requiresRole: string;
  @Input()
  public set ssdDisableIfNotGranted(val: string) {
    this.requiresRole = val;
    this.check();
  }
  public get ssdDisableIfNotGranted(): string {
    return this.requiresRole;
  }

  private _requiresCapability: ResortConfigurationChecks;
  @Input()
  public set requiresCapability(v: ResortConfigurationChecks) {
    this._requiresCapability = v;
    this.check();
  }
  public get requiresCapability(): ResortConfigurationChecks {
    return this._requiresCapability;
  }

  private check() {
    if (
      this.as.isAuthorized(this.requiresRole)
      && (
        !this.requiresCapability
        || this.rcs.checkCapability(this.requiresCapability)
      )
    ) {
      this.makeItEnabled();
    } else {
      this.makeItDisabled();
    }
  }

  private makeItDisabled() {
      this.elRef.setAttribute('disabled', 'disabled');
      this.elRef.style.pointerEvents = 'none';
      this.elRef.classList.add('disabled');
  }

  private makeItEnabled() {
      this.elRef.removeAttribute('disabled');
      this.elRef.style.pointerEvents = 'auto';
      this.elRef.classList.remove('disabled');
  }

  constructor(
    private el: ElementRef,
    private as: AuthService,
    private rcs: ResortConfigurationService
  ) {
    this.elRef = this.el.nativeElement;
  }

}
