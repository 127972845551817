import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndpointMapService } from '../endpoint-map.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class DevicesClientService {

  constructor(private e: EndpointMapService, private http: HttpClient) {}

  public get(id: string): Observable<any> {
    return this.http.get(this.e.devices + '/' + id, httpOptions);
  }

  public getByIds(ids: string[]): Observable<any> {
    return this.http.post(this.e.devices, ids, httpOptions);
  }

  public save(id: string, params: any): Observable<any> {
    return this.http.post(this.e.devices + '/' + id, params, httpOptions);
  }




  public getStats(ids: string[], period: number = 12): Observable<any> {
    const options = { ... httpOptions };
    options['params'] = { period };
    return this.http.post(this.e.deviceStatistics, ids, options);
  }




  public getSeasonLimits(ids: string[], period: number = 10): Observable<any> {
    const options = { ... httpOptions };
    options['params'] = { period };
    return this.http.post(this.e.deviceSeasonLimits, ids, options);
  }

  public getSnowCannonPositionHistory(id: string, limit: number = 72, offset: number = 0) {
    const options = { ... httpOptions };
    options['params'] = {
      limit,
      offset
    };
    return this.http.get(this.e.snowCannonPositionHistory + '/' + id, options);
  }
}
