import { WaterPipeline, AirPipeline, ElectricalLine, CommunicationLine, ResortArea, SnowCannon } from '.';
import { WorkingArea } from './working-area.model';
import { AbstractDevice } from './abstract-device.model';
import { ConvertUnit, DeviceSystemType } from '../../../../../common';

export class ConnectionPoint extends AbstractDevice {
    systemType: DeviceSystemType = 'ConnectionPoint';

    position: any;
    symbol: string;

    waterPipeline: string;
    airPipeline: string;
    electricalLine: string;
    communicationLine: string;
    resortArea: string;
    workingArea: string;

    isLocked = false;

    mountType: string;
    baseOrientationOffsetFromNorth: number;

    priority: number;
    @ConvertUnit('production', 'm3/h')
    snowProductionVolume: number;
    @ConvertUnit('waterFlow', 'l/s')
    computedWaterFlow: number;
    computedPowerDraw: number;
    computedAirFlow: number;
    limitSnowProductionVolume: number;
    limitWaterConsumption: number;
    limitPowerConsumption: number;
    airHumidity: number;
    airTemperature: number;
    wetBulbTemperature: number;

    waterPipelineRef?: WaterPipeline;
    airPipelineRef?: AirPipeline;
    electricalLineRef?: ElectricalLine;
    communicationLineRef?: CommunicationLine;
    resortAreaRef?: ResortArea;
    workingAreaRef?: WorkingArea;

    snowCannonRefs: Map<string, SnowCannon> = new Map();

    getSnowCannons(): SnowCannon[] {
        return Array.from(this.snowCannonRefs.values());
    }

    get symbolAsNumber(): number {
        return parseInt(this.symbol, 10) || 0;
    }
}
