import { Component } from '@angular/core';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { AbstractSnowCannonComponentButtonComponent } from '../abstract-snow-cannon-component-button.component';

@Component({
  selector: 'ss-snow-cannon-component-button-hydrant-drive',
  templateUrl: './component.html',
  styleUrls: []
})
export class SnowCannonComponentButtonHydrantDriveComponent extends AbstractSnowCannonComponentButtonComponent {

  constructor(cs: ConfigureClientService) {
    super(cs);
  }

}
