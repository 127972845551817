import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { StartScreenComponent, StartScreenAuthGuard } from './start-screen/start-screen.component';

import { ApplicationGuard } from './application-guard.service'; // move to application

import { ApplicationComponent } from './application/application.component';
import { PumpRoomsComponent } from './application/pump-rooms/pump-rooms.component';
import { RecentNotificationsComponent } from './application/recent-notifications/recent-notifications.component';
import { SettingsComponent } from './application/settings/settings.component';
import { ResortComponent } from './application/resort/resort.component';
import { AreaListComponent } from './application/resort/area/area-list/area-list.component';
import { AreaMapComponent } from './application/resort/area/area-map/area-map.component';
import { SnowCannonPanelComponent } from './application/resort/area/area-map/snow-cannon-panel/snow-cannon-panel.component';
import { AreaPanelComponent } from './application/resort/area/area-map/area-panel/area-panel.component';
import { AreaDataResolverService } from './application/resort/area/area-data-resolver.service';
import { AreasDataResolverService } from './application/resort/areas/areas-data-resolver.service';
import { AreasRecentComponent } from './application/resort/areas-recent/areas-recent.component';
import { AreasListComponent } from './application/resort/areas/areas-list/areas-list.component';
import { AreasMapComponent } from './application/resort/areas/areas-map/areas-map.component';
import { PumpRoomsListComponent } from './application/pump-rooms/pump-rooms-list/pump-rooms-list.component';
import { PumpRoomDetailComponent } from './application/pump-rooms/pump-room-detail/pump-room-detail.component';
import { UsersComponent } from './application/users/users.component';
import { UsersListComponent } from './application/users/users-list/users-list.component';
import { UserEditComponent } from './application/users/user-edit/user-edit.component';
import { WeatherAndSchedulesComponent } from './application/weather-and-schedules/weather-and-schedules.component';
import { WeatherAndSchedulesEditScheduleComponent } from './application/weather-and-schedules/edit-schedule/edit-schedule.component';
import { WeatherAndSchedulesListComponent } from './application/weather-and-schedules/list/list.component';
import { ListDataResolverService } from './application/weather-and-schedules/list-data-resolver.service';
import { EditDataResolverService } from './application/weather-and-schedules/edit-data-resolver.service';
import { AuditTrailComponent } from './application/audit-trail/audit-trail.component';
import { UserProfileComponent } from './application/user-profile/user-profile.component';
import { SnowCannonPositionHistoryComponent } from './application/snow-cannon-position-history/snow-cannon-position-history.component';
import { PositionHistoryResolverService } from './application/snow-cannon-position-history/position-history-resolver.service';
import { UserListResolverService } from './application/users/user-list-resolver.service';
import { SingleUserResolverService } from './application/users/single-user-resolver.service';
import { UserStatsResolverService } from './application/users/user-stats-resolver.service';
import { RequiresRoleGuard } from './requires-role.guard';
import { UserAuditTrailResolverService } from './application/users/user-audit-trail-resolver.service';
import { AreasComponent } from './application/resort/areas/areas.component';
import { SnowCannonDataResolverService } from './application/resort/area/area-map/snow-cannon-panel/snow-cannon-data-resolver.service';
import { MassOperationsModalComponent } from './application/modals/mass-operations-modal/mass-operations-modal.component';
import {
  ConfigureWaterPipelineModalComponent
} from './application/pump-rooms/pump-room-detail/configure-water-pipeline-modal/configure-water-pipeline-modal.component';
import {
  SnowCannonIsFromListDataResolver
} from './application/resort/area/area-map/snow-cannon-panel/snow-cannon-is-from-list-data-resolver.service';
import {
  EditorComponent,
  ResortEditorComponent,
  EditorMenuComponent,
  EditConnectionPointsComponent,
  EditAreasComponent,
  PumpRoomFullSchemaEditorComponent
} from './application/editor';
import {
  UserNotesEditorModalComponent
} from './shared/components/user-notes/editor/editor.component';
import {
  ResetUserPasswordComponent
} from './application/users/user-edit/reset-user-password/reset-user-password.component';
import {
  PumpRoomsDataResolver
} from './application/pump-rooms/pump-rooms-data-resolver.service';
import {
  AreaConfigurationComponent
} from './application/resort/area/area-configuration/area-configuration.component';
import {
  ResortAreaConfigurationModalComponent
} from './application/resort/area/resort-area-configuration-modal/resort-area-configuration-modal.component';
import {
  WorkingAreaConfigurationModalComponent
} from './application/resort/area/working-area-configuration-modal/working-area-configuration-modal.component';
import {
  SnowCannonConfigurationModalComponent
} from './application/resort/area/snow-cannon-configuration-modal/snow-cannon-configuration-modal.component';
import {
  ConnectionPointConfigurationModalComponent
} from './application/resort/area/connection-point-configuration-modal/connection-point-configuration-modal.component';
import {
  MeteoStationConfigurationModalComponent
} from './application/weather-and-schedules/meteo-station-configuration-modal/meteo-station-configuration-modal.component';
import {
  ProgressOfObservablesModalComponent
} from './application/modals/progress-of-observables-modal/progress-of-observables-modal.component';
import {
  AreaConfigurationDataResolverService
} from './application/resort/area/area-configuration/area-configuration-data-resolver.service';
import {
  MeteoConditionsSourceSelectionModalComponent
} from './application/modals/meteo-conditions-source-selection-modal/meteo-conditions-source-selection-modal.component';
import { ReportingComponent } from './application/reporting/reporting.component';
import { ReportingDefinitionsListComponent } from './application/reporting/list/list.component';
import { ReportingSingleReportComponent } from './application/reporting/single/single.component';
import { ListResolverService } from './application/reporting/list/list-resolver.service';
import { DefinitionResolverService } from './application/reporting/single/definition-resolver.service';
import {
  PowerSourcesComponent,
  PowerSourcesDetailComponent,
  PowerSourcesListComponent,
  ConfigureElectricalLineModalComponent,
  ConfigurePowerSourceModalComponent,
  PowerSourcesDataResolverService
} from './application/power-sources';
import { RenameModalComponent } from './application/modals/rename-modal/rename-modal.component';
import { ReportingPartCreatorModalComponent } from './application/reporting/single/part-creator-modal/part-creator-modal.component';
import { ReportingExportsRecentComponent } from './application/reporting/exports/recent/recent.component';

const routes: Routes = [
  {
    path: 'application',
    component: ApplicationComponent,
    canActivate: [ApplicationGuard],
    children: [
      {
        path: 'user-profile',
        component: UserProfileComponent,
        outlet: 'modal'
      },
      {
        path: 'progress-modal',
        component: ProgressOfObservablesModalComponent,
        outlet: 'modal'
      },
      {
        path: 'rename-modal',
        component: RenameModalComponent,
        outlet: 'modal'
      },
      {
        path: 'notifications-recent',
        component: RecentNotificationsComponent,
        outlet: 'modal'
      },
      // {
      //   path: 'snow-cannon-position-history/:snowCannonId',
      //   component: SnowCannonPositionHistoryComponent,
      //   resolve: {
      //     positionHistory: PositionHistoryResolverService
      //   },
      //   outlet: 'modal'
      // },
      {
        path: 'configure-electrical-line-modal/:electricalLineId',
        component: ConfigureElectricalLineModalComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredRole: 'ROLE_OPERATOR'
        },
        outlet: 'modal'
      },
      {
        path: 'configure-power-source-modal/:powerSourceId',
        component: ConfigurePowerSourceModalComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredRole: 'ROLE_OPERATOR'
        },
        outlet: 'modal'
      },
      {
        path: 'configure-water-pipeline-modal/:waterPipelineId',
        component: ConfigureWaterPipelineModalComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredRole: 'ROLE_OPERATOR'
        },
        outlet: 'modal'
      },
      {
        path: 'mass-operations-modal/:joinedCannonIds',
        component: MassOperationsModalComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredRole: 'ROLE_OPERATOR'
        },
        outlet: 'modal'
      },
      {
        path: 'meteo-conditions-source-selection-modal/:resortAreaId/:workinngAreaId',
        component: MeteoConditionsSourceSelectionModalComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredRole: 'ROLE_OPERATOR'
        },
        outlet: 'modal'
      },
      {
        path: 'user-notes-editor-modal/:noteContext/:noteId',
        component: UserNotesEditorModalComponent,
        outlet: 'modal'
      },
      {
        path: 'resort-area-config-modal/:resortAreaId',
        component: ResortAreaConfigurationModalComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredRole: 'ROLE_OPERATOR'
        },
        outlet: 'modal'
      },
      {
        path: 'meteo-station-modal/:meteoStationId',
        component: MeteoStationConfigurationModalComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredRole: 'ROLE_OPERATOR'
        },
        outlet: 'modal'
      },
      {
        path: 'working-area-config-modal/:workingAreaId',
        component: WorkingAreaConfigurationModalComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredRole: 'ROLE_OPERATOR'
        },
        outlet: 'modal'
      },
      {
        path: 'snow-cannon-config-modal/:snowCannonId',
        component: SnowCannonConfigurationModalComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredRole: 'ROLE_OPERATOR'
        },
        outlet: 'modal'
      },
      {
        path: 'connection-point-config-modal/:connectionPointId',
        component: ConnectionPointConfigurationModalComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredRole: 'ROLE_OPERATOR'
        },
        outlet: 'modal'
      },
      {
        path: 'reset-user-password-modal/:userId',
        component: ResetUserPasswordComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredRole: 'ROLE_OPERATOR'
        },
        outlet: 'modal'
      },
      {
        path: 'resort-areas-recent',
        component: AreasRecentComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredCapability: 'hasSnowmakingCapabilities'
        },
        resolve: {
          areasData: AreasDataResolverService
        },
        outlet: 'modal'
      },
      {
        path: 'resort',
        component: ResortComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredCapability: 'hasSnowmakingCapabilities'
        },
        children: [
          {
            path: 'areas',
            component: AreasComponent,
            resolve: {
              areasData: AreasDataResolverService
            },
            children: [
              {
                path: 'map',
                component: AreasMapComponent
              },
              {
                path: 'list',
                component: AreasListComponent
              },
              {
                path: '**',
                redirectTo: 'map'
              }
            ]
          },
          {
            path: 'area/:id',
            component: AreasComponent,
            resolve: {
              areaData: AreaDataResolverService
            },
            children: [
              {
                path: 'map',
                component: AreaMapComponent,
                children: [
                  {
                    path: '',
                    component: AreaPanelComponent
                  },
                  {
                    path: ':snowCannonId',
                    component: SnowCannonPanelComponent,
                    resolve: {
                      snowCannonData: SnowCannonDataResolverService,
                      isFromList: SnowCannonIsFromListDataResolver
                    }
                  },
                  {
                    path: '**',
                    redirectTo: ''
                  }
                ]
              },
              {
                path: 'list',
                component: AreaListComponent
              },
              {
                path: 'configure',
                component: AreaConfigurationComponent,
                canActivate: [RequiresRoleGuard],
                data: {
                  requiredRole: 'ROLE_OPERATOR'
                },
                resolve: {
                  cfg: AreaConfigurationDataResolverService
                }
              },
              {
                path: '**',
                redirectTo: 'map'
              }
            ]
          },
          {
            path: '**',
            redirectTo: 'areas'
          }
        ]
      },
      {
        path: 'power-sources',
        component: PowerSourcesComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredCapability: 'hasPowerSources'
        },
        children: [
          {
            path: 'list',
            component: PowerSourcesListComponent,
            resolve: {
              redirector: PowerSourcesDataResolverService
            }
          },
          {
            path: 'detail/:id',
            component: PowerSourcesDetailComponent,
            resolve: {
              redirector: PowerSourcesDataResolverService
            }
          },
          {
            path: '**',
            redirectTo: 'list'
          }
        ]
      },
      {
        path: 'pump-rooms',
        component: PumpRoomsComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredCapability: 'hasPumprooms'
        },
        children: [
          {
            path: 'list',
            component: PumpRoomsListComponent,
            resolve: {
              redirector: PumpRoomsDataResolver
            }
          },
          {
            path: 'detail/:id',
            component: PumpRoomDetailComponent,
            resolve: {
              redirector: PumpRoomsDataResolver
            }
          },
          {
            path: '**',
            redirectTo: 'list'
          }
        ]
      },
      {
        path: 'weather-and-schedules',
        component: WeatherAndSchedulesComponent,
        children: [
          {
            path: 'list',
            component: WeatherAndSchedulesListComponent,
            resolve: {
              areaData: ListDataResolverService
            }
          },
          {
            path: 'edit/:id',
            component: WeatherAndSchedulesEditScheduleComponent,
            resolve: {
              areaData: EditDataResolverService
            }
          },
          {
            path: '**',
            redirectTo: 'list'
          }
        ]
      },
      {
        path: 'reporting-part-creator-modal',
        component: ReportingPartCreatorModalComponent,
        outlet: 'modal'
      },
      {
        path: 'reporting-exports-recent',
        component: ReportingExportsRecentComponent,
        outlet: 'modal'
      },
      {
        path: 'reporting',
        component: ReportingComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredCapability: 'hasReports'
        },
        children: [
          {
            path: 'list',
            component: ReportingDefinitionsListComponent,
            resolve: {
              definitions: ListResolverService
            }
          },
          {
            path: ':reportId',
            component: ReportingSingleReportComponent,
            resolve: {
              definition: DefinitionResolverService
            }
          },
          {
            path: '**',
            redirectTo: 'list'
          }
        ]
      },
      {
        path: 'notifications',
        component: RecentNotificationsComponent
      },
      {
        path: 'settings',
        component: SettingsComponent
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [RequiresRoleGuard],
        data: {
          requiredRole: 'ROLE_ADMIN',
          requiredCapability: 'hasUserManagement'
        },
        children: [
          {
            path: 'list',
            component: UsersListComponent,
            resolve: {
              stats: UserStatsResolverService,
              users: UserListResolverService
            }
          },
          {
            path: 'edit/:userId',
            component: UserEditComponent,
            resolve: {
              stats: UserStatsResolverService,
              user: SingleUserResolverService,
              auditTrail: UserAuditTrailResolverService
            }
          },
          {
            path: '**',
            redirectTo: 'list'
          },
        ]
      },
      {
        path: 'audit-trail',
        canActivate: [RequiresRoleGuard],
        data: {
          requiredRole: 'ROLE_SUPERADMIN',
          // requiredCapability: 'hasAuditTrail'
        },
        component: AuditTrailComponent
      },
      {
        path: 'editor',
        canActivate: [RequiresRoleGuard],
        data: {
          requiredRole: 'ROLE_SUPERADMIN'
        },
        component: EditorComponent,
        children: [
          {
            path: 'menu',
            component: EditorMenuComponent
          },
          {
            path: 'resort',
            component: ResortEditorComponent
          },
          {
            path: 'areas',
            component: EditAreasComponent
          },
          {
            path: 'conenction-points',
            component: EditConnectionPointsComponent
          },
          {
            path: '**',
            redirectTo: 'menu'
          }
        ]
      },
      {
        path: 'editor-pump-room-full-schema/:pumpRoomId',
        canActivate: [RequiresRoleGuard],
        data: {
          requiredRole: 'ROLE_SUPERADMIN'
        },
        component: PumpRoomFullSchemaEditorComponent
      },
      {
        path: '**',
        redirectTo: 'resort'
      }
    ]
  },
  {
    path: 'start-screen',
    canActivate: [StartScreenAuthGuard],
    component: StartScreenComponent
  },
  {
    path: '**',
    redirectTo: 'start-screen'
  }
];

const config: ExtraOptions = {
    useHash: false,
    enableTracing: false,
    paramsInheritanceStrategy: 'always',
    relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {

}
