import { isArray } from '../..';
import { ReportPartType } from './constants';
import { ReportSeries } from './series';

export class ReportPart {
  public partName?: string;
  public partType: ReportPartType;

  public series: ReportSeries[] = [];

  public static create(data: any, forceClone: boolean = false): ReportPart {
    if(!forceClone && data instanceof ReportPart) {
      return data;
    }

    const parsedData = { ... data };
    if(isArray(parsedData.series)) {
      parsedData.series = parsedData.series.map(ds => ReportSeries.create(ds, forceClone));
    } else {
      parsedData.series = [];
    }

    return Object.assign(new ReportPart(), parsedData);
  }

  public toHttpRequest(): any {
    const resp = {
      ...this
    };
    return resp;
  }
}
