<perfect-scrollbar>

  <div class="px-2 pb-5">
    <div class="pump-room__header mt-2">
      <ss-tabs>
        <ss-tab tabTitle="Podsumowanie"
          i18n-tabTitle="Podsumowanie|Tytuł zakładki z podsumowaniem@@pumpRoomDetailTabTitleSummary">

          <div *ngIf="pumpRoom.connectionStatus && !pumpRoom.infoRemoteModeEnabled"
            class="pump-room__mode-alert color-white p-4 d-flex align-items-center">
            <svg class="mb-1 mr-2">
              <use xlink:href='#icon-lock' />
            </svg>
            <span class="fs-20 pr-2"
              i18n-title="Sterowanie lokalne|Komunikat o trybie sterowania pompownią@@pumpRoomDetailLocalSteering">Sterowanie
              lokalne</span>
            <ng-container
              i18n-title="Brak możliwości zdalnego sterowania pompownią|Komunikat o braku możliwości sterowania zdalnego pompownią@@pumpRoomDetailLocalSteeringDet">
              Brak możliwości zdalnego sterowania pompownią</ng-container>
          </div>
          <div class="d-flex justify-content-between px-4 pt-5 pb-2">
            <h3>
              <ng-container i18n="Pompownia|Etykieta pompowni@@pumpRoomLabel">Pompownia</ng-container>:
              {{ pumpRoom.symbol }}
            </h3>
            <div class="d-flex ">
              <div class="mx-4">
                <p class="color-light-grey fs-12 text-uppercase"
                  i18n="Przepływ aktualny|Etykieta wskaźnika aktualnego przepływu@@pumpRoomCurrentFlow">Przepływ
                  aktualny</p>
                <p class="mt-2 white-space-nowrap">
                  <span class="fs-42 color-green">{{ pumpRoom|convertedValue:'computedWaterFlow' }}</span>
                  <span class="fs-20 color-mid-grey pl-1"> {{ pumpRoom|convertedUnit:'computedWaterFlow' }}</span>
                </p>
              </div>
              <div class="ml-4 mr-2">
                <div style="width: 730px">
                  <ss-d3-pressure-and-flow-chart [device]="pumpRoom"></ss-d3-pressure-and-flow-chart>
                </div>
              </div>
              <div>
                <p class="color-light-grey fs-12 text-uppercase"
                  i18n="Przepływ maksymalny|Etykieta wskaźnika maksymalnego przepływu@@pumpRoomMaxFlow">Przepływ
                  maksymalny</p>
                <p class="mt-2 white-space-nowrap">
                  <span class="fs-20 color-white">{{ pumpRoom|convertedValue:'maximumWaterFlow' }}</span>
                  <span class="fs-12 color-mid-grey pl-1"> {{ pumpRoom|convertedUnit:'maximumWaterFlow' }}</span></p>
              </div>
            </div>
          </div>
        </ss-tab>
        <ss-tab [hidden]="true" tabTitle="Statystyki i raporty"
          i18n-tabTitle="Statystyki i raporty|Tytuł zakładki ze statystykami dot. danej armatki@@snowCannonTabTitleStats">
          <div class="p-3">
            <p class="window-overview__header text-uppercase"
              i18n="Statystyki|Nagłówek pola ze statystykami dot. danej armatki@@snowCannonTabHeaderStats">
              Statystyki
            </p>
          </div>
        </ss-tab>

        <ss-user-notes-tab
          [context]="pumpRoom.id"
          tabTitle="Notatki"
          i18n-tabTitle="Notatki|Tytuł zakładki z notatkami dot. danej armatki@@pumproomDetailTabTitleNotes"></ss-user-notes-tab>
        <ss-documentation-tab
          [context]="pumpRoom.id"
          [extraWide]="true"
          tabTitle="Dokumentacja"
          i18n-tabTitle="Dokumentacja|Tytuł zakładki z dokuemntacją dot. danej armatki@@pumproomDetailTabTitleDocumentation"></ss-documentation-tab>
        <ss-tab tabTitle="Debug" *ssdHideIfNotGranted="'ROLE_SUPERADMIN'">
          <div class="p-3 color-light-grey">
            <h1>{{ pumpRoom.systemType }} {{ pumpRoom.symbol }}</h1>
            <ss-debug-table [data]="pumpRoom"></ss-debug-table>

            <ng-container *ngFor="let wp of pipelines">
              <h1>{{ wp.systemType }} {{ wp.symbol }}</h1>
              <ss-debug-table [data]="wp"></ss-debug-table>
            </ng-container>
          </div>
        </ss-tab>
        <ss-notifications-tab
          [context]="devIds"
          [extraWide]="true"
          tabTitle="Powiadomienia"
          i18n-tabTitle="Powiadomienia|Tytuł zakładki z listą awarii@@pumpRoomDetailTabTitleNotifications"></ss-notifications-tab>
      </ss-tabs>
    </div>


    <ss-page-accordion-panel>

      <ss-page-accordion-block *ngIf="pipelines && pipelines.length" [expand]="true">
        <ss-page-accordion-block-header>
          <div class="row">
            <div class="col-4">
              <h3><ng-container i18n="Rurociągi|Rurociągi - tytuł panelu@@pumproomDetailPipelinePanelTitle">Rurociągi</ng-container> ({{ pipelines.length }})</h3>
            </div>
            <div class="col-8">
              <ss-pump-room-detail-multi-status [components]="pipelines">
                <ss-pump-room-detail-status status="working" class="first-compacted"></ss-pump-room-detail-status>
                <ss-pump-room-detail-status status="filling" [optional]="true"></ss-pump-room-detail-status>
                <ss-pump-room-detail-status status="snoozing"></ss-pump-room-detail-status>
                <ss-pump-room-detail-status status="ready-to-work"></ss-pump-room-detail-status>
                <ss-pump-room-detail-status status="standing-by" class="last-compacted"></ss-pump-room-detail-status>
                <ss-pump-room-detail-status status="breakdown" [standalone]="true"></ss-pump-room-detail-status>
                <ss-pump-room-detail-status status="connection-lost" [standalone]="true"></ss-pump-room-detail-status>
              </ss-pump-room-detail-multi-status>
            </div>
          </div>
        </ss-page-accordion-block-header>
        <div class="row component-row">
          <div class="col-4" *ngFor="let p of pipelines">
            <ss-water-pipeline-tile
              (selected)="highlightComponentsChange(p, $event)"
              [waterPipeline]="p"></ss-water-pipeline-tile>
          </div>
        </div>
      </ss-page-accordion-block>

      <ng-container *ngIf="simplifiedSchema">
        <ss-page-accordion-block *ngIf="simplifiedSchema && simplifiedSchema.additionalModesOfOperation.length > 0" [expand]="true">
          <ss-page-accordion-block-header>
            <div class="row">
              <div class="col-4">
                <h3><ng-container i18n="Additional modes of operation|Additional modes of operation@@pumproomDetailPanelTitleAdditionalModesOfOperation">Additional modes of operation</ng-container></h3>
              </div>
              <div class="col-8"></div>
            </div>
          </ss-page-accordion-block-header>
          <div class="row component-row">
            <div class="col-1-of-5" *ngFor="let c of simplifiedSchema.additionalModesOfOperation">
              <ss-additional-mode-of-operation-tile [mode]="c"></ss-additional-mode-of-operation-tile>
            </div>
          </div>
        </ss-page-accordion-block>
        <ss-page-accordion-block *ngIf="simplifiedSchema && simplifiedSchema.secondStagePumps.length > 0" [expand]="pumps2AccordionExpanded">
          <ss-page-accordion-block-header>
            <div class="row">
              <div class="col-4">
                <h3><ng-container i18n="Pompy II stopnia|Pompy II stopnia@@pumproomDetailPanelTitle2ndStagePumps">Pompy II stopnia</ng-container> ({{ simplifiedSchema.secondStagePumps.length }})</h3>
              </div>
              <div class="col-8">
                <ss-pump-room-detail-multi-status [components]="simplifiedSchema.secondStagePumps">
                  <ss-pump-room-detail-status status="working" class="first-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="ready-to-work"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="standing-by" class="last-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="breakdown" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="connection-lost" [standalone]="true"></ss-pump-room-detail-status>

                  <ss-pump-room-detail-status status="dry-run" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="overheated" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="overcurrent" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="fsl" [standalone]="true"></ss-pump-room-detail-status>
                </ss-pump-room-detail-multi-status>
              </div>
            </div>
          </ss-page-accordion-block-header>
          <div class="row component-row">
            <div class="col-1-of-5" *ngFor="let c of simplifiedSchema.secondStagePumps">
              <ss-pump-tile [selectedPipelineIds]="selectedPipelineIds" [pump]="c"></ss-pump-tile>
            </div>
          </div>
        </ss-page-accordion-block>

        <ss-page-accordion-block *ngIf="simplifiedSchema && simplifiedSchema.firstStagePumps.length > 0" [expand]="pumps1AccordionExpanded">
          <ss-page-accordion-block-header>
            <div class="row">
              <div class="col-4">
                <h3><ng-container i18n="Pompy I stopnia|Pompy I stopnia@@pumproomDetailPanelTitle1stStagePumps">Pompy I stopnia</ng-container> ({{ simplifiedSchema.firstStagePumps.length }})</h3>
              </div>
              <div class="col-8">
                <ss-pump-room-detail-multi-status [components]="simplifiedSchema.firstStagePumps">
                  <ss-pump-room-detail-status status="working" class="first-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="ready-to-work"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="standing-by" class="last-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="breakdown" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="connection-lost" [standalone]="true"></ss-pump-room-detail-status>

                  <ss-pump-room-detail-status status="dry-run" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="overheated" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="overcurrent" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="fsl" [standalone]="true"></ss-pump-room-detail-status>
                </ss-pump-room-detail-multi-status>
              </div>
            </div>
          </ss-page-accordion-block-header>
          <div class="row component-row">
            <div class="col-1-of-5" *ngFor="let c of simplifiedSchema.firstStagePumps">
              <ss-pump-tile [selectedPipelineIds]="selectedPipelineIds" [pump]="c"></ss-pump-tile>
            </div>
          </div>
        </ss-page-accordion-block>

        <ss-page-accordion-block *ngIf="simplifiedSchema && simplifiedSchema.liftingPumps.length > 0" [expand]="lifitngPumpAccordionExpanded">
          <ss-page-accordion-block-header>
            <div class="row">
              <div class="col-4">
                <h3><ng-container i18n="Pompy podnoszące|Pompy podnoszące@@pumproomDetailPanelTitleLiftingPumps">Pompy podnoszące</ng-container> ({{ simplifiedSchema.liftingPumps.length }})</h3>
              </div>
              <div class="col-8">
                <ss-pump-room-detail-multi-status [components]="simplifiedSchema.liftingPumps">
                  <ss-pump-room-detail-status status="working" class="first-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="ready-to-work"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="standing-by" class="last-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="breakdown" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="connection-lost" [standalone]="true"></ss-pump-room-detail-status>

                  <ss-pump-room-detail-status status="dry-run" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="overheated" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="overcurrent" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="fsl" [standalone]="true"></ss-pump-room-detail-status>
                </ss-pump-room-detail-multi-status>
              </div>
            </div>
          </ss-page-accordion-block-header>
          <div class="row component-row">
            <div class="col-1-of-5" *ngFor="let c of simplifiedSchema.liftingPumps">
              <ss-pump-tile [selectedPipelineIds]="selectedPipelineIds" [pump]="c"></ss-pump-tile>
            </div>
          </div>
        </ss-page-accordion-block>

        <ss-page-accordion-block *ngIf="simplifiedSchema && simplifiedSchema.valves.length > 0" [expand]="valvesAccordionExpanded">
          <ss-page-accordion-block-header>
            <div class="row">
              <div class="col-4">
                <h3><ng-container i18n="Zawory|Zawory@@pumproomDetailPanelTitleValves">Zawory</ng-container> ({{ simplifiedSchema.valves.length }})</h3>
              </div>
              <div class="col-8">
                <ss-pump-room-detail-multi-status [components]="simplifiedSchema.valves">
                  <ss-pump-room-detail-status status="working" class="first-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="standing-by" class="last-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="breakdown" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="connection-lost" [standalone]="true"></ss-pump-room-detail-status>
                </ss-pump-room-detail-multi-status>
              </div>
            </div>
          </ss-page-accordion-block-header>
          <div class="row component-row">
            <div class="col-1-of-5" *ngFor="let c of simplifiedSchema.valves">
              <ss-valve-tile [selectedPipelineIds]="selectedPipelineIds" [valve]="c"></ss-valve-tile>
            </div>
          </div>
        </ss-page-accordion-block>

        <ss-page-accordion-block *ngIf="simplifiedSchema && simplifiedSchema.filters.length > 0" [expand]="filtersAccordionExpanded">
          <ss-page-accordion-block-header>
            <div class="row">
              <div class="col-4">
                <h3><ng-container i18n="Filtry|Filtry@@pumproomDetailPanelTitleFilters">Filtry</ng-container> ({{ simplifiedSchema.filters.length }})</h3>
              </div>
              <div class="col-8">
                <ss-pump-room-detail-multi-status [components]="simplifiedSchema.filters">
                  <ss-pump-room-detail-status status="working" class="first-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="standing-by" class="last-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="breakdown" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="connection-lost" [standalone]="true"></ss-pump-room-detail-status>
                </ss-pump-room-detail-multi-status>
              </div>
            </div>
          </ss-page-accordion-block-header>
          <div class="row component-row">
            <div class="col-1-of-5" *ngFor="let c of simplifiedSchema.filters">
              <ss-filter-tile [selectedPipelineIds]="selectedPipelineIds" [filter]="c"></ss-filter-tile>
            </div>
          </div>
        </ss-page-accordion-block>

        <ss-page-accordion-block *ngIf="simplifiedSchema && simplifiedSchema.coolingTowers.length > 0" [expand]="coolingTowersAccordionExpanded">
          <ss-page-accordion-block-header>
            <div class="row">
              <div class="col-4">
                <h3><ng-container i18n="Wieże chłodnicze|Wieże chłodnicze@@pumproomDetailPanelTitleCoolingTowers">Wieże chłodnicze</ng-container> ({{ simplifiedSchema.coolingTowers.length }})</h3>
              </div>
              <div class="col-8">
                <ss-pump-room-detail-multi-status [components]="simplifiedSchema.coolingTowers">
                  <ss-pump-room-detail-status status="working"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="ready-to-work"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="standing-by" class="last-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="breakdown" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="connection-lost" [standalone]="true"></ss-pump-room-detail-status>
                </ss-pump-room-detail-multi-status>
              </div>
            </div>
          </ss-page-accordion-block-header>
          <div class="row component-row">
            <div class="col-1-of-5" *ngFor="let c of simplifiedSchema.coolingTowers">
              <ss-cooling-tower-tile [selectedPipelineIds]="selectedPipelineIds" [coolingTower]="c"></ss-cooling-tower-tile>
            </div>
          </div>
        </ss-page-accordion-block>

        <ss-page-accordion-block *ngIf="simplifiedSchema && simplifiedSchema.coolingTowerPumps.length > 0" [expand]="coolingTowerPumpAccordionExpanded">
          <ss-page-accordion-block-header>
            <div class="row">
              <div class="col-4">
                <h3><ng-container i18n="Pompy wież chłodniczych|Pompy wież chłodniczych@@pumproomDetailPanelTitleCoolingTowerPumps">Pompy wież chłodniczych</ng-container> ({{ simplifiedSchema.coolingTowerPumps.length }})</h3>
              </div>
              <div class="col-8">
                <ss-pump-room-detail-multi-status [components]="simplifiedSchema.coolingTowerPumps">
                  <ss-pump-room-detail-status status="working" class="first-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="ready-to-work"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="standing-by" class="last-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="breakdown" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="connection-lost" [standalone]="true"></ss-pump-room-detail-status>

                  <ss-pump-room-detail-status status="dry-run" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="overheated" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="overcurrent" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="fsl" [standalone]="true"></ss-pump-room-detail-status>
                </ss-pump-room-detail-multi-status>
              </div>
            </div>
          </ss-page-accordion-block-header>
          <div class="row component-row">
            <div class="col-1-of-5" *ngFor="let c of simplifiedSchema.coolingTowerPumps">
              <ss-pump-tile [selectedPipelineIds]="selectedPipelineIds" [pump]="c"></ss-pump-tile>
            </div>
          </div>
        </ss-page-accordion-block>













        <ss-page-accordion-block *ngIf="simplifiedSchema && simplifiedSchema.compressors.length > 0" [expand]="compressorAccordionExpanded">
          <ss-page-accordion-block-header>
            <div class="row">
              <div class="col-4">
                <h3><ng-container i18n="Kompresory napowietrzania|Kompresory napowietrzania@@pumproomDetailPanelTitleCompressors">Kompresory napowietrzania</ng-container> ({{ simplifiedSchema.compressors.length }})</h3>
              </div>
              <div class="col-8">
                <ss-pump-room-detail-multi-status [components]="simplifiedSchema.compressors">
                  <ss-pump-room-detail-status status="working" class="first-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="ready-to-work"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="standing-by" class="last-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="breakdown" [standalone]="true"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="connection-lost" [standalone]="true"></ss-pump-room-detail-status>
                </ss-pump-room-detail-multi-status>
              </div>
            </div>
          </ss-page-accordion-block-header>
          <div class="row component-row">
            <div class="col-1-of-5" *ngFor="let c of simplifiedSchema.compressors">
              <ss-compressor-tile [selectedPipelineIds]="selectedPipelineIds" [compressor]="c"></ss-compressor-tile>
            </div>
          </div>
        </ss-page-accordion-block>
        <ss-page-accordion-block *ngIf="simplifiedSchema && simplifiedSchema.aerationLevels.length > 0" [expand]="aerationLevelAccordionExpanded">
          <ss-page-accordion-block-header>
            <div class="row">
              <div class="col-4">
                <h3><ng-container i18n="Poziomy napowietrzania|Poziomy napowietrzania@@pumproomDetailPanelTitleAerationLevels">Poziomy napowietrzania</ng-container> ({{ simplifiedSchema.aerationLevels.length }})</h3>
              </div>
              <div class="col-8">
                <ss-pump-room-detail-multi-status [components]="simplifiedSchema.aerationLevels">
                  <ss-pump-room-detail-status status="working" class="first-compacted"></ss-pump-room-detail-status>
                  <ss-pump-room-detail-status status="standing-by" class="last-compacted"></ss-pump-room-detail-status>
                </ss-pump-room-detail-multi-status>
              </div>
            </div>
          </ss-page-accordion-block-header>
          <div class="row component-row">
            <div class="col-1-of-5" *ngFor="let c of simplifiedSchema.aerationLevels">
              <ss-aeration-level-tile [selectedPipelineIds]="selectedPipelineIds" [aerationLevel]="c"></ss-aeration-level-tile>
            </div>
          </div>
        </ss-page-accordion-block>











      </ng-container>

      <ss-page-accordion-block *ngIf="waterReservoirs && waterReservoirs.length > 0">
        <ss-page-accordion-block-header>
          <h3><ng-container i18n="Zbiorniki|Zbiorniki@@pumproomDetailPanelTitleReserviors">Zbiorniki</ng-container> ({{ waterReservoirs.length }})</h3>
        </ss-page-accordion-block-header>
        <div class="row">
          <div class="col-3" *ngFor="let w of waterReservoirs">
            <ss-water-tank size="large" [waterReservoir]="w"></ss-water-tank>
          </div>
        </div>
      </ss-page-accordion-block>

      <ss-page-accordion-block *ngIf="pumpRoom && this.pumpRoom.configuration.fullSchema && this.pumpRoom.configuration.fullSchema.all.length > 0" [expand]="true">
        <ss-page-accordion-block-header>
          <h3 i18n="Pełny schemat pompownii|Pełny schemat pompownii@@pumproomDetailPanelTitleFullSchema">Pełny schemat pompownii</h3>
        </ss-page-accordion-block-header>
        <div>
          <ss-full-schema [allowEdit]="false" [pumpRoom]="pumpRoom"></ss-full-schema>
        </div>
      </ss-page-accordion-block>
    </ss-page-accordion-panel>
  </div>
</perfect-scrollbar>
