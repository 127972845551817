export type UnitType = 'length'
  |'temperature'
  |'atmosphericAirPressure'
  |'humidity'
  |'windSpeed'
  |'airPressure'
  |'airFlow'
  |'waterPressure'
  |'waterFlow'
  |'production'
  |'powerDraw'
  |'airUsage'
  |'waterUsage'
  |'powerUsage'
  |'voltage'
  |'current'
  |'scalar';

export interface UnitSetInterface {
  length: 'm';
  temperature: 'C';
  atmosphericAirPressure: 'hPa';
  humidity: '%';
  windSpeed: 'm/s'|'km/h';
  airPressure: 'bar';
  airFlow: 'l/s'|'m3/h';
  waterPressure: 'bar';
  waterFlow: 'l/s'|'m3/h';
  production: 'm3/h';
  powerDraw: 'kW';
  airUsage: 'l'|'m3';
  waterUsage: 'l'|'m3';
  powerUsage: 'kWh';
  voltage: 'V';
  current: 'A';
  scalar: '';
}

export class UnitSet implements UnitSetInterface {
  length: 'm';
  temperature: 'C';
  atmosphericAirPressure: 'hPa';
  humidity: '%';
  windSpeed: 'm/s'|'km/h';
  airPressure: 'bar';
  airFlow: 'l/s'|'m3/h';
  waterPressure: 'bar';
  waterFlow: 'l/s'|'m3/h';
  production: 'm3/h';
  powerDraw: 'kW';
  airUsage: 'l'|'m3';
  waterUsage: 'l'|'m3';
  powerUsage: 'kWh';
  voltage: 'V';
  current: 'A';
  scalar: '';

  currentLocale: 'pl';

  public getCalculatedValue(unitType: UnitType, value: number, baseUnit?: string): number {
    if (unitType === 'waterFlow') {
      return UnitSet.calculateWaterFlow(value, this.waterFlow, baseUnit as any);
    } else if (unitType === 'production') {
      return UnitSet.calculateSnowProductionVolume(value, this.production, baseUnit as any);
    }

    return value;
  }

  public getCalculatedUnit(unitType: UnitType): string {
    return this[unitType];
  }

  public static calculateWaterFlow(value: number, to: 'l/s'|'m3/h', from: 'l/s'|'m3/h' = 'l/s'): number {
    if (!value) {
      return value;
    }

    if (from === 'l/s' && to === 'm3/h') {
      return 3.6 * value;
    }

    if (from === 'm3/h' && to === 'l/s') {
      return value / 3.6;
    }

    return value;
  }

  public static calculateSnowProductionVolume(value: number, to: 'm3/h', from: 'm3/h' = 'm3/h'): number {
    if (!value) {
      return value;
    }

    return value;
  }

  public static create($locale: string): UnitSet {
    return Object.assign(new UnitSet(), {
      length: 'm',
      temperature: 'C',
      atmosphericAirPressure: 'hPa',
      humidity: '%',
      windSpeed: 'm/s',
      airPressure: 'bar',
      airFlow: $locale !== 'pl' ? 'l/s' : 'm3/h',
      waterPressure: 'bar',
      waterFlow: $locale !== 'pl' ? 'l/s' : 'm3/h',
      production: 'm3/h',
      powerDraw: 'kW',
      airUsage: 'm3',
      waterUsage: 'm3',
      powerUsage: 'kWh',
      voltage: 'V',
      current: 'A',
      currentLocale: $locale
    });
  }

  public extend(data: any) {
    Object.assign(this, data);
  }
}
