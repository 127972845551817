import { Component, OnInit } from '@angular/core';
import { FormTextComponent } from './form-text.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

let identifier = 0;

@Component({
  selector: 'form-email',
  templateUrl: './form-text.component.html',
  styleUrls: [],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FormEmailComponent,
    multi: true,
  }],
})
export class FormEmailComponent extends FormTextComponent {
  public type = 'email';
  public identifier = `form-email-${identifier++}-` + Math.random();
}
