<ss-page-toolbar>
  <div class="flex-row w-100 justify-content-stretch" [ngStyle]="{ 'display': canDisplayChart ? 'block' : 'none' }">

    <div class="chart-background">
      <svg class="chart-placeholder" style="width: 1px; height: 1px;"></svg>
    </div>

  </div>
  <div class="flex-row w-100 justify-content-stretch">
    <div class="d-flex align-items-center">
      <button class="btn btn-default"
        (click)="moveToDate(resultView.prevDisplayDelim)"
        [disabled]="!resultView.prevDisplayDelim">
        <svg>
          <use xlink:href='#icon-chevron-left' />
        </svg>
        <ng-container i18n="wcześniej|akcja 'wcześniej' w wyborze wyświetlanego zakresu dat@@report-detail-time-range-toolbar-previous">wcześniej</ng-container>
        <kbd><span>&#x02190;</span></kbd>
      </button>
      <div class="filler"></div>
      <p class="text-center">
        <ng-container *ngIf="canDisplayChart">
          <span class="help">
            <svg >
              <use xlink:href='#icon-mouse-left-button' />
            </svg>
            <ng-container i18n="Kliknij lewym klawiszem myszy aby wyśrodkować|akcja 'Kliknij lewym klawiszem myszy aby wyśrodkować' w wyborze wyświetlanego zakresu dat@@report-detail-time-range-toolbar-hint-lmb-to-center">Kliknij lewym klawiszem myszy aby wyśrodkować</ng-container>
          </span>
          <span class="help">
            <svg >
              <use xlink:href='#icon-mouse-drag-horizontal' />
            </svg>
            <ng-container i18n="Przeciągnij aby przybliżyć|akcja 'Przeciągnij aby przybliżyć' w wyborze wyświetlanego zakresu dat@@report-detail-time-range-toolbar-hint-drag-to-zoom-in">Przeciągnij aby przybliżyć</ng-container>
          </span>
          <span class="help">
            <svg >
              <use xlink:href='#icon-mouse-right-button' />
            </svg>
            <ng-container i18n="Kliknij prawym klawiszem myszy aby oddalić|akcja 'Kliknij prawym klawiszem myszy aby oddalić' w wyborze wyświetlanego zakresu dat@@report-detail-time-range-toolbar-hint-rmb-to-zoom-out">Kliknij prawym klawiszem myszy aby oddalić</ng-container>
          </span>
          <br />
        </ng-container>
        <button class="btn btn-inline btn-link"
          [disabled]="!resultView.firstDisplayDelim"
          (click)="moveToDate(resultView.firstDisplayDelim);">
          <ng-container i18n="przejdź na początek|akcja 'przejdź na początek' w wyborze wyświetlanego zakresu dat@@report-detail-time-range-toolbar-go-to-start">przejdź na początek</ng-container>
          <kbd>Home</kbd>
          </button>
        <button class="btn btn-inline btn-link"
          [disabled]="!resultView.prevMonthDisplayDelim"
          (click)="moveToDate(resultView.prevMonthDisplayDelim);">
          <ng-container i18n="miesiąc wcześniej|akcja 'miesiąc wcześniej' w wyborze wyświetlanego zakresu dat@@report-detail-time-range-toolbar-go-back-month">miesiąc wcześniej</ng-container>
          <!--<kbd><span>&#x021D1;</span>+PgUp</kbd>-->
        </button>
        <button class="btn btn-inline btn-link"
          [disabled]="!resultView.prevWeekDisplayDelim"
          (click)="moveToDate(resultView.prevWeekDisplayDelim);">
          <ng-container i18n="tydzień wcześniej|akcja 'tydzień wcześniej' w wyborze wyświetlanego zakresu dat@@report-detail-time-range-toolbar-go-back-weekj">tydzień wcześniej</ng-container>
          <!--<kbd>PgUp</kbd>-->
        </button>
        <button class="btn btn-inline btn-link"
          [disabled]="!resultView.prevDayDisplayDelim"
          (click)="moveToDate(resultView.prevDayDisplayDelim);">
          <ng-container i18n="dzień wcześniej|akcja 'dzień wcześniej' w wyborze wyświetlanego zakresu dat@@report-detail-time-range-toolbar-go-back-day">dzień wcześniej</ng-container>
          <!--<kbd><span>&#x021D1;</span>+<span>&#x02190;</span></kbd>-->
        </button>
        <button class="btn btn-inline btn-link"
          [disabled]="!resultView.nextDayDisplayDelim"
          (click)="moveToDate(resultView.nextDayDisplayDelim);">
          <ng-container i18n="dzień później|akcja 'dzień później' w wyborze wyświetlanego zakresu dat@@report-detail-time-range-toolbar-go-next-day">dzień później</ng-container>
          <!--<kbd><span>&#x021D1;</span>+<span>&#x02192;</span></kbd>-->
        </button>
        <button class="btn btn-inline btn-link"
          [disabled]="!resultView.nextWeekDisplayDelim"
          (click)="moveToDate(resultView.nextWeekDisplayDelim);">
          <ng-container i18n="tydzień później|akcja 'tydzień później' w wyborze wyświetlanego zakresu dat@@report-detail-time-range-toolbar-go-next-week">tydzień później</ng-container>
          <!--<kbd>PgDn</kbd>-->
        </button>
        <button class="btn btn-inline btn-link"
          [disabled]="!resultView.nextMonthDisplayDelim"
          (click)="moveToDate(resultView.nextMonthDisplayDelim);">
          <ng-container i18n="miesiąc później|akcja 'miesiąc później' w wyborze wyświetlanego zakresu dat@@report-detail-time-range-toolbar-go-next-month">miesiąc później</ng-container>
          <!--<kbd><span>&#x021D1;</span>+PgDn</kbd>-->
        </button>
        <button class="btn btn-inline btn-link"
          [disabled]="!resultView.lastDisplayDelim"
          (click)="moveToDate(resultView.lastDisplayDelim);">
          <ng-container i18n="przejdź na koniec|akcja 'przejdź na koniec' w wyborze wyświetlanego zakresu dat@@report-detail-time-range-toolbar-go-to-end">przejdź na koniec</ng-container>
          <kbd>End</kbd>
        </button>
      </p>
      <div class="filler"></div>
      <button class="btn btn-default"
        (click)="moveToDate(resultView.nextDisplayDelim)"
        [disabled]="!resultView.nextDisplayDelim">
        <ng-container i18n="później|akcja 'później' w wyborze wyświetlanego zakresu dat@@report-detail-time-range-toolbar-next">później</ng-container>
        <kbd><span>&#x02192;</span></kbd>
        <svg>
          <use xlink:href='#icon-chevron-right' />
        </svg>
      </button>
    </div>
  </div>
</ss-page-toolbar>
