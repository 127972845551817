<div class="row align-items-center">
  <div class="col">
    <p *ngIf="notes.length > 0"><small i18n="Ilość notatek|Panel notatek - Ilość notatek@@notesPanelNotesCount">Ilość przypiętych notatek: {{ notes.length|number }}</small></p>
    <p *ngIf="!notes.length"><small i18n="Brak notatek|Panel notatek - brak notatek@@notesPanelNoNotesYet">W tym miejscu nie ma przypiętych żadnych notatek.</small></p>
  </div>
  <div class="col-auto">
    <button class="btn btn-default" (click)="edit(null)">
      <svg>
        <use xlink:href='#icon-plus' />
      </svg>
      <ng-container i18n="Dodaj notatkę|Panel notatek - Dodaj notatkę@@notesPanelAddNotesButton">Dodaj notatkę</ng-container>
    </button>
  </div>
</div>

<div *ngFor="let n of notes" class="note">
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col-auto">
          <small>{{ n.created | date : "yyyy-MM-dd HH:mm" }}</small>
        </div>
        <div class="col px-2">
          <small>{{ n.user.name }} {{ n.user.surname }}</small>
        </div>
        <div class="col-auto pr-2">
          <small *ngIf="n.replaces" (click)="showHistory(n)" class="clickable"  i18n="Pokaż historię|Panel notatek - Pokaż historię@@notesPanelShowHistoryButton">pokaż historię</small>
        </div>
      </div>
      <div class="row">
        <div class="col">
          {{ n.content }}
        </div>
      </div>
    </div>
    <div class="col-auto">
      <button class="btn btn-default" (click)="edit(n.id)" i18n="Edytuj|Panel notatek - Edytuj@@notesPanelEditNoteButton">Edytuj</button>
    </div>
  </div>

  <ng-container *ngIf="n.isHistoryShown">
    <div *ngFor="let r of n.replacementArray" class="note note--historic">
      <div class="row">
        <div class="col-auto">
          <small>{{ r.created | date : "yyyy-MM-dd HH:mm" }}</small>
        </div>
        <div class="col px-2">
          <small>{{ r.user.name }} {{ r.user.surname }}</small>
        </div>
      </div>
      <div class="row">
        <div class="col">
          {{ r.content }}
        </div>
      </div>
    </div>
  </ng-container>
</div>
