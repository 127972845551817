import { D3DrawerCanvas } from '../d3-drawer-canvas.type';
import { D3DrawerRoot } from '../d3-drawer-root.type';

export const selectCanvas = (
  root: D3DrawerRoot
): D3DrawerCanvas => {
  let canvas = root.select('g.chart-canvas') as D3DrawerCanvas;
  if(canvas.empty()) {
    canvas = root.append('g')
      .attr('class', 'chart-canvas')
      .attr('transform', 'translate(0,0)');
  }

  return canvas;
};
