import { Component } from '@angular/core';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { AbstractSnowCannonComponentButtonComponent } from '../abstract-snow-cannon-component-button.component';

@Component({
  selector: 'ss-snow-cannon-component-button-heater',
  templateUrl: './component.html',
  styleUrls: []
})
export class SnowCannonComponentButtonHeaterComponent extends AbstractSnowCannonComponentButtonComponent {

  constructor(cs: ConfigureClientService) {
    super(cs);
  }

  public toggleHeater() {
    if (this.snowCannon.commandStatusRingHeating) {
      this.configureSnowCannon({
        commandRingHeatingOff: true
      });
    } else {
      this.configureSnowCannon({
        commandRingHeatingOn: true
      });
    }
  }

}
