import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ReportExport } from '../../../../../../../common';
import { ReportingReportExportService } from '../../report-export.service';

@Component({
  selector: 'ss-recent',
  templateUrl: './recent.component.html',
  styleUrls: ['./recent.component.sass']
})
export class ReportingExportsRecentComponent implements OnInit, OnDestroy {

  public exports: ReportExport[] = [];
  private recentExportChangedSubscription: Subscription;
  public isDownlaodInProgress = false;

  constructor(private rres: ReportingReportExportService) {
    this.exports = this.rres.recentExports;
  }

  ngOnInit() {
    this.recentExportChangedSubscription = this.rres.recentExportChanged
      .subscribe(() => {
        this.exports = this.rres.recentExports;
      });
  }

  ngOnDestroy() {
    if(this.recentExportChangedSubscription) {
      this.recentExportChangedSubscription.unsubscribe();
    }
  }

  public download(e: ReportExport) {
    this.isDownlaodInProgress = true;
    this.rres.download(e).subscribe({complete: () => {
      this.isDownlaodInProgress = false;
    }, error: (err) => {
      console.error('download failed', err);
      this.isDownlaodInProgress = false;
    }});
  }

  public ident(row: any): any {
    return row.id;
  }

}
