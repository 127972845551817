<div class="vertical"
    [ngClass]="{
        'in-progress': !snowCannon.canChangePositionOrOscillation
    }"
    *ngIf="snowCannon">
    <div class="circle">
        <div class="decor"></div>
    </div>
    <div class="scale">
        <div class="decor"></div>
        <div class="display" [ngStyle]="{
            transform: 'rotate(-' + (inclinationAngle || 0) + 'deg)'
        }"></div>
    </div>
    <div [ngStyle]="{
            transform: 'rotate(-' + (inclinationAngle || 0) + 'deg)'
        }" class="direction button">
        <button [disabled]="snowCannon.isLocked"
            (mousedown)="putInclinationInProgress()"
            (touchstart)="putInclinationInProgress()"
            (window:mouseup)="releaseMoveProgress()"
            (window:touchend)="releaseMoveProgress()"
            (window:mousemove)="moveInclinationIndicator($event)"
            (window:touchmove)="moveInclinationIndicator($event)"
            class="move">
            <svg>
                <use xlink:href='#icon-move-linked'/>
            </svg>
        </button>
        <button [disabled]="snowCannon.isLocked"
            (click)="cancelMovement()"
            class="stop"
            [ngStyle]="{
                transform: 'rotate(' + (inclinationAngle || 0) + 'deg)'
            }">
            <svg>
                <use xlink:href='#icon-stop'/>
            </svg>
        </button>
        <div [ngStyle]="{
                transform: 'rotate(' + inclinationAngle + 'deg)'
            }"
            [ngClass]="{
                show: moveInclinationInProgress
            }"
            class="label">{{ inclinationAngle|number }}&deg;</div>
    </div>
    <div [ngStyle]="{
            transform: 'rotate(-' + (snowCannon.tubeVerticalStartAngle || 0) + 'deg)'
        }"
        class="position start">
        <div></div>
    </div>
    <div [ngStyle]="{
            transform: 'rotate(-' + (snowCannon.tubeVerticalCurrentAngle || 0) + 'deg)'
        }"
        class="position current">
        <div></div>
    </div>
</div>
