import { subjectFieldMapping } from '..';
import { ConvertUnit, isArray, isNumber, isObject } from '../../..';
import { ReportPartView } from '../part-view';
import { ReportResultView } from '../result-view';

export class ReportAggrDiffPartView extends ReportPartView {
  public calculate(resultView: ReportResultView) {
    this.calculateAutoLabel(resultView);
    if(this.part.series.length === 1) {
      const type = this.part.series[0].subjectType;
      const field = this.part.series[0].subjectField;
      const id = this.part.series[0].subjectId;

      if(subjectFieldMapping[type] && subjectFieldMapping[type].fields[field]) {
        const dataByIds = this.getDataByIds(resultView);

        if(!isObject(dataByIds[id]) || !isArray(dataByIds[id][field])) {
          return;
        }

        const rawLabels = (dataByIds[id].labels as number[] || []);
        const rawValues = (dataByIds[id][field] as number[] || []);
        const labels = [];
        const values = [];
        rawValues.forEach((v, i) => {
          if(isNumber(v)) {
            labels.push(rawLabels[i]);
            values.push(rawValues[i]);
          }
        });
        let minLabel = Infinity;
        let minIndex = 0;
        let maxLabel = -Infinity;
        let maxIndex = 0;
        labels.forEach((l, i) => {
          if(minLabel > l) {
            minLabel = l;
            minIndex = i;
          }
          if(maxLabel < l) {
            maxLabel = l;
            maxIndex = i;
          }
        });

        if(values.length > 0) {
          const first = values[minIndex];
          const last = values[maxIndex];
          const diff = Math.abs(last - first);
          const scalarData = { value: diff };
          ConvertUnit(
            subjectFieldMapping[type].fields[field].unitType,
            subjectFieldMapping[type].fields[field].storedUnitOverride
          )(scalarData, 'value');
          scalarData.value = diff;
          this.scalarData = scalarData as any;
        }
      }
    }
  }
}
