
export const jsonEqual = (a, b): boolean => JSON.stringify(a) === JSON.stringify(b);

export const isArray = (arg: any): boolean => Array.isArray(arg);

export const isFunction = (arg: any): boolean => typeof arg === 'function';

export const isObject = (arg: any): boolean => typeof arg === 'object' && arg !== null;

export const isBoolean = (arg: any): boolean => typeof arg === 'boolean';

export const isString = (arg: any): boolean => typeof arg === 'string';

export const isNumber = (arg: any): boolean => typeof arg === 'number' && !isNaN(arg);

export const isNullOrUndefined = (arg: any): boolean => arg === undefined || arg === null;

export const isNull = (arg: any): boolean => arg === null;

export const isUndefined = (arg: any): boolean => arg === undefined;

export const replaceAccents = (s: string): string => `${s}`.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const toSearchString = (s: string): string => replaceAccents(s).toLowerCase().trim();
