import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ss-power-sources',
  templateUrl: './power-sources.component.html',
  styleUrls: ['./power-sources.component.sass']
})
export class PowerSourcesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
