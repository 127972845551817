import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Schedule, ScheduleDay } from 'src/app/shared/models';

export class TimeRangePopupRequest {
  centerY: number;
  centerX: number;
  maxTailWidth: number;
  schedule: Schedule;
  timeRange: ScheduleDay;
}

@Component({
  selector: 'ss-time-range-popup',
  templateUrl: './time-range-popup.component.html',
  styleUrls: ['./time-range-popup.component.sass']
})
export class TimeRangePopupComponent implements OnInit {

  constructor() { }

  @Input()
  get data(): TimeRangePopupRequest {
    return this._data;
  }
  set data(data: TimeRangePopupRequest) {
    this._data = data;
    if (this._data) {
      this._data.timeRange.isEdited = true;
      setTimeout(() => {
        this.isVisible = true;
      }, 10);
    }
  }

  @Input()
  canDelete = false;

  @Output()
  needsSaving: EventEmitter<Schedule> = new EventEmitter();

  isVisible = false;

  private _data: TimeRangePopupRequest;

  remove() {
    this.data.schedule.removeScheduleDay(this.data.timeRange);
    this.needsSaving.emit(this.data.schedule);
    this.closeTimeRangePopup();
  }

  setHourFrom(val: number) {
    this.data.timeRange.hourFrom = val;
    this.needsSaving.emit(this.data.schedule);
  }

  setHourTo(val: number) {
    this.data.timeRange.hourTo = val;
    this.needsSaving.emit(this.data.schedule);
  }

  ngOnInit() {
  }

  closeTimeRangePopup() {
    this.data.timeRange.isEdited = false;
    this.isVisible = false;
    setTimeout(() => {
      this.data = null;
    }, 500);
  }

}
