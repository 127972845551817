import { Directive, ViewContainerRef } from '@angular/core';

export interface HeaderExtensionComponent {
  updateData(data: any);
}

@Directive({
  selector: '[ssdHeaderExtension]'
})
export class HeaderExtensionHostDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}
