import { PowerSource, ResortArea } from '.';
import { ConvertUnit, DeviceSystemType } from '../../../../../common';
import { AbstractDevice } from './abstract-device.model';

export class ElectricalLine extends AbstractDevice {
  systemType: DeviceSystemType = 'ElectricalLine';

  points: any;
  symbol: string;
  powerSource: string;
  powerSourceRef: PowerSource;
  resortAreaRefs: Map<string, ResortArea> = new Map();
  configuration: {
    hasMeteredPowerConsumption: boolean;
    hasCurrentPowerDraw: boolean;
    hasAveragePowerDraw: boolean;
    hasReactivePowerDraw: boolean;
    hasAverageCurrentOfAllPhases: boolean;
  };

  @ConvertUnit('powerDraw', 'kW')
  currentPowerDraw: number;
  @ConvertUnit('powerDraw', 'kW')
  maximumPowerDraw: number;
  limitPowerDraw: number;

  @ConvertUnit('powerDraw', 'kW')
  averagePowerDraw: number;
  reactivePowerDraw: number;
  voltagePhaseL1ToN: number;
  voltagePhaseL2ToN: number;
  voltagePhaseL3ToN: number;
  voltagePhaseL1ToL2: number;
  voltagePhaseL2ToL3: number;
  voltagePhaseL3ToL1: number;
  currentPhaseL1: number;
  currentPhaseL2: number;
  currentPhaseL3: number;
  averageVoltagePhaseToNeutral: number;
  averageVoltagePhaseToPhase: number;
  averageCurrentOfAllPhases: number;

  meteredPowerConsumption: number;

  isLocked = false;


  getResortAreas(): ResortArea[] {
    return Array.from(this.resortAreaRefs.values());
  }

  countResortAreas(): number {
    return Array.from(this.resortAreaRefs.values()).length;
  }
}
