import { D3 } from '..';
import { TimedChartConfigSeries } from '../..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { D3DrawerData } from '../../models/charts/d3-drawer-data.model';
import { getYTickFormat } from './get-y-tick-format.function';

export const drawYAxisPointDefault = (
  state: D3DrawerChartState,
  data: D3DrawerData,
  series: TimedChartConfigSeries,
  seriesIndex: number,
  min: number,
  max: number,
  draw: 'left'|'right'|'no'
): D3DrawerChartState => {

  if(series.fixedMax && !isNaN(series.fixedMax)) {
    max = Math.max(series.fixedMax, max);
  }
  if (max < 1 || isNaN(max)) {
    max = 1;
  }

  let range: [number, number] = [state.viewBoxHeight - 5, state.config.legend ? 25 : 5];
  if(state.config.xAxisType !== 'invisible') {
    range = [state.viewBoxHeight - 15, state.config.legend ? 30 : 10];
  }

  let barsYAxisScale = state.generatedValuesStorage.get(`series${seriesIndex}YAxisScale`);
  if (!barsYAxisScale) {
    barsYAxisScale = D3.scaleLinear();
    state.generatedValuesStorage.set(`series${seriesIndex}YAxisScale`, barsYAxisScale);
  }
  barsYAxisScale
    .domain([ min, max ])
    .nice(4)
    .range(range);

  if(draw === 'no') {
    return state;
  }

  let barsYAxis = state.generatedValuesStorage.get(`series${seriesIndex}YAxis`);
  if(!barsYAxis) {
    if(draw === 'left') {
      barsYAxis = D3.axisLeft(barsYAxisScale);
    } else {
      barsYAxis = D3.axisRight(barsYAxisScale);
    }
    state.generatedValuesStorage.set(`series${seriesIndex}YAxis`, barsYAxis);
  }
  const format = series.valueFormatter || getYTickFormat;
  barsYAxis
    .ticks(4)
    .tickSize(5)
    .tickPadding(10)
    .tickFormat((val, ind) => format(val, state, series));

  let barsYAxisGroup = state.generatedCanvas.select(`.y-axis-series${seriesIndex}`);
  if(barsYAxisGroup.empty()) {
    barsYAxisGroup = state.generatedCanvas.append('g');
  }
  barsYAxisGroup
    .attr('class', `y-axis-series${seriesIndex}`)
    .attr('stroke-width', 0)
    .style('color', '#B9BBC8')
    .style('font-size', '12')
    .style('font-family', 'Montserrat')
    .call(barsYAxis);
  if(state.config.yAxisType === 'single-with-line' || state.config.yAxisType === 'double-with-line') {
    barsYAxisGroup.selectAll('path').attr('stroke-width', 1);
    barsYAxisGroup.selectAll('line').attr('stroke-width', 1);
  }

  if(draw === 'left') {
    barsYAxisGroup.attr('transform', 'translate(' + 85 + ',0)');
  } else {
    barsYAxisGroup.attr('transform', 'translate(' + (state.viewBoxWidth - 85) + ',0)');
  }
  barsYAxisGroup.call(barsYAxis);

  return state;
};
