<div class="overlay" [ngClass]="{'visible': isVisible}">
  <ss-page [modal]="true">
    <ss-page-header>
      <ss-page-header-title i18n="Przetwarzanie...|Tytuł modala z paskiem postepu 'Przetwarzanie'@@massOperationsProgressProcessingTitle">Przetwarzanie...</ss-page-header-title>
    </ss-page-header>

    <div class="progressbar">
      <div class="display-bar" [ngStyle]="{ width: percent+'%' }"></div>
      <p class="petcent">{{ percent|number:'1.0-0' }} %</p>
    </div>

    <ss-page-footer>
      <div></div>
      <ss-page-button-group>
        <button class="btn btn-default" (click)="moveToBackground()" i18n="Praca w tle|Praca w tle@@massOperationsProgressMoveToBackgroundButton">Praca w tle</button>
      </ss-page-button-group>
    </ss-page-footer>

  </ss-page>
</div>
