import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { isObject } from '../../../../../../common';

@Component({
  selector: 'ss-debug-table',
  templateUrl: './debug-table.component.html',
  styleUrls: ['./debug-table.component.sass']
})
export class DebugTableComponent implements OnInit {

  @Input()
  public set data(val: any) {
    this._data = val;
    this._filter = '';
    this.filterCommand.emit('');
  }
  public get data(): any {
    return this._data;
  }

  public set filter(f: string) {
    this._filter = f;
    this.filterCommand.emit(f);
  }
  public get filter(): string {
    return this._filter;
  }

  constructor() {
    this.filterCommand.pipe(debounceTime(500)).subscribe(filter => {
      const filterParts = filter.toLocaleLowerCase().split(' ');

      this.keys = this.calcKeys()
        .filter(k => filterParts.reduce((prev, v) => prev || k.toLocaleLowerCase().includes(v), false));

      if (isObject(this._data['extraParameters'])) {
        this.extraParameterKeys = (Object.keys(this._data['extraParameters']) || [])
          .filter(k => filterParts.reduce((prev, v) => prev || k.toLocaleLowerCase().includes(v), false));
      } else {
        this.extraParameterKeys = [];
      }
    });
  }
  private _data: any = {};

  public extraParameterKeys: string[] = [];
  public keys: string[] = [];
  private _filter = '';

  private filterCommand: EventEmitter<string> = new EventEmitter();

  private calcKeys(): string[] {
    return (Object.keys(this._data) || []).filter(k => k !== 'extraParameters');
  }

  ngOnInit() {
  }

}
