<ss-page>
    <form #form="ngForm" autocomplete="off" (submit)="save()">
        <ss-page-header>
            <ss-page-header-title *ngIf="user.id"
              i18n="Edytuj użytkownika|Tytuł strony@@userProfileTitleEdit">Edytuj użytkownika '{{ user.email }}'</ss-page-header-title>
            <ss-page-header-title *ngIf="!user.id"
              i18n="Dodaj nowego użytkownika|Tytuł strony@@userProfileTitleAdd">Dodaj nowego użytkownika</ss-page-header-title>
            <ss-page-button-group>
                <button type="button" [routerLink]="'../../list'" class="btn btn-default"
                  i18n="Anuluj|Anuluj na panelu Dane konta@@userProfileCancelButton">Anuluj</button>
                <button type="submit" [disabled]="!form.valid" class="btn btn-primary"
                  i18n="Zapisz zmiany|Zapisz zmiany na panelu Dane konta@@userProfileAccountPanelSave">Zapisz zmiany</button>
            </ss-page-button-group>
        </ss-page-header>

        <ss-page-panel *ngIf="user.id">
            <ss-d3-user-activity-chart [chartData]="chartData"></ss-d3-user-activity-chart>
        </ss-page-panel>

        <div class="row">
            <div class="col-4" *ngIf="!user.id"></div>
            <div class="col-4 pr-1">
                <ss-page-panel panelTitle="Dane konta"
                  i18n-panelTitle="Dane konta|Tytuł panelu Dane konta@@userProfileAccountPanelTitle">
                    <form-email required email name="user_email"
                        [disabled]="inProgress || !!user.id"
                        [(ngModel)]="email"
                        label="E-mail"
                        i18n-label="E-mail|E-mail użytkownika na panelu Dane konta@@userProfileAccountPanelEmail">
                    </form-email>
                    <form-text required name="user_name"
                        [disabled]="inProgress"
                        [(ngModel)]="name"
                        label="Imię"
                        i18n-label="Imię|Imię użytkownika na panelu Dane konta@@userProfileAccountPanelUserName">
                    </form-text>
                    <form-text required name="user_surname"
                        [disabled]="inProgress"
                        [(ngModel)]="surname"
                        label="Nazwisko"
                        i18n-label="Nazwisko|Nazwisko użytkownika na panelu Dane konta@@userProfileAccountPanelUserSurname">
                    </form-text>
                    <form-text name="user_phone"
                        [disabled]="inProgress"
                        [(ngModel)]="phone"
                        label="Telefon kontaktowy"
                        i18n-label="Telefon kontaktowy|Telefon kontaktowy użytkownika na panelu Dane konta@@userProfileAccountPanelUserPhone"
                        placeholder="Numer telefonu używany do powiadomień"
                        i18n-placeholder="Numer telefonu używany do powiadomień|placeholder pola 'Telefon kontaktowy' użytkownika na panelu Dane konta@@userProfileAccountPanelUserPhoneInfo">
                    </form-text>
                    <form-select required name="user_role"
                        [disabled]="inProgress || isOwnAccount"
                        [(ngModel)]="role"
                        label="Rola w systemie"
                        i18n-label="Rola w systemie|Rola użytkownika na panelu Dane konta@@userProfileAccountPanelRole">
                        <form-select-option value="ROLE_OBSERVER" label="Obserwator"></form-select-option>
                        <form-select-option value="ROLE_OPERATOR" label="Operator"></form-select-option>
                        <form-select-option value="ROLE_ADMIN" label="Administrator"></form-select-option>
                        <ng-container *ssdHideIfNotGranted="'ROLE_SUPERADMIN'">
                            <form-select-option value="ROLE_SUPERADMIN" label="Serwis"></form-select-option>
                        </ng-container>
                    </form-select>
                    <form-checkbox name="user_isEnabled"
                        [disabled]="inProgress || isOwnAccount || !user.id"
                        [hideLabel]="true"
                        label="Konto jest aktywne"
                        i18n-label="Konto jest aktywne|Checkbox oznaczający, że konto jest aktywne i można się na nie zalogować na panelu Dane konta@@userProfileAccountPanel"
                        [(ngModel)]="isEnabled">
                    </form-checkbox>
                    <form-checkbox name="user_isVisibleOnPreloginList"
                        [disabled]="inProgress"
                        [hideLabel]="true"
                        label="Konto jest widoczne ja liście dostępnych użytkowników przed zalogowaniem się"
                        i18n-label="Konto jest widoczne ja liście dostępnych użytkowników przed zalogowaniem się|Checkbox oznaczający, czy login użytkownika widać przed zalogowaniem@@userProfileAccountPanelAccountVisibilityBeforeLogin"
                        [(ngModel)]="isVisibleOnPreloginList">
                    </form-checkbox>
                    <form-checkbox name="user_isAllowedToLogInRemotely"
                        [disabled]="inProgress"
                        [hideLabel]="true"
                        label="Na to konto można się logować przez internet"
                        i18n-label="Na to konto można się logować przez internet|Checkbox oznaczający, czy user może się logować przez internet@@userProfileAccountPanelAccountAllowedToLogInRemotely"
                        [(ngModel)]="isAllowedToLogInRemotely">
                    </form-checkbox>
                </ss-page-panel>
            </div>
            <div class="col-8 pl-1 d-flex" *ngIf="user.id">
                <ss-page-panel panelTitle="Ostatnie akcje wykonane w systemie" i18n-panelTitle="Ostatnie akcje wykonane w systemie|Ostatnie akcje wykonane w systemie na panelu Historii akcji@@userProfileLatestActionsTitle">
                <p *ngIf="atFrom && atTo">{{ atFrom|date:'yyyy-MM-dd HH:mm:ss' }} - {{ atTo|date:'yyyy-MM-dd HH:mm:ss' }}</p>

                <table class="table my-3">
                  <thead>
                    <tr>
                      <td colspan="2" i18n="Data|Data na panelu Historii akcji@@userProfileLatestActionsTableDate">Data</td>
                      <td colspan="3" i18n="Akcja|Akcja na panelu Historii akcji@@userProfileLatestActionsTableAction">Akcja</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let at of atData">
                      <td>{{ (1000 * at.created)|date:'yyyy-MM-dd' }}</td>
                      <td>{{ (1000 * at.created)|date:'HH:mm:ss' }}</td>
                      <td>{{ at.action_type }}</td>
                      <td>{{ at.object_symbol }}</td>
                      <td>{{ at.action_description }}</td>

                    </tr>
                  </tbody>
                </table>
                </ss-page-panel>
            </div>
        </div>
        <ss-page-footer>
            <ss-page-button-group>
              <button
                *ngIf="user.id"
                [disabled]="inProgress || isOwnAccount"
                (click)="delete()"
                type="button"
                class="btn btn-danger"
                i18n="Usuń konto|Przycisk 'Usuń konto' w stopce strony@@userProfileAdminActionDeleteAccount">Usuń konto</button>
              <button
                *ngIf="user.id"
                [disabled]="inProgress || isOwnAccount"
                (click)="resetPassword()"
                type="button"
                class="btn btn-warning"
                i18n="Resetuj hasło|Przycisk 'Resetuj hasło' w stopce strony@@userProfileAdminActionResetPassword">Resetuj hasło</button>
            </ss-page-button-group>
            <div></div>
            <ss-page-button-group>
                <button type="button" [routerLink]="'../../list'" class="btn btn-default" i18n="Anuluj|Anuluj na panelu Dane konta@@userProfileCancelButton">Anuluj</button>
                <button type="submit" [disabled]="!form.valid" class="btn btn-primary" i18n="Zapisz zmiany|Zapisz zmiany na panelu Dane konta@@userProfileAccountPanelSave">Zapisz zmiany</button>
            </ss-page-button-group>
        </ss-page-footer>
    </form>
</ss-page>
