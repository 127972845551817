import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  ElementRef,
  SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { dayjs, DAYJS } from '../../../../../../../common';
import { D3 } from '../../../../../../../common/d3';

@Component({
  selector: 'ss-reporting-single-time-selection-toolbar',
  template: '<svg style="width: 1px; height: 1px;"></svg>',
  styleUrls: ['./time-selection-toolbar.component.sass']
})
export class ReportingSingleTimeSelectionToolbarComponent implements OnInit, OnDestroy, OnChanges {

  public constructor(private elRef: ElementRef) {
    this.hostElement = this.elRef.nativeElement;

    this.dataChange.pipe(debounceTime(500)).subscribe(() => {
      if (!this.svg) {
        this.createChart();
      } else {
        this.updateChart();
      }
    });

    this.selectedDateDebouncerSubscription = this.selectedDateDebouncer
      .pipe(tap(sd => {
        if (sd) {
          const minmax = this.xAxisScale.range();
          const min = minmax[0] + this.labelWidth / 2;
          const max = minmax[1] - this.labelWidth / 2;

          const linePosition = this.xAxisScale(1000 * sd.unix());
          if (linePosition < min) {
            this.lineGroup.select('rect').attr('x', 0);
            this.lineGroup.select('text').attr('x', this.labelWidth / 2);
          } else if (linePosition > max) {
            this.lineGroup.select('rect').attr('x', -this.labelWidth);
            this.lineGroup.select('text').attr('x', -this.labelWidth / 2);
          } else {
            this.lineGroup.select('rect').attr('x', -this.labelWidth / 2);
            this.lineGroup.select('text').attr('x', 0);
          }

          this.lineGroup.select('text').text(sd.tz().format('YYYY-MM-DD HH:mm'));
          this.lineGroup.attr('transform', 'translate(' + linePosition + ', 0)');
          this.lineGroup.style('opacity', 1);
        }
      }))
      .pipe(debounceTime(100))
      .subscribe(sd => {
        this.selectedDate.emit(sd);
      });
  }

  @Input()
  public from: DAYJS;

  @Input()
  public to: DAYJS;

  @Input()
  public viewBoxHeight = 120;

  @Input()
  public viewBoxWidth = 730;

  @Output()
  public selectedDate: EventEmitter<DAYJS> = new EventEmitter();

  private selectedDateDebouncer: EventEmitter<DAYJS> = new EventEmitter();
  private selectedDateDebouncerSubscription: Subscription;

  private labelWidth = 140;
  private verticalPadding = 20;
  private statusAxisOffset = 105;

  private data: {x: number}[] = [];

  private dataChange: EventEmitter<any> = new EventEmitter();
  private hostElement: any;
  private svg;
  private g;
  private xAxisScale: D3.ScaleTime<number, number>;
  private lineGroup;

  private updateSelectedDate(sd: DAYJS) {
    this.selectedDateDebouncer.emit(sd);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.from
      || changes.to
    ) {
      this.hideLineGroup();
    }

    if (
      changes.from
      || changes.to
      || changes.viewBoxWidth
      || changes.viewBoxHeight
    ) {
      this.dataChange.emit();
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.selectedDateDebouncerSubscription.unsubscribe();
  }

  private createChart() {
    this.setChartDimensions();
    this.addGraphicsElement();

    this.updateChart();
  }

  private updateChart() {
    this.svg.attr('viewBox', '0 0 ' + this.viewBoxWidth + ' ' + this.viewBoxHeight);
    this.svg.select('rect.overlay')
      .attr('x', this.statusAxisOffset - 10)
      .attr('y', this.verticalPadding)
      .attr('width', this.viewBoxWidth - 2 * this.statusAxisOffset + 20)
      .attr('height', (this.viewBoxHeight || 40) - 2 * this.verticalPadding);

    const from = 1000 * this.from.unix();
    const to = 1000 * Math.ceil(this.to.unix() / 60) * 60;

    this.data = [{x: from}, {x: to}];

    this.drawAxis();
    this.drawLineGroup();
  }

  private setChartDimensions() {
    this.svg = D3.select(this.hostElement).append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', '0 0 ' + this.viewBoxWidth + ' ' + this.viewBoxHeight);
  }

  private addGraphicsElement() {
    this.g = this.svg.append('g')
        .attr('class', 'time-selection-toolbar')
        .attr('transform', 'translate(0,0)');

    const filter = this.svg.append('defs')
      .append('filter')
      .attr('id', 'drop-shadow-tst')
      .attr('filterUnits', 'userSpaceOnUse');

    filter.append('feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', 5);

    filter.append('feOffset')
      .attr('dx', 0)
      .attr('dy', 0);
    const feTransfer = filter.append('feComponentTransfer');

    feTransfer.append('feFuncA')
      .attr('type', 'linear')
      .attr('slope', 1);

    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode');
    feMerge.append('feMergeNode')
      .attr('in', 'SourceGraphic');
  }


  private createXAxis() {
    this.xAxisScale = D3.scaleUtc()
    .range([
      this.statusAxisOffset,
      this.viewBoxWidth - this.statusAxisOffset
    ])
      .domain([
        D3.min(this.data, d => d.x),
        D3.max(this.data, d => d.x)
      ]);

    this.svg.append('rect')
      .attr('class', 'overlay')
      .attr('width', this.viewBoxWidth)
      .attr('height', this.viewBoxHeight)
      .attr('fill', 'transparent')
      .style('pointer-events', 'all')
      .style('cursor', 'crosshair')
      .on('mouseleave', () => {
        this.hideLineGroup();
      })
      .on('mousemove', (ev: MouseEvent) => {
        if (this.xAxisScale) {
          let scaleDate = this.xAxisScale.invert(ev.offsetX);

          const minmax = this.xAxisScale.domain();
          if (minmax[0].valueOf() > scaleDate.valueOf()) {
            scaleDate = minmax[0];
          } else if (minmax[1].valueOf() < scaleDate.valueOf()) {
            scaleDate = minmax[1];
          }
          const sd = dayjs(scaleDate.valueOf());
          this.updateSelectedDate(sd);
        }
      });
  }

  private hideLineGroup() {
    if (this.lineGroup) {
      this.lineGroup.style('opacity', 0);
      this.updateSelectedDate(undefined);
    }
  }

  private drawAxis() {
    if (!this.xAxisScale) {
      this.createXAxis();
    }
    const tmin = D3.min(this.data, d => d.x);
    const tmax = D3.max(this.data, d => d.x);
    this.xAxisScale
      .range([
        this.statusAxisOffset,
        this.viewBoxWidth - this.statusAxisOffset
      ])
      .domain([
        tmin,
        tmax
      ]);
  }

  private drawLineGroup() {
    if (!this.lineGroup) {
      this.lineGroup = this.g.append('g')
        .attr('class', 'line-group');
      this.lineGroup.append('line')
        .attr('x1', 0)
        .attr('x2', 0)
        .style('filter', 'url(#drop-shadow-tst)');
      this.lineGroup.append('rect')
        .style('filter', 'url(#drop-shadow-tst)')
        .attr('height', 20)
        .attr('rx', 2);
      this.lineGroup.append('text')
        .attr('x', 0);
    }

    this.lineGroup.selectAll('line')
      .attr('y1', this.verticalPadding)
      .attr('y2', this.viewBoxHeight - this.verticalPadding);
    this.lineGroup.select('rect')
      .attr('y', this.verticalPadding)
      .attr('x', -this.labelWidth / 2)
      .attr('width', this.labelWidth);
    this.lineGroup.select('text')
      .attr('y', this.verticalPadding + 14);
  }
}
