import { Component, OnInit } from '@angular/core';
import { MeteoStation } from 'src/app/shared/models';
import { ResortService } from 'src/app/resort.service';
import { ConfigureClientService } from 'src/app/http-clients/configure.client.service';
import { ActivatedRoute } from '@angular/router';
import { ModalRouteService } from '../../modal-route.service';

@Component({
  selector: 'ss-meteo-station-configuration-modal',
  templateUrl: './meteo-station-configuration-modal.component.html',
  styleUrls: ['./meteo-station-configuration-modal.component.sass']
})
export class MeteoStationConfigurationModalComponent implements OnInit {

  public lock = false;
  public device: MeteoStation;

  constructor(
    private rs: ResortService,
    private cs: ConfigureClientService,
    private ar: ActivatedRoute,
    private mr: ModalRouteService
  ) { }

  public enableSimulation = false;
  public wetBulbTemperatureOverride = -5;
  public airTemperatureOverride = -5;
  public airHumidityOverride = 50;

  close() {
    this.mr.closeModal();
  }

  save() {
    this.lock = true;
    this.cs.configureMeteoStation(this.device, {
      airHumidityOverride: this.enableSimulation ? this.airHumidityOverride : -100,
      airTemperatureOverride: this.enableSimulation ? this.airTemperatureOverride : -100,
      wetBulbTemperatureOverride: this.enableSimulation ? this.wetBulbTemperatureOverride : -100
    }).subscribe(() => {
      this.lock = false;
      this.close();
    }, (err) => {
      console.error(err);
      this.lock = false;
    });
  }


  ngOnInit(): void {
    this.ar.paramMap.subscribe(params => {
      const deviceId = params.get('meteoStationId');
      const device: MeteoStation = this.rs.getById(deviceId);

      if (
        device
        && device.isPurelySynthetic
      ) {
        this.device = device;
        this.enableSimulation = !!device.airHumidityOverride && !!device.airTemperatureOverride && !!device.wetBulbTemperatureOverride;
        this.wetBulbTemperatureOverride = device.wetBulbTemperatureOverride || -5;
        this.airTemperatureOverride = device.airTemperatureOverride || -5;
        this.airHumidityOverride = device.airHumidityOverride || 50;

      } else {
        this.close();
      }
    });
  }

}
