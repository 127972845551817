<svg:rect
  (click)="onClick($event)"
  (mouseenter)="onMouseEnter($event)"
  (mouseleave)="onMouseLeave($event)"
  (mousedown)="onMouseDown($event)"
  (mousemove)="onMouseMove($event)"
  (mouseup)="onMouseUp($event)"
  [attr.data-symbol]="castedDrawableComponent.symbol"
  [ngClass]="{
    selected: drawable.isSelected,
    hover: drawable.isHover,
    drag: drawable.isDragged,
    error: !castedDrawableComponent.component
  }">
</svg:rect>
<ng-container *ngIf="castedDrawableComponent.component">
  <ng-container *ngIf="castedDrawableComponent.component.type === 'pump'">
    <svg:g ss-full-schema-pump [allowEdit]="allowEdit" [drawable]="castedDrawableComponent"></svg:g>
  </ng-container>
  <ng-container *ngIf="castedDrawableComponent.component.type === 'filter'">
    <svg:g ss-full-schema-filter [allowEdit]="allowEdit" [drawable]="castedDrawableComponent"></svg:g>
  </ng-container>
  <ng-container *ngIf="castedDrawableComponent.component.type === 'valve'">
    <svg:g ss-full-schema-valve [allowEdit]="allowEdit" [drawable]="castedDrawableComponent"></svg:g>
  </ng-container>
  <ng-container *ngIf="castedDrawableComponent.component.type === 'coolingTower'">
    <svg:g ss-full-schema-cooling-tower [allowEdit]="allowEdit" [drawable]="castedDrawableComponent"></svg:g>
  </ng-container>
  <ng-container *ngIf="castedDrawableComponent.component.type === 'sensor'">
    <svg:g ss-full-schema-sensor [allowEdit]="allowEdit" [drawable]="castedDrawableComponent"></svg:g>
  </ng-container>
  <ng-container *ngIf="castedDrawableComponent.component.type === 'levelIndicator'">
    <svg:g ss-full-schema-level-indicator [allowEdit]="allowEdit" [drawable]="castedDrawableComponent"></svg:g>
  </ng-container>
  <ng-container *ngIf="castedDrawableComponent.component.type === 'visualComponent'">
    <svg:g ss-full-schema-visual-component [allowEdit]="allowEdit" [drawable]="castedDrawableComponent"></svg:g>
  </ng-container>
</ng-container>
<ng-container *ngIf="allowEdit && !castedDrawableComponent.component">
  <svg:text>No componet selected</svg:text>
</ng-container>
