import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { DrawableComponent, Valve } from 'src/app/shared/models/pump-room';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: '[ss-full-schema-valve]',
  templateUrl: './valve.component.html',
  styleUrls: ['./valve.component.sass']
})
export class ValveComponent implements OnInit {

  constructor() { }

  @Input()
  public allowEdit = false;

  @Input()
  public drawable: DrawableComponent<Valve>;

  ngOnInit() {
  }

  get icon(): string {
    if (this.drawable && this.drawable.component.subType === 'check-valve') {
      return '#check-valve';
    }
    if (this.drawable && this.drawable.component.subType === 'pneumatic') {
      return '#pneumatic-valve';
    }
    if (this.drawable && this.drawable.component.subType === 'acutated') {
      return '#acutated-valve';
    }

    return '#manual-valve';
  }

  @HostBinding('class.breakdown')
  get breakdown(): boolean {
    return this.hasSensorData && ['connection-lost', 'breakdown'].includes(this.drawable.component['computedStatus']);
  }

  @HostBinding('class.working')
  get working(): boolean {
    return this.drawable && this.drawable.component['isWorking'];
  }
  @HostBinding('class.sensored')
  get hasSensorData(): boolean {
    return this.drawable && this.drawable.component['hasSensorData'];
  }
}
