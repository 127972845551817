import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, NEVER } from 'rxjs';
import { ResortService } from 'src/app/resort.service';

@Injectable({
  providedIn: 'root'
})
export class PumpRoomsDataResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private rs: ResortService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any | Observable<any> | Observable<never> {
    const pumpRooms = this.rs.getPumpRooms();
    const id = route.paramMap.get('id');

    if (!!id) {
      if (pumpRooms.findIndex(p => p.id === id) < 0) {
        if (pumpRooms.length === 1) {
          this.router.navigate(['/application/pump-rooms/detail', pumpRooms.pop().id]);
        } else {
          this.router.navigate(['/application/pump-rooms']);
        }
        return NEVER;
      }
    } else {
      if (pumpRooms.length === 1) {
        this.router.navigate(['/application/pump-rooms/detail', pumpRooms.pop().id]);
        return NEVER;
      }
    }

    return true;
  }
}
