<perfect-scrollbar>
  <div class="areas m-2 py-6">
    <div class="areas__header" *ngIf="recentAreas &&  recentAreas.length > 0">
      <p class="fs-26 color-white font-bold px-4" i18n="Ostatnio przeglądane trasy|Nagłówek listy ostatnio przeglądanych tras@@areasRecentHeader">Ostatnio przeglądane trasy</p>
    </div>
    <div class="areas__body d-flex flex-wrap px-2 py-4" *ngIf="recentAreas &&  recentAreas.length > 0">
      <div *ngFor="let area of recentAreas" class="area-column">
        <div [routerLink]="['/application', { outlets: { primary: ['resort', 'area', area.id, 'map'], modal: null } }]"
          class="area-preview">
          <div class="area-preview__header font-bold" [ngClass]="{'area-preview__header--warning': !area.operationMode}">
            <strong>{{ area.symbol }}</strong>
            <span *ngIf="area.countByBreakdown() > 0" class="poser">
              <ss-badge color="danger" size="small">{{ area.countByBreakdown() }}</ss-badge>
            </span>
          </div>
          <div class="area-preview__content">
            <div class="devices-states devices-states--small">
              <div class="devices-states__state" title="Pracuje" i18n-title="Pracuje|Nazwa stanu urządzenia@@stateWorking">
                <!-- devices-states__state--main -->
                {{ area.countByWorking() }}
                <ng-container *ngIf="area.countByWorkingInManualMode() > 0">
                  ({{ area.countByWorkingInManualMode() }})
                </ng-container>
              </div>
              <div class="devices-states__state" title="Czeka na warunki" i18n-title="Czeka na warunki|Nazwa stanu urządzenia@@stateAwaitingForConditions">
                <!-- devices-states__state--main -->
                {{ area.countByAwaitingForConditions() }}
              </div>
              <div class="devices-states__state" title="Gotowość" i18n-title="Gotowość|Nazwa stanu urządzenia@@stateReadyToWork">
                <!-- devices-states__state--main -->
                {{ area.countByReadyToWork() }}
              </div>
              <div class="devices-states__state" title="Czuwanie" i18n-title="Czuwanie|Nazwa stanu urządzenia@@stateStandingBy">
                <!-- devices-states__state--main -->
                {{ area.countByStandingBy() }}
              </div>
              <div class="devices-states__state" title="Uruchamianie" i18n-title="Uruchamianie|Nazwa stanu urządzenia@@stateStarting">
                {{ area.countByStarting() }}
              </div>
              <div class="devices-states__state" title="Usypianie" i18n-title="Usypianie|Nazwa stanu urządzenia@@stateSnoozing">
                {{ area.countBySnoozing() }}
              </div>
              <div class="devices-states__state" title="Zatrzymywanie" i18n-title="Zatrzymywanie|Nazwa stanu urządzenia@@stateStopping">
                {{ area.countByStopping() }}
              </div>
              <div class="devices-states__state" title="Przygotowanie" i18n-title="Przygotowanie|Nazwa stanu urządzenia@@statePreparing">
                {{ area.countByPreparing() }}
              </div>
            </div>
          </div>
          <div class="area-preview__chart">
            <div class="row-forced align-items-center">
              <div class="col-4">
                <p class="color-white white-space-nowrap text-right pr-2">
                  <strong class="color-green fs-20">{{ area|convertedValue:'snowProductionVolume' }}</strong>
                  <span class="fs-14 color-light-grey">{{ area|convertedUnit:'snowProductionVolume' }}</span>
                </p>
              </div>
              <div class="col-8" #miniBarChartContainer1>
                <ss-d3-linked-mini-bar-chart [viewBoxWidth]="miniBarChartContainer1.offsetWidth" tag="compare-areas" [device]="area"></ss-d3-linked-mini-bar-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="areas__bar p-4">
      <input [(ngModel)]="areasFilter" (keyup)="moveToSearchResults()" type="text" i18n-placeholder="Szukaj trasy|Placeholder w wyszukiwarce tras@@areasMapSearch" placeholder="Szukaj trasy" class="input areas__search">
    </div>
    <div class="areas__body d-flex flex-wrap px-2 py-4">
      <div *ngFor="let area of filteredAreas()" class="area-column">
        <div [routerLink]="['/application', { outlets: { primary: ['resort', 'area', area.id, 'map'], modal: null } }]"
          class="area-preview">
          <div class="area-preview__header font-bold" [ngClass]="{'area-preview__header--warning': !area.operationMode}">
            <strong>{{ area.symbol }}</strong>
            <span *ngIf="area.countByBreakdown() > 0" class="poser">
              <ss-badge color="danger" size="small">{{ area.countByBreakdown() }}</ss-badge>
            </span>
          </div>
          <div class="area-preview__content">
            <div class="devices-states devices-states--small">
              <div class="devices-states__state" title="Pracuje" i18n-title="Pracuje|Nazwa stanu urządzenia@@stateWorking">
                <!-- devices-states__state--main -->
                {{ area.countByWorking() }}
                <ng-container *ngIf="area.countByWorkingInManualMode() > 0">
                  ({{ area.countByWorkingInManualMode() }})
                </ng-container>
              </div>
              <div class="devices-states__state" title="Czeka na warunki" i18n-title="Czeka na warunki|Nazwa stanu urządzenia@@stateAwaitingForConditions">
                <!-- devices-states__state--main -->
                {{ area.countByAwaitingForConditions() }}
              </div>
              <div class="devices-states__state" title="Gotowość" i18n-title="Gotowość|Nazwa stanu urządzenia@@stateReadyToWork">
                <!-- devices-states__state--main -->
                {{ area.countByReadyToWork() }}
              </div>
              <div class="devices-states__state" title="Czuwanie" i18n-title="Czuwanie|Nazwa stanu urządzenia@@stateStandingBy">
                <!-- devices-states__state--main -->
                {{ area.countByStandingBy() }}
              </div>
              <div class="devices-states__state" title="Uruchamianie" i18n-title="Uruchamianie|Nazwa stanu urządzenia@@stateStarting">
                {{ area.countByStarting() }}
              </div>
              <div class="devices-states__state" title="Usypianie" i18n-title="Usypianie|Nazwa stanu urządzenia@@stateSnoozing">
                {{ area.countBySnoozing() }}
              </div>
              <div class="devices-states__state" title="Zatrzymywanie" i18n-title="Zatrzymywanie|Nazwa stanu urządzenia@@stateStopping">
                {{ area.countByStopping() }}
              </div>
              <div class="devices-states__state" title="Przygotowanie" i18n-title="Przygotowanie|Nazwa stanu urządzenia@@statePreparing">
                {{ area.countByPreparing() }}
              </div>
            </div>
          </div>
          <div class="area-preview__chart">
            <div class="row-forced align-items-center">
              <div class="col-4">
                <p class="color-white white-space-nowrap text-right pr-2">
                    <strong class="color-green fs-20">{{ area|convertedValue:'snowProductionVolume' }}</strong>
                  <span class="fs-14 color-light-grey">{{ area|convertedUnit:'snowProductionVolume' }}</span>
                </p>
              </div>
              <div class="col-8">
                <div #miniBarChartContainer2>
                  <ss-d3-linked-mini-bar-chart [viewBoxWidth]="miniBarChartContainer2.offsetWidth" tag="compare-areas" [device]="area"></ss-d3-linked-mini-bar-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</perfect-scrollbar>
