import { D3 } from '..';
import { dayjs } from '../..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { D3DrawerData } from '../../models/charts/d3-drawer-data.model';
import { getXTickFormatAndFunction } from './get-x-tick-format-and-filter.function';

export const drawXAxisScaleBottomAndUp = (state: D3DrawerChartState, data: D3DrawerData): D3DrawerChartState => {
  const min = D3.min(data, d => +d[state.config.labelFieldName]);
  const max = D3.max(data, d => +d[state.config.labelFieldName]);

  let range: [number, number] = [ 5, state.viewBoxWidth - 5 ];
  if(['single','single-with-line' ].includes(state.config.yAxisType)) {
    range = [ 85, state.viewBoxWidth - 20 ];
  } else if(['double','double-with-line' ].includes(state.config.yAxisType)) {
    range = [ 85, state.viewBoxWidth - 85 ];
  }

  const tickdesc = getXTickFormatAndFunction(state, min, max);

  let xAxisScale = state.generatedValuesStorage.get('xAxisScale');
  if (!xAxisScale) {
    xAxisScale = D3.scaleUtc();
    state.generatedValuesStorage.set('xAxisScale', xAxisScale);
  }
  xAxisScale
    .range(range)
    .domain([ min, max ]);

  let xAxisBandScale = state.generatedValuesStorage.get('xAxisBandScale');
  if (!xAxisBandScale) {
    xAxisBandScale = D3.scaleBand()
      .padding(0);
    state.generatedValuesStorage.set('xAxisBandScale', xAxisBandScale);
  }
  xAxisBandScale
    .range(range)
    .domain(data.map( d => `${d[state.config.labelFieldName]}`));

  let xAxis = state.generatedValuesStorage.get('xAxis');
  if (!xAxis) {
    xAxis = D3.axisBottom(xAxisScale);
    state.generatedValuesStorage.set('xAxis', xAxis);
  }
  xAxis
    .tickSize(0)
    .tickPadding(0)
    .ticks(tickdesc.ticksFilterFn)
    .tickFormat((val, ind) => dayjs(+val).tz().format(tickdesc.ticksFormat));

  let xAxisSecondary = state.generatedValuesStorage.get('xAxisSecondary');
  if (!xAxisSecondary) {
    xAxisSecondary = D3.axisTop(xAxisScale);
    state.generatedValuesStorage.set('xAxisSecondary', xAxisSecondary);
  }
  xAxisSecondary
    .tickSize(0)
    .tickPadding(0)
    .ticks(D3.utcHour.filter(d => dayjs(d).tz().hour() === 0
        || d.getTime() === min
        || d.getTime() === max))
    .tickFormat((val, ind) => dayjs(+val).tz().format('DD-MM dd'));

  let xAxisGroup = state.generatedCanvas.select('.x-axis-bottom');
  if(xAxisGroup.empty()) {
    xAxisGroup = state.generatedCanvas.append('g')
      .attr('class', 'x-axis-bottom')
      .attr('transform', 'translate(0, ' + (state.viewBoxHeight - 10) + ')')
      .attr('stroke-width', 0)
      .style('color', '#B9BBC8')
      .style('font-size', '12')
      .style('font-family', 'Montserrat');
  }

  let xAxisSecondaryGroup = state.generatedCanvas.select('.x-axis-top');
  if(xAxisSecondaryGroup.empty()) {
    xAxisSecondaryGroup = state.generatedCanvas.append('g')
      .attr('class', 'x-axis-top')
      .attr('transform', 'translate(0, ' + (state.config.legend ? 30 : 10) + ')')
      .attr('stroke-width', 0)
      .style('color', '#B9BBC8')
      .style('font-size', '12')
      .style('font-family', 'Montserrat');
  }

  xAxisGroup.call(xAxis);
  xAxisSecondaryGroup.call(xAxisSecondary);

  xAxisGroup.selectAll('.tick text')
    .attr('text-anchor', 'middle');
  xAxisGroup.select('.tick:first-of-type text')
    .attr('text-anchor', 'start');
  xAxisGroup.select('.tick:last-of-type text')
    .attr('text-anchor', 'end');

  xAxisSecondaryGroup.selectAll('.tick text')
    .attr('text-anchor', 'middle');
  xAxisSecondaryGroup.select('.tick:first-of-type text')
    .attr('text-anchor', 'start');
  xAxisSecondaryGroup.select('.tick:last-of-type text')
    .attr('text-anchor', 'end');

  return state;
};
