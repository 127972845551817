import { D3 } from '..';
import { D3DrawerChartState } from '../../models/charts/d3-drawer-chart-state.model';
import { drawXAxisScaleBottom } from './draw-x-axis-scale-bottom.function';
import { drawXAxisScaleInvisible } from './draw-x-axis-scale-invisible.function';
import { drawYAxisBarDefault } from './draw-y-axis-bar-default.function';
import { drawYAxisLineDefault } from './draw-y-axis-line-default.function';
import { drawXAxisScaleBottomAndUp } from './draw-x-axis-scale-bottom-and-up.function';
import { drawYAxisPointDefault } from './draw-y-axis-point-default.function';
import { isNullOrUndefined, UnitType } from '../..';

export const drawAxis = (state: D3DrawerChartState): D3DrawerChartState => {
  switch(state.config.xAxisType) {
    case 'invisible':
      state = drawXAxisScaleInvisible(state, state.defaultData);
      break;
    case 'bottom':
      state = drawXAxisScaleBottom(state, state.defaultData);
      break;
    case 'up-and-bottom':
      state = drawXAxisScaleBottomAndUp(state, state.defaultData);
      break;
  }

  const seriesByUnitMap: Map<UnitType, {
    draw: 'left'|'right'|'no';
    domain: { min: number; max: number };
  }> = new Map();
  let drawPositionIndex = 0;
  state.config.series.forEach((series, seriesIndex) => {
    const u = series.unit ? series.unit : 'scalar';

    if(!seriesByUnitMap.has(u)) {
      let draw: 'left'|'right'|'no' = 'no';
      if(
        ['single','single-with-line' ].includes(state.config.yAxisType)
        && drawPositionIndex === 0
      ) {
        draw = 'left';
      } else if(
        ['double','double-with-line' ].includes(state.config.yAxisType)
        && drawPositionIndex <= 1
      ) {
        draw = drawPositionIndex % 2 === 0 ? 'left' : 'right';
      }
      seriesByUnitMap.set(u, {
        draw,
        domain: { min: Infinity, max: -Infinity }
      });
      drawPositionIndex++;
    }

    const data = state.seriesSpecificData[seriesIndex]
      ? state.seriesSpecificData[seriesIndex]
      : state.defaultData;

    const prefilteredData = data.filter(d =>
      !isNullOrUndefined(d[series.valueFieldName])
      && !isNaN(d[series.valueFieldName] as any)
    );
    let min = Math.floor(D3.min(prefilteredData, d => +d[series.valueFieldName]));
    let max = Math.ceil(D3.max(prefilteredData, d => +d[series.valueFieldName]));

    min = isNaN(min) ? 0 : min;
    max = isNaN(max) ? 1 : max;

    seriesByUnitMap.get(u).domain.min = Math.min(seriesByUnitMap.get(u).domain.min, min);
    seriesByUnitMap.get(u).domain.max = Math.max(seriesByUnitMap.get(u).domain.max, max);
  });

  state.config.series.forEach((series, seriesIndex) => {
    const data = state.seriesSpecificData[seriesIndex]
      ? state.seriesSpecificData[seriesIndex]
      : state.defaultData;

    const u = series.unit ? series.unit : 'scalar';
    const unitParams = seriesByUnitMap.get(u);

    switch(series.seriesType) {
      case 'bar':
      case 'bar-w-rounded-top':
        state = drawYAxisBarDefault(state, data, series, seriesIndex, 0, unitParams.domain.max, unitParams.draw);
        break;
      case 'line-w-rounded-end':
      case 'line-stepped':
      case 'line':
        state = drawYAxisLineDefault(state, data, series, seriesIndex, unitParams.domain.min, unitParams.domain.max, unitParams.draw);
        break;
      case 'point':
        state = drawYAxisPointDefault(state, data, series, seriesIndex, 0, unitParams.domain.max, unitParams.draw);
        break;
    }
  });

  return state;
};
