import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SettingsService } from '../../../settings.service';
import {
  ReportDefinition,
  ReportDefinitionDuration,
  ReportDefinitionPrecision,
  ReportExportFormat,
  ReportPart,
  ReportResult,
  ReportResultView,
  ReportSeries
} from '../../../../../../common';
import { ReportingClientService } from '../../../http-clients/reporting.client.service';
import { ReportingService } from '../reporting.service';
import { ConfirmModalService } from '../../confirm-modal.service';
import { ModalRouteService } from '../../modal-route.service';
import { DragulaService } from 'ng2-dragula';
import { ResizedEvent } from '../../../resized-event.directive';
import { HeaderService } from '../../../header.service';
import { ReportingHeaderExtensionComponent } from '../header-extension/header-extension.component';
import { DAYJS, dayjs, isArray, isString } from '../../../../../../common';
import { ReportingReportExportService } from '../report-export.service';

@Component({
  selector: 'ss-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.sass']
})
export class ReportingSingleReportComponent implements OnInit, OnDestroy {

  public readonly DG_PARTS: string = 'DG_PARTS';

  constructor(
    private titleService: HeaderService,
    private router: Router,
    private settings: SettingsService,
    private route: ActivatedRoute,
    private rds: ReportingService,
    private rcs: ReportingClientService,
    private confirmModalService: ConfirmModalService,
    private mr: ModalRouteService,
    private dragulaService: DragulaService,
    private rrcs: ReportingReportExportService
  ) {
    if(!dragulaService.find(this.DG_PARTS)) {
      dragulaService.createGroup(this.DG_PARTS, {
        revertOnSpill: true,
        moves: (el, container, handle) => handle.className === 'col-auto handler part-handle'
      });
    }
  }

  public refresher = '';
  public result: ReportResult;
  public resultView: ReportResultView;

  public isLoading = true;
  private _isEditMode = false;
  public get isEditMode(): boolean {
    return this._isEditMode;
  }
  public set isEditMode(v: boolean) {
    this._isEditMode = v;
    if(v) {
      this.clearTimeout();
    } else {
      this.updateView();
    }
  }
  public selectedDate: DAYJS;

  public offsetHeight = 1800;
  public offsetWidth = 1800;
  public secondaryOffsetWidth = 1800;

  public onResize(ev: ResizedEvent) {
    this.offsetHeight = ev.newHeight;
    this.offsetWidth = ev.newWidth;
    this.secondaryOffsetWidth = ev.newWidth - 40;
  }

  public liveReloadTimeout;

  ngOnInit(): void {
    const minStartDate: DAYJS = dayjs(1000 * this.settings.get<number>('timestampOfOldestStatusEntry'));

    this.route.data.subscribe((data: { definition: ReportDefinition; result: ReportResult }) => {
      let result: ReportResult;

      if(!data.result) {
        let from = dayjs().subtract(6, 'days');
        if (from.isBefore(minStartDate)) {
          from = minStartDate;
        }

        result = ReportResult.create({
          ...data.definition,
          from: from.startOf('d'),
          to: dayjs().endOf('d'),
          displayTo: dayjs().endOf('d'),
        });
      } else {
        result = ReportResult.create({ ... data.result });
      }

      this.isEditMode = result.isHidden;
      this.result = result;

      this.titleService.setUpdateHeader('menuReports', ReportingHeaderExtensionComponent, { report: this.result });

      result.parts.forEach(p => {
        p.series.forEach(s => {
          if(!s.subjectId) {
            const dev = this.rds.getDevidesBySubjectType(s.subjectType);
            if(dev.length <= 0) {
              throw Error('invalid device type ' + s.subjectType + ' or no devices of that type');
            }
            s.subjectId = dev[0].id;
          }
        });
      });

      this.loadDataForResultSet(result);
    });
  }

  public ngOnDestroy() {
    this.clearTimeout();
  }

  public setSelectedDate(selectedDate: DAYJS) {
    this.selectedDate = selectedDate;
  }

  private loadDataForResultSet(result: ReportResult, showLoadingScreen: boolean = true) {
    this.isLoading = showLoadingScreen;

    this.rds.fetch(result).subscribe(res => {
      this.resultView = ReportResultView.create(res, this.resultView);

      this.isLoading = false;
      this.updateView();

      this.titleService.setUpdateHeader('menuReports', ReportingHeaderExtensionComponent, { report: this.resultView });
    });

  }

  public updateResult(data: any) {
    this.loadDataForResultSet(ReportResult.create({
      ...this.resultView,
      ...data
    }));
  }

  private clearTimeout() {
    clearTimeout(this.liveReloadTimeout);
    this.liveReloadTimeout = null;
  }

  public updateView(data?: any) {
    if(!this.resultView) {
      return;
    }

    this.refresher = Date.now() + '-' + Math.random();
    if(!this.isLoading) {
      this.resultView.update(data);
    }

    if(this.resultView.isLive) {
      // turn into is live panic mode
      const from = dayjs().subtract(1, 'd').startOf('day');
      const to = dayjs().endOf('day');
      const displayTo = dayjs().endOf('hours');
      const precision: ReportDefinitionPrecision = '5m';
      const duration: ReportDefinitionDuration = '4h';

      if(
        this.resultView.precision !== precision
        || this.resultView.duration !== duration
        || !this.resultView.from.isSame(from, 'minutes')
        || !this.resultView.to.isSame(to, 'minutes')
        || !this.resultView.displayTo.isSame(displayTo, 'minutes')
      ) {
        this.resultView.update({ displayTo });
        this.updateResult({
          from,
          to,
          precision,
          duration,
          displayTo
        });
        this.refresher = Date.now() + '-' + Math.random();
        return;
      }
    }

    if(this.resultView.isLive && !this.liveReloadTimeout) {
      this.liveReloadTimeout = setTimeout(() => {
        this.clearTimeout();
        if(!this.isEditMode) {
          this.loadDataForResultSet(this.resultView, false);
        }
      }, 30000);
    } else if(!this.resultView.isLive) {
      this.clearTimeout();
    }
  }

  public export(format: ReportExportFormat) {
    this.rrcs.scheduleExport(this.resultView, format).subscribe((resp) => {
      console.log('exporting done!', resp);
    }, (err) => {
      console.log('exporting failed!', err);
    });
  }

  public editPart(part: ReportPart) {
    const uuids: string[] = [];
    (this.resultView.parts || []).forEach(p => {
      (p.series || []).forEach(s => {
        if(!uuids.includes(s.subjectId)) {
          uuids.push(s.subjectId);
        }
      });
    });

    this.mr.showDialog('reporting-part-creator-modal', (resp: ReportPart) => {
      if(resp && resp.partType && isArray(resp.series)) {
        part.partType = resp.partType;
        part.series = [... resp.series].map(s => ReportSeries.create(s, true));

        this.resultView.updatePart(part);

        this.updateResult({});
      }
    }, {
      part,
      usedDeviceUuids: uuids
    });
  }

  public addPart() {
    this.editPart(new ReportPart());
  }

  public removePart(part: ReportPart) {
    this.resultView.removePart(part);

    this.updateResult({});
  }

  public reorderResultParts($event: ReportPart[]) {
    this.resultView.reorderParts($event);

    this.updateView();
  }

  public renameReport() {
    const text = $localize`:Zmień nazwę raportu|Tytuł modala@@reportingRenameReportModalTitle:Zmień nazwę raportu`;
    this.mr.showDialog('rename-modal', resp => {
      if(isString(resp)) {
        this.resultView.reportName = resp;
      }
    }, {
      displayTitleText: text,
      displayInputLabel: text,
      renameString: this.resultView.reportName
    });
  }

  public renamePart(part: ReportPart) {
    const text =
      $localize`:Zmień nazwę wykresu lub podsumowania|Tytuł modala@@reportingRenamePartModalTitle:Zmień nazwę wykresu lub podsumowania`;
    this.mr.showDialog('rename-modal', resp => {
      if(isString(resp)) {
        part.partName = resp;

        this.updateView();
      }
    }, {
      displayTitleText: text,
      displayInputLabel: text,
      renameString: part.partName
    });
  }

  public saveReportDefinition(saveAsCopy: boolean) {
    this.isLoading = true;

    this.rcs.saveReportDefinition(ReportDefinition.create({
      ... this.resultView,
      id: saveAsCopy ? false : this.resultView.id
    })).subscribe(p => {
      this.isEditMode = false;
      this.isLoading = false;

      this.router.navigate(['/', 'application', 'reporting', p.id]);
    });
  }

  public removeReportDefinition() {
    this.confirmModalService.open($localize`:Do you want to remove this report definition?
      |Treść powiadomienia z potwierdzeniem usunięcia definicji raportu
      @@reportingSingleReportRemoveDefinition:
      Do you want to remove this report definition?
    `).subscribe(resp => {
      this.isLoading = true;
      if(resp) {
        this.rcs.removeReportDefinition(this.resultView.id).subscribe(() => {
          this.isLoading = false;
          this.router.navigate(['/', 'application', 'reporting', 'list']);
        });
      }
    });
  }

}
