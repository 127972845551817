import { Injectable } from '@angular/core';
import { ResortService } from './resort.service';
import { ResortConfiguration, ResortConfigurationChecks } from './shared/models';

@Injectable({
  providedIn: 'root'
})
export class ResortConfigurationService {

  constructor(private rs: ResortService) { }

  public getResortConfiguration(): ResortConfiguration {
    return this.rs.getResort().configuration;
  }

  public checkCapability(check: ResortConfigurationChecks): boolean {
    return !!this.rs.getResort().configuration[check];
  }

  public getAllowedPath(): string [] {
    if(this.checkCapability('hasSnowmakingCapabilities')) {
      return ['/application/resort'];
    } else if(this.checkCapability('hasPumprooms')) {
      return ['/application/pump-rooms'];
    } else if(this.checkCapability('hasPowerSources')) {
      return ['/application/power-sources'];
    }

    return ['/application/weather-and-schedules'];
  }
}
