<div [ngClass]="{ 'disabled': lock }" id="mass-operations-modal">
    <ss-page [modal]="true">
        <ss-page-header>
            <ss-page-header-title i18n="Operacje masowe na X armatkach|Operacje masowe na X armatkach@@massOperationsModalTitle">Operacje masowe na {{ ids.length }} armatkach</ss-page-header-title>
        </ss-page-header>
        <ss-page-panel>
            <div class="row">
                <div class="col">
                    <div [ngClass]="{ 'disabled': manualIds.length <= 0 }">
                        <h4 i18n="Przełączenie X armatek w tryb sterowania przez system Snowmatic|Przełączenie X armatek w tryb sterowania przez system Snowmatic@@massOperationsModalInfosSnowmatic">Przełączenie {{ manualIds.length }} armatek w tryb sterowania przez system Snowmatic</h4>
                        <div (click)="setOperationModeAuto()" class="mode mode--auto" i18n="Snowmatic|Tryb pracy Snowmatic@@operationModeSnowmatic">Snowmatic</div>
                    </div>
                </div>

                <div class="col">
                    <div [ngClass]="{ 'disabled': autoIds.length <= 0 }">
                        <h4 i18n="Przełączenie X armatek w tryb sterowania przez system Operatora|Przełączenie X armatek w tryb sterowania przez system Operatora@@massOperationsModalInfosOperator">Przełączenie {{ autoIds.length }} armatek w tryb sterowania przez system Operatora</h4>
                        <div (click)="setOperationModeManual()" class="mode mode--manual" i18n="Operator|Tryb pracy Operator@@operationModeOperator">Operator</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div [ngClass]="{ 'disabled': stoppedManualIds.length <= 0 }">
                        <h4 i18n="Start X armatek w trybie sterowania przez operatora|Start X armatek w trybie sterowania przez operatora@@massOperationsModalInfosStart">Start {{ stoppedManualIds.length }} armatek w trybie sterowania przez operatora</h4>
                        <ss-control-button action="start"
                            (click)="startCannons()">Start</ss-control-button>
                    </div>
                </div>
                <div class="col">
                    <div [ngClass]="{ 'disabled': startedManualIds.length <= 0 }">
                        <h4 i18n="Stop X armatek w trybie sterowania przez operatora|Stop X armatek w trybie sterowania przez operatora@@massOperationsModalInfosStop">Stop {{ startedManualIds.length }} armatek w trybie sterowania przez operatora</h4>
                        <ss-control-button action="stop"
                            (click)="stopCannons()">Stop</ss-control-button>

                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col">
                  <div>
                      <h4 i18n="Jakość śniegu|Jakość śniegu@@massOperationsModalInfosSnowQuality">Jakość śniegu</h4>
                      <div class="row">
                        <div class="col-auto">
                          <ss-snow-quality (snowQualityChange)="setSnowQuality($event)"
                              [snowQualityType]="0"
                              [disableSnowQualitySelection]="true"></ss-snow-quality>
                        </div>
                        <div class="col"></div>
                      </div>
                  </div>
              </div>
              <div class="col">
                <div *ngIf="canSetStartTemperature.length > 0">
                  <h4 i18n="Temperatura startu|Temperatura startu@@massOperationsModalInfosStartTemperature">Temperatura startu na {{ canSetStartTemperature.length }} armatkach</h4>
                  <div class="row">
                    <div class="col-auto mr-2">
                      <form-number [hideLabel]="true" [(ngModel)]="startTemperature" [min]="-15" [step]=".1" [max]="5"></form-number>
                    </div>
                    <div class="col">
                      <button (click)="setStartTemperature()" class="btn w-100 btn-primary"
                        style="height: 50px"
                        i18n="Zapisz|Zapisz@@massOperationsModalSaveButton">Zapisz</button>
                    </div>
                  </div>
                </div>
                <div *ngIf="canSetStartWaterFlow.length > 0">
                  <h4 i18n="Przepływ startu|Przepływ startu@@massOperationsModalInfosStartWaterFlow">Przepływ startu na {{ canSetStartWaterFlow.length }} armatkach</h4>
                  <div class="row">
                    <div class="col-auto mr-2">
                      <form-number [hideLabel]="true" [(ngModel)]="startWaterFlow" [min]="0.7" [step]=".1" [max]="11.1"></form-number>
                    </div>
                    <div class="col">
                      <button (click)="setStartWaterFlow()" class="btn w-100 btn-primary"
                        style="height: 50px"
                        i18n="Zapisz|Zapisz@@massOperationsModalSaveButton">Zapisz</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-auto">
                  <div>
                      <h4 i18n="Grzałki|Grzałki@@massOperationsModalInfosHeaters">Grzałki</h4>
                      <ss-snow-cannon-component-button [isActive]="true" mode="auto" (click)="enableHeaters()"
                          class="mx-1" size="extra-small" deviceType="heater"></ss-snow-cannon-component-button>
                      <ss-snow-cannon-component-button [isActive]="false" mode="manual" (click)="disableHeaters()"
                          class="mx-1" size="extra-small" deviceType="heater"></ss-snow-cannon-component-button>
                  </div>
              </div>
              <div class="col"></div>
              <div class="col-auto">
                  <div>
                      <h4 i18n="Lampa|Lampa@@massOperationsModalInfosLamp">Lampa</h4>
                      <ss-snow-cannon-component-button [isActive]="true" (click)="enableLamp()" mode="manual"
                          class="mx-1" size="extra-small" deviceType="lamp"></ss-snow-cannon-component-button>
                      <ss-snow-cannon-component-button [isActive]="false" (click)="setLampToAuto()" mode="auto"
                          class="mx-1" size="extra-small" deviceType="lamp"></ss-snow-cannon-component-button>
                      <ss-snow-cannon-component-button [isActive]="false" (click)="disableLamp()" mode="manual"
                          class="mx-1" size="extra-small" deviceType="lamp"></ss-snow-cannon-component-button>
                  </div>
              </div>
              <div class="col"></div>
              <div class="col-auto">
                <h4 i18n="Reset alarmów|Reset alarmów@@massOperationsModalInfosResetAlarms">Reset alarmów</h4>
                <button class="btn btn-danger" (click)="resetAlarms()"
                  i18n="Reset alarmów|Reset alarmów@@massOperationsModalInfosResetAlarms">Reset alarmów</button>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div [ngClass]="{ 'disabled': canChangeOscillationAngleIds.length <= 0 }">
                  <h4 i18n="Kont oscylacji|Kont oscylacji@@massOperationsModalInfosOscillationAngle">Kont oscylacji na {{ canChangeOscillationAngleIds.length }} armatkach</h4>
                  <p class="white-space-nowrap">
                    <button class="btn btn-inline btn-link" (click)="setOscillationAngle(15)">15&deg;</button>
                    <button class="btn btn-inline btn-link" (click)="setOscillationAngle(30)">30&deg;</button>
                    <button class="btn btn-inline btn-link" (click)="setOscillationAngle(45)">45&deg;</button>
                    <button class="btn btn-inline btn-link" (click)="setOscillationAngle(60)">60&deg;</button>
                    <button class="btn btn-inline btn-link" (click)="setOscillationAngle(90)">90&deg;</button>
                    <button class="btn btn-inline btn-link" (click)="setOscillationAngle(105)">105&deg;</button>
                    <button class="btn btn-inline btn-link" (click)="setOscillationAngle(120)">120&deg;</button>
                  </p>
                </div>
              </div>
              <div class="col pl-2">
                <div [ngClass]="{ 'disabled': canStartOscillationIds.length <= 0 }">
                  <h4 i18n="Uruchom oscylację|Uruchom oscylację@@massOperationsModalInfosOscillationStart">Uruchom oscylację na {{ canStartOscillationIds.length }} armatkach</h4>
                  <ss-control-button action="start" (click)="startOscillation()">Start</ss-control-button>
                </div>
              </div>
              <div class="col pl-2">
                <div [ngClass]="{ 'disabled': canStopOscillationIds.length <= 0 }">
                  <h4 i18n="Zatrzymaj oscylację|Zatrzymaj oscylację@@massOperationsModalInfosOscillationStop">Zatrzymaj oscylację na {{ canStopOscillationIds.length }} armatkach</h4>
                  <ss-control-button action="stop" (click)="stopOscillation()">Stop</ss-control-button>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="hasRemoteConditionsOnIds.length">
              <div class="col">
                <h4 i18n="Zatrzymaj zadawanie zdalych parametrów meteo na X armatkach|Zatrzymaj zadawanie zdalych parametrów meteo na X armatkach@@massOperationsModalInfosResetRemoteMeteoConditions">Zatrzymaj zadawanie zdalych parametrów meteo na {{ hasRemoteConditionsOnIds.length }} armatkach</h4>
                <button class="btn w-100 btn-warning" (click)="resetRemoteMeteoConditions()" i18n="Zatrzymaj zadawanie zdalych parametrów meteo|Zatrzymaj zadawanie zdalych parametrów meteo@@massOperationsModalInfosResetRemoteMeteoConditionsButton">Zatrzymaj zadawanie zdalych parametrów meteo</button>
              </div>
            </div>

            <div class="row" *ngIf="meteoSimulator">
              <div class="col">
                <h4 i18n="Włącz zadawanie zdalych parametrów meteo z symulatora na X armatkach|Włącz zadawanie zdalych parametrów meteo na X armatkach@@massOperationsModalInfosSetRemoteMeteoConditionsFromSimulator">Włącz zadawanie zdalych parametrów meteo z symulatora na {{ hasRemoteConditionsOnIds.length }} armatkach</h4>
                <button class="btn w-100 btn-warning" (click)="setMeteoSimulatorAsRemoteMeteoConditions()" i18n="Zatrzymaj zadawanie zdalych parametrów meteo|Zatrzymaj zadawanie zdalych parametrów meteo@@massOperationsModalInfosSetRemoteMeteoConditionsFromSimulatorButton">Włącz zadawanie zdalych parametrów meteo z symulatora {{ meteoSimulator.symbol }}</button>
              </div>
            </div>

            <div class="row" *ngIf="needToFixHydrantNoIds.length">
              <div class="col">
                <h4 i18n="Naprawa X armatek, które mają różny numer hydrantu w systemie i na panelu armatki|Naprawa X armatek, które mają różny numer hydrantu w systemie i na panelu armatki@@massOperationsModalRepairInvalidHydrantNo">Naprawa {{ needToFixHydrantNoIds.length }} armatek, które mają różny numer hydrantu w systemie i na panelu armatki</h4>
                <div class="row">
                  <div class="col pr-2">
                    <button (click)="applyHydrantNumberFromSystem()"
                      class="btn w-100 btn-warning"
                      i18n="Ustaw armatkom taki numer hydrantu jaki mają w systemie|from system to snow cannon@@massOperationsModalRepairInvalidHydrantNoFromSystem">Ustaw armatkom taki numer hydrantu jaki mają w systemie</button>
                  </div>
                  <div class="col">
                    <button (click)="applyHydrantNumberFromSnowCannon()"
                      class="btn w-100 btn-warning"
                      i18n="Ustaw w systemie taki numer hydrantu jaki mają na panelu|from snow cannon to system@@massOperationsModalRepairInvalidHydrantNoToSystem">Ustaw w systemie taki numer hydrantu jaki mają na panelu</button>
                  </div>
                </div>
              </div>
            </div>

        </ss-page-panel>
        <ss-page-footer>
            <div></div>
            <ss-page-button-group>
                <button class="btn btn-default" (click)="close()" i18n="Anuluj|Anuluj@@massOperationsModalCancelButon">Anuluj</button>
            </ss-page-button-group>
        </ss-page-footer>
    </ss-page>
</div>
