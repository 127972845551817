<div class="window-overview-wrapper" [ngClass]="{ 'collapsed': windowCollapse }" *ngIf="area">
  <button class="window-overview__collapse-btn" (click)="toggleWindowCollapse()">
    <svg>
      <use xlink:href='#icon-chevron-double'/>
    </svg>
  </button>
  <perfect-scrollbar>

    <div class="window-overview mb-2">
      <div class="window-overview__container">
        <ss-meteo-conditions-named-display
          class="clickable"
          (click)="openMeteoSelectorModal()"
          [sourceId]="area.remoteMeteoStation"></ss-meteo-conditions-named-display>
      </div>
      <div class="window-overview__mode">
        <div class="mode-dropdown" [ngClass]="{'mode-dropdown--manual': !area.operationMode}">
          <button [disabled]="area.isLocked" class="mode-dropdown__btn" (click)="toggleProperty(area, 'isModeDropdownExpanded')">
            <span class="mode-dropdown__subheader" i18n="Stokiem steruje|Etykieta wyboru trybu pracy stoku@@areaPanelOperationMode">Stokiem steruje</span>
            <span class="mode-dropdown__header" *ngIf="area.operationMode" i18n="Snowmatic|Tryb pracy Snowmatic@@operationModeSnowmatic">Snowmatic</span>
            <span class="mode-dropdown__header" *ngIf="!area.operationMode" i18n="Operator|Tryb pracy Operator@@operationModeOperator">Operator</span>
            <svg class="mode-dropdown__angle" [ngClass]="{'mode-dropdown__angle--rotated': area['isModeDropdownExpanded']}" >
              <use xlink:href='#icon-chevron-down'/>
            </svg>
          </button>
          <div class="mode-dropdown__menu" [ngClass]="{'mode-dropdown__menu--expanded': area['isModeDropdownExpanded'], 'mode-dropdown__menu--auto': !area.operationMode}">
            <button [disabled]="area.isLocked" class="mode-dropdown__btn" (click)="setAreaOperationMode(area, !area.operationMode); toggleProperty(area, 'isModeDropdownExpanded')">
                <span class="mode-dropdown__header" *ngIf="!area.operationMode" i18n="Snowmatic|Tryb pracy Snowmatic@@operationModeSnowmatic">Snowmatic</span>
                <span class="mode-dropdown__header" *ngIf="area.operationMode" i18n="Operator|Tryb pracy Operator@@operationModeOperator">Operator</span>
            </button>
          </div>
        </div>
        <div class="mode-details px-4" [ngClass]="{'mode-details--manual': !area.operationMode}">
          <ng-container *ngIf="area.operationMode; else manualSettings">
            <div class="mode-details__col">
              <p class="mode-details__header">{{ currentSchedule.schema.name }}</p>
              <p class="mode-details__setting">
                <svg>
                  <use xlink:href='#icon-termo-wet'/>
                </svg>
                {{ currentSchedule.schema.startTemperature }}&#176;C
              </p>
            </div>
            <div class="mode-details__col">
              <button class="mode-details__edit-btn" [routerLink]="['/application/weather-and-schedules/edit', currentSchedule.id]" i18n="Edytuj|Edycja ustawień w trybie automatycznym@@areaButtonEdit">Edytuj</button>
              <p class="mode-details__setting">
                <svg>
                  <use xlink:href='#icon-time'/>
                </svg>
                {{ closestScheduleHours }}
              </p>
            </div>
          </ng-container>
          <ng-template #manualSettings>
            <div class="mode-details__col">
              <ng-container *ngIf="hasSnowCannonWithStartTemperature">
                <p class="mode-details__header" i18n="Temperatura startu|Etykieta pola ustawiającego temperaturę startu@@areaStartTemperature">Temperatura startu</p>
                <div class="mode-details__setting"  [ngClass]="{ 'mode-details__setting__small': hasSnowCannonWithStartFlow}">
                  <ng-container *ngIf="minSnowCannonStartTemperature === maxSnowCannonStartTemperature">
                    {{ minSnowCannonStartTemperature|number:'1.1-1' }}
                    <svg>
                      <use xlink:href='#icon-termo-wet' />
                    </svg>&#176;C
                  </ng-container>
                  <ng-container *ngIf="minSnowCannonStartTemperature < maxSnowCannonStartTemperature">
                    <small i18n="od|Etykieta poczatku przedzialu wartosci startowej@@areaStartParamFrom">od</small>&nbsp;
                    {{ minSnowCannonStartTemperature|number:'1.1-1' }}&nbsp;
                    <small i18n="do|Etykieta konca przedzialu wartosci startowej@@areaStartParamTo">do</small>&nbsp;
                    {{ maxSnowCannonStartTemperature|number:'1.1-1' }}
                    <svg>
                      <use xlink:href='#icon-termo-wet' />
                    </svg>&#176;C
                  </ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="hasSnowCannonWithStartFlow">
                <p class="mode-details__header" i18n="Przeplyw startu|Etykieta pola ustawiającego przepływ startu@@areaStartFlow">Przeplyw startu</p>
                <div class="mode-details__setting" [ngClass]="{ 'mode-details__setting__small': hasSnowCannonWithStartTemperature}">
                  <ng-container *ngIf="minSnowCannonStartFlow === maxSnowCannonStartFlow">
                    {{ minSnowCannonStartFlow|number:'1.1-1' }}
                    <svg>
                      <use xlink:href='#icon-tap' />
                    </svg>l/s
                  </ng-container>
                  <ng-container *ngIf="minSnowCannonStartFlow < maxSnowCannonStartFlow">
                    <small i18n="od|Etykieta poczatku przedzialu wartosci startowej@@areaStartParamFrom">od</small>&nbsp;
                    {{ minSnowCannonStartFlow|number:'1.1-1' }}&nbsp;
                    <small i18n="do|Etykieta konca przedzialu wartosci startowej@@areaStartParamTo">do</small>&nbsp;
                    {{ maxSnowCannonStartFlow|number:'1.1-1' }}
                    <svg>
                      <use xlink:href='#icon-tap' />
                    </svg>l/s
                  </ng-container>
                </div>
              </ng-container>
            </div>
            <div class="mode-details__col">
              <ss-control-button size="small"
                [ssdDisableIfNotGranted]="'ROLE_OPERATOR'"
                [disabled]="area.isLocked"
                [action]="area.isAnyCannonStarted() ? 'stop' : 'start'"
                (click)="toggleAreaStartStop(area, !area.isAnyCannonStarted())">
                <ng-container *ngIf="area.isAnyCannonStarted()" i18n="Stop|Etykieta przycisku zatrzymującego@@controlButtonStop">Stop</ng-container>
                <ng-container *ngIf="!area.isAnyCannonStarted()" i18n="Start|Etykieta przycisku uruchamiającego@@controlButtonStart">Start</ng-container>
              </ss-control-button>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="window-overview__container">

        <ss-area-cannons-status-display [area]="area"></ss-area-cannons-status-display>

      </div>
      <div class="window-overview__container window-overview__container--double">
        <div class="window-overview__col pt-3">
          <p class="window-overview__header" i18n="Produkcja śniegu|Nazwa pola przedstawiającego wielkość produkcji śniegu@@snowProduction">Produkcja śniegu</p>
          <p class="window-overview__text white-space-nowrap">
            <span class="window-overview__text--xl color-green">{{ area|convertedValue:'snowProductionVolume' }}</span>
            <span class="window-overview__text window-overview__text--l color-mid-grey">{{ area|convertedUnit:'snowProductionVolume' }}</span>
          </p>

          <p class="window-overview__header pt-1" i18n="Zużycie wody|Nazwa pola przedstawiającego wielkość zużycie wody@@currentWaterFlowFieldTitle">Zużycie wody</p>
          <p class="window-overview__text white-space-nowrap">
            <span class="window-overview__text--xl color-mid-grey">{{ area|convertedValue:'computedWaterFlow' }}</span>
            <span class="window-overview__text window-overview__text--l color-mid-grey">{{ area|convertedUnit:'computedWaterFlow' }}</span>
          </p>

        </div>
        <div class="window-overview__col pt-4">
          <ss-d3-snow-production-and-twb-chart [device]="area" [suplementaryDevice]="area.remoteMeteoStationRef"></ss-d3-snow-production-and-twb-chart>
        </div>
      </div>
      <div class="window-overview__container window-overview__container--double">
        <div class="py-3 w-100">
            <ss-snow-quality
            [showIcons]="true"
            [isDisabled]="area.isLocked"
            [disableSnowQualitySelection]="area.operationMode === 0"
            [noOfDevicesBySnowQuality]="area.countBySnowQuality()"
            [currentSnowQuality]="area.snowQualityConstant"
            [snowQualityConstant]="area.snowQualityConstant"
            [snowQualityMin]="area.snowQualityLow"
            [snowQualityMax]="area.snowQualityHigh"
            [snowQualityType]="area.snowQualityType"
            (snowQualityChange)="setAreaSnowQuality(area, $event)" size="large"></ss-snow-quality>
        </div>
      </div>

    </div>

    <div class="window-overview window-overview--tabs">
      <ss-tabs>
        <ss-area-stats-and-reports-tab
          [area]="area"
          [pumpRooms]="pumpRooms"
          [powerSources]="powerSources"
          [waterReservoirs]="waterReservoirs"
          tabTitle="Statystyki i raporty"
          i18n-tabTitle="Statystyki i raporty|Tytuł zakładki ze statystykami dot. danej armatki@@areaTabsTitleStats"></ss-area-stats-and-reports-tab>

        <ss-user-notes-tab
          [context]="area.id"
          tabTitle="Notatki"
          i18n-tabTitle="Notatki|Tytuł zakładki z notatkami dot. danej armatki@@areaTabsTitleNotes"></ss-user-notes-tab>

        <ss-documentation-tab
          [context]="area.id"
          tabTitle="Dokumentacja"
          i18n-tabTitle="Dokumentacja|Tytuł zakładki z dokuemntacją dot. danej armatki@@areaTabsTitleDocumentation"></ss-documentation-tab>

        <ss-tab tabTitle="Pokrywa śnieżna" i18n-tabTitle="Pokrywa śnieżna|Tytuł zakładki Pokrywa śnieżna@@areaTabsTitleSnowDepth" [hidden]="canNotShowSnowThickness">
          <div class="px-3 pt-2 pb-3 color-light-grey">

            <div class="row">
              <div class="col-auto mr-3">
                <p class="window-overview__header text-uppercase"
                  i18n="Żródło danych|Zakładki Pokrywa śnieżna - Żródło danych@@areaTabsSnowDepthDataSource">Żródło danych</p>
                <p class="window-overview__text">
                  <span *ngFor="let k of objectKeys(sTMap); let last = last"
                    [ngClass]="{'font-bold': sTProviderId === k }">
                    <span class="clickable" (click)="setsTProviderId(k)">
                      {{ k }}</span><ng-container *ngIf="!last">,</ng-container>
                  </span>
                </p>
              </div>
              <div class="col-auto mr-3" *ngIf="sTProviderId && sTMap[sTProviderId]">
                <p class="window-overview__header text-uppercase"
                  i18n="Rok|Zakładki Pokrywa śnieżna - Rok@@areaTabsSnowDepthYear">Rok</p>
                <p class="window-overview__text">
                  <span *ngFor="let k of objectKeys(sTMap[sTProviderId]); let last = last"
                    class="clickable"
                    [ngClass]="{'font-bold': sTYear === +k }">
                    <span class="clickable" (click)="setsTYear(k)">
                      {{ k }}</span><ng-container *ngIf="!last">,</ng-container>
                  </span>
                </p>
              </div>
              <div class="col-auto mr-3" *ngIf="sTProviderId && sTYear && sTMap[sTProviderId][sTYear]">
                <p class="window-overview__header text-uppercase"
                  i18n="Miesiąc|Zakładki Pokrywa śnieżna - Miesiąc@@areaTabsSnowDepthMonth">Miesiąc</p>
                <p class="window-overview__text">
                  <span *ngFor="let k of objectKeys(sTMap[sTProviderId][sTYear]); let last = last"
                    class="clickable"
                    [ngClass]="{'font-bold': sTMonth === +k }">
                    <span class="clickable" (click)="setsTMonth(k)">
                      {{ k }}</span><ng-container *ngIf="!last">,</ng-container>
                  </span>
                </p>
              </div>
              <div class="col mr-3" *ngIf="sTProviderId && sTYear && sTMonth && sTMap[sTProviderId][sTYear][sTMonth]">
                <p class="window-overview__header text-uppercase"
                  i18n="Dzień|Zakładki Pokrywa śnieżna - Dzień@@areaTabsSnowDepthDay">Dzień</p>
                <p class="window-overview__text">
                  <span *ngFor="let k of objectKeys(sTMap[sTProviderId][sTYear][sTMonth]); let last = last"
                    class="clickable"
                    [ngClass]="{'font-bold': sTDay === +k }">
                    <span class="clickable" (click)="setsTDay(k)">
                      {{ k }}</span><ng-container *ngIf="!last">,</ng-container>
                  </span>
                </p>
              </div>
              <div class="col-auto">
                <button class="btn btn-default" (click)="toggleSnowThicknessLayer()"
                  i18n="Pokaż na mapie|Zakładki Pokrywa śnieżna - Pokaż na mapie@@areaTabsSnowDepthShowOnMapButton">Pokaż na mapie</button>
              </div>
            </div>
          </div>
        </ss-tab>

        <ss-area-settings-tab
          *ssdHideIfNotGranted="'ROLE_OPERATOR'"
          [area]="area"
          tabTitle="Ustawienia"
          i18n-tabTitle="Ustawienia|Tytuł zakładki ze ustawieniami@@areaTabsTitleSettings"></ss-area-settings-tab>

        <ss-tab tabTitle="Debug" *ssdHideIfNotGranted="'ROLE_SUPERADMIN'">
          <div class="p-3 color-light-grey">
            <ss-debug-table [data]="area"></ss-debug-table>
          </div>
        </ss-tab>
        <ss-notifications-tab
          [context]="devIds"
          tabTitle="Powiadomienia"
          i18n-tabTitle="Powiadomienia|Nazwa zakładki z listą powiadomień@@areaTabsAlerts"></ss-notifications-tab>

      </ss-tabs>
    </div>
  </perfect-scrollbar>
</div>
